// C:\project\rest_api\client\src\scenes\T1\index.js
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { createChart } from 'lightweight-charts';

// Convert "YYYY-MM-DD HH:MM:SS" to seconds
function datetimeToTimestamp(datetimeStr) {
  if (!datetimeStr || typeof datetimeStr !== 'string') {
    console.error("Invalid datetimeStr:", datetimeStr);
    return null;
  }
  const dateObj = new Date(datetimeStr.replace(' ', 'T') + 'Z');
  return Math.floor(dateObj.getTime() / 1000);
}

function validateSeriesData(series) {
  for (let i = 1; i < series.length; i++) {
    if (series[i].time <= series[i - 1].time) {
      console.error(`Data is not strictly ascending at index ${i}:`, series[i], series[i - 1]);
      return false;
    }
  }
  return true;
}

/**
 * Accumulates daily. 
 *  - Resets to 0 each new date.
 */
function convertApiDataToSeries(data) {
  const sortedData = [...data]
    .filter(d => d.datetime)
    .sort((a, b) => datetimeToTimestamp(a.datetime) - datetimeToTimestamp(b.datetime));

  let accumulatedValue = 0;
  let currentDay = null;
  const resultSeries = [];
  const seenTimestamps = new Set();

  sortedData.forEach(d => {
    const timestamp = datetimeToTimestamp(d.datetime);
    if (timestamp === null) return;

    const dateObj = new Date(d.datetime.replace(' ', 'T') + 'Z');
    const day = dateObj.toISOString().split('T')[0];
    if (currentDay !== day) {
      accumulatedValue = 0;
      currentDay = day;
    }

    // negative_to_positive - positive_to_negative
    const val = (d.negative_to_positive || 0) - (d.positive_to_negative || 0);
    accumulatedValue += val;

    if (!seenTimestamps.has(timestamp)) {
      resultSeries.push({ time: timestamp, value: accumulatedValue });
      seenTimestamps.add(timestamp);
    } else {
      console.warn(`Duplicate timestamp: ${timestamp} => skipping entry.`);
    }
  });

  return resultSeries;
}

// ---------- API fetches (node backend) ----------
async function fetchHistoryData(market, csrfToken) {
  // e.g. GET /t1/history/SET  and  GET /intraday/history/SET
  const baseApiUrl = window.config.base_api_url; // e.g. "http://yourdomain.com"
  const t1HistoryUrl = `${baseApiUrl}/t1/history/${market}`;
  const intradayHistoryUrl = `${baseApiUrl}/intraday/history/${market}`;

  try {
    const [t1HistoryRes, intradayHistoryRes] = await Promise.all([
      fetch(t1HistoryUrl, {
        headers: {
          'Content-Type': 'application/json',
          'xsrf-token': csrfToken,
        },
      }),
      fetch(intradayHistoryUrl, {
        headers: {
          'Content-Type': 'application/json',
          'xsrf-token': csrfToken,
        },
      }),
    ]);

    if (!t1HistoryRes.ok) {
      console.error(`Failed to fetch T1 history: ${t1HistoryRes.statusText}`);
      return { t1History: [], intradayHistory: [] };
    }
    if (!intradayHistoryRes.ok) {
      console.error(`Failed to fetch Intraday history: ${intradayHistoryRes.statusText}`);
      return { t1History: [], intradayHistory: [] };
    }

    const t1History = await t1HistoryRes.json();
    const intradayHistory = await intradayHistoryRes.json();

    return { t1History, intradayHistory };
  } catch (error) {
    console.error('Error fetching history data:', error);
    return { t1History: [], intradayHistory: [] };
  }
}

async function fetchRealtimeData(market, csrfToken) {
  // e.g. GET /t1/realtime/SET  and  GET /intraday/realtime/SET
  const baseApiUrl = window.config.base_api_url;
  const t1RealtimeUrl = `${baseApiUrl}/t1/realtime/${market}`;
  const intradayRealtimeUrl = `${baseApiUrl}/intraday/realtime/${market}`;

  try {
    const [t1RealtimeRes, intradayRealtimeRes] = await Promise.all([
      fetch(t1RealtimeUrl, {
        headers: {
          'Content-Type': 'application/json',
          'xsrf-token': csrfToken,
        },
      }),
      fetch(intradayRealtimeUrl, {
        headers: {
          'Content-Type': 'application/json',
          'xsrf-token': csrfToken,
        },
      }),
    ]);

    if (!t1RealtimeRes.ok) {
      console.error(`Failed to fetch T1 realtime: ${t1RealtimeRes.statusText}`);
      return { t1Realtime: [], intradayRealtime: [] };
    }
    if (!intradayRealtimeRes.ok) {
      console.error(`Failed to fetch Intraday realtime: ${intradayRealtimeRes.statusText}`);
      return { t1Realtime: [], intradayRealtime: [] };
    }

    const t1Realtime = await t1RealtimeRes.json();
    const intradayRealtime = await intradayRealtimeRes.json();

    return { t1Realtime, intradayRealtime };
  } catch (error) {
    console.error('Error fetching realtime data:', error);
    return { t1Realtime: [], intradayRealtime: [] };
  }
}

// ---------- The main ChartSection component ----------
const ChartSection = ({ title, market }) => {
  // We'll read csrfToken from Redux:
  const csrfToken = useSelector(state => state.csrfToken);

  const chartContainerRef = useRef(null);
  const chartRef = useRef(null);

  const t1SeriesRef = useRef(null);
  const intradaySeriesRef = useRef(null);

  const [t1HistoryData, setT1HistoryData] = useState([]);
  const [intradayHistoryData, setIntradayHistoryData] = useState([]);
  const [t1RealtimeData, setT1RealtimeData] = useState([]);
  const [intradayRealtimeData, setIntradayRealtimeData] = useState([]);

  const [showT1, setShowT1] = useState(true);
  const [showIntraday, setShowIntraday] = useState(true);

  // On mount, fetch data + create chart
  useEffect(() => {
    let isMounted = true;

    const initLoad = async () => {
      const { t1History, intradayHistory } = await fetchHistoryData(market, csrfToken);
      if (isMounted) {
        setT1HistoryData(t1History);
        setIntradayHistoryData(intradayHistory);
      }

      const { t1Realtime, intradayRealtime } = await fetchRealtimeData(market, csrfToken);
      if (isMounted) {
        setT1RealtimeData(t1Realtime);
        setIntradayRealtimeData(intradayRealtime);
      }
    };

    initLoad();

    // Poll real-time
    const intervalId = setInterval(async () => {
      const { t1Realtime, intradayRealtime } = await fetchRealtimeData(market, csrfToken);
      if (isMounted) {
        setT1RealtimeData(t1Realtime);
        setIntradayRealtimeData(intradayRealtime);
      }
    }, 10000);

    // Create chart
    if (chartContainerRef.current) {
      const chart = createChart(chartContainerRef.current, {
        layout: { textColor: 'black', background: { type: 'solid', color: 'white' } },
        timeScale: {
          timeVisible: true,
          rightOffset: 120, // fixed offset
        },
        height: 300,
        width: chartContainerRef.current.offsetWidth,
        localization: {
          priceFormatter: (price) => price.toLocaleString('en-US', { maximumFractionDigits: 0 }),
        },
        priceScale: {
          mode: 0,
        },
      });

      chartRef.current = chart;

      // Baseline
      const baselineSeries = chart.addLineSeries({
        color: 'black',
        lineWidth: 1,
        lineStyle: 0,
        lastValueVisible: false,
        priceLineVisible: false,
      });

      // T1 line
      t1SeriesRef.current = chart.addLineSeries({
        color: '#2196f3',
        lineWidth: 2,
        title: 'Flip T-1 -> T',
      });

      // Intraday line
      intradaySeriesRef.current = chart.addLineSeries({
        color: '#4caf50',
        lineWidth: 2,
        title: 'Intraday',
      });

      // Resize observer
      const ro = new ResizeObserver(() => {
        if (chartContainerRef.current) {
          chart.applyOptions({
            width: chartContainerRef.current.clientWidth,
            height: chartContainerRef.current.clientHeight,
          });
        }
      });
      ro.observe(chartContainerRef.current);

      return () => {
        isMounted = false;
        clearInterval(intervalId);
        ro.disconnect();
        chart.remove();
      };
    }
  }, [market, csrfToken]);

  // Whenever data changes, setData on the existing series
  useEffect(() => {
    if (!chartRef.current || !t1SeriesRef.current || !intradaySeriesRef.current) return;

    // Merge + convert
    const t1Data = convertApiDataToSeries([...t1HistoryData, ...t1RealtimeData]);
    const intradayData = convertApiDataToSeries([...intradayHistoryData, ...intradayRealtimeData]);
    t1Data.sort((a, b) => a.time - b.time);
    intradayData.sort((a, b) => a.time - b.time);

    if (!validateSeriesData(t1Data) || !validateSeriesData(intradayData)) {
      console.error('Invalid data => skip updating chart lines');
      return;
    }

    // Update line data
    t1SeriesRef.current.setData(t1Data);
    intradaySeriesRef.current.setData(intradayData);

    // Update baseline if intraday data exists
    const baselineSeries = chartRef.current.addLineSeries({
      color: 'black',
      lineWidth: 1,
      lineStyle: 0,
      lastValueVisible: false,
      priceLineVisible: false,
    });
    if (intradayData.length > 0) {
      baselineSeries.setData([
        { time: intradayData[0].time, value: 0 },
        { time: intradayData[intradayData.length - 1].time, value: 0 },
      ]);
    }
  }, [t1HistoryData, t1RealtimeData, intradayHistoryData, intradayRealtimeData]);

  // Toggle T1
  useEffect(() => {
    if (t1SeriesRef.current) {
      t1SeriesRef.current.applyOptions({ visible: showT1 });
    }
  }, [showT1]);

  // Toggle Intraday
  useEffect(() => {
    if (intradaySeriesRef.current) {
      intradaySeriesRef.current.applyOptions({ visible: showIntraday });
    }
  }, [showIntraday]);

  return (
    <div style={{ marginBottom: '20px' }}>
      <h2 style={{ textAlign: 'center' }}>
        {title} ({market})
      </h2>

      <div style={{ textAlign: 'right', marginBottom: '10px' }}>
        <button onClick={() => setShowT1(prev => !prev)}>
          {showT1 ? 'Flip T-1 -> T' : 'Flip T-1 -> T'}
        </button>
        <button
          style={{ marginLeft: '10px' }}
          onClick={() => setShowIntraday(prev => !prev)}
        >
          {showIntraday ? 'Intraday' : 'Intraday'}
        </button>
      </div>

      <div style={{ width: '100%', height: '300px' }} ref={chartContainerRef} />
    </div>
  );
};

// Page that renders multiple charts
const FlipPage = () => {
  return (
    <div style={{ padding: '0px' }}>
      <ChartSection title="SET" market="SET" />
      <ChartSection title="MAI" market="MAI" />
      <ChartSection title="WARRANT" market="WARRANT" />
    </div>
  );
};

export default FlipPage;
