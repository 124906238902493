import React, { useState, useEffect, useRef } from 'react';
import { createChart } from 'lightweight-charts';
import { useSelector } from "react-redux";
import { useTheme, Box, Container, Grid, Card, TextField, Button, Table, TableHead, TableRow, TableCell, TableBody, Typography, Paper, Slider } from '@mui/material';

import { tokens } from "../../theme";
import Header from "../../components/Header";
import CardContent from '@mui/material/CardContent';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { __fetching_data__ } from '../../components/api.js';
import dayjs from 'dayjs';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import TableContainer from '@mui/material/TableContainer';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import IconButton from '@mui/material/IconButton';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import Preloader from '../../components/Preloader';

const base_api_url =
    process.env.NODE_ENV === "production"
        ? window.location.protocol === "https:"
            ? process.env.REACT_APP_API_HTTPS_URL
            : process.env.REACT_APP_API_HTTP_URL
        : "/application";

function createData(vol_bid, bid, ask, vol_ask) {
    return { vol_bid, bid, ask, vol_ask };
}

function createDataTickmatch(open, high, low, close) {
    return { open, high, low, close };
}

const ShortTermTrading = () => {
    const [symbol, setSymbol] = useState('');
    const [date, setDate] = useState(dayjs().format("YYYY-MM-DD"));
    const [intradayData, setIntradayData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [dailyData, setDailyData] = useState(null);
    const [sliderValue, setSliderValue] = useState(0);
    const priceChartRef = useRef();
    const intradayNetChartRef = useRef();
    const intraday15MinChartRef = useRef();
    const csrfToken = useSelector((state) => state.csrfToken);
    const [Time2Show, setTime2Show] = useState('00:00:00');
    const fetchDailyData = async (symbol) => {
        try {
            const response = await fetch(`${window.config.base_api_url}/vot?symbol=${symbol}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'xsrf-token': csrfToken,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            setDailyData(data.dailyData);
            console.log("VOT Daily response data:", data);
        } catch (error) {
            console.error('Error fetching daily data:', error);
        }
    };
    const fetchIntradayData = async (symbol, date) => {
        try {
            const response = await fetch(`${window.config.base_api_url}/vot?symbol=${symbol}&date=${date}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'xsrf-token': csrfToken,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            setIntradayData(data);
            console.log("Intraday response data:", data);
        } catch (error) {
            console.error('Error fetching intraday data:', error);
        }
    };
    const fetchData = async () => {
        if (!symbol || !date) {
            console.error('Both Symbol and Date are required');
            return;
        }

        setLoading(true);
        try {
            await fetchDailyData(symbol);
            await fetchIntradayData(symbol, date);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };
    const sortByTimeAscending = (data) => {
        return data.sort((a, b) => a.time - b.time);
    };
    const aggregateToHourly = (data) => {
        const hourlyData = [];
        data.forEach((item) => {
            const date = new Date(item.time);
            const roundedTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours()).getTime() / 1000;

            const existingEntry = hourlyData.find((entry) => entry.time === roundedTime);
            if (existingEntry) {
                existingEntry.value = item.last;
            } else {
                hourlyData.push({
                    time: roundedTime,
                    value: item.last,
                });
            }
        });
        return hourlyData;
    };
    const aggregateTo15Min = (data) => {
        const fifteenMinData = [];
        data.forEach((item) => {
            const date = new Date(item.time);
            const roundedTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), Math.floor(date.getMinutes() / 15) * 15).getTime() / 1000;
            const existingEntry = fifteenMinData.find((entry) => entry.time === roundedTime);
            if (existingEntry) {
                existingEntry.value = item.last;
            } else {
                fifteenMinData.push({
                    time: roundedTime,
                    value: item.last,
                });
            }
        });
        return fifteenMinData;
    };
    const createChartContainer = (container, options) => {
        const chart = createChart(container, options);
        container._chart = chart;
        return chart;
    };
    const createPriceChart = (container, priceData) => {
        const chart = createChartContainer(container, {
            width: container.clientWidth,
            height: 150,
        });
        const series = chart.addLineSeries({ color: 'green' });
        series.setData(priceData.map(item => ({
            time: new Date(item.date).getTime() / 1000, // Convert to timestamp
            value: item.close,
        })));
        container._chart = { chart, series }; // เก็บ chart และ series ใน container
        return { chart, series };
    };
    const createHourlyPriceChart = (container, priceData) => {
        const chart = createChartContainer(container, {
            width: container.clientWidth,
            height: container.clientHeight,
            timeScale: {
                timeVisible: true,
                secondsVisible: false,
            },
        });
        const series = chart.addLineSeries({ color: 'blue' });
        const hourlyData = aggregateToHourly(priceData);
        series.setData(sortByTimeAscending(hourlyData));
        return { chart, series };
    };
    const create15MinPriceChart = (container, priceData) => {
        const chart = createChartContainer(container, {
            width: container.clientWidth,
            height: container.clientHeight,
            timeScale: {
                timeVisible: true,
                secondsVisible: false,
            },
        });
        const series = chart.addLineSeries({ color: 'red' });
        const fifteenMinData = aggregateTo15Min(priceData);
        series.setData(sortByTimeAscending(fifteenMinData));
        return { chart, series };
    };
    const syncVisibleRange = (chart1, chart2) => {
        chart1.timeScale().subscribeVisibleLogicalRangeChange((timeRange) => {
            chart2.timeScale().setVisibleLogicalRange(timeRange);
        });

        chart2.timeScale().subscribeVisibleLogicalRangeChange((timeRange) => {
            chart1.timeScale().setVisibleLogicalRange(timeRange);
        });
    };
    const syncCrosshairMove = (chart1, series1, chart2, series2, chart3, series3) => {
        chart1.subscribeCrosshairMove((param) => {
            const dataPoint = param.seriesData.get(series1);
            if (dataPoint) {
                const date = new Date(dataPoint.time * 1000);
                const currentDate = new Date();

                if (date.toDateString() === currentDate.toDateString()) {
                    chart2.setCrosshairPosition(dataPoint.value, dataPoint.time, series2);
                    chart3.setCrosshairPosition(dataPoint.value, dataPoint.time, series3);
                } else {
                    date.setDate(date.getDate() - 1);
                    const adjustedTime = date.getTime() / 1000;

                    chart2.setCrosshairPosition(dataPoint.value, dataPoint.time, series2);
                    chart3.setCrosshairPosition(dataPoint.value, dataPoint.time, series3);
                    chart1.setCrosshairPosition(dataPoint.value, adjustedTime, series1);
                }

                const time = date.toISOString().substr(11, 8);
                setTime2Show(time);
            }
        });

        chart2.subscribeCrosshairMove((param) => {
            const dataPoint = param.seriesData.get(series2);
            if (dataPoint) {
                const date = new Date(dataPoint.time * 1000);
                const currentDate = new Date();

                if (date.toDateString() === currentDate.toDateString()) {
                    chart1.setCrosshairPosition(dataPoint.value, dataPoint.time, series1);
                    chart3.setCrosshairPosition(dataPoint.value, dataPoint.time, series3);
                } else {
                    date.setDate(date.getDate() - 1);
                    const adjustedTime = date.getTime() / 1000;

                    chart1.setCrosshairPosition(dataPoint.value, adjustedTime, series1);
                    chart3.setCrosshairPosition(dataPoint.value, dataPoint.time, series3);
                }

                const time = date.toISOString().substr(11, 8);
                setTime2Show(time);
            }
        });

        chart3.subscribeCrosshairMove((param) => {
            const dataPoint = param.seriesData.get(series3);
            if (dataPoint) {
                const date = new Date(dataPoint.time * 1000);
                const currentDate = new Date();

                if (date.toDateString() === currentDate.toDateString()) {
                    chart1.setCrosshairPosition(dataPoint.value, dataPoint.time, series1);
                    chart2.setCrosshairPosition(dataPoint.value, dataPoint.time, series2);
                } else {
                    date.setDate(date.getDate() - 1);
                    const adjustedTime = date.getTime() / 1000;

                    chart1.setCrosshairPosition(dataPoint.value, adjustedTime, series1);
                    chart2.setCrosshairPosition(dataPoint.value, dataPoint.time, series2);
                }

                const time = date.toISOString().substr(11, 8);
                setTime2Show(time);
            }
        });
    };
    useEffect(() => {
        if (dailyData && !priceChartRef.current._chart) {
            const { chart: priceChart, series: priceSeries } = createPriceChart(priceChartRef.current, dailyData.dailyPrice);

            if (intradayNetChartRef.current._chart && intraday15MinChartRef.current._chart) {
                const { chart: hourlyPriceChart, series: hourlyPriceSeries } = intradayNetChartRef.current._chart;
                const { chart: fifteenMinPriceChart, series: fifteenMinPriceSeries } = intraday15MinChartRef.current._chart;

                syncCrosshairMove(priceChart, priceSeries, hourlyPriceChart, hourlyPriceSeries, fifteenMinPriceChart, fifteenMinPriceSeries);
            }
        }

        if (intradayData && !intradayNetChartRef.current._chart && !intraday15MinChartRef.current._chart) {
            const { chart: hourlyPriceChart, series: hourlyPriceSeries } = createHourlyPriceChart(
                intradayNetChartRef.current,
                intradayData.priceData
            );

            const { chart: fifteenMinPriceChart, series: fifteenMinPriceSeries } = create15MinPriceChart(
                intraday15MinChartRef.current,
                intradayData.priceData
            );

            syncVisibleRange(hourlyPriceChart, fifteenMinPriceChart);

            if (priceChartRef.current._chart) {
                const { chart: priceChart, series: priceSeries } = priceChartRef.current._chart;

                syncVisibleRange(hourlyPriceChart, fifteenMinPriceChart);
                syncCrosshairMove(priceChart, priceSeries, hourlyPriceChart, hourlyPriceSeries, fifteenMinPriceChart, fifteenMinPriceSeries);
            }
        }
    }, [dailyData, intradayData]);
    /////////////////////////////////////////////
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const now = dayjs();
    const today = now.format('YYYY-MM-DD');
    const today_value = dayjs(today).subtract(0, 'day');
    const initialRow = [
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData("", "", "", ""),
        createData("Sum Bid", "", "", "Sum Ask"),
        createData(0, "", "", 0),
    ]
    const initialInRange = [
        createDataTickmatch(0, 0, 0, 0),
    ]
    const initialActual = [
        createDataTickmatch(0, 0, 0, 0),
    ]
    const [rows, setRows] = useState(initialRow);
    const [rowsInRange, setRowsInRange] = useState(initialInRange);
    const [rowsActual, setRowsActual] = useState(initialActual);
    const [initialValues, setInitialValues] = useState({
        start_date: today_value,
        end_date: today_value,
        start_time: "10:00",        // กำหนด start_time คงที่ที่ 10:00
        end_time: "16:30",          // กำหนด end_time คงที่ที่ 16:30
        symbol: "",
        speed: 1
    })
    const formatTime = (time) => {
        if (typeof time === 'object') {
            return `${dayjs(time).hour()}:${String(dayjs(time).minute()).padStart(2, '0')}`;
        }
        return time;
    };
    const formatDate = (date) => {
        if (typeof date === 'object') {
            return `${dayjs(date).year()}-${String(dayjs(date).month() + 1).padStart(2, '0')}-${String(dayjs(date).date()).padStart(2, '0')}`;
        }
        return date;
    };
    const objectToQueryString = (obj) => {
        return Object.keys(obj)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]))
            .join('&');
    }
    const [countRecordBidAsk, setCountRecordBidAsk] = useState(0);
    const [bidaskLength, setBidAskLength] = useState(1);
    const [resultBidAsk, setResultBidAsk] = useState([]);
    const [resultTickMatch, setResultTickMatch] = useState([]);
    const resetProcess = () => {
        previousBidAsk.current = {};
        previousTickMatchInRange.current = {};
        previousTickMatchActual.current = {};
        document.querySelectorAll('.change-tracking td').forEach(cell => {
            cell.style.backgroundColor = '';
        });
        setRows(initialRow);
        setRowsInRange(initialInRange);
        setRowsActual(initialActual);
        setCountRecordBidAsk(0);
        setIsPlaying(false);
        setTime2Show('00:00:00');
    }
    const handleFetchAndSearch = async () => {
        if (!symbol || !date) {
            console.error("Both Symbol and Date are required");
            return;
        }

        setLoading(true);
        resetProcess();

        try {
            await fetchDailyData(symbol);
            await fetchIntradayData(symbol, date);
            await fetchflowdailyData(symbol, date);

            const values = {
                symbol: symbol,
                start_date: date,
                end_date: date,
                start_time: "10:00",
                end_time: "16:30",
                speed: 1
            };

            const response = await __fetching_data__({
                url: `${base_api_url}/bidask_dejavu_report?${objectToQueryString(values)}`,
                method: "GET",
                headers: { "xsrf-token": csrfToken }
            });

            if (response.status) {
                setResultBidAsk(response.data.bidask);
                setResultTickMatch(response.data.tickmatch);
                setBidAskLength(response.data.bidask.length);
                setIsPlaying(true);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        if (dayjs(initialValues.start_date).isSame(dayjs(), 'day')) {
            setInitialValues((prevData) => ({
                ...prevData,
                start_time: "10:00",  // ล็อกเวลาเริ่มต้น
                end_time: "16:30",    // ล็อกเวลาสิ้นสุด
            }));
        }
    }, [initialValues.start_date]);
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            fontSize: 14,
            backgroundColor: colors.blueAccent[500],//"#B6DBFB",//theme.palette.common.black,
            color: theme.palette.common.white,//"#1B7FD6",//theme.palette.common.white,
            fontWeight: 'bold',

        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            backgroundColor: colors.blueAccent[500],
            color: theme.palette.common.white,
            fontWeight: 'bold',
        },
    }));
    const [isPlaying, setIsPlaying] = useState(false);
    const handleSliderChange = (event, newValue) => {
        setCountRecordBidAsk(newValue);

        if (resultBidAsk[newValue - 1]) {
            const newTime = dayjs(resultBidAsk[newValue - 1].Time).format('HH:mm:ss');
            setTime2Show(newTime);
        }
    };
    const NumberWithCommasAndDecimal = (number, decimalPlaces) => {
        const formattedNumber = decimalPlaces === 2
            ? Number(number).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })
            : Number(number).toLocaleString('en-US');

        return <div>{formattedNumber}</div>;
    }
    const hightLow = (values, key) => {
        var min = Math.min(...values.map(item => parseFloat(item[key])));
        var max = Math.max(...values.map(item => parseFloat(item[key])));
        return {
            max: max,
            min: min
        }
    }
    const previousBidAsk = useRef({});
    const previousTickMatchInRange = useRef({});
    const previousTickMatchActual = useRef({});
    const extractAndConvertChildren = (obj) => {
        return Object.keys(obj).reduce((acc, key) => {
            if (obj[key]) {
                if (typeof obj[key] === 'object' && obj[key].props && obj[key].props.children) {
                    const childrenValue = obj[key].props.children.replace(/,/g, '');
                    acc[key] = parseFloat(childrenValue);
                } else {
                    acc[key] = obj[key];
                }
            }

            return acc;
        }, {});
    };
    const compareData = (oldData, newData) => {
        const differences = [];

        oldData.forEach((oldRow, index) => {
            const newRow = newData[index];
            if (!newRow) return;
            const diff = {};

            for (const key in oldRow) {
                if (oldRow[key] !== newRow[key]) {
                    const oldVal = parseFloat(oldRow[key]) || 0;
                    const newVal = parseFloat(newRow[key]) || 0;
                    const change = newVal - oldVal;
                    const changeType = change === 0 ? "" : (change > 0 ? 'positive' : 'negative');
                    diff[key] = {
                        old: oldVal,
                        new: newVal,
                        change: change,
                        type: changeType,
                        rowIndex: index
                    };
                }
            }

            if (Object.keys(diff).length > 0) {
                differences.push({ index, diff });
            }
        });

        return differences;
    };
    const isEmpty = (obj) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    }
    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
    const [speedChanged, setSpeedChanged] = useState(false);
    const prevSpeedRef = useRef(initialValues.speed);
    useEffect(() => {
        let interval;
        let speed = initialValues.speed == 0 || initialValues.speed == null ? 1 : initialValues.speed;
        if (isPlaying) {
            interval = setInterval(async () => {
                speed = initialValues.speed == 0 || initialValues.speed == null ? 1 : initialValues.speed;
                if (speedChanged) {
                    setLoading(true);
                    await delay(2000);
                    setLoading(false);
                }
                let time_minute_search = 0;
                setCountRecordBidAsk(prevCount => {
                    if (prevCount >= bidaskLength - 1) {
                        setIsPlaying(false);
                        clearInterval(interval);
                        return prevCount;
                    } else {
                        let bidask_per_sec = resultBidAsk[prevCount];
                        time_minute_search = dayjs(bidask_per_sec['Time']).format('YYYY-MM-DD HH:mm');
                        let time2show = dayjs(bidask_per_sec['Time']).format('HH:mm:ss');
                        setTime2Show(time2show);

                        const dateToCheck = dayjs(formatDate(initialValues.start_date))
                        const comparisonDate = dayjs('2023-05-08');
                        const isBefore = dateToCheck.isBefore(comparisonDate);

                        document.querySelectorAll('.change-tracking td').forEach(cell => {
                            cell.style.backgroundColor = '';
                        });
                        let currentBidAsk = []
                        const updatedRows = [...rows];
                        if (isBefore) {
                            if (bidask_per_sec != undefined) {
                                currentBidAsk[0] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid1), NumberWithCommasAndDecimal(bidask_per_sec.Bid1, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask5, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk5))
                                currentBidAsk[1] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid2), NumberWithCommasAndDecimal(bidask_per_sec.Bid2, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask4, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk4))
                                currentBidAsk[2] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid3), NumberWithCommasAndDecimal(bidask_per_sec.Bid3, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask3, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk3))
                                currentBidAsk[3] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid4), NumberWithCommasAndDecimal(bidask_per_sec.Bid4, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask2, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk2))
                                currentBidAsk[4] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid5), NumberWithCommasAndDecimal(bidask_per_sec.Bid5, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask1, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk1))
                                currentBidAsk[12] = createData(
                                    NumberWithCommasAndDecimal(parseFloat(bidask_per_sec.VolBid1) + parseFloat(bidask_per_sec.VolBid2) + parseFloat(bidask_per_sec.VolBid3) + parseFloat(bidask_per_sec.VolBid4) + parseFloat(bidask_per_sec.VolBid5)),
                                    "", "",
                                    NumberWithCommasAndDecimal(parseFloat(bidask_per_sec.VolAsk1) + parseFloat(bidask_per_sec.VolAsk2) + parseFloat(bidask_per_sec.VolAsk3) + parseFloat(bidask_per_sec.VolAsk4) + parseFloat(bidask_per_sec.VolAsk5)),
                                )
                                const extractedRows = currentBidAsk.map(extractAndConvertChildren);
                                if (!isEmpty(previousBidAsk.current)) {
                                    let compData = compareData(previousBidAsk.current, extractedRows);
                                    if (compData.length > 0) {
                                        compData.map((obj, index) => {
                                            const entries = Object.entries(obj.diff);
                                            entries.map(([key, value]) => {
                                                const cell = document.querySelector(`#cell-${value.rowIndex}-${key}`);
                                                if (cell) {
                                                    cell.style.backgroundColor = value.type === 'positive' ? 'lightgreen' : 'lightcoral';
                                                }
                                            });
                                        })
                                    }
                                }
                                updatedRows[0] = currentBidAsk[0];
                                updatedRows[1] = currentBidAsk[1];
                                updatedRows[2] = currentBidAsk[2];
                                updatedRows[3] = currentBidAsk[3];
                                updatedRows[4] = currentBidAsk[4];
                                updatedRows[12] = currentBidAsk[12];
                                previousBidAsk.current = extractedRows
                            }
                        } else {
                            if (bidask_per_sec != undefined) {
                                currentBidAsk[0] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid1), NumberWithCommasAndDecimal(bidask_per_sec.Bid1, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask10, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk10))
                                currentBidAsk[1] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid2), NumberWithCommasAndDecimal(bidask_per_sec.Bid2, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask9, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk9))
                                currentBidAsk[2] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid3), NumberWithCommasAndDecimal(bidask_per_sec.Bid3, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask8, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk8))
                                currentBidAsk[3] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid4), NumberWithCommasAndDecimal(bidask_per_sec.Bid4, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask7, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk7))
                                currentBidAsk[4] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid5), NumberWithCommasAndDecimal(bidask_per_sec.Bid5, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask6, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk6))
                                currentBidAsk[5] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid6), NumberWithCommasAndDecimal(bidask_per_sec.Bid6, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask5, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk5))
                                currentBidAsk[6] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid7), NumberWithCommasAndDecimal(bidask_per_sec.Bid7, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask4, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk4))
                                currentBidAsk[7] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid8), NumberWithCommasAndDecimal(bidask_per_sec.Bid8, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask3, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk3))
                                currentBidAsk[8] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid9), NumberWithCommasAndDecimal(bidask_per_sec.Bid9, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask2, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk2))
                                currentBidAsk[9] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid10), NumberWithCommasAndDecimal(bidask_per_sec.Bid10, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask1, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk1))
                                currentBidAsk[12] = createData(
                                    NumberWithCommasAndDecimal(
                                        parseFloat(bidask_per_sec.VolBid1) + parseFloat(bidask_per_sec.VolBid2) + parseFloat(bidask_per_sec.VolBid3) + parseFloat(bidask_per_sec.VolBid4) +
                                        parseFloat(bidask_per_sec.VolBid5) + parseFloat(bidask_per_sec.VolBid6) + parseFloat(bidask_per_sec.VolBid7) + parseFloat(bidask_per_sec.VolBid8) +
                                        parseFloat(bidask_per_sec.VolBid9) + parseFloat(bidask_per_sec.VolBid10)
                                    ),
                                    "", "",
                                    NumberWithCommasAndDecimal(
                                        parseFloat(bidask_per_sec.VolAsk1) + parseFloat(bidask_per_sec.VolAsk2) + parseFloat(bidask_per_sec.VolAsk3) + parseFloat(bidask_per_sec.VolAsk4) +
                                        parseFloat(bidask_per_sec.VolAsk5) + parseFloat(bidask_per_sec.VolAsk6) + parseFloat(bidask_per_sec.VolAsk7) + parseFloat(bidask_per_sec.VolAsk8) +
                                        parseFloat(bidask_per_sec.VolAsk9) + parseFloat(bidask_per_sec.VolAsk10)
                                    ),
                                )
                                const extractedRows = currentBidAsk.map(extractAndConvertChildren);
                                if (!isEmpty(previousBidAsk.current)) {
                                    let compData = compareData(previousBidAsk.current, extractedRows);
                                    if (compData.length > 0) {
                                        compData.map((obj, index) => {
                                            const entries = Object.entries(obj.diff);
                                            entries.map(([key, value]) => {
                                                const cell = document.querySelector(`#cell-${value.rowIndex}-${key}`);
                                                if (cell) {
                                                    cell.style.backgroundColor = value.type === 'positive' ? 'lightgreen' : 'lightcoral';
                                                }
                                            });
                                        })
                                    }
                                }
                                updatedRows[0] = currentBidAsk[0];
                                updatedRows[1] = currentBidAsk[1];
                                updatedRows[2] = currentBidAsk[2];
                                updatedRows[3] = currentBidAsk[3];
                                updatedRows[4] = currentBidAsk[4];
                                updatedRows[5] = currentBidAsk[5];
                                updatedRows[6] = currentBidAsk[6];
                                updatedRows[7] = currentBidAsk[7];
                                updatedRows[8] = currentBidAsk[8];
                                updatedRows[9] = currentBidAsk[9];
                                updatedRows[12] = currentBidAsk[12];
                                previousBidAsk.current = extractedRows
                            }
                        }
                        setRows(updatedRows);
                        if (resultTickMatch != undefined) {
                            let tickmatch_hight_low;
                            let tickmatch_per_min = resultTickMatch.filter((x) => {
                                return x.DtMinOfRec.substring(0, x.DtMinOfRec.length - 3) === time_minute_search;
                            })
                            let tickmatch_in_range = resultTickMatch.filter((x) => {
                                let x1 = dayjs(x.DtMinOfRec);
                                let x21 = dayjs(formatDate(initialValues.start_date) + " " + formatTime(initialValues.start_time));
                                let x22 = dayjs(formatDate(initialValues.end_date) + " " + formatTime(initialValues.end_time));
                                return (x1 >= x21 && x1 <= x22)
                            });
                            if (tickmatch_per_min.length > 0) {
                                tickmatch_hight_low = hightLow(tickmatch_per_min, 'Last');
                            }
                            const updatedRowsInRange = [...rowsInRange];
                            let InRagneTickmatch = createDataTickmatch(
                                tickmatch_in_range.length > 0 ? NumberWithCommasAndDecimal(tickmatch_in_range[0].Last, 2) : 0,
                                tickmatch_hight_low?.max ?? previousTickMatchInRange?.current?.high ?? 0,
                                tickmatch_hight_low?.min ?? previousTickMatchInRange?.current?.low ?? 0,
                                tickmatch_in_range.length > 0 ? NumberWithCommasAndDecimal(tickmatch_in_range[tickmatch_in_range.length - 1].Last, 2) : 0
                            )
                            updatedRowsInRange[0] = InRagneTickmatch;
                            previousTickMatchInRange.current = {
                                open: InRagneTickmatch.open && InRagneTickmatch.open.props ? parseFloat(InRagneTickmatch.open.props.children) : 0,
                                high: parseFloat(InRagneTickmatch.high),
                                low: parseFloat(InRagneTickmatch.low),
                                close: InRagneTickmatch.close && InRagneTickmatch.close.props ? parseFloat(InRagneTickmatch.close.props.children) : 0
                            }
                            setRowsInRange(updatedRowsInRange);

                            const updatedRowsActual = [...rowsActual];
                            let allTickmatchHighLow = hightLow(resultTickMatch, 'Last');
                            let ActualTickmatch = createDataTickmatch(
                                resultTickMatch.length > 0 ? NumberWithCommasAndDecimal(resultTickMatch[0].Last, 2) : 0,
                                allTickmatchHighLow?.max ?? previousTickMatchActual?.current?.high ?? 0,
                                allTickmatchHighLow?.min ?? previousTickMatchActual?.current?.low ?? 0,
                                resultTickMatch.length > 0 ? NumberWithCommasAndDecimal(resultTickMatch[resultTickMatch.length - 1].Last, 2) : 0
                            )
                            updatedRowsActual[0] = ActualTickmatch
                            previousTickMatchActual.current = {
                                open: ActualTickmatch.open && ActualTickmatch.open.props ? parseFloat(ActualTickmatch.open.props.children) : 0,
                                high: ActualTickmatch.high ? parseFloat(ActualTickmatch.high) : 0,
                                low: ActualTickmatch.low ? parseFloat(ActualTickmatch.low) : 0,
                                close: ActualTickmatch.close && ActualTickmatch.close.props ? parseFloat(ActualTickmatch.close.props.children) : 0
                            }
                            setRowsActual(updatedRowsActual)
                        }
                        return prevCount + 1;
                    }
                });
            }, 1000 / speed);
        }
        if (speed !== prevSpeedRef.current) {
            setSpeedChanged(true);
        } else {
            setSpeedChanged(false);
        }
        prevSpeedRef.current = speed;
        return () => clearInterval(interval);
    }, [isPlaying, countRecordBidAsk, initialValues.speed]);
    //////////////////////////////////////////
    const [flowData, setFlowData] = useState(null);
    const flowdaily = useRef();

    const fetchflowdailyData = async (symbol, date) => {
        try {
            const response = await fetch(`${window.config.base_api_url}/vot?symbol=${symbol}&date=${date}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'xsrf-token': csrfToken,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            setFlowData(data);
            console.log("VOT Intraday response data:", data);
            return data;
        } catch (error) {
            console.error('Error fetching intraday data:', error);
            return { error: "Unable to fetch VOT intraday response" };
        }
    };

    const fetchflowData = async () => {
        if (!symbol || !date) {
            console.error('Both Symbol and Date are required');
            return;
        }

        setLoading(true);
        try {
            clearCharts();
            await fetchflowdailyData(symbol, date);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const clearCharts = () => {
        if (flowdaily.current && flowdaily.current._chart) {
            flowdaily.current._chart.remove();
            flowdaily.current._chart = null;
        }
    };

    const createIntradayPriceChart = (container, priceData, exceedDate) => {
        const chart = createChartContainer(container, {
            width: container.clientWidth,
            height: container.clientHeight,
            timeScale: {
                timeVisible: true,
                secondsVisible: true,
                rightOffset: 5
            },
        });
        const series = chart.addLineSeries({ color: 'green' });
        series.setData(sortByTimeAscending(priceData.map(item => ({
            time: new Date(item.time).getTime() / 1000,
            value: item.last,
        }))));

        const markers = [];
        exceedDate.up.forEach((time) => {
            markers.push({
                time: parseDateString(time),
                position: 'aboveBar',
                color: 'green',
                shape: 'arrowUp',
                text: 'U',
            });
        });

        exceedDate.down.forEach((time) => {
            markers.push({
                time: parseDateString(time),
                position: 'belowBar',
                color: 'red',
                shape: 'arrowDown',
                text: 'D',
            });
        });

        markers.sort((a, b) => a.time - b.time);
        series.setMarkers(markers);

        return { chart, series };
    };

    useEffect(() => {
        if (flowData && !flowdaily.current._chart) {
            createIntradayPriceChart(flowdaily.current, flowData.priceData, flowData.exceedDate);
        }
    }, [flowData]);

    return (
        <div style={{ fontFamily: 'Arial, sans-serif', maxWidth: '1800px', padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            {/* <h2>Short term trading</h2> */}
            <div style={{ marginTop: 0, display: 'flex', flexDirection: 'row', gap: '10px' }}>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                    <div>
                        <label>Symbol:</label>
                        <input
                            value={symbol}
                            onChange={(e) => setSymbol(e.target.value)}
                            placeholder="Enter symbol"
                            style={{ marginLeft: '10px' }}
                        />
                    </div>
                    <div>
                        <label>Select date:</label>
                        <input
                            type="date"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                            style={{ marginLeft: '10px' }}
                        />
                    </div>
                    <Button
                        variant="contained"
                        fullWidth
                        sx={{
                            backgroundColor: colors.blueAccent[500],
                            width: "auto",
                            height: "auto"
                        }}
                        type="button"
                        onClick={handleFetchAndSearch}
                        disabled={!symbol || !date}
                    >
                        Search
                    </Button>
                </div>
                {loading && <p>Loading...</p>}
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', width: '100%', alignItems: 'flex-start' }}>
                <div style={{ flexBasis: '50%' }}>
                    <Box sx={{ marginTop: 0 }}>
                        <Preloader open={loading} />
                        <Grid>
                            <Grid display="flex" alignItems="center" justifyContent="center" sx={{ marginTop: 1 }}>
                            </Grid>
                            <Grid className='bidask-table'>
                                <Grid item md={6}>
                                    <Card fullWidth>
                                        <Grid>
                                            <TableContainer>
                                                <Table aria-label="simple table" className="change-tracking">
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell align="center">Vol BID</StyledTableCell>
                                                            <StyledTableCell align="center">BID</StyledTableCell>
                                                            <StyledTableCell align="center">ASK</StyledTableCell>
                                                            <StyledTableCell align="center">Vol ASK</StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {rows.map((row, index) => {
                                                            if (index < 10) {
                                                                return (
                                                                    <TableRow key={index}>
                                                                        <TableCell id={`cell-${index}-vol_bid`} align="center" style={{ padding: '4px', width: '30%' }}>{row.vol_bid}</TableCell>
                                                                        <TableCell id={`cell-${index}-bid`} align="center" style={{ padding: '4px', width: '20%' }}>{row.bid}</TableCell>
                                                                        <TableCell id={`cell-${index}-ask`} align="center" style={{ padding: '4px', width: '20%' }}>{row.ask}</TableCell>
                                                                        <TableCell id={`cell-${index}-vol_ask`} align="center" style={{ padding: '4px', width: '30%' }}>{row.vol_ask}</TableCell>
                                                                    </TableRow>
                                                                );
                                                            } if (index === 11) {
                                                                return (
                                                                    <TableRow key={index}>
                                                                        <StyledTableCell id={`cell-${index}-vol_bid`} align="center" style={{ padding: '4px' }}>{row.vol_bid}</StyledTableCell>
                                                                        <StyledTableCell id={`cell-${index}-bid`} align="center" style={{ padding: '4px' }}>{row.bid}</StyledTableCell>
                                                                        <StyledTableCell id={`cell-${index}-ask`} align="center" style={{ padding: '4px' }}>{row.ask}</StyledTableCell>
                                                                        <StyledTableCell id={`cell-${index}-vol_ask`} align="center" style={{ padding: '4px' }}>{row.vol_ask}</StyledTableCell>
                                                                    </TableRow>
                                                                )
                                                            }
                                                            else {
                                                                return (
                                                                    <TableRow key={index}>
                                                                        <TableCell id={`cell-${index}-vol_bid`} align="center" style={{ padding: '4px' }}>{row.vol_bid}</TableCell>
                                                                        <TableCell align="center" style={{ padding: '4px' }}>{row.bid}</TableCell>
                                                                        <TableCell align="center" style={{ padding: '4px' }}>{row.ask}</TableCell>
                                                                        <TableCell id={`cell-${index}-vol_ask`} align="center" style={{ padding: '4px' }}>{row.vol_ask}</TableCell>
                                                                    </TableRow>
                                                                )
                                                            }
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box >
                    <br />
                    <div
                        // ref={flowdaily}
                        style={{
                            position: 'relative',
                            height: '130px',
                            width: '100%',
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                        }}
                    >
                        <div style={{ position: "absolute", top: '2%', left: '2%', zIndex: 20, color: 'black' }}>Tickmatch</div>
                    </div>
                    <br />
                    <div
                        ref={flowdaily}
                        style={{
                            position: 'relative',
                            height: '130px',
                            width: '100%',
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                        }}
                    >
                        <div style={{ position: "absolute", top: '2%', left: '2%', zIndex: 20, color: 'black' }}>Flowdaily</div>
                    </div>
                </div>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'flex-end', marginTop: -31}}>
                    <Typography variant='h3' sx={{ fontWeight: 'bold', marginRight: '15rem' }}>{Time2Show}</Typography>
                    <div
                        ref={priceChartRef}
                        style={{
                            position: 'relative',
                            height: '200px',
                            width: '100%',
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                        }}
                    >
                        <div style={{ position: "absolute", top: '2%', left: '2%', zIndex: 20, color: 'black' }}>Day</div>
                    </div>
                    <div
                        ref={intradayNetChartRef}
                        style={{
                            position: 'relative',
                            height: '200px',
                            width: '100%',
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                        }}
                    >
                        <div style={{ position: "absolute", top: '2%', left: '2%', zIndex: 20, color: 'black' }}>Hourly Price</div>
                    </div>
                    <div
                        ref={intraday15MinChartRef}
                        style={{
                            position: 'relative',
                            height: '200px',
                            width: '100%',
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                        }}
                    >
                        <div style={{ position: "absolute", top: '2%', left: '2%', zIndex: 20, color: 'black' }}>15-Minute Price</div>
                    </div>
                    <Slider
                        aria-labelledby="continuous-slider"
                        style={{ marginTop: '20px', marginRight: '7rem', width: '50%' }}
                        defaultValue={1}
                        aria-label="Time Slider"
                        valueLabelDisplay="auto"
                        min={1}
                        max={bidaskLength}
                        value={countRecordBidAsk}
                        onChange={handleSliderChange}
                        valueLabelFormat={(value) => `${Time2Show}`}
                    />
                </div>
            </div>
        </div>
    );
};

export default ShortTermTrading;
