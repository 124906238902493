import React, { useEffect, useRef, useState } from 'react';
import { createChart } from 'lightweight-charts';
import { useSelector } from "react-redux";
import './Hisflow.css'; // Basic styling as needed
import { useIsMobile, useIsPortraitTablet, useIsTablet } from '../../components/hooks';

const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const earthToneColor = '#D2B48C';
const getOppositeColor = () => '#0d0082';

const formatDateString = (date) => {
  if (!(date instanceof Date) || isNaN(date)) return '';
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const adjustToLocalTime = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const timezoneOffset = date.getTimezoneOffset() * 60;
  return timestamp - timezoneOffset;
};

const ChartSectionWithMultipleLines = ({ title, market, type, selectedDate }) => {
  const chartContainerRef = useRef(null);
  const chartRef = useRef(null);

  const lineSeriesRef = useRef(new Map());
  const seriesDataRef = useRef(new Map());
  const originalColorsRef = useRef(new Map());

  const [tableData, setTableData] = useState([]);
  const [previousRanks, setPreviousRanks] = useState({});
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const csrfToken = useSelector((state) => state.csrfToken);

  const shouldInvert = (type === "out");
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isPTablet = useIsPortraitTablet();

  /** Main fetch function */
  const fetchHistoricalData = async () => {
    if (!selectedDate) {
      console.warn('No date selected, skipping fetch.');
      return;
    }

    const formattedDate = formatDateString(selectedDate);
    if (!formattedDate) {
      console.warn('Invalid date, skipping fetch.');
      return;
    }

    // e.g. /flow/hist/SET100&2024-03-12
    const url = `${window.config.base_api_url}/flow/hist/${market}&${formattedDate}`;
    let response;
    try {
      const resp = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          'xsrf-token': csrfToken,
        },
      });
      response = await resp.json();
    } catch (err) {
      console.error(`Network/JSON error fetching historical data for ${market}, date=${selectedDate}:`, err);
      return; // Don’t blow away the table entirely; user can see old data or a partial result
    }

    const flowData = response?.data?.[type];
    if (!flowData) {
      console.warn(`No data found for type=${type} in response`);
      setTableData([]); // If truly no data for that day, table is empty
      return;
    }

    // Gather all time slots that exist in both rank + value
    const allTimes = Object.keys(flowData.rank)
      .filter((t) => flowData.value[t])
      .sort();

    if (allTimes.length === 0) {
      console.warn("No valid times found for rank/value data");
      setTableData([]);
      return;
    }

    /** Build historicalData[symbol] = array of {time, rank, value} */
    const historicalData = {};

    for (const timeStr of allTimes) {
      const timestamp = Math.floor(new Date(timeStr).getTime() / 1000);
      const rankObj = flowData.rank[timeStr] || {};
      const valueObj = flowData.value[timeStr] || {};

      for (const symbol of Object.keys(rankObj)) {
        const rankVal = rankObj[symbol];
        const rawVal = valueObj[symbol];

        if (rankVal === undefined || rawVal === undefined) {
          // Just skip this symbol/time, don't crash the entire load
          console.warn(`Skipping ${symbol} at ${timeStr}: rank or value missing`);
          continue;
        }

        if (!historicalData[symbol]) {
          historicalData[symbol] = [];
        }

        const finalVal = shouldInvert ? -1 * rawVal : rawVal;
        historicalData[symbol].push({
          time: adjustToLocalTime(timestamp),
          rank: rankVal,
          value: finalVal,
        });
      }
    }

    /** Sort each symbol’s data by time */
    for (const symbol of Object.keys(historicalData)) {
      historicalData[symbol].sort((a, b) => a.time - b.time);
    }

    // Create or update chart series
    const symbolList = Object.keys(historicalData);
    const newPreviousRanks = {};

    for (const sym of symbolList) {
      const symData = historicalData[sym];
      if (!symData || symData.length === 0) {
        // Possibly remove an old series if it exists
        const existing = lineSeriesRef.current.get(sym);
        if (existing) {
          try {
            chartRef.current?.removeSeries(existing);
          } catch (e) {
            console.warn(`removeSeries error for symbol=${sym}`, e);
          }
          lineSeriesRef.current.delete(sym);
          seriesDataRef.current.delete(sym);
          originalColorsRef.current.delete(sym);
        }
        continue;
      }

      const latestPoint = symData[symData.length - 1];
      newPreviousRanks[sym] = latestPoint.rank;

      let series = lineSeriesRef.current.get(sym);
      if (!series) {
        // Add a new line series
        const color = getRandomColor();
        try {
          series = chartRef.current.addLineSeries({
            color,
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: false,
            title: sym,
          });
        } catch (e) {
          console.warn(`Error adding new line series for symbol=${sym}`, e);
          continue; // Don’t crash everything
        }
        lineSeriesRef.current.set(sym, series);
        originalColorsRef.current.set(sym, color);
      }

      // Set data on the series
      const seriesData = symData.map(dp => ({ time: dp.time, value: dp.value }));
      try {
        series.setData(seriesData);
      } catch (e) {
        console.warn(`Error setting data for symbol=${sym}`, e);
        // If it fails, remove the series so we don’t break chart next time
        chartRef.current?.removeSeries(series);
        lineSeriesRef.current.delete(sym);
        continue;
      }
      seriesDataRef.current.set(sym, seriesData);
    }

    // Remove leftover series for symbols not in symbolList
    const existingSymbols = Array.from(lineSeriesRef.current.keys());
    for (const sym of existingSymbols) {
      if (!symbolList.includes(sym)) {
        const s = lineSeriesRef.current.get(sym);
        if (s) {
          try {
            chartRef.current?.removeSeries(s);
          } catch (err) {
            console.warn(`removeSeries error for leftover symbol=${sym}`, err);
          }
        }
        lineSeriesRef.current.delete(sym);
        seriesDataRef.current.delete(sym);
        originalColorsRef.current.delete(sym);
      }
    }

    setPreviousRanks(newPreviousRanks);

    /** Build table data for each symbol’s final point */
    const updatedTableData = symbolList.map((symbol) => {
      const sData = historicalData[symbol];
      if (!sData || sData.length === 0) return null;
      const lastPoint = sData[sData.length - 1];
      return {
        rank: lastPoint.rank,
        symbol,
        value: lastPoint.value,
        color: 'transparent',
      };
    }).filter(Boolean);

    setTableData(updatedTableData);
  };

  useEffect(() => {
    // Initialize chart
    chartRef.current = createChart(chartContainerRef.current, {
      layout: { background: { type: 'solid', color: 'white' } },
      timeScale: { timeVisible: true, rightOffset: 10 },
      height: 290,
      width: chartContainerRef.current ? chartContainerRef.current.offsetWidth : 600,
      crosshair: {
        horizontalLine: { visible: false },
        verticalLine: { visible: true },
      },
    });

    // Invert scale for 'out'
    if (shouldInvert) {
      chartRef.current.applyOptions({ invertScale: true });
    }

    // Price format
    chartRef.current.applyOptions({
      localization: {
        priceFormatter: (price) => (price / 1_000_000).toLocaleString('en-US', { maximumFractionDigits: 2 }),
      },
      priceScale: { mode: 0 },
    });

    setTableData([]); // Clear table before fetch
    fetchHistoricalData();

    return () => {
      // Cleanup
      if (chartRef.current) {
        try {
          chartRef.current.remove();
        } catch (err) {
          // If already disposed, ignore
        }
      }
    };
  }, [selectedDate, market, type]);

  // Highlight on row click
  const handleRowClick = (symbol) => {
    if (symbol === selectedSymbol) {
      // Deselect
      setSelectedSymbol(null);
      lineSeriesRef.current.forEach((series, sym) => {
        const origColor = originalColorsRef.current.get(sym);
        series.applyOptions({ color: origColor, lineWidth: 2 });
      });
    } else {
      setSelectedSymbol(symbol);
      const chosenSeries = lineSeriesRef.current.get(symbol);
      if (!chosenSeries) return;

      const seriesData = seriesDataRef.current.get(symbol);
      // “Remove and re-add” to bring on top
      try {
        chartRef.current?.removeSeries(chosenSeries);
      } catch (err) {
        console.warn(`removeSeries error for highlight symbol=${symbol}`, err);
      }

      let newSeries;
      try {
        newSeries = chartRef.current.addLineSeries({
          color: chosenSeries.options().color,
          lineWidth: chosenSeries.options().lineWidth,
          lastValueVisible: true,
          priceLineVisible: false,
          title: symbol,
        });
        newSeries.setData(seriesData);
      } catch (err) {
        console.warn(`Error re-adding line series for highlight symbol=${symbol}`, err);
        return;
      }

      lineSeriesRef.current.set(symbol, newSeries);

      // Highlight chosen line
      newSeries.applyOptions({ color: getOppositeColor(), lineWidth: 4 });

      // Fade out others
      lineSeriesRef.current.forEach((s, otherSym) => {
        if (otherSym !== symbol) {
          s.applyOptions({ color: earthToneColor, lineWidth: 2 });
        }
      });
    }
  };

  // Keep chart size dynamic
  const [isCollapsed, setIsCollapsed] = useState(false);
  const toggleCollapse = () => setIsCollapsed(!isCollapsed);

  useEffect(() => {
    if (!chartContainerRef.current || !chartRef.current) return;
    const updateSize = () => {
      chartRef.current.resize(
        chartContainerRef.current.offsetWidth,
        chartContainerRef.current.offsetHeight
      );
    };
    updateSize();

    const ro = new ResizeObserver(() => requestAnimationFrame(updateSize));
    ro.observe(chartContainerRef.current);
    return () => ro.disconnect();
  }, [isCollapsed]);

  const renderTable = (start, end) => (
    <table style={{ fontSize: '11.5px', border: '1px solid #ddd', borderCollapse: 'collapse' }}>
      <thead>
        <tr style={{ backgroundColor: '#fdf5e6' }}>
          <th style={{ textAlign: 'center', padding: '3px' }}>Rank</th>
          <th style={{ textAlign: 'center', padding: '3px' }}>Symbol</th>
          <th style={{ textAlign: 'center', padding: '3px' }}>Value</th>
        </tr>
      </thead>
      <tbody>
        {tableData
          .sort((a, b) => a.rank - b.rank)
          .slice(start, end)
          .map((row, idx) => (
            <tr
              key={idx}
              style={{
                backgroundColor: row.color,
                cursor: 'pointer',
                fontWeight: row.symbol === selectedSymbol ? 'bold' : 'normal',
                transition: 'background-color 0.3s ease',
                borderBottom: '1px solid #ddd',
              }}
              onClick={() => handleRowClick(row.symbol)}
            >
              <td style={{ textAlign: 'center', padding: '3px' }}>{row.rank}</td>
              <td style={{ textAlign: 'center', padding: '3px' }}>{row.symbol}</td>
              <td style={{ textAlign: 'center', padding: '3px' }}>
                {(row.value / 1_000_000).toLocaleString('en-US', { maximumFractionDigits: 2 })}
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );

  return (
    <div className="chart-section" style={{ fontFamily: 'Arial, sans-serif' }}>
      <h3 style={{ display: 'flex', justifyContent: 'space-between' }}>
        {title}
        <button
          onClick={() => {
            const dateStr = formatDateString(selectedDate);
            const url = `/fullscreenhisrealflow?market=${market}&type=${type}&date=${dateStr}&title=${encodeURIComponent(title)}`;
            window.open(url, '_blank');
          }}
          className="expand-button"
        >
          🔍
        </button>
      </h3>
      <div style={{ display: 'flex', flexDirection: 'row', gap: isMobile ? '2vw' : '5px' }}>
        <div
          ref={chartContainerRef}
          // style={{ width: '60%', position: 'relative', border: '1px solid #ccc' }}
          style={{
            width: '60%',
            maxHeight: '300px',     // FIXED HEIGHT
            position: 'relative',
            border: '1px solid #ccc',
            overflow: 'hidden'
            }}
        />

        <div style={{
          width: '20%',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          overflowY: 'auto',
          maxHeight: '80vh',
        }}>
          {renderTable(0, 10)}
        </div>
        <div style={{
          width: '20%',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          overflowY: 'auto',
          maxHeight: '80vh',
        }}>
          {renderTable(10, 20)}
        </div>
      </div>
    </div>
  );
};

export default function Hisflow() {
  function getDefaultDate() {
    const d = new Date();
    d.setDate(d.getDate() - 1);
    if (d.getDay() === 6) d.setDate(d.getDate() - 1);
    else if (d.getDay() === 0) d.setDate(d.getDate() - 2);
    return d;
  }

  const [selectedDate, setSelectedDate] = useState(() => getDefaultDate());

  const datePickerStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    margin: '10px 0',
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
      <h1>RealFlow History</h1>
      <div style={datePickerStyle}>
        <label style={{ fontWeight: 'bold' }}>Select Date:</label>
        <input
          type="date"
          value={selectedDate.toISOString().split('T')[0]}
          onChange={(e) => setSelectedDate(new Date(e.target.value))}
          style={{ padding: '4px' }}
        />
      </div>

      <div style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '20px',
        width: '100%',
      }}>
        {/* SET100 & NON-SET100 => in */}
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <ChartSectionWithMultipleLines
            title="SET100 Hist In (+)"
            market="SET100"
            type="in"
            selectedDate={selectedDate}
          />
          <ChartSectionWithMultipleLines
            title="NON-SET100 Hist In (+)"
            market="NON-SET100"
            type="in"
            selectedDate={selectedDate}
          />
        </div>

        {/* SET100 & NON-SET100 => out */}
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <ChartSectionWithMultipleLines
            title="SET100 Hist Out (-)"
            market="SET100"
            type="out"
            selectedDate={selectedDate}
          />
          <ChartSectionWithMultipleLines
            title="NON-SET100 Hist Out (-)"
            market="NON-SET100"
            type="out"
            selectedDate={selectedDate}
          />
        </div>

        {/* MAI & WARRANT => in */}
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <ChartSectionWithMultipleLines
            title="MAI Hist In (+)"
            market="MAI"
            type="in"
            selectedDate={selectedDate}
          />
          <ChartSectionWithMultipleLines
            title="WARRANT Hist In (+)"
            market="WARRANT"
            type="in"
            selectedDate={selectedDate}
          />
        </div>

        {/* MAI & WARRANT => out */}
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <ChartSectionWithMultipleLines
            title="MAI Hist Out (-)"
            market="MAI"
            type="out"
            selectedDate={selectedDate}
          />
          <ChartSectionWithMultipleLines
            title="WARRANT Hist Out (-)"
            market="WARRANT"
            type="out"
            selectedDate={selectedDate}
          />
        </div>
      </div>
    </div>
  );
}
