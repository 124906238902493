import React, { useEffect, useRef, useState } from 'react';
import { createChart } from 'lightweight-charts';

const symbolData = {
  2020: {
    S50H20: {
      volumeData: [
        { date: '27/9/2562', callPut: 51, futurePrice: 1084.9 },
        { date: '30/9/2562', callPut: -15, futurePrice: 1081.8 },
        { date: '1/10/2562', callPut: 31, futurePrice: 1072.1 },
        { date: '2/10/2562', callPut: -60, futurePrice: 1064 },
        { date: '3/10/2562', callPut: -7, futurePrice: 1064.6 },
        { date: '4/10/2562', callPut: 33, futurePrice: 1061.9 },
        { date: '7/10/2562', callPut: -12, futurePrice: 1066.4 },
        { date: '8/10/2562', callPut: 24, futurePrice: 1064.8 },
        { date: '9/10/2562', callPut: 57, futurePrice: 1071.5 },
        { date: '10/10/2562', callPut: -6, futurePrice: 1063.4 },
        { date: '11/10/2562', callPut: -132, futurePrice: 1076.3 },
        { date: '15/10/2562', callPut: 72, futurePrice: 1079.8 },
        { date: '16/10/2562', callPut: 98, futurePrice: 1081 },
        { date: '17/10/2562', callPut: 0, futurePrice: 1084 },
        { date: '18/10/2562', callPut: -52, futurePrice: 1081.6 },
        { date: '21/10/2562', callPut: 55, futurePrice: 1075.1 },
        { date: '22/10/2562', callPut: 13, futurePrice: 1080.9 },
        { date: '24/10/2562', callPut: 103, futurePrice: 1078.1 },
        { date: '25/10/2562', callPut: 103, futurePrice: 1060 },
        { date: '28/10/2562', callPut: 119, futurePrice: 1062.8 },
        { date: '29/10/2562', callPut: -22, futurePrice: 1059.5 },
        { date: '30/10/2562', callPut: 252, futurePrice: 1068.9 },
        { date: '31/10/2562', callPut: 108, futurePrice: 1063.2 },
        { date: '1/11/2562', callPut: 57, futurePrice: 1062 },
        { date: '4/11/2562', callPut: 58, futurePrice: 1086.1 },
        { date: '5/11/2562', callPut: -40, futurePrice: 1090.5 },
        { date: '6/11/2562', callPut: -82, futurePrice: 1086.8 },
        { date: '7/11/2562', callPut: 32, futurePrice: 1097.9 },
        { date: '8/11/2562', callPut: -28, futurePrice: 1097 },
        { date: '11/11/2562', callPut: 56, futurePrice: 1088.6 },
        { date: '12/11/2562', callPut: 27, futurePrice: 1088.6 },
        { date: '13/11/2562', callPut: 280, futurePrice: 1083.3 },
        { date: '14/11/2562', callPut: 63, futurePrice: 1081.1 },
        { date: '15/11/2562', callPut: -369, futurePrice: 1079 },
        { date: '18/11/2562', callPut: -46, futurePrice: 1082.4 },
        { date: '19/11/2562', callPut: -25, futurePrice: 1080.8 },
        { date: '20/11/2562', callPut: -153, futurePrice: 1070.9 },
        { date: '21/11/2562', callPut: 150, futurePrice: 1069.7 },
        { date: '22/11/2562', callPut: -9, futurePrice: 1072 },
        { date: '25/11/2562', callPut: -22, futurePrice: 1081.1 },
        { date: '26/11/2562', callPut: -304, futurePrice: 1080 },
        { date: '27/11/2562', callPut: -4, futurePrice: 1082.4 },
        { date: '28/11/2562', callPut: -242, futurePrice: 1075.1 },
        { date: '29/11/2562', callPut: -72, futurePrice: 1068.8 },
        { date: '2/12/2562', callPut: 329, futurePrice: 1052.5 },
        { date: '3/12/2562', callPut: 272, futurePrice: 1050.1 },
        { date: '4/12/2562', callPut: 89, futurePrice: 1051.5 },
        { date: '6/12/2562', callPut: 320, futurePrice: 1044.9 },
        { date: '9/12/2562', callPut: 173, futurePrice: 1043.5 },
        { date: '11/12/2562', callPut: 91, futurePrice: 1045 },
        { date: '12/12/2562', callPut: 549, futurePrice: 1055.8 },
        { date: '13/12/2562', callPut: 920, futurePrice: 1066.4 },
        { date: '16/12/2562', callPut: 720, futurePrice: 1044.4 },
        { date: '17/12/2562', callPut: 761, futurePrice: 1048.9 },
        { date: '18/12/2562', callPut: 1253, futurePrice: 1058.3 },
        { date: '19/12/2562', callPut: 990, futurePrice: 1067.4 },
        { date: '20/12/2562', callPut: 450, futurePrice: 1062.4 },
        { date: '23/12/2562', callPut: 128, futurePrice: 1065.2 },
        { date: '24/12/2562', callPut: 158, futurePrice: 1060.7 },
        { date: '25/12/2562', callPut: 449, futurePrice: 1064.9 },
        { date: '26/12/2562', callPut: 535, futurePrice: 1067 },
        { date: '27/12/2562', callPut: 374, futurePrice: 1068.8 },
        { date: '30/12/2562', callPut: 423, futurePrice: 1066.9 },
        { date: '2/1/2563', callPut: 519, futurePrice: 1076.6 },
        { date: '3/1/2563', callPut: 56, futurePrice: 1075.4 },
        { date: '6/1/2563', callPut: 1637, futurePrice: 1059.2 },
        { date: '7/1/2563', callPut: 296, futurePrice: 1070.9 },
        { date: '8/1/2563', callPut: 683, futurePrice: 1054.2 },
        { date: '9/1/2563', callPut: 1940, futurePrice: 1068 },
        { date: '10/1/2563', callPut: 369, futurePrice: 1068.7 },
        { date: '13/1/2563', callPut: -823, futurePrice: 1071.8 },
        { date: '14/1/2563', callPut: 705, futurePrice: 1071.8 },
        { date: '15/1/2563', callPut: 170, futurePrice: 1064.5 },
        { date: '16/1/2563', callPut: 95, futurePrice: 1072.8 },
        { date: '17/1/2563', callPut: -159, futurePrice: 1077 },
        { date: '20/1/2563', callPut: 751, futurePrice: 1069.9 },
        { date: '21/1/2563', callPut: 793, futurePrice: 1060.5 },
        { date: '22/1/2563', callPut: 1061, futurePrice: 1060.5 },
        { date: '23/1/2563', callPut: 848, futurePrice: 1058.5 },
        { date: '24/1/2563', callPut: 195, futurePrice: 1055.3 },
        { date: '27/1/2563', callPut: 3042, futurePrice: 1017.3 },
        { date: '28/1/2563', callPut: 625, futurePrice: 1013.2 },
        { date: '29/1/2563', callPut: 560, futurePrice: 1024.1 },
        { date: '30/1/2563', callPut: 914, futurePrice: 1018.9 },
        { date: '31/1/2563', callPut: 394, futurePrice: 1013.2 },
        { date: '3/2/2563', callPut: 1613, futurePrice: 1001.4 },
        { date: '4/2/2563', callPut: 1111, futurePrice: 1017.9 },
        { date: '5/2/2563', callPut: 1045, futurePrice: 1027.3 },
        { date: '6/2/2563', callPut: 1201, futurePrice: 1024 },
        { date: '7/2/2563', callPut: 442, futurePrice: 1023.6 },
        { date: '11/2/2563', callPut: -58, futurePrice: 1017.7 },
        { date: '12/2/2563', callPut: 32, futurePrice: 1028.2 },
        { date: '13/2/2563', callPut: 222, futurePrice: 1023.9 },
        { date: '14/2/2563', callPut: 116, futurePrice: 1019.6 },
        { date: '17/2/2563', callPut: 165, futurePrice: 1020.2 },
        { date: '18/2/2563', callPut: -374, futurePrice: 1012.5 },
        { date: '19/2/2563', callPut: -480, futurePrice: 1010.8 },
        { date: '20/2/2563', callPut: -216, futurePrice: 995.9 },
        { date: '21/2/2563', callPut: 313, futurePrice: 1000.8 },
        { date: '24/2/2563', callPut: 698, futurePrice: 953.6 },
        { date: '25/2/2563', callPut: 803, futurePrice: 962.1 },
        { date: '26/2/2563', callPut: 2760, futurePrice: 918.9 },
        { date: '27/2/2563', callPut: 1650, futurePrice: 932.6 },
        { date: '28/2/2563', callPut: 1483, futurePrice: 894.3 },
        { date: '2/3/2563', callPut: 2030, futurePrice: 893.3 },
        { date: '3/3/2563', callPut: 661, futurePrice: 924.3 },
        { date: '4/3/2563', callPut: 3516, futurePrice: 927.4 },
        { date: '5/3/2563', callPut: 1326, futurePrice: 931.8 },
        { date: '6/3/2563', callPut: 124, futurePrice: 913.7 },
        { date: '9/3/2563', callPut: 1380, futurePrice: 829.8 },
        { date: '10/3/2563', callPut: 941, futurePrice: 843.3 },
        { date: '11/3/2563', callPut: 2357, futurePrice: 821.6 },
        { date: '12/3/2563', callPut: 3747, futurePrice: 722.6 },
        { date: '13/3/2563', callPut: 2871, futurePrice: 745.2 },
        { date: '16/3/2563', callPut: 4684, futurePrice: 681.3 },
        { date: '17/3/2563', callPut: 2808, futurePrice: 676.5 },
        { date: '18/3/2563', callPut: 1578, futurePrice: 692.6 },
        { date: '19/3/2563', callPut: 991, futurePrice: 688.5 },
        { date: '20/3/2563', callPut: 2069, futurePrice: 757.4 },
        { date: '23/3/2563', callPut: 391, futurePrice: 672 },
        { date: '24/3/2563', callPut: 1804.8000000000002, futurePrice: 696.1 },
        { date: '25/3/2563', callPut: 801.3, futurePrice: 723.9 },
        { date: '26/3/2563', callPut: 340.29999999999995, futurePrice: 737.7 },
        { date: '27/3/2563', callPut: 511.79999999999995, futurePrice: 739.4 },
        { date: '30/3/2563', callPut: 795.3499999999999, futurePrice: 731.35 },
        { date: '31/3/2563', callPut: 0, futurePrice: 0 },
        { date: '1/4/2563', callPut: 0, futurePrice: 0 },
      ],
      openInterestData: [
        { date: '27/9/2562', OI: 51, futurePrice: 1084.9 },
        { date: '30/9/2562', OI: 36, futurePrice: 1081.8 },
        { date: '1/10/2562', OI: 52, futurePrice: 1072.1 },
        { date: '2/10/2562', OI: 28, futurePrice: 1064 },
        { date: '3/10/2562', OI: 10, futurePrice: 1064.6 },
        { date: '4/10/2562', OI: 66, futurePrice: 1061.9 },
        { date: '7/10/2562', OI: 52, futurePrice: 1066.4 },
        { date: '8/10/2562', OI: 82, futurePrice: 1064.8 },
        { date: '9/10/2562', OI: 150, futurePrice: 1071.5 },
        { date: '10/10/2562', OI: 132, futurePrice: 1063.4 },
        { date: '11/10/2562', OI: 110, futurePrice: 1076.3 },
        { date: '15/10/2562', OI: 137, futurePrice: 1079.8 },
        { date: '16/10/2562', OI: 232, futurePrice: 1081 },
        { date: '17/10/2562', OI: 222, futurePrice: 1084 },
        { date: '18/10/2562', OI: 232, futurePrice: 1081.6 },
        { date: '21/10/2562', OI: 194, futurePrice: 1075.1 },
        { date: '22/10/2562', OI: 229, futurePrice: 1080.9 },
        { date: '24/10/2562', OI: 297, futurePrice: 1078.1 },
        { date: '25/10/2562', OI: 193, futurePrice: 1060 },
        { date: '28/10/2562', OI: 268, futurePrice: 1062.8 },
        { date: '29/10/2562', OI: 239, futurePrice: 1059.5 },
        { date: '30/10/2562', OI: 446, futurePrice: 1068.9 },
        { date: '31/10/2562', OI: 395, futurePrice: 1063.2 },
        { date: '1/11/2562', OI: 437, futurePrice: 1062 },
        { date: '4/11/2562', OI: 532, futurePrice: 1086.1 },
        { date: '5/11/2562', OI: 365, futurePrice: 1090.5 },
        { date: '6/11/2562', OI: 354, futurePrice: 1086.8 },
        { date: '7/11/2562', OI: 446, futurePrice: 1097.9 },
        { date: '8/11/2562', OI: 468, futurePrice: 1097 },
        { date: '11/11/2562', OI: 484, futurePrice: 1088.6 },
        { date: '12/11/2562', OI: 411, futurePrice: 1088.6 },
        { date: '13/11/2562', OI: 467, futurePrice: 1083.3 },
        { date: '14/11/2562', OI: 362, futurePrice: 1081.1 },
        { date: '15/11/2562', OI: -18, futurePrice: 1079 },
        { date: '18/11/2562', OI: -48, futurePrice: 1082.4 },
        { date: '19/11/2562', OI: -70, futurePrice: 1080.8 },
        { date: '20/11/2562', OI: -134, futurePrice: 1070.9 },
        { date: '21/11/2562', OI: -8, futurePrice: 1069.7 },
        { date: '22/11/2562', OI: -59, futurePrice: 1072 },
        { date: '25/11/2562', OI: 7, futurePrice: 1081.1 },
        { date: '26/11/2562', OI: -221, futurePrice: 1080 },
        { date: '27/11/2562', OI: -224, futurePrice: 1082.4 },
        { date: '28/11/2562', OI: -475, futurePrice: 1075.1 },
        { date: '29/11/2562', OI: -662, futurePrice: 1068.8 },
        { date: '2/12/2562', OI: -797, futurePrice: 1052.5 },
        { date: '3/12/2562', OI: -835, futurePrice: 1050.1 },
        { date: '4/12/2562', OI: -874, futurePrice: 1051.5 },
        { date: '6/12/2562', OI: -892, futurePrice: 1044.9 },
        { date: '9/12/2562', OI: -858, futurePrice: 1043.5 },
        { date: '11/12/2562', OI: -940, futurePrice: 1045 },
        { date: '12/12/2562', OI: -978, futurePrice: 1055.8 },
        { date: '13/12/2562', OI: -607, futurePrice: 1066.4 },
        { date: '16/12/2562', OI: -788, futurePrice: 1044.4 },
        { date: '17/12/2562', OI: -386, futurePrice: 1048.9 },
        { date: '18/12/2562', OI: 124, futurePrice: 1058.3 },
        { date: '19/12/2562', OI: 390, futurePrice: 1067.4 },
        { date: '20/12/2562', OI: 483, futurePrice: 1062.4 },
        { date: '23/12/2562', OI: 423, futurePrice: 1065.2 },
        { date: '24/12/2562', OI: 298, futurePrice: 1060.7 },
        { date: '25/12/2562', OI: 283, futurePrice: 1064.9 },
        { date: '26/12/2562', OI: 428, futurePrice: 1067 },
        { date: '27/12/2562', OI: 295, futurePrice: 1068.8 },
        { date: '30/12/2562', OI: 515, futurePrice: 1066.9 },
        { date: '2/1/2563', OI: 112, futurePrice: 1076.6 },
        { date: '3/1/2563', OI: -339, futurePrice: 1075.4 },
        { date: '6/1/2563', OI: -781, futurePrice: 1059.2 },
        { date: '7/1/2563', OI: -1137, futurePrice: 1070.9 },
        { date: '8/1/2563', OI: -1723, futurePrice: 1054.2 },
        { date: '9/1/2563', OI: -1641, futurePrice: 1068 },
        { date: '10/1/2563', OI: -1864, futurePrice: 1068.7 },
        { date: '13/1/2563', OI: -1967, futurePrice: 1071.8 },
        { date: '14/1/2563', OI: -2423, futurePrice: 1071.8 },
        { date: '15/1/2563', OI: -2612, futurePrice: 1064.5 },
        { date: '16/1/2563', OI: -2344, futurePrice: 1072.8 },
        { date: '17/1/2563', OI: -2948, futurePrice: 1077 },
        { date: '20/1/2563', OI: -3038, futurePrice: 1069.9 },
        { date: '21/1/2563', OI: -3203, futurePrice: 1060.5 },
        { date: '22/1/2563', OI: -3287, futurePrice: 1060.5 },
        { date: '23/1/2563', OI: -3593, futurePrice: 1058.5 },
        { date: '24/1/2563', OI: -3425, futurePrice: 1055.3 },
        { date: '27/1/2563', OI: -3744, futurePrice: 1017.3 },
        { date: '28/1/2563', OI: -3212, futurePrice: 1013.2 },
        { date: '29/1/2563', OI: -2841, futurePrice: 1024.1 },
        { date: '30/1/2563', OI: -3045, futurePrice: 1018.9 },
        { date: '31/1/2563', OI: -3661, futurePrice: 1013.2 },
        { date: '3/2/2563', OI: -3631, futurePrice: 1001.4 },
        { date: '4/2/2563', OI: -3883, futurePrice: 1017.9 },
        { date: '5/2/2563', OI: -4151, futurePrice: 1027.3 },
        { date: '6/2/2563', OI: -4325, futurePrice: 1024 },
        { date: '7/2/2563', OI: -5010, futurePrice: 1023.6 },
        { date: '11/2/2563', OI: -4958, futurePrice: 1017.7 },
        { date: '12/2/2563', OI: -5054, futurePrice: 1028.2 },
        { date: '13/2/2563', OI: -5363, futurePrice: 1023.9 },
        { date: '14/2/2563', OI: -5665, futurePrice: 1019.6 },
        { date: '17/2/2563', OI: -5620, futurePrice: 1020.2 },
        { date: '18/2/2563', OI: -6577, futurePrice: 1012.5 },
        { date: '19/2/2563', OI: -7084, futurePrice: 1010.8 },
        { date: '20/2/2563', OI: -7350, futurePrice: 995.9 },
        { date: '21/2/2563', OI: -7846, futurePrice: 1000.8 },
        { date: '24/2/2563', OI: -4519, futurePrice: 953.6 },
        { date: '25/2/2563', OI: -4155, futurePrice: 962.1 },
        { date: '26/2/2563', OI: -1863, futurePrice: 918.9 },
        { date: '27/2/2563', OI: -1585, futurePrice: 932.6 },
        { date: '28/2/2563', OI: -864, futurePrice: 894.3 },
        { date: '2/3/2563', OI: 108, futurePrice: 893.3 },
        { date: '3/3/2563', OI: -1371, futurePrice: 924.3 },
        { date: '4/3/2563', OI: -2510, futurePrice: 927.4 },
        { date: '5/3/2563', OI: -4157, futurePrice: 931.8 },
        { date: '6/3/2563', OI: -4321, futurePrice: 913.7 },
        { date: '9/3/2563', OI: -1027, futurePrice: 829.8 },
        { date: '10/3/2563', OI: -1658, futurePrice: 843.3 },
        { date: '11/3/2563', OI: -1661, futurePrice: 821.6 },
        { date: '12/3/2563', OI: 1624, futurePrice: 722.6 },
        { date: '13/3/2563', OI: 2157, futurePrice: 745.2 },
        { date: '16/3/2563', OI: 1120, futurePrice: 681.3 },
        { date: '17/3/2563', OI: 637, futurePrice: 676.5 },
        { date: '18/3/2563', OI: 591, futurePrice: 692.6 },
        { date: '19/3/2563', OI: 541, futurePrice: 688.5 },
        { date: '20/3/2563', OI: -289, futurePrice: 757.4 },
        { date: '23/3/2563', OI: 1146, futurePrice: 672 },
        { date: '24/3/2563', OI: 962, futurePrice: 696.1 },
        { date: '25/3/2563', OI: 655, futurePrice: 723.9 },
        { date: '26/3/2563', OI: 342, futurePrice: 737.7 },
        { date: '27/3/2563', OI: -218, futurePrice: 739.4 },
        { date: '30/3/2563', OI: 0, futurePrice: 731.35 },
        { date: '31/3/2563', OI: 0, futurePrice: 0 },
        { date: '1/4/2563', OI: 0, futurePrice: 0 },
      ]
    },
    S50M20: {
      volumeData: [
        { date: '27/12/2562', callPut: 54, futurePrice: 1064.6 },
        { date: '30/12/2562', callPut: 83, futurePrice: 1063.1 },
        { date: '2/1/2563', callPut: 74, futurePrice: 1071.7 },
        { date: '3/1/2563', callPut: 78, futurePrice: 1070.1 },
        { date: '6/1/2563', callPut: 248, futurePrice: 1055 },
        { date: '7/1/2563', callPut: 49, futurePrice: 1066.2 },
        { date: '8/1/2563', callPut: -1, futurePrice: 1050.1 },
        { date: '9/1/2563', callPut: 38, futurePrice: 1063.3 },
        { date: '10/1/2563', callPut: -43, futurePrice: 1063.8 },
        { date: '13/1/2563', callPut: 52, futurePrice: 1067.1 },
        { date: '14/1/2563', callPut: 25, futurePrice: 1067.2 },
        { date: '15/1/2563', callPut: 27, futurePrice: 1060.3 },
        { date: '16/1/2563', callPut: 148, futurePrice: 1068.1 },
        { date: '17/1/2563', callPut: -35, futurePrice: 1072 },
        { date: '20/1/2563', callPut: -122, futurePrice: 1065.7 },
        { date: '21/1/2563', callPut: -63, futurePrice: 1056.4 },
        { date: '22/1/2563', callPut: -6, futurePrice: 1056.7 },
        { date: '23/1/2563', callPut: -41, futurePrice: 1054.5 },
        { date: '24/1/2563', callPut: -56, futurePrice: 1051.6 },
        { date: '27/1/2563', callPut: 281, futurePrice: 1013.7 },
        { date: '28/1/2563', callPut: 253, futurePrice: 1009.1 },
        { date: '29/1/2563', callPut: -77, futurePrice: 1020 },
        { date: '30/1/2563', callPut: -21, futurePrice: 1015.5 },
        { date: '31/1/2563', callPut: -32, futurePrice: 1009.8 },
        { date: '3/2/2563', callPut: -112, futurePrice: 997.8 },
        { date: '4/2/2563', callPut: 26, futurePrice: 1013.7 },
        { date: '5/2/2563', callPut: -82, futurePrice: 1022.8 },
        { date: '6/2/2563', callPut: 132, futurePrice: 1019.5 },
        { date: '7/2/2563', callPut: -20, futurePrice: 1019 },
        { date: '11/2/2563', callPut: 25, futurePrice: 1013.4 },
        { date: '12/2/2563', callPut: 91, futurePrice: 1023.6 },
        { date: '13/2/2563', callPut: 106, futurePrice: 1019.5 },
        { date: '14/2/2563', callPut: 43, futurePrice: 1015.5 },
        { date: '17/2/2563', callPut: 121, futurePrice: 1016 },
        { date: '18/2/2563', callPut: 16, futurePrice: 1008.2 },
        { date: '19/2/2563', callPut: 0, futurePrice: 1005.9 },
        { date: '20/2/2563', callPut: 126, futurePrice: 991.8 },
        { date: '21/2/2563', callPut: -39, futurePrice: 995.9 },
        { date: '24/2/2563', callPut: 523, futurePrice: 948.3 },
        { date: '25/2/2563', callPut: -225, futurePrice: 956.7 },
        { date: '26/2/2563', callPut: -226, futurePrice: 912.8 },
        { date: '27/2/2563', callPut: 464, futurePrice: 926.3 },
        { date: '28/2/2563', callPut: 521, futurePrice: 888.3 },
        { date: '2/3/2563', callPut: 240, futurePrice: 887.3 },
        { date: '3/3/2563', callPut: 1056, futurePrice: 917.9 },
        { date: '4/3/2563', callPut: 546, futurePrice: 920.6 },
        { date: '5/3/2563', callPut: -26, futurePrice: 924.6 },
        { date: '6/3/2563', callPut: -305, futurePrice: 906.9 },
        { date: '9/3/2563', callPut: 1055, futurePrice: 822.3 },
        { date: '10/3/2563', callPut: 189, futurePrice: 835.1 },
        { date: '11/3/2563', callPut: 1006, futurePrice: 813.6 },
        { date: '12/3/2563', callPut: 747, futurePrice: 715.2 },
        { date: '13/3/2563', callPut: 882, futurePrice: 738.4 },
        { date: '16/3/2563', callPut: 1216, futurePrice: 673.5 },
        { date: '17/3/2563', callPut: 572, futurePrice: 668.5 },
        { date: '18/3/2563', callPut: 404, futurePrice: 684.4 },
        { date: '19/3/2563', callPut: 473, futurePrice: 681.3 },
        { date: '20/3/2563', callPut: 1123, futurePrice: 750.1 },
        { date: '23/3/2563', callPut: 372, futurePrice: 664 },
        { date: '24/3/2563', callPut: 165, futurePrice: 688.1 },
        { date: '25/3/2563', callPut: 428, futurePrice: 717.5 },
        { date: '26/3/2563', callPut: 175, futurePrice: 729.2 },
        { date: '27/3/2563', callPut: 309, futurePrice: 731.6 },
        { date: '30/3/2563', callPut: 231, futurePrice: 722 },
        { date: '31/3/2563', callPut: 752, futurePrice: 742.9 },
        { date: '1/4/2563', callPut: 1022, futurePrice: 731.1 },
        { date: '2/4/2563', callPut: 1308, futurePrice: 757.8 },
        { date: '3/4/2563', callPut: 664, futurePrice: 761.7 },
        { date: '7/4/2563', callPut: 2287, futurePrice: 822.2 },
        { date: '8/4/2563', callPut: 378, futurePrice: 812.5 },
        { date: '9/4/2563', callPut: 1166, futurePrice: 813.9 },
        { date: '10/4/2563', callPut: 653, futurePrice: 826.3 },
        { date: '13/4/2563', callPut: -73, futurePrice: 827.2 },
        { date: '14/4/2563', callPut: 136, futurePrice: 841.1 },
        { date: '15/4/2563', callPut: -172, futurePrice: 824 },
        { date: '16/4/2563', callPut: -295, futurePrice: 797.7 },
        { date: '17/4/2563', callPut: -445, futurePrice: 834.6 },
        { date: '20/4/2563', callPut: -188, futurePrice: 850.9 },
        { date: '21/4/2563', callPut: -696, futurePrice: 835.5 },
        { date: '22/4/2563', callPut: -777, futurePrice: 841.4 },
        { date: '23/4/2563', callPut: -64, futurePrice: 850.6 },
        { date: '24/4/2563', callPut: -319, futurePrice: 840.5 },
        { date: '27/4/2563', callPut: -366, futurePrice: 845.5 },
        { date: '28/4/2563', callPut: -122, futurePrice: 854.4 },
        { date: '29/4/2563', callPut: -295, futurePrice: 857.7 },
        { date: '30/4/2563', callPut: -438, futurePrice: 872.5 },
        { date: '5/5/2563', callPut: -1923, futurePrice: 853.3 },
        { date: '7/5/2563', callPut: -2103, futurePrice: 841.9 },
        { date: '8/5/2563', callPut: -17, futurePrice: 845.3 },
        { date: '11/5/2563', callPut: -385, futurePrice: 859.4 },
        { date: '12/5/2563', callPut: -383, futurePrice: 867.6 },
        { date: '13/5/2563', callPut: -662, futurePrice: 864 },
        { date: '14/5/2563', callPut: -205, futurePrice: 855.1 },
        { date: '15/5/2563', callPut: -258, futurePrice: 846.1 },
        { date: '18/5/2563', callPut: -232, futurePrice: 858.5 },
        { date: '19/5/2563', callPut: -877, futurePrice: 875.2 },
        { date: '20/5/2563', callPut: -353, futurePrice: 882.8 },
        { date: '21/5/2563', callPut: -1008, futurePrice: 878.6 },
        { date: '22/5/2563', callPut: -1032, futurePrice: 861.5 },
        { date: '25/5/2563', callPut: -262, futurePrice: 876.2 },
        { date: '26/5/2563', callPut: -440, futurePrice: 886.8 },
        { date: '27/5/2563', callPut: -285, futurePrice: 894.8 },
        { date: '28/5/2563', callPut: -672, futurePrice: 882.4 },
        { date: '29/5/2563', callPut: -718, futurePrice: 892.4 },
        { date: '1/6/2563', callPut: 390, futurePrice: 900.5 },
        { date: '2/6/2563', callPut: -79, futurePrice: 918.5 },
        { date: '4/6/2563', callPut: 1207, futurePrice: 945.8 },
        { date: '5/6/2563', callPut: 328, futurePrice: 962.2 },
        { date: '8/6/2563', callPut: 914, futurePrice: 964.1 },
        { date: '9/6/2563', callPut: -411, futurePrice: 939.3 },
        { date: '10/6/2563', callPut: 14, futurePrice: 950.6 },
        { date: '11/6/2563', callPut: -935, futurePrice: 931.1 },
        { date: '12/6/2563', callPut: -1754, futurePrice: 917.1 },
        { date: '15/6/2563', callPut: -825, futurePrice: 891.7 },
        { date: '16/6/2563', callPut: 1772, futurePrice: 912.2 },
        { date: '17/6/2563', callPut: -657, futurePrice: 908.6 },
        { date: '18/6/2563', callPut: -360, futurePrice: 909.4 },
        { date: '19/6/2563', callPut: -25, futurePrice: 910 },
        { date: '22/6/2563', callPut: -221, futurePrice: 896.8 },
        { date: '23/6/2563', callPut: -519, futurePrice: 896.8 },
        { date: '24/6/2563', callPut: -398, futurePrice: 878.9 },
        { date: '25/6/2563', callPut: -1369, futurePrice: 871.6 },
        { date: '26/6/2563', callPut: -81, futurePrice: 878.4 },
        { date: '29/6/2563', callPut: -1214, futurePrice: 875.35 },
        { date: '30/6/2563', callPut: 0, futurePrice: 0 },
        { date: '1/7/2563', callPut: 0, futurePrice: 0 },
      ],
      openInterestData: [
        { date: '27/12/2019', OI: 55, futurePrice: 1064.6 },
        { date: '30/12/2019', OI: 145, futurePrice: 1063.1 },
        { date: '2/1/2020', OI: 238, futurePrice: 1071.7 },
        { date: '3/1/2020', OI: 319, futurePrice: 1070.1 },
        { date: '6/1/2020', OI: 527, futurePrice: 1055 },
        { date: '7/1/2020', OI: 503, futurePrice: 1066.2 },
        { date: '8/1/2020', OI: 465, futurePrice: 1050.1 },
        { date: '9/1/2020', OI: 434, futurePrice: 1063.3 },
        { date: '10/1/2020', OI: 426, futurePrice: 1063.8 },
        { date: '13/1/2020', OI: 442, futurePrice: 1067.1 },
        { date: '14/1/2020', OI: 450, futurePrice: 1067.2 },
        { date: '15/1/2020', OI: 451, futurePrice: 1060.3 },
        { date: '16/1/2020', OI: 558, futurePrice: 1068.1 },
        { date: '17/1/2020', OI: 592, futurePrice: 1072 },
        { date: '20/1/2020', OI: 441, futurePrice: 1065.7 },
        { date: '21/1/2020', OI: 360, futurePrice: 1056.4 },
        { date: '22/1/2020', OI: 357, futurePrice: 1056.7 },
        { date: '23/1/2020', OI: 300, futurePrice: 1054.5 },
        { date: '24/1/2020', OI: 220, futurePrice: 1051.6 },
        { date: '27/1/2020', OI: 232, futurePrice: 1013.7 },
        { date: '28/1/2020', OI: 232, futurePrice: 1009.1 },
        { date: '29/1/2020', OI: 138, futurePrice: 1020 },
        { date: '30/1/2020', OI: 164, futurePrice: 1015.5 },
        { date: '31/1/2020', OI: 162, futurePrice: 1009.8 },
        { date: '3/2/2020', OI: 140, futurePrice: 997.8 },
        { date: '4/2/2020', OI: 47, futurePrice: 1013.7 },
        { date: '5/2/2020', OI: -254, futurePrice: 1022.8 },
        { date: '6/2/2020', OI: -249, futurePrice: 1019.5 },
        { date: '7/2/2020', OI: -239, futurePrice: 1019 },
        { date: '11/2/2020', OI: -221, futurePrice: 1013.4 },
        { date: '12/2/2020', OI: -245, futurePrice: 1023.6 },
        { date: '13/2/2020', OI: -237, futurePrice: 1019.5 },
        { date: '14/2/2020', OI: -195, futurePrice: 1015.5 },
        { date: '17/2/2020', OI: -120, futurePrice: 1016 },
        { date: '18/2/2020', OI: 3, futurePrice: 1008.2 },
        { date: '19/2/2020', OI: -11, futurePrice: 1005.9 },
        { date: '20/2/2020', OI: -207, futurePrice: 991.8 },
        { date: '21/2/2020', OI: -266, futurePrice: 995.9 },
        { date: '24/2/2020', OI: -243, futurePrice: 948.3 },
        { date: '25/2/2020', OI: -348, futurePrice: 956.7 },
        { date: '26/2/2020', OI: -429, futurePrice: 912.8 },
        { date: '27/2/2020', OI: -383, futurePrice: 926.3 },
        { date: '28/2/2020', OI: -205, futurePrice: 888.3 },
        { date: '2/3/2020', OI: -224, futurePrice: 887.3 },
        { date: '3/3/2020', OI: -5, futurePrice: 917.9 },
        { date: '4/3/2020', OI: 26, futurePrice: 920.6 },
        { date: '5/3/2020', OI: -107, futurePrice: 924.6 },
        { date: '6/3/2020', OI: -614, futurePrice: 906.9 },
        { date: '9/3/2020', OI: -413, futurePrice: 822.3 },
        { date: '10/3/2020', OI: -290, futurePrice: 835.1 },
        { date: '11/3/2020', OI: -164, futurePrice: 813.6 },
        { date: '12/3/2020', OI: 177, futurePrice: 715.2 },
        { date: '13/3/2020', OI: 210, futurePrice: 738.4 },
        { date: '16/3/2020', OI: 710, futurePrice: 673.5 },
        { date: '17/3/2020', OI: 845, futurePrice: 668.5 },
        { date: '18/3/2020', OI: 1088, futurePrice: 684.4 },
        { date: '19/3/2020', OI: 1099, futurePrice: 681.3 },
        { date: '20/3/2020', OI: 862, futurePrice: 750.1 },
        { date: '23/3/2020', OI: 657, futurePrice: 664 },
        { date: '24/3/2020', OI: 628, futurePrice: 688.1 },
        { date: '25/3/2020', OI: 546, futurePrice: 717.5 },
        { date: '26/3/2020', OI: 451, futurePrice: 729.2 },
        { date: '27/3/2020', OI: 354, futurePrice: 731.6 },
        { date: '30/3/2020', OI: 299, futurePrice: 722 },
        { date: '31/3/2020', OI: 366, futurePrice: 742.9 },
        { date: '1/4/2020', OI: 260, futurePrice: 731.1 },
        { date: '2/4/2020', OI: 112, futurePrice: 757.8 },
        { date: '3/4/2020', OI: 202, futurePrice: 761.7 },
        { date: '7/4/2020', OI: -310, futurePrice: 822.2 },
        { date: '8/4/2020', OI: -625, futurePrice: 812.5 },
        { date: '9/4/2020', OI: -1000, futurePrice: 813.9 },
        { date: '10/4/2020', OI: -1277, futurePrice: 826.3 },
        { date: '13/4/2020', OI: -1719, futurePrice: 827.2 },
        { date: '14/4/2020', OI: -2229, futurePrice: 841.1 },
        { date: '15/4/2020', OI: -2584, futurePrice: 824 },
        { date: '16/4/2020', OI: -2789, futurePrice: 797.7 },
        { date: '17/4/2020', OI: -3113, futurePrice: 834.6 },
        { date: '20/4/2020', OI: -3544, futurePrice: 850.9 },
        { date: '21/4/2020', OI: -4036, futurePrice: 835.5 },
        { date: '22/4/2020', OI: -4271, futurePrice: 841.4 },
        { date: '23/4/2020', OI: -4432, futurePrice: 850.6 },
        { date: '24/4/2020', OI: -4559, futurePrice: 840.5 },
        { date: '27/4/2020', OI: -4744, futurePrice: 845.5 },
        { date: '28/4/2020', OI: -4792, futurePrice: 854.4 },
        { date: '29/4/2020', OI: -4983, futurePrice: 857.7 },
        { date: '30/4/2020', OI: -5499, futurePrice: 872.5 },
        { date: '5/5/2020', OI: -6614, futurePrice: 853.3 },
        { date: '7/5/2020', OI: -8061, futurePrice: 841.9 },
        { date: '8/5/2020', OI: -8380, futurePrice: 845.3 },
        { date: '11/5/2020', OI: -8731, futurePrice: 859.4 },
        { date: '12/5/2020', OI: -9043, futurePrice: 867.6 },
        { date: '13/5/2020', OI: -9589, futurePrice: 864 },
        { date: '14/5/2020', OI: -9882, futurePrice: 855.1 },
        { date: '15/5/2020', OI: -10054, futurePrice: 846.1 },
        { date: '18/5/2020', OI: -10242, futurePrice: 858.5 },
        { date: '19/5/2020', OI: -10793, futurePrice: 875.2 },
        { date: '20/5/2020', OI: -11482, futurePrice: 882.8 },
        { date: '21/5/2020', OI: -12384, futurePrice: 878.6 },
        { date: '22/5/2020', OI: -12982, futurePrice: 861.5 },
        { date: '25/5/2020', OI: -13578, futurePrice: 876.2 },
        { date: '26/5/2020', OI: -14633, futurePrice: 886.8 },
        { date: '27/5/2020', OI: -15534, futurePrice: 894.8 },
        { date: '28/5/2020', OI: -16223, futurePrice: 882.4 },
        { date: '29/5/2020', OI: -17182, futurePrice: 892.4 },
        { date: '1/6/2020', OI: -17623, futurePrice: 900.5 },
        { date: '2/6/2020', OI: -17940, futurePrice: 918.5 },
        { date: '4/6/2020', OI: -17400, futurePrice: 945.8 },
        { date: '5/6/2020', OI: -18034, futurePrice: 962.2 },
        { date: '8/6/2020', OI: -18078, futurePrice: 964.1 },
        { date: '9/6/2020', OI: -17964, futurePrice: 939.3 },
        { date: '10/6/2020', OI: -18006, futurePrice: 950.6 },
        { date: '11/6/2020', OI: -18392, futurePrice: 931.1 },
        { date: '12/6/2020', OI: -18313, futurePrice: 917.1 },
        { date: '15/6/2020', OI: -17853, futurePrice: 891.7 },
        { date: '16/6/2020', OI: -17980, futurePrice: 912.2 },
        { date: '17/6/2020', OI: -18207, futurePrice: 908.6 },
        { date: '18/6/2020', OI: -18559, futurePrice: 909.4 },
        { date: '19/6/2020', OI: -18664, futurePrice: 910 },
        { date: '22/6/2020', OI: -18586, futurePrice: 896.8 },
        { date: '23/6/2020', OI: -18525, futurePrice: 896.8 },
        { date: '24/6/2020', OI: -18167, futurePrice: 878.9 },
        { date: '25/6/2020', OI: -17821, futurePrice: 871.6 },
        { date: '26/6/2020', OI: -18065, futurePrice: 878.4 },
        { date: '29/6/2020', OI: 0, futurePrice: 875.35 },
        { date: '30/6/2020', OI: 0, futurePrice: 0 },
        { date: '1/7/2020', OI: 0, futurePrice: 0 },
      ]
    },
  },
  2022: {
    S50H22: {
      volumeData: [
        { date: '29/9/2021', callPut: -5, futurePrice: 967.9 },
        { date: '30/9/2021', callPut: 102, futurePrice: 958.7 },
        { date: '1/10/2021', callPut: -597, futurePrice: 956 },
        { date: '4/10/2021', callPut: -126, futurePrice: 962.6 },
        { date: '5/10/2021', callPut: -48, futurePrice: 970 },
        { date: '6/10/2021', callPut: 95, futurePrice: 961.8 },
        { date: '7/10/2021', callPut: -169, futurePrice: 974.7 },
        { date: '8/10/2021', callPut: -89, futurePrice: 978.3 },
        { date: '11/10/2021', callPut: -62, futurePrice: 976.4 },
        { date: '12/10/2021', callPut: -71, futurePrice: 982.7 },
        { date: '14/10/2021', callPut: -423, futurePrice: 982.5 },
        { date: '15/10/2021', callPut: -15, futurePrice: 981.6 },
        { date: '18/10/2021', callPut: -220, futurePrice: 985.8 },
        { date: '19/10/2021', callPut: -216, futurePrice: 977.2 },
        { date: '20/10/2021', callPut: -423, futurePrice: 978.5 },
        { date: '21/10/2021', callPut: -239, futurePrice: 983.4 },
        { date: '25/10/2021', callPut: -512, futurePrice: 975.7 },
        { date: '26/10/2021', callPut: -259, futurePrice: 979.2 },
        { date: '27/10/2021', callPut: -333, futurePrice: 971.4 },
        { date: '28/10/2021', callPut: -530, futurePrice: 970.6 },
        { date: '29/10/2021', callPut: 278, futurePrice: 970.4 },
        { date: '1/11/2021', callPut: -241, futurePrice: 966.7 },
        { date: '2/11/2021', callPut: -41, futurePrice: 967.4 },
        { date: '3/11/2021', callPut: -147, futurePrice: 967.6 },
        { date: '4/11/2021', callPut: -18, futurePrice: 974.8 },
        { date: '5/11/2021', callPut: -144, futurePrice: 974.6 },
        { date: '8/11/2021', callPut: -29, futurePrice: 974 },
        { date: '9/11/2021', callPut: -106, futurePrice: 975.2 },
        { date: '10/11/2021', callPut: -133, futurePrice: 973.3 },
        { date: '11/11/2021', callPut: -142, futurePrice: 975 },
        { date: '12/11/2021', callPut: -1014, futurePrice: 976.1 },
        { date: '15/11/2021', callPut: 478, futurePrice: 979.2 },
        { date: '16/11/2021', callPut: -197, futurePrice: 982.1 },
        { date: '17/11/2021', callPut: 232, futurePrice: 980 },
        { date: '18/11/2021', callPut: -34, futurePrice: 981.9 },
        { date: '19/11/2021', callPut: 29, futurePrice: 978.1 },
        { date: '22/11/2021', callPut: -49, futurePrice: 982.3 },
        { date: '23/11/2021', callPut: -1708, futurePrice: 981.3 },
        { date: '24/11/2021', callPut: -390, futurePrice: 983.2 },
        { date: '25/11/2021', callPut: -23, futurePrice: 982.4 },
        { date: '26/11/2021', callPut: -2381, futurePrice: 956.4 },
        { date: '29/11/2021', callPut: -2013, futurePrice: 941.9 },
        { date: '30/11/2021', callPut: -714, futurePrice: 925 },
        { date: '1/12/2021', callPut: -2375, futurePrice: 940.3 },
        { date: '2/12/2021', callPut: -552, futurePrice: 942.3 },
        { date: '3/12/2021', callPut: 376, futurePrice: 937.4 },
        { date: '7/12/2021', callPut: -202, futurePrice: 951 },
        { date: '8/12/2021', callPut: 27, futurePrice: 957.3 },
        { date: '9/12/2021', callPut: -406, futurePrice: 957.6 },
        { date: '13/12/2021', callPut: -996, futurePrice: 959.8 },
        { date: '14/12/2021', callPut: -576, futurePrice: 961 },
        { date: '15/12/2021', callPut: -34, futurePrice: 957.9 },
        { date: '16/12/2021', callPut: 18, futurePrice: 973.2 },
        { date: '17/12/2021', callPut: -111, futurePrice: 970.6 },
        { date: '20/12/2021', callPut: -869, futurePrice: 951.6 },
        { date: '21/12/2021', callPut: -483, futurePrice: 955.7 },
        { date: '22/12/2021', callPut: -255, futurePrice: 958.8 },
        { date: '23/12/2021', callPut: -726, futurePrice: 970.1 },
        { date: '24/12/2021', callPut: 14, futurePrice: 970.4 },
        { date: '27/12/2021', callPut: -116, futurePrice: 968.3 },
        { date: '28/12/2021', callPut: 543, futurePrice: 975.4 },
        { date: '29/12/2021', callPut: 123, futurePrice: 983 },
        { date: '30/12/2021', callPut: -782, futurePrice: 986.8 },
        { date: '4/1/2022', callPut: 747, futurePrice: 993.5 },
        { date: '5/1/2022', callPut: -644, futurePrice: 996.3 },
        { date: '6/1/2022', callPut: -4719, futurePrice: 979.1 },
        { date: '7/1/2022', callPut: -479, futurePrice: 977.3 },
        { date: '10/1/2022', callPut: -708, futurePrice: 978 },
        { date: '11/1/2022', callPut: -443, futurePrice: 984.2 },
        { date: '12/1/2022', callPut: -165, futurePrice: 990.2 },
        { date: '13/1/2022', callPut: -474, futurePrice: 990.4 },
        { date: '14/1/2022', callPut: -831, futurePrice: 986.5 },
        { date: '17/1/2022', callPut: -1359, futurePrice: 990.4 },
        { date: '18/1/2022', callPut: -1545, futurePrice: 980.3 },
        { date: '19/1/2022', callPut: -1719, futurePrice: 983.9 },
        { date: '20/1/2022', callPut: 1384, futurePrice: 982.4 },
        { date: '21/1/2022', callPut: -2461, futurePrice: 980.2 },
        { date: '24/1/2022', callPut: -3231, futurePrice: 976.9 },
        { date: '25/1/2022', callPut: -4838, futurePrice: 978.6 },
        { date: '26/1/2022', callPut: -1495, futurePrice: 985.1 },
        { date: '27/1/2022', callPut: -2638, futurePrice: 979 },
        { date: '28/1/2022', callPut: -508, futurePrice: 981.4 },
        { date: '31/1/2022', callPut: -444, futurePrice: 986.6 },
        { date: '1/2/2022', callPut: -1359, futurePrice: 988.4 },
        { date: '2/2/2022', callPut: -249, futurePrice: 990.8 },
        { date: '3/2/2022', callPut: -652, futurePrice: 990.7 },
        { date: '4/2/2022', callPut: -788, futurePrice: 995.7 },
        { date: '7/2/2022', callPut: -1526, futurePrice: 996.4 },
        { date: '8/2/2022', callPut: -362, futurePrice: 1005 },
        { date: '9/2/2022', callPut: 450, futurePrice: 1022.4 },
        { date: '10/2/2022', callPut: -2744, futurePrice: 1023.8 },
        { date: '11/2/2022', callPut: 320, futurePrice: 1021.1 },
        { date: '14/2/2022', callPut: -984, futurePrice: 1014.1 },
        { date: '15/2/2022', callPut: 956, futurePrice: 1024.3 },
        { date: '17/2/2022', callPut: -1769, futurePrice: 1029.2 },
        { date: '18/2/2022', callPut: -69, futurePrice: 1031.9 },
        { date: '21/2/2022', callPut: -431, futurePrice: 1014.9 },
        { date: '22/2/2022', callPut: -2802, futurePrice: 1009.9 },
        { date: '23/2/2022', callPut: -1651, futurePrice: 1016.8 },
        { date: '24/2/2022', callPut: -5649, futurePrice: 1001.5 },
        { date: '25/2/2022', callPut: -2195, futurePrice: 1008.4 },
        { date: '28/2/2022', callPut: 104, futurePrice: 1008.2 },
        { date: '1/3/2022', callPut: -1771, futurePrice: 1017.3 },
        { date: '2/3/2022', callPut: -3367, futurePrice: 1019.8 },
        { date: '3/3/2022', callPut: -1437, futurePrice: 1023.3 },
        { date: '4/3/2022', callPut: -1753, futurePrice: 1007.9 },
        { date: '7/3/2022', callPut: -8600, futurePrice: 984 },
        { date: '8/3/2022', callPut: -10877, futurePrice: 978.4 },
        { date: '9/3/2022', callPut: 813, futurePrice: 989.1 },
        { date: '10/3/2022', callPut: 281, futurePrice: 994.4 },
        { date: '11/3/2022', callPut: -1056, futurePrice: 1002.7 },
        { date: '14/3/2022', callPut: 98, futurePrice: 1007 },
        { date: '15/3/2022', callPut: -1409, futurePrice: 995.4 },
        { date: '16/3/2022', callPut: -1196, futurePrice: 1009.3 },
        { date: '17/3/2022', callPut: 381, futurePrice: 1018.1 },
        { date: '18/3/2022', callPut: -415, futurePrice: 1015.8 },
        { date: '21/3/2022', callPut: -795, futurePrice: 1011.7 },
        { date: '22/3/2022', callPut: -691, futurePrice: 1012.2 },
        { date: '23/3/2022', callPut: -1571, futurePrice: 1006.8 },
        { date: '24/3/2022', callPut: -372, futurePrice: 1012.2 },
        { date: '25/3/2022', callPut: -600, futurePrice: 1009.9 },
        { date: '28/3/2022', callPut: 579, futurePrice: 1014.9 },
        { date: '29/3/2022', callPut: -424, futurePrice: 1015.7 },
        { date: '30/3/2022', callPut: 610, futurePrice: 1022.9 },
        { date: '31/3/2022', callPut: 0, futurePrice: 0 },
        { date: '1/4/2022', callPut: 0, futurePrice: 0 },
      ],
      openInterestData: [
        { date: '29/9/2021', OI: -5, futurePrice: 967.9 },
        { date: '30/9/2021', OI: -25, futurePrice: 958.7 },
        { date: '1/10/2021', OI: -551, futurePrice: 956 },
        { date: '4/10/2021', OI: -669, futurePrice: 962.6 },
        { date: '5/10/2021', OI: -721, futurePrice: 970 },
        { date: '6/10/2021', OI: -627, futurePrice: 961.8 },
        { date: '7/10/2021', OI: -674, futurePrice: 974.7 },
        { date: '8/10/2021', OI: -705, futurePrice: 978.3 },
        { date: '11/10/2021', OI: -747, futurePrice: 976.4 },
        { date: '12/10/2021', OI: -801, futurePrice: 982.7 },
        { date: '14/10/2021', OI: -1168, futurePrice: 982.5 },
        { date: '15/10/2021', OI: -1197, futurePrice: 981.6 },
        { date: '18/10/2021', OI: -1353, futurePrice: 985.8 },
        { date: '19/10/2021', OI: -1522, futurePrice: 977.2 },
        { date: '20/10/2021', OI: -1959, futurePrice: 978.5 },
        { date: '21/10/2021', OI: -2110, futurePrice: 983.4 },
        { date: '25/10/2021', OI: -2565, futurePrice: 975.7 },
        { date: '26/10/2021', OI: -2957, futurePrice: 979.2 },
        { date: '27/10/2021', OI: -3274, futurePrice: 971.4 },
        { date: '28/10/2021', OI: -3853, futurePrice: 970.6 },
        { date: '29/10/2021', OI: -3813, futurePrice: 970.4 },
        { date: '1/11/2021', OI: -4089, futurePrice: 966.7 },
        { date: '2/11/2021', OI: -4141, futurePrice: 967.4 },
        { date: '3/11/2021', OI: -4335, futurePrice: 967.6 },
        { date: '4/11/2021', OI: -4326, futurePrice: 974.8 },
        { date: '5/11/2021', OI: -4232, futurePrice: 974.6 },
        { date: '8/11/2021', OI: -4264, futurePrice: 974 },
        { date: '9/11/2021', OI: -4313, futurePrice: 975.2 },
        { date: '10/11/2021', OI: -4406, futurePrice: 973.3 },
        { date: '11/11/2021', OI: -4472, futurePrice: 975 },
        { date: '12/11/2021', OI: -5326, futurePrice: 976.1 },
        { date: '15/11/2021', OI: -5027, futurePrice: 979.2 },
        { date: '16/11/2021', OI: -5079, futurePrice: 982.1 },
        { date: '17/11/2021', OI: -5203, futurePrice: 980 },
        { date: '18/11/2021', OI: -5195, futurePrice: 981.9 },
        { date: '19/11/2021', OI: -5043, futurePrice: 978.1 },
        { date: '22/11/2021', OI: -5076, futurePrice: 982.3 },
        { date: '23/11/2021', OI: -6284, futurePrice: 981.3 },
        { date: '24/11/2021', OI: -6387, futurePrice: 983.2 },
        { date: '25/11/2021', OI: -6665, futurePrice: 982.4 },
        { date: '26/11/2021', OI: -7560, futurePrice: 956.4 },
        { date: '29/11/2021', OI: -6610, futurePrice: 941.9 },
        { date: '30/11/2021', OI: -6792, futurePrice: 925 },
        { date: '1/12/2021', OI: -6046, futurePrice: 940.3 },
        { date: '2/12/2021', OI: -6609, futurePrice: 942.3 },
        { date: '3/12/2021', OI: -6283, futurePrice: 937.4 },
        { date: '7/12/2021', OI: -6460, futurePrice: 951 },
        { date: '8/12/2021', OI: -6296, futurePrice: 957.3 },
        { date: '9/12/2021', OI: -6701, futurePrice: 957.6 },
        { date: '13/12/2021', OI: -7070, futurePrice: 959.8 },
        { date: '14/12/2021', OI: -7505, futurePrice: 961 },
        { date: '15/12/2021', OI: -7552, futurePrice: 957.9 },
        { date: '16/12/2021', OI: -7539, futurePrice: 973.2 },
        { date: '17/12/2021', OI: -7774, futurePrice: 970.6 },
        { date: '20/12/2021', OI: -7895, futurePrice: 951.6 },
        { date: '21/12/2021', OI: -8102, futurePrice: 955.7 },
        { date: '22/12/2021', OI: -8181, futurePrice: 958.8 },
        { date: '23/12/2021', OI: -7900, futurePrice: 970.1 },
        { date: '24/12/2021', OI: -7989, futurePrice: 970.4 },
        { date: '27/12/2021', OI: -7944, futurePrice: 968.3 },
        { date: '28/12/2021', OI: -8069, futurePrice: 975.4 },
        { date: '29/12/2021', OI: -7734, futurePrice: 983 },
        { date: '30/12/2021', OI: -8601, futurePrice: 986.8 },
        { date: '4/1/2022', OI: -9970, futurePrice: 993.5 },
        { date: '5/1/2022', OI: -11107, futurePrice: 996.3 },
        { date: '6/1/2022', OI: -13901, futurePrice: 979.1 },
        { date: '7/1/2022', OI: -14397, futurePrice: 977.3 },
        { date: '10/1/2022', OI: -14331, futurePrice: 978 },
        { date: '11/1/2022', OI: -14818, futurePrice: 984.2 },
        { date: '12/1/2022', OI: -14539, futurePrice: 990.2 },
        { date: '13/1/2022', OI: -14739, futurePrice: 990.4 },
        { date: '14/1/2022', OI: -15481, futurePrice: 986.5 },
        { date: '17/1/2022', OI: -16118, futurePrice: 990.4 },
        { date: '18/1/2022', OI: -16874, futurePrice: 980.3 },
        { date: '19/1/2022', OI: -18173, futurePrice: 983.9 },
        { date: '20/1/2022', OI: -16926, futurePrice: 982.4 },
        { date: '21/1/2022', OI: -17486, futurePrice: 980.2 },
        { date: '24/1/2022', OI: -18739, futurePrice: 976.9 },
        { date: '25/1/2022', OI: -17903, futurePrice: 978.6 },
        { date: '26/1/2022', OI: -18954, futurePrice: 985.1 },
        { date: '27/1/2022', OI: -19374, futurePrice: 979 },
        { date: '28/1/2022', OI: -19879, futurePrice: 981.4 },
        { date: '31/1/2022', OI: -20197, futurePrice: 986.6 },
        { date: '1/2/2022', OI: -20576, futurePrice: 988.4 },
        { date: '2/2/2022', OI: -20693, futurePrice: 990.8 },
        { date: '3/2/2022', OI: -20715, futurePrice: 990.7 },
        { date: '4/2/2022', OI: -22131, futurePrice: 995.7 },
        { date: '7/2/2022', OI: -22194, futurePrice: 996.4 },
        { date: '8/2/2022', OI: -23680, futurePrice: 1005 },
        { date: '9/2/2022', OI: -28379, futurePrice: 1022.4 },
        { date: '10/2/2022', OI: -30809, futurePrice: 1023.8 },
        { date: '11/2/2022', OI: -29879, futurePrice: 1021.1 },
        { date: '14/2/2022', OI: -30308, futurePrice: 1014.1 },
        { date: '15/2/2022', OI: -31272, futurePrice: 1024.3 },
        { date: '17/2/2022', OI: -34534, futurePrice: 1029.2 },
        { date: '18/2/2022', OI: -35271, futurePrice: 1031.9 },
        { date: '21/2/2022', OI: -35081, futurePrice: 1014.9 },
        { date: '22/2/2022', OI: -34657, futurePrice: 1009.9 },
        { date: '23/2/2022', OI: -35269, futurePrice: 1016.8 },
        { date: '24/2/2022', OI: -33946, futurePrice: 1001.5 },
        { date: '25/2/2022', OI: -34478, futurePrice: 1008.4 },
        { date: '28/2/2022', OI: -36978, futurePrice: 1008.2 },
        { date: '1/3/2022', OI: -39366, futurePrice: 1017.3 },
        { date: '2/3/2022', OI: -41331, futurePrice: 1019.8 },
        { date: '3/3/2022', OI: -43642, futurePrice: 1023.3 },
        { date: '4/3/2022', OI: -42679, futurePrice: 1007.9 },
        { date: '7/3/2022', OI: -39153, futurePrice: 984 },
        { date: '8/3/2022', OI: -33847, futurePrice: 978.4 },
        { date: '9/3/2022', OI: -34062, futurePrice: 989.1 },
        { date: '10/3/2022', OI: -35633, futurePrice: 994.4 },
        { date: '11/3/2022', OI: -36355, futurePrice: 1002.7 },
        { date: '14/3/2022', OI: -37090, futurePrice: 1007 },
        { date: '15/3/2022', OI: -37293, futurePrice: 995.4 },
        { date: '16/3/2022', OI: -37498, futurePrice: 1009.3 },
        { date: '17/3/2022', OI: -37399, futurePrice: 1018.1 },
        { date: '18/3/2022', OI: -37332, futurePrice: 1015.8 },
        { date: '21/3/2022', OI: -37480, futurePrice: 1011.7 },
        { date: '22/3/2022', OI: -37326, futurePrice: 1012.2 },
        { date: '23/3/2022', OI: -37363, futurePrice: 1006.8 },
        { date: '24/3/2022', OI: -37014, futurePrice: 1012.2 },
        { date: '25/3/2022', OI: -37259, futurePrice: 1009.9 },
        { date: '28/3/2022', OI: -38198, futurePrice: 1014.9 },
        { date: '29/3/2022', OI: -38537, futurePrice: 1015.7 },
        { date: '30/3/2022', OI: -39824, futurePrice: 1022.9 },
        { date: '31/3/2022', OI: 0, futurePrice: 0 },
        { date: '1/4/2022', OI: 0, futurePrice: 0 },
      ]
    },
    S50M22: {
      volumeData: [
        { date: '29/12/2021', callPut: -523, futurePrice: 979.7 },
        { date: '30/12/2021', callPut: 133, futurePrice: 983.6 },
        { date: '4/1/2022', callPut: 377, futurePrice: 990.2 },
        { date: '5/1/2022', callPut: -169, futurePrice: 992.9 },
        { date: '6/1/2022', callPut: -299, futurePrice: 975.8 },
        { date: '7/1/2022', callPut: -87, futurePrice: 974.1 },
        { date: '10/1/2022', callPut: -111, futurePrice: 974.6 },
        { date: '11/1/2022', callPut: -168, futurePrice: 980.5 },
        { date: '12/1/2022', callPut: -259, futurePrice: 986.1 },
        { date: '13/1/2022', callPut: -144, futurePrice: 986.2 },
        { date: '14/1/2022', callPut: -8, futurePrice: 982.4 },
        { date: '17/1/2022', callPut: -144, futurePrice: 985.8 },
        { date: '18/1/2022', callPut: -383, futurePrice: 975.6 },
        { date: '19/1/2022', callPut: -392, futurePrice: 979.2 },
        { date: '20/1/2022', callPut: -176, futurePrice: 977.7 },
        { date: '21/1/2022', callPut: -671, futurePrice: 975.7 },
        { date: '24/1/2022', callPut: 242, futurePrice: 972.4 },
        { date: '25/1/2022', callPut: -570, futurePrice: 973.9 },
        { date: '26/1/2022', callPut: 10, futurePrice: 980.3 },
        { date: '27/1/2022', callPut: -35, futurePrice: 974.2 },
        { date: '28/1/2022', callPut: 43, futurePrice: 976 },
        { date: '31/1/2022', callPut: 16, futurePrice: 981.4 },
        { date: '1/2/2022', callPut: -115, futurePrice: 983.2 },
        { date: '2/2/2022', callPut: -79, futurePrice: 985.2 },
        { date: '3/2/2022', callPut: -29, futurePrice: 985 },
        { date: '4/2/2022', callPut: -93, futurePrice: 989.9 },
        { date: '7/2/2022', callPut: -196, futurePrice: 990.5 },
        { date: '8/2/2022', callPut: -97, futurePrice: 998.3 },
        { date: '9/2/2022', callPut: 528, futurePrice: 1016.5 },
        { date: '10/2/2022', callPut: 355, futurePrice: 1017.9 },
        { date: '11/2/2022', callPut: 48, futurePrice: 1015.4 },
        { date: '14/2/2022', callPut: -32, futurePrice: 1008.4 },
        { date: '15/2/2022', callPut: 100, futurePrice: 1018.5 },
        { date: '17/2/2022', callPut: 78, futurePrice: 1023.3 },
        { date: '18/2/2022', callPut: -75, futurePrice: 1026.2 },
        { date: '21/2/2022', callPut: -164, futurePrice: 1009.9 },
        { date: '22/2/2022', callPut: -818, futurePrice: 1004.9 },
        { date: '23/2/2022', callPut: -216, futurePrice: 1011.5 },
        { date: '24/2/2022', callPut: 443, futurePrice: 996.2 },
        { date: '25/2/2022', callPut: 21, futurePrice: 1003.3 },
        { date: '28/2/2022', callPut: -137, futurePrice: 1003.4 },
        { date: '1/3/2022', callPut: -239, futurePrice: 1012 },
        { date: '2/3/2022', callPut: -187, futurePrice: 1014.5 },
        { date: '3/3/2022', callPut: -283, futurePrice: 1018.3 },
        { date: '4/3/2022', callPut: -221, futurePrice: 1003.5 },
        { date: '7/3/2022', callPut: -1003, futurePrice: 979.2 },
        { date: '8/3/2022', callPut: -545, futurePrice: 974 },
        { date: '9/3/2022', callPut: -80, futurePrice: 984.7 },
        { date: '10/3/2022', callPut: -189, futurePrice: 989 },
        { date: '11/3/2022', callPut: -427, futurePrice: 996.1 },
        { date: '14/3/2022', callPut: -181, futurePrice: 1000.1 },
        { date: '15/3/2022', callPut: -413, futurePrice: 989.1 },
        { date: '16/3/2022', callPut: -401, futurePrice: 1003.6 },
        { date: '17/3/2022', callPut: -402, futurePrice: 1012 },
        { date: '18/3/2022', callPut: -305, futurePrice: 1009.8 },
        { date: '21/3/2022', callPut: -106, futurePrice: 1005.4 },
        { date: '22/3/2022', callPut: -528, futurePrice: 1005.4 },
        { date: '23/3/2022', callPut: -108, futurePrice: 1000.6 },
        { date: '24/3/2022', callPut: -164, futurePrice: 1006.1 },
        { date: '25/3/2022', callPut: 597, futurePrice: 1003.6 },
        { date: '28/3/2022', callPut: -582, futurePrice: 1009.4 },
        { date: '29/3/2022', callPut: -585, futurePrice: 1011.1 },
        { date: '30/3/2022', callPut: -975, futurePrice: 1018.1 },
        { date: '31/3/2022', callPut: 483, futurePrice: 1016.7 },
        { date: '1/4/2022', callPut: -932, futurePrice: 1018.7 },
        { date: '4/4/2022', callPut: -783, futurePrice: 1018.5 },
        { date: '5/4/2022', callPut: -791, futurePrice: 1016.4 },
        { date: '7/4/2022', callPut: -696, futurePrice: 1003.4 },
        { date: '8/4/2022', callPut: -674, futurePrice: 1002.9 },
        { date: '11/4/2022', callPut: -708, futurePrice: 997.3 },
        { date: '12/4/2022', callPut: -629, futurePrice: 994.9 },
        { date: '18/4/2022', callPut: 276, futurePrice: 988.5 },
        { date: '19/4/2022', callPut: 372, futurePrice: 989.3 },
        { date: '20/4/2022', callPut: 413, futurePrice: 991.7 },
        { date: '21/4/2022', callPut: -440, futurePrice: 997.8 },
        { date: '22/4/2022', callPut: 244, futurePrice: 990.4 },
        { date: '25/4/2022', callPut: -404, futurePrice: 978.4 },
        { date: '26/4/2022', callPut: 16, futurePrice: 981.3 },
        { date: '27/4/2022', callPut: 1565, futurePrice: 976.7 },
        { date: '28/4/2022', callPut: -1277, futurePrice: 977.9 },
        { date: '29/4/2022', callPut: -1716, futurePrice: 978.1 },
        { date: '3/5/2022', callPut: -474, futurePrice: 975.1 },
        { date: '5/5/2022', callPut: -178, futurePrice: 971.8 },
        { date: '6/5/2022', callPut: -611, futurePrice: 964 },
        { date: '9/5/2022', callPut: -133, futurePrice: 948 },
        { date: '10/5/2022', callPut: -1298, futurePrice: 959.5 },
        { date: '11/5/2022', callPut: 2630, futurePrice: 957.2 },
        { date: '12/5/2022', callPut: -54, futurePrice: 944.1 },
        { date: '13/5/2022', callPut: -1957, futurePrice: 948.5 },
        { date: '17/5/2022', callPut: 347, futurePrice: 967.5 },
        { date: '18/5/2022', callPut: 63, futurePrice: 973.1 },
        { date: '19/5/2022', callPut: -1131, futurePrice: 961.1 },
        { date: '20/5/2022', callPut: 201, futurePrice: 976.1 },
        { date: '23/5/2022', callPut: -637, futurePrice: 983.9 },
        { date: '24/5/2022', callPut: 157, futurePrice: 977.2 },
        { date: '25/5/2022', callPut: -319, futurePrice: 974.5 },
        { date: '26/5/2022', callPut: -23, futurePrice: 980 },
        { date: '27/5/2022', callPut: -305, futurePrice: 985.8 },
        { date: '30/5/2022', callPut: -2917, futurePrice: 995.8 },
        { date: '31/5/2022', callPut: -568, futurePrice: 998 },
        { date: '1/6/2022', callPut: -1196, futurePrice: 1001.1 },
        { date: '2/6/2022', callPut: -1140, futurePrice: 996.2 },
        { date: '6/6/2022', callPut: 347, futurePrice: 994 },
        { date: '7/6/2022', callPut: 1113, futurePrice: 985.1 },
        { date: '8/6/2022', callPut: 925, futurePrice: 985.4 },
        { date: '9/6/2022', callPut: -245, futurePrice: 991.2 },
        { date: '10/6/2022', callPut: -1047, futurePrice: 986.5 },
        { date: '13/6/2022', callPut: -5119, futurePrice: 967.8 },
        { date: '14/6/2022', callPut: -1243, futurePrice: 966.8 },
        { date: '15/6/2022', callPut: -580, futurePrice: 964.9 },
        { date: '16/6/2022', callPut: -2482, futurePrice: 940.7 },
        { date: '17/6/2022', callPut: -4897, futurePrice: 942.9 },
        { date: '20/6/2022', callPut: -1477, futurePrice: 942.5 },
        { date: '21/6/2022', callPut: 102, futurePrice: 953.5 },
        { date: '22/6/2022', callPut: -341, futurePrice: 943 },
        { date: '23/6/2022', callPut: -1215, futurePrice: 942.7 },
        { date: '24/6/2022', callPut: -12, futurePrice: 948 },
        { date: '27/6/2022', callPut: 97, futurePrice: 958.4 },
        { date: '28/6/2022', callPut: 372, futurePrice: 963.8 },
        { date: '29/6/2022', callPut: 192, futurePrice: 962.49 },
        { date: '30/6/2022', callPut: 0, futurePrice: 0 },
        { date: '1/7/2022', callPut: 0, futurePrice: 0 },
      ],
      openInterestData: [
        { date: '29/12/2021', OI: -521, futurePrice: 979.7 },
        { date: '30/12/2021', OI: -462, futurePrice: 983.6 },
        { date: '4/1/2022', OI: -237, futurePrice: 990.2 },
        { date: '5/1/2022', OI: -468, futurePrice: 992.9 },
        { date: '6/1/2022', OI: -717, futurePrice: 975.8 },
        { date: '7/1/2022', OI: -795, futurePrice: 974.1 },
        { date: '10/1/2022', OI: -873, futurePrice: 974.6 },
        { date: '11/1/2022', OI: -983, futurePrice: 980.5 },
        { date: '12/1/2022', OI: -1140, futurePrice: 986.1 },
        { date: '13/1/2022', OI: -1229, futurePrice: 986.2 },
        { date: '14/1/2022', OI: -1234, futurePrice: 982.4 },
        { date: '17/1/2022', OI: -1281, futurePrice: 985.8 },
        { date: '18/1/2022', OI: -1639, futurePrice: 975.6 },
        { date: '19/1/2022', OI: -1882, futurePrice: 979.2 },
        { date: '20/1/2022', OI: -1924, futurePrice: 977.7 },
        { date: '21/1/2022', OI: -2317, futurePrice: 975.7 },
        { date: '24/1/2022', OI: -2090, futurePrice: 972.4 },
        { date: '25/1/2022', OI: -2475, futurePrice: 973.9 },
        { date: '26/1/2022', OI: -2435, futurePrice: 980.3 },
        { date: '27/1/2022', OI: -2468, futurePrice: 974.2 },
        { date: '28/1/2022', OI: -2433, futurePrice: 976 },
        { date: '31/1/2022', OI: -2396, futurePrice: 981.4 },
        { date: '1/2/2022', OI: -2473, futurePrice: 983.2 },
        { date: '2/2/2022', OI: -2532, futurePrice: 985.2 },
        { date: '3/2/2022', OI: -2548, futurePrice: 985 },
        { date: '4/2/2022', OI: -2587, futurePrice: 989.9 },
        { date: '7/2/2022', OI: -2662, futurePrice: 990.5 },
        { date: '8/2/2022', OI: -2724, futurePrice: 998.3 },
        { date: '9/2/2022', OI: -2640, futurePrice: 1016.5 },
        { date: '10/2/2022', OI: -2908, futurePrice: 1017.9 },
        { date: '11/2/2022', OI: -2871, futurePrice: 1015.4 },
        { date: '14/2/2022', OI: -2608, futurePrice: 1008.4 },
        { date: '15/2/2022', OI: -2674, futurePrice: 1018.5 },
        { date: '17/2/2022', OI: -2638, futurePrice: 1023.3 },
        { date: '18/2/2022', OI: -2640, futurePrice: 1026.2 },
        { date: '21/2/2022', OI: -2789, futurePrice: 1009.9 },
        { date: '22/2/2022', OI: -2981, futurePrice: 1004.9 },
        { date: '23/2/2022', OI: -2862, futurePrice: 1011.5 },
        { date: '24/2/2022', OI: -2256, futurePrice: 996.2 },
        { date: '25/2/2022', OI: -2244, futurePrice: 1003.3 },
        { date: '28/2/2022', OI: -2280, futurePrice: 1003.4 },
        { date: '1/3/2022', OI: -2414, futurePrice: 1012 },
        { date: '2/3/2022', OI: -2460, futurePrice: 1014.5 },
        { date: '3/3/2022', OI: -2567, futurePrice: 1018.3 },
        { date: '4/3/2022', OI: -2653, futurePrice: 1003.5 },
        { date: '7/3/2022', OI: -2955, futurePrice: 979.2 },
        { date: '8/3/2022', OI: -2777, futurePrice: 974 },
        { date: '9/3/2022', OI: -2894, futurePrice: 984.7 },
        { date: '10/3/2022', OI: -3127, futurePrice: 989 },
        { date: '11/3/2022', OI: -3157, futurePrice: 996.1 },
        { date: '14/3/2022', OI: -3352, futurePrice: 1000.1 },
        { date: '15/3/2022', OI: -3402, futurePrice: 989.1 },
        { date: '16/3/2022', OI: -3292, futurePrice: 1003.6 },
        { date: '17/3/2022', OI: -3715, futurePrice: 1012 },
        { date: '18/3/2022', OI: -3855, futurePrice: 1009.8 },
        { date: '21/3/2022', OI: -4012, futurePrice: 1005.4 },
        { date: '22/3/2022', OI: -4333, futurePrice: 1005.4 },
        { date: '23/3/2022', OI: -4208, futurePrice: 1000.6 },
        { date: '24/3/2022', OI: -4298, futurePrice: 1006.1 },
        { date: '25/3/2022', OI: -4216, futurePrice: 1003.6 },
        { date: '28/3/2022', OI: -4395, futurePrice: 1009.4 },
        { date: '29/3/2022', OI: -4562, futurePrice: 1011.1 },
        { date: '30/3/2022', OI: -4994, futurePrice: 1018.1 },
        { date: '31/3/2022', OI: -5302, futurePrice: 1016.7 },
        { date: '1/4/2022', OI: -5782, futurePrice: 1018.7 },
        { date: '4/4/2022', OI: -6079, futurePrice: 1018.5 },
        { date: '5/4/2022', OI: -6625, futurePrice: 1016.4 },
        { date: '7/4/2022', OI: -6900, futurePrice: 1003.4 },
        { date: '8/4/2022', OI: -7421, futurePrice: 1002.9 },
        { date: '11/4/2022', OI: -7776, futurePrice: 997.3 },
        { date: '12/4/2022', OI: -8036, futurePrice: 994.9 },
        { date: '18/4/2022', OI: -7314, futurePrice: 988.5 },
        { date: '19/4/2022', OI: -6672, futurePrice: 989.3 },
        { date: '20/4/2022', OI: -6340, futurePrice: 991.7 },
        { date: '21/4/2022', OI: -6273, futurePrice: 997.8 },
        { date: '22/4/2022', OI: -5701, futurePrice: 990.4 },
        { date: '25/4/2022', OI: -5344, futurePrice: 978.4 },
        { date: '26/4/2022', OI: -5644, futurePrice: 981.3 },
        { date: '27/4/2022', OI: -4697, futurePrice: 976.7 },
        { date: '28/4/2022', OI: -4566, futurePrice: 977.9 },
        { date: '29/4/2022', OI: -4310, futurePrice: 978.1 },
        { date: '3/5/2022', OI: -4190, futurePrice: 975.1 },
        { date: '5/5/2022', OI: -4124, futurePrice: 971.8 },
        { date: '6/5/2022', OI: -3928, futurePrice: 964 },
        { date: '9/5/2022', OI: -2163, futurePrice: 948 },
        { date: '10/5/2022', OI: -1641, futurePrice: 959.5 },
        { date: '11/5/2022', OI: 371, futurePrice: 957.2 },
        { date: '12/5/2022', OI: 2806, futurePrice: 944.1 },
        { date: '13/5/2022', OI: 2971, futurePrice: 948.5 },
        { date: '17/5/2022', OI: 1901, futurePrice: 967.5 },
        { date: '18/5/2022', OI: 2123, futurePrice: 973.1 },
        { date: '19/5/2022', OI: 1939, futurePrice: 961.1 },
        { date: '20/5/2022', OI: 1672, futurePrice: 976.1 },
        { date: '23/5/2022', OI: 1266, futurePrice: 983.9 },
        { date: '24/5/2022', OI: 1824, futurePrice: 977.2 },
        { date: '25/5/2022', OI: 1972, futurePrice: 974.5 },
        { date: '26/5/2022', OI: 862, futurePrice: 980 },
        { date: '27/5/2022', OI: 685, futurePrice: 985.8 },
        { date: '30/5/2022', OI: -1409, futurePrice: 995.8 },
        { date: '31/5/2022', OI: -1577, futurePrice: 998 },
        { date: '1/6/2022', OI: -2786, futurePrice: 1001.1 },
        { date: '2/6/2022', OI: -3568, futurePrice: 996.2 },
        { date: '6/6/2022', OI: -3334, futurePrice: 994 },
        { date: '7/6/2022', OI: -3806, futurePrice: 985.1 },
        { date: '8/6/2022', OI: -3592, futurePrice: 985.4 },
        { date: '9/6/2022', OI: -3852, futurePrice: 991.2 },
        { date: '10/6/2022', OI: -3767, futurePrice: 986.5 },
        { date: '13/6/2022', OI: -2278, futurePrice: 967.8 },
        { date: '14/6/2022', OI: -1702, futurePrice: 966.8 },
        { date: '15/6/2022', OI: -1759, futurePrice: 964.9 },
        { date: '16/6/2022', OI: 1130, futurePrice: 940.7 },
        { date: '17/6/2022', OI: 3583, futurePrice: 942.9 },
        { date: '20/6/2022', OI: 4991, futurePrice: 942.5 },
        { date: '21/6/2022', OI: 5165, futurePrice: 953.5 },
        { date: '22/6/2022', OI: 5979, futurePrice: 943 },
        { date: '23/6/2022', OI: 5978, futurePrice: 942.7 },
        { date: '24/6/2022', OI: 5815, futurePrice: 948 },
        { date: '27/6/2022', OI: 5918, futurePrice: 958.4 },
        { date: '28/6/2022', OI: 5406, futurePrice: 963.8 },
        { date: '29/6/2022', OI: 4911, futurePrice: 962.49 },
        { date: '30/6/2022', OI: 0, futurePrice: 0 },
        { date: '1/7/2022', OI: 0, futurePrice: 0 },
      ]
    },
    S50U22: {
      volumeData: [
        { date: '30/3/2565', callPut: -22, futurePrice: 1011.8 },
        { date: '31/3/2565', callPut: 261, futurePrice: 1010.6 },
        { date: '1/4/2565', callPut: 250, futurePrice: 1012.5 },
        { date: '4/4/2565', callPut: 43, futurePrice: 1012.3 },
        { date: '5/4/2565', callPut: 4, futurePrice: 1010.1 },
        { date: '7/4/2565', callPut: -38, futurePrice: 997.6 },
        { date: '8/4/2565', callPut: -447, futurePrice: 997.1 },
        { date: '11/4/2565', callPut: 40, futurePrice: 991.4 },
        { date: '12/4/2565', callPut: -85, futurePrice: 988.9 },
        { date: '18/4/2565', callPut: -107, futurePrice: 982.9 },
        { date: '19/4/2565', callPut: 11, futurePrice: 983.6 },
        { date: '20/4/2565', callPut: -19, futurePrice: 985.9 },
        { date: '21/4/2565', callPut: -150, futurePrice: 991.5 },
        { date: '22/4/2565', callPut: -153, futurePrice: 984.1 },
        { date: '25/4/2565', callPut: -139, futurePrice: 972.1 },
        { date: '26/4/2565', callPut: 70, futurePrice: 975 },
        { date: '27/4/2565', callPut: 63, futurePrice: 970.8 },
        { date: '28/4/2565', callPut: -13, futurePrice: 972 },
        { date: '29/4/2565', callPut: -55, futurePrice: 971.8 },
        { date: '3/5/2565', callPut: -66, futurePrice: 968.9 },
        { date: '5/5/2565', callPut: 74, futurePrice: 965.7 },
        { date: '6/5/2565', callPut: -725, futurePrice: 958.1 },
        { date: '9/5/2565', callPut: -637, futurePrice: 942.2 },
        { date: '10/5/2565', callPut: -478, futurePrice: 953.3 },
        { date: '11/5/2565', callPut: -195, futurePrice: 950.9 },
        { date: '12/5/2565', callPut: -192, futurePrice: 938.1 },
        { date: '13/5/2565', callPut: 14, futurePrice: 942.4 },
        { date: '17/5/2565', callPut: -129, futurePrice: 961.2 },
        { date: '18/5/2565', callPut: -410, futurePrice: 966.3 },
        { date: '19/5/2565', callPut: -460, futurePrice: 954.3 },
        { date: '20/5/2565', callPut: -165, futurePrice: 969.4 },
        { date: '23/5/2565', callPut: -239, futurePrice: 977.1 },
        { date: '24/5/2565', callPut: -236, futurePrice: 970.4 },
        { date: '25/5/2565', callPut: -326, futurePrice: 967.8 },
        { date: '26/5/2565', callPut: -105, futurePrice: 973.3 },
        { date: '27/5/2565', callPut: -331, futurePrice: 979 },
        { date: '30/5/2565', callPut: -810, futurePrice: 988.8 },
        { date: '31/5/2565', callPut: -446, futurePrice: 990.9 },
        { date: '1/6/2565', callPut: -44, futurePrice: 993.6 },
        { date: '2/6/2565', callPut: -468, futurePrice: 988.6 },
        { date: '6/6/2565', callPut: -352, futurePrice: 986.9 },
        { date: '7/6/2565', callPut: 38, futurePrice: 978.7 },
        { date: '8/6/2565', callPut: -114, futurePrice: 978.8 },
        { date: '9/6/2565', callPut: 103, futurePrice: 984.1 },
        { date: '10/6/2565', callPut: -759, futurePrice: 979.6 },
        { date: '13/6/2565', callPut: -933, futurePrice: 960.9 },
        { date: '14/6/2565', callPut: 105, futurePrice: 960 },
        { date: '15/6/2565', callPut: -264, futurePrice: 958.2 },
        { date: '16/6/2565', callPut: 213, futurePrice: 934.7 },
        { date: '17/6/2565', callPut: -714, futurePrice: 937 },
        { date: '20/6/2565', callPut: -508, futurePrice: 936.5 },
        { date: '21/6/2565', callPut: -410, futurePrice: 946.7 },
        { date: '22/6/2565', callPut: -268, futurePrice: 936.2 },
        { date: '23/6/2565', callPut: 7, futurePrice: 936.8 },
        { date: '24/6/2565', callPut: -433, futurePrice: 942.2 },
        { date: '27/6/2565', callPut: -571, futurePrice: 951.9 },
        { date: '28/6/2565', callPut: -159, futurePrice: 956.9 },
        { date: '29/6/2565', callPut: -1806, futurePrice: 952.4 },
        { date: '30/6/2565', callPut: -712, futurePrice: 939.6 },
        { date: '1/7/2565', callPut: -1103, futurePrice: 944.8 },
        { date: '4/7/2565', callPut: 421, futurePrice: 941.3 },
        { date: '5/7/2565', callPut: -417, futurePrice: 934.6 },
        { date: '6/7/2565', callPut: -488, futurePrice: 937.7 },
        { date: '7/7/2565', callPut: -253, futurePrice: 949.4 },
        { date: '8/7/2565', callPut: 96, futurePrice: 950.1 },
        { date: '11/7/2565', callPut: 193, futurePrice: 949.6 },
        { date: '12/7/2565', callPut: -4, futurePrice: 941.7 },
        { date: '14/7/2565', callPut: -469, futurePrice: 935.2 },
        { date: '15/7/2565', callPut: -518, futurePrice: 933.9 },
        { date: '18/7/2565', callPut: 991, futurePrice: 940.9 },
        { date: '19/7/2565', callPut: -2240, futurePrice: 934.2 },
        { date: '20/7/2565', callPut: -32, futurePrice: 934.3 },
        { date: '21/7/2565', callPut: -269, futurePrice: 937.8 },
        { date: '22/7/2565', callPut: 1456, futurePrice: 945.8 },
        { date: '25/7/2565', callPut: 3116, futurePrice: 947.6 },
        { date: '26/7/2565', callPut: 3115, futurePrice: 945.5 },
        { date: '27/7/2565', callPut: 1038, futurePrice: 951.9 },
        { date: '1/8/2565', callPut: 1108, futurePrice: 958.8 },
        { date: '2/8/2565', callPut: 1171, futurePrice: 958.4 },
        { date: '3/8/2565', callPut: 864, futurePrice: 962.1 },
        { date: '4/8/2565', callPut: 499, futurePrice: 961.7 },
        { date: '5/8/2565', callPut: -20, futurePrice: 962.4 },
        { date: '8/8/2565', callPut: 605, futurePrice: 971.1 },
        { date: '9/8/2565', callPut: -3713, futurePrice: 977.3 },
        { date: '10/8/2565', callPut: -3219, futurePrice: 975 },
        { date: '11/8/2565', callPut: -2987, futurePrice: 979 },
        { date: '15/8/2565', callPut: -1880, futurePrice: 983 },
        { date: '16/8/2565', callPut: -297, futurePrice: 981 },
        { date: '17/8/2565', callPut: -1326, futurePrice: 985.7 },
        { date: '18/8/2565', callPut: 229, futurePrice: 987.1 },
        { date: '19/8/2565', callPut: 756, futurePrice: 978.4 },
        { date: '22/8/2565', callPut: 44, futurePrice: 970.2 },
        { date: '23/8/2565', callPut: -1440, futurePrice: 983.5 },
        { date: '24/8/2565', callPut: 156, futurePrice: 984.2 },
        { date: '25/8/2565', callPut: -704, futurePrice: 990.8 },
        { date: '26/8/2565', callPut: -265, futurePrice: 991.3 },
        { date: '29/8/2565', callPut: -1525, futurePrice: 978 },
        { date: '30/8/2565', callPut: -873, futurePrice: 989.1 },
        { date: '31/8/2565', callPut: -3100, futurePrice: 989.4 },
        { date: '1/9/2565', callPut: -3159, futurePrice: 980.8 },
        { date: '2/9/2565', callPut: -1672, futurePrice: 977.5 },
        { date: '5/9/2565', callPut: -2190, futurePrice: 977.4 },
        { date: '6/9/2565', callPut: -2350, futurePrice: 985.5 },
        { date: '7/9/2565', callPut: -1713, futurePrice: 984.8 },
        { date: '8/9/2565', callPut: -1713, futurePrice: 983.3 },
        { date: '9/9/2565', callPut: -1042, futurePrice: 990.9 },
        { date: '12/9/2565', callPut: 80, futurePrice: 1000.1 },
        { date: '13/9/2565', callPut: 171, futurePrice: 1001.2 },
        { date: '14/9/2565', callPut: -2569, futurePrice: 995.7 },
        { date: '15/9/2565', callPut: -3370, futurePrice: 985.2 },
        { date: '16/9/2565', callPut: -2956, futurePrice: 979.5 },
        { date: '19/9/2565', callPut: -503, futurePrice: 978.8 },
        { date: '20/9/2565', callPut: -807, futurePrice: 982.1 },
        { date: '21/9/2565', callPut: -916, futurePrice: 982.9 },
        { date: '22/9/2565', callPut: -1807, futurePrice: 987.5 },
        { date: '23/9/2565', callPut: -3954, futurePrice: 978.1 },
        { date: '26/9/2565', callPut: -3444, futurePrice: 971.8 },
        { date: '27/9/2565', callPut: -2334, futurePrice: 969.2 },
        { date: '28/9/2565', callPut: -5919, futurePrice: 961.2 },
        { date: '29/9/2565', callPut: -2769, futurePrice: 958.07 },
        { date: '30/9/2565', callPut: 0, futurePrice: 0 },
        { date: '3/10/2565', callPut: 0, futurePrice: 0 },
      ],
      openInterestData: [
        { date: '30/3/2565', OI: -25, futurePrice: 1011.8 },
        { date: '31/3/2565', OI: 105, futurePrice: 1010.6 },
        { date: '1/4/2565', OI: 290, futurePrice: 1012.5 },
        { date: '4/4/2565', OI: 341, futurePrice: 1012.3 },
        { date: '5/4/2565', OI: 361, futurePrice: 1010.1 },
        { date: '7/4/2565', OI: 395, futurePrice: 997.6 },
        { date: '8/4/2565', OI: -92, futurePrice: 997.1 },
        { date: '11/4/2565', OI: -70, futurePrice: 991.4 },
        { date: '12/4/2565', OI: -195, futurePrice: 988.9 },
        { date: '18/4/2565', OI: -391, futurePrice: 982.9 },
        { date: '19/4/2565', OI: -394, futurePrice: 983.6 },
        { date: '20/4/2565', OI: -384, futurePrice: 985.9 },
        { date: '21/4/2565', OI: -515, futurePrice: 991.5 },
        { date: '22/4/2565', OI: -588, futurePrice: 984.1 },
        { date: '25/4/2565', OI: -661, futurePrice: 972.1 },
        { date: '26/4/2565', OI: -546, futurePrice: 975 },
        { date: '27/4/2565', OI: -558, futurePrice: 970.8 },
        { date: '28/4/2565', OI: -582, futurePrice: 972 },
        { date: '29/4/2565', OI: -576, futurePrice: 971.8 },
        { date: '3/5/2565', OI: -560, futurePrice: 968.9 },
        { date: '5/5/2565', OI: -518, futurePrice: 965.7 },
        { date: '6/5/2565', OI: -1278, futurePrice: 958.1 },
        { date: '9/5/2565', OI: -1835, futurePrice: 942.2 },
        { date: '10/5/2565', OI: -2321, futurePrice: 953.3 },
        { date: '11/5/2565', OI: -2314, futurePrice: 950.9 },
        { date: '12/5/2565', OI: -2371, futurePrice: 938.1 },
        { date: '13/5/2565', OI: -2348, futurePrice: 942.4 },
        { date: '17/5/2565', OI: -2460, futurePrice: 961.2 },
        { date: '18/5/2565', OI: -2686, futurePrice: 966.3 },
        { date: '19/5/2565', OI: -2878, futurePrice: 954.3 },
        { date: '20/5/2565', OI: -2962, futurePrice: 969.4 },
        { date: '23/5/2565', OI: -3245, futurePrice: 977.1 },
        { date: '24/5/2565', OI: -3243, futurePrice: 970.4 },
        { date: '25/5/2565', OI: -3464, futurePrice: 967.8 },
        { date: '26/5/2565', OI: -3495, futurePrice: 973.3 },
        { date: '27/5/2565', OI: -3409, futurePrice: 979 },
        { date: '30/5/2565', OI: -3507, futurePrice: 988.8 },
        { date: '31/5/2565', OI: -3775, futurePrice: 990.9 },
        { date: '1/6/2565', OI: -3776, futurePrice: 993.6 },
        { date: '2/6/2565', OI: -3842, futurePrice: 988.6 },
        { date: '6/6/2565', OI: -3495, futurePrice: 986.9 },
        { date: '7/6/2565', OI: -3159, futurePrice: 978.7 },
        { date: '8/6/2565', OI: -3308, futurePrice: 978.8 },
        { date: '9/6/2565', OI: -2731, futurePrice: 984.1 },
        { date: '10/6/2565', OI: -2955, futurePrice: 979.6 },
        { date: '13/6/2565', OI: -3138, futurePrice: 960.9 },
        { date: '14/6/2565', OI: -2725, futurePrice: 960 },
        { date: '15/6/2565', OI: -2890, futurePrice: 958.2 },
        { date: '16/6/2565', OI: -2572, futurePrice: 934.7 },
        { date: '17/6/2565', OI: -2703, futurePrice: 937 },
        { date: '20/6/2565', OI: -2707, futurePrice: 936.5 },
        { date: '21/6/2565', OI: -2913, futurePrice: 946.7 },
        { date: '22/6/2565', OI: -2822, futurePrice: 936.2 },
        { date: '23/6/2565', OI: -3053, futurePrice: 936.8 },
        { date: '24/6/2565', OI: -3176, futurePrice: 942.2 },
        { date: '27/6/2565', OI: -3009, futurePrice: 951.9 },
        { date: '28/6/2565', OI: -2925, futurePrice: 956.9 },
        { date: '29/6/2565', OI: -4146, futurePrice: 952.4 },
        { date: '30/6/2565', OI: -4227, futurePrice: 939.6 },
        { date: '1/7/2565', OI: -4111, futurePrice: 944.8 },
        { date: '4/7/2565', OI: -3604, futurePrice: 941.3 },
        { date: '5/7/2565', OI: -3173, futurePrice: 934.6 },
        { date: '6/7/2565', OI: -3214, futurePrice: 937.7 },
        { date: '7/7/2565', OI: -2586, futurePrice: 949.4 },
        { date: '8/7/2565', OI: -2670, futurePrice: 950.1 },
        { date: '11/7/2565', OI: -2462, futurePrice: 949.6 },
        { date: '12/7/2565', OI: -2325, futurePrice: 941.7 },
        { date: '14/7/2565', OI: -1986, futurePrice: 935.2 },
        { date: '15/7/2565', OI: -2000, futurePrice: 933.9 },
        { date: '18/7/2565', OI: -1527, futurePrice: 940.9 },
        { date: '19/7/2565', OI: -2941, futurePrice: 934.2 },
        { date: '20/7/2565', OI: -2777, futurePrice: 934.3 },
        { date: '21/7/2565', OI: -2842, futurePrice: 937.8 },
        { date: '22/7/2565', OI: -2766, futurePrice: 945.8 },
        { date: '25/7/2565', OI: -1587, futurePrice: 947.6 },
        { date: '26/7/2565', OI: 488, futurePrice: 945.5 },
        { date: '27/7/2565', OI: 926, futurePrice: 951.9 },
        { date: '1/8/2565', OI: 1019, futurePrice: 958.8 },
        { date: '2/8/2565', OI: 1131, futurePrice: 958.4 },
        { date: '3/8/2565', OI: 1780, futurePrice: 962.1 },
        { date: '4/8/2565', OI: 1281, futurePrice: 961.7 },
        { date: '5/8/2565', OI: 649, futurePrice: 962.4 },
        { date: '8/8/2565', OI: -275, futurePrice: 971.1 },
        { date: '9/8/2565', OI: -2178, futurePrice: 977.3 },
        { date: '10/8/2565', OI: -3715, futurePrice: 975 },
        { date: '11/8/2565', OI: -5658, futurePrice: 979 },
        { date: '15/8/2565', OI: -7259, futurePrice: 983 },
        { date: '16/8/2565', OI: -8008, futurePrice: 981 },
        { date: '17/8/2565', OI: -9089, futurePrice: 985.7 },
        { date: '18/8/2565', OI: -9380, futurePrice: 987.1 },
        { date: '19/8/2565', OI: -9462, futurePrice: 978.4 },
        { date: '22/8/2565', OI: -9765, futurePrice: 970.2 },
        { date: '23/8/2565', OI: -11521, futurePrice: 983.5 },
        { date: '24/8/2565', OI: -12317, futurePrice: 984.2 },
        { date: '25/8/2565', OI: -16924, futurePrice: 990.8 },
        { date: '26/8/2565', OI: -17902, futurePrice: 991.3 },
        { date: '29/8/2565', OI: -19115, futurePrice: 978 },
        { date: '30/8/2565', OI: -21931, futurePrice: 989.1 },
        { date: '31/8/2565', OI: -24319, futurePrice: 989.4 },
        { date: '1/9/2565', OI: -25071, futurePrice: 980.8 },
        { date: '2/9/2565', OI: -25394, futurePrice: 977.5 },
        { date: '5/9/2565', OI: -25759, futurePrice: 977.4 },
        { date: '6/9/2565', OI: -27301, futurePrice: 985.5 },
        { date: '7/9/2565', OI: -28146, futurePrice: 984.8 },
        { date: '8/9/2565', OI: -29284, futurePrice: 983.3 },
        { date: '9/9/2565', OI: -32266, futurePrice: 990.9 },
        { date: '12/9/2565', OI: -35495, futurePrice: 1000.1 },
        { date: '13/9/2565', OI: -36585, futurePrice: 1001.2 },
        { date: '14/9/2565', OI: -36611, futurePrice: 995.7 },
        { date: '15/9/2565', OI: -36671, futurePrice: 985.2 },
        { date: '16/9/2565', OI: -35233, futurePrice: 979.5 },
        { date: '19/9/2565', OI: -34524, futurePrice: 978.8 },
        { date: '20/9/2565', OI: -34805, futurePrice: 982.1 },
        { date: '21/9/2565', OI: -34607, futurePrice: 982.9 },
        { date: '22/9/2565', OI: -34096, futurePrice: 987.5 },
        { date: '23/9/2565', OI: -34338, futurePrice: 978.1 },
        { date: '26/9/2565', OI: -34034, futurePrice: 971.8 },
        { date: '27/9/2565', OI: -33645, futurePrice: 969.2 },
        { date: '28/9/2565', OI: -29347, futurePrice: 961.2 },
        { date: '29/9/2565', OI: -27527, futurePrice: 958.07 },
        { date: '30/9/2565', OI: 0, futurePrice: 0 },
        { date: '3/10/2565', OI: 0, futurePrice: 0 },
      ]
    },
    S50Z22: {
      volumeData: [
        { date: '29/6/2022', callPut: -459, futurePrice: 951.2 },
        { date: '30/6/2022', callPut: 39, futurePrice: 938.9 },
        { date: '1/7/2022', callPut: -96, futurePrice: 943.9 },
        { date: '4/7/2022', callPut: 193, futurePrice: 940.5 },
        { date: '5/7/2022', callPut: 194, futurePrice: 934 },
        { date: '6/7/2022', callPut: -81, futurePrice: 937.2 },
        { date: '7/7/2022', callPut: -93, futurePrice: 948.9 },
        { date: '8/7/2022', callPut: -47, futurePrice: 949.6 },
        { date: '11/7/2022', callPut: 31, futurePrice: 949.2 },
        { date: '12/7/2022', callPut: 41, futurePrice: 941.6 },
        { date: '14/7/2022', callPut: -119, futurePrice: 935 },
        { date: '15/7/2022', callPut: -676, futurePrice: 933.9 },
        { date: '18/7/2022', callPut: -145, futurePrice: 940.6 },
        { date: '19/7/2022', callPut: -943, futurePrice: 934.2 },
        { date: '20/7/2022', callPut: -335, futurePrice: 934.2 },
        { date: '21/7/2022', callPut: -106, futurePrice: 937.5 },
        { date: '22/7/2022', callPut: -167, futurePrice: 945.3 },
        { date: '25/7/2022', callPut: -136, futurePrice: 947 },
        { date: '26/7/2022', callPut: -50, futurePrice: 944.6 },
        { date: '27/7/2022', callPut: -177, futurePrice: 951.3 },
        { date: '1/8/2022', callPut: -279, futurePrice: 957.6 },
        { date: '2/8/2022', callPut: -54, futurePrice: 956.9 },
        { date: '3/8/2022', callPut: -90, futurePrice: 960.3 },
        { date: '4/8/2022', callPut: -154, futurePrice: 959.9 },
        { date: '5/8/2022', callPut: -22, futurePrice: 961 },
        { date: '8/8/2022', callPut: 163, futurePrice: 969.7 },
        { date: '9/8/2022', callPut: 507, futurePrice: 975.6 },
        { date: '10/8/2022', callPut: -11, futurePrice: 973.7 },
        { date: '11/8/2022', callPut: 389, futurePrice: 977.4 },
        { date: '15/8/2022', callPut: 166, futurePrice: 981.4 },
        { date: '16/8/2022', callPut: 181, futurePrice: 979.7 },
        { date: '17/8/2022', callPut: -121, futurePrice: 984.1 },
        { date: '18/8/2022', callPut: 0, futurePrice: 985.3 },
        { date: '19/8/2022', callPut: 261, futurePrice: 976.6 },
        { date: '22/8/2022', callPut: 12, futurePrice: 968.7 },
        { date: '23/8/2022', callPut: -267, futurePrice: 981.9 },
        { date: '24/8/2022', callPut: -266, futurePrice: 982.5 },
        { date: '25/8/2022', callPut: 508, futurePrice: 989.1 },
        { date: '26/8/2022', callPut: -240, futurePrice: 989.5 },
        { date: '29/8/2022', callPut: -201, futurePrice: 976.5 },
        { date: '30/8/2022', callPut: 129, futurePrice: 987.5 },
        { date: '31/8/2022', callPut: 68, futurePrice: 987.5 },
        { date: '1/9/2022', callPut: -237, futurePrice: 979 },
        { date: '2/9/2022', callPut: 90, futurePrice: 975.8 },
        { date: '5/9/2022', callPut: -88, futurePrice: 975.8 },
        { date: '6/9/2022', callPut: -201, futurePrice: 983.3 },
        { date: '7/9/2022', callPut: -236, futurePrice: 982.4 },
        { date: '8/9/2022', callPut: -117, futurePrice: 980.9 },
        { date: '9/9/2022', callPut: -325, futurePrice: 987.8 },
        { date: '12/9/2022', callPut: -264, futurePrice: 997.1 },
        { date: '13/9/2022', callPut: -1426, futurePrice: 998.3 },
        { date: '14/9/2022', callPut: -1334, futurePrice: 993.6 },
        { date: '15/9/2022', callPut: 996, futurePrice: 984 },
        { date: '16/9/2022', callPut: 239, futurePrice: 978.5 },
        { date: '19/9/2022', callPut: -407, futurePrice: 978 },
        { date: '20/9/2022', callPut: -1017, futurePrice: 980.4 },
        { date: '21/9/2022', callPut: 1068, futurePrice: 980.9 },
        { date: '22/9/2022', callPut: -1143, futurePrice: 985.3 },
        { date: '23/9/2022', callPut: -2725, futurePrice: 976.1 },
        { date: '26/9/2022', callPut: -510, futurePrice: 971 },
        { date: '27/9/2022', callPut: -1607, futurePrice: 969.2 },
        { date: '28/9/2022', callPut: -3699, futurePrice: 961.5 },
        { date: '29/9/2022', callPut: -7485, futurePrice: 957.6 },
        { date: '30/9/2022', callPut: -4398, futurePrice: 951.8 },
        { date: '3/10/2022', callPut: -392, futurePrice: 940 },
        { date: '4/10/2022', callPut: 157, futurePrice: 943.5 },
        { date: '5/10/2022', callPut: -365, futurePrice: 942 },
        { date: '6/10/2022', callPut: -161, futurePrice: 947.6 },
        { date: '7/10/2022', callPut: -481, futurePrice: 946.1 },
        { date: '10/10/2022', callPut: -4341, futurePrice: 937.1 },
        { date: '11/10/2022', callPut: 435, futurePrice: 931.8 },
        { date: '12/10/2022', callPut: -733, futurePrice: 936.6 },
        { date: '17/10/2022', callPut: -726, futurePrice: 940.7 },
        { date: '18/10/2022', callPut: -2572, futurePrice: 949.1 },
        { date: '19/10/2022', callPut: 417, futurePrice: 951 },
        { date: '20/10/2022', callPut: -268, futurePrice: 958.5 },
        { date: '21/10/2022', callPut: -2979, futurePrice: 959.2 },
        { date: '25/10/2022', callPut: -1959, futurePrice: 965.2 },
        { date: '26/10/2022', callPut: -920, futurePrice: 965.8 },
        { date: '27/10/2022', callPut: -1116, futurePrice: 973.6 },
        { date: '28/10/2022', callPut: -2114, futurePrice: 977.1 },
        { date: '31/10/2022', callPut: -2443, futurePrice: 979.1 },
        { date: '1/11/2022', callPut: -5432, futurePrice: 992.1 },
        { date: '2/11/2022', callPut: -342, futurePrice: 991.1 },
        { date: '3/11/2022', callPut: -2119, futurePrice: 988.7 },
        { date: '4/11/2022', callPut: -931, futurePrice: 985.9 },
        { date: '7/11/2022', callPut: -3164, futurePrice: 988.5 },
        { date: '8/11/2022', callPut: -1360, futurePrice: 996.6 },
        { date: '9/11/2022', callPut: -1453, futurePrice: 988.8 },
        { date: '10/11/2022', callPut: -779, futurePrice: 987.8 },
        { date: '11/11/2022', callPut: -3575, futurePrice: 1003.5 },
        { date: '14/11/2022', callPut: -1679, futurePrice: 992.8 },
        { date: '15/11/2022', callPut: -1775, futurePrice: 993.7 },
        { date: '16/11/2022', callPut: -1541, futurePrice: 985.2 },
        { date: '17/11/2022', callPut: -3259, futurePrice: 980.9 },
        { date: '18/11/2022', callPut: -1056, futurePrice: 983.7 },
        { date: '21/11/2022', callPut: -944, futurePrice: 984.8 },
        { date: '22/11/2022', callPut: -1966, futurePrice: 983.6 },
        { date: '23/11/2022', callPut: -2746, futurePrice: 991.7 },
        { date: '24/11/2022', callPut: 79, futurePrice: 988.5 },
        { date: '25/11/2022', callPut: -1816, futurePrice: 982.8 },
        { date: '28/11/2022', callPut: -1721, futurePrice: 979.5 },
        { date: '29/11/2022', callPut: -1602, futurePrice: 987.1 },
        { date: '30/11/2022', callPut: -1887, futurePrice: 994.8 },
        { date: '1/12/2022', callPut: -1447, futurePrice: 994.3 },
        { date: '2/12/2022', callPut: -435, futurePrice: 988.5 },
        { date: '6/12/2022', callPut: -417, futurePrice: 984.4 },
        { date: '7/12/2022', callPut: -1665, futurePrice: 980.9 },
        { date: '8/12/2022', callPut: -990, futurePrice: 976.5 },
        { date: '9/12/2022', callPut: -1720, futurePrice: 981.2 },
        { date: '13/12/2022', callPut: -324, futurePrice: 981.3 },
        { date: '14/12/2022', callPut: -957, futurePrice: 986.5 },
        { date: '15/12/2022', callPut: -2515, futurePrice: 977.9 },
        { date: '16/12/2022', callPut: -850, futurePrice: 981.9 },
        { date: '19/12/2022', callPut: 781, futurePrice: 980.3 },
        { date: '20/12/2022', callPut: 518, futurePrice: 975.1 },
        { date: '21/12/2022', callPut: -85, futurePrice: 974 },
        { date: '22/12/2022', callPut: -172, futurePrice: 977.3 },
        { date: '23/12/2022', callPut: 300, futurePrice: 981.3 },
        { date: '26/12/2022', callPut: 318, futurePrice: 985.4 },
        { date: '27/12/2022', callPut: 4778, futurePrice: 998.6 },
        { date: '28/12/2022', callPut: 1738, futurePrice: 999.8 },
        { date: '29/12/2022', callPut: 2064, futurePrice: 1007.94 },
        { date: '30/12/2022', callPut: 0, futurePrice: 0 },
        { date: '2/1/2023', callPut: 0, futurePrice: 0 },
      ],
      openInterestData: [
        { date: '29/6/2022', OI: -439, futurePrice: 951.2 },
        { date: '30/6/2022', OI: -347, futurePrice: 938.9 },
        { date: '1/7/2022', OI: -427, futurePrice: 943.9 },
        { date: '4/7/2022', OI: -199, futurePrice: 940.5 },
        { date: '5/7/2022', OI: -12, futurePrice: 934 },
        { date: '6/7/2022', OI: -63, futurePrice: 937.2 },
        { date: '7/7/2022', OI: -107, futurePrice: 948.9 },
        { date: '8/7/2022', OI: -126, futurePrice: 949.6 },
        { date: '11/7/2022', OI: -91, futurePrice: 949.2 },
        { date: '12/7/2022', OI: -46, futurePrice: 941.6 },
        { date: '14/7/2022', OI: -154, futurePrice: 935 },
        { date: '15/7/2022', OI: -748, futurePrice: 933.9 },
        { date: '18/7/2022', OI: -855, futurePrice: 940.6 },
        { date: '19/7/2022', OI: -1689, futurePrice: 934.2 },
        { date: '20/7/2022', OI: -1878, futurePrice: 934.2 },
        { date: '21/7/2022', OI: -1907, futurePrice: 937.5 },
        { date: '22/7/2022', OI: -1932, futurePrice: 945.3 },
        { date: '25/7/2022', OI: -2010, futurePrice: 947 },
        { date: '26/7/2022', OI: -2051, futurePrice: 944.6 },
        { date: '27/7/2022', OI: -2184, futurePrice: 951.3 },
        { date: '1/8/2022', OI: -2220, futurePrice: 957.6 },
        { date: '2/8/2022', OI: -2245, futurePrice: 956.9 },
        { date: '3/8/2022', OI: -2291, futurePrice: 960.3 },
        { date: '4/8/2022', OI: -2323, futurePrice: 959.9 },
        { date: '5/8/2022', OI: -2309, futurePrice: 961 },
        { date: '8/8/2022', OI: -2117, futurePrice: 969.7 },
        { date: '9/8/2022', OI: -1610, futurePrice: 975.6 },
        { date: '10/8/2022', OI: -1581, futurePrice: 973.7 },
        { date: '11/8/2022', OI: -1328, futurePrice: 977.4 },
        { date: '15/8/2022', OI: -1043, futurePrice: 981.4 },
        { date: '16/8/2022', OI: -989, futurePrice: 979.7 },
        { date: '17/8/2022', OI: -1067, futurePrice: 984.1 },
        { date: '18/8/2022', OI: -1079, futurePrice: 985.3 },
        { date: '19/8/2022', OI: -926, futurePrice: 976.6 },
        { date: '22/8/2022', OI: -950, futurePrice: 968.7 },
        { date: '23/8/2022', OI: -894, futurePrice: 981.9 },
        { date: '24/8/2022', OI: -1149, futurePrice: 982.5 },
        { date: '25/8/2022', OI: -599, futurePrice: 989.1 },
        { date: '26/8/2022', OI: -744, futurePrice: 989.5 },
        { date: '29/8/2022', OI: -564, futurePrice: 976.5 },
        { date: '30/8/2022', OI: -753, futurePrice: 987.5 },
        { date: '31/8/2022', OI: -851, futurePrice: 987.5 },
        { date: '1/9/2022', OI: -903, futurePrice: 979 },
        { date: '2/9/2022', OI: -1086, futurePrice: 975.8 },
        { date: '5/9/2022', OI: -1054, futurePrice: 975.8 },
        { date: '6/9/2022', OI: -1132, futurePrice: 983.3 },
        { date: '7/9/2022', OI: -1342, futurePrice: 982.4 },
        { date: '8/9/2022', OI: -1696, futurePrice: 980.9 },
        { date: '9/9/2022', OI: -1889, futurePrice: 987.8 },
        { date: '12/9/2022', OI: -2167, futurePrice: 997.1 },
        { date: '13/9/2022', OI: -3277, futurePrice: 998.3 },
        { date: '14/9/2022', OI: -3924, futurePrice: 993.6 },
        { date: '15/9/2022', OI: -4567, futurePrice: 984 },
        { date: '16/9/2022', OI: -4951, futurePrice: 978.5 },
        { date: '19/9/2022', OI: -5470, futurePrice: 978 },
        { date: '20/9/2022', OI: -5949, futurePrice: 980.4 },
        { date: '21/9/2022', OI: -4525, futurePrice: 980.9 },
        { date: '22/9/2022', OI: -4649, futurePrice: 985.3 },
        { date: '23/9/2022', OI: -6108, futurePrice: 976.1 },
        { date: '26/9/2022', OI: -6454, futurePrice: 971 },
        { date: '27/9/2022', OI: -8377, futurePrice: 969.2 },
        { date: '28/9/2022', OI: -8903, futurePrice: 961.5 },
        { date: '29/9/2022', OI: -13664, futurePrice: 957.6 },
        { date: '30/9/2022', OI: -15114, futurePrice: 951.8 },
        { date: '3/10/2022', OI: -13522, futurePrice: 940 },
        { date: '4/10/2022', OI: -13969, futurePrice: 943.5 },
        { date: '5/10/2022', OI: -13809, futurePrice: 942 },
        { date: '6/10/2022', OI: -13826, futurePrice: 947.6 },
        { date: '7/10/2022', OI: -14061, futurePrice: 946.1 },
        { date: '10/10/2022', OI: -10901, futurePrice: 937.1 },
        { date: '11/10/2022', OI: -9546, futurePrice: 931.8 },
        { date: '12/10/2022', OI: -9858, futurePrice: 936.6 },
        { date: '17/10/2022', OI: -10320, futurePrice: 940.7 },
        { date: '18/10/2022', OI: -11973, futurePrice: 949.1 },
        { date: '19/10/2022', OI: -12606, futurePrice: 951 },
        { date: '20/10/2022', OI: -13993, futurePrice: 958.5 },
        { date: '21/10/2022', OI: -15244, futurePrice: 959.2 },
        { date: '25/10/2022', OI: -16713, futurePrice: 965.2 },
        { date: '26/10/2022', OI: -17312, futurePrice: 965.8 },
        { date: '27/10/2022', OI: -19657, futurePrice: 973.6 },
        { date: '28/10/2022', OI: -21758, futurePrice: 977.1 },
        { date: '31/10/2022', OI: -24872, futurePrice: 979.1 },
        { date: '1/11/2022', OI: -30524, futurePrice: 992.1 },
        { date: '2/11/2022', OI: -32509, futurePrice: 991.1 },
        { date: '3/11/2022', OI: -34588, futurePrice: 988.7 },
        { date: '4/11/2022', OI: -36024, futurePrice: 985.9 },
        { date: '7/11/2022', OI: -36953, futurePrice: 988.5 },
        { date: '8/11/2022', OI: -38022, futurePrice: 996.6 },
        { date: '9/11/2022', OI: -38569, futurePrice: 988.8 },
        { date: '10/11/2022', OI: -38521, futurePrice: 987.8 },
        { date: '11/11/2022', OI: -39302, futurePrice: 1003.5 },
        { date: '14/11/2022', OI: -40209, futurePrice: 992.8 },
        { date: '15/11/2022', OI: -40844, futurePrice: 993.7 },
        { date: '16/11/2022', OI: -41368, futurePrice: 985.2 },
        { date: '17/11/2022', OI: -42977, futurePrice: 980.9 },
        { date: '18/11/2022', OI: -43224, futurePrice: 983.7 },
        { date: '21/11/2022', OI: -42650, futurePrice: 984.8 },
        { date: '22/11/2022', OI: -43130, futurePrice: 983.6 },
        { date: '23/11/2022', OI: -43659, futurePrice: 991.7 },
        { date: '24/11/2022', OI: -43480, futurePrice: 988.5 },
        { date: '25/11/2022', OI: -43670, futurePrice: 982.8 },
        { date: '28/11/2022', OI: -43569, futurePrice: 979.5 },
        { date: '29/11/2022', OI: -44061, futurePrice: 987.1 },
        { date: '30/11/2022', OI: -45846, futurePrice: 994.8 },
        { date: '1/12/2022', OI: -45832, futurePrice: 994.3 },
        { date: '2/12/2022', OI: -46415, futurePrice: 988.5 },
        { date: '6/12/2022', OI: -46169, futurePrice: 984.4 },
        { date: '7/12/2022', OI: -44727, futurePrice: 980.9 },
        { date: '8/12/2022', OI: -42646, futurePrice: 976.5 },
        { date: '9/12/2022', OI: -41590, futurePrice: 981.2 },
        { date: '13/12/2022', OI: -41809, futurePrice: 981.3 },
        { date: '14/12/2022', OI: -42368, futurePrice: 986.5 },
        { date: '15/12/2022', OI: -41571, futurePrice: 977.9 },
        { date: '16/12/2022', OI: -41439, futurePrice: 981.9 },
        { date: '19/12/2022', OI: -40568, futurePrice: 980.3 },
        { date: '20/12/2022', OI: -39762, futurePrice: 975.1 },
        { date: '21/12/2022', OI: -39803, futurePrice: 974 },
        { date: '22/12/2022', OI: -39152, futurePrice: 977.3 },
        { date: '23/12/2022', OI: -39014, futurePrice: 981.3 },
        { date: '26/12/2022', OI: -38983, futurePrice: 985.4 },
        { date: '27/12/2022', OI: -41403, futurePrice: 998.6 },
        { date: '28/12/2022', OI: -42855, futurePrice: 999.8 },
        { date: '29/12/2022', OI: -44543, futurePrice: 1007.94 },
        { date: '30/12/2022', OI: 0, futurePrice: 0 },
        { date: '2/1/2023', OI: 0, futurePrice: 0 }
      ]
    }
  },
  2023: {
    S50H23: {
      volumeData: [
        { date: '29/9/2565', callPut: -121, futurePrice: 951.7 },
        { date: '30/9/2565', callPut: -167, futurePrice: 946.6 },
        { date: '3/10/2565', callPut: -2289, futurePrice: 935.1 },
        { date: '4/10/2565', callPut: -159, futurePrice: 938.5 },
        { date: '5/10/2565', callPut: -75, futurePrice: 936.9 },
        { date: '6/10/2565', callPut: -141, futurePrice: 942.4 },
        { date: '7/10/2565', callPut: -105, futurePrice: 940.9 },
        { date: '10/10/2565', callPut: -213, futurePrice: 932.2 },
        { date: '11/10/2565', callPut: -487, futurePrice: 927 },
        { date: '12/10/2565', callPut: 11, futurePrice: 931.3 },
        { date: '17/10/2565', callPut: -270, futurePrice: 935.7 },
        { date: '18/10/2565', callPut: 75, futurePrice: 943.9 },
        { date: '19/10/2565', callPut: 25, futurePrice: 945.4 },
        { date: '20/10/2565', callPut: 55, futurePrice: 952.8 },
        { date: '21/10/2565', callPut: 23, futurePrice: 953.5 },
        { date: '25/10/2565', callPut: -39, futurePrice: 959.2 },
        { date: '26/10/2565', callPut: 209, futurePrice: 959.9 },
        { date: '27/10/2565', callPut: 287, futurePrice: 967.6 },
        { date: '28/10/2565', callPut: 60, futurePrice: 970.6 },
        { date: '31/10/2565', callPut: -66, futurePrice: 972.9 },
        { date: '1/11/2565', callPut: 415, futurePrice: 985.8 },
        { date: '2/11/2565', callPut: 40, futurePrice: 984.8 },
        { date: '3/11/2565', callPut: 21, futurePrice: 982.4 },
        { date: '4/11/2565', callPut: 176, futurePrice: 979.3 },
        { date: '7/11/2565', callPut: 3, futurePrice: 982.1 },
        { date: '8/11/2565', callPut: 17, futurePrice: 989.9 },
        { date: '9/11/2565', callPut: 58, futurePrice: 982 },
        { date: '10/11/2565', callPut: -187, futurePrice: 981.3 },
        { date: '11/11/2565', callPut: 246, futurePrice: 997.1 },
        { date: '14/11/2565', callPut: -126, futurePrice: 986.7 },
        { date: '15/11/2565', callPut: 133, futurePrice: 987.3 },
        { date: '16/11/2565', callPut: -36, futurePrice: 978.9 },
        { date: '17/11/2565', callPut: 24, futurePrice: 975 },
        { date: '18/11/2565', callPut: 29, futurePrice: 977.9 },
        { date: '21/11/2565', callPut: 149, futurePrice: 979.1 },
        { date: '22/11/2565', callPut: -102, futurePrice: 978 },
        { date: '23/11/2565', callPut: 129, futurePrice: 985.8 },
        { date: '24/11/2565', callPut: -43, futurePrice: 982.5 },
        { date: '25/11/2565', callPut: -53, futurePrice: 977.6 },
        { date: '28/11/2565', callPut: 35, futurePrice: 974.2 },
        { date: '29/11/2565', callPut: -649, futurePrice: 981.5 },
        { date: '30/11/2565', callPut: -23, futurePrice: 988.8 },
        { date: '1/12/2565', callPut: -194, futurePrice: 988.3 },
        { date: '2/12/2565', callPut: -235, futurePrice: 982.7 },
        { date: '6/12/2565', callPut: -820, futurePrice: 978.9 },
        { date: '7/12/2565', callPut: -493, futurePrice: 975.3 },
        { date: '8/12/2565', callPut: 199, futurePrice: 970.4 },
        { date: '9/12/2565', callPut: -690, futurePrice: 975 },
        { date: '13/12/2565', callPut: -688, futurePrice: 974.7 },
        { date: '14/12/2565', callPut: -611, futurePrice: 978.8 },
        { date: '15/12/2565', callPut: 167, futurePrice: 970.4 },
        { date: '16/12/2565', callPut: -610, futurePrice: 973.8 },
        { date: '19/12/2565', callPut: -327, futurePrice: 972.4 },
        { date: '20/12/2565', callPut: -625, futurePrice: 968.9 },
        { date: '21/12/2565', callPut: -27, futurePrice: 968.5 },
        { date: '22/12/2565', callPut: -410, futurePrice: 971.2 },
        { date: '23/12/2565', callPut: 121, futurePrice: 973.8 },
        { date: '26/12/2565', callPut: -335, futurePrice: 978.4 },
        { date: '27/12/2565', callPut: 971, futurePrice: 994.4 },
        { date: '28/12/2565', callPut: 1479, futurePrice: 997.1 },
        { date: '29/12/2565', callPut: -476, futurePrice: 1002.4 },
        { date: '30/12/2565', callPut: 790, futurePrice: 1007.3 },
        { date: '3/1/2566', callPut: -820, futurePrice: 1008.1 },
        { date: '4/1/2566', callPut: 360, futurePrice: 1005.1 },
        { date: '5/1/2566', callPut: -497, futurePrice: 999.5 },
        { date: '6/1/2566', callPut: 1034, futurePrice: 1003.6 },
        { date: '9/1/2566', callPut: 494, futurePrice: 1014.6 },
        { date: '10/1/2566', callPut: 3689, futurePrice: 1012.5 },
        { date: '11/1/2566', callPut: -951, futurePrice: 1008.5 },
        { date: '12/1/2566', callPut: 54, futurePrice: 1009.8 },
        { date: '13/1/2566', callPut: 104, futurePrice: 1003.6 },
        { date: '16/1/2566', callPut: -3542, futurePrice: 1003.5 },
        { date: '17/1/2566', callPut: -349, futurePrice: 1002.5 },
        { date: '18/1/2566', callPut: -167, futurePrice: 1004.6 },
        { date: '19/1/2566', callPut: -622, futurePrice: 1005.8 },
        { date: '20/1/2566', callPut: -1542, futurePrice: 1000.2 },
        { date: '23/1/2566', callPut: -4664, futurePrice: 1004.4 },
        { date: '24/1/2566', callPut: -1961, futurePrice: 1001.6 },
        { date: '25/1/2566', callPut: -2978, futurePrice: 1002.1 },
        { date: '26/1/2566', callPut: 1197, futurePrice: 995.3 },
        { date: '27/1/2566', callPut: 12, futurePrice: 1000 },
        { date: '30/1/2566', callPut: -1403, futurePrice: 998.9 },
        { date: '31/1/2566', callPut: -1353, futurePrice: 990.3 },
        { date: '1/2/2566', callPut: -4732, futurePrice: 997.5 },
        { date: '2/2/2566', callPut: -472, futurePrice: 996.1 },
        { date: '3/2/2566', callPut: 112, futurePrice: 1001.1 },
        { date: '6/2/2566', callPut: -58, futurePrice: 995 },
        { date: '7/2/2566', callPut: 523, futurePrice: 995.3 },
        { date: '8/2/2566', callPut: 1481, futurePrice: 988.3 },
        { date: '9/2/2566', callPut: 3554, futurePrice: 987 },
        { date: '10/2/2566', callPut: 228, futurePrice: 982.7 },
        { date: '13/2/2566', callPut: 978, futurePrice: 984.5 },
        { date: '14/2/2566', callPut: 732, futurePrice: 984.3 },
        { date: '15/2/2566', callPut: 795, futurePrice: 983.8 },
        { date: '16/2/2566', callPut: 462, futurePrice: 990.9 },
        { date: '17/2/2566', callPut: 1019, futurePrice: 988.4 },
        { date: '20/2/2566', callPut: -933, futurePrice: 989.1 },
        { date: '21/2/2566', callPut: 1574, futurePrice: 994.7 },
        { date: '22/2/2566', callPut: 2129, futurePrice: 986.4 },
        { date: '23/2/2566', callPut: 1122, futurePrice: 983.1 },
        { date: '24/2/2566', callPut: 3443, futurePrice: 967.7 },
        { date: '27/2/2566', callPut: -3537, futurePrice: 967.5 },
        { date: '28/2/2566', callPut: 194, futurePrice: 965.8 },
        { date: '1/3/2566', callPut: 808, futurePrice: 961.4 },
        { date: '2/3/2566', callPut: 1093, futurePrice: 962.3 },
        { date: '3/3/2566', callPut: -548, futurePrice: 957.8 },
        { date: '7/3/2566', callPut: 2495, futurePrice: 964.9 },
        { date: '8/3/2566', callPut: -576, futurePrice: 959.6 },
        { date: '9/3/2566', callPut: -667, futurePrice: 960.1 },
        { date: '10/3/2566', callPut: -2640, futurePrice: 954.1 },
        { date: '13/3/2566', callPut: -1908, futurePrice: 932.4 },
        { date: '14/3/2566', callPut: -23857, futurePrice: 905.4 },
        { date: '15/3/2566', callPut: -3211, futurePrice: 932.3 },
        { date: '16/3/2566', callPut: -2443, futurePrice: 928.7 },
        { date: '17/3/2566', callPut: -3481, futurePrice: 939.5 },
        { date: '20/3/2566', callPut: -3563, futurePrice: 933.2 },
        { date: '21/3/2566', callPut: -286, futurePrice: 948.4 },
        { date: '22/3/2566', callPut: 2261, futurePrice: 953.9 },
        { date: '23/3/2566', callPut: 4564, futurePrice: 959.1 },
        { date: '24/3/2566', callPut: 7345, futurePrice: 955.8 },
        { date: '27/3/2566', callPut: 500, futurePrice: 959.7 },
        { date: '28/3/2566', callPut: 2499, futurePrice: 966.7 },
        { date: '29/3/2566', callPut: 2808, futurePrice: 972.6 },
        { date: '30/3/2566', callPut: 2335, futurePrice: 968.42 },
        { date: '31/3/2566', callPut: 0, futurePrice: 0 },
        { date: '3/4/2566', callPut: 0, futurePrice: 0 }
      ],
      openInterestData: [
        { date: '29/9/2565', OI: -101, futurePrice: 951.7 },
        { date: '30/9/2565', OI: -255, futurePrice: 946.6 },
        { date: '3/10/2565', OI: -2410, futurePrice: 935.1 },
        { date: '4/10/2565', OI: -2521, futurePrice: 938.5 },
        { date: '5/10/2565', OI: -2564, futurePrice: 936.9 },
        { date: '6/10/2565', OI: -2659, futurePrice: 942.4 },
        { date: '7/10/2565', OI: -2730, futurePrice: 940.9 },
        { date: '10/10/2565', OI: -2925, futurePrice: 932.2 },
        { date: '11/10/2565', OI: -3392, futurePrice: 927 },
        { date: '12/10/2565', OI: -3374, futurePrice: 931.3 },
        { date: '17/10/2565', OI: -3594, futurePrice: 935.7 },
        { date: '18/10/2565', OI: -3482, futurePrice: 943.9 },
        { date: '19/10/2565', OI: -3460, futurePrice: 945.4 },
        { date: '20/10/2565', OI: -3369, futurePrice: 952.8 },
        { date: '21/10/2565', OI: -3352, futurePrice: 953.5 },
        { date: '25/10/2565', OI: -3379, futurePrice: 959.2 },
        { date: '26/10/2565', OI: -3229, futurePrice: 959.9 },
        { date: '27/10/2565', OI: -2958, futurePrice: 967.6 },
        { date: '28/10/2565', OI: -2909, futurePrice: 970.6 },
        { date: '31/10/2565', OI: -2918, futurePrice: 972.9 },
        { date: '1/11/2565', OI: -2583, futurePrice: 985.8 },
        { date: '2/11/2565', OI: -2658, futurePrice: 984.8 },
        { date: '3/11/2565', OI: -2734, futurePrice: 982.4 },
        { date: '4/11/2565', OI: -2757, futurePrice: 979.3 },
        { date: '7/11/2565', OI: -2737, futurePrice: 982.1 },
        { date: '8/11/2565', OI: -2627, futurePrice: 989.9 },
        { date: '9/11/2565', OI: -2582, futurePrice: 982 },
        { date: '10/11/2565', OI: -2657, futurePrice: 981.3 },
        { date: '11/11/2565', OI: -2389, futurePrice: 997.1 },
        { date: '14/11/2565', OI: -2459, futurePrice: 986.7 },
        { date: '15/11/2565', OI: -2371, futurePrice: 987.3 },
        { date: '16/11/2565', OI: -2328, futurePrice: 978.9 },
        { date: '17/11/2565', OI: -2445, futurePrice: 975 },
        { date: '18/11/2565', OI: -2415, futurePrice: 977.9 },
        { date: '21/11/2565', OI: -2402, futurePrice: 979.1 },
        { date: '22/11/2565', OI: -2503, futurePrice: 978 },
        { date: '23/11/2565', OI: -2465, futurePrice: 985.8 },
        { date: '24/11/2565', OI: -2495, futurePrice: 982.5 },
        { date: '25/11/2565', OI: -2536, futurePrice: 977.6 },
        { date: '28/11/2565', OI: -2510, futurePrice: 974.2 },
        { date: '29/11/2565', OI: -2641, futurePrice: 981.5 },
        { date: '30/11/2565', OI: -2362, futurePrice: 988.8 },
        { date: '1/12/2565', OI: -2619, futurePrice: 988.3 },
        { date: '2/12/2565', OI: -2945, futurePrice: 982.7 },
        { date: '6/12/2565', OI: -3191, futurePrice: 978.9 },
        { date: '7/12/2565', OI: -3634, futurePrice: 975.3 },
        { date: '8/12/2565', OI: -3415, futurePrice: 970.4 },
        { date: '9/12/2565', OI: -3931, futurePrice: 975 },
        { date: '13/12/2565', OI: -4731, futurePrice: 974.7 },
        { date: '14/12/2565', OI: -5227, futurePrice: 978.8 },
        { date: '15/12/2565', OI: -5060, futurePrice: 970.4 },
        { date: '16/12/2565', OI: -5545, futurePrice: 973.8 },
        { date: '19/12/2565', OI: -5715, futurePrice: 972.4 },
        { date: '20/12/2565', OI: -6956, futurePrice: 968.9 },
        { date: '21/12/2565', OI: -7013, futurePrice: 968.5 },
        { date: '22/12/2565', OI: -7457, futurePrice: 971.2 },
        { date: '23/12/2565', OI: -7416, futurePrice: 973.8 },
        { date: '26/12/2565', OI: -7821, futurePrice: 978.4 },
        { date: '27/12/2565', OI: -8085, futurePrice: 994.4 },
        { date: '28/12/2565', OI: -7421, futurePrice: 997.1 },
        { date: '29/12/2565', OI: -9396, futurePrice: 1002.4 },
        { date: '30/12/2565', OI: -10393, futurePrice: 1007.3 },
        { date: '3/1/2566', OI: -10736, futurePrice: 1008.1 },
        { date: '4/1/2566', OI: -10695, futurePrice: 1005.1 },
        { date: '5/1/2566', OI: -11722, futurePrice: 999.5 },
        { date: '6/1/2566', OI: -11942, futurePrice: 1003.6 },
        { date: '9/1/2566', OI: -9320, futurePrice: 1014.6 },
        { date: '10/1/2566', OI: -7377, futurePrice: 1012.5 },
        { date: '11/1/2566', OI: -8812, futurePrice: 1008.5 },
        { date: '12/1/2566', OI: -9534, futurePrice: 1009.8 },
        { date: '13/1/2566', OI: -9169, futurePrice: 1003.6 },
        { date: '16/1/2566', OI: -12322, futurePrice: 1003.5 },
        { date: '17/1/2566', OI: -12832, futurePrice: 1002.5 },
        { date: '18/1/2566', OI: -12960, futurePrice: 1004.6 },
        { date: '19/1/2566', OI: -13829, futurePrice: 1005.8 },
        { date: '20/1/2566', OI: -13602, futurePrice: 1000.2 },
        { date: '23/1/2566', OI: -17822, futurePrice: 1004.4 },
        { date: '24/1/2566', OI: -19677, futurePrice: 1001.6 },
        { date: '25/1/2566', OI: -22306, futurePrice: 1002.1 },
        { date: '26/1/2566', OI: -21061, futurePrice: 995.3 },
        { date: '27/1/2566', OI: -21547, futurePrice: 1000 },
        { date: '30/1/2566', OI: -21780, futurePrice: 998.9 },
        { date: '31/1/2566', OI: -23294, futurePrice: 990.3 },
        { date: '1/2/2566', OI: -27840, futurePrice: 997.5 },
        { date: '2/2/2566', OI: -28455, futurePrice: 996.1 },
        { date: '3/2/2566', OI: -28583, futurePrice: 1001.1 },
        { date: '6/2/2566', OI: -28346, futurePrice: 995 },
        { date: '7/2/2566', OI: -28785, futurePrice: 995.3 },
        { date: '8/2/2566', OI: -27647, futurePrice: 988.3 },
        { date: '9/2/2566', OI: -24968, futurePrice: 987 },
        { date: '10/2/2566', OI: -24087, futurePrice: 982.7 },
        { date: '13/2/2566', OI: -23711, futurePrice: 984.5 },
        { date: '14/2/2566', OI: -23487, futurePrice: 984.3 },
        { date: '15/2/2566', OI: -22853, futurePrice: 983.8 },
        { date: '16/2/2566', OI: -22514, futurePrice: 990.9 },
        { date: '17/2/2566', OI: -21617, futurePrice: 988.4 },
        { date: '20/2/2566', OI: -20298, futurePrice: 989.1 },
        { date: '21/2/2566', OI: -20062, futurePrice: 994.7 },
        { date: '22/2/2566', OI: -17795, futurePrice: 986.4 },
        { date: '23/2/2566', OI: -14325, futurePrice: 983.1 },
        { date: '24/2/2566', OI: -8471, futurePrice: 967.7 },
        { date: '27/2/2566', OI: -7001, futurePrice: 967.5 },
        { date: '28/2/2566', OI: -3912, futurePrice: 965.8 },
        { date: '1/3/2566', OI: -1814, futurePrice: 961.4 },
        { date: '2/3/2566', OI: -1950, futurePrice: 962.3 },
        { date: '3/3/2566', OI: 611, futurePrice: 957.8 },
        { date: '7/3/2566', OI: -660, futurePrice: 964.9 },
        { date: '8/3/2566', OI: 2170, futurePrice: 959.6 },
        { date: '9/3/2566', OI: 3193, futurePrice: 960.1 },
        { date: '10/3/2566', OI: 5271, futurePrice: 954.1 },
        { date: '13/3/2566', OI: 13141, futurePrice: 932.4 },
        { date: '14/3/2566', OI: 32430, futurePrice: 905.4 },
        { date: '15/3/2566', OI: 33860, futurePrice: 932.3 },
        { date: '16/3/2566', OI: 36743, futurePrice: 928.7 },
        { date: '17/3/2566', OI: 36339, futurePrice: 939.5 },
        { date: '20/3/2566', OI: 35378, futurePrice: 933.2 },
        { date: '21/3/2566', OI: 35036, futurePrice: 948.4 },
        { date: '22/3/2566', OI: 33654, futurePrice: 953.9 },
        { date: '23/3/2566', OI: 32026, futurePrice: 959.1 },
        { date: '24/3/2566', OI: 27924, futurePrice: 955.8 },
        { date: '27/3/2566', OI: 27467, futurePrice: 959.7 },
        { date: '28/3/2566', OI: 27130, futurePrice: 966.7 },
        { date: '29/3/2566', OI: 25032, futurePrice: 972.6 },
        { date: '30/3/2566', OI: 25281, futurePrice: 968.42 },
        { date: '31/3/2566', OI: 0, futurePrice: 0 },
        { date: '3/4/2566', OI: 0, futurePrice: 0 }
      ]
    },
    S50M23: {
      volumeData: [
        { date: '29/12/2022', callPut: -110, futurePrice: 999.5 },
        { date: '30/12/2022', callPut: 177, futurePrice: 1004.4 },
        { date: '3/1/2023', callPut: 8, futurePrice: 1005 },
        { date: '4/1/2023', callPut: -76, futurePrice: 1001.9 },
        { date: '5/1/2023', callPut: 26, futurePrice: 996.1 },
        { date: '6/1/2023', callPut: 455, futurePrice: 1000.3 },
        { date: '9/1/2023', callPut: -1585, futurePrice: 1011 },
        { date: '10/1/2023', callPut: 212, futurePrice: 1009 },
        { date: '11/1/2023', callPut: -11, futurePrice: 1004.8 },
        { date: '12/1/2023', callPut: 28, futurePrice: 1006.3 },
        { date: '13/1/2023', callPut: -117, futurePrice: 1000 },
        { date: '16/1/2023', callPut: -48, futurePrice: 1000.1 },
        { date: '17/1/2023', callPut: 133, futurePrice: 999.1 },
        { date: '18/1/2023', callPut: 237, futurePrice: 1001.2 },
        { date: '19/1/2023', callPut: -40, futurePrice: 1002.5 },
        { date: '20/1/2023', callPut: -252, futurePrice: 997.1 },
        { date: '23/1/2023', callPut: -166, futurePrice: 1000.9 },
        { date: '24/1/2023', callPut: 22, futurePrice: 998.3 },
        { date: '25/1/2023', callPut: -71, futurePrice: 998.8 },
        { date: '26/1/2023', callPut: -506, futurePrice: 992.3 },
        { date: '27/1/2023', callPut: -80, futurePrice: 996.9 },
        { date: '30/1/2023', callPut: 1, futurePrice: 996.1 },
        { date: '31/1/2023', callPut: 106, futurePrice: 988.6 },
        { date: '1/2/2023', callPut: 51, futurePrice: 995.4 },
        { date: '2/2/2023', callPut: 167, futurePrice: 994.3 },
        { date: '3/2/2023', callPut: -356, futurePrice: 998.6 },
        { date: '6/2/2023', callPut: -295, futurePrice: 993.2 },
        { date: '7/2/2023', callPut: -145, futurePrice: 993.4 },
        { date: '8/2/2023', callPut: -84, futurePrice: 986.9 },
        { date: '9/2/2023', callPut: -47, futurePrice: 985.7 },
        { date: '10/2/2023', callPut: 396, futurePrice: 981.9 },
        { date: '13/2/2023', callPut: -538, futurePrice: 983.3 },
        { date: '14/2/2023', callPut: -884, futurePrice: 982.8 },
        { date: '15/2/2023', callPut: 54, futurePrice: 982.5 },
        { date: '16/2/2023', callPut: 429, futurePrice: 988.8 },
        { date: '17/2/2023', callPut: -1267, futurePrice: 986.6 },
        { date: '20/2/2023', callPut: 10, futurePrice: 987.3 },
        { date: '21/2/2023', callPut: 255, futurePrice: 992.8 },
        { date: '22/2/2023', callPut: -1547, futurePrice: 985.3 },
        { date: '23/2/2023', callPut: -251, futurePrice: 981.9 },
        { date: '24/2/2023', callPut: -867, futurePrice: 966.8 },
        { date: '27/2/2023', callPut: 240, futurePrice: 966.6 },
        { date: '28/2/2023', callPut: -259, futurePrice: 964.8 },
        { date: '1/3/2023', callPut: 109, futurePrice: 960.6 },
        { date: '2/3/2023', callPut: 183, futurePrice: 961.3 },
        { date: '3/3/2023', callPut: 26, futurePrice: 956.9 },
        { date: '7/3/2023', callPut: 246, futurePrice: 963.3 },
        { date: '8/3/2023', callPut: 189, futurePrice: 957.9 },
        { date: '9/3/2023', callPut: 633, futurePrice: 958.2 },
        { date: '10/3/2023', callPut: 616, futurePrice: 952.2 },
        { date: '13/3/2023', callPut: -2798, futurePrice: 929.6 },
        { date: '14/3/2023', callPut: -4722, futurePrice: 901.8 },
        { date: '15/3/2023', callPut: -336, futurePrice: 928.8 },
        { date: '16/3/2023', callPut: -322, futurePrice: 926.2 },
        { date: '17/3/2023', callPut: -225, futurePrice: 936.9 },
        { date: '20/3/2023', callPut: -260, futurePrice: 930.1 },
        { date: '21/3/2023', callPut: 82, futurePrice: 945.1 },
        { date: '22/3/2023', callPut: 179, futurePrice: 950.6 },
        { date: '23/3/2023', callPut: 535, futurePrice: 956.3 },
        { date: '24/3/2023', callPut: 517, futurePrice: 952.5 },
        { date: '27/3/2023', callPut: 436, futurePrice: 956.1 },
        { date: '28/3/2023', callPut: 1101, futurePrice: 962.5 },
        { date: '29/3/2023', callPut: 1161, futurePrice: 967.5 },
        { date: '30/3/2023', callPut: 2028, futurePrice: 963.9 },
        { date: '31/3/2023', callPut: -972, futurePrice: 968.3 },
        { date: '3/4/2023', callPut: -71, futurePrice: 962.3 },
        { date: '4/4/2023', callPut: 1149, futurePrice: 959.2 },
        { date: '5/4/2023', callPut: 856, futurePrice: 944.1 },
        { date: '7/4/2023', callPut: -35, futurePrice: 946.7 },
        { date: '10/4/2023', callPut: 1427, futurePrice: 961.7 },
        { date: '11/4/2023', callPut: -407, futurePrice: 962.3 },
        { date: '12/4/2023', callPut: 846, futurePrice: 960.5 },
        { date: '17/4/2023', callPut: 783, futurePrice: 964.6 },
        { date: '18/4/2023', callPut: -506, futurePrice: 960.6 },
        { date: '19/4/2023', callPut: -531, futurePrice: 951.7 },
        { date: '20/4/2023', callPut: 761, futurePrice: 943.8 },
        { date: '21/4/2023', callPut: 516, futurePrice: 937.4 },
        { date: '24/4/2023', callPut: -503, futurePrice: 937.8 },
        { date: '25/4/2023', callPut: -696, futurePrice: 930.2 },
        { date: '26/4/2023', callPut: 1117, futurePrice: 932.1 },
        { date: '27/4/2023', callPut: 1038, futurePrice: 924.1 },
        { date: '28/4/2023', callPut: -141, futurePrice: 920.4 },
        { date: '2/5/2023', callPut: -689, futurePrice: 926.9 },
        { date: '3/5/2023', callPut: 215, futurePrice: 928.4 },
        { date: '8/5/2023', callPut: 3651, futurePrice: 945.9 },
        { date: '9/5/2023', callPut: 1411, futurePrice: 947.4 },
        { date: '10/5/2023', callPut: -2130, futurePrice: 947.9 },
        { date: '11/5/2023', callPut: 960, futurePrice: 948.6 },
        { date: '12/5/2023', callPut: 1860, futurePrice: 948.6 },
        { date: '15/5/2023', callPut: 1605, futurePrice: 933.5 },
        { date: '16/5/2023', callPut: -608, futurePrice: 932.8 },
        { date: '17/5/2023', callPut: -873, futurePrice: 920.9 },
        { date: '18/5/2023', callPut: -928, futurePrice: 926.2 },
        { date: '19/5/2023', callPut: 1595, futurePrice: 918.5 },
        { date: '22/5/2023', callPut: -823, futurePrice: 929.8 },
        { date: '23/5/2023', callPut: 1182, futurePrice: 926.2 },
        { date: '24/5/2023', callPut: -343, futurePrice: 926.9 },
        { date: '25/5/2023', callPut: -282, futurePrice: 927.1 },
        { date: '26/5/2023', callPut: 1358, futurePrice: 921.9 },
        { date: '29/5/2023', callPut: -1528, futurePrice: 931 },
        { date: '30/5/2023', callPut: 700, futurePrice: 924.7 },
        { date: '31/5/2023', callPut: 700, futurePrice: 921.7 },
        { date: '1/6/2023', callPut: 1169, futurePrice: 914.2 },
        { date: '2/6/2023', callPut: -203, futurePrice: 923.7 },
        { date: '6/6/2023', callPut: 2054, futurePrice: 921.4 },
        { date: '7/6/2023', callPut: 776, futurePrice: 926.6 },
        { date: '8/6/2023', callPut: 4795, futurePrice: 941.3 },
        { date: '9/6/2023', callPut: 1406, futurePrice: 940.1 },
        { date: '12/6/2023', callPut: -724, futurePrice: 939 },
        { date: '13/6/2023', callPut: -858, futurePrice: 945.2 },
        { date: '14/6/2023', callPut: 916, futurePrice: 946 },
        { date: '15/6/2023', callPut: 140, futurePrice: 947 },
        { date: '16/6/2023', callPut: 3016, futurePrice: 947.3 },
        { date: '19/6/2023', callPut: -414, futurePrice: 948.4 },
        { date: '20/6/2023', callPut: 2899, futurePrice: 935 },
        { date: '21/6/2023', callPut: 2303, futurePrice: 924 },
        { date: '22/6/2023', callPut: -1073, futurePrice: 918.5 },
        { date: '23/6/2023', callPut: -658, futurePrice: 915.4 },
        { date: '26/6/2023', callPut: 190, futurePrice: 908.1 },
        { date: '27/6/2023', callPut: 225, futurePrice: 902.9 },
        { date: '28/6/2023', callPut: -1117, futurePrice: 897.5 },
        { date: '29/6/2023', callPut: -1112, futurePrice: 905.5 },
        { date: '30/6/2023', callPut: 0, futurePrice: 0 },
        { date: '3/7/2023', callPut: 0, futurePrice: 0 },
      ],
      openInterestData: [
        { date: '29/12/2022', OI: -104, futurePrice: 999.5 },
        { date: '30/12/2022', OI: 79, futurePrice: 1004.4 },
        { date: '3/1/2023', OI: 47, futurePrice: 1005 },
        { date: '4/1/2023', OI: -27, futurePrice: 1001.9 },
        { date: '5/1/2023', OI: -4, futurePrice: 996.1 },
        { date: '6/1/2023', OI: 450, futurePrice: 1000.3 },
        { date: '9/1/2023', OI: -1150, futurePrice: 1011 },
        { date: '10/1/2023', OI: -1000, futurePrice: 1009 },
        { date: '11/1/2023', OI: -1005, futurePrice: 1004.8 },
        { date: '12/1/2023', OI: -949, futurePrice: 1006.3 },
        { date: '13/1/2023', OI: -1082, futurePrice: 1000 },
        { date: '16/1/2023', OI: -1134, futurePrice: 1000.1 },
        { date: '17/1/2023', OI: -999, futurePrice: 999.1 },
        { date: '18/1/2023', OI: -922, futurePrice: 1001.2 },
        { date: '19/1/2023', OI: -1003, futurePrice: 1002.5 },
        { date: '20/1/2023', OI: -1280, futurePrice: 997.1 },
        { date: '23/1/2023', OI: -1373, futurePrice: 1000.9 },
        { date: '24/1/2023', OI: -1337, futurePrice: 998.3 },
        { date: '25/1/2023', OI: -1388, futurePrice: 998.8 },
        { date: '26/1/2023', OI: -1821, futurePrice: 992.3 },
        { date: '27/1/2023', OI: -1920, futurePrice: 996.9 },
        { date: '30/1/2023', OI: -1881, futurePrice: 996.1 },
        { date: '31/1/2023', OI: -1830, futurePrice: 988.6 },
        { date: '1/2/2023', OI: -1791, futurePrice: 995.4 },
        { date: '2/2/2023', OI: -1712, futurePrice: 994.3 },
        { date: '3/2/2023', OI: -2032, futurePrice: 998.6 },
        { date: '6/2/2023', OI: -2281, futurePrice: 993.2 },
        { date: '7/2/2023', OI: -2429, futurePrice: 993.4 },
        { date: '8/2/2023', OI: -2517, futurePrice: 986.9 },
        { date: '9/2/2023', OI: -2586, futurePrice: 985.7 },
        { date: '10/2/2023', OI: -2176, futurePrice: 981.9 },
        { date: '13/2/2023', OI: -2599, futurePrice: 983.3 },
        { date: '14/2/2023', OI: -3294, futurePrice: 982.8 },
        { date: '15/2/2023', OI: -3289, futurePrice: 982.5 },
        { date: '16/2/2023', OI: -2988, futurePrice: 988.8 },
        { date: '17/2/2023', OI: -3936, futurePrice: 986.6 },
        { date: '20/2/2023', OI: -3915, futurePrice: 987.3 },
        { date: '21/2/2023', OI: -4066, futurePrice: 992.8 },
        { date: '22/2/2023', OI: -5098, futurePrice: 985.3 },
        { date: '23/2/2023', OI: -5254, futurePrice: 981.9 },
        { date: '24/2/2023', OI: -5908, futurePrice: 966.8 },
        { date: '27/2/2023', OI: -5483, futurePrice: 966.6 },
        { date: '28/2/2023', OI: -5226, futurePrice: 964.8 },
        { date: '1/3/2023', OI: -4928, futurePrice: 960.6 },
        { date: '2/3/2023', OI: -5049, futurePrice: 961.3 },
        { date: '3/3/2023', OI: -4881, futurePrice: 956.9 },
        { date: '7/3/2023', OI: -4904, futurePrice: 963.3 },
        { date: '8/3/2023', OI: -4340, futurePrice: 957.9 },
        { date: '9/3/2023', OI: -4066, futurePrice: 958.2 },
        { date: '10/3/2023', OI: -3392, futurePrice: 952.2 },
        { date: '13/3/2023', OI: -2150, futurePrice: 929.6 },
        { date: '14/3/2023', OI: -332, futurePrice: 901.8 },
        { date: '15/3/2023', OI: 1203, futurePrice: 928.8 },
        { date: '16/3/2023', OI: 71, futurePrice: 926.2 },
        { date: '17/3/2023', OI: 255, futurePrice: 936.9 },
        { date: '20/3/2023', OI: 445, futurePrice: 930.1 },
        { date: '21/3/2023', OI: 936, futurePrice: 945.1 },
        { date: '22/3/2023', OI: 829, futurePrice: 950.6 },
        { date: '23/3/2023', OI: 832, futurePrice: 956.3 },
        { date: '24/3/2023', OI: 1086, futurePrice: 952.5 },
        { date: '27/3/2023', OI: 1395, futurePrice: 956.1 },
        { date: '28/3/2023', OI: 2091, futurePrice: 962.5 },
        { date: '29/3/2023', OI: 2180, futurePrice: 967.5 },
        { date: '30/3/2023', OI: 2814, futurePrice: 963.9 },
        { date: '31/3/2023', OI: 1889, futurePrice: 968.3 },
        { date: '3/4/2023', OI: 1294, futurePrice: 962.3 },
        { date: '4/4/2023', OI: 1807, futurePrice: 959.2 },
        { date: '5/4/2023', OI: 2991, futurePrice: 944.1 },
        { date: '7/4/2023', OI: 3123, futurePrice: 946.7 },
        { date: '10/4/2023', OI: 3685, futurePrice: 961.7 },
        { date: '11/4/2023', OI: 3603, futurePrice: 962.3 },
        { date: '12/4/2023', OI: 3671, futurePrice: 960.5 },
        { date: '17/4/2023', OI: 3955, futurePrice: 964.6 },
        { date: '18/4/2023', OI: 3308, futurePrice: 960.6 },
        { date: '19/4/2023', OI: 3991, futurePrice: 951.7 },
        { date: '20/4/2023', OI: 4878, futurePrice: 943.8 },
        { date: '21/4/2023', OI: 6415, futurePrice: 937.4 },
        { date: '24/4/2023', OI: 6236, futurePrice: 937.8 },
        { date: '25/4/2023', OI: 9099, futurePrice: 930.2 },
        { date: '26/4/2023', OI: 9820, futurePrice: 932.1 },
        { date: '27/4/2023', OI: 10859, futurePrice: 924.1 },
        { date: '28/4/2023', OI: 11678, futurePrice: 920.4 },
        { date: '2/5/2023', OI: 11546, futurePrice: 926.9 },
        { date: '3/5/2023', OI: 13065, futurePrice: 928.4 },
        { date: '8/5/2023', OI: 12899, futurePrice: 945.9 },
        { date: '9/5/2023', OI: 12984, futurePrice: 947.4 },
        { date: '10/5/2023', OI: 12662, futurePrice: 947.9 },
        { date: '11/5/2023', OI: 13035, futurePrice: 948.6 },
        { date: '12/5/2023', OI: 14277, futurePrice: 948.6 },
        { date: '15/5/2023', OI: 15160, futurePrice: 933.5 },
        { date: '16/5/2023', OI: 14808, futurePrice: 932.8 },
        { date: '17/5/2023', OI: 15104, futurePrice: 920.9 },
        { date: '18/5/2023', OI: 14353, futurePrice: 926.2 },
        { date: '19/5/2023', OI: 14369, futurePrice: 918.5 },
        { date: '22/5/2023', OI: 14056, futurePrice: 929.8 },
        { date: '23/5/2023', OI: 14566, futurePrice: 926.2 },
        { date: '24/5/2023', OI: 14635, futurePrice: 926.9 },
        { date: '25/5/2023', OI: 16526, futurePrice: 927.1 },
        { date: '26/5/2023', OI: 17029, futurePrice: 921.9 },
        { date: '29/5/2023', OI: 16869, futurePrice: 931 },
        { date: '30/5/2023', OI: 19256, futurePrice: 924.7 },
        { date: '31/5/2023', OI: 21690, futurePrice: 921.7 },
        { date: '1/6/2023', OI: 22610, futurePrice: 914.2 },
        { date: '2/6/2023', OI: 23056, futurePrice: 923.7 },
        { date: '6/6/2023', OI: 24345, futurePrice: 921.4 },
        { date: '7/6/2023', OI: 25549, futurePrice: 926.6 },
        { date: '8/6/2023', OI: 26405, futurePrice: 941.3 },
        { date: '9/6/2023', OI: 26048, futurePrice: 940.1 },
        { date: '12/6/2023', OI: 25475, futurePrice: 939 },
        { date: '13/6/2023', OI: 24960, futurePrice: 945.2 },
        { date: '14/6/2023', OI: 24172, futurePrice: 946 },
        { date: '15/6/2023', OI: 24650, futurePrice: 947 },
        { date: '16/6/2023', OI: 24303, futurePrice: 947.3 },
        { date: '19/6/2023', OI: 23437, futurePrice: 948.4 },
        { date: '20/6/2023', OI: 25555, futurePrice: 935 },
        { date: '21/6/2023', OI: 26481, futurePrice: 924 },
        { date: '22/6/2023', OI: 28823, futurePrice: 918.5 },
        { date: '23/6/2023', OI: 29635, futurePrice: 915.4 },
        { date: '26/6/2023', OI: 29907, futurePrice: 908.1 },
        { date: '27/6/2023', OI: 30905, futurePrice: 902.9 },
        { date: '28/6/2023', OI: 31985, futurePrice: 897.5 },
        { date: '29/6/2023', OI: 31870, futurePrice: 905.5 },
        { date: '30/6/2023', OI: 0, futurePrice: 0 },
        { date: '3/7/2023', OI: 0, futurePrice: 0 },
      ]
    },
    S50U23: {
      volumeData: [
        { date: '30/3/2566', callPut: -143, futurePrice: 960.9 },
        { date: '31/3/2566', callPut: 39, futurePrice: 964.8 },
        { date: '3/4/2566', callPut: 14, futurePrice: 959.1 },
        { date: '4/4/2566', callPut: 25, futurePrice: 956.3 },
        { date: '5/4/2566', callPut: -151, futurePrice: 940.9 },
        { date: '7/4/2566', callPut: -21, futurePrice: 943.4 },
        { date: '10/4/2566', callPut: 27, futurePrice: 958.5 },
        { date: '11/4/2566', callPut: 7, futurePrice: 959.4 },
        { date: '12/4/2566', callPut: 25, futurePrice: 957.3 },
        { date: '17/4/2566', callPut: -71, futurePrice: 961.5 },
        { date: '18/4/2566', callPut: -165, futurePrice: 957.4 },
        { date: '19/4/2566', callPut: 33, futurePrice: 948.7 },
        { date: '20/4/2566', callPut: -208, futurePrice: 940.9 },
        { date: '21/4/2566', callPut: -70, futurePrice: 934.8 },
        { date: '24/4/2566', callPut: 639, futurePrice: 935 },
        { date: '25/4/2566', callPut: 197, futurePrice: 927.7 },
        { date: '26/4/2566', callPut: -208, futurePrice: 929.6 },
        { date: '27/4/2566', callPut: 14, futurePrice: 922 },
        { date: '28/4/2566', callPut: -103, futurePrice: 918.3 },
        { date: '2/5/2566', callPut: 37, futurePrice: 924.4 },
        { date: '3/5/2566', callPut: 31, futurePrice: 926.1 },
        { date: '8/5/2566', callPut: -46, futurePrice: 943.9 },
        { date: '9/5/2566', callPut: -55, futurePrice: 944.7 },
        { date: '10/5/2566', callPut: -37, futurePrice: 945.1 },
        { date: '11/5/2566', callPut: 147, futurePrice: 945.9 },
        { date: '12/5/2566', callPut: 194, futurePrice: 945.9 },
        { date: '15/5/2566', callPut: 115, futurePrice: 931.3 },
        { date: '16/5/2566', callPut: 114, futurePrice: 930.7 },
        { date: '17/5/2566', callPut: 58, futurePrice: 918.4 },
        { date: '18/5/2566', callPut: 412, futurePrice: 923.5 },
        { date: '19/5/2566', callPut: 130, futurePrice: 915.8 },
        { date: '22/5/2566', callPut: 358, futurePrice: 926.9 },
        { date: '23/5/2566', callPut: 99, futurePrice: 923.2 },
        { date: '24/5/2566', callPut: 39, futurePrice: 924.2 },
        { date: '25/5/2566', callPut: 66, futurePrice: 924.4 },
        { date: '26/5/2566', callPut: -172, futurePrice: 919.3 },
        { date: '29/5/2566', callPut: 669, futurePrice: 927.7 },
        { date: '30/5/2566', callPut: 418, futurePrice: 921.8 },
        { date: '31/5/2566', callPut: 62, futurePrice: 919 },
        { date: '1/6/2566', callPut: 263, futurePrice: 911.8 },
        { date: '2/6/2566', callPut: 346, futurePrice: 920.6 },
        { date: '6/6/2566', callPut: 502, futurePrice: 918.9 },
        { date: '7/6/2566', callPut: 259, futurePrice: 923.5 },
        { date: '8/6/2566', callPut: 497, futurePrice: 938 },
        { date: '9/6/2566', callPut: 332, futurePrice: 936.8 },
        { date: '12/6/2566', callPut: -215, futurePrice: 936.1 },
        { date: '13/6/2566', callPut: 133, futurePrice: 942.2 },
        { date: '14/6/2566', callPut: 124, futurePrice: 942.9 },
        { date: '15/6/2566', callPut: 662, futurePrice: 943.2 },
        { date: '16/6/2566', callPut: 230, futurePrice: 944.2 },
        { date: '19/6/2566', callPut: 512, futurePrice: 944.7 },
        { date: '20/6/2566', callPut: 308, futurePrice: 932.5 },
        { date: '21/6/2566', callPut: 722, futurePrice: 921.7 },
        { date: '22/6/2566', callPut: 146, futurePrice: 916.3 },
        { date: '23/6/2566', callPut: 991, futurePrice: 913.3 },
        { date: '26/6/2566', callPut: 987, futurePrice: 904.6 },
        { date: '27/6/2566', callPut: 152, futurePrice: 898 },
        { date: '28/6/2566', callPut: 1708, futurePrice: 890.6 },
        { date: '29/6/2566', callPut: 2021, futurePrice: 899.3 },
        { date: '30/6/2566', callPut: 998, futurePrice: 914.6 },
        { date: '3/7/2566', callPut: 1921, futurePrice: 916.4 },
        { date: '4/7/2566', callPut: 1219, futurePrice: 921.7 },
        { date: '5/7/2566', callPut: 313, futurePrice: 920.5 },
        { date: '6/7/2566', callPut: 183, futurePrice: 907.8 },
        { date: '7/7/2566', callPut: -142, futurePrice: 907.7 },
        { date: '10/7/2566', callPut: 3861, futurePrice: 915 },
        { date: '11/7/2566', callPut: 2698, futurePrice: 916.1 },
        { date: '12/7/2566', callPut: 4110, futurePrice: 913.4 },
        { date: '13/7/2566', callPut: 1930, futurePrice: 916.8 },
        { date: '14/7/2566', callPut: 475, futurePrice: 932.6 },
        { date: '17/7/2566', callPut: 4290, futurePrice: 937.8 },
        { date: '18/7/2566', callPut: 573, futurePrice: 943.4 },
        { date: '19/7/2566', callPut: 1225, futurePrice: 942.4 },
        { date: '20/7/2566', callPut: 672, futurePrice: 934.2 },
        { date: '21/7/2566', callPut: -161, futurePrice: 941.5 },
        { date: '24/7/2566', callPut: -265, futurePrice: 938 },
        { date: '25/7/2566', callPut: -7, futurePrice: 943.1 },
        { date: '26/7/2566', callPut: -695, futurePrice: 942 },
        { date: '27/7/2566', callPut: -398, futurePrice: 954.6 },
        { date: '31/7/2566', callPut: -539, futurePrice: 963.7 },
        { date: '2/8/2566', callPut: 1396, futurePrice: 958.7 },
        { date: '3/8/2566', callPut: 61, futurePrice: 942.9 },
        { date: '4/8/2566', callPut: 406, futurePrice: 944.8 },
        { date: '7/8/2566', callPut: -360, futurePrice: 951.6 },
        { date: '8/8/2566', callPut: -290, futurePrice: 938.1 },
        { date: '9/8/2566', callPut: -152, futurePrice: 943.5 },
        { date: '10/8/2566', callPut: 128, futurePrice: 947.4 },
        { date: '11/8/2566', callPut: -838, futurePrice: 947.1 },
        { date: '15/8/2566', callPut: -1074, futurePrice: 937.2 },
        { date: '16/8/2566', callPut: -484, futurePrice: 932.2 },
        { date: '17/8/2566', callPut: -321, futurePrice: 938 },
        { date: '18/8/2566', callPut: -1228, futurePrice: 931.6 },
        { date: '21/8/2566', callPut: 230, futurePrice: 936.9 },
        { date: '22/8/2566', callPut: 276, futurePrice: 949.5 },
        { date: '23/8/2566', callPut: -597, futurePrice: 949.1 },
        { date: '24/8/2566', callPut: -1043, futurePrice: 954.5 },
        { date: '25/8/2566', callPut: -929, futurePrice: 954.7 },
        { date: '28/8/2566', callPut: -1177, futurePrice: 956.1 },
        { date: '29/8/2566', callPut: -1485, futurePrice: 958.7 },
        { date: '30/8/2566', callPut: -1012, futurePrice: 961.1 },
        { date: '31/8/2566', callPut: -1270, futurePrice: 952.6 },
        { date: '1/9/2566', callPut: -152, futurePrice: 952.8 },
        { date: '4/9/2566', callPut: -3005, futurePrice: 946.5 },
        { date: '5/9/2566', callPut: 571, futurePrice: 943.9 },
        { date: '6/9/2566', callPut: 918, futurePrice: 944 },
        { date: '7/9/2566', callPut: 35, futurePrice: 944.8 },
        { date: '8/9/2566', callPut: 1671, futurePrice: 940.2 },
        { date: '11/9/2566', callPut: 6, futurePrice: 937.5 },
        { date: '12/9/2566', callPut: 1003, futurePrice: 941 },
        { date: '13/9/2566', callPut: -378, futurePrice: 937.9 },
        { date: '14/9/2566', callPut: -178, futurePrice: 944.7 },
        { date: '15/9/2566', callPut: 877, futurePrice: 946.1 },
        { date: '18/9/2566', callPut: -619, futurePrice: 936 },
        { date: '19/9/2566', callPut: -118, futurePrice: 933.1 },
        { date: '20/9/2566', callPut: -2513, futurePrice: 922.7 },
        { date: '21/9/2566', callPut: -1065, futurePrice: 923.8 },
        { date: '22/9/2566', callPut: -2373, futurePrice: 931.8 },
        { date: '25/9/2566', callPut: -807, futurePrice: 923.9 },
        { date: '26/9/2566', callPut: -2779, futurePrice: 917.6 },
        { date: '27/9/2566', callPut: -2758, futurePrice: 918.7 },
        { date: '28/9/2566', callPut: -342, futurePrice: 911.94 },
        { date: '29/9/2566', callPut: 0, futurePrice: 0 },
        { date: '2/10/2566', callPut: 0, futurePrice: 0 },
      ],
      openInterestData: [
        { date: '30/3/2566', OI: -139, futurePrice: 960.9 },
        { date: '31/3/2566', OI: -98, futurePrice: 964.8 },
        { date: '3/4/2566', OI: -96, futurePrice: 959.1 },
        { date: '4/4/2566', OI: -72, futurePrice: 956.3 },
        { date: '5/4/2566', OI: -233, futurePrice: 940.9 },
        { date: '7/4/2566', OI: -248, futurePrice: 943.4 },
        { date: '10/4/2566', OI: -209, futurePrice: 958.5 },
        { date: '11/4/2566', OI: -277, futurePrice: 959.4 },
        { date: '12/4/2566', OI: -281, futurePrice: 957.3 },
        { date: '17/4/2566', OI: -329, futurePrice: 961.5 },
        { date: '18/4/2566', OI: -425, futurePrice: 957.4 },
        { date: '19/4/2566', OI: -386, futurePrice: 948.7 },
        { date: '20/4/2566', OI: -508, futurePrice: 940.9 },
        { date: '21/4/2566', OI: -493, futurePrice: 934.8 },
        { date: '24/4/2566', OI: 29, futurePrice: 935 },
        { date: '25/4/2566', OI: -54, futurePrice: 927.7 },
        { date: '26/4/2566', OI: -223, futurePrice: 929.6 },
        { date: '27/4/2566', OI: -254, futurePrice: 922 },
        { date: '28/4/2566', OI: -384, futurePrice: 918.3 },
        { date: '2/5/2566', OI: -386, futurePrice: 924.4 },
        { date: '3/5/2566', OI: -677, futurePrice: 926.1 },
        { date: '8/5/2566', OI: -718, futurePrice: 943.9 },
        { date: '9/5/2566', OI: -816, futurePrice: 944.7 },
        { date: '10/5/2566', OI: -878, futurePrice: 945.1 },
        { date: '11/5/2566', OI: -808, futurePrice: 945.9 },
        { date: '12/5/2566', OI: -683, futurePrice: 945.9 },
        { date: '15/5/2566', OI: -592, futurePrice: 931.3 },
        { date: '16/5/2566', OI: -508, futurePrice: 930.7 },
        { date: '17/5/2566', OI: -598, futurePrice: 918.4 },
        { date: '18/5/2566', OI: -318, futurePrice: 923.5 },
        { date: '19/5/2566', OI: -329, futurePrice: 915.8 },
        { date: '22/5/2566', OI: -248, futurePrice: 926.9 },
        { date: '23/5/2566', OI: -364, futurePrice: 923.2 },
        { date: '24/5/2566', OI: -340, futurePrice: 924.2 },
        { date: '25/5/2566', OI: -329, futurePrice: 924.4 },
        { date: '26/5/2566', OI: -329, futurePrice: 919.3 },
        { date: '29/5/2566', OI: 358, futurePrice: 927.7 },
        { date: '30/5/2566', OI: 667, futurePrice: 921.8 },
        { date: '31/5/2566', OI: 626, futurePrice: 919 },
        { date: '1/6/2566', OI: 842, futurePrice: 911.8 },
        { date: '2/6/2566', OI: 1041, futurePrice: 920.6 },
        { date: '6/6/2566', OI: 1236, futurePrice: 918.9 },
        { date: '7/6/2566', OI: 1284, futurePrice: 923.5 },
        { date: '8/6/2566', OI: 1347, futurePrice: 938 },
        { date: '9/6/2566', OI: 1420, futurePrice: 936.8 },
        { date: '12/6/2566', OI: 1242, futurePrice: 936.1 },
        { date: '13/6/2566', OI: 1481, futurePrice: 942.2 },
        { date: '14/6/2566', OI: 1681, futurePrice: 942.9 },
        { date: '15/6/2566', OI: 2195, futurePrice: 943.2 },
        { date: '16/6/2566', OI: 2405, futurePrice: 944.2 },
        { date: '19/6/2566', OI: 2834, futurePrice: 944.7 },
        { date: '20/6/2566', OI: 3159, futurePrice: 932.5 },
        { date: '21/6/2566', OI: 3344, futurePrice: 921.7 },
        { date: '22/6/2566', OI: 2821, futurePrice: 916.3 },
        { date: '23/6/2566', OI: 2988, futurePrice: 913.3 },
        { date: '26/6/2566', OI: 3032, futurePrice: 904.6 },
        { date: '27/6/2566', OI: 3464, futurePrice: 898 },
        { date: '28/6/2566', OI: 4035, futurePrice: 890.6 },
        { date: '29/6/2566', OI: 4862, futurePrice: 899.3 },
        { date: '30/6/2566', OI: 4620, futurePrice: 914.6 },
        { date: '3/7/2566', OI: 5484, futurePrice: 916.4 },
        { date: '4/7/2566', OI: 5920, futurePrice: 921.7 },
        { date: '5/7/2566', OI: 6309, futurePrice: 920.5 },
        { date: '6/7/2566', OI: 6966, futurePrice: 907.8 },
        { date: '7/7/2566', OI: 9009, futurePrice: 907.7 },
        { date: '10/7/2566', OI: 14021, futurePrice: 915 },
        { date: '11/7/2566', OI: 12769, futurePrice: 916.1 },
        { date: '12/7/2566', OI: 12330, futurePrice: 913.4 },
        { date: '13/7/2566', OI: 12507, futurePrice: 916.8 },
        { date: '14/7/2566', OI: 11888, futurePrice: 932.6 },
        { date: '17/7/2566', OI: 12670, futurePrice: 937.8 },
        { date: '18/7/2566', OI: 12586, futurePrice: 943.4 },
        { date: '19/7/2566', OI: 11837, futurePrice: 942.4 },
        { date: '20/7/2566', OI: 12097, futurePrice: 934.2 },
        { date: '21/7/2566', OI: 12456, futurePrice: 941.5 },
        { date: '24/7/2566', OI: 12138, futurePrice: 938 },
        { date: '25/7/2566', OI: 11794, futurePrice: 943.1 },
        { date: '26/7/2566', OI: 11315, futurePrice: 942 },
        { date: '27/7/2566', OI: 11034, futurePrice: 954.6 },
        { date: '31/7/2566', OI: 8903, futurePrice: 963.7 },
        { date: '2/8/2566', OI: 8286, futurePrice: 958.7 },
        { date: '3/8/2566', OI: 8949, futurePrice: 942.9 },
        { date: '4/8/2566', OI: 8482, futurePrice: 944.8 },
        { date: '7/8/2566', OI: 8206, futurePrice: 951.6 },
        { date: '8/8/2566', OI: 8707, futurePrice: 938.1 },
        { date: '9/8/2566', OI: 8253, futurePrice: 943.5 },
        { date: '10/8/2566', OI: 8210, futurePrice: 947.4 },
        { date: '11/8/2566', OI: 7517, futurePrice: 947.1 },
        { date: '15/8/2566', OI: 6237, futurePrice: 937.2 },
        { date: '16/8/2566', OI: 6094, futurePrice: 932.2 },
        { date: '17/8/2566', OI: 5618, futurePrice: 938 },
        { date: '18/8/2566', OI: 4682, futurePrice: 931.6 },
        { date: '21/8/2566', OI: 5174, futurePrice: 936.9 },
        { date: '22/8/2566', OI: 4654, futurePrice: 949.5 },
        { date: '23/8/2566', OI: 3579, futurePrice: 949.1 },
        { date: '24/8/2566', OI: 2169, futurePrice: 954.5 },
        { date: '25/8/2566', OI: 1935, futurePrice: 954.7 },
        { date: '28/8/2566', OI: 1634, futurePrice: 956.1 },
        { date: '29/8/2566', OI: 1718, futurePrice: 958.7 },
        { date: '30/8/2566', OI: 2150, futurePrice: 961.1 },
        { date: '31/8/2566', OI: 1120, futurePrice: 952.6 },
        { date: '1/9/2566', OI: 802, futurePrice: 952.8 },
        { date: '4/9/2566', OI: 411, futurePrice: 946.5 },
        { date: '5/9/2566', OI: 847, futurePrice: 943.9 },
        { date: '6/9/2566', OI: 1391, futurePrice: 944 },
        { date: '7/9/2566', OI: 1851, futurePrice: 944.8 },
        { date: '8/9/2566', OI: 3366, futurePrice: 940.2 },
        { date: '11/9/2566', OI: 3682, futurePrice: 937.5 },
        { date: '12/9/2566', OI: 2827, futurePrice: 941 },
        { date: '13/9/2566', OI: 2598, futurePrice: 937.9 },
        { date: '14/9/2566', OI: 2553, futurePrice: 944.7 },
        { date: '15/9/2566', OI: 2334, futurePrice: 946.1 },
        { date: '18/9/2566', OI: 2963, futurePrice: 936 },
        { date: '19/9/2566', OI: 3796, futurePrice: 933.1 },
        { date: '20/9/2566', OI: 3556, futurePrice: 922.7 },
        { date: '21/9/2566', OI: 3073, futurePrice: 923.8 },
        { date: '22/9/2566', OI: 1992, futurePrice: 931.8 },
        { date: '25/9/2566', OI: 609, futurePrice: 923.9 },
        { date: '26/9/2566', OI: 1068, futurePrice: 917.6 },
        { date: '27/9/2566', OI: 2698, futurePrice: 918.7 },
        { date: '28/9/2566', OI: 3335, futurePrice: 911.94 },
        { date: '29/9/2566', OI: 0, futurePrice: 0 },
        { date: '2/10/2566', OI: 0, futurePrice: 0 },
      ]
    },
    S50Z23: {
      volumeData: [
        { date: '29/6/2566', callPut: -321, futurePrice: 902.9 },
        { date: '30/6/2566', callPut: -269, futurePrice: 916.9 },
        { date: '3/7/2566', callPut: -143, futurePrice: 918.1 },
        { date: '4/7/2566', callPut: -174, futurePrice: 923.6 },
        { date: '5/7/2566', callPut: -63, futurePrice: 922.2 },
        { date: '6/7/2566', callPut: 45, futurePrice: 909.9 },
        { date: '7/7/2566', callPut: -44, futurePrice: 909.9 },
        { date: '10/7/2566', callPut: -60, futurePrice: 916.7 },
        { date: '11/7/2566', callPut: -41, futurePrice: 917.7 },
        { date: '12/7/2566', callPut: 36, futurePrice: 915.2 },
        { date: '13/7/2566', callPut: 6, futurePrice: 918.6 },
        { date: '14/7/2566', callPut: 145, futurePrice: 933.9 },
        { date: '17/7/2566', callPut: 85, futurePrice: 939.2 },
        { date: '18/7/2566', callPut: 143, futurePrice: 944.3 },
        { date: '19/7/2566', callPut: 96, futurePrice: 943.4 },
        { date: '20/7/2566', callPut: -76, futurePrice: 935.1 },
        { date: '21/7/2566', callPut: 26, futurePrice: 942.6 },
        { date: '24/7/2566', callPut: -42, futurePrice: 939.4 },
        { date: '25/7/2566', callPut: 14, futurePrice: 943.8 },
        { date: '26/7/2566', callPut: 73, futurePrice: 943 },
        { date: '27/7/2566', callPut: 273, futurePrice: 955.7 },
        { date: '31/7/2566', callPut: 107, futurePrice: 964.6 },
        { date: '2/8/2566', callPut: 242, futurePrice: 959.5 },
        { date: '3/8/2566', callPut: -220, futurePrice: 944.1 },
        { date: '4/8/2566', callPut: -37, futurePrice: 945.9 },
        { date: '7/8/2566', callPut: -170, futurePrice: 953.1 },
        { date: '8/8/2566', callPut: 58, futurePrice: 939.8 },
        { date: '9/8/2566', callPut: 222, futurePrice: 944.8 },
        { date: '10/8/2566', callPut: 116, futurePrice: 948.8 },
        { date: '11/8/2566', callPut: -279, futurePrice: 948.6 },
        { date: '15/8/2566', callPut: 488, futurePrice: 939.1 },
        { date: '16/8/2566', callPut: -159, futurePrice: 934.7 },
        { date: '17/8/2566', callPut: 46, futurePrice: 939.8 },
        { date: '18/8/2566', callPut: -75, futurePrice: 934.2 },
        { date: '21/8/2566', callPut: 352, futurePrice: 939.5 },
        { date: '22/8/2566', callPut: 213, futurePrice: 951.3 },
        { date: '23/8/2566', callPut: -127, futurePrice: 951.2 },
        { date: '24/8/2566', callPut: -135, futurePrice: 956 },
        { date: '25/8/2566', callPut: 199, futurePrice: 956.4 },
        { date: '28/8/2566', callPut: -385, futurePrice: 957.9 },
        { date: '29/8/2566', callPut: -100, futurePrice: 959.8 },
        { date: '30/8/2566', callPut: 97, futurePrice: 962.9 },
        { date: '31/8/2566', callPut: 238, futurePrice: 954.5 },
        { date: '1/9/2566', callPut: 337, futurePrice: 954.9 },
        { date: '4/9/2566', callPut: -28, futurePrice: 948.6 },
        { date: '5/9/2566', callPut: 92, futurePrice: 946.5 },
        { date: '6/9/2566', callPut: 97, futurePrice: 946.7 },
        { date: '7/9/2566', callPut: -292, futurePrice: 947 },
        { date: '8/9/2566', callPut: -87, futurePrice: 943 },
        { date: '11/9/2566', callPut: 89, futurePrice: 940.5 },
        { date: '12/9/2566', callPut: 419, futurePrice: 943.6 },
        { date: '13/9/2566', callPut: -84, futurePrice: 940.5 },
        { date: '14/9/2566', callPut: -379, futurePrice: 947.3 },
        { date: '15/9/2566', callPut: 3, futurePrice: 948.6 },
        { date: '18/9/2566', callPut: 579, futurePrice: 939.2 },
        { date: '19/9/2566', callPut: -85, futurePrice: 936.3 },
        { date: '20/9/2566', callPut: -952, futurePrice: 927.1 },
        { date: '21/9/2566', callPut: 73, futurePrice: 927.9 },
        { date: '22/9/2566', callPut: 75, futurePrice: 935.1 },
        { date: '25/9/2566', callPut: 538, futurePrice: 923 },
        { date: '26/9/2566', callPut: -851, futurePrice: 916.6 },
        { date: '27/9/2566', callPut: 398, futurePrice: 917.5 },
        { date: '28/9/2566', callPut: -132, futurePrice: 911.8 },
        { date: '29/9/2566', callPut: 2102, futurePrice: 901.6 },
        { date: '2/10/2566', callPut: 2087, futurePrice: 901.5 },
        { date: '3/10/2566', callPut: -193, futurePrice: 882.7 },
        { date: '4/10/2566', callPut: -944, futurePrice: 886.8 },
        { date: '5/10/2566', callPut: -195, futurePrice: 887.5 },
        { date: '6/10/2566', callPut: 2422, futurePrice: 876.5 },
        { date: '9/10/2566', callPut: -1662, futurePrice: 875.6 },
        { date: '10/10/2566', callPut: 1916, futurePrice: 879 },
        { date: '11/10/2566', callPut: -2274, futurePrice: 895.1 },
        { date: '12/10/2566', callPut: -3741, futurePrice: 892.8 },
        { date: '16/10/2566', callPut: -2722, futurePrice: 878.7 },
        { date: '17/10/2566', callPut: -2060, futurePrice: 881.5 },
        { date: '18/10/2566', callPut: -1657, futurePrice: 890.9 },
        { date: '19/10/2566', callPut: -2627, futurePrice: 879.4 },
        { date: '20/10/2566', callPut: -3034, futurePrice: 861.7 },
        { date: '24/10/2566', callPut: -659, futurePrice: 861.4 },
        { date: '25/10/2566', callPut: 261, futurePrice: 866.7 },
        { date: '26/10/2566', callPut: -2363, futurePrice: 846.9 },
        { date: '27/10/2566', callPut: -1815, futurePrice: 859.1 },
        { date: '30/10/2566', callPut: 633, futurePrice: 868.3 },
        { date: '31/10/2566', callPut: -494, futurePrice: 859 },
        { date: '1/11/2566', callPut: 1833, futurePrice: 855 },
        { date: '2/11/2566', callPut: -876, futurePrice: 871 },
        { date: '3/11/2566', callPut: 1945, futurePrice: 878 },
        { date: '6/11/2566', callPut: 1331, futurePrice: 877.5 },
        { date: '7/11/2566', callPut: 1717, futurePrice: 871.5 },
        { date: '8/11/2566', callPut: -726, futurePrice: 875.2 },
        { date: '9/11/2566', callPut: 2704, futurePrice: 872.2 },
        { date: '10/11/2566', callPut: -432, futurePrice: 859.6 },
        { date: '13/11/2566', callPut: -771, futurePrice: 862.8 },
        { date: '14/11/2566', callPut: -755, futurePrice: 862.1 },
        { date: '15/11/2566', callPut: -1985, futurePrice: 881.2 },
        { date: '16/11/2566', callPut: 2269, futurePrice: 880.4 },
        { date: '17/11/2566', callPut: 1247, futurePrice: 877.8 },
        { date: '20/11/2566', callPut: 287, futurePrice: 880.9 },
        { date: '21/11/2566', callPut: 2233, futurePrice: 881 },
        { date: '22/11/2566', callPut: 1117, futurePrice: 875 },
        { date: '23/11/2566', callPut: 2090, futurePrice: 869.2 },
        { date: '24/11/2566', callPut: -1245, futurePrice: 864.4 },
        { date: '27/11/2566', callPut: 1927, futurePrice: 860.9 },
        { date: '28/11/2566', callPut: 1510, futurePrice: 868.3 },
        { date: '29/11/2566', callPut: 920, futurePrice: 858.4 },
        { date: '30/11/2566', callPut: 2261, futurePrice: 851.6 },
        { date: '1/12/2566', callPut: 1118, futurePrice: 853.7 },
        { date: '4/12/2566', callPut: 2223, futurePrice: 856.3 },
        { date: '6/12/2566', callPut: 896, futurePrice: 856.6 },
        { date: '7/12/2566', callPut: 1399, futurePrice: 849.4 },
        { date: '8/12/2566', callPut: 3641, futurePrice: 853.6 },
        { date: '12/12/2566', callPut: 1015, futurePrice: 850.8 },
        { date: '13/12/2566', callPut: 303, futurePrice: 840 },
        { date: '14/12/2566', callPut: -1600, futurePrice: 854.8 },
        { date: '15/12/2566', callPut: 1614, futurePrice: 863 },
        { date: '18/12/2566', callPut: -388, futurePrice: 863.6 },
        { date: '19/12/2566', callPut: -14, futurePrice: 865.6 },
        { date: '20/12/2566', callPut: 577, futurePrice: 870.2 },
        { date: '21/12/2566', callPut: 294, futurePrice: 871 },
        { date: '22/12/2566', callPut: 442, futurePrice: 872.4 },
        { date: '25/12/2566', callPut: 916, futurePrice: 874.2 },
        { date: '26/12/2566', callPut: 2205, futurePrice: 878.3 },
        { date: '27/12/2566', callPut: 284, futurePrice: 872.79 },
        { date: '28/12/2566', callPut: 0, futurePrice: 0 },
        { date: '29/12/2566', callPut: 0, futurePrice: 0 }
      ],
      openInterestData: [
        { date: '29/6/2566', OI: -312, futurePrice: 902.9 },
        { date: '30/6/2566', OI: -572, futurePrice: 916.9 },
        { date: '3/7/2566', OI: -643, futurePrice: 918.1 },
        { date: '4/7/2566', OI: -753, futurePrice: 923.6 },
        { date: '5/7/2566', OI: -773, futurePrice: 922.2 },
        { date: '6/7/2566', OI: -734, futurePrice: 909.9 },
        { date: '7/7/2566', OI: -746, futurePrice: 909.9 },
        { date: '10/7/2566', OI: -795, futurePrice: 916.7 },
        { date: '11/7/2566', OI: -833, futurePrice: 917.7 },
        { date: '12/7/2566', OI: -802, futurePrice: 915.2 },
        { date: '13/7/2566', OI: -841, futurePrice: 918.6 },
        { date: '14/7/2566', OI: -709, futurePrice: 933.9 },
        { date: '17/7/2566', OI: -607, futurePrice: 939.2 },
        { date: '18/7/2566', OI: -472, futurePrice: 944.3 },
        { date: '19/7/2566', OI: -492, futurePrice: 943.4 },
        { date: '20/7/2566', OI: -562, futurePrice: 935.1 },
        { date: '21/7/2566', OI: -526, futurePrice: 942.6 },
        { date: '24/7/2566', OI: -591, futurePrice: 939.4 },
        { date: '25/7/2566', OI: -544, futurePrice: 943.8 },
        { date: '26/7/2566', OI: -491, futurePrice: 943 },
        { date: '27/7/2566', OI: -250, futurePrice: 955.7 },
        { date: '31/7/2566', OI: -9, futurePrice: 964.6 },
        { date: '2/8/2566', OI: 9, futurePrice: 959.5 },
        { date: '3/8/2566', OI: -83, futurePrice: 944.1 },
        { date: '4/8/2566', OI: -118, futurePrice: 945.9 },
        { date: '7/8/2566', OI: -228, futurePrice: 953.1 },
        { date: '8/8/2566', OI: -248, futurePrice: 939.8 },
        { date: '9/8/2566', OI: -72, futurePrice: 944.8 },
        { date: '10/8/2566', OI: -52, futurePrice: 948.8 },
        { date: '11/8/2566', OI: -344, futurePrice: 948.6 },
        { date: '15/8/2566', OI: 48, futurePrice: 939.1 },
        { date: '16/8/2566', OI: -172, futurePrice: 934.7 },
        { date: '17/8/2566', OI: -58, futurePrice: 939.8 },
        { date: '18/8/2566', OI: -139, futurePrice: 934.2 },
        { date: '21/8/2566', OI: -292, futurePrice: 939.5 },
        { date: '22/8/2566', OI: -212, futurePrice: 951.3 },
        { date: '23/8/2566', OI: -352, futurePrice: 951.2 },
        { date: '24/8/2566', OI: -475, futurePrice: 956 },
        { date: '25/8/2566', OI: -527, futurePrice: 956.4 },
        { date: '28/8/2566', OI: -999, futurePrice: 957.9 },
        { date: '29/8/2566', OI: -1112, futurePrice: 959.8 },
        { date: '30/8/2566', OI: -1007, futurePrice: 962.9 },
        { date: '31/8/2566', OI: -901, futurePrice: 954.5 },
        { date: '1/9/2566', OI: -834, futurePrice: 954.9 },
        { date: '4/9/2566', OI: -608, futurePrice: 948.6 },
        { date: '5/9/2566', OI: -666, futurePrice: 946.5 },
        { date: '6/9/2566', OI: -943, futurePrice: 946.7 },
        { date: '7/9/2566', OI: -1363, futurePrice: 947 },
        { date: '8/9/2566', OI: -1522, futurePrice: 943 },
        { date: '11/9/2566', OI: -1736, futurePrice: 940.5 },
        { date: '12/9/2566', OI: -1657, futurePrice: 943.6 },
        { date: '13/9/2566', OI: -1725, futurePrice: 940.5 },
        { date: '14/9/2566', OI: -1868, futurePrice: 947.3 },
        { date: '15/9/2566', OI: -1765, futurePrice: 948.6 },
        { date: '18/9/2566', OI: -1465, futurePrice: 939.2 },
        { date: '19/9/2566', OI: -1387, futurePrice: 936.3 },
        { date: '20/9/2566', OI: -2026, futurePrice: 927.1 },
        { date: '21/9/2566', OI: -2310, futurePrice: 927.9 },
        { date: '22/9/2566', OI: -2337, futurePrice: 935.1 },
        { date: '25/9/2566', OI: -2143, futurePrice: 923 },
        { date: '26/9/2566', OI: -3247, futurePrice: 916.6 },
        { date: '27/9/2566', OI: -3246, futurePrice: 917.5 },
        { date: '28/9/2566', OI: -3495, futurePrice: 911.8 },
        { date: '29/9/2566', OI: -1610, futurePrice: 901.6 },
        { date: '2/10/2566', OI: 983, futurePrice: 901.5 },
        { date: '3/10/2566', OI: 4500, futurePrice: 882.7 },
        { date: '4/10/2566', OI: 7207, futurePrice: 886.8 },
        { date: '5/10/2566', OI: 8237, futurePrice: 887.5 },
        { date: '6/10/2566', OI: 8865, futurePrice: 876.5 },
        { date: '9/10/2566', OI: 10489, futurePrice: 875.6 },
        { date: '10/10/2566', OI: 10147, futurePrice: 879 },
        { date: '11/10/2566', OI: 6582, futurePrice: 895.1 },
        { date: '12/10/2566', OI: 4723, futurePrice: 892.8 },
        { date: '16/10/2566', OI: 5533, futurePrice: 878.7 },
        { date: '17/10/2566', OI: 3674, futurePrice: 881.5 },
        { date: '18/10/2566', OI: 1239, futurePrice: 890.9 },
        { date: '19/10/2566', OI: 1415, futurePrice: 879.4 },
        { date: '20/10/2566', OI: 856, futurePrice: 861.7 },
        { date: '24/10/2566', OI: 819, futurePrice: 861.4 },
        { date: '25/10/2566', OI: 2877, futurePrice: 866.7 },
        { date: '26/10/2566', OI: 5422, futurePrice: 846.9 },
        { date: '27/10/2566', OI: 7870, futurePrice: 859.1 },
        { date: '30/10/2566', OI: 9890, futurePrice: 868.3 },
        { date: '31/10/2566', OI: 11761, futurePrice: 859 },
        { date: '1/11/2566', OI: 13434, futurePrice: 855 },
        { date: '2/11/2566', OI: 14377, futurePrice: 871 },
        { date: '3/11/2566', OI: 14718, futurePrice: 878 },
        { date: '6/11/2566', OI: 15211, futurePrice: 877.5 },
        { date: '7/11/2566', OI: 15848, futurePrice: 871.5 },
        { date: '8/11/2566', OI: 15883, futurePrice: 875.2 },
        { date: '9/11/2566', OI: 18501, futurePrice: 872.2 },
        { date: '10/11/2566', OI: 20094, futurePrice: 859.6 },
        { date: '13/11/2566', OI: 19928, futurePrice: 862.8 },
        { date: '14/11/2566', OI: 21106, futurePrice: 862.1 },
        { date: '15/11/2566', OI: 18497, futurePrice: 881.2 },
        { date: '16/11/2566', OI: 18313, futurePrice: 880.4 },
        { date: '17/11/2566', OI: 18188, futurePrice: 877.8 },
        { date: '20/11/2566', OI: 17728, futurePrice: 880.9 },
        { date: '21/11/2566', OI: 18263, futurePrice: 881 },
        { date: '22/11/2566', OI: 18886, futurePrice: 875 },
        { date: '23/11/2566', OI: 19447, futurePrice: 869.2 },
        { date: '24/11/2566', OI: 21213, futurePrice: 864.4 },
        { date: '27/11/2566', OI: 21635, futurePrice: 860.9 },
        { date: '28/11/2566', OI: 22425, futurePrice: 868.3 },
        { date: '29/11/2566', OI: 22680, futurePrice: 858.4 },
        { date: '30/11/2566', OI: 24556, futurePrice: 851.6 },
        { date: '1/12/2566', OI: 24954, futurePrice: 853.7 },
        { date: '4/12/2566', OI: 24510, futurePrice: 856.3 },
        { date: '6/12/2566', OI: 25105, futurePrice: 856.6 },
        { date: '7/12/2566', OI: 25118, futurePrice: 849.4 },
        { date: '8/12/2566', OI: 26706, futurePrice: 853.6 },
        { date: '12/12/2566', OI: 27143, futurePrice: 850.8 },
        { date: '13/12/2566', OI: 28361, futurePrice: 840 },
        { date: '14/12/2566', OI: 29272, futurePrice: 854.8 },
        { date: '15/12/2566', OI: 29807, futurePrice: 863 },
        { date: '18/12/2566', OI: 29213, futurePrice: 863.6 },
        { date: '19/12/2566', OI: 29758, futurePrice: 865.6 },
        { date: '20/12/2566', OI: 29889, futurePrice: 870.2 },
        { date: '21/12/2566', OI: 29880, futurePrice: 871 },
        { date: '22/12/2566', OI: 29743, futurePrice: 872.4 },
        { date: '25/12/2566', OI: 29848, futurePrice: 874.2 },
        { date: '26/12/2566', OI: 28761, futurePrice: 878.3 },
        { date: '27/12/2566', OI: 27371, futurePrice: 872.79 },
        { date: '28/12/2566', OI: 0, futurePrice: 0 },
        { date: '29/12/2566', OI: 0, futurePrice: 0 },
      ]
    }
  },
  2024: {
    S50H24: {
      volumeData: [
        { date: '28/9/2566', callPut: -686, futurePrice: 911.4 },
        { date: '29/9/2566', callPut: -500, futurePrice: 901.3 },
        { date: '2/10/2566', callPut: -167, futurePrice: 901.1 },
        { date: '3/10/2566', callPut: -917, futurePrice: 882 },
        { date: '4/10/2566', callPut: -445, futurePrice: 885.5 },
        { date: '5/10/2566', callPut: -143, futurePrice: 886.4 },
        { date: '6/10/2566', callPut: 147, futurePrice: 875.3 },
        { date: '9/10/2566', callPut: 100, futurePrice: 874 },
        { date: '10/10/2566', callPut: -73, futurePrice: 877.2 },
        { date: '11/10/2566', callPut: 624, futurePrice: 893.4 },
        { date: '12/10/2566', callPut: 30, futurePrice: 891.1 },
        { date: '16/10/2566', callPut: -454, futurePrice: 877 },
        { date: '17/10/2566', callPut: -768, futurePrice: 879.7 },
        { date: '18/10/2566', callPut: 458, futurePrice: 888.8 },
        { date: '19/10/2566', callPut: -64, futurePrice: 877.3 },
        { date: '20/10/2566', callPut: -714, futurePrice: 859.6 },
        { date: '24/10/2566', callPut: -58, futurePrice: 859.2 },
        { date: '25/10/2566', callPut: -305, futurePrice: 864.3 },
        { date: '26/10/2566', callPut: -48, futurePrice: 844.5 },
        { date: '27/10/2566', callPut: -420, futurePrice: 856.5 },
        { date: '30/10/2566', callPut: -280, futurePrice: 865.7 },
        { date: '31/10/2566', callPut: 261, futurePrice: 856.5 },
        { date: '1/11/2566', callPut: -48, futurePrice: 852.6 },
        { date: '2/11/2566', callPut: -451, futurePrice: 868.4 },
        { date: '3/11/2566', callPut: -754, futurePrice: 875.2 },
        { date: '6/11/2566', callPut: -131, futurePrice: 874.9 },
        { date: '7/11/2566', callPut: -194, futurePrice: 869.1 },
        { date: '8/11/2566', callPut: 5, futurePrice: 872.7 },
        { date: '9/11/2566', callPut: -139, futurePrice: 869.9 },
        { date: '10/11/2566', callPut: -173, futurePrice: 857.2 },
        { date: '13/11/2566', callPut: 38, futurePrice: 860.5 },
        { date: '14/11/2566', callPut: 50, futurePrice: 860 },
        { date: '15/11/2566', callPut: -748, futurePrice: 878.8 },
        { date: '16/11/2566', callPut: 84, futurePrice: 878 },
        { date: '17/11/2566', callPut: -246, futurePrice: 875.5 },
        { date: '20/11/2566', callPut: -43, futurePrice: 878.8 },
        { date: '21/11/2566', callPut: 53, futurePrice: 879 },
        { date: '22/11/2566', callPut: 79, futurePrice: 873 },
        { date: '23/11/2566', callPut: 110, futurePrice: 867.3 },
        { date: '24/11/2566', callPut: -41, futurePrice: 862.6 },
        { date: '27/11/2566', callPut: -392, futurePrice: 859.3 },
        { date: '28/11/2566', callPut: -113, futurePrice: 866.4 },
        { date: '29/11/2566', callPut: -11, futurePrice: 856.6 },
        { date: '30/11/2566', callPut: -232, futurePrice: 849.7 },
        { date: '1/12/2566', callPut: 263, futurePrice: 851.9 },
        { date: '4/12/2566', callPut: 440, futurePrice: 854.8 },
        { date: '6/12/2566', callPut: 173, futurePrice: 855.2 },
        { date: '7/12/2566', callPut: 470, futurePrice: 848 },
        { date: '8/12/2566', callPut: 466, futurePrice: 851.8 },
        { date: '12/12/2566', callPut: 380, futurePrice: 849.7 },
        { date: '13/12/2566', callPut: 54, futurePrice: 839 },
        { date: '14/12/2566', callPut: 801, futurePrice: 853.4 },
        { date: '15/12/2566', callPut: 564, futurePrice: 861.9 },
        { date: '18/12/2566', callPut: 114, futurePrice: 862.9 },
        { date: '19/12/2566', callPut: -18, futurePrice: 864.6 },
        { date: '20/12/2566', callPut: 290, futurePrice: 868.6 },
        { date: '21/12/2566', callPut: 482, futurePrice: 867.9 },
        { date: '22/12/2566', callPut: 644, futurePrice: 864.9 },
        { date: '25/12/2566', callPut: 226, futurePrice: 867.8 },
        { date: '26/12/2566', callPut: 419, futurePrice: 872.9 },
        { date: '27/12/2566', callPut: -195, futurePrice: 870.2 },
        { date: '28/12/2566', callPut: 189, futurePrice: 873.2 },
        { date: '2/1/2567', callPut: -80, futurePrice: 878.8 },
        { date: '3/1/2567', callPut: -123, futurePrice: 874.6 },
        { date: '4/1/2567', callPut: -344, futurePrice: 875.2 },
        { date: '5/1/2567', callPut: -1058, futurePrice: 869.2 },
        { date: '8/1/2567', callPut: -930, futurePrice: 864.7 },
        { date: '9/1/2567', callPut: -707, futurePrice: 864.2 },
        { date: '10/1/2567', callPut: -591, futurePrice: 863 },
        { date: '11/1/2567', callPut: -1152, futurePrice: 861.2 },
        { date: '12/1/2567', callPut: 355, futurePrice: 861.2 },
        { date: '15/1/2567', callPut: 1129, futurePrice: 856.3 },
        { date: '16/1/2567', callPut: -833, futurePrice: 851.9 },
        { date: '17/1/2567', callPut: 625, futurePrice: 839 },
        { date: '18/1/2567', callPut: 388, futurePrice: 835.9 },
        { date: '19/1/2567', callPut: -781, futurePrice: 839.4 },
        { date: '22/1/2567', callPut: 1059, futurePrice: 832.2 },
        { date: '23/1/2567', callPut: 1847, futurePrice: 825.8 },
        { date: '24/1/2567', callPut: 959, futurePrice: 840.7 },
        { date: '25/1/2567', callPut: -91, futurePrice: 836.8 },
        { date: '26/1/2567', callPut: 113, futurePrice: 834.3 },
        { date: '29/1/2567', callPut: 1652, futurePrice: 836.1 },
        { date: '30/1/2567', callPut: -233, futurePrice: 835.4 },
        { date: '31/1/2567', callPut: 1481, futurePrice: 829.7 },
        { date: '1/2/2567', callPut: 701, futurePrice: 830.6 },
        { date: '2/2/2567', callPut: -1411, futurePrice: 843.6 },
        { date: '5/2/2567', callPut: 481, futurePrice: 839.1 },
        { date: '6/2/2567', callPut: 2525, futurePrice: 847 },
        { date: '7/2/2567', callPut: 2789, futurePrice: 849.3 },
        { date: '8/2/2567', callPut: 979, futurePrice: 843.5 },
        { date: '9/2/2567', callPut: 639, futurePrice: 840.2 },
        { date: '12/2/2567', callPut: 562, futurePrice: 843.6 },
        { date: '13/2/2567', callPut: -1149, futurePrice: 844.4 },
        { date: '14/2/2567', callPut: 276, futurePrice: 836.1 },
        { date: '15/2/2567', callPut: 24, futurePrice: 837.6 },
        { date: '16/2/2567', callPut: -240, futurePrice: 837.8 },
        { date: '19/2/2567', callPut: 3593, futurePrice: 839.8 },
        { date: '20/2/2567', callPut: 914, futurePrice: 838 },
        { date: '21/2/2567', callPut: -4907, futurePrice: 847.7 },
        { date: '22/2/2567', callPut: -103, futurePrice: 853.9 },
        { date: '23/2/2567', callPut: 55, futurePrice: 851.5 },
        { date: '27/2/2567', callPut: 1660, futurePrice: 846.4 },
        { date: '28/2/2567', callPut: -510, futurePrice: 836.7 },
        { date: '1/3/2567', callPut: 246, futurePrice: 826.2 },
        { date: '4/3/2567', callPut: -312, futurePrice: 830.2 },
        { date: '5/3/2567', callPut: 976, futurePrice: 826.1 },
        { date: '6/3/2567', callPut: -150, futurePrice: 834.7 },
        { date: '7/3/2567', callPut: -244, futurePrice: 836.3 },
        { date: '8/3/2567', callPut: -400, futurePrice: 844.1 },
        { date: '11/3/2567', callPut: 60, futurePrice: 841.1 },
        { date: '12/3/2567', callPut: -487, futurePrice: 842.7 },
        { date: '13/3/2567', callPut: -156, futurePrice: 845.4 },
        { date: '14/3/2567', callPut: -445, futurePrice: 852.8 },
        { date: '15/3/2567', callPut: 145, futurePrice: 846.3 },
        { date: '18/3/2567', callPut: -650, futurePrice: 847.3 },
        { date: '19/3/2567', callPut: 1092, futurePrice: 843.4 },
        { date: '20/3/2567', callPut: 1025, futurePrice: 838.7 },
        { date: '21/3/2567', callPut: -1678, futurePrice: 851.1 },
        { date: '22/3/2567', callPut: -92, futurePrice: 849 },
        { date: '25/3/2567', callPut: -637, futurePrice: 843.4 },
        { date: '26/3/2567', callPut: 1129, futurePrice: 845.2 },
        { date: '27/3/2567', callPut: 512, futurePrice: 846.6 },
        { date: '28/3/2567', callPut: 829, futurePrice: 837.58 },
        { date: '29/3/2567', callPut: 0, futurePrice: 0 },
        { date: '1/4/2567', callPut: 0, futurePrice: 0 },
      ],
      openInterestData: [
        { date: '28/9/2566', OI: -681, futurePrice: 911.4 },
        { date: '29/9/2566', OI: -1161, futurePrice: 901.3 },
        { date: '2/10/2566', OI: -1270, futurePrice: 901.1 },
        { date: '3/10/2566', OI: -2065, futurePrice: 882 },
        { date: '4/10/2566', OI: -2284, futurePrice: 885.5 },
        { date: '5/10/2566', OI: -2330, futurePrice: 886.4 },
        { date: '6/10/2566', OI: -2183, futurePrice: 875.3 },
        { date: '9/10/2566', OI: -2090, futurePrice: 874 },
        { date: '10/10/2566', OI: -1953, futurePrice: 877.2 },
        { date: '11/10/2566', OI: -1783, futurePrice: 893.4 },
        { date: '12/10/2566', OI: -2046, futurePrice: 891.1 },
        { date: '16/10/2566', OI: -2503, futurePrice: 877 },
        { date: '17/10/2566', OI: -3325, futurePrice: 879.7 },
        { date: '18/10/2566', OI: -2826, futurePrice: 888.8 },
        { date: '19/10/2566', OI: -2882, futurePrice: 877.3 },
        { date: '20/10/2566', OI: -3397, futurePrice: 859.6 },
        { date: '24/10/2566', OI: -3368, futurePrice: 859.2 },
        { date: '25/10/2566', OI: -3533, futurePrice: 864.3 },
        { date: '26/10/2566', OI: -3583, futurePrice: 844.5 },
        { date: '27/10/2566', OI: -3723, futurePrice: 856.5 },
        { date: '30/10/2566', OI: -3829, futurePrice: 865.7 },
        { date: '31/10/2566', OI: -3567, futurePrice: 856.5 },
        { date: '1/11/2566', OI: -3566, futurePrice: 852.6 },
        { date: '2/11/2566', OI: -3327, futurePrice: 868.4 },
        { date: '3/11/2566', OI: -3521, futurePrice: 875.2 },
        { date: '6/11/2566', OI: -3371, futurePrice: 874.9 },
        { date: '7/11/2566', OI: -3525, futurePrice: 869.1 },
        { date: '8/11/2566', OI: -3523, futurePrice: 872.7 },
        { date: '9/11/2566', OI: -3525, futurePrice: 869.9 },
        { date: '10/11/2566', OI: -3653, futurePrice: 857.2 },
        { date: '13/11/2566', OI: -3601, futurePrice: 860.5 },
        { date: '14/11/2566', OI: -3514, futurePrice: 860 },
        { date: '15/11/2566', OI: -2575, futurePrice: 878.8 },
        { date: '16/11/2566', OI: -2392, futurePrice: 878 },
        { date: '17/11/2566', OI: -2821, futurePrice: 875.5 },
        { date: '20/11/2566', OI: -2809, futurePrice: 878.8 },
        { date: '21/11/2566', OI: -2853, futurePrice: 879 },
        { date: '22/11/2566', OI: -2783, futurePrice: 873 },
        { date: '23/11/2566', OI: -2656, futurePrice: 867.3 },
        { date: '24/11/2566', OI: -2564, futurePrice: 862.6 },
        { date: '27/11/2566', OI: -2621, futurePrice: 859.3 },
        { date: '28/11/2566', OI: -2721, futurePrice: 866.4 },
        { date: '29/11/2566', OI: -2595, futurePrice: 856.6 },
        { date: '30/11/2566', OI: -2707, futurePrice: 849.7 },
        { date: '1/12/2566', OI: -2325, futurePrice: 851.9 },
        { date: '4/12/2566', OI: -1911, futurePrice: 854.8 },
        { date: '6/12/2566', OI: -1947, futurePrice: 855.2 },
        { date: '7/12/2566', OI: -1689, futurePrice: 848 },
        { date: '8/12/2566', OI: -1563, futurePrice: 851.8 },
        { date: '12/12/2566', OI: -1302, futurePrice: 849.7 },
        { date: '13/12/2566', OI: -1071, futurePrice: 839 },
        { date: '14/12/2566', OI: -517, futurePrice: 853.4 },
        { date: '15/12/2566', OI: -250, futurePrice: 861.9 },
        { date: '18/12/2566', OI: -175, futurePrice: 862.9 },
        { date: '19/12/2566', OI: -120, futurePrice: 864.6 },
        { date: '20/12/2566', OI: 80, futurePrice: 868.6 },
        { date: '21/12/2566', OI: 267, futurePrice: 867.9 },
        { date: '22/12/2566', OI: 585, futurePrice: 864.9 },
        { date: '25/12/2566', OI: 805, futurePrice: 867.8 },
        { date: '26/12/2566', OI: 1208, futurePrice: 872.9 },
        { date: '27/12/2566', OI: 1169, futurePrice: 870.2 },
        { date: '28/12/2566', OI: 1658, futurePrice: 873.2 },
        { date: '2/1/2567', OI: 1816, futurePrice: 878.8 },
        { date: '3/1/2567', OI: 1961, futurePrice: 874.6 },
        { date: '4/1/2567', OI: 1190, futurePrice: 875.2 },
        { date: '5/1/2567', OI: 780, futurePrice: 869.2 },
        { date: '8/1/2567', OI: 295, futurePrice: 864.7 },
        { date: '9/1/2567', OI: 571, futurePrice: 864.2 },
        { date: '10/1/2567', OI: -13, futurePrice: 863 },
        { date: '11/1/2567', OI: -1020, futurePrice: 861.2 },
        { date: '12/1/2567', OI: -1222, futurePrice: 861.2 },
        { date: '15/1/2567', OI: -1024, futurePrice: 856.3 },
        { date: '16/1/2567', OI: -1968, futurePrice: 851.9 },
        { date: '17/1/2567', OI: -221, futurePrice: 839 },
        { date: '18/1/2567', OI: 831, futurePrice: 835.9 },
        { date: '19/1/2567', OI: -35, futurePrice: 839.4 },
        { date: '22/1/2567', OI: 1549, futurePrice: 832.2 },
        { date: '23/1/2567', OI: 3718, futurePrice: 825.8 },
        { date: '24/1/2567', OI: 4960, futurePrice: 840.7 },
        { date: '25/1/2567', OI: 4993, futurePrice: 836.8 },
        { date: '26/1/2567', OI: 5472, futurePrice: 834.3 },
        { date: '29/1/2567', OI: 5404, futurePrice: 836.1 },
        { date: '30/1/2567', OI: 5063, futurePrice: 835.4 },
        { date: '31/1/2567', OI: 5845, futurePrice: 829.7 },
        { date: '1/2/2567', OI: 6372, futurePrice: 830.6 },
        { date: '2/2/2567', OI: 4577, futurePrice: 843.6 },
        { date: '5/2/2567', OI: 5305, futurePrice: 839.1 },
        { date: '6/2/2567', OI: 5121, futurePrice: 847 },
        { date: '7/2/2567', OI: 5386, futurePrice: 849.3 },
        { date: '8/2/2567', OI: 5966, futurePrice: 843.5 },
        { date: '9/2/2567', OI: 6139, futurePrice: 840.2 },
        { date: '12/2/2567', OI: 5994, futurePrice: 843.6 },
        { date: '13/2/2567', OI: 3898, futurePrice: 844.4 },
        { date: '14/2/2567', OI: 4699, futurePrice: 836.1 },
        { date: '15/2/2567', OI: 4923, futurePrice: 837.6 },
        { date: '16/2/2567', OI: 4307, futurePrice: 837.8 },
        { date: '19/2/2567', OI: 3960, futurePrice: 839.8 },
        { date: '20/2/2567', OI: 4046, futurePrice: 838 },
        { date: '21/2/2567', OI: 1333, futurePrice: 847.7 },
        { date: '22/2/2567', OI: 258, futurePrice: 853.9 },
        { date: '23/2/2567', OI: 85, futurePrice: 851.5 },
        { date: '27/2/2567', OI: 1583, futurePrice: 846.4 },
        { date: '28/2/2567', OI: 1725, futurePrice: 836.7 },
        { date: '1/3/2567', OI: 5029, futurePrice: 826.2 },
        { date: '4/3/2567', OI: 5136, futurePrice: 830.2 },
        { date: '5/3/2567', OI: 6475, futurePrice: 826.1 },
        { date: '6/3/2567', OI: 6116, futurePrice: 834.7 },
        { date: '7/3/2567', OI: 5062, futurePrice: 836.3 },
        { date: '8/3/2567', OI: 4475, futurePrice: 844.1 },
        { date: '11/3/2567', OI: 4072, futurePrice: 841.1 },
        { date: '12/3/2567', OI: 4147, futurePrice: 842.7 },
        { date: '13/3/2567', OI: 4327, futurePrice: 845.4 },
        { date: '14/3/2567', OI: 3256, futurePrice: 852.8 },
        { date: '15/3/2567', OI: 2445, futurePrice: 846.3 },
        { date: '18/3/2567', OI: 1669, futurePrice: 847.3 },
        { date: '19/3/2567', OI: 2665, futurePrice: 843.4 },
        { date: '20/3/2567', OI: 3504, futurePrice: 838.7 },
        { date: '21/3/2567', OI: 3038, futurePrice: 851.1 },
        { date: '22/3/2567', OI: 3699, futurePrice: 849 },
        { date: '25/3/2567', OI: 3873, futurePrice: 843.4 },
        { date: '26/3/2567', OI: 3893, futurePrice: 845.2 },
        { date: '27/3/2567', OI: 4060, futurePrice: 846.6 },
        { date: '28/3/2567', OI: 3523, futurePrice: 837.58 },
        { date: '29/3/2567', OI: 0, futurePrice: 0 },
        { date: '1/4/2567', OI: 0, futurePrice: 0 }
      ]
    },
    S50M24: {
      volumeData: [
        { date: '27/12/2023', callPut: -152, futurePrice: 868.1 },
        { date: '28/12/2023', callPut: -119, futurePrice: 871.1 },
        { date: '2/1/2024', callPut: -64, futurePrice: 828 },
        { date: '3/1/2024', callPut: 1, futurePrice: 876.6 },
        { date: '4/1/2024', callPut: -60, futurePrice: 872.4 },
        { date: '5/1/2024', callPut: -121, futurePrice: 873 },
        { date: '8/1/2024', callPut: -91, futurePrice: 867.1 },
        { date: '9/1/2024', callPut: 9, futurePrice: 862.5 },
        { date: '10/1/2024', callPut: -20, futurePrice: 861.9 },
        { date: '11/1/2024', callPut: -26, futurePrice: 860.9 },
        { date: '12/1/2024', callPut: -6, futurePrice: 859 },
        { date: '15/1/2024', callPut: -16, futurePrice: 859 },
        { date: '16/1/2024', callPut: -221, futurePrice: 854.1 },
        { date: '17/1/2024', callPut: -483, futurePrice: 849.7 },
        { date: '18/1/2024', callPut: -57, futurePrice: 836.7 },
        { date: '19/1/2024', callPut: 12, futurePrice: 833.7 },
        { date: '22/1/2024', callPut: -25, futurePrice: 837.1 },
        { date: '23/1/2024', callPut: -434, futurePrice: 829.9 },
        { date: '24/1/2024', callPut: -247, futurePrice: 823.4 },
        { date: '25/1/2024', callPut: 84, futurePrice: 837.9 },
        { date: '26/1/2024', callPut: 49, futurePrice: 834 },
        { date: '29/1/2024', callPut: 54, futurePrice: 831.5 },
        { date: '30/1/2024', callPut: 117, futurePrice: 833.5 },
        { date: '31/1/2024', callPut: 46, futurePrice: 833.3 },
        { date: '1/2/2024', callPut: 9, futurePrice: 827.1 },
        { date: '2/2/2024', callPut: 107, futurePrice: 840.3 },
        { date: '5/2/2024', callPut: 77, futurePrice: 836.2 },
        { date: '6/2/2024', callPut: -21, futurePrice: 843.8 },
        { date: '7/2/2024', callPut: 42, futurePrice: 845.8 },
        { date: '8/2/2024', callPut: -5, futurePrice: 840.1 },
        { date: '9/2/2024', callPut: -196, futurePrice: 836.8 },
        { date: '12/2/2024', callPut: -88, futurePrice: 840 },
        { date: '13/2/2024', callPut: 50, futurePrice: 840.9 },
        { date: '14/2/2024', callPut: 120, futurePrice: 832.8 },
        { date: '15/2/2024', callPut: 59, futurePrice: 834 },
        { date: '16/2/2024', callPut: -68, futurePrice: 834.5 },
        { date: '19/2/2024', callPut: -28, futurePrice: 836.2 },
        { date: '20/2/2024', callPut: -235, futurePrice: 834.4 },
        { date: '21/2/2024', callPut: -616, futurePrice: 843.8 },
        { date: '22/2/2024', callPut: -1195, futurePrice: 849.7 },
        { date: '23/2/2024', callPut: -325, futurePrice: 847.7 },
        { date: '27/2/2024', callPut: -415, futurePrice: 842.9 },
        { date: '28/2/2024', callPut: 253, futurePrice: 833.8 },
        { date: '29/2/2024', callPut: 353, futurePrice: 830.6 },
        { date: '1/3/2024', callPut: -13, futurePrice: 824 },
        { date: '4/3/2024', callPut: 18, futurePrice: 827.3 },
        { date: '5/3/2024', callPut: -5, futurePrice: 823.1 },
        { date: '6/3/2024', callPut: 82, futurePrice: 831.6 },
        { date: '7/3/2024', callPut: -284, futurePrice: 833.1 },
        { date: '8/3/2024', callPut: -265, futurePrice: 840.9 },
        { date: '11/3/2024', callPut: -294, futurePrice: 837.7 },
        { date: '12/3/2024', callPut: 33, futurePrice: 839.3 },
        { date: '13/3/2024', callPut: -342, futurePrice: 842.3 },
        { date: '14/3/2024', callPut: 385, futurePrice: 849.5 },
        { date: '15/3/2024', callPut: 612, futurePrice: 843.2 },
        { date: '18/3/2024', callPut: 181, futurePrice: 844.1 },
        { date: '19/3/2024', callPut: -358, futurePrice: 840.2 },
        { date: '20/3/2024', callPut: 172, futurePrice: 835.1 },
        { date: '21/3/2024', callPut: 667, futurePrice: 847.5 },
        { date: '22/3/2024', callPut: 1008, futurePrice: 844.3 },
        { date: '25/3/2024', callPut: 93, futurePrice: 836.8 },
        { date: '26/3/2024', callPut: -236, futurePrice: 839.1 },
        { date: '27/3/2024', callPut: 44, futurePrice: 839.1 },
        { date: '28/3/2024', callPut: 54, futurePrice: 833.3 },
        { date: '29/3/2024', callPut: -1, futurePrice: 837.2 },
        { date: '1/4/2024', callPut: 1198, futurePrice: 840.3 },
        { date: '2/4/2024', callPut: -505, futurePrice: 841 },
        { date: '3/4/2024', callPut: -412, futurePrice: 839 },
        { date: '4/4/2024', callPut: -10, futurePrice: 840.3 },
        { date: '5/4/2024', callPut: -1426, futurePrice: 839.3 },
        { date: '9/4/2024', callPut: -194, futurePrice: 864.5 },
        { date: '10/4/2024', callPut: 2507, futurePrice: 864.1 },
        { date: '11/4/2024', callPut: 965, futurePrice: 854.3 },
        { date: '17/4/2024', callPut: -1830, futurePrice: 835.8 },
        { date: '18/4/2024', callPut: -1190, futurePrice: 830.2 },
        { date: '19/4/2024', callPut: -3992, futurePrice: 808.1 },
        { date: '22/4/2024', callPut: -4201, futurePrice: 823 },
        { date: '23/4/2024', callPut: -1525, futurePrice: 829.4 },
        { date: '24/4/2024', callPut: -950, futurePrice: 832.4 },
        { date: '25/4/2024', callPut: -1559, futurePrice: 833.5 },
        { date: '26/4/2024', callPut: -913, futurePrice: 831.9 },
        { date: '29/4/2024', callPut: 473, futurePrice: 833.2 },
        { date: '30/4/2024', callPut: 55, futurePrice: 835.5 },
        { date: '2/5/2024', callPut: -4216, futurePrice: 832 },
        { date: '3/5/2024', callPut: -3719, futurePrice: 836.7 },
        { date: '7/5/2024', callPut: 860, futurePrice: 845.7 },
        { date: '8/5/2024', callPut: 565, futurePrice: 842 },
        { date: '9/5/2024', callPut: -549, futurePrice: 838.7 },
        { date: '10/5/2024', callPut: -182, futurePrice: 842.7 },
        { date: '13/5/2024', callPut: 208, futurePrice: 842.5 },
        { date: '14/5/2024', callPut: -62, futurePrice: 846.3 },
        { date: '15/5/2024', callPut: 62, futurePrice: 844.9 },
        { date: '16/5/2024', callPut: 532, futurePrice: 849.3 },
        { date: '17/5/2024', callPut: -2637, futurePrice: 854.4 },
        { date: '20/5/2024', callPut: -1314, futurePrice: 853 },
        { date: '21/5/2024', callPut: -2243, futurePrice: 844.8 },
        { date: '23/5/2024', callPut: 117, futurePrice: 842 },
        { date: '24/5/2024', callPut: -807, futurePrice: 839.8 },
        { date: '27/5/2024', callPut: -966, futurePrice: 845.2 },
        { date: '28/5/2024', callPut: -1299, futurePrice: 839.7 },
        { date: '29/5/2024', callPut: -1459, futurePrice: 830.5 },
        { date: '30/5/2024', callPut: -4237, futurePrice: 829.2 },
        { date: '31/5/2024', callPut: -1352, futurePrice: 825.5 },
        { date: '4/6/2024', callPut: -222, futurePrice: 822.3 },
        { date: '5/6/2024', callPut: -54, futurePrice: 823.5 },
        { date: '6/6/2024', callPut: -861, futurePrice: 816.5 },
        { date: '7/6/2024', callPut: 84, futurePrice: 818.4 },
        { date: '10/6/2024', callPut: -3570, futurePrice: 807.7 },
        { date: '11/6/2024', callPut: -797, futurePrice: 807.3 },
        { date: '12/6/2024', callPut: -1453, futurePrice: 811.4 },
        { date: '13/6/2024', callPut: -1414, futurePrice: 808.7 },
        { date: '14/6/2024', callPut: 850, futurePrice: 803.8 },
        { date: '17/6/2024', callPut: -1524, futurePrice: 799.4 },
        { date: '18/6/2024', callPut: -1357, futurePrice: 802.4 },
        { date: '19/6/2024', callPut: -36, futurePrice: 810.9 },
        { date: '20/6/2024', callPut: -740, futurePrice: 804.6 },
        { date: '21/6/2024', callPut: -114, futurePrice: 808.8 },
        { date: '24/6/2024', callPut: -1655, futurePrice: 815.6 },
        { date: '25/6/2024', callPut: -576, futurePrice: 818.1 },
        { date: '26/6/2024', callPut: 1073, futurePrice: 817.2 },
        { date: '27/6/2024', callPut: 548, futurePrice: 813.43 },
        { date: '28/6/2024', callPut: 0, futurePrice: 0 },
        { date: '1/7/2024', callPut: 0, futurePrice: 0 },
      ],
      openInterestData: [
        { date: '27/12/2023', OI: -142, futurePrice: 868.1 },
        { date: '28/12/2023', OI: -261, futurePrice: 871.1 },
        { date: '2/1/2024', OI: -298, futurePrice: 828 },
        { date: '3/1/2024', OI: -236, futurePrice: 876.6 },
        { date: '4/1/2024', OI: -290, futurePrice: 872.4 },
        { date: '5/1/2024', OI: -349, futurePrice: 873 },
        { date: '8/1/2024', OI: -380, futurePrice: 867.1 },
        { date: '9/1/2024', OI: -358, futurePrice: 862.5 },
        { date: '10/1/2024', OI: -378, futurePrice: 861.9 },
        { date: '11/1/2024', OI: -394, futurePrice: 860.9 },
        { date: '12/1/2024', OI: -354, futurePrice: 859 },
        { date: '15/1/2024', OI: -352, futurePrice: 859 },
        { date: '16/1/2024', OI: -581, futurePrice: 854.1 },
        { date: '17/1/2024', OI: -965, futurePrice: 849.7 },
        { date: '18/1/2024', OI: -937, futurePrice: 836.7 },
        { date: '19/1/2024', OI: -932, futurePrice: 833.7 },
        { date: '22/1/2024', OI: -973, futurePrice: 837.1 },
        { date: '23/1/2024', OI: -1377, futurePrice: 829.9 },
        { date: '24/1/2024', OI: -1458, futurePrice: 823.4 },
        { date: '25/1/2024', OI: -1407, futurePrice: 837.9 },
        { date: '26/1/2024', OI: -1432, futurePrice: 834 },
        { date: '29/1/2024', OI: -1438, futurePrice: 831.5 },
        { date: '30/1/2024', OI: -1343, futurePrice: 833.5 },
        { date: '31/1/2024', OI: -1314, futurePrice: 833.3 },
        { date: '1/2/2024', OI: -1304, futurePrice: 827.1 },
        { date: '2/2/2024', OI: -1156, futurePrice: 840.3 },
        { date: '5/2/2024', OI: -1075, futurePrice: 836.2 },
        { date: '6/2/2024', OI: -1046, futurePrice: 843.8 },
        { date: '7/2/2024', OI: -970, futurePrice: 845.8 },
        { date: '8/2/2024', OI: -961, futurePrice: 840.1 },
        { date: '9/2/2024', OI: -1048, futurePrice: 836.8 },
        { date: '12/2/2024', OI: -1087, futurePrice: 840 },
        { date: '13/2/2024', OI: -975, futurePrice: 840.9 },
        { date: '14/2/2024', OI: -810, futurePrice: 832.8 },
        { date: '15/2/2024', OI: -798, futurePrice: 834 },
        { date: '16/2/2024', OI: -915, futurePrice: 834.5 },
        { date: '19/2/2024', OI: -872, futurePrice: 836.2 },
        { date: '20/2/2024', OI: -1200, futurePrice: 834.4 },
        { date: '21/2/2024', OI: -1837, futurePrice: 843.8 },
        { date: '22/2/2024', OI: -3125, futurePrice: 849.7 },
        { date: '23/2/2024', OI: -3402, futurePrice: 847.7 },
        { date: '27/2/2024', OI: -3754, futurePrice: 842.9 },
        { date: '28/2/2024', OI: -3697, futurePrice: 833.8 },
        { date: '29/2/2024', OI: -3561, futurePrice: 830.6 },
        { date: '1/3/2024', OI: -3452, futurePrice: 824 },
        { date: '4/3/2024', OI: -3494, futurePrice: 827.3 },
        { date: '5/3/2024', OI: -3500, futurePrice: 823.1 },
        { date: '6/3/2024', OI: -3400, futurePrice: 831.6 },
        { date: '7/3/2024', OI: -3821, futurePrice: 833.1 },
        { date: '8/3/2024', OI: -4312, futurePrice: 840.9 },
        { date: '11/3/2024', OI: -4566, futurePrice: 837.7 },
        { date: '12/3/2024', OI: -4646, futurePrice: 839.3 },
        { date: '13/3/2024', OI: -5166, futurePrice: 842.3 },
        { date: '14/3/2024', OI: -5417, futurePrice: 849.5 },
        { date: '15/3/2024', OI: -5354, futurePrice: 843.2 },
        { date: '18/3/2024', OI: -5639, futurePrice: 844.1 },
        { date: '19/3/2024', OI: -5991, futurePrice: 840.2 },
        { date: '20/3/2024', OI: -6227, futurePrice: 835.1 },
        { date: '21/3/2024', OI: -5670, futurePrice: 847.5 },
        { date: '22/3/2024', OI: -5045, futurePrice: 844.3 },
        { date: '25/3/2024', OI: -5130, futurePrice: 836.8 },
        { date: '26/3/2024', OI: -5448, futurePrice: 839.1 },
        { date: '27/3/2024', OI: -5307, futurePrice: 839.1 },
        { date: '28/3/2024', OI: -5330, futurePrice: 833.3 },
        { date: '29/3/2024', OI: -5957, futurePrice: 837.2 },
        { date: '1/4/2024', OI: -4298, futurePrice: 840.3 },
        { date: '2/4/2024', OI: -4016, futurePrice: 841 },
        { date: '3/4/2024', OI: -4438, futurePrice: 839 },
        { date: '4/4/2024', OI: -4645, futurePrice: 840.3 },
        { date: '5/4/2024', OI: -5712, futurePrice: 839.3 },
        { date: '9/4/2024', OI: -5998, futurePrice: 864.5 },
        { date: '10/4/2024', OI: -7081, futurePrice: 864.1 },
        { date: '11/4/2024', OI: -7884, futurePrice: 854.3 },
        { date: '17/4/2024', OI: -8509, futurePrice: 835.8 },
        { date: '18/4/2024', OI: -8499, futurePrice: 830.2 },
        { date: '19/4/2024', OI: -6703, futurePrice: 808.1 },
        { date: '22/4/2024', OI: -7572, futurePrice: 823 },
        { date: '23/4/2024', OI: -9995, futurePrice: 829.4 },
        { date: '24/4/2024', OI: -10547, futurePrice: 832.4 },
        { date: '25/4/2024', OI: -11493, futurePrice: 833.5 },
        { date: '26/4/2024', OI: -11949, futurePrice: 831.9 },
        { date: '29/4/2024', OI: -11665, futurePrice: 833.2 },
        { date: '30/4/2024', OI: -11874, futurePrice: 835.5 },
        { date: '2/5/2024', OI: -14207, futurePrice: 832 },
        { date: '3/5/2024', OI: -15878, futurePrice: 836.7 },
        { date: '7/5/2024', OI: -15924, futurePrice: 845.7 },
        { date: '8/5/2024', OI: -15976, futurePrice: 842 },
        { date: '9/5/2024', OI: -16557, futurePrice: 838.7 },
        { date: '10/5/2024', OI: -16644, futurePrice: 842.7 },
        { date: '13/5/2024', OI: -16829, futurePrice: 842.5 },
        { date: '14/5/2024', OI: -16550, futurePrice: 846.3 },
        { date: '15/5/2024', OI: -16852, futurePrice: 844.9 },
        { date: '16/5/2024', OI: -16898, futurePrice: 849.3 },
        { date: '17/5/2024', OI: -18325, futurePrice: 854.4 },
        { date: '20/5/2024', OI: -20493, futurePrice: 853 },
        { date: '21/5/2024', OI: -21468, futurePrice: 844.8 },
        { date: '23/5/2024', OI: -21036, futurePrice: 842 },
        { date: '24/5/2024', OI: -22072, futurePrice: 839.8 },
        { date: '27/5/2024', OI: -23389, futurePrice: 845.2 },
        { date: '28/5/2024', OI: -23478, futurePrice: 839.7 },
        { date: '29/5/2024', OI: -24061, futurePrice: 830.5 },
        { date: '30/5/2024', OI: -22868, futurePrice: 829.2 },
        { date: '31/5/2024', OI: -22693, futurePrice: 825.5 },
        { date: '4/6/2024', OI: -22198, futurePrice: 822.3 },
        { date: '5/6/2024', OI: -21975, futurePrice: 823.5 },
        { date: '6/6/2024', OI: -18533, futurePrice: 816.5 },
        { date: '7/6/2024', OI: -17278, futurePrice: 818.4 },
        { date: '10/6/2024', OI: -14699, futurePrice: 807.7 },
        { date: '11/6/2024', OI: -14207, futurePrice: 807.3 },
        { date: '12/6/2024', OI: -14001, futurePrice: 811.4 },
        { date: '13/6/2024', OI: -14308, futurePrice: 808.7 },
        { date: '14/6/2024', OI: -12570, futurePrice: 803.8 },
        { date: '17/6/2024', OI: -10076, futurePrice: 799.4 },
        { date: '18/6/2024', OI: -9419, futurePrice: 802.4 },
        { date: '19/6/2024', OI: -9166, futurePrice: 810.9 },
        { date: '20/6/2024', OI: -9388, futurePrice: 804.6 },
        { date: '21/6/2024', OI: -9488, futurePrice: 808.8 },
        { date: '24/6/2024', OI: -9394, futurePrice: 815.6 },
        { date: '25/6/2024', OI: -8962, futurePrice: 818.1 },
        { date: '26/6/2024', OI: -8538, futurePrice: 817.2 },
        { date: '27/6/2024', OI: -8967, futurePrice: 813.43 },
        { date: '28/6/2024', OI: 0, futurePrice: 0 },
        { date: '1/7/2024', OI: 0, futurePrice: 0 },
      ]
    },
    S50U24: {
      volumeData: [
        { date: '28/3/2567', callPut: -135, futurePrice: 830.9 },
        { date: '29/3/2567', callPut: 44, futurePrice: 834.9 },
        { date: '1/4/2567', callPut: 54, futurePrice: 838 },
        { date: '2/4/2567', callPut: -343, futurePrice: 838.7 },
        { date: '3/4/2567', callPut: -21, futurePrice: 836.7 },
        { date: '4/4/2567', callPut: -175, futurePrice: 837.8 },
        { date: '5/4/2567', callPut: -386, futurePrice: 836.9 },
        { date: '9/4/2567', callPut: 228, futurePrice: 861.8 },
        { date: '10/4/2567', callPut: 156, futurePrice: 861.3 },
        { date: '11/4/2567', callPut: -171, futurePrice: 851.8 },
        { date: '17/4/2567', callPut: -295, futurePrice: 833.5 },
        { date: '18/4/2567', callPut: 66, futurePrice: 827.8 },
        { date: '19/4/2567', callPut: -1461, futurePrice: 805.8 },
        { date: '22/4/2567', callPut: -308, futurePrice: 820.6 },
        { date: '23/4/2567', callPut: -144, futurePrice: 827 },
        { date: '24/4/2567', callPut: 16, futurePrice: 829.7 },
        { date: '25/4/2567', callPut: -130, futurePrice: 831 },
        { date: '26/4/2567', callPut: 12, futurePrice: 829.1 },
        { date: '29/4/2567', callPut: 32, futurePrice: 830.4 },
        { date: '30/4/2567', callPut: -28, futurePrice: 832.3 },
        { date: '2/5/2567', callPut: -70, futurePrice: 829.2 },
        { date: '3/5/2567', callPut: -173, futurePrice: 833.5 },
        { date: '7/5/2567', callPut: -129, futurePrice: 841.9 },
        { date: '8/5/2567', callPut: 208, futurePrice: 838.2 },
        { date: '9/5/2567', callPut: 24, futurePrice: 834.8 },
        { date: '10/5/2567', callPut: 9, futurePrice: 838.9 },
        { date: '13/5/2567', callPut: 93, futurePrice: 838.6 },
        { date: '14/5/2567', callPut: -193, futurePrice: 842.2 },
        { date: '15/5/2567', callPut: 84, futurePrice: 840.7 },
        { date: '16/5/2567', callPut: -198, futurePrice: 844.9 },
        { date: '17/5/2567', callPut: -31, futurePrice: 849.9 },
        { date: '20/5/2567', callPut: -93, futurePrice: 848.8 },
        { date: '21/5/2567', callPut: 0, futurePrice: 840.8 },
        { date: '23/5/2567', callPut: -183, futurePrice: 838.1 },
        { date: '24/5/2567', callPut: -386, futurePrice: 835.9 },
        { date: '27/5/2567', callPut: 72, futurePrice: 841.4 },
        { date: '28/5/2567', callPut: -599, futurePrice: 836 },
        { date: '29/5/2567', callPut: -300, futurePrice: 827.1 },
        { date: '30/5/2567', callPut: -603, futurePrice: 825.8 },
        { date: '31/5/2567', callPut: 11, futurePrice: 822.1 },
        { date: '4/6/2567', callPut: 247, futurePrice: 819.1 },
        { date: '5/6/2567', callPut: -10, futurePrice: 820.3 },
        { date: '6/6/2567', callPut: -18, futurePrice: 813.4 },
        { date: '7/6/2567', callPut: 251, futurePrice: 815.2 },
        { date: '10/6/2567', callPut: -715, futurePrice: 805 },
        { date: '11/6/2567', callPut: 200, futurePrice: 804.2 },
        { date: '12/6/2567', callPut: -212, futurePrice: 808 },
        { date: '13/6/2567', callPut: -779, futurePrice: 805.8 },
        { date: '14/6/2567', callPut: 566, futurePrice: 801 },
        { date: '17/6/2567', callPut: -523, futurePrice: 796.6 },
        { date: '18/6/2567', callPut: -341, futurePrice: 799.2 },
        { date: '19/6/2567', callPut: -1324, futurePrice: 807.8 },
        { date: '20/6/2567', callPut: 311, futurePrice: 801.6 },
        { date: '21/6/2567', callPut: -51, futurePrice: 805.1 },
        { date: '24/6/2567', callPut: 18, futurePrice: 808.7 },
        { date: '25/6/2567', callPut: 641, futurePrice: 811.2 },
        { date: '26/6/2567', callPut: 75, futurePrice: 810.9 },
        { date: '27/6/2567', callPut: 819, futurePrice: 805.9 },
        { date: '28/6/2567', callPut: -859, futurePrice: 803.1 },
        { date: '1/7/2567', callPut: -268, futurePrice: 802 },
        { date: '2/7/2567', callPut: 258, futurePrice: 793.4 },
        { date: '3/7/2567', callPut: 1160, futurePrice: 799.9 },
        { date: '4/7/2567', callPut: -505, futurePrice: 805.1 },
        { date: '5/7/2567', callPut: -578, futurePrice: 809.9 },
        { date: '8/7/2567', callPut: 881, futurePrice: 811.9 },
        { date: '9/7/2567', callPut: -618, futurePrice: 810.8 },
        { date: '10/7/2567', callPut: 413, futurePrice: 813.5 },
        { date: '11/7/2567', callPut: -573, futurePrice: 818.2 },
        { date: '12/7/2567', callPut: -799, futurePrice: 822.2 },
        { date: '15/7/2567', callPut: 226, futurePrice: 821 },
        { date: '16/7/2567', callPut: 54, futurePrice: 816.7 },
        { date: '17/7/2567', callPut: -482, futurePrice: 820.5 },
        { date: '18/7/2567', callPut: -831, futurePrice: 824.2 },
        { date: '19/7/2567', callPut: -123, futurePrice: 818.4 },
        { date: '23/7/2567', callPut: -1146, futurePrice: 812.7 },
        { date: '24/7/2567', callPut: -526, futurePrice: 809.2 },
        { date: '25/7/2567', callPut: -2068, futurePrice: 805.2 },
        { date: '26/7/2567', callPut: -1717, futurePrice: 816.3 },
        { date: '30/7/2567', callPut: -606, futurePrice: 817 },
        { date: '31/7/2567', callPut: -150, futurePrice: 827 },
        { date: '1/8/2567', callPut: -462, futurePrice: 825.8 },
        { date: '2/8/2567', callPut: 93, futurePrice: 816.6 },
        { date: '5/8/2567', callPut: -8540, futurePrice: 797.9 },
        { date: '6/8/2567', callPut: -3372, futurePrice: 796 },
        { date: '7/8/2567', callPut: -2306, futurePrice: 811.8 },
        { date: '8/8/2567', callPut: -2278, futurePrice: 807.8 },
        { date: '9/8/2567', callPut: -742, futurePrice: 813.4 },
        { date: '13/8/2567', callPut: -494, futurePrice: 812.4 },
        { date: '14/8/2567', callPut: -1753, futurePrice: 811.6 },
        { date: '15/8/2567', callPut: -1995, futurePrice: 811.6 },
        { date: '16/8/2567', callPut: -1012, futurePrice: 818.7 },
        { date: '19/8/2567', callPut: 2791, futurePrice: 832.1 },
        { date: '20/8/2567', callPut: -401, futurePrice: 834.4 },
        { date: '21/8/2567', callPut: -1197, futurePrice: 840.8 },
        { date: '22/8/2567', callPut: -678, futurePrice: 840.3 },
        { date: '23/8/2567', callPut: 300, futurePrice: 849.7 },
        { date: '26/8/2567', callPut: -5162, futurePrice: 857.6 },
        { date: '27/8/2567', callPut: -258, futurePrice: 856.7 },
        { date: '28/8/2567', callPut: -1190, futurePrice: 861.1 },
        { date: '29/8/2567', callPut: 310, futurePrice: 855.3 },
        { date: '30/8/2567', callPut: -674, futurePrice: 853.5 },
        { date: '2/9/2567', callPut: -689, futurePrice: 849.8 },
        { date: '3/9/2567', callPut: -700, futurePrice: 855.8 },
        { date: '4/9/2567', callPut: -192, futurePrice: 854.8 },
        { date: '5/9/2567', callPut: 1443, futurePrice: 890.3 },
        { date: '6/9/2567', callPut: 1907, futurePrice: 905.4 },
        { date: '9/9/2567', callPut: 2349, futurePrice: 900 },
        { date: '10/9/2567', callPut: 731, futurePrice: 898.8 },
        { date: '11/9/2567', callPut: -1583, futurePrice: 892.7 },
        { date: '12/9/2567', callPut: -430, futurePrice: 897.1 },
        { date: '13/9/2567', callPut: 277, futurePrice: 899.7 },
        { date: '16/9/2567', callPut: 357, futurePrice: 903 },
        { date: '17/9/2567', callPut: -1334, futurePrice: 904.2 },
        { date: '18/9/2567', callPut: 122, futurePrice: 903.1 },
        { date: '19/9/2567', callPut: -2380, futurePrice: 915.8 },
        { date: '20/9/2567', callPut: 3682, futurePrice: 910.3 },
        { date: '23/9/2567', callPut: 660, futurePrice: 910.4 },
        { date: '24/9/2567', callPut: 3600, futurePrice: 925.4 },
        { date: '25/9/2567', callPut: 1299, futurePrice: 922.8 },
        { date: '26/9/2567', callPut: 528, futurePrice: 921.5 },
        { date: '27/9/2567', callPut: 2007, futurePrice: 912.77 },
        { date: '30/9/2567', callPut: 0, futurePrice: 0 },
        { date: '1/10/2567', callPut: 0, futurePrice: 0 }
      ],
      openInterestData: [
        { date: '28/3/2567', OI: 135, futurePrice: 830.9 },
        { date: '29/3/2567', OI: 91, futurePrice: 834.9 },
        { date: '1/4/2567', OI: 47, futurePrice: 838 },
        { date: '2/4/2567', OI: 391, futurePrice: 838.7 },
        { date: '3/4/2567', OI: 405, futurePrice: 836.7 },
        { date: '4/4/2567', OI: 604, futurePrice: 837.8 },
        { date: '5/4/2567', OI: 984, futurePrice: 836.9 },
        { date: '9/4/2567', OI: 719, futurePrice: 861.8 },
        { date: '10/4/2567', OI: 559, futurePrice: 861.3 },
        { date: '11/4/2567', OI: 702, futurePrice: 851.8 },
        { date: '17/4/2567', OI: 972, futurePrice: 833.5 },
        { date: '18/4/2567', OI: 1009, futurePrice: 827.8 },
        { date: '19/4/2567', OI: 1743, futurePrice: 805.8 },
        { date: '22/4/2567', OI: 1917, futurePrice: 820.6 },
        { date: '23/4/2567', OI: 2003, futurePrice: 827 },
        { date: '24/4/2567', OI: 2009, futurePrice: 829.7 },
        { date: '25/4/2567', OI: 2087, futurePrice: 831 },
        { date: '26/4/2567', OI: 2073, futurePrice: 829.1 },
        { date: '29/4/2567', OI: 2091, futurePrice: 830.4 },
        { date: '30/4/2567', OI: 2152, futurePrice: 832.3 },
        { date: '2/5/2567', OI: 2231, futurePrice: 829.2 },
        { date: '3/5/2567', OI: 2399, futurePrice: 833.5 },
        { date: '7/5/2567', OI: 2284, futurePrice: 841.9 },
        { date: '8/5/2567', OI: 2160, futurePrice: 838.2 },
        { date: '9/5/2567', OI: 2059, futurePrice: 834.8 },
        { date: '10/5/2567', OI: 2027, futurePrice: 838.9 },
        { date: '13/5/2567', OI: 1973, futurePrice: 838.6 },
        { date: '14/5/2567', OI: 2145, futurePrice: 842.2 },
        { date: '15/5/2567', OI: 2039, futurePrice: 840.7 },
        { date: '16/5/2567', OI: 2098, futurePrice: 844.9 },
        { date: '17/5/2567', OI: 1968, futurePrice: 849.9 },
        { date: '20/5/2567', OI: 2054, futurePrice: 848.8 },
        { date: '21/5/2567', OI: 2158, futurePrice: 840.8 },
        { date: '23/5/2567', OI: 2241, futurePrice: 838.1 },
        { date: '24/5/2567', OI: 2575, futurePrice: 835.9 },
        { date: '27/5/2567', OI: 2497, futurePrice: 841.4 },
        { date: '28/5/2567', OI: 2693, futurePrice: 836 },
        { date: '29/5/2567', OI: 2753, futurePrice: 827.1 },
        { date: '30/5/2567', OI: 3257, futurePrice: 825.8 },
        { date: '31/5/2567', OI: 3204, futurePrice: 822.1 },
        { date: '4/6/2567', OI: 3572, futurePrice: 819.1 },
        { date: '5/6/2567', OI: 3695, futurePrice: 820.3 },
        { date: '6/6/2567', OI: 3618, futurePrice: 813.4 },
        { date: '7/6/2567', OI: 3515, futurePrice: 815.2 },
        { date: '10/6/2567', OI: 2009, futurePrice: 805 },
        { date: '11/6/2567', OI: 1885, futurePrice: 804.2 },
        { date: '12/6/2567', OI: 2307, futurePrice: 808 },
        { date: '13/6/2567', OI: 2862, futurePrice: 805.8 },
        { date: '14/6/2567', OI: 2965, futurePrice: 801 },
        { date: '17/6/2567', OI: 2702, futurePrice: 796.6 },
        { date: '18/6/2567', OI: 2774, futurePrice: 799.2 },
        { date: '19/6/2567', OI: 2533, futurePrice: 807.8 },
        { date: '20/6/2567', OI: 2427, futurePrice: 801.6 },
        { date: '21/6/2567', OI: 2013, futurePrice: 805.1 },
        { date: '24/6/2567', OI: 1983, futurePrice: 808.7 },
        { date: '25/6/2567', OI: 1729, futurePrice: 811.2 },
        { date: '26/6/2567', OI: 1683, futurePrice: 810.9 },
        { date: '27/6/2567', OI: 2046, futurePrice: 805.9 },
        { date: '28/6/2567', OI: 2169, futurePrice: 803.1 },
        { date: '1/7/2567', OI: 1740, futurePrice: 802 },
        { date: '2/7/2567', OI: 1830, futurePrice: 793.4 },
        { date: '3/7/2567', OI: 1505, futurePrice: 799.9 },
        { date: '4/7/2567', OI: 1063, futurePrice: 805.1 },
        { date: '5/7/2567', OI: 1229, futurePrice: 809.9 },
        { date: '8/7/2567', OI: 810, futurePrice: 811.9 },
        { date: '9/7/2567', OI: 643, futurePrice: 810.8 },
        { date: '10/7/2567', OI: 904, futurePrice: 813.5 },
        { date: '11/7/2567', OI: 1924, futurePrice: 818.2 },
        { date: '12/7/2567', OI: 2161, futurePrice: 822.2 },
        { date: '15/7/2567', OI: 1755, futurePrice: 821 },
        { date: '16/7/2567', OI: 1766, futurePrice: 816.7 },
        { date: '17/7/2567', OI: 1993, futurePrice: 820.5 },
        { date: '18/7/2567', OI: 2790, futurePrice: 824.2 },
        { date: '19/7/2567', OI: 3818, futurePrice: 818.4 },
        { date: '23/7/2567', OI: 4229, futurePrice: 812.7 },
        { date: '24/7/2567', OI: 3802, futurePrice: 809.2 },
        { date: '25/7/2567', OI: 5212, futurePrice: 805.2 },
        { date: '26/7/2567', OI: 6313, futurePrice: 816.3 },
        { date: '30/7/2567', OI: 7061, futurePrice: 817 },
        { date: '31/7/2567', OI: 7530, futurePrice: 827 },
        { date: '1/8/2567', OI: 7561, futurePrice: 825.8 },
        { date: '2/8/2567', OI: 7295, futurePrice: 816.6 },
        { date: '5/8/2567', OI: 6296, futurePrice: 797.9 },
        { date: '6/8/2567', OI: 6045, futurePrice: 796 },
        { date: '7/8/2567', OI: 6479, futurePrice: 811.8 },
        { date: '8/8/2567', OI: 7613, futurePrice: 807.8 },
        { date: '9/8/2567', OI: 7960, futurePrice: 813.4 },
        { date: '13/8/2567', OI: 9448, futurePrice: 812.4 },
        { date: '14/8/2567', OI: 11464, futurePrice: 811.6 },
        { date: '15/8/2567', OI: 10998, futurePrice: 811.6 },
        { date: '16/8/2567', OI: 10836, futurePrice: 818.7 },
        { date: '19/8/2567', OI: 15059, futurePrice: 832.1 },
        { date: '20/8/2567', OI: 19057, futurePrice: 834.4 },
        { date: '21/8/2567', OI: 21169, futurePrice: 840.8 },
        { date: '22/8/2567', OI: 22966, futurePrice: 840.3 },
        { date: '23/8/2567', OI: 24952, futurePrice: 849.7 },
        { date: '26/8/2567', OI: 28547, futurePrice: 857.6 },
        { date: '27/8/2567', OI: 29681, futurePrice: 856.7 },
        { date: '28/8/2567', OI: 31144, futurePrice: 861.1 },
        { date: '29/8/2567', OI: 31441, futurePrice: 855.3 },
        { date: '30/8/2567', OI: 32411, futurePrice: 853.5 },
        { date: '2/9/2567', OI: 33207, futurePrice: 849.8 },
        { date: '3/9/2567', OI: 34211, futurePrice: 855.8 },
        { date: '4/9/2567', OI: 34970, futurePrice: 854.8 },
        { date: '5/9/2567', OI: 40408, futurePrice: 890.3 },
        { date: '6/9/2567', OI: 45688, futurePrice: 905.4 },
        { date: '9/9/2567', OI: 47462, futurePrice: 900 },
        { date: '10/9/2567', OI: 49223, futurePrice: 898.8 },
        { date: '11/9/2567', OI: 50040, futurePrice: 892.7 },
        { date: '12/9/2567', OI: 51394, futurePrice: 897.1 },
        { date: '13/9/2567', OI: 51657, futurePrice: 899.7 },
        { date: '16/9/2567', OI: 52716, futurePrice: 903 },
        { date: '17/9/2567', OI: 54126, futurePrice: 904.2 },
        { date: '18/9/2567', OI: 55880, futurePrice: 903.1 },
        { date: '19/9/2567', OI: 57163, futurePrice: 915.8 },
        { date: '20/9/2567', OI: 57104, futurePrice: 910.3 },
        { date: '23/9/2567', OI: 57351, futurePrice: 910.4 },
        { date: '24/9/2567', OI: 56260, futurePrice: 925.4 },
        { date: '25/9/2567', OI: 56988, futurePrice: 922.8 },
        { date: '26/9/2567', OI: 58132, futurePrice: 921.5 },
        { date: '27/9/2567', OI: 58155, futurePrice: 912.77 },
        { date: '30/9/2567', OI: 0, futurePrice: 0 },
        { date: '1/10/2567', OI: 0, futurePrice: 0 },
      ]
    },
    S50Z24: {
      volumeData: [
        { date: '27/6/2567', callPut: -80, futurePrice: 807.8 },
        { date: '28/6/2567', callPut: -101, futurePrice: 805 },
        { date: '1/7/2567', callPut: -63, futurePrice: 803.8 },
        { date: '2/7/2567', callPut: -387, futurePrice: 795.3 },
        { date: '3/7/2567', callPut: 156, futurePrice: 801.5 },
        { date: '4/7/2567', callPut: -182, futurePrice: 806.4 },
        { date: '5/7/2567', callPut: 57, futurePrice: 810.9 },
        { date: '8/7/2567', callPut: -38, futurePrice: 812.9 },
        { date: '9/7/2567', callPut: -46, futurePrice: 811.9 },
        { date: '10/7/2567', callPut: 57, futurePrice: 814.2 },
        { date: '11/7/2567', callPut: -46, futurePrice: 818.9 },
        { date: '12/7/2567', callPut: 116, futurePrice: 822.6 },
        { date: '15/7/2567', callPut: -150, futurePrice: 821.2 },
        { date: '16/7/2567', callPut: -9, futurePrice: 817 },
        { date: '17/7/2567', callPut: -5, futurePrice: 820.6 },
        { date: '18/7/2567', callPut: -62, futurePrice: 824 },
        { date: '19/7/2567', callPut: 33, futurePrice: 818.5 },
        { date: '23/7/2567', callPut: 360, futurePrice: 812.8 },
        { date: '24/7/2567', callPut: -30, futurePrice: 809.6 },
        { date: '25/7/2567', callPut: -247, futurePrice: 805.8 },
        { date: '26/7/2567', callPut: 113, futurePrice: 816.4 },
        { date: '30/7/2567', callPut: -31, futurePrice: 817.1 },
        { date: '31/7/2567', callPut: 61, futurePrice: 826.8 },
        { date: '1/8/2567', callPut: -435, futurePrice: 825.6 },
        { date: '2/8/2567', callPut: -199, futurePrice: 816.6 },
        { date: '5/8/2567', callPut: -634, futurePrice: 798.2 },
        { date: '6/8/2567', callPut: -1193, futurePrice: 796.3 },
        { date: '7/8/2567', callPut: -385, futurePrice: 811.7 },
        { date: '8/8/2567', callPut: -609, futurePrice: 807.8 },
        { date: '9/8/2567', callPut: -179, futurePrice: 813.6 },
        { date: '13/8/2567', callPut: -328, futurePrice: 812.7 },
        { date: '14/8/2567', callPut: 219, futurePrice: 811.9 },
        { date: '15/8/2567', callPut: 352, futurePrice: 811.8 },
        { date: '16/8/2567', callPut: -60, futurePrice: 818.7 },
        { date: '19/8/2567', callPut: 628, futurePrice: 832.2 },
        { date: '20/8/2567', callPut: 236, futurePrice: 834.8 },
        { date: '21/8/2567', callPut: 204, futurePrice: 841 },
        { date: '22/8/2567', callPut: -15, futurePrice: 840.6 },
        { date: '23/8/2567', callPut: -12, futurePrice: 849.9 },
        { date: '26/8/2567', callPut: 567, futurePrice: 858.1 },
        { date: '27/8/2567', callPut: -217, futurePrice: 857.4 },
        { date: '28/8/2567', callPut: -94, futurePrice: 861.7 },
        { date: '29/8/2567', callPut: 37, futurePrice: 856 },
        { date: '30/8/2567', callPut: 68, futurePrice: 854.3 },
        { date: '2/9/2567', callPut: -11, futurePrice: 850.6 },
        { date: '3/9/2567', callPut: -10, futurePrice: 856.4 },
        { date: '4/9/2567', callPut: 29, futurePrice: 855.5 },
        { date: '5/9/2567', callPut: 1046, futurePrice: 890.9 },
        { date: '6/9/2567', callPut: 84, futurePrice: 906.9 },
        { date: '9/9/2567', callPut: 1402, futurePrice: 902.1 },
        { date: '10/9/2567', callPut: -80, futurePrice: 900.5 },
        { date: '11/9/2567', callPut: 793, futurePrice: 895 },
        { date: '12/9/2567', callPut: 144, futurePrice: 899.3 },
        { date: '13/9/2567', callPut: -140, futurePrice: 902.1 },
        { date: '16/9/2567', callPut: 270, futurePrice: 905.2 },
        { date: '17/9/2567', callPut: 1439, futurePrice: 906.4 },
        { date: '18/9/2567', callPut: 1044, futurePrice: 905.9 },
        { date: '19/9/2567', callPut: 733, futurePrice: 918.5 },
        { date: '20/9/2567', callPut: -1447, futurePrice: 913.4 },
        { date: '23/9/2567', callPut: 148, futurePrice: 912.5 },
        { date: '24/9/2567', callPut: 500, futurePrice: 926.2 },
        { date: '25/9/2567', callPut: -1784, futurePrice: 922 },
        { date: '26/9/2567', callPut: 166, futurePrice: 921.9 },
        { date: '27/9/2567', callPut: 328, futurePrice: 916.4 },
        { date: '30/9/2567', callPut: -2799, futurePrice: 914.7 },
        { date: '1/10/2567', callPut: -2010, futurePrice: 924.8 },
        { date: '2/10/2567', callPut: -5495, futurePrice: 914.6 },
        { date: '3/10/2567', callPut: -1634, futurePrice: 911.7 },
        { date: '4/10/2567', callPut: -8239, futurePrice: 913.8 },
        { date: '7/10/2567', callPut: -3124, futurePrice: 919.5 },
        { date: '8/10/2567', callPut: -980, futurePrice: 919.7 },
        { date: '9/10/2567', callPut: -1055, futurePrice: 922.9 },
        { date: '10/10/2567', callPut: -2184, futurePrice: 932.7 },
        { date: '11/10/2567', callPut: -2539, futurePrice: 934.3 },
        { date: '15/10/2567', callPut: -1970, futurePrice: 934.8 },
        { date: '16/10/2567', callPut: -4666, futurePrice: 948.9 },
        { date: '17/10/2567', callPut: -1543, futurePrice: 956.8 },
        { date: '18/10/2567', callPut: -4420, futurePrice: 956.6 },
        { date: '21/10/2567', callPut: -2866, futurePrice: 951.8 },
        { date: '22/10/2567', callPut: -4605, futurePrice: 940 },
        { date: '24/10/2567', callPut: -2034, futurePrice: 936 },
        { date: '25/10/2567', callPut: -3878, futurePrice: 939.9 },
        { date: '28/10/2567', callPut: -2117, futurePrice: 929.5 },
        { date: '29/10/2567', callPut: -881, futurePrice: 929.8 },
        { date: '30/10/2567', callPut: 563, futurePrice: 923.6 },
        { date: '31/10/2567', callPut: 891, futurePrice: 937.2 },
        { date: '1/11/2567', callPut: -529, futurePrice: 936.2 },
        { date: '4/11/2567', callPut: -2208, futurePrice: 938.8 },
        { date: '5/11/2567', callPut: -1148, futurePrice: 952.5 },
        { date: '6/11/2567', callPut: -2063, futurePrice: 944.6 },
        { date: '7/11/2567', callPut: -2574, futurePrice: 949.6 },
        { date: '8/11/2567', callPut: -4436, futurePrice: 941.1 },
        { date: '11/11/2567', callPut: -1224, futurePrice: 937.5 },
        { date: '12/11/2567', callPut: -2516, futurePrice: 932.2 },
        { date: '13/11/2567', callPut: -3453, futurePrice: 936.4 },
        { date: '14/11/2567', callPut: 296, futurePrice: 931.6 },
        { date: '15/11/2567', callPut: 0, futurePrice: 0 },
        { date: '18/11/2567', callPut: 0, futurePrice: 0 }
      ],
      openInterestData: [
        { date: '27/6/2567', OI: -80, futurePrice: 807.8 },
        { date: '28/6/2567', OI: -166, futurePrice: 805 },
        { date: '1/7/2567', OI: -228, futurePrice: 803.8 },
        { date: '2/7/2567', OI: -603, futurePrice: 795.3 },
        { date: '3/7/2567', OI: -414, futurePrice: 801.5 },
        { date: '4/7/2567', OI: -572, futurePrice: 806.4 },
        { date: '5/7/2567', OI: -457, futurePrice: 810.9 },
        { date: '8/7/2567', OI: -485, futurePrice: 812.9 },
        { date: '9/7/2567', OI: -453, futurePrice: 811.9 },
        { date: '10/7/2567', OI: -391, futurePrice: 814.2 },
        { date: '11/7/2567', OI: -426, futurePrice: 818.9 },
        { date: '12/7/2567', OI: -253, futurePrice: 822.6 },
        { date: '15/7/2567', OI: -318, futurePrice: 821.2 },
        { date: '16/7/2567', OI: -295, futurePrice: 817 },
        { date: '17/7/2567', OI: -276, futurePrice: 820.6 },
        { date: '18/7/2567', OI: -268, futurePrice: 824 },
        { date: '19/7/2567', OI: -281, futurePrice: 818.5 },
        { date: '23/7/2567', OI: 73, futurePrice: 812.8 },
        { date: '24/7/2567', OI: -4, futurePrice: 809.6 },
        { date: '25/7/2567', OI: -224, futurePrice: 805.8 },
        { date: '26/7/2567', OI: -120, futurePrice: 816.4 },
        { date: '30/7/2567', OI: -240, futurePrice: 817.1 },
        { date: '31/7/2567', OI: -13, futurePrice: 826.8 },
        { date: '1/8/2567', OI: -205, futurePrice: 825.6 },
        { date: '2/8/2567', OI: -363, futurePrice: 816.6 },
        { date: '5/8/2567', OI: -796, futurePrice: 798.2 },
        { date: '6/8/2567', OI: -1826, futurePrice: 796.3 },
        { date: '7/8/2567', OI: -1981, futurePrice: 811.7 },
        { date: '8/8/2567', OI: -2430, futurePrice: 807.8 },
        { date: '9/8/2567', OI: -2477, futurePrice: 813.6 },
        { date: '13/8/2567', OI: -2703, futurePrice: 812.7 },
        { date: '14/8/2567', OI: -2579, futurePrice: 811.9 },
        { date: '15/8/2567', OI: -2456, futurePrice: 811.8 },
        { date: '16/8/2567', OI: -2466, futurePrice: 818.7 },
        { date: '19/8/2567', OI: -1923, futurePrice: 832.2 },
        { date: '20/8/2567', OI: -1943, futurePrice: 834.8 },
        { date: '21/8/2567', OI: -1929, futurePrice: 841 },
        { date: '22/8/2567', OI: -1917, futurePrice: 840.6 },
        { date: '23/8/2567', OI: -1843, futurePrice: 849.9 },
        { date: '26/8/2567', OI: -1259, futurePrice: 858.1 },
        { date: '27/8/2567', OI: -1077, futurePrice: 857.4 },
        { date: '28/8/2567', OI: -1471, futurePrice: 861.7 },
        { date: '29/8/2567', OI: -1950, futurePrice: 856 },
        { date: '30/8/2567', OI: -2009, futurePrice: 854.3 },
        { date: '2/9/2567', OI: -2238, futurePrice: 850.6 },
        { date: '3/9/2567', OI: -2418, futurePrice: 856.4 },
        { date: '4/9/2567', OI: -2589, futurePrice: 855.5 },
        { date: '5/9/2567', OI: -2737, futurePrice: 890.9 },
        { date: '6/9/2567', OI: -3471, futurePrice: 906.9 },
        { date: '9/9/2567', OI: -3239, futurePrice: 902.1 },
        { date: '10/9/2567', OI: -3471, futurePrice: 900.5 },
        { date: '11/9/2567', OI: -2894, futurePrice: 895 },
        { date: '12/9/2567', OI: -2618, futurePrice: 899.3 },
        { date: '13/9/2567', OI: -2530, futurePrice: 902.1 },
        { date: '16/9/2567', OI: -2128, futurePrice: 905.2 },
        { date: '17/9/2567', OI: -1265, futurePrice: 906.4 },
        { date: '18/9/2567', OI: -442, futurePrice: 905.9 },
        { date: '19/9/2567', OI: 1193, futurePrice: 918.5 },
        { date: '20/9/2567', OI: 187, futurePrice: 913.4 },
        { date: '23/9/2567', OI: 766, futurePrice: 912.5 },
        { date: '24/9/2567', OI: 922, futurePrice: 926.2 },
        { date: '25/9/2567', OI: 218, futurePrice: 922 },
        { date: '26/9/2567', OI: 562, futurePrice: 921.9 },
        { date: '27/9/2567', OI: 693, futurePrice: 916.4 },
        { date: '30/9/2567', OI: 221, futurePrice: 914.7 },
        { date: '1/10/2567', OI: 1076, futurePrice: 924.8 },
        { date: '2/10/2567', OI: -2527, futurePrice: 914.6 },
        { date: '3/10/2567', OI: -3395, futurePrice: 911.7 },
        { date: '4/10/2567', OI: -9837, futurePrice: 913.8 },
        { date: '7/10/2567', OI: -13203, futurePrice: 919.5 },
        { date: '8/10/2567', OI: -14181, futurePrice: 919.7 },
        { date: '9/10/2567', OI: -15041, futurePrice: 922.9 },
        { date: '10/10/2567', OI: -16355, futurePrice: 932.7 },
        { date: '11/10/2567', OI: -18506, futurePrice: 934.3 },
        { date: '15/10/2567', OI: -20102, futurePrice: 934.8 },
        { date: '16/10/2567', OI: -21511, futurePrice: 948.9 },
        { date: '17/10/2567', OI: -22416, futurePrice: 956.8 },
        { date: '18/10/2567', OI: -24656, futurePrice: 956.6 },
        { date: '21/10/2567', OI: -24780, futurePrice: 951.8 },
        { date: '22/10/2567', OI: -25478, futurePrice: 940 },
        { date: '24/10/2567', OI: -25463, futurePrice: 936 },
        { date: '25/10/2567', OI: -25521, futurePrice: 939.9 },
        { date: '28/10/2567', OI: -24525, futurePrice: 929.5 },
        { date: '29/10/2567', OI: -24691, futurePrice: 929.8 },
        { date: '30/10/2567', OI: -24106, futurePrice: 923.6 },
        { date: '31/10/2567', OI: -23267, futurePrice: 937.2 },
        { date: '1/11/2567', OI: -23918, futurePrice: 936.2 },
        { date: '4/11/2567', OI: -24071, futurePrice: 938.8 },
        { date: '5/11/2567', OI: -24770, futurePrice: 952.5 },
        { date: '6/11/2567', OI: -25915, futurePrice: 944.6 },
        { date: '7/11/2567', OI: -26825, futurePrice: 949.6 },
        { date: '8/11/2567', OI: -27394, futurePrice: 941.1 },
        { date: '11/11/2567', OI: -27081, futurePrice: 937.5 },
        { date: '12/11/2567', OI: -26620, futurePrice: 932.2 },
        { date: '13/11/2567', OI: -26641, futurePrice: 936.4 },
        { date: '14/11/2567', OI: -26155, futurePrice: 931.6 },
        { date: '15/11/2567', OI: 0, futurePrice: 0 },
        { date: '18/11/2567', OI: 0, futurePrice: 0 }
      ]
    }
  }
};

const DualAxisChart = ({
  data,
  chartTitle,
  primaryLineColor = 'green',
  secondaryLineColor = 'red',
  onChartReady,
}) => {
  const chartContainerRef = useRef();
  const [chart, setChart] = useState(null);
  const tooltipRef = useRef();

  useEffect(() => {
    if (!chartContainerRef.current) return;

    const tooltipDiv = document.createElement('div');
    tooltipDiv.style.position = 'absolute';
    tooltipDiv.style.display = 'none';
    tooltipDiv.style.padding = '8px';
    tooltipDiv.style.backgroundColor = 'rgba(211, 211, 211, 0.8)';
    tooltipDiv.style.color = 'Black';
    tooltipDiv.style.borderRadius = '20px';
    tooltipDiv.style.fontSize = '13px';
    tooltipDiv.style.zIndex = '1000';
    tooltipDiv.style.pointerEvents = 'none';
    chartContainerRef.current.appendChild(tooltipDiv);
    tooltipRef.current = tooltipDiv;
    tooltipDiv.style.fontWeight = '1000';

    const newChart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: 260,
      rightPriceScale: {
        visible: true,
        borderColor: '#D1D4DC',
        textColor: '#000',
        fontSize: 30,
      },
      leftPriceScale: {
        visible: true,
        borderColor: '#D1D4DC',
        textColor: '#000',
        fontSize: 30,
      },
      grid: {
        vertLines: { color: '#f0f0f0' },
        horzLines: { color: '#f0f0f0' },
      },
      timeScale: {
        timeVisible: true,
        secondsVisible: false,
        borderColor: '#D1D4DC',
        rightOffset: 5,
        barSpacing: 30,
        fixLeftEdge: false,
        fixRightEdge: false,
        rightBarStaysOnScroll: true,
        lockVisibleTimeRangeOnResize: true,
        minBarSpacing: 10,
        tickMarkFormatter: (time) => {
          const date = new Date(time * 1000);
          return date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          });
        },
      },
      crosshair: {
        mode: 1,
        vertLine: {
          width: 1,
          color: '#758696',
          style: 3,
        },
        horzLine: {
          visible: false,
        },
      },
      handleScale: {
        mouseWheel: true,
        pinch: true,
        axisPressedMouseMove: true,
      },
      handleScroll: {
        mouseWheel: true,
        pressedMouseMove: true,
        horzTouchDrag: true,
        vertTouchDrag: true,
      },
    });

    setChart(newChart);

    if (onChartReady) {
      onChartReady(newChart);
    }

    const filteredData = data.filter((d) => d.futurePrice !== 0);

    const xData = filteredData.map((d) => {
      const [day, month, year] = d.date.split('/').map(Number);
      const adjustedYear = year > 2400 ? year - 543 : year;
      const timestamp = new Date(adjustedYear, month - 1, day, 12, 0, 0, 0).getTime() / 1000;

      return {
        time: timestamp,
        primaryValue: d.callPut,
        futurePrice: d.futurePrice,
        originalDate: `${day}/${month}/${adjustedYear}`,
      };
    });

    xData.sort((a, b) => a.time - b.time);
    const primarySeries = newChart.addLineSeries({
      color: primaryLineColor,
      lineWidth: 2,
      lastPriceAnimation: 0,
      priceScaleId: 'right',
      title: 'Call-Put',
      crosshairMarkerVisible: true,
      crosshairMarkerRadius: 4,
      priceFormat: {
        type: 'custom',
        formatter: (price) => {
          return Math.round(price).toLocaleString('en-US');
        },
      },
    });

    const secondarySeries = newChart.addLineSeries({
      color: secondaryLineColor,
      lineWidth: 2,
      lastPriceAnimation: 0,
      priceScaleId: 'left',
      title: 'Futures Price',
      crosshairMarkerVisible: true,
      crosshairMarkerRadius: 4,
      priceFormat: {
        type: 'custom',
        formatter: (price) => {
          return price.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        },
      },
    });

    primarySeries.setData(
      xData.map((d) => ({
        time: d.time,
        value: d.primaryValue,
      }))
    );

    secondarySeries.setData(
      xData.map((d) => ({
        time: d.time,
        value: d.futurePrice,
      }))
    );

    newChart.subscribeCrosshairMove((param) => {
      if (
        param.point === undefined ||
        !param.time ||
        param.point.x < 0 ||
        param.point.y < 0
      ) {
        tooltipRef.current.style.display = 'none';
        return;
      }

      const coordinate = param.point;
      const dataPoint = xData.find((d) => d.time === param.time);

      if (dataPoint) {
        tooltipRef.current.style.display = 'block';
        tooltipRef.current.style.left = `${coordinate.x + 15}px`;
        tooltipRef.current.style.top = `${coordinate.y + 15}px`;

        tooltipRef.current.innerHTML = `
          <div style="margin-bottom: 4px">วันที่: ${dataPoint.originalDate}</div>
          <div style="color: ${primaryLineColor}">Call-Put: ${dataPoint.primaryValue.toLocaleString()}</div>
          <div style="color: ${secondaryLineColor}">Futures Price: ${dataPoint.futurePrice.toLocaleString()}</div>
        `;
      }
    });

    newChart.timeScale().fitContent();

    const handleResize = () => {
      if (chartContainerRef.current && chart) {
        chart.resize(chartContainerRef.current.clientWidth, 400);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      if (tooltipRef.current) {
        tooltipRef.current.remove();
      }
      newChart.remove();
    };
  }, [data, primaryLineColor, secondaryLineColor]);

  return (
    <div style={{ marginBottom: '-10px' }}>
      <h2 sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            padding: '12px',
            background: '#f8f9fa',
            borderRadius: '8px',
            marginBottom: '16px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          }}
        >
          {chartTitle} :
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              fontSize: '18px',
            }}
          >
            <div
              style={{
                width: '30px',
                height: '4px',
                backgroundColor: primaryLineColor,
                marginRight: '8px',
              }}
            />
            <span>Call-Put</span>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              fontSize: '18px',
            }}
          >
            <div
              style={{
                width: '30px',
                height: '4px',
                backgroundColor: secondaryLineColor,
                marginRight: '8px',
              }}
            />
            <span>ราคา Futures</span>
          </div>
        </div>
      </h2>
      <div ref={chartContainerRef} style={{ height: '100%', width: '100%', position: 'relative' }} />
    </div>
  );
};

const DualAxisChart1 = ({
  data,
  chartTitle,
  primaryLineColor = 'blue',
  secondaryLineColor = 'red',
  onChartReady,
}) => {
  const chartContainerRef = useRef();
  const [chart, setChart] = useState(null);
  const tooltipRef = useRef();

  useEffect(() => {
    if (!chartContainerRef.current) return;

    const tooltipDiv = document.createElement('div');
    tooltipDiv.style.position = 'absolute';
    tooltipDiv.style.display = 'none';
    tooltipDiv.style.padding = '8px';
    tooltipDiv.style.backgroundColor = 'rgba(211, 211, 211, 0.8)';
    tooltipDiv.style.color = 'Black';
    tooltipDiv.style.borderRadius = '20px';
    tooltipDiv.style.fontSize = '13px';
    tooltipDiv.style.zIndex = '1000';
    tooltipDiv.style.pointerEvents = 'none';
    chartContainerRef.current.appendChild(tooltipDiv);
    tooltipRef.current = tooltipDiv;
    tooltipDiv.style.fontWeight = '1000';

    const newChart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: 260,
      rightPriceScale: {
        visible: true,
        borderColor: '#D1D4DC',
        textColor: '#000',
        fontSize: 30,
      },
      leftPriceScale: {
        visible: true,
        borderColor: '#D1D4DC',
        textColor: '#000',
        fontSize: 30,
      },
      grid: {
        vertLines: { color: '#f0f0f0' },
        horzLines: { color: '#f0f0f0' },
      },
      timeScale: {
        timeVisible: true,
        secondsVisible: false,
        borderColor: '#D1D4DC',
        rightOffset: 5,
        barSpacing: 30,
        fixLeftEdge: false,
        fixRightEdge: false,
        rightBarStaysOnScroll: true,
        lockVisibleTimeRangeOnResize: true,
        minBarSpacing: 10,
        tickMarkFormatter: (time) => {
          const date = new Date(time * 1000);
          return date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          });
        }
      },
      crosshair: {
        mode: 1,
        vertLine: {
          width: 1,
          color: '#758696',
          style: 3,
        },
        horzLine: {
          visible: false,
        },
      },
      handleScale: {
        mouseWheel: true,
        pinch: true,
        axisPressedMouseMove: true,
      },
      handleScroll: {
        mouseWheel: true,
        pressedMouseMove: true,
        horzTouchDrag: true,
        vertTouchDrag: true,
      },
    });

    setChart(newChart);

    if (onChartReady) {
      onChartReady(newChart);
    }

    const filteredData = data.filter(d => d.futurePrice !== 0);

    const xData = filteredData.map((d) => {
      const [day, month, year] = d.date.split('/').map(Number);
      const adjustedYear = year > 2400 ? year - 543 : year;
      const timestamp = new Date(adjustedYear, month - 1, day, 12, 0, 0, 0).getTime() / 1000;

      return {
        time: timestamp,
        primaryValue: d.OI,
        futurePrice: d.futurePrice,
        originalDate: `${day}/${month}/${adjustedYear}`
      };
    });

    xData.sort((a, b) => a.time - b.time);

    const primarySeries = newChart.addLineSeries({
      color: primaryLineColor,
      lineWidth: 2,
      lastPriceAnimation: 0,
      priceScaleId: 'right',
      title: 'Open Interest',
      crosshairMarkerVisible: true,
      crosshairMarkerRadius: 4,
      priceFormat: {
        type: 'custom',
        formatter: (price) => {
          return Math.round(price).toLocaleString('en-US');
        },
      },
    });

    const secondarySeries = newChart.addLineSeries({
      color: secondaryLineColor,
      lineWidth: 2,
      lastPriceAnimation: 0,
      priceScaleId: 'left',
      title: 'Futures Price',
      crosshairMarkerVisible: true,
      crosshairMarkerRadius: 4,
      priceFormat: {
        type: 'custom',
        formatter: (price) => {
          return price.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          });
        },
      },
    });

    primarySeries.setData(xData.map(d => ({
      time: d.time,
      value: d.primaryValue
    })));

    secondarySeries.setData(xData.map(d => ({
      time: d.time,
      value: d.futurePrice
    })));

    newChart.subscribeCrosshairMove(param => {
      if (param.point === undefined || !param.time || param.point.x < 0 || param.point.y < 0) {
        tooltipRef.current.style.display = 'none';
        return;
      }

      const coordinate = param.point;
      const dataPoint = xData.find(d => d.time === param.time);

      if (dataPoint) {
        tooltipRef.current.style.display = 'block';
        tooltipRef.current.style.left = `${coordinate.x + 15}px`;
        tooltipRef.current.style.top = `${coordinate.y + 15}px`;

        tooltipRef.current.innerHTML = `
          <div style="margin-bottom: 4px">วันที่: ${dataPoint.originalDate}</div>
          <div style="color: ${primaryLineColor}">Open Interest: ${dataPoint.primaryValue.toLocaleString()}</div>
          <div style="color: ${secondaryLineColor}">Futures Price: ${dataPoint.futurePrice.toLocaleString()}</div>
        `;
      }
    });

    newChart.timeScale().fitContent();

    const handleResize = () => {
      if (chartContainerRef.current && chart) {
        chart.resize(chartContainerRef.current.clientWidth, 400);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      if (tooltipRef.current) {
        tooltipRef.current.remove();
      }
      newChart.remove();
    };
  }, [data, primaryLineColor, secondaryLineColor]);

  return (
    <div >
      <h2 sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            padding: '12px',
            background: '#f8f9fa',
            borderRadius: '8px',
            marginBottom: '16px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          }}
        >
          {chartTitle} :
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              fontSize: '18px',
            }}
          >
            <div
              style={{
                width: '30px',
                height: '4px',
                backgroundColor: primaryLineColor,
                marginRight: '8px',
              }}
            />
            <span>Open Interest</span>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              fontSize: '18px',
            }}
          >
            <div
              style={{
                width: '30px',
                height: '4px',
                backgroundColor: secondaryLineColor,
                marginRight: '8px',
              }}
            />
            <span>ราคา Futures</span>
          </div>
        </div>
      </h2>
      <div ref={chartContainerRef} style={{ height: '100%', width: '100%', position: 'relative' }} />
    </div>
  );
};

const syncCharts = (chart1, chart2) => {
  if (!chart1 || !chart2) return;

  let isSyncing = false;

  chart1.timeScale().subscribeVisibleTimeRangeChange((range) => {
    if (!range || isSyncing) return;

    isSyncing = true;
    chart2.timeScale().setVisibleRange(range);
    isSyncing = false;
  });

  chart2.timeScale().subscribeVisibleTimeRangeChange((range) => {
    if (!range || isSyncing) return;

    isSyncing = true;
    chart1.timeScale().setVisibleRange(range);
    isSyncing = false;
  });
};

const App = () => {
  const [selectedYear, setSelectedYear] = useState(2020);
  const [selectedSeries, setSelectedSeries] = useState(Object.keys(symbolData[2020])[0]);

  
  const chart1Ref = useRef(null); // สร้าง chart1Ref
  const chart2Ref = useRef(null); // สร้าง chart2Ref
  
  const handleYearChange = (year) => {
    setSelectedYear(year);
    setSelectedSeries(Object.keys(symbolData[year])[0]);
  };

  const handleSeriesChange = (series) => {
    setSelectedSeries(series);
  };

  return (
    <div style={{ width: "90%", margin: "auto" }}>
      {/* <h1 style={{ textAlign: "center" }}>Options</h1> */}
      <div style={{
        display: 'flex',
        marginTop: '10px',
        padding: '15px',
        backgroundColor: '#f8f9fa',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
      }}>
        <div>
          <label htmlFor="yearSelect">เลือกปี: </label>
          <select
            id="yearSelect"
            value={selectedYear}
            onChange={(e) => handleYearChange(Number(e.target.value))}
            style={{ padding: '5px', borderRadius: '5px' }}
          >
            {Object.keys(symbolData).map((year) => (
              <option key={year} value={year}>{year}</option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="seriesSelect">เลือก Series: </label>
          <select
            id="seriesSelect"
            value={selectedSeries}
            onChange={(e) => handleSeriesChange(e.target.value)}
            style={{ padding: '5px', borderRadius: '5px' }}
          >
            {Object.keys(symbolData[selectedYear] || {}).map((series) => (
              <option key={series} value={series}>{series}</option>
            ))}
          </select>
        </div>
      </div>

      <DualAxisChart
        data={symbolData[selectedYear][selectedSeries].volumeData}
        chartTitle="Volume"
        primaryLineColor="green"
        secondaryLineColor="red"
        onChartReady={(chart) => {
          chart1Ref.current = chart;
          if (chart1Ref.current && chart2Ref.current) {
            syncCharts(chart1Ref.current, chart2Ref.current);
          }
        }}
      />

      <DualAxisChart1
        data={symbolData[selectedYear][selectedSeries].openInterestData}
        chartTitle="OI"
        primaryLineColor="blue"
        secondaryLineColor="red"
        onChartReady={(chart) => {
          chart2Ref.current = chart;
          if (chart1Ref.current && chart2Ref.current) {
            syncCharts(chart1Ref.current, chart2Ref.current);
          }
        }}
      />
    </div>
  );
};

export default App;
