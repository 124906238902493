// FullscreenPageRealflow.js

import React, { useEffect, useRef, useState } from 'react';
import { createChart, LineStyle } from 'lightweight-charts';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
// import axios from 'axios';

// const API_BASE_URL = "http://172.18.1.81:3101/flow";

const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const earthToneColor = '#D2B48C';
const getOppositeColor = () => '#0d0082';

const getTodayDateString = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const adjustToLocalTime = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const timezoneOffset = date.getTimezoneOffset() * 60;
  return timestamp - timezoneOffset;
};

const FullscreenPageRealflow = () => {
  const location = useLocation();
  const navigate = useNavigate();
  
  const params = new URLSearchParams(location.search);
  const market = params.get('market');
  const type = params.get('type');
  const title = params.get('title');

  const fullScreenChartContainerRef = useRef(null);
  const fullScreenChartRef = useRef(null);

  const lineSeriesRef = useRef(new Map());
  const seriesDataRef = useRef(new Map());
  const originalColorsRef = useRef(new Map());

  const [tableData, setTableData] = useState([]);
  const tableDataRef = useRef([]);
  const [previousRanks, setPreviousRanks] = useState({});
  const previousRanksRef = useRef({});
  const [lastRankChange, setLastRankChange] = useState({});
  const lastRankChangeRef = useRef({});
  const [selectedModalSymbol, setSelectedModalSymbol] = useState(null);

  const csrfToken = useSelector((state) => state.csrfToken);

  const shouldInvert = (type === "out");

  useEffect(() => {
    previousRanksRef.current = previousRanks;
  }, [previousRanks]);

  useEffect(() => {
    lastRankChangeRef.current = lastRankChange;
  }, [lastRankChange]);

  useEffect(() => {
    tableDataRef.current = tableData;
  }, [tableData]);

  const fetchHistoricalData = async () => {
    try {
      const fetch_response = await fetch(`${window.config.base_api_url}/flow/today/${market}`, {
        headers: {
          'Content-Type': 'application/json',
          'xsrf-token': csrfToken
        },
      });
      const response = await fetch_response.json();
      console.log(response);

      const data = response.data[type]; // 'in' or 'out'
      const historicalData = {};

      const times = Object.keys(data.rank);
      times.sort();
      const latestTime = times[times.length - 1];
      const latestSymbols = Object.keys(data.rank[latestTime]);

      // Build historicalData
      times.forEach((time) => {
        const timestamp = Math.floor(new Date(time).getTime() / 1000);

        Object.keys(data.rank[time]).forEach((symbol) => {
          if (!historicalData[symbol]) {
            historicalData[symbol] = [];
          }
          const rawValue = data.value[time][symbol];
          const finalValue = shouldInvert ? -1 * rawValue : rawValue;

          historicalData[symbol].push({
            time: adjustToLocalTime(timestamp),     // in seconds
            rank: data.rank[time][symbol],
            value: finalValue,
          });
        });
      });

      const newPreviousRanks = {};

      // Track rank changes to find the most recent one
      let lastChangeTime = -Infinity;
      const rankChanges = []; // {symbol, time, oldRank, newRank}

      latestSymbols.forEach((symbol) => {
        const symbolData = historicalData[symbol];
        if (!symbolData || symbolData.length === 0) {
          newPreviousRanks[symbol] = null;
          return;
        }

        symbolData.sort((a, b) => a.time - b.time);
        const latestRank = symbolData[symbolData.length - 1].rank;
        newPreviousRanks[symbol] = latestRank;

        // Find rank changes for this symbol
        for (let i = 1; i < symbolData.length; i++) {
          if (symbolData[i].rank !== symbolData[i - 1].rank) {
            const changeTime = symbolData[i].time;
            rankChanges.push({
              symbol: symbol,
              time: changeTime,
              oldRank: symbolData[i - 1].rank,
              newRank: symbolData[i].rank
            });
            if (changeTime > lastChangeTime) {
              lastChangeTime = changeTime;
            }
          }
        }

        // Create/update series
        let series = lineSeriesRef.current.get(symbol);
        if (!series) {
          const color = getRandomColor();
          series = fullScreenChartRef.current.addLineSeries({
            color: color,
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: false,
            title: symbol,
          });
          lineSeriesRef.current.set(symbol, series);
          originalColorsRef.current.set(symbol, color);
        }

        const seriesData = symbolData.map((dp) => ({
          time: dp.time,
          value: dp.value,
        }));
        series.setData(seriesData);
        seriesDataRef.current.set(symbol, seriesData);
      });

      setPreviousRanks(newPreviousRanks);
      previousRanksRef.current = newPreviousRanks;

      // Remove symbols not in latestSymbols
      const allSymbols = Array.from(lineSeriesRef.current.keys());
      const symbolsToRemove = allSymbols.filter(sym => !latestSymbols.includes(sym));
      symbolsToRemove.forEach((symbol) => {
        const series = lineSeriesRef.current.get(symbol);
        if (series) {
          fullScreenChartRef.current.removeSeries(series);
        }
        lineSeriesRef.current.delete(symbol);
        seriesDataRef.current.delete(symbol);
        originalColorsRef.current.delete(symbol);
      });

      if (selectedModalSymbol && !latestSymbols.includes(selectedModalSymbol)) {
        setSelectedModalSymbol(null);
      }

      // Determine colors based on lastChangeTime
      const lastChanges = rankChanges.filter(rc => rc.time === lastChangeTime);
      const symbolColors = new Map();
      if (lastChangeTime !== -Infinity && lastChanges.length > 0) {
        lastChanges.forEach(change => {
          if (change.newRank < change.oldRank) {
            symbolColors.set(change.symbol, '#ccffcc');
          } else if (change.newRank > change.oldRank) {
            symbolColors.set(change.symbol, '#ffcccc');
          } else {
            symbolColors.set(change.symbol, 'transparent');
          }
        });
      }

      // Update table data
      const updatedTableData = latestSymbols.map((symbol) => {
        const symbolData = historicalData[symbol];
        const latestData = symbolData[symbolData.length - 1];
        const color = symbolColors.get(symbol) || 'transparent';

        return {
          rank: latestData.rank,
          symbol,
          value: latestData.value,
          color,
        };
      });

      setTableData(updatedTableData);
      tableDataRef.current = updatedTableData;

    } catch (error) {
      console.error(`Error fetching historical data for ${market}:`, error);
    }
  };

  const fetchCurrentData = async () => {
    try {
      if (Object.keys(previousRanksRef.current).length === 0) {
        return;
      }
      const fetch_response = await fetch(`${window.config.base_api_url}/flow/now/${market}`, {
        headers: {
          'Content-Type': 'application/json',
          'xsrf-token': csrfToken
        },
      });
      const response = await fetch_response.json();
      const data = response.data[type]; // "in" or "out"
      const timeKey = Object.keys(data.rank)[0];
      const timestamp = Math.floor(new Date(timeKey).getTime() / 1000);

      const latestSymbols = Object.keys(data.rank[timeKey]);

      // Compare ranks for changes
      const newPreviousRanks = {};
      latestSymbols.forEach((symbol) => {
        const currentRank = data.rank[timeKey][symbol];
        const oldRank = previousRanksRef.current[symbol];

        // If oldRank is undefined => NEW symbol => treat as green
        if (oldRank === undefined) {
          highlightColorsRef.current[symbol] = '#ccffcc'; // new symbol => green
        } else if (currentRank < oldRank) {
          highlightColorsRef.current[symbol] = '#ccffcc'; // green
        } else if (currentRank > oldRank) {
          highlightColorsRef.current[symbol] = '#ffcccc'; // red
        }
        // if currentRank === oldRank => do NOTHING
        // that preserves any previous color

        newPreviousRanks[symbol] = currentRank;
      });

      // Store table
      const updatedTableData = latestSymbols.map((symbol) => {
        const symbolData = seriesDataRef.current.get(symbol) || [];
        const latestData = symbolData[symbolData.length - 1];
        const color = highlightColorsRef.current[symbol] || 'transparent';

        return {
          rank: newPreviousRanks[symbol],
          symbol,
          value: latestData.value,
          color,
        };
      });

      setTableData(updatedTableData);
      tableDataRef.current = updatedTableData;

      // Update previousRanks
      setPreviousRanks(newPreviousRanks);
      previousRanksRef.current = newPreviousRanks;

      // Remove missing symbols
      const allSymbols = Array.from(lineSeriesRef.current.keys());
      const symbolsToRemove = allSymbols.filter(sym => !latestSymbols.includes(sym));
      symbolsToRemove.forEach((symbol) => {
        const series = lineSeriesRef.current.get(symbol);
        if (series) {
          fullScreenChartRef.current.removeSeries(series);
        }
        lineSeriesRef.current.delete(symbol);
        seriesDataRef.current.delete(symbol);
        originalColorsRef.current.delete(symbol);

        // Also remove from highlightColorsRef if symbol is truly gone
        delete highlightColorsRef.current[symbol];
      });

      if (selectedModalSymbol && !latestSymbols.includes(selectedModalSymbol)) {
        setSelectedModalSymbol(null);
      }

      // Update the chart series with new data points
      latestSymbols.forEach((symbol) => {
        const updatePoint = {
          time: adjustToLocalTime(timestamp),
          value: (shouldInvert ? -1 : 1) * data.value[timeKey][symbol],
        };

        let series = lineSeriesRef.current.get(symbol);
        if (series) {
          const existingData = seriesDataRef.current.get(symbol) || [];

          if (
            existingData.length > 0 &&
            existingData[existingData.length - 1].time === updatePoint.time
          ) {
            existingData[existingData.length - 1] = updatePoint;
          } else {
            existingData.push(updatePoint);
          }

          series.setData(existingData);
          seriesDataRef.current.set(symbol, existingData);
        } else {
          // new symbol => create a line series
          const color = getRandomColor();
          series = fullScreenChartRef.current.addLineSeries({
            color,
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: false,
            title: symbol,
          });
          series.setData([updatePoint]);
          lineSeriesRef.current.set(symbol, series);
          originalColorsRef.current.set(symbol, color);
          seriesDataRef.current.set(symbol, [updatePoint]);
        }
      });

      // Highlight changes
      const symbolColors = new Map();
      if (Object.keys(highlightColorsRef.current).length > 0) {
        latestSymbols.forEach((symbol) => {
          const color = highlightColorsRef.current[symbol];
          if (color) {
            symbolColors.set(symbol, color);
          }
        });
      }

      // Update table data
      const finalTableData = latestSymbols.map((symbol) => {
        const dataArr = seriesDataRef.current.get(symbol) || [];
        const latestData = dataArr[dataArr.length - 1];
        const color = symbolColors.get(symbol) || 'transparent';

        return {
          rank: newPreviousRanks[symbol],
          symbol,
          value: latestData.value,
          color,
        };
      });

      setTableData(finalTableData);
      tableDataRef.current = finalTableData;

    } catch (error) {
      console.error(`Error fetching current data for ${market}:`, error);
    }
  };

  useEffect(() => {
    if (fullScreenChartContainerRef.current) {
      fullScreenChartRef.current = createChart(fullScreenChartContainerRef.current, {
        layout: { textColor: 'black', background: { type: 'solid', color: 'white' } },
        timeScale: { timeVisible: true , rightOffset: 10},
        height: fullScreenChartContainerRef.current.offsetHeight,
        width: fullScreenChartContainerRef.current.offsetWidth,
        crosshair: {
          horizontalLine: { visible: false },
          verticalLine: { visible: true },
        },
        localization: {
          priceFormatter: (price) =>
            (price / 1_000_000).toLocaleString('en-US', { maximumFractionDigits: 2 }),
        },
        priceScale: {
          mode: 0,
        },
      });

      // Apply custom price scale options
      fullScreenChartRef.current.priceScale('right').applyOptions({
        textColor: 'white', // Set the price scale text color to white
      });
      
      // If we want to invert the scale for type === "out"
      if (shouldInvert) {
        fullScreenChartRef.current.applyOptions({
          invertScale: true,
        });
      }

      // Apply localization and price scale options
      fullScreenChartRef.current.applyOptions({
        localization: {
          priceFormatter: (price) =>
            (price / 1_000_000).toLocaleString('en-US', { maximumFractionDigits: 2 }),
        },
        priceScale: {
          mode: 0,
        },
      });

      const initializeData = async () => {
        await fetchHistoricalData();
        await fetchCurrentData();
      };

      initializeData();

      const intervalID = setInterval(() => {
        fetchCurrentData();
      }, 10000);

      return () => {
        clearInterval(intervalID);
        if (fullScreenChartRef.current) {
          fullScreenChartRef.current.remove();
          fullScreenChartRef.current = null;
        }
      };
    }
  }, [market, type]);

  const handleStartButtonClick = () => {
    if (!fullScreenChartRef.current) return;

    const timeScale = fullScreenChartRef.current.timeScale();
    let earliestTime = Infinity;
    let latestTime = -Infinity;

    seriesDataRef.current.forEach((data) => {
      if (data.length > 0) {
        earliestTime = Math.min(earliestTime, data[0].time);
        latestTime = Math.max(latestTime, data[data.length - 1].time);
      }
    });

    if (earliestTime !== Infinity && latestTime !== -Infinity) {
      const visibleRange = timeScale.getVisibleRange();
      const rangeDuration = visibleRange.to - visibleRange.from;
      timeScale.setVisibleRange({
        from: earliestTime,
        to: earliestTime + rangeDuration,
      });
    }
  };

  const handleHalfDayButtonClick = () => {
    if (!fullScreenChartRef.current) return;

    const timeScale = fullScreenChartRef.current.timeScale();
    let earliestTime = Infinity;
    let latestTime = -Infinity;

    seriesDataRef.current.forEach((data) => {
      if (data.length > 0) {
        earliestTime = Math.min(earliestTime, data[0].time);
        latestTime = Math.max(latestTime, data[data.length - 1].time);
      }
    });

    if (earliestTime !== Infinity && latestTime !== -Infinity) {
      const middleTime = (earliestTime + latestTime) / 2;
      const visibleRange = timeScale.getVisibleRange();
      const rangeDuration = visibleRange.to - visibleRange.from;

      timeScale.setVisibleRange({
        from: middleTime - rangeDuration / 2,
        to: middleTime + rangeDuration / 2,
      });
    }
  };

  const handleEndDayButtonClick = () => {
    if (!fullScreenChartRef.current) return;

    const timeScale = fullScreenChartRef.current.timeScale();
    let latestTime = -Infinity;

    seriesDataRef.current.forEach((data) => {
      if (data.length > 0) {
        latestTime = Math.max(latestTime, data[data.length - 1].time);
      }
    });

    if (latestTime !== -Infinity) {
      const visibleRange = timeScale.getVisibleRange();
      const rangeDuration = visibleRange.to - visibleRange.from;
      timeScale.setVisibleRange({
        from: latestTime - rangeDuration,
        to: latestTime,
      });
    }
  };

  const handleShowAllButtonClick = () => {
    if (!fullScreenChartRef.current) return;

    const timeScale = fullScreenChartRef.current.timeScale();
    let earliestTime = Infinity;
    let latestTime = -Infinity;

    seriesDataRef.current.forEach((data) => {
      if (data.length > 0) {
        earliestTime = Math.min(earliestTime, data[0].time);
        latestTime = Math.max(latestTime, data[data.length - 1].time);
      }
    });

    if (earliestTime !== Infinity && latestTime !== -Infinity) {
      timeScale.setVisibleRange({
        from: earliestTime,
        to: latestTime,
      });
    }
  };

  const handleRowClickModal = (symbol) => {
    if (selectedModalSymbol === symbol) {
      // Deselect
      setSelectedModalSymbol(null);
      // Reset all series to original colors and styles
      lineSeriesRef.current.forEach((series, sym) => {
        const originalColor = originalColorsRef.current.get(sym);
        series.applyOptions({
          color: originalColor,
          lineWidth: 2,
        });
      });
    } else {
      // Highlight the clicked series
      setSelectedModalSymbol(symbol);
      const selectedSeries = lineSeriesRef.current.get(symbol);
      const data = seriesDataRef.current.get(symbol);

      if (selectedSeries && fullScreenChartRef.current) {
        // Remove and re-add to bring on top
        fullScreenChartRef.current.removeSeries(selectedSeries);
        const newSeries = fullScreenChartRef.current.addLineSeries({
          color: selectedSeries.options().color,
          lineWidth: selectedSeries.options().lineWidth,
          lastValueVisible: true,
          priceLineVisible: false,
          title: symbol,
        });
        newSeries.setData(data);
        lineSeriesRef.current.set(symbol, newSeries);

        const originalColor = originalColorsRef.current.get(symbol);
        const oppositeColor = getOppositeColor();
        newSeries.applyOptions({
          color: oppositeColor,
          lineWidth: 4,
        });

        // Fade other series
        lineSeriesRef.current.forEach((s, sym) => {
          if (sym !== symbol) {
            s.applyOptions({
              color: earthToneColor,
              lineWidth: 2,
            });
          }
        });
      }
    }
  };

  const toggleSeriesVisibility = (symbol) => {
    const series = lineSeriesRef.current.get(symbol);
    if (series) {
      const currentVisible = series.options().visible ?? true;
      series.applyOptions({ visible: !currentVisible });
    }
  };

  const renderTable = (startIndex, endIndex, onRowClick, selectedSym) => (
    <table className="rank-table" style={{ fontSize: '11.5px', fontFamily: 'Arial, sans-serif' }}>
      <thead>
        <tr>
          <th style={{ textAlign: 'left' }}>Rank</th>
          <th style={{ textAlign: 'left' }}>Symbol</th>
          <th style={{ textAlign: 'left' }}>Value</th>
        </tr>
      </thead>
      <tbody>
        {tableData
          .sort((a, b) => a.rank - b.rank)
          .slice(startIndex, endIndex)
          .map((row, index) => (
            <tr
              key={index}
              style={{
                backgroundColor: row.color,
                cursor: 'pointer',
                fontWeight: row.symbol === selectedSym ? 'bold' : 'normal',
              }}
              onClick={() => onRowClick(row.symbol)}
            >
              <td>{row.rank}</td>
              <td>{row.symbol}</td>
              <td>{(row.value / 1_000_000).toLocaleString('en-US', { maximumFractionDigits: 2 })}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );

  return (
    <div style={{ fontFamily: 'Arial, sans-serif', padding:'20px' }}>
      <h3>{title}</h3>
      <div style={{
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between',
        marginBottom:'10px'
      }}>
        <div style={{display:'flex', gap:'10px'}}>
          <button onClick={handleStartButtonClick} className="nav-button">
            Start
          </button>
          <button onClick={handleHalfDayButtonClick} className="nav-button">
            Half-Day
          </button>
          <button onClick={handleEndDayButtonClick} className="nav-button">
            End-Day
          </button>
          <button onClick={handleShowAllButtonClick} className="nav-button">
            Show All
          </button>
        </div>
      </div>
      <div style={{display:'flex', flexDirection:'row', gap:'20px'}}>
        <div
          ref={fullScreenChartContainerRef}
          className="chart-container"
          style={{
            width: '80%',
            height: '80vh',
            position: 'relative',
            fontFamily: 'Arial, sans-serif',
          }}
        ></div>
        <div
          className="table-container"
          style={{
            width: '20%',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            overflowY: 'auto',
            maxHeight: '80vh',
            fontFamily: 'Arial, sans-serif',
          }}
        >
          <table className="rank-table" style={{ fontSize:'11.5px', fontFamily:'Arial, sans-serif' }}>
            <thead>
              <tr>
                <th style={{ textAlign:'left' }}>Rank</th>
                <th style={{ textAlign:'left' }}>Symbol</th>
                <th style={{ textAlign:'left' }}>Value</th>
              </tr>
            </thead>
            <tbody>
              {tableData
                .sort((a, b) => a.rank - b.rank)
                .map((row, index) => (
                  <tr
                    key={index}
                    style={{
                      backgroundColor: row.color,
                      cursor: 'pointer',
                      fontWeight: row.symbol === selectedModalSymbol ? 'bold' : 'normal',
                    }}
                    onClick={() => handleRowClickModal(row.symbol)}
                  >
                    <td>{row.rank}</td>
                    <td>{row.symbol}</td>
                    <td>{(row.value/1_000_000).toLocaleString('en-US',{maximumFractionDigits:2})}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default FullscreenPageRealflow;
