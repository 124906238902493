import React, { useEffect, useRef, useState } from 'react';
import { createChart } from 'lightweight-charts';
// import './index.css'; // <-- Remove this import
// import axios from 'axios'; // <-- Remove axios import
import { useSelector } from 'react-redux'; // <-- If you need the token from Redux

// Color utilities
function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
const earthToneColor = '#D2B48C';
const getOppositeColor = () => '#0d0082';

// Convert "YYYY-MM-DD" => numeric timestamp
function dateToTimestamp(dateStr) {
  return Math.floor(new Date(dateStr + 'T00:00:00').getTime() / 1000);
}

export default function YTDPage() {
  const chartContainerRef = useRef(null);
  const chartRef = useRef(null);

  // Data from /api/ytd (historical) and /api/ytd/realtime (real-time)
  const [histData, setHistData] = useState([]);
  const [rtData, setRtData] = useState([]);
  const [combinedData, setCombinedData] = useState([]);

  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const [tableData, setTableData] = useState([]);

  // For the chart lines
  const lineSeriesRef = useRef(new Map());
  const originalColorsRef = useRef(new Map());
  const seriesDataRef = useRef(new Map());

  // If you have a Redux store holding a csrf token, grab it:
  const csrfToken = useSelector((state) => state.csrfToken);

  // ---------------------------------------------------------
  // A) Create chart on mount
  // ---------------------------------------------------------
  useEffect(() => {
    const chart = createChart(chartContainerRef.current, {
      layout: { background: { type: 'solid', color: 'white' } },
      timeScale: {
        timeVisible: true,
        rightOffset: 2,
      },
      height: 600,
      crosshair: {
        horizontalLine: { visible: false },
        verticalLine: { visible: true },
      },
      localization: {
        priceFormatter: (val) => (val * 100).toFixed(2) + '%',
      },
      priceScale: {
        mode: 0,
      },
    });
    chartRef.current = chart;

    return () => {
      if (chartRef.current) {
        chartRef.current.remove();
      }
    };
  }, []);

  // ---------------------------------------------------------
  // B) Fetch historical data (using fetch + xsrf-token)
  // ---------------------------------------------------------
  useEffect(() => {
    async function fetchHistorical() {
      try {
        const res = await fetch(`${window.config.base_api_url}/api/ytd`, {
          headers: {
            'Content-Type': 'application/json',
            'xsrf-token': csrfToken,
          },
        });
        if (!res.ok) {
          throw new Error(`Failed to fetch /api/ytd, status: ${res.status}`);
        }
        const data = await res.json();
        setHistData(data);
      } catch (err) {
        console.error('Failed to fetch /api/ytd:', err);
      }
    }
    fetchHistorical();
  }, [csrfToken]);

  // ---------------------------------------------------------
  // C) Poll real-time data (using fetch + xsrf-token)
  // ---------------------------------------------------------
  useEffect(() => {
    let isMounted = true;

    async function fetchRealtime() {
      try {
        const res = await fetch(`${window.config.base_api_url}/api/ytd/realtime`, {
          headers: {
            'Content-Type': 'application/json',
            'xsrf-token': csrfToken,
          },
        });
        if (!res.ok) {
          throw new Error(`Failed to fetch /api/ytd/realtime, status: ${res.status}`);
        }
        const data = await res.json();
        if (isMounted) {
          setRtData(data);
        }
      } catch (err) {
        console.error('Failed to fetch /api/ytd/realtime:', err);
      }
    }

    fetchRealtime();
    const intervalId = setInterval(() => {
      fetchRealtime();
    }, 60_000); // poll every 1 minute

    return () => {
      isMounted = false;
      clearInterval(intervalId);
    };
  }, [csrfToken]);

  // ---------------------------------------------------------
  // D) Combine historical + real-time
  // ---------------------------------------------------------
  useEffect(() => {
    if (!histData.length && !rtData.length) return;

    // Merge them
    const merged = [...histData];
    for (const row of rtData) {
      merged.push({
        date: row.date,
        symbol: row.symbol,
        percentChange: row.percentChange,
        cumulative: row.cumulative || null,
      });
    }
    setCombinedData(merged);
  }, [histData, rtData]);

  // ---------------------------------------------------------
  // E) Once combinedData changes => build chart
  // ---------------------------------------------------------
  useEffect(() => {
    if (!combinedData.length || !chartRef.current) return;

    // 1) Group by date => date => array of {symbol, percentChange, cumulative}
    const byDate = {};
    for (const row of combinedData) {
      const { date, symbol, percentChange, cumulative } = row;
      if (!byDate[date]) {
        byDate[date] = [];
      }
      byDate[date].push({ symbol, percentChange, cumulative });
    }

    // 2) find the newest date
    const allDates = Object.keys(byDate).sort();
    if (!allDates.length) return;
    const latestDate = allDates[allDates.length - 1];

    // 3) from that date, pick only symbols that meet threshold or are real-time
    let latestArr = byDate[latestDate];
    latestArr = latestArr.filter((item) => {
      // if item.cumulative == null => probably real-time => keep
      // else => keep only if cumulative >= 1,000,000
      if (item.cumulative == null) return true;
      return item.cumulative >= 1_000_000;
    });

    // 4) sort by % desc, pick top 50
    const sorted = [...latestArr].sort((a, b) => b.percentChange - a.percentChange);
    const topSymbols = sorted.slice(0, 50).map(x => x.symbol);

    // 5) table data => rank + symbol + %change
    const finalTable = sorted.slice(0, 50).map((item, idx) => ({
      rank: idx + 1,
      symbol: item.symbol,
      percentChange: item.percentChange,
    }));
    setTableData(finalTable);

    // 6) Build lines: symbol -> array of { time, value }
    const symbolSeriesData = new Map();
    for (const d of allDates) {
      const dayRows = byDate[d];
      // Make a quick symbol => row map for that date
      const dayMap = {};
      for (const row of dayRows) {
        dayMap[row.symbol] = row;
      }
      for (const sym of topSymbols) {
        if (dayMap[sym]) {
          if (!symbolSeriesData.has(sym)) {
            symbolSeriesData.set(sym, []);
          }
          symbolSeriesData.get(sym).push({
            time: dateToTimestamp(d),
            value: dayMap[sym].percentChange,
          });
        }
      }
    }

    // 7) Clear old lines
    lineSeriesRef.current.forEach((series) => {
      chartRef.current.removeSeries(series);
    });
    lineSeriesRef.current.clear();
    originalColorsRef.current.clear();
    seriesDataRef.current.clear();

    // 8) Add new lines for top 50
    topSymbols.forEach((sym) => {
      const dataArr = symbolSeriesData.get(sym) || [];
      if (!dataArr.length) return;

      const color = getRandomColor();
      const series = chartRef.current.addLineSeries({
        color,
        lineWidth: 2,
        lastValueVisible: true,
        priceLineVisible: false,
        title: sym,
      });
      series.setData(dataArr);

      lineSeriesRef.current.set(sym, series);
      originalColorsRef.current.set(sym, color);
      seriesDataRef.current.set(sym, dataArr);
    });

    // 9) fit content
    chartRef.current.timeScale().fitContent();
  }, [combinedData]);

  // ---------------------------------------------------------
  // F) highlight logic on row click
  // ---------------------------------------------------------
  const handleRowClick = (symbol) => {
    if (!symbol || !chartRef.current) return;

    if (selectedSymbol === symbol) {
      // unselect
      setSelectedSymbol(null);
      lineSeriesRef.current.forEach((series, s2) => {
        const origColor = originalColorsRef.current.get(s2);
        series.applyOptions({ color: origColor, lineWidth: 2 });
      });
    } else {
      setSelectedSymbol(symbol);
      // fade others
      lineSeriesRef.current.forEach((series) => {
        series.applyOptions({ color: earthToneColor, lineWidth: 2 });
      });
      // remove & re-add highlighted line
      const target = lineSeriesRef.current.get(symbol);
      if (target) {
        chartRef.current.removeSeries(target);
        lineSeriesRef.current.delete(symbol);

        const dataArr = seriesDataRef.current.get(symbol) || [];
        const highlightSeries = chartRef.current.addLineSeries({
          color: getOppositeColor(),
          lineWidth: 4,
          lastValueVisible: true,
          priceLineVisible: false,
          title: symbol,
        });
        highlightSeries.setData(dataArr);
        lineSeriesRef.current.set(symbol, highlightSeries);
      }
    }
  };

  // ---------------------------------------------------------
  // G) Render table => 50 rows, with rank and a scroll
  // ---------------------------------------------------------
  function renderTable() {
    return (
      <div
        style={{
          height: '600px',
          overflowY: 'auto',
          border: '1px solid #ccc',
        }}
      >
        <table
          style={{
            width: '100%',
            borderCollapse: 'collapse',
            fontSize: '15px',
          }}
        >
          <thead style={{ position: 'sticky', top: 0, background: '#f5f5f5' }}>
            <tr>
              <th style={{ textAlign: 'left', padding: '4px' }}>Rank</th>
              <th style={{ textAlign: 'left', padding: '4px' }}>Symbol</th>
              <th style={{ textAlign: 'right', padding: '4px' }}>% Change</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, idx) => {
              const isSelected = row.symbol === selectedSymbol;
              return (
                <tr
                  key={idx}
                  style={{
                    backgroundColor: isSelected ? '#ffe0cc' : 'transparent',
                    cursor: 'pointer',
                    fontWeight: isSelected ? 'bold' : 'normal',
                  }}
                  onClick={() => handleRowClick(row.symbol)}
                >
                  <td style={{ padding: '4px' }}>{row.rank}</td>
                  <td style={{ padding: '4px' }}>{row.symbol}</td>
                  <td style={{ padding: '4px', textAlign: 'right' }}>
                    {(row.percentChange * 100).toFixed(2)}%
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  // ---------------------------------------------------------
  // H) Render
  // ---------------------------------------------------------
  return (
    <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px' }}>
      <h1>YTD Top 50</h1>
      <div
        style={{
          display: 'flex',
          gap: '20px',
          width: '100%',
        }}
      >
        {/* Chart container */}
        <div
          ref={chartContainerRef}
          style={{
            flex: '1 1 0px',
            minWidth: '0',
            border: '1px solid #ccc',
          }}
        />

        {/* Table side */}
        <div style={{ width: '260px' }}>
          
          {renderTable()}
        </div>
      </div>
    </div>
  );
}
