import { useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';

const useIsMobile = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return isMobile;
};

const useIsTablet = () => {
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.between('sm','lg'));
    return isTablet;
};

const useIsPortraitTablet = () => {
    const theme = useTheme();
    const isPortraitTablet = useMediaQuery(theme.breakpoints.between('sm','900'))
    return isPortraitTablet;
}

export { useIsMobile, useIsTablet, useIsPortraitTablet };