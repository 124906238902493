import React from 'react';
import { Box, Button } from '@mui/material';
import { useLocation } from 'react-router-dom'; 

function Navbar() {
  const location = useLocation();

  // ฟังก์ชันเช็คว่าเส้นทางตรงกับลิงค์หรือไม่
  const isActive = (path) => location.pathname === path;

  return (
    <Box
      sx={{
        marginTop: '16px',
        display: 'flex',
        justifyContent: 'center', 
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '6px', 
          borderRadius: '6px', 
        }}
      >
        <Button
          href="/allnews"
          sx={{
            color: '#272727',
            backgroundColor: isActive('/allnews') ? '#a1a1a1' : '#ffffff', // ถ้าเส้นทางตรงกับลิงค์จะมีพื้นหลังสีเทา
            color: isActive('/allnews') ? '#f0f0f0' : '#272727',
            padding: '10px',
            borderRadius: '6px',
            boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.1)', 
            '&:hover': {
              backgroundColor: '#a1a1a1',
              color: '#f0f0f0',
            },
          }}
        >
          News
        </Button>

        <Button
          href="/acceptnews"
          sx={{
            color: '#272727',
            backgroundColor: isActive('/acceptnews') ? '#a1a1a1' : '#ffffff',
            color: isActive('/acceptnews') ? '#f0f0f0' : '#272727',
            padding: '10px',
            borderRadius: '6px',
            boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.1)',
            '&:hover': {
              backgroundColor: '#a1a1a1',
              color: '#f0f0f0',
            },
          }}
        >
          Accept News
        </Button>

        <Button
          href="/declinenews"
          sx={{
            color: '#272727',
            backgroundColor: isActive('/declinenews') ? '#a1a1a1' : '#ffffff',
            color: isActive('/declinenews') ? '#f0f0f0' : '#272727',
            padding: '10px',
            borderRadius: '6px',
            boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.1)',
            '&:hover': {
              backgroundColor: '#a1a1a1',
              color: '#f0f0f0',
            },
          }}
        >
          Decline News
        </Button>
      </Box>
    </Box>
  );
}

export default Navbar;
