import React, { useEffect, useRef, useState } from "react";
import { createChart } from "lightweight-charts";
import { useLocation } from "react-router-dom";
import { Box, Button, Container, Paper, Grid } from "@mui/material";


import Header from "../../components/Header";
import LightweightChart from "../../components/LightweightChart";

const FullChart = () => {
    const chartContainerRef = useRef(null);
    const location = useLocation();

    // ดึงข้อมูล Base64 จาก query parameters
    const queryParams = new URLSearchParams(location.search);
    const base64Data = queryParams.get("data");

    // แปลงจาก Base64 กลับเป็น JSON
    const allData = base64Data ? JSON.parse(atob(base64Data)) : [];


    const [chartData, setChartData] = useState([]);
    const [chartTitle, setChartTitle] = useState([]);

    useEffect(() => {

        const storedData = localStorage.getItem("chartData");

        // console.log(storedData)
        if (storedData) {
            const allData = JSON.parse(storedData);

            const title = localStorage.getItem("chartTitle");
            setChartData(allData);
            setChartTitle(title);
            // const chart = createChart(chartContainerRef.current, {
            //     width: window.innerWidth,
            //     height: window.innerHeight,
            //     layout: {
            //         background: { color: "#FFFFFF" },
            //         textColor: "#333",
            //     },
            // });

            // const lineSeries = chart.addLineSeries();
            // lineSeries.setData(allData);

            // return () => {
            //     chart.remove(); // ลบกราฟเมื่อ component ถูกถอดออก
            // };
        }

    }, []);

    const commonOptions = {
        // width: 500,
        // height: 200,
        crosshair: {
            mode: 0
        },
        timeScale: {
            visible: true,
        },
        localization: {
            locale: "en-US"
        },
        layout: {
            background: {
                type: 'solid',
                color: '#f5f5f5',
            },
        },
        // rightPriceScale: {
        //     visible: false,
        //     scaleMargins: {
        //         top: 0.3,
        //         bottom: 0.2,
        //     },
        //     borderVisible: true,
        //     drawTicks: false,
        // },

        // lastValueVisible: false,
        // priceFormat: {
        //     type: 'price',
        // },

        rightPriceScale: {
            visible: false,
        },
        leftPriceScale: {
            visible: false,
            // mode: PriceScaleMode.Normal,
            // autoScale: false,  // Disable automatic scaling
            // invertScale: false,
            // alignLabels: false,
            // minValue: -1,      // Set the minimum value
            // maxValue: 1,       // Set the maximum value
        },
    };


    const chartRefs = useRef([]);
    const chartContainerRefs = useRef([]);


    const content = document.getElementsByClassName('content')[0];
    const resizeHandler = () => {
        chartRefs.current.forEach((chartRef, index) => {
            if (chartRef) {
                const chart = chartRef.getChartRef();
                try {
                    const container = chartContainerRefs.current[index]
                    if (container) {
                        chart.resize(container.clientWidth, container.clientHeight);
                    }
                } catch (error) {
                    console.error('Error resizing chart:', error);
                }
            }
        });
    };

    useEffect(() => {
        if (content) {
            const resizeObserver = new ResizeObserver(resizeHandler);
            resizeObserver.observe(content);
            return () => {
                if (resizeObserver) {
                    resizeObserver.disconnect();
                }
            };
        }
    }, [content]);

    useEffect(() => {
        chartRefs.current.forEach((chartRef, index) => {
            if (chartRef) {
                // console.log(`Chart ${index + 1} instance:`, chartRef);
                const chart = chartRef.getChartRef();
                const series = chartRef.getSeriesRefs();

                const container = chartContainerRefs.current[index];
                const width = container.clientWidth;
                const height = container.clientHeight * 2;
                chart.resize(width, height);


                const seriesData = series[0].data();
                if (seriesData.length > 0) {
                    const lastTime = new Date(seriesData[seriesData.length - 1].time).getTime();
                    const firstTime = new Date(seriesData[0].time).getTime();
                    // console.log(seriesData)

                    const visibleRangeSize = 50//1 * 24 * 60 * 60 * 1000;
                    const from = Math.max(lastTime - visibleRangeSize, firstTime);
                    const to = lastTime;

                    chart.timeScale().setVisibleLogicalRange({ from, to });
                    chart.timeScale().scrollToPosition(2);
                }


            }

            return () => {

            }
        });

    }, [chartContainerRefs.current, chartRefs.current, chartData]);

    return (
        <Box m="20px">
            {/* <Preloader open={loading} /> */}
            {/* <Box display="flex" justifyContent={{ md: 'center', lg: 'center' }} alignItems="center" p="0" > */}
            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                <Box flex="1" display="flex" justifyContent="center"></Box>
                <Box flex="1" display="flex" justifyContent="center">
                    <Header title=" " subtitle="" />
                </Box>
                {/* <Box flex="1" display="flex" justifyContent="end">
                    <Box mb="30px">
                        <Button
                            // onClick={moveToFirstData}
                            sx={{
                                variant: "h2",
                                backgroundColor: colors.greenAccent[700],
                                color: colors.grey[100],
                                // fontSize: "14px",
                                fontWeight: "bold",
                                // padding: "10px 20px",
                                m: "0 0 5px 0"
                            }}
                        >
                            First Data
                        </Button>
                    </Box>
                    <Box mb="30px" ml="3px">
                        <Button
                            onClick={moveToLastData}
                            sx={{
                                variant: "h2",
                                backgroundColor: colors.blueAccent[700],
                                color: colors.grey[100],
                                // fontSize: "14px",
                                fontWeight: "bold",
                                // padding: "10px 20px",
                                m: "0 0 5px 0"
                            }}
                        >
                            Last Data
                        </Button>
                    </Box>
                </Box> */}
            </Box>
            <Box p="0" display="Box"
                sx={{
                    height: {
                        xs: '50vh', // height for extra-small screens
                        sm: '60vh', // height for small screens
                        md: '90vh', // height for medium screens
                        lg: '90vh', // height for large screens
                        xl: '100vh', // height for extra-large screens
                    },
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '-2.3rem',
                    // width: '100%'
                }}>
                <Container maxWidth={false} sx={{ flex: 1, display: 'flex', flexDirection: 'column', }}>
                    <Paper
                        sx={{
                            flex: 1,
                            overflowY: 'auto',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            p: 2,
                            maxHeight: {
                                // xs: '50vh', // height for extra-small screens
                                // sm: '60vh', // height for small screens
                                // xs: '90vh',
                                // sm: '90vh',
                                // md: '90vh', // height for medium screens
                                // lg: '90vh', // height for large screens
                                // xl: '100vh', // height for extra-large screens
                            },

                        }}
                    >
                        <Grid container
                            direction="row"
                            spacing={0}
                            // key={"chart_row" + row} 
                            wrap="wrap"
                            sx={{
                                width: '100%',
                                margin: 0,
                                padding: 0,
                                marginTop: '5px',
                                height: '100%',

                            }}

                        > <Grid item

                            sx={{
                                width: '100%',
                                height: '100%',
                                // height: 'calc(100% * 9 / 16)',
                                padding: 0,
                                margin: 0,
                                // backgroundColor: row === 0 ? 'red' : 'blue',
                                borderLeft: '0.5px solid #000',
                                borderBottom: '0.5px solid #000',
                                borderRight: '0.5px solid #000',
                                borderRadius: '4px',
                                paddingX: '1px',
                                // backgroundColor: 'red'
                            }}

                        >
                                <Box
                                    sx={{
                                        width: '100%',
                                        padding: 0,
                                        margin: 0,
                                        // maxHeight: 'calc(100% * 9 / 16)',
                                        height: '100%',
                                        // backgroundColor: 'red'
                                    }}
                                    ref={(el) => (chartContainerRefs.current[0] = el)}
                                >


                                    <LightweightChart
                                        ref={(el) => (chartRefs.current[0] = el)}
                                        options={{ ...commonOptions, fontSize: 48 }}
                                        seriesOptions={[{
                                            options: {
                                                color: 'black',
                                                lineWidth: 2,
                                                priceLineVisible: false,
                                            },
                                            data: chartData,
                                        }]}
                                        title={chartTitle}
                                    />

                                </Box>
                            </Grid>
                        </Grid>


                    </Paper>
                </Container>

            </Box>

        </Box>


    );
};

export default FullChart;
