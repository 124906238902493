import React, { useEffect, useRef, useState } from 'react';
import { createChart } from 'lightweight-charts';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
// import axios from 'axios';
import { useIsMobile, useIsTablet, useIsPortraitTablet } from '../../components/hooks';

const API_BASE_URL = "http://172.18.1.81:3100/money_in_out";

const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const earthToneColor = '#D2B48C';
const getOppositeColor = () => '#0d0082';

const getTodayDateString = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const adjustToLocalTime = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const timezoneOffset = date.getTimezoneOffset() * 60;
  return timestamp - timezoneOffset;
};




const FullscreenPageMoneyinout = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Read query params
  const params = new URLSearchParams(location.search);
  const market = params.get('market');   // e.g. "SET100"
  const type = params.get('type');       // e.g. "in" or "out"
  const title = params.get('title');     // e.g. "SET100 Down (-)"

  // We'll define a flag whether to invert & negate the chart if type === "out"
  const shouldInvert = (type === "out");

  const fullScreenChartContainerRef = useRef(null);
  const fullScreenChartRef = useRef(null);

  const lineSeriesRef = useRef(new Map());
  const seriesDataRef = useRef(new Map());
  const originalColorsRef = useRef(new Map());

  const isMobile = useIsMobile();
  const isPTablet = useIsPortraitTablet();

  const [tableData, setTableData] = useState([]);
  const [previousRanks, setPreviousRanks] = useState({});
  const [lastRankChange, setLastRankChange] = useState({});
  const [selectedModalSymbol, setSelectedModalSymbol] = useState(null);

  const csrfToken = useSelector((state) => state.csrfToken);

  // Keep references updated on re-render
  useEffect(() => {
    // each time previousRanks changes, store in a ref
    // so we can read them inside async calls
    lineSeriesRef.current = lineSeriesRef.current || new Map();
  }, []);

  // -- Utility for fetching historical data (one-time)
  const fetchHistoricalData = async () => {
    try {
      const fetch_response = await fetch(`${window.config.base_api_url}/money_in_out/today/${market}`, {
        headers: {
          'Content-Type': 'application/json',
          'xsrf-token': csrfToken
        },
      });

      const response = await fetch_response.json();
      console.log("Fullscreen historical data:", response);

      const data = response.data[type]; // 'in' or 'out'
      const historicalData = {};
      const todayDateString = getTodayDateString();

      const times = Object.keys(data.rank);
      times.sort();
      const latestTime = times[times.length - 1];
      const latestSymbols = Object.keys(data.rank[latestTime]);

      // Build historicalData
      times.forEach((time) => {
        Object.keys(data.rank[time]).forEach((symbol) => {
          if (!historicalData[symbol]) {
            historicalData[symbol] = [];
          }
          const dateTimeString = `${todayDateString}T${time}`;
          const timestamp = Math.floor(new Date(dateTimeString).getTime() / 1000);

          // Possibly negate
          const rawValue = data.value[time][symbol];
          const finalValue = shouldInvert ? -1 * rawValue : rawValue;

          historicalData[symbol].push({
            time: adjustToLocalTime(timestamp),
            rank: data.rank[time][symbol],
            value: finalValue,
          });
        });
      });

      // We'll store the final "previousRanks" from the last snapshot
      const newPreviousRanks = {};

      let lastChangeTime = -Infinity;
      const rankChanges = [];

      // Populate the chart
      const latestSymbolsSet = new Set(latestSymbols);

      latestSymbols.forEach((symbol) => {
        const symbolData = historicalData[symbol];
        if (!symbolData || symbolData.length === 0) {
          newPreviousRanks[symbol] = null;
          return;
        }

        symbolData.sort((a, b) => a.time - b.time);
        const latestRank = symbolData[symbolData.length - 1].rank;
        newPreviousRanks[symbol] = latestRank;

        // Track rank changes
        for (let i = 1; i < symbolData.length; i++) {
          if (symbolData[i].rank !== symbolData[i - 1].rank) {
            const changeTime = symbolData[i].time;
            rankChanges.push({
              symbol,
              time: changeTime,
              oldRank: symbolData[i - 1].rank,
              newRank: symbolData[i].rank,
            });
            if (changeTime > lastChangeTime) {
              lastChangeTime = changeTime;
            }
          }
        }

        // Create or update the series
        let series = lineSeriesRef.current.get(symbol);
        if (!series) {
          const color = getRandomColor();
          series = fullScreenChartRef.current.addLineSeries({
            color,
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: false,
            title: symbol,
          });
          lineSeriesRef.current.set(symbol, series);
          originalColorsRef.current.set(symbol, color);
        }

        // Convert to {time, value} for the chart
        const seriesData = symbolData.map((dp) => ({
          time: dp.time,
          value: dp.value,
        }));
        series.setData(seriesData);
        seriesDataRef.current.set(symbol, seriesData);
      });

      setPreviousRanks(newPreviousRanks);

      // Remove any symbols not in latestSymbols
      const allSymbols = Array.from(lineSeriesRef.current.keys());
      const symbolsToRemove = allSymbols.filter(sym => !latestSymbolsSet.has(sym));
      symbolsToRemove.forEach((symbol) => {
        const s = lineSeriesRef.current.get(symbol);
        if (s) {
          fullScreenChartRef.current.removeSeries(s);
        }
        lineSeriesRef.current.delete(symbol);
        seriesDataRef.current.delete(symbol);
        originalColorsRef.current.delete(symbol);
      });

      // If user had selected a symbol that is no longer in the data, unselect
      if (selectedModalSymbol && !latestSymbolsSet.has(selectedModalSymbol)) {
        setSelectedModalSymbol(null);
      }

      // Determine highlight colors
      const lastChanges = rankChanges.filter((rc) => rc.time === lastChangeTime);
      const symbolColors = new Map();
      if (lastChangeTime !== -Infinity && lastChanges.length > 0) {
        lastChanges.forEach((change) => {
          if (change.newRank < change.oldRank) {
            symbolColors.set(change.symbol, '#ccffcc');
          } else if (change.newRank > change.oldRank) {
            symbolColors.set(change.symbol, '#ffcccc');
          } else {
            symbolColors.set(change.symbol, 'transparent');
          }
        });
      }

      // Build table data
      const updatedTableData = latestSymbols.map((symbol) => {
        const symbolData = historicalData[symbol];
        const latestData = symbolData[symbolData.length - 1];
        const color = symbolColors.get(symbol) || 'transparent';

        return {
          rank: latestData.rank,
          symbol,
          value: latestData.value,
          color,
        };
      });

      setTableData(updatedTableData);

    } catch (error) {
      console.error("Error fetching historical data:", error);
    }
  };

  // -- Utility for fetching current data (polling)
  const fetchCurrentData = async () => {
    try {
      // if (!Object.keys(previousRanks).length) {
      //   console.warn("previousRanks not init, skipping fetchCurrentData");
      //   return;
      // }
      
      const fetch_response = await fetch(`${window.config.base_api_url}/money_in_out/now/${market}`, {
        headers: {
          'Content-Type': 'application/json',
          'xsrf-token': csrfToken
        },
      });
      const response = await fetch_response.json();

      const data = response.data[type]; // 'in' or 'out'
      const timeKey = Object.keys(data.rank)[0];
      const todayDateString = getTodayDateString();
      const dateTimeString = `${todayDateString}T${timeKey}`;
      const timestamp = Math.floor(new Date(dateTimeString).getTime() / 1000);

      const latestSymbols = Object.keys(data.rank[timeKey]) || [];
      const newPreviousRanks = {};

      const rankChanges = [];
      let lastChangeTime = timestamp;

      // We'll gather updated table data
      const updatedTableData = [];

      latestSymbols.forEach((symbol) => {
        const currentRank = data.rank[timeKey][symbol];
        const oldRank = previousRanks[symbol] ?? currentRank;

        newPreviousRanks[symbol] = currentRank;

        if (currentRank !== oldRank) {
          rankChanges.push({
            symbol,
            time: adjustToLocalTime(timestamp),
            oldRank,
            newRank: currentRank,
          });
        }

        // Possibly invert
        const rawValue = data.value[timeKey][symbol];
        const finalValue = shouldInvert ? -1 * rawValue : rawValue;

        updatedTableData.push({
          rank: currentRank,
          symbol,
          value: finalValue,
          color: 'transparent', // default, we can update highlight next
        });
      });

      setPreviousRanks(newPreviousRanks);

      // Remove any symbol not in the latest
      const allSymbols = Array.from(lineSeriesRef.current.keys());
      const missingSymbols = allSymbols.filter(sym => !latestSymbols.includes(sym));
      missingSymbols.forEach((symbol) => {
        const s = lineSeriesRef.current.get(symbol);
        if (s) {
          fullScreenChartRef.current.removeSeries(s);
        }
        lineSeriesRef.current.delete(symbol);
        seriesDataRef.current.delete(symbol);
        originalColorsRef.current.delete(symbol);
      });

      if (selectedModalSymbol && !latestSymbols.includes(selectedModalSymbol)) {
        setSelectedModalSymbol(null);
      }

      // Update the chart lines for each symbol
      latestSymbols.forEach((symbol) => {
        // Possibly invert
        const rawValue = data.value[timeKey][symbol];
        const finalValue = shouldInvert ? -1 * rawValue : rawValue;

        const updatePoint = {
          time: adjustToLocalTime(timestamp),
          value: finalValue,
        };

        let series = lineSeriesRef.current.get(symbol);
        if (series) {
          const oldSeriesData = seriesDataRef.current.get(symbol) || [];
          const lastIdx = oldSeriesData.length - 1;
          if (lastIdx >= 0 && oldSeriesData[lastIdx].time === updatePoint.time) {
            oldSeriesData[lastIdx] = updatePoint;
          } else {
            oldSeriesData.push(updatePoint);
          }
          series.setData(oldSeriesData);
          seriesDataRef.current.set(symbol, oldSeriesData);
        } else {
          const color = getRandomColor();
          series = fullScreenChartRef.current.addLineSeries({
            color,
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: false,
            title: symbol,
          });
          series.setData([updatePoint]);
          lineSeriesRef.current.set(symbol, series);
          originalColorsRef.current.set(symbol, color);
          seriesDataRef.current.set(symbol, [updatePoint]);
        }
      });

      // highlight any changed ranks
      if (rankChanges.length > 0) {
        rankChanges.forEach(change => {
          if (change.newRank < change.oldRank) {
            const idx = updatedTableData.findIndex((r) => r.symbol === change.symbol);
            if (idx >= 0) updatedTableData[idx].color = '#ccffcc';
          } else if (change.newRank > change.oldRank) {
            const idx = updatedTableData.findIndex((r) => r.symbol === change.symbol);
            if (idx >= 0) updatedTableData[idx].color = '#ffcccc';
          }
        });
      }

      setTableData(updatedTableData);

    } catch (error) {
      console.error("Error fetching current data:", error);
    }
  };

  // -- Set up the chart once
  useEffect(() => {
    if (!fullScreenChartContainerRef.current) return;

    const chart = createChart(fullScreenChartContainerRef.current, {
      layout: { textColor: 'black', background: { type: 'solid', color: 'white' } },
      timeScale: { timeVisible: true, rightOffset: 10 },
      height: fullScreenChartContainerRef.current.offsetHeight,
      width: fullScreenChartContainerRef.current.offsetWidth,
      crosshair: {
        horizontalLine: { visible: false },
        verticalLine: { visible: true },
      },
      localization: {
        priceFormatter: (price) => (price / 1_000_000).toLocaleString('en-US', { maximumFractionDigits: 2 }),
      },
      priceScale: {
        mode: 0,
      },
    });
    fullScreenChartRef.current = chart;

    // If we want to invert the scale for type === "out"
    if (shouldInvert) {
      chart.applyOptions({
        invertScale: true,
      });
    }

    const initializeData = async () => {
      await fetchHistoricalData();
      await fetchCurrentData();
    };

    initializeData();

    const intervalID = setInterval(() => {
      fetchCurrentData();
    }, 10000);

    //Dynamic Chart Size

    const resizeObserver = new ResizeObserver(() => {
      if (chart && fullScreenChartContainerRef.current) {
        chart.resize(
          fullScreenChartContainerRef.current.offsetWidth,
          fullScreenChartContainerRef.current.offsetHeight
        );
      }
    });
  
    resizeObserver.observe(fullScreenChartContainerRef.current);

    //Dynamic Chart Size

    return () => {
      clearInterval(intervalID);
      resizeObserver.disconnect();
      if (chart) {
        chart.remove();
      }
    };
  }, [market, type]);

  // -- Chart Navigation
  const handleStartButtonClick = () => {
    if (!fullScreenChartRef.current) return;

    const timeScale = fullScreenChartRef.current.timeScale();
    let earliestTime = Infinity;
    let latestTime = -Infinity;

    seriesDataRef.current.forEach((data) => {
      if (data.length > 0) {
        earliestTime = Math.min(earliestTime, data[0].time);
        latestTime = Math.max(latestTime, data[data.length - 1].time);
      }
    });

    if (earliestTime !== Infinity && latestTime !== -Infinity) {
      const visibleRange = timeScale.getVisibleRange();
      const rangeDuration = visibleRange.to - visibleRange.from;
      timeScale.setVisibleRange({
        from: earliestTime,
        to: earliestTime + rangeDuration,
      });
    }
  };

  const handleHalfDayButtonClick = () => {
    if (!fullScreenChartRef.current) return;

    const timeScale = fullScreenChartRef.current.timeScale();
    let earliestTime = Infinity;
    let latestTime = -Infinity;

    seriesDataRef.current.forEach((data) => {
      if (data.length > 0) {
        earliestTime = Math.min(earliestTime, data[0].time);
        latestTime = Math.max(latestTime, data[data.length - 1].time);
      }
    });

    if (earliestTime !== Infinity && latestTime !== -Infinity) {
      const middleTime = (earliestTime + latestTime) / 2;
      const visibleRange = timeScale.getVisibleRange();
      const rangeDuration = visibleRange.to - visibleRange.from;

      timeScale.setVisibleRange({
        from: middleTime - rangeDuration / 2,
        to: middleTime + rangeDuration / 2,
      });
    }
  };

  const handleEndDayButtonClick = () => {
    if (!fullScreenChartRef.current) return;

    const timeScale = fullScreenChartRef.current.timeScale();
    let latestTime = -Infinity;

    seriesDataRef.current.forEach((data) => {
      if (data.length > 0) {
        latestTime = Math.max(latestTime, data[data.length - 1].time);
      }
    });

    if (latestTime !== -Infinity) {
      const visibleRange = timeScale.getVisibleRange();
      const rangeDuration = visibleRange.to - visibleRange.from;
      timeScale.setVisibleRange({
        from: latestTime - rangeDuration,
        to: latestTime,
      });
    }
  };

  const handleShowAllButtonClick = () => {
    if (!fullScreenChartRef.current) return;

    const timeScale = fullScreenChartRef.current.timeScale();
    let earliestTime = Infinity;
    let latestTime = -Infinity;

    seriesDataRef.current.forEach((data) => {
      if (data.length > 0) {
        earliestTime = Math.min(earliestTime, data[0].time);
        latestTime = Math.max(latestTime, data[data.length - 1].time);
      }
    });

    if (earliestTime !== Infinity && latestTime !== -Infinity) {
      timeScale.setVisibleRange({ from: earliestTime, to: latestTime });
    }
  };

  // -- Table row click logic
  const handleRowClickModal = (symbol) => {
    if (selectedModalSymbol === symbol) {
      // Deselect
      setSelectedModalSymbol(null);
      // Reset all series to original colors and styles
      lineSeriesRef.current.forEach((series, sym) => {
        const originalColor = originalColorsRef.current.get(sym);
        series.applyOptions({
          color: originalColor,
          lineWidth: 2,
        });
      });
    } else {
      // Highlight the clicked series
      setSelectedModalSymbol(symbol);
      const selectedSeries = lineSeriesRef.current.get(symbol);
      const data = seriesDataRef.current.get(symbol);

      if (selectedSeries && fullScreenChartRef.current) {
        // remove & re-add to bring on top
        fullScreenChartRef.current.removeSeries(selectedSeries);
        const newSeries = fullScreenChartRef.current.addLineSeries({
          color: selectedSeries.options().color,
          lineWidth: selectedSeries.options().lineWidth,
          lastValueVisible: true,
          priceLineVisible: false,
          title: symbol,
        });
        newSeries.setData(data);
        lineSeriesRef.current.set(symbol, newSeries);

        const originalColor = originalColorsRef.current.get(symbol);
        const oppositeColor = getOppositeColor();
        newSeries.applyOptions({
          color: oppositeColor,
          lineWidth: 4,
        });

        // fade other series
        lineSeriesRef.current.forEach((s, sym) => {
          if (sym !== symbol) {
            s.applyOptions({
              color: earthToneColor,
              lineWidth: 2,
            });
          }
        });
      }
    }
  };


  function renderTable(startIndex, endIndex) {
    const dataSlice = tableData.sort((a, b) => a.rank - b.rank).slice(startIndex, endIndex);
    return (
      <table className="rank-table" style={{ fontSize: '11.5px', fontFamily: 'Arial,sans-serif' }}>
        <thead>
          <tr>
            <th style={{ textAlign: 'left' }}>Rank</th>
            <th style={{ textAlign: 'left' }}>Symbol</th>
            <th style={{ textAlign: 'left' }}>Value</th>
          </tr>
        </thead>
        <tbody>
          {dataSlice.map((row, idx) => (
            <tr
              key={idx}
              style={{
                backgroundColor: row.color,
                cursor: 'pointer',
                fontWeight: row.symbol === selectedModalSymbol ? 'bold' : 'normal',
              }}
              onClick={() => handleRowClickModal(row.symbol)}
            >
              <td>{row.rank}</td>
              <td>{row.symbol}</td>
              <td>{(row.value / 1_000_000).toLocaleString('en-US', { maximumFractionDigits: 2 })}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  return (
    <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px' }}>
      {/* Back button */}

      <h3>{title}</h3>

      {/* Buttons to shift time range */}
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
        <div style={{ display: 'flex', gap: '10px' }}>
          <button onClick={handleStartButtonClick} className="nav-button">Start</button>
          <button onClick={handleHalfDayButtonClick} className="nav-button">Half-Day</button>
          <button onClick={handleEndDayButtonClick} className="nav-button">End-Day</button>
          <button onClick={handleShowAllButtonClick} className="nav-button">Show All</button>
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: (isMobile || isPTablet) ? 'column' : 'row', gap: '20px' }}>
        {/* Chart Container */}
        <div
          ref={fullScreenChartContainerRef}
          className="chart-container"
          style={{
            width: (isMobile || isPTablet) ? '100%' : '80%',
            height: (isMobile || isPTablet) ? '40vh' : '80vh',
            position: 'relative',
            fontFamily: 'Arial, sans-serif',
          }}
        />

        {/* Right side table */}

        {(!isMobile && !isPTablet) && (
          <>
            <div
              className="table-container"
              style={{
                width: '20%',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                overflowY: 'auto',
                maxHeight: '80vh',
                fontFamily: 'Arial, sans-serif',
              }}
            >
              <table className="rank-table" style={{ fontSize: '11.5px', fontFamily: 'Arial, sans-serif' }}>
                <thead>
                  <tr>
                    <th style={{ textAlign: 'left' }}>Rank</th>
                    <th style={{ textAlign: 'left' }}>Symbol</th>
                    <th style={{ textAlign: 'left' }}>Value</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData
                    .sort((a, b) => a.rank - b.rank)
                    .map((row, index) => (
                      <tr
                        key={index}
                        style={{
                          backgroundColor: row.color,
                          cursor: 'pointer',
                          fontWeight: row.symbol === selectedModalSymbol ? 'bold' : 'normal',
                        }}
                        onClick={() => handleRowClickModal(row.symbol)}
                      >
                        <td>{row.rank}</td>
                        <td>{row.symbol}</td>
                        <td>{(row.value / 1_000_000).toLocaleString('en-US', { maximumFractionDigits: 2 })}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </>
        )}
        {(isMobile || isPTablet) && (
          <>
            <div
              className="table-container"
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
                justifyContent: 'center',
                // overflowY: 'auto',
                // maxHeight: '80vh',
                fontFamily: 'Arial, sans-serif',
              }}
            >
              <div style={{ width: '40%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                {renderTable(0, 10)}
              </div>
              <div style={{ width: '40%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                {renderTable(10, 20)}
              </div>
            </div>
          </>
        )}

      </div>
    </div>
  );
};

export default FullscreenPageMoneyinout;
