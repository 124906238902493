import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux"; // if you need the csrfToken from Redux

// We'll fetch from your existing endpoint


/**
 * A simple component to draw a bar chart with negative/positive bars using plain HTML + CSS.
 */
function SimpleBarChart({ data, width = 800, height = 400 }) {
    const HOTSTOCK_API = `${window.config.base_api_url}/hotstock`;
    // We'll assume `data` = [{ symbol, score }, ...], sorted ascending by `score`.

    // The midpoint of the chart's vertical space is y=height/2 => zero line.
    const zeroLine = height / 2;

    // Define each bar's width and gap in pixels
    const barWidth = 30;
    const barGap = 5;

    // The total width needed to display all bars side-by-side
    const totalBars = data.length;
    const chartWidth = totalBars * (barWidth + barGap);

    // Find the maximum absolute score => used to scale bar heights
    const maxAbsScore = Math.max(...data.map(d => Math.abs(d.score)), 0);

    return (
        <div style={{ width: width, overflowX: 'auto' }}>
            {/* Chart container is relatively positioned */}
            <div
                style={{
                    position: 'relative',
                    height: `${height}px`,
                    width: `${chartWidth}px`,
                    border: '1px solid #ccc',
                    background: '#fdfdfd',
                }}
            >
                {/* Zero line */}
                <div
                    style={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: zeroLine,
                        height: '2px',
                        backgroundColor: '#000',
                    }}
                />

                {/* Bars */}
                {data.map((item, idx) => {
                    // scale factor: (abs(score) / maxAbsScore)
                    const scale = maxAbsScore === 0 ? 0 : Math.abs(item.score) / maxAbsScore;
                    // each bar extends up or down from zero line
                    const barHeight = scale * (height / 2); // half for positive above zero, half for negative below zero
                    const barColor = item.score < 0 ? "#ff4d4f" : "#4caf50";
                    const leftPos = idx * (barWidth + barGap);

                    // If score >= 0 => bar extends upward from zero line
                    // => top coordinate is zeroLine - barHeight
                    // If score < 0 => bar extends downward => top = zeroLine
                    const topPos = item.score >= 0 ? zeroLine - barHeight : zeroLine;

                    return (
                        <div
                            key={item.symbol}
                            title={`${item.symbol}: ${item.score}`}
                            style={{
                                position: 'absolute',
                                left: leftPos,
                                width: `${barWidth}px`,
                                height: `${barHeight}px`,
                                top: `${topPos}px`,
                                backgroundColor: barColor,
                                cursor: 'pointer',
                            }}
                        />
                    );
                })}

                {/* X-Axis Labels under each bar */}
                {data.map((item, idx) => {
                    const leftPos = idx * (barWidth + barGap);
                    return (
                        <div
                            key={`${item.symbol}-label`}
                            style={{
                                position: 'absolute',
                                left: leftPos,
                                top: zeroLine + 4, // just below the zero line
                                width: `${barWidth}px`,
                                textAlign: 'center',
                                fontSize: '11px',
                                color: '#333',
                            }}
                        >
                            {item.symbol}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

function HotStockSection() {
    const [hotStockData, setHotStockData] = useState([]);
    const csrfToken = useSelector((state) => state.csrfToken); // If you have a Redux store

    useEffect(() => {
        async function fetchHotStockData() {
            try {
                const response = await fetch(HOTSTOCK_API, {
                    headers: {
                        'Content-Type': 'application/json',
                        'xsrf-token': csrfToken,
                    },
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                // Example: { "XRPTHB": -12, "DELTA": 5, "DELTAH25": 5, "CCET": 4, ... }
                const data = await response.json();
                console.log("Raw hotstock data from API:", data);

                // Convert that object into an array => [{symbol, score}, ...]
                const arrayData = Object.keys(data).map((symbol) => ({
                    symbol,
                    score: data[symbol],
                }));

                // Sort ascending by score => negative first
                arrayData.sort((a, b) => a.score - b.score);

                setHotStockData(arrayData);
            } catch (error) {
                console.error("Error fetching hotstock data:", error);
            }
        }
        fetchHotStockData();
    }, [csrfToken]);

    return (
        <div style={{ marginBottom: "40px" }}>
            <h2>Hot Stock (Scores)</h2>
            {hotStockData.length === 0 ? (
                <p>Loading...</p>
            ) : (
                // Render our custom bar chart
                <SimpleBarChart data={hotStockData} width={900} height={400} />
            )}
        </div>
    );
}

export default HotStockSection;
