import React, { useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { createChart, CrosshairMode, PriceScaleMode } from 'lightweight-charts';

// const LightweightChart = ({ options, seriesOptions, title }) => {
const LightweightChart = forwardRef(({ options, seriesOptions, title }, ref) => {
    const chartContainerRef = useRef(null);
    const chartRef = useRef(null);
    const seriesRefs = useRef([]);

    useEffect(() => {
        const chart = createChart(chartContainerRef.current, options);
        chartRef.current = chart;

        // chartAnnotation(chart, title);
        seriesOptions.forEach((seriesOption, index) => {
            const lineSeries = chart.addLineSeries(seriesOption.options);
            lineSeries.setData(seriesOption.data);
            seriesRefs.current[index] = lineSeries;

            lineSeries.applyOptions({
                priceFormat: {
                    type: 'custom',
                    formatter: (price) => {
                        let shortenedPrice = price / 1_000_000_000;
                        return shortenedPrice.toLocaleString('en-US', {
                            minimumFractionDigits: 1,
                            maximumFractionDigits: 1,
                        }) + 'B';

                    },
                },
            });

            if (seriesOption.data.length <= 1) {
                chart.applyOptions({
                    watermark: {
                        visible: true,
                        fontSize: 14,
                        horzAlign: 'center',
                        vertAlign: 'center',
                        color: 'grey',
                        text: `${title}:None`,
                    },
                });
            } else {
                chart.applyOptions({
                    watermark: {
                        visible: true,
                        fontSize: options.fontSize || 32,
                        horzAlign: 'center',
                        vertAlign: 'center',
                        color: 'rgba(0, 0, 0, 0.1)',
                        text: `${title}`,
                    },
                });
            }

        });

        // chart.subscribeCrosshairMove((param) => {
        //     if (param) {
        //         const series = seriesRefs.current[0];  // หรือเลือก series ที่คุณต้องการ
        //         const dataPoint = getCrosshairDataPoint(series, param);
        //         if (dataPoint) {
        //             onCrosshairMove(dataPoint);
        //         }
        //     }
        // });

        return () => {
            chart.remove();
        };
    }, [options, seriesOptions]);

    // useEffect(() => {
    //     if (ref) {
    //         ref.current = seriesRefs.current;
    //         
    //     }
    // }, [ref]);
    // useImperativeHandle(ref, () => ({
    //     getSeriesRefs: () => seriesRefs.current,
    // }));
    useImperativeHandle(ref, () => ({
        getChartRef: () => chartRef.current, // ส่ง ref ของ chart
        getSeriesRefs: () => seriesRefs.current, // ส่ง ref ของ series
    }));



    return <div ref={chartContainerRef} style={{ width: '100%', height: '300px' }} />;
    // };
});

function chartAnnotation(chart, text) {
    chart.applyOptions({
        watermark: {
            visible: true,
            fontSize: 20,
            horzAlign: 'left',
            vertAlign: 'top',
            color: 'rgb(1,216,149)',
            text: text,
        },
        rightPriceScale: {
            visible: true,
        },
        // leftPriceScale: {
        //     visible: true,
        //     mode: PriceScaleMode.Normal,
        //     autoScale: false,  // Disable automatic scaling
        //     invertScale: false,
        //     alignLabels: true,
        //     minValue: -1,      // Set the minimum value
        //     maxValue: 1,       // Set the maximum value
        // },
    });



}

export default LightweightChart;
