import React from 'react';
import './NoDataAnimation.css'; // นำเข้าไฟล์ CSS สำหรับแอนิเมชั่น

const NoDataAnimation = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <div className="loader">
        <div className="snow">
          <span style={{ '--i': 11 }}></span>
          <span style={{ '--i': 12 }}></span>
          <span style={{ '--i': 15 }}></span>
          <span style={{ '--i': 17 }}></span>
          <span style={{ '--i': 18 }}></span>
          <span style={{ '--i': 13 }}></span>
          <span style={{ '--i': 14 }}></span>
          <span style={{ '--i': 19 }}></span>
          <span style={{ '--i': 20 }}></span>
          <span style={{ '--i': 10 }}></span>
          <span style={{ '--i': 18 }}></span>
          <span style={{ '--i': 13 }}></span>
          <span style={{ '--i': 14 }}></span>
          <span style={{ '--i': 19 }}></span>
          <span style={{ '--i': 20 }}></span>
          <span style={{ '--i': 10 }}></span>
          <span style={{ '--i': 18 }}></span>
          <span style={{ '--i': 13 }}></span>
          <span style={{ '--i': 14 }}></span>
          <span style={{ '--i': 19 }}></span>
          <span style={{ '--i': 20 }}></span>
          <span style={{ '--i': 10 }}></span>
        </div>
      </div>
      <p style={{ marginTop: '20px', color: '#555',marginLeft:'8px' }}>No data available </p>
    </div>
  );
};

export default NoDataAnimation;
