
import React from 'react';
import './Loading.css'; // นำเข้าไฟล์ CSS

const Loading = () => {
  return (
    <div className="center-lo">
      <div className="loging">
        <div id="load1"></div>
        <div id="load2"></div>
        <div id="load3"></div>
        <div id="load4"></div>
        <div id="load5"></div>
      </div>
    </div>
  );
};

export default Loading;