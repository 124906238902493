import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { TextField, Typography, Switch, Box, Divider, List, ListItem, ListItemText, Button, Checkbox, CircularProgress  } from '@mui/material';
import { __fetching_data__ } from '../../components/api.js';
import Navbar from './component/Navbar';
import { useIsMobile, useIsTablet, useIsPortraitTablet } from '../../components/hooks';

const base_api_url =
    process.env.NODE_ENV === "production"
        ? window.location.protocol === "https:"
            ? process.env.REACT_APP_API_HTTPS_URL
            : process.env.REACT_APP_API_HTTP_URL
        : "/application";

const NewsComponent = () => {
    const [searchText, setSearchText] = useState('');
    const [news, setNews] = useState([]);
    const [selectedNews, setSelectedNews] = useState([]);  // สำหรับเก็บข่าวที่ถูกเลือกเพื่อการยืนยัน
    const [isAtBottom, setIsAtBottom] = useState(true); // ตรวจสอบว่าปุ่มอยู่ด้านล่างสุดหรือไม่
    const [isLoading, setIsLoading] = useState(true); // สถานะการโหลด

    const csrfToken = useSelector((state) => state.csrfToken);
    const isMobile = useIsMobile();
    const isPTablet = useIsPortraitTablet();
    const isTablet = useIsTablet();

    // ตรวจสอบตำแหน่งของ Box
    useEffect(() => {
        const handleScroll = () => {
            const windowHeight = window.innerHeight;
            const bodyHeight = document.body.scrollHeight;
            const scrollPosition = window.scrollY;
    
            const isAtBottom = windowHeight + scrollPosition >= bodyHeight - 1; // ตรวจสอบว่าถึงด้านล่างสุดแล้วหรือยัง
    
            setIsAtBottom(isAtBottom);
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Fetch news from backend
    useEffect(() => {
        const fetchNews = async () => {
            try {
                const response = await __fetching_data__({
                    url: `${base_api_url}/fetch_news_type/`,
                    method: "GET",
                    headers: { "xsrf-token": csrfToken },
                });
                
                if (!response || !response.data) {
                    throw new Error('Invalid response data');
                }

                setNews(
                    response.data.news
                    .filter((item) => item.status === 'none')  // แสดงเฉพาะข่าวที่มีสถานะ 'none'
                    .map((item) => ({
                        id: item.title,
                        title: item.title,
                        enabled: item.status === 'none', // Convert status to boolean
                    }))
                );
            } catch (error) {
                console.error('Error fetching news:', error);
            } finally {
                setIsLoading(false); // ปิดสถานะการโหลด
            }
        };
        fetchNews();
    }, [__fetching_data__, csrfToken]);

    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    };

    const handleCheckboxChange = (id) => {
        // เพิ่ม/ลบ ข่าวที่เลือก
        setSelectedNews((prevSelected) => {
            if (prevSelected.includes(id)) {
                return prevSelected.filter((newsId) => newsId !== id); // ถ้าเลือกแล้วให้ลบออก
            } else {
                return [...prevSelected, id]; // ถ้ายังไม่เลือกให้เพิ่ม
            }
        });
    };

    const handleConfirmAccept = async () => {
        try {
            const response = await __fetching_data__({ url: `${base_api_url}/change_news_type/`, 
                method: 'POST',
                body: JSON.stringify({
                    word: selectedNews, // ส่ง array ไปที่ backend
                    status: "access",
                }),
            });
    
            // อัปเดตสถานะใน news state หลังจากยืนยันสำเร็จ
            setNews((prevNews) =>
                prevNews.map((item) =>
                    selectedNews.includes(item.id)
                        ? { ...item, enabled: true }
                        : item
                )
            );
    
            // ล้างการเลือกหลังจากยืนยันสำเร็จ
            setSelectedNews([]);
            window.location.reload();
        } catch (error) {
            console.error("Error updating news status:", error);
        }
    };
    
    const handleConfirmDecline = async () => {
        try {
            const response = await __fetching_data__({ url: `${base_api_url}/change_news_type/`, 
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'xsrf-token': csrfToken,
                },
                body: JSON.stringify({
                    word: selectedNews, // ส่ง array ไปที่ backend
                    status: "inaccessible",
                }),
            });
    
            // อัปเดตสถานะใน news state หลังจากยืนยันสำเร็จ
            setNews((prevNews) =>
                prevNews.map((item) =>
                    selectedNews.includes(item.id)
                        ? { ...item, enabled: true }
                        : item
                )
            );
    
            // ล้างการเลือกหลังจากยืนยันสำเร็จ
            setSelectedNews([]);
            window.location.reload();
        } catch (error) {
            console.error("Error updating news status:", error);
        }
    };

    const filteredNews = news.filter((item) => 
        (item.title?.toLowerCase() || '').includes(searchText.toLowerCase())
    );

    return (
        <>
        <Navbar />
        <Box
            sx={{
                maxWidth: isMobile ? '90vw' : 600,
                marginTop: '16px',
                marginBottom: '50px',
                marginLeft: 'auto',
                marginRight: 'auto',
                padding: 2,
                backgroundColor: 'white',
                borderRadius: 3,
                boxShadow: 3,
                fontFamily: 'Arial, sans-serif',
            }}
        >
            {/* Header */}
            <Typography variant="h4" gutterBottom>
                หัวข้อข่าว
            </Typography>

            {/* Search Bar */}
            <TextField
                fullWidth
                variant="outlined"
                placeholder="ค้นหาหัวข้อข่าว..."
                value={searchText}
                onChange={handleSearchChange}
                sx={{
                    marginBottom: 2,
                    borderRadius: '10px',
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '10px',
                    },
                }}
            />

            <Divider sx={{ marginBottom: 2 }} />

            {isLoading ? (
                    /* แสดงไอคอนโหลด */
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '200px',
                        }}
                    >
                        <CircularProgress />
                    </Box>
                ) : (
                /* News List */
                <List>
                    {filteredNews.map((item) => (
                        <ListItem
                            key={item.id}
                            sx={{
                                padding: 0,
                                paddingX: 2,
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                border: '1px solid #ddd',
                                borderRadius: 2,
                                marginBottom: 1,
                                boxShadow: 0,
                            }}
                        >
                            <ListItemText
                                primary={
                                    <a
                                        href={`/linknews/${encodeURIComponent(item.title)}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                            textDecoration: 'none',
                                            color: 'inherit',
                                        }}
                                        onMouseEnter={(e) => (e.target.style.textDecoration = 'underline')}
                                        onMouseLeave={(e) => (e.target.style.textDecoration = 'none')}
                                    >
                                        {item.title}
                                    </a>
                                }
                            />
                            <Checkbox
                                checked={selectedNews.includes(item.id)} // เช็คว่าข่าวถูกเลือกแล้วหรือยัง
                                onChange={() => handleCheckboxChange(item.id)}
                            />
                        </ListItem>
                    ))}
                </List>
            )}

            <Box
                sx={{
                    position: 'sticky', // ใช้ sticky แทน fixed
                    bottom: 16, // ตำแหน่งล่างเมื่อถึงขอบล่างของหน้าจอ
                    marginTop: '16px', // ระยะห่างจาก List
                    display: 'flex',
                    justifyContent: 'space-between', // เว้นระยะห่างระหว่างปุ่ม
                    backgroundColor: 'white', // สีพื้นหลัง
                    padding: 2, // ระยะห่างภายใน
                    borderRadius: 3, // มุมโค้งมน
                    boxShadow: isAtBottom ? 'none' : 3, // หากอยู่ที่ด้านล่างสุดจะไม่มีเงา
                    zIndex: 1000, // ให้ปุ่มลอยเหนือองค์ประกอบอื่น
                    border: '1px solid #ddd',
                }}
            >
                {/* Confirm Accept Button */}
                <Button
                    variant="contained"
                    color="success"
                    onClick={handleConfirmAccept}
                    disabled={selectedNews.length === 0} // ปุ่มจะไม่สามารถกดได้หากไม่มีการเลือกข่าว
                    sx={{
                        borderRadius: 2,
                        boxShadow: 'none',
                    }}
                >
                    Move to Accept
                </Button>

                {/* Confirm Decline Button */}
                <Button
                    variant="contained"
                    color="error"
                    onClick={handleConfirmDecline}
                    disabled={selectedNews.length === 0} // ปุ่มจะไม่สามารถกดได้หากไม่มีการเลือกข่าว
                    sx={{
                        borderRadius: 2,
                        boxShadow: 'none',
                    }}
                >
                    Move to Decline
                </Button>
            </Box>


        </Box>
        </>
    );
};

export default NewsComponent;
