const convertThaiDateToJSDate = (thaiDate, time) => {
    // แยกวัน เดือน และปีจากวันที่ภาษาไทย
    const [day, monthThai, yearThai] = thaiDate.split(' ');

    // สร้างออบเจกต์สำหรับแปลงชื่อเดือนภาษาไทยเป็นตัวเลขเดือน
    const thaiMonths = {
        'ม.ค.': 0,
        'ก.พ.': 1,
        'มี.ค.': 2,
        'เม.ย.': 3,
        'พ.ค.': 4,
        'มิ.ย.': 5,
        'ก.ค.': 6,
        'ส.ค.': 7,
        'ก.ย.': 8,
        'ต.ค.': 9,
        'พ.ย.': 10,
        'ธ.ค.': 11
    };

    // แปลงปี พ.ศ. เป็นปี ค.ศ.
    const yearCE = parseInt(yearThai) - 543;

    // แปลงเวลาให้อยู่ในรูปแบบ HH:mm
    const [hour, minute] = time.split(':');

    // สร้าง Date object
    return new Date(yearCE, thaiMonths[monthThai], parseInt(day), parseInt(hour), parseInt(minute));
};

export default convertThaiDateToJSDate;
