import React, { useState, useEffect } from 'react';
import Chart from './Chart';
import Form from './Form';
import Table from './Table';
import LoadingSpinner from './LoadingSpinner';
import NoDataAnimation from './NoDataAnimation';
import { useSelector } from 'react-redux';

const PageFirst = () => {
    const [symbol, setSymbol] = useState(null);
    const [startDate, setStartDate] = useState("2024-12-25");
    const [endDate, setEndDate] = useState("2024-12-25");
    const [nStd, setNStd] = useState(3);
    const [windowState, setWindowState] = useState(0);
    const [data, setData] = useState(null);
    const [upperExceedData, setUpperExceedData] = useState([]);
    const [lowerExceedData, setLowerExceedData] = useState([]);
    const [loading, setLoading] = useState(false);
    const csrfToken = useSelector((state) => state.csrfToken);

    async function fetchData() {
        console.log("Parameters:", { symbol, startDate, endDate, nStd, windowState });
        // const url = `${window.config.base_api_url}/bidask_volume/${symbol}?startDate=${startDate}&endDate=${endDate}&nStd=${nStd}&windowState=${windowState}`;
        const url = `${window.config.base_api_url}/bidask_volume/${symbol}&${startDate}&${endDate}&${nStd}&${windowState}`;
        console.log("Requesting URL:", url);
        // console.log(window.config.base_api_url)

        setLoading(true); // เปิด LoadingSpinner ก่อนเริ่มโหลดข้อมูล
        try {
            const response = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'xsrf-token': csrfToken,
                },
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch data. Status: ${response.status}`);
            }

            const data = await response.json();
            console.log("Fetched data:", data);
            setData(data.data);
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }finally {
            setLoading(false); // ปิด LoadingSpinner หลังโหลดข้อมูลเสร็จ (ไม่ว่าจะสำเร็จหรือไม่)
        }
    }

    useEffect(() => {
        if (!data) return;

        const upperExceed = [];
        const lowerExceed = [];

        data.forEach((item) => {
            if (item.spread_vol > item.spread_upper_bound) {
                upperExceed.push(item);
            }
            if (item.spread_vol < item.spread_lower_bound) {
                lowerExceed.push(item);
            }
        });

        setUpperExceedData(upperExceed);
        setLowerExceedData(lowerExceed);
    }, [data]);

    if (loading) {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontFamily: '"Inter", system-ui, Avenir, Helvetica, Arial, sans-serif',
                textAlign: 'center',
            }}>
                <LoadingSpinner />
            </div>
        );
    }

    return (
        <div style={{ padding: '20px', marginTop: '-40px' }}>
            <div style={{ textAlign: 'left', marginBottom: '20px' }}>
                <h1 style={{ marginLeft: '450px', fontFamily:' sans-serif', fontSize:'2.5rem' }}>Bid/Ask Volume Visualization</h1>
                <Form
                    symbol={symbol}
                    startDate={startDate}
                    endDate={endDate}
                    nStd={nStd}
                    window={windowState}
                    setSymbol={setSymbol}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    setNStd={setNStd}
                    setWindow={setWindowState}
                    fetchData={fetchData}
                />
            </div>

            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: '20px',
                marginTop: '-30px',
                fontFamily: '"Inter", system-ui, Avenir, Helvetica, Arial, sans-serif',
            }}>
                {data ? (
                    <>
                        <Chart data={data} />
                        <Table upperExceedData={upperExceedData} lowerExceedData={lowerExceedData} />
                    </>
                ) : (
                    <NoDataAnimation />
                )}
            </div>
        </div>
    );
};

export default PageFirst;
