import { useEffect } from "react"

import { __fetching_data__ } from '../../components/api.js';
import { useState } from "react";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTheme, Box, Container, Grid, Card, TextField, Button, Table, TableHead, TableRow, TableCell, TableBody, Typography, Paper } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';

import './index.css'
import BidAskComponent2 from "./bidask.jsx";
import { ColorType, createChart, Rectangle } from 'lightweight-charts';

const base_api_url =
    process.env.NODE_ENV === "production"
        ? window.location.protocol === "https:"
            ? process.env.REACT_APP_API_HTTPS_URL
            : process.env.REACT_APP_API_HTTP_URL
        : "/application";

let globalList = []

export default function HisDayTrade() {
    const csrfToken = useSelector((state) => state.csrfToken);
    const [idxSelect, setIdxSelect] = useState(-1)
    const [data1, setData1] = useState([])
    const [data2, setData2] = useState([])
    const [detailData, setDetailData] = useState([])
    const [selectDate, setSelectDate] = useState()
    const [selectTime, setSelectTime] = useState()
    const [strDate, setStrDate] = useState('')
    const [strTime, setStrTime] = useState('10:00')
    const [onClickTable, setOnClickTable] = useState(false)
    const [selectSymbol, setSelectSymbol] = useState('')
    let [excList, setExcList] = useState([])
    let localExcList = []
    // let excList = []

    const [stHour, stMinute] = [10, 0];
    const now = dayjs();
    const today = now.format('YYYY-MM-DD');
    const formatted_now = now.format('YYYY-MM-DD HH:mm:ss');
    const today_value = dayjs(today).subtract(0, 'day');
    const current_time = dayjs().set('hour', stHour).set('minute', stMinute);
    const MAX_ROW = 20;
    let counter = 0;
    let allData = [];
    let [excData, setExecData] = useState([]);
    let [uiExcBtn, setUIExcBtn] = useState([]);
    // let excData = [];

    const formatTime = (time) => {
        if (typeof time === 'object') {
            return `${dayjs(time).hour()}:${String(dayjs(time).minute()).padStart(2, '0')}`;
        }
        return time;
    };
    const formatDate = (date) => {
        if (typeof date === 'object') {
            return `${dayjs(date).year()}-${String(dayjs(date).month() + 1).padStart(2, '0')}-${String(dayjs(date).date()).padStart(2, '0')}`;
        }
        return date;
    };

    function sleep(ms) {
        return new Promise(res => setTimeout(res, ms));
    }

    const displayData2 = async () => {
        const start = 0
        const end = allData.length
        let counter = 0;
        for (let i = start; i < end; i++) {
            let symbol = allData[i][0]
            let indexList = excData.filter(e => e[0] == symbol)
            let isDisplay = true
            if (indexList.length > 0) {
                let gIndex = globalList.filter(e => e.indexOf(indexList[0][1]) != -1)
                // if (globalList.indexOf(indexList[0]) != -1) {
                if (gIndex.length > 0) {
                    isDisplay = false
                    // counterDisplay++;
                    continue;
                }
            }
            if (counter < (MAX_ROW / 2)) {
                setData1(e => {
                    e[counter] = allData[i]
                    return ([...e])
                })
            } else {
                setData2(e => {
                    let row2 = counter % 10
                    e[row2] = allData[i]
                    return ([...e])
                })
            }
            setIdxSelect(counter);
            await sleep(1000);
            if (counter == (MAX_ROW) - 1) {
                counter = 0
            } else {
                counter++;
            }
        }
    }

    const fetchData = async () => {
        const url = `${base_api_url}/his_day_trade?date=${strDate}&time=${strTime}`
        // console.log(url)
        const response = await __fetching_data__({ url: url, method: "GET", headers: { "xsrf-token": csrfToken } });
        // console.log('response', response)
        // setAllData(response.data)
        allData = response.data.data
        // excData = response.data.exc
        // allData[0] = ['AAI', 'BUY', 175, '927.1000', '2024.12.03 09:45.001']
        if (response.status) {
            // display3()
            displayData2()
            // const d1 = []
            // const d2 = []
            // for (let i = 0; i < response.data.length; i++) {
            // let counterDisplay = 0;
            // for (let i = 0; i < MAX_ROW; i++) {
            //     let symbol = allData[i][0]
            //     let indexList = excData.filter(e => e[0] == symbol)
            //     let isDisplay = true
            //     if (indexList.length > 0) {
            //         if (excList.indexOf(indexList[0][1]) != -1) {
            //             isDisplay = false
            //             counterDisplay++;
            //             continue;
            //         }
            //     }
            //     if (i < MAX_ROW / 2) {
            //         // d1.push(response.data[i])
            //         setData1(e => {
            //             return ([...e, allData[i]])
            //         })
            //     } else {
            //         // d2.push(response.data[i])
            //         setData2(e => {
            //             return ([...e, allData[i]])
            //         })
            //     }
            //     setIdxSelect(i-counterDisplay);
            //     await sleep(1000);
            // }
            // setTimeout(displayData2, 300)
        }
    }

    useEffect(() => {
        setSelectDate(today_value)
        setSelectTime(current_time)
        const fetchData = async () => {
            const url = `${base_api_url}/his_day_trade_exc`
            // console.log(url)
            const response = await __fetching_data__({ url: url, method: "GET", headers: { "xsrf-token": csrfToken } });
            console.log(response.data)
            setExecData(response.data)
            // excData = response.data

        }
        fetchData()
    }, [])


    const onClick = async (symbol) => {
        console.log('on click', symbol)
        setSelectSymbol(symbol)
        clearDiv()
        const response = await __fetching_data__({ url: `${base_api_url}/his_day_trade_detail?symbol=${symbol}`, method: "GET", headers: { "xsrf-token": csrfToken } });
        setDetailData(response.data)

        var value = {
            // user_input: 'AOT'
            user_input: symbol
        };
        const response_chatbot = await __fetching_data__({ url: `${base_api_url}/chat_bot_report`, method: "POST", body: JSON.stringify(value) });
        console.log(response_chatbot.data)

        setOnClickTable(true)

        const [chartLast, lineSeriesLast] = createChartTrade('last', response_chatbot.data.plot_data.price, 'Last', true)
        const [chartPredictedTrend, lineSeriesPredictedTrend] = createChartTrade('predicted_trend', response_chatbot.data.plot_data.flow_accum, 'Predicted Trend', false)
        const [chartShort, lineShort1, lineShort2] = createChartShort(response_chatbot.data.plot_data.short, response_chatbot.data.plot_data.outstanding_short)

        const response_money_input = await __fetching_data__({ url: `${base_api_url}/his_money_inout?symbol=24CS&startdate=2024-12-02`, method: "GET", headers: { "xsrf-token": csrfToken } });
        const [chartMoneyInout, lineSeriesMoneyInout] = createChartMoneyInout(response_money_input.data)

        chartLast.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
            chartLast.timeScale().setVisibleLogicalRange(timeRange);
            chartPredictedTrend.timeScale().setVisibleLogicalRange(timeRange);
            chartShort.timeScale().setVisibleLogicalRange(timeRange);
            chartMoneyInout.timeScale().setVisibleLogicalRange(timeRange);
        });
        chartPredictedTrend.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
            chartLast.timeScale().setVisibleLogicalRange(timeRange);
            chartPredictedTrend.timeScale().setVisibleLogicalRange(timeRange);
            chartShort.timeScale().setVisibleLogicalRange(timeRange);
            chartMoneyInout.timeScale().setVisibleLogicalRange(timeRange);
        });
        chartShort.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
            chartLast.timeScale().setVisibleLogicalRange(timeRange);
            chartPredictedTrend.timeScale().setVisibleLogicalRange(timeRange);
            chartShort.timeScale().setVisibleLogicalRange(timeRange);
            chartMoneyInout.timeScale().setVisibleLogicalRange(timeRange);
        });
        chartMoneyInout.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
            chartLast.timeScale().setVisibleLogicalRange(timeRange);
            chartPredictedTrend.timeScale().setVisibleLogicalRange(timeRange);
            chartShort.timeScale().setVisibleLogicalRange(timeRange);
            chartMoneyInout.timeScale().setVisibleLogicalRange(timeRange);
        });

        function getCrosshairDataPoint(series, param) {
            if (!param.time) {
                return null;
            }
            const dataPoint = param.seriesData.get(series);
            return dataPoint || null;
        }

        function syncCrosshair(chart, series, dataPoint) {
            try {
                if (dataPoint && dataPoint.value != null && dataPoint.time != null) {
                    // Set crosshair position only if dataPoint has valid value and time
                    chart.setCrosshairPosition(dataPoint.value, dataPoint.time, series);
                } else {
                    // Clear the crosshair if dataPoint is invalid or missing
                    chart.clearCrosshairPosition();
                }
            } catch (error) {
                // Suppress any unexpected errors
                console.error("Error in syncCrosshair: ", error);
            }
        }


        chartLast.subscribeCrosshairMove(param => {
            const dataPoint = getCrosshairDataPoint(lineSeriesLast, param);
            if (dataPoint) {
                syncCrosshair(chartPredictedTrend, lineSeriesPredictedTrend, dataPoint);
                syncCrosshair(chartShort, lineShort1, dataPoint);
                syncCrosshair(chartMoneyInout, lineSeriesMoneyInout, dataPoint);
            }
        });
        chartPredictedTrend.subscribeCrosshairMove(param => {
            const dataPoint = getCrosshairDataPoint(lineSeriesPredictedTrend, param);
            if (dataPoint) {
                syncCrosshair(chartLast, lineSeriesLast, dataPoint);
                syncCrosshair(chartShort, lineShort1, dataPoint);
                syncCrosshair(chartMoneyInout, lineSeriesMoneyInout, dataPoint);
            }
        });
        chartMoneyInout.subscribeCrosshairMove(param => {
            const dataPoint = getCrosshairDataPoint(lineSeriesMoneyInout, param);
            if (dataPoint) {
                syncCrosshair(chartLast, lineSeriesLast, dataPoint);
                syncCrosshair(chartShort, lineShort1, dataPoint);
                syncCrosshair(chartPredictedTrend, lineSeriesPredictedTrend, dataPoint);
            }
        });

    }

    function createChartTrade(id, data, txt, isLast) {
        const chartDiv = document.getElementById('chart-div')
        const filterContainer = document.createElement('div')
        filterContainer.setAttribute('id', id)
        // filterContainer.style.width = "100%"
        // isMobile ? filterContainer.style.height = "200px" : filterContainer.style.height = "250px"
        chartDiv.append(filterContainer)
        const chart = createChart(document.getElementById(id),
            {
                width: isLast ? 270 : 230,
                height: 200,
                timeScale: {
                    rightOffset: 5
                }
            }
        );
        chart.applyOptions({
            watermark: {
                visible: true,
                fontSize: 48,
                horzAlign: 'center',
                vertAlign: 'center',
                color: 'rgba(0, 0, 0, 0.1)',
                text: txt,
            },
        });
        // chartAnnotation(chart, txt)
        let lineSeries;
        if (isLast) {
            lineSeries = chart.addLineSeries({ title: '', color: 'green', lastValueVisible: true, priceLineVisible: false, priceScaleId: 'right', CrosshairMarkerVisible: false })
        } else {
            chart.applyOptions({
                crosshair: {
                    // mode: CrosshairMode.Normal,
                    // Horizontal crosshair line (showing Price in Label)
                    horzLine: {
                        labelVisible: false
                    },
                }
            })
            chart.priceScale('right').applyOptions({
                visible: false,
                textColor: '#F5F5F5',
            });
            chart.priceScale('left').applyOptions({
                visible: false,
                textColor: '#F5F5F5'

            });
            lineSeries = chart.addLineSeries({ lineStyle: 0, lineWidth: 3, lastValueVisible: false, priceLineVisible: false, });
        }
        lineSeries.setData(data);
        return [chart, lineSeries]
    }

    function createChartShort(data1, data2) {
        const chartDiv = document.getElementById('chart-div')
        const filterContainer = document.createElement('div')
        filterContainer.setAttribute('id', 'short')
        // filterContainer.style.width = "100%"
        // isMobile ? filterContainer.style.height = "200px" : filterContainer.style.height = "250px"
        chartDiv.append(filterContainer)
        const chart = createChart(document.getElementById('short'),
            {
                width: 325,
                height: 200,
                timeScale: {
                    rightOffset: 5
                }
            }
        );
        const sumVal = data1.reduce((acc, dict) => {
            return 'value' in dict ? acc + dict['value'] : acc;
        }, 0);

        if (sumVal === 0 || data1.length <= 1) {
            chart.applyOptions({
                watermark: {
                    visible: true,
                    fontSize: 14,
                    horzAlign: 'center',
                    vertAlign: 'center',
                    color: 'grey',
                    text: "%Short: None",
                },
            });
        } else {
            chart.applyOptions({
                watermark: {
                    visible: true,
                    fontSize: 48,
                    color: 'rgba(0, 0, 0, 0.1)',
                    text: '%Short',  // Dynamic text based on inputValue
                    horzAlign: 'center',
                    vertAlign: 'center',
                },
            });

            chart.priceScale('left').applyOptions({
                autoScale: true,
                scaleMargins: { top: 0.1, bottom: 0.1 }
            })
        }
        // chartAnnotation(chart, 'Last')
        const lineSeries1 = chart.addLineSeries({ color: 'green' });
        lineSeries1.setData(data1);
        for (let i = 0; i < data2.length; i++) {
            data2[i]['value'] = Number(data2[i]['value'])
        }

        const lineSeries2 = chart.addLineSeries({ color: 'red' });
        lineSeries2.setData(data2);
        return [chart, lineSeries1, lineSeries2]
    }

    function createChartMoneyInout(data) {
        const chartDiv = document.getElementById('chart-div')
        const filterContainer = document.createElement('div')
        filterContainer.setAttribute('id', 'money_inout')
        // filterContainer.style.width = "100%"
        // isMobile ? filterContainer.style.height = "200px" : filterContainer.style.height = "250px"
        chartDiv.append(filterContainer)

        data = data.map(e => ({ 'time': Date.parse(e['Date'] + ' ' + e['Time']), 'value': e['Money In/out'] }))
        const chart = createChart(document.getElementById('money_inout'),
            {
                width: 300,
                height: 200,
                timeScale: {
                    rightOffset: 5
                }
            }
        );
        chart.applyOptions({
            watermark: {
                visible: true,
                fontSize: 48,
                horzAlign: 'center',
                vertAlign: 'center',
                color: 'rgba(0, 0, 0, 0.1)',
                text: 'Flow',
            },
        });
        // chartAnnotation(chart, 'Flow')
        chart.applyOptions({
            crosshair: {
                // mode: CrosshairMode.Normal,
                // Horizontal crosshair line (showing Price in Label)
                horzLine: {
                    labelVisible: false
                },
            }
        })
        chart.priceScale('right').applyOptions({
            visible: false,
            textColor: '#F5F5F5',
        });
        chart.priceScale('left').applyOptions({
            visible: false,
            textColor: '#F5F5F5'

        });
        function compare(a, b) {
            if (a.time < b.time) {
                return -1;
            }
            if (a.time > b.time) {
                return 1;
            }
            return 0;
        }
        // data.sort(compare)
        console.log('dd', data)
        let lineSeries = chart.addLineSeries({ lineStyle: 0, lineWidth: 3, lastValueVisible: false, priceLineVisible: false, });
        lineSeries.setData(data);
        return [chart, lineSeries]
    }

    function chartAnnotation(chart, text) {
        chart.applyOptions({
            watermark: {
                visible: true,
                fontSize: 24,
                horzAlign: 'left',
                vertAlign: 'top',
                color: 'rgb(0,0,0)',
                text: text,
            },
            rightPriceScale: {
                visible: true,
            },
            leftPriceScale: {
                visible: true,
                //mode: PriceScaleMode.Normal,
                autoScale: false,  // Disable automatic scaling
                invertScale: false,
                alignLabels: false,
                minValue: -1,      // Set the minimum value
                maxValue: 1,       // Set the maximum value
            },
        });

    }

    const genTable = (dataTable, isCol2) => {
        return <div>
            <table className="customers">
                <thead>
                    <tr>
                        <th>SYMBOL</th>
                        <th>T</th>
                        <th>VOL</th>
                        <th>PRICE</th>
                        <th>TIME</th>
                    </tr>
                </thead>
                <tbody>
                    {dataTable.map((e, idx) => {
                        var row_data = e
                        let colCheck = isCol2 ? idx + 10 : idx
                        let cssDisplay = { cursor: 'pointer' }
                        if (colCheck == idxSelect) {
                            cssDisplay['backgroundColor'] = row_data[1] == 'BUY' ? 'lightgreen' : 'lightcoral'
                        }

                        return <tr onClick={() =>
                            onClick(row_data[0])
                        } style={cssDisplay}>
                            <td>
                                {row_data[0]}
                            </td>
                            <td>
                                {row_data[1] == 'BUY' ? 'B' : 'S'}
                            </td>
                            <td>
                                {Number(row_data[2]).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                            </td>
                            <td>
                                {Number(row_data[3]).toFixed(2).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                            </td>
                            <td>
                                {row_data[4].split(' ')[1]}
                            </td>
                        </tr>
                    })
                    }
                </tbody>
            </table>
        </div>
    }

    const onClickExc = (name) => {
        console.log('click')
        const idx = excList.indexOf(name)
        if (idx != -1) {
            setExcList(excList.filter(e => e != name))
            excList = excList.filter(e => e != name)
        } else {
            setExcList(e => ([...e, name]))
            excList = [...excList, name]
        }
        globalList = excList
        console.log(globalList)
    }

    const clearDiv = () => {
        document.getElementById('last').remove()
        document.getElementById('predicted_trend').remove()
        document.getElementById('short').remove()
        document.getElementById('money_inout').remove()
    }

    return (<div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '20px', marginRight: '20px' }}>
            <span>His Day Trade</span>

            <div>
                <div>
                    <div>Exc</div>
                    <div style={{ display: 'flex' }}>
                        <div>
                            <button onClick={() => onClickExc('S50')}
                                className={excList.indexOf('S50') == -1 ? 'btn_exc' : 'btn_exc_click'}>S50</button>
                        </div>
                        <div style={{ marginLeft: '8px' }}>
                            <button onClick={() => onClickExc('USD')}
                                className={excList.indexOf('USD') == -1 ? 'btn_exc' : 'btn_exc_click'}>USD </button>
                        </div>
                        <div style={{ marginLeft: '8px' }}>
                            <button
                                onClick={() => onClickExc('GO,GF,GF10')}
                                className={excList.indexOf('GO,GF,GF10') == -1 ? 'btn_exc' : 'btn_exc_click'}>GO, GF10, GF</button>
                        </div>
                        <div style={{ marginLeft: '8px' }}>
                            <button onClick={() => onClickExc('SVF')}
                                className={excList.indexOf('SVF') == -1 ? 'btn_exc' : 'btn_exc_click'}>SVF</button>
                        </div>
                        <div style={{ marginLeft: '8px' }}>
                            <button onClick={() => onClickExc('DW')}
                                className={excList.indexOf('DW') == -1 ? 'btn_exc' : 'btn_exc_click'}>DW</button>
                        </div>
                        <div style={{ marginLeft: '8px' }}>
                            <button onClick={() => onClickExc('SET')}
                                className={excList.indexOf('SET') == -1 ? 'btn_exc' : 'btn_exc_click'}>SET</button>
                        </div>
                        <div style={{ marginLeft: '8px' }}>
                            <button onClick={() => onClickExc('MAI')}
                                className={excList.indexOf('MAI') == -1 ? 'btn_exc' : 'btn_exc_click'}>MAI</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div style={{ display: 'flex', marginLeft: '50px' }}>
            <div style={{ marginTop: '10px' }}>
                <div>
                    <Grid item xs={4} md={3} lg={2} sx={{ flexGrow: 1 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                            <DatePicker
                                id="select_date"
                                name="select_date"
                                label="Select Date"
                                value={selectDate}
                                onChange={(e) => {
                                    setSelectDate(e)
                                    setStrDate(formatDate(e))
                                }}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                            <TimePicker
                                id="select_time"
                                name="select_time"
                                label="Select Time"
                                value={selectTime}
                                onChange={(e) => {
                                    setSelectTime(e)
                                    setStrTime(formatTime(e))
                                }}
                            />
                        </LocalizationProvider>
                        <Button variant="contained" onClick={() => fetchData()} style={{ height: '55px', marginLeft: '10px' }}>
                            Search
                        </Button>
                    </Grid>
                </div>
                <div style={{ display: 'flex', marginTop: '15px' }}>
                    {genTable(data1, false)}
                    {genTable(data2, true)}
                </div>
            </div>
            <BidAskComponent2 start_date={selectDate}
                start_time={selectTime}
                onClickSearch={onClickTable}
                symbol={selectSymbol}
                onPlotGraph={onClick}
                />
        </div>

        <div id="chart-div" style={{ display: 'flex', marginTop: '10px', marginLeft: '10px' }}>
            <div id='last'></div>
            <div style={{ marginLeft: '10px' }} id='predicted_trend'></div>
            <div style={{ marginLeft: '10px' }} id='short'></div>
            <div style={{ marginLeft: '10px' }} id="money_inout"></div>
        </div>

    </div>)
}