import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { tokens } from "../../theme";
import {
    useTheme, Box, Container, Grid, Card, TextField, Button, Table, TableHead, TableRow, TableCell, TableBody, Typography, Paper, useMediaQuery,
    FormControl, InputLabel, Select, MenuItem

} from '@mui/material';
import Header from "../../components/Header";
import LightweightChart from "../../components/LightweightChart";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";

import CardContent from '@mui/material/CardContent';

import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import StatBox from "../../components/StatBox";
import EmailIcon from "@mui/icons-material/Email";

import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';

import 'dayjs/locale/en-gb';
import 'dayjs/locale/es-us';
import { __fetching_data__ } from '../../components/api.js';

import dayjs from 'dayjs';

import { Formik } from 'formik'
import * as yup from "yup";


import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

import TableContainer from '@mui/material/TableContainer';
import Slider from '@mui/material/Slider';

import IconButton from '@mui/material/IconButton';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import ZoomInIcon from '@mui/icons-material/ZoomIn'; // ไอคอนสำหรับปุ่มแว่นขยาย

import Preloader from '../../components/Preloader';

import { createChart, CrosshairMode, PriceScaleMode } from 'lightweight-charts';

// import { useProSidebar } from "react-pro-sidebar"; 

const generateData = (data, date_columm = 'DateTime', field) => {
    const timeZoneOffset = 7 * 60 * 60 * 1000;
    const cdata = data.map(d => {
        let time = timeToTz(d[date_columm], 'Asia/Bangkok')

        const dd = new Date(d[date_columm])
        const year = dd.getFullYear();
        const month = String(dd.getMonth() + 1).padStart(2, '0'); // Add 1 because months are zero-indexed
        const day = String(dd.getDate()).padStart(2, '0');

        // let time2 = (new Date(d[date_columm]).getTime() + timeZoneOffset) / 1000
        let time2 = `${year}-${month}-${day}`;

        return { time: time2, value: parseFloat(d[field]) || 0 }
    });
    return cdata;
}
function timeToTz(originalTime, timeZone) {
    const zonedDate = new Date(new Date(originalTime).toLocaleString('en-US', { timeZone }));
    // const options = {
    //     timeZone: timeZone,
    //     hour: 'numeric',
    //     minute: 'numeric',
    //     second: 'numeric',
    //     hour12: false,
    // };
    // const formatter = new Intl.DateTimeFormat('en-US', options);
    return zonedDate.getTime() / 1000;
}

function syncCrosshair(chart, series, dataPoint) {
    if (dataPoint) {
        chart.setCrosshairPosition(dataPoint.value, dataPoint.time, series);
        return;
    }
    chart.clearCrosshairPosition();
}

const getCrosshairDataPoint = (series, param) => {
    if (!param.time) {
        return null;
    }
    const dataPoint = param.seriesData.get(series);
    return dataPoint || null;
};

function generateDarkColorsFromArrayLength(len) {
    const colors = [];

    // ฟังก์ชันสำหรับสร้างสีสุ่มที่สดใส
    function generateColor() {
        let r, g, b;

        // ค่าสุ่มสีให้มีความหลากหลายและสดใส
        r = Math.floor(Math.random() * 200); // สีแดง R มีค่าจาก 0-199
        g = Math.floor(Math.random() * 200); // สีเขียว G มีค่าจาก 0-199
        b = Math.floor(Math.random() * 200); // สีน้ำเงิน B มีค่าจาก 0-199

        // ตรวจสอบไม่ให้เป็นสีดำหรือสีที่ใกล้เคียง
        while (r === 0 && g === 0 && b === 0) {
            r = Math.floor(Math.random() * 200);
            g = Math.floor(Math.random() * 200);
            b = Math.floor(Math.random() * 200);
        }

        return `#${(1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1).toUpperCase()}`;
    }

    for (let i = 0; i < len; i++) {
        // colors.push(generateColor()); // เพิ่มสีเข้าไปใน Array
        colors.push(i % 2 === 0 ? "#006400" : "#000000");
    }

    return colors; // คืนค่าสีทั้งหมดที่สุ่มได้
}

const SectorRotation = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const csrfToken = useSelector((state) => state.csrfToken);
    const now = dayjs();
    const today = now.format('YYYY-MM-DD');

    // const isSmallScreen = useMediaQuery('(max-width:600px)');
    // const isLargeScreen = useMediaQuery('(min-width:1200px)');

    const chartRefs = useRef([]);
    const chartContainerRefs = useRef([]);
    const [seriesOptions, setSeriesOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState('SET');


    const sectors_set = ['AGRI', 'AUTO', 'BANK', 'COMM', 'CONMAT',
        'CONS', 'ENERG', 'ETRON', 'FASHION', 'FIN', 'FOOD', 'HELTH', 'HOME',
        'ICT', 'IMM', 'INSUR', 'MEDIA', 'MINE', 'PAPER', 'PERSON', 'PETRO', 'PF&REIT', 'PKG', 'PROF', 'PROP', 'REHABCO', 'STEEL',
        'TOURISM', 'TRANS'



    ];
    /*
     
        */
    const sectors_mai = ['AGRO', 'CONSUMP', 'FINCIAL', 'INDUS', 'PROPCON', 'RESOURC', 'SERVICE', 'TECH'];
    const sectors_set_and_mai = ['SET', 'MAI'];
    const [sectors, setSectors] = useState(sectors_set);
    // const sectors = sectors.slice(0, 1);
    const [sectorsChunks, setSectorsChunks] = useState([]);
    const itemsPerRow = window.innerWidth > 1366 ? 6 : 5;
    const [chartData, setChartData] = useState(sectors.map(() => []));

    const [loading, setLoading] = useState(true);
    const [isReady, setIsReady] = useState(false);
    // const [status, setStatus] = useState(false);

    let start_td = 0;

    // useEffect(() => {
    //     setIsReady(false);
    //     //     const timeout = setTimeout(() => {
    //     //         setIsReady(true);
    //     //     }, 100);
    //     //     return () => clearTimeout(timeout);
    // }, [chartData]);

    const commonOptions = {
        width: 400,
        height: 200,
        crosshair: {
            mode: 0
        },
        timeScale: {
            visible: true,
        },
        localization: {
            locale: "en-US"
        },
        layout: {
            background: {
                type: 'solid',
                color: '#f5f5f5',
            },
        },
        // rightPriceScale: {
        //     visible: false,
        //     scaleMargins: {
        //         top: 0.3,
        //         bottom: 0.2,
        //     },
        //     borderVisible: true,
        //     drawTicks: false,
        // },

        // lastValueVisible: false,
        // priceFormat: {
        //     type: 'price',
        // },

        rightPriceScale: {
            visible: false,
        },
        leftPriceScale: {
            visible: false,
            // mode: PriceScaleMode.Normal,
            // autoScale: false,  // Disable automatic scaling
            // invertScale: false,
            // alignLabels: false,
            // minValue: -1,      // Set the minimum value
            // maxValue: 1,       // Set the maximum value
        },
    };

    const content = document.getElementsByClassName('content')[0];
    const resizeHandler = () => {
        chartRefs.current.forEach((chartRef, index) => {
            if (chartRef && chartRef.getChartRef) {
                const chart = chartRef.getChartRef();
                try {
                    const container = chartContainerRefs.current[index]
                    if (container) {
                        chart.resize(container.clientWidth, container.clientHeight);
                    }
                } catch (error) {
                    console.error('Error resizing chart:', error);
                }
            }
        });
    };

    useEffect(() => {
        if (content) {
            const resizeObserver = new ResizeObserver(resizeHandler);
            resizeObserver.observe(content);
            return () => {
                if (resizeObserver) {
                    resizeObserver.disconnect();
                }
            };
        }
    }, [content]);

    const fetchData = async () => {
        setIsReady(false);
        try {
            const responses = await Promise.all(
                sectors.map((sector) => {

                    let paramsQuery = {
                        market: selectedOption,
                        sector: sector,
                    };
                    // return fetch(`${window.config.base_api_url}/sector_rotation?${new URLSearchParams(paramsQuery)}`).then((res) => res.json())
                    return fetch(`${window.config.base_api_url}/sector_rotation?${new URLSearchParams(paramsQuery)}`)
                        .then((res) => {
                            if (!res.ok) {
                                return [];
                            }
                            return res.json().then((data) => {
                                return data.data || [];
                            });
                        })
                        .catch(() => { return []; });
                })
            );

            const updatedData = responses
                .map((data) => data || [])
                .filter((data) => data.length >= 1);
            const colors = generateDarkColorsFromArrayLength(itemsPerRow);

            const line_option = colors.map((color, index) => ({
                ...seriesOptions[index],
                'color': color,
                'lineWidth': 3
            }));
            // console.log(maxLength, updatedData, responses)
            setSeriesOptions(line_option);
            setChartData(updatedData);
            setSectorsChunks(Array.from({ length: Math.ceil(updatedData.length / itemsPerRow) }, (_, i) => updatedData.slice(i * itemsPerRow, i * itemsPerRow + itemsPerRow)));

        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [selectedOption]);

    useEffect(() => {

    }, [chartData]);

    useEffect(() => {
        // setIsReady(false);

        console.log("Chart Length:", chartRefs.current.length)
        chartRefs.current.forEach((chartRef, index) => {
            if (chartRef && chartRef.getChartRef) {
                // console.log(`Chart ${index + 1} instance:`, chartRef);
                const chart = chartRef.getChartRef();
                const series = chartRef.getSeriesRefs();

                const container = chartContainerRefs.current[index];
                const width = container.clientWidth;
                const height = container.clientHeight;
                chart.resize(width, height);

                chart.subscribeCrosshairMove(param => {
                    const dataPoint = getCrosshairDataPoint(series[0], param);
                    chartRefs.current.forEach((_, otherIndex) => {
                        if (otherIndex !== index) {
                            if (chartRefs.current && chartRefs.current[otherIndex] && chartRefs.current[otherIndex].getChartRef) {
                                const otherChart = chartRefs.current[otherIndex].getChartRef();
                                const otherSeries = chartRefs.current[otherIndex].getSeriesRefs();
                                otherSeries.forEach(otherSeries => {
                                    syncCrosshair(otherChart, otherSeries, dataPoint);
                                });
                            }
                        }
                    });
                });

                chart.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
                    chartRefs.current.forEach((_, otherIndex) => {
                        if (otherIndex !== index) {
                            if (chartRefs.current && chartRefs.current[otherIndex] && chartRefs.current[otherIndex].getChartRef) {
                                const otherChart = chartRefs.current[otherIndex].getChartRef();
                                otherChart.timeScale().setVisibleLogicalRange(timeRange);
                            }
                        }
                    });
                });

                const seriesData = series[0].data();
                const lastTime = new Date(seriesData[seriesData.length - 1].time).getTime();
                const firstTime = new Date(seriesData[0].time).getTime();

                const visibleRangeSize = 50//1 * 24 * 60 * 60 * 1000;
                const from = Math.max(lastTime - visibleRangeSize, firstTime);
                const to = lastTime;

                chart.timeScale().setVisibleLogicalRange({ from, to });
                chart.timeScale().scrollToPosition(2);
                // chart.timeScale().fitContent();
            }
        });
        const timeout_ready = setTimeout(() => {
            setIsReady(true);
        }, 100);





        return () => {
            // chartRefs.current.forEach(chartRef => {
            //     if (chartRef && chartRef.getChartRef) {
            //         const chart = chartRef.getChartRef();
            //         // chart.unsubscribeAll();
            //     }
            // });
            clearInterval(timeout_ready);
        };
    }, [chartData, selectedOption]); // chartContainerRefs.current, chartRefs.current, commonOptions, 



    useEffect(() => {
        const fetchDataRealtime = async () => {
            console.log("Ready RealTime...")

            try {

                // chartRefs.current.forEach((chartRef, index) => {
                for (const [index, chartRef] of chartRefs.current.entries()) {
                    if (chartRef && chartRef.getChartRef) {
                        let paramsQuery = {
                            market: selectedOption,
                            sector: sectors[index],
                        };
                        // let url = `${window.config.base_api_url}/sector_rotation/realtime?${new URLSearchParams(paramsQuery)}`
                        // const response = await fetch(url);
                        // const data = await response.json();

                        // const series = chartRef.getSeriesRefs();
                        // series.forEach((s, seriesIndex) => {
                        //     if (data.data.length > 0) {
                        //         let x = generateData(data.data, 'DateTime', 'cumsum');

                        //         const newDataPoint = {
                        //             //                             time: Math.floor(Date.now() / 1000),//data[seriesIndex].timestamp,
                        //             //                             value: Math.random() * 100 //data[seriesIndex].value,
                        //             time: x[0]?.time,
                        //             value: x[0]?.value
                        //         };

                        //         s.update(newDataPoint);
                        //     }

                        // });
                    }
                    // });
                }
            } catch (error) {

            } finally {
            }


        };
        //     const responses = await Promise.all(
        //         sectors.map((sector) => {

        //             let paramsQuery = {
        //                 market: selectedOption,
        //                 sector: sector,
        //             };
        //             // return fetch(`${window.config.base_api_url}/sector_rotation?${new URLSearchParams(paramsQuery)}`).then((res) => res.json())
        //             return fetch(`${window.config.base_api_url}/sector_rotation/realtime?${new URLSearchParams(paramsQuery)}`)
        //                 .then((res) => {
        //                     if (!res.ok) {
        //                         return [];
        //                     }
        //                     return res.json().then((data) => {
        //                         return data.data || [];
        //                     });
        //                 })
        //                 .catch(() => { return []; });
        //         })
        //     );


        //     console.log(responses)
        //     // console.log(isReady)
        if (isReady) {
            const interval = setInterval(fetchDataRealtime, 1000);
            return () => clearInterval(interval);
        }
    }, [isReady]);


    const moveToLastData = () => {
        chartRefs.current.forEach((chartWrapper, index) => {
            const chart = chartWrapper.getChartRef();
            const series = chartWrapper.getSeriesRefs();

            if (series.length > 0) {
                const seriesData = series[0].data();

                if (seriesData.length > 0) {
                    const lastTime = new Date(seriesData[seriesData.length - 1].time).getTime();
                    const firstTime = new Date(seriesData[0].time).getTime();

                    const visibleRangeSize = 50//1 * 24 * 60 * 60 * 1000;
                    const from = Math.max(lastTime - visibleRangeSize, firstTime);
                    const to = lastTime;

                    chart.timeScale().setVisibleLogicalRange({ from, to });
                    chart.timeScale().scrollToPosition(2);
                    // chart.timeScale().fitContent();
                }
            }
        });
    };

    const moveToFirstData = () => {
        chartRefs.current.forEach((chartWrapper) => {
            const chart = chartWrapper.getChartRef();
            const series = chartWrapper.getSeriesRefs();

            if (series.length > 0) {
                const seriesData = series[0].data();

                if (seriesData.length > 0) {
                    const firstTime = new Date(seriesData[0].time).getTime();
                    const visibleRangeSize = 7 * 24 * 60 * 60 * 1000;


                    const from = firstTime;
                    const to = from + visibleRangeSize;

                    chart.timeScale().setVisibleLogicalRange({
                        from: 1,
                        to: 100,
                    });

                    // chart.timeScale().setVisibleLogicalRange({ from, to });
                    // chart.timeScale().scrollToPosition(-Number.MAX_SAFE_INTEGER, false);
                    chart.timeScale().scrollToPosition(-seriesData.length + 95);
                    // chart.timeScale().scrollToPosition(2);
                    // chart.timeScale().fitContent();




                }
            }
        });
    };






    const handleChange = (event) => {
        setLoading(true);
        const value = event.target.value
        chartRefs.current = [];
        chartContainerRefs.current = [];

        setTimeout(() => {
            setSelectedOption(value);
            setSectors(value === 'SET' ? sectors_set : value === 'MAI' ? sectors_mai : sectors_set_and_mai);
        }, 500);
    };

    return (
        <Box m="20px">
            <Preloader open={loading} />
            {/* <Box display="flex" justifyContent={{ md: 'center', lg: 'center' }} alignItems="center" p="0" > */}
            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                <Box
                    sx={{
                        display: "flex",
                        flex: "1",
                        justifyContent: "center",
                        ml: "1rem"
                    }}>
                    <Box mb="30px" width={"100%"}>
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small" >
                            <InputLabel id="dropdown-label">Select Market</InputLabel>
                            <Select
                                labelId="dropdown-label"
                                value={selectedOption}
                                onChange={handleChange}
                                label="Select Market"
                            >
                                <MenuItem value="SET">SET</MenuItem>
                                <MenuItem value="MAI">MAI</MenuItem>
                                <MenuItem value="SET_MAI">SET&MAI</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
                <Box flex="1" display="flex" justifyContent="center">
                    <Header title="Sector Rotation" subtitle="" />
                </Box>
                <Box flex="1" display="flex" justifyContent="end"
                    sx={{
                        mr: "1rem"
                    }}>
                    <Box mb="30px">
                        <Button
                            onClick={moveToFirstData}
                            sx={{
                                variant: "h2",
                                backgroundColor: colors.greenAccent[700],
                                color: colors.grey[100],
                                // fontSize: "14px",
                                fontWeight: "bold",
                                // padding: "10px 20px",
                                m: "0 0 5px 0"
                            }}
                        >
                            First Data
                        </Button>
                    </Box>
                    <Box mb="30px" ml="3px">
                        <Button
                            onClick={moveToLastData}
                            sx={{
                                variant: "h2",
                                backgroundColor: colors.blueAccent[700],
                                color: colors.grey[100],
                                // fontSize: "14px",
                                fontWeight: "bold",
                                // padding: "10px 20px",
                                m: "0 0 5px 0"
                            }}
                        >
                            Last Data
                        </Button>
                    </Box>
                </Box>
            </Box>
            <Box p="0" display="Box"
                sx={{
                    height: {
                        xs: '50vh', // height for extra-small screens
                        sm: '60vh', // height for small screens
                        md: '90vh', // height for medium screens
                        lg: '90vh', // height for large screens
                        xl: '100vh', // height for extra-large screens
                    },
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '-2.3rem',
                    // width: '100%'
                }}>
                <Container maxWidth={false} sx={{ flex: 1, display: 'flex', flexDirection: 'column', }}>
                    <Paper
                        sx={{
                            flex: 1,
                            overflowY: 'auto',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            p: 2,
                            maxHeight: {
                                // xs: '50vh', // height for extra-small screens
                                // sm: '60vh', // height for small screens
                                // xs: '90vh',
                                // sm: '90vh',
                                // md: '90vh', // height for medium screens
                                // lg: '90vh', // height for large screens
                                // xl: '100vh', // height for extra-large screens
                            },

                        }}
                    >

                        {!loading && sectorsChunks.map((_r, row) => {
                            const currentTd = start_td;
                            start_td += itemsPerRow;
                            // console.log("Row :", row)
                            const filledData = chartData.slice(currentTd, currentTd + itemsPerRow)

                            return (
                                <Grid container direction="row" spacing={0} key={"chart_row" + row} wrap="wrap"
                                    sx={{
                                        width: '100%',
                                        margin: 0,
                                        padding: 0,
                                        marginTop: '5px',

                                    }}

                                >
                                    {filledData.map((data, index) => {
                                        const dataIndex = currentTd + index;
                                        // { console.log("td :", chartData[dataIndex]) }
                                        return (
                                            <Grid item key={"chart_number" + dataIndex}
                                                xs={12} md={12 / itemsPerRow}
                                                sx={{
                                                    // width: '100%',
                                                    // height: '50%',
                                                    // height: 'calc(100% * 9 / 16)',
                                                    padding: 0,
                                                    margin: 0,
                                                    // backgroundColor: row === 0 ? 'red' : 'blue',
                                                    borderLeft: '0.5px solid #000',
                                                    borderBottom: '0.5px solid #000',
                                                    borderRadius: '4px',
                                                    paddingX: '1px',
                                                    position: 'relative',
                                                }}

                                            >
                                                {/* <Typography>{dataIndex}</Typography> */}
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        padding: 0,
                                                        margin: 0,
                                                        // maxHeight: 'calc(100% * 9 / 16)',
                                                        height: '8rem',
                                                        position: 'relative',

                                                    }}
                                                    ref={(el) => (chartContainerRefs.current[dataIndex] = el)}
                                                >
                                                    <LightweightChart
                                                        ref={(el) => (chartRefs.current[dataIndex] = el)}
                                                        options={{ ...commonOptions, fontSize: 32 }}
                                                        seriesOptions={[{
                                                            options: {
                                                                color: seriesOptions[index].color || 'black',
                                                                lineWidth: seriesOptions[index].lineWidth || 1,
                                                                priceLineVisible: false,
                                                            },
                                                            data: generateData(chartData[dataIndex], 'DateTime', 'cumsum'),
                                                        }]}
                                                        title={sectors[dataIndex]}
                                                    />

                                                    <Box
                                                        sx={{
                                                            position: 'absolute',
                                                            top: 2,
                                                            right: 2,
                                                            zIndex: 10,
                                                        }}
                                                    >
                                                        <IconButton
                                                            onClick={() => {
                                                                // chart.subscribeClick((param) => {
                                                                //     if (param.time && param.point) {
                                                                //         // ลิงก์ที่คุณต้องการเปิด
                                                                //         // const link = `https://example.com/data?time=${param.time}`;

                                                                //         // const link = `/full-chart?time=${param.time}`;

                                                                const allData = generateData(chartData[index], 'DateTime', 'cumsum');//chartData[index];
                                                                // const jsonString = JSON.stringify(allData);
                                                                //         // const base64Data = btoa(jsonString);
                                                                localStorage.setItem("chartData", JSON.stringify(allData));
                                                                localStorage.setItem("chartTitle", sectors[index]);

                                                                //         // const link = `/full-chart?data=${base64Data}`;

                                                                const link = `/full-chart`;
                                                                window.open(link, "_blank");
                                                                //     }
                                                                // });
                                                            }}
                                                            sx={{
                                                                width: 25,
                                                                height: 25,
                                                                padding: 0,
                                                                backgroundColor: 'transparent',
                                                                border: 'none',//'1px solid #ddd',
                                                                boxShadow: 'none',//'0 2px 4px rgba(0, 0, 0, 0.1)',
                                                                '&:hover': {
                                                                    backgroundColor: 'transparent',//'#f5f5f5',
                                                                },
                                                            }}
                                                        >
                                                            <ZoomInIcon />
                                                        </IconButton>
                                                    </Box>

                                                </Box>

                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            );
                        })}









                    </Paper >
                </Container>
            </Box>
        </Box >
    );
}



export default SectorRotation;