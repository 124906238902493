import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ColorType, createChart, Rectangle } from 'lightweight-charts';
import { useMediaQuery, useTheme } from '@mui/material';
import DataTable from "./table";
import BasicTable from "./table";
import EnhancedTable from "./table";
import { __fetching_data__ } from '../../components/api.js';
import { useState } from "react";
import { CircularProgress } from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import IconButton from "@mui/material/IconButton";

import './index.css';

const base_api_url =
    process.env.NODE_ENV === "production"
        ? window.location.protocol === "https:"
            ? process.env.REACT_APP_API_HTTPS_URL
            : process.env.REACT_APP_API_HTTP_URL
        : "/application";

function DRPage() {
    const theme = useTheme();
    const csrfToken = useSelector((state) => state.csrfToken);
    const [symbol, setSymbol] = useState([])
    let [data, setData] = useState([])
    const [abc, setAbc] = useState(0)
    const [isLoad, setLoad] = useState(false)

    const [collapsedTables, setCollapsedTables] = useState({});
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('1024'));

    const toggleTable = (region) => {
        setCollapsedTables((prev) => ({
            ...prev,
            [region]: !prev[region], // Toggle collapsed state for the region
        }));
    };

    useEffect(() => {
        callAPI()
    }, [])

    useEffect(() => {
        if (!isMobile || !isTablet) {
            setCollapsedTables({});
        }

    }, [isMobile, isTablet]);

    async function callAPI() {
        const response = await __fetching_data__({ url: `${base_api_url}/dr_list_symbol`, method: "GET", headers: { "xsrf-token": csrfToken } });
        console.log('response', response)
        if (response.status) {
            // data = response.data
            setData(response.data)
            // console.log(data)
            // setAbc(20)
        }
    }

    function bgChart(name, data, chartName, lineColor) {
        const chartDiv = document.getElementById('chart-div')
        const filterContainer = document.createElement('div')
        filterContainer.setAttribute('id', name)
        filterContainer.style.width = "100%"
        isMobile ? filterContainer.style.height = "200px" : filterContainer.style.height = "250px"
        chartDiv.append(filterContainer)
        // filterContainer.setAttribute('style', 'position: relative; overflow: visible; z-index:3;')
        const chart = createChart(document.getElementById(name),
            {
                width: filterContainer.clientWidth,
                height: filterContainer.clientHeight,
                timeScale: {
                    rightOffset: 5
                }
            }
        );
        chart.applyOptions({
            watermark: {
                visible: true,
                fontSize: 48,
                horzAlign: 'center',
                vertAlign: 'center',
                color: 'rgba(0, 0, 0, 0.1)',
                text: `${chartName}`,
            },
        });

        function resizeChart() {
            const containerWidth = filterContainer.clientWidth;
            const containerHeight = isMobile ? 200 : 250;
            chart.resize(containerWidth, containerHeight);
        }

        const resizeObserver = new ResizeObserver(() => {
            resizeChart();
        });

        resizeObserver.observe(filterContainer);

        window.addEventListener("resize", () => {
            const containerWidth = filterContainer.clientWidth;
            const containerHeight = isMobile ? 200 : 250;
            chart.resize(containerWidth, containerHeight);
        });
        resizeChart();

        const lineSeries = chart.addLineSeries({ color: lineColor });
        lineSeries.setData(data);
        return [chart, lineSeries]
    }


    const inList = (data, lst) => {
        for (let i = 0; i < lst.length; i++) {
            if (data == lst[i]['time']) {
                return i
            }
        }
        return undefined
    }

    const clearDiv = () => {
        document.getElementById('chart1').remove()
        document.getElementById('chart2').remove()
        document.getElementById('chart3').remove()
    }

    const onClickTable = async (id) => {
        setLoad(true)
        const response = await __fetching_data__({ url: `${base_api_url}/dr_detail_symbol?id=${id}`, method: "GET", headers: { "xsrf-token": csrfToken } });
        console.log('response click', response)
        const data = []
        if (response.status) {
            for (let i = 0; i < response.data.chart3.length; i++) {
                const chartData = response.data.chart3[i]
                // const dateData = chartData['date'].split(' ')[0]
                const dateTime = chartData['date'].includes(' ') 
                        ? chartData['date'].split(' ')[0] 
                        : chartData['date'].split('T')[0];
                let dateData;

                if (typeof chartData['date'] === 'string') { // หากวันที่เป็น String (เช่น "2024-11-19T00:00:00")
                    // dateData = chartData['date'].split('T')[0].replaceAll('.', '-')
                    // const dateTime = chartData['date'].split(' ')[0]; // แยกเฉพาะส่วนวันที่
                    dateData = dateTime.replaceAll('.', '-');
                    // console.log(dateData)
                } else if (chartData['date'] instanceof Date) { // หากวันที่เป็น date object
                    
                    const year = chartData['date'].getFullYear()
                    const month = String(chartData['date'].getMonth() + 1).padStart(2, '0')
                    const day = String(chartData['date'].getDate()).padStart(2, '0')
                    dateData = `${year}-${month}-${day}`
                    // console.log(dateData)
                } else {
                    continue;
                }

                // const dateData =
                //     typeof chartData['date'] === 'string'
                //         ? chartData['date'].split(' ')[0].replaceAll('.', '-')
                //         : chartData['date'] instanceof Date
                //         ? `${chartData['date'].getFullYear()}-${String(chartData['date'].getMonth() + 1).padStart(2, '0')}-${String(chartData['date'].getDate()).padStart(2, '0')}`
                //         : null;
                // if (!dateData) continue;

                // const dateData = chartData['date'].split(' ')[0].replaceAll('.', '-')

                let dataInList = inList(dateData, data)
                if (dataInList != undefined) {
                    data[dataInList]['value'] = Number(chartData['value'])
                } else {
                    data.push({ 'time': dateData, 'value': Number(chartData['value']) })
                }
            }
            clearDiv()
            function compare(a, b) {
                if (a.time < b.time) {
                    return -1;
                }
                if (a.time > b.time) {
                    return 1;
                }
                return 0;
            }
            data.sort(compare)
            response.data.chart1.sort(compare)
            response.data.chart2.sort(compare)
            var [chart1, mainSeries1] = bgChart('chart1', response.data.chart1, response.data.name.chart1, 'blue')
            var [chart2, mainSeries2] = bgChart('chart2', response.data.chart2, response.data.name.chart2, 'black')
            var [chart3, mainSeries3] = bgChart('chart3', data, response.data.name.chart3, 'green')
            // var [chart1, mainSeries1] = bgChart('chart1', response.data.chart1, response.data.name.chart1, 'blue')
            // var [chart2, mainSeries2] = bgChart('chart2', data, response.data.name.chart2, 'black')
            // var [chart3, mainSeries3] = bgChart('chart3', data, response.data.name.chart3, 'green')
            // var [chart3, mainSeries3] = bgChart('chart3',  response.data.chart1, response.data.name.chart3, 'green')
            chart1.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
                chart1.timeScale().setVisibleLogicalRange(timeRange);
                chart2.timeScale().setVisibleLogicalRange(timeRange);
                chart3.timeScale().setVisibleLogicalRange(timeRange);
            });

            chart2.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
                chart1.timeScale().setVisibleLogicalRange(timeRange);
                chart2.timeScale().setVisibleLogicalRange(timeRange);
                chart3.timeScale().setVisibleLogicalRange(timeRange);
            });

            chart3.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
                chart1.timeScale().setVisibleLogicalRange(timeRange);
                chart2.timeScale().setVisibleLogicalRange(timeRange);
                chart3.timeScale().setVisibleLogicalRange(timeRange);
            });

            function getCrosshairDataPoint(series, param) {
                if (!param.time) {
                    return null;
                }
                const dataPoint = param.seriesData.get(series);
                return dataPoint || null;
            }

            // function syncCrosshair(chart, series, dataPoint) {
            //     if (dataPoint) {
            //         chart.setCrosshairPosition(dataPoint.value, dataPoint.time, series);
            //         return;
            //     }
            //     chart.clearCrosshairPosition();
            // }

            function syncCrosshair(chart, series, dataPoint) {
                try {
                    if (dataPoint && dataPoint.value != null && dataPoint.time != null) {
                        // Set crosshair position only if dataPoint has valid value and time
                        chart.setCrosshairPosition(dataPoint.value, dataPoint.time, series);
                    } else {
                        // Clear the crosshair if dataPoint is invalid or missing
                        chart.clearCrosshairPosition();
                    }
                } catch (error) {
                    // Suppress any unexpected errors
                    console.error("Error in syncCrosshair: ", error);
                }
            }
            

            chart1.subscribeCrosshairMove(param => {
                const dataPoint = getCrosshairDataPoint(mainSeries1, param);
                // syncCrosshair(chart2, mainSeries2, dataPoint);
                // syncCrosshair(chart3, mainSeries3, dataPoint);
                if (dataPoint) {
                    syncCrosshair(chart2, mainSeries2, dataPoint);
                    syncCrosshair(chart3, mainSeries3, dataPoint);
                }
            });
            chart2.subscribeCrosshairMove(param => {
                const dataPoint = getCrosshairDataPoint(mainSeries2, param);
                syncCrosshair(chart1, mainSeries1, dataPoint);
                syncCrosshair(chart3, mainSeries3, dataPoint);
            });
            chart3.subscribeCrosshairMove(param => {
                const dataPoint = getCrosshairDataPoint(mainSeries3, param);
                syncCrosshair(chart1, mainSeries1, dataPoint);
                syncCrosshair(chart2, mainSeries2, dataPoint);
                // syncCrosshair(chart3, mainSeries3, dataPoint);
            });
            setLoad(false)
        }
    }
    // 
    

    const categorizeByStaticId = (data) => {
        const regions = {
            "USA": [215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 229, 230, 231, 232, 233, 234],
            "Europe": [171, 179, 182, 190, 191, 197, 199],
            "ETC": [181],
        };
    
        return Object.fromEntries(
            Object.entries(regions).map(([region, ids]) => [
                region,
                data.filter((item) => ids.includes(item[0])),
            ])
        );
    };

    const categorizeByStaticId2 = (data) => {
        const regions = {
            "Japan": [187, 192, 193, 196, 202, 203, 209, 210],
            "Singapore": [177, 188, 200, 201, 206, 208, 211, 212],
            "Taiwan": [228, 237, 238]
        };
    
        return Object.fromEntries(
            Object.entries(regions).map(([region, ids]) => [
                region,
                data.filter((item) => ids.includes(item[0])),
            ])
        );
    };
    const categorizeByStaticId3 = (data) => {
        const regions = {
            "China": [172, 173, 174, 175, 176, 183, 184, 185, 186, 189, 194, 195, 198, 204, 205, 207, 214],
            "Vietnam": [178, 236, 180, 213]

        };
    
        return Object.fromEntries(
            Object.entries(regions).map(([region, ids]) => [
                region,
                data.filter((item) => ids.includes(item[0])),
            ])
        );
    };

    const groupedDataLeft = categorizeByStaticId(data);
    const groupedDataRight = categorizeByStaticId2(data);
    const groupedDataRight2 = categorizeByStaticId3(data);
    // 
    return (<div>
        <div className="main-content">
            
            {(!isMobile || !isTablet) && <div className="left-block">
                {Object.entries(groupedDataLeft).map(([region, items]) => (
                    <div key={region} style={{width: "200px"}} className="dr-n-table">
                        <h3>{region}</h3>
                        <table style={{ marginBottom: "5px", width: "200px" }}>
                            <thead>
                                <tr>
                                    <th>DR/DRx</th>
                                    <th>TradingView</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map((item) => (
                                    <tr key={item[0]} onClick={() => onClickTable(item[0])} style={{ cursor: "pointer" }}>
                                        <td>{item[1]}</td>
                                        <td>
                                            <div className="tooltip">{item[2]}
                                                <span className="tooltiptext">{item[4]}</span>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ))}
            </div>}
            {isLoad && <div style={{ zIndex: 100, position: 'absolute', top: '30%', left: '60%' }}><CircularProgress /></div>}
            <div id="chart-div" className="all-chart">
                <div><div id="chart1"></div></div>
                <div><div id="chart2"></div></div>
                <div><div id="chart3"></div></div>
            </div>
            {/* {!isLoad && <div id="chart-div" style={{ width: '850px', marginLeft: '5px' }}>
                <div><div id="chart1"></div></div>
                <div><div id="chart2"></div></div>
                <div><div id="chart3"></div></div>
            </div>} */}
            {/*  */}
            <div className="all-contents">
                <div className="big-block">
                    <div className="big-block-header">
                        <h3 style={{marginBottom: "10px", paddingBottom: "5px"}}>Asia</h3>
                    </div>
                    <div className="right-block">
                        <div className="each-column" style={{marginRight: "10px"}}>
                            {Object.entries(groupedDataRight).map(([region, items]) => (
                                <div key={region} style={{width: "200px"}} className="dr-n-table">
                                    {/* <h3>{region}</h3> */}
                                    {isMobile || isTablet ? (
                                            <h3>
                                                {region}{" "}
                                                <IconButton
                                                    onClick={() => toggleTable(region)}
                                                    style={{color: "white", cursor: "pointer" }}
                                                >
                                                    {collapsedTables[region] ? (
                                                        <ExpandMoreIcon />
                                                    ) : (
                                                        <ExpandLessIcon />
                                                    )}
                                                </IconButton>
                                            </h3>
                                        ) : (
                                            <h3>{region}</h3>
                                        )}
                                        {!collapsedTables[region] && (
                                    <table style={{ marginBottom: "5px", width: "200px" }}>
                                        <thead>
                                            <tr>
                                                <th>DR/DRx</th>
                                                <th>TradingView</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {items.map((item) => (
                                                <tr key={item[0]} onClick={() => onClickTable(item[0])} style={{ cursor: "pointer" }}>
                                                    <td>{item[1]}</td>
                                                    <td>
                                                        <div className="tooltip">{item[2]}
                                                            <span className="tooltiptext">{item[4]}</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>)}
                                </div>
                            ))}
                        </div>
                        <div className="each-column">
                            {Object.entries(groupedDataRight2).map(([region, items]) => (
                                <div key={region} style={{width: "200px"}} className="dr-n-table">
                                    {/* <h3>{region}</h3> */}
                                    {isMobile || isTablet ? (
                                            <h3>
                                                {region}{" "}
                                                <IconButton
                                                    onClick={() => toggleTable(region)}
                                                    style={{color: "white", cursor: "pointer" }}
                                                >
                                                    {collapsedTables[region] ? (
                                                        <ExpandMoreIcon />
                                                    ) : (
                                                        <ExpandLessIcon />
                                                    )}
                                                </IconButton>
                                            </h3>
                                        ) : (
                                            <h3>{region}</h3>
                                        )}
                                        {!collapsedTables[region] && (
                                    <table style={{ marginBottom: "5px", width: "200px" }}>
                                        <thead>
                                            <tr>
                                                <th>DR/DRx</th>
                                                <th>TradingView</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {items.map((item) => (
                                                <tr key={item[0]} onClick={() => onClickTable(item[0])} style={{ cursor: "pointer" }}>
                                                    <td>{item[1]}</td>
                                                    <td>
                                                        <div className="tooltip">{item[2]}
                                                            <span className="tooltiptext">{item[4]}</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>)}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {(isMobile || isTablet) && <div className="right-content2">
                        <div className="big-block-header">
                            <h3 style={{ marginBottom: "10px", paddingBottom: "5px", color: "white" }}>America / EU</h3>
                        </div>
                        <div className="each-column">
                            {Object.entries(groupedDataLeft).map(([region, items]) => (
                                <div key={region} style={{ width: "200px" }} className="dr-n-table">
                                    {isMobile || isTablet ? (
                                            <h3>
                                                {region}{" "}
                                                <IconButton
                                                    onClick={() => toggleTable(region)}
                                                    style={{color: "white", cursor: "pointer" }}
                                                >
                                                    {collapsedTables[region] ? (
                                                        <ExpandMoreIcon />
                                                    ) : (
                                                        <ExpandLessIcon />
                                                    )}
                                                </IconButton>
                                            </h3>
                                        ) : (
                                            <h3>{region}</h3>
                                        )}
                                    {!collapsedTables[region] && (
                                        <table style={{ marginBottom: "10px", width: "200px" }}>
                                            <thead>
                                                <tr>
                                                    <th>DR/DRx</th>
                                                    <th>TradingView</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {items.map((item) => (
                                                    <tr key={item[0]} onClick={() => onClickTable(item[0])} style={{ cursor: "pointer" }}>
                                                        <td>{item[1]}</td>
                                                        <td>
                                                            <div className="tooltip">{item[2]}
                                                                <span className="tooltiptext">{item[4]}</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>)
                                    }
                                </div>
                            ))}
                        </div>
                    </div>}
            </div>
            
            {/*  */}
        </div>
    </div>)
}

export default DRPage
