import React, { useEffect, useState, useRef, useCallback } from 'react';
import { createChart } from 'lightweight-charts';
import { useSelector } from "react-redux";
// import './Flow2.css';
import { useIsMobile, useIsTablet } from '../../components/hooks';
import { CircularProgress, Box, Grid, useMediaQuery, Container, useTheme, Dialog, DialogContent, DialogActions, Button, IconButton, Typography } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close"
import ZoomInIcon from "@mui/icons-material/ZoomIn"

function convertData(data, date_column = 'time', field = 'value', timezone = true) {
    const timeZoneOffset = timezone ? 7 * 60 * 60 * 1000 : 0;
    return data.map(d => ({
        time: (new Date(d[date_column]).getTime() + timeZoneOffset) / 1000,
        value: parseFloat(d[field]) || 0,
    }));
}

function TradingViewChart({ lineSeriesData, symbol, date, onClick }) {
    const chartContainerRef = useRef();

    useEffect(() => {
        const chart = createChart(chartContainerRef.current, {
            layout: { backgroundColor: '#ffffff', textColor: 'rgba(33, 56, 77, 1)' },
            grid: { vertLines: { color: 'rgba(197, 203, 206, 0.7)' }, horzLines: { color: 'rgba(197, 203, 206, 0.7)' } },
            rightPriceScale: { scaleMargins: { top: 0.3, bottom: 0.25 } },
            leftPriceScale: { scaleMargins: { top: 0.3, bottom: 0.25 }, visible: false },
            timeScale: {
                rightOffset: 12,
                barSpacing: 3,
                lockVisibleTimeRangeOnResize: true,
                rightBarStaysOnScroll: true,
                borderVisible: true,
                visible: true,
                timeVisible: true,
                secondsVisible: true,
                handleScroll: false,
            },
            watermark: {
                color: '#E4E5E6',
                visible: true,
                text: (symbol || '').toUpperCase(),
                fontSize: 50,
                horzAlign: 'center',
                vertAlign: 'center',
            },
        });

        const updateChartSize = () => {
            if (!chartContainerRef.current) return;
            chart.applyOptions({ width: chartContainerRef.current.clientWidth, height: chartContainerRef.current.clientHeight });
        };
        const resizeObserver = new ResizeObserver(() => requestAnimationFrame(updateChartSize));
        resizeObserver.observe(chartContainerRef.current);
        updateChartSize();

        const priceLineSeries = chart.addLineSeries({ color: 'rgba(0, 0, 255, 1)', lineWidth: 2, priceScaleId: 'right' });
        priceLineSeries.setData(lineSeriesData.price);

        const flowLineSeries = chart.addLineSeries({ color: 'rgba(255, 0, 0, 1)', lineWidth: 2, priceScaleId: 'left', priceFormat: { type: 'volume' } });
        flowLineSeries.setData(lineSeriesData.flow);

        return () => {
            resizeObserver.disconnect();
            chart.remove();
        };
    }, [lineSeriesData, symbol, date]);

    return <div ref={chartContainerRef} style={{ position: 'relative', marginTop: '3%', height: '80%', width: '100%' }} onClick={onClick} />;
}

function ChartContainer({ chartId, containerData, onDateChange, onSymbolChange, onSubmit, onDateReplicate, onSymbolReplicate }) {
    const isMobile = useIsMobile();
    const isTablet = useIsTablet();
    const theme = useTheme();
    const isLaptop = useMediaQuery(theme.breakpoints.down('1400'));
    const { selectedDate, symbol, lineSeriesData } = containerData;

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [popupData, setPopupData] = useState(null);

    const handleChartClick = (data) => {
        setPopupData(data); // ตั้งค่าข้อมูลที่จะส่งไปยัง popup
        setIsPopupOpen(true); // เปิด popup
    };

    const handleClose = () => {
        setIsPopupOpen(false);
        setPopupData(null);
    };

    const handleDateChange = (e) => {
        onDateChange(chartId, e.target.value);
    };

    const handleSymbolChange = (e) => {
        onSymbolChange(chartId, e.target.value);
    };

    // const handleLocalSubmit = () => {
    //     onSubmit(chartId, selectedDate, symbol);
    // };

    const [isSearching, setIsSearching] = useState(false);
    const [debouncedSymbol, setDebouncedSymbol] = useState(symbol);
    const [debouncedDate, setDebouncedDate] = useState(selectedDate);

    const previousSymbol = useRef('');
    const previousDate = useRef('');

    // Delay การอัพเดตค่า
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSymbol(symbol?.trim() || '');
            setDebouncedDate(selectedDate?.trim() || '');
        }, 1000); // Delay 1 วินาที

        return () => clearTimeout(handler);
    }, [symbol, selectedDate]);

    // ตรวจสอบการเปลี่ยนแปลงค่าที่แท้จริงก่อนเรียก onSubmit
    useEffect(() => {
        if (
            debouncedSymbol &&
            debouncedDate &&
            (previousSymbol.current !== debouncedSymbol || previousDate.current !== debouncedDate)
        ) {
            console.log("start searching");
            setIsSearching(true); // เริ่มต้นแสดง Indicator
            onSubmit(chartId, debouncedDate, debouncedSymbol).finally(() => {
                setIsSearching(false); // ปิด Indicator เมื่อการค้นหาเสร็จสิ้น
            });
            previousSymbol.current = debouncedSymbol;
            previousDate.current = debouncedDate;
        }
    }, [debouncedSymbol, debouncedDate, chartId, onSubmit]);

    return (
        <div id={chartId} style={{ display: 'flex', flexDirection: 'column', maxWidth: '600px', margin: '0.2vw', backgroundColor: 'white' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-start', height: '3vh', alignItems: 'center', marginTop: '1%' }}>
                {/* Date input + button */}
                {(!isMobile && !isTablet) && (<>
                    <Box sx={{
                        // display: isLaptop ? 'column' : 'flex',
                        display: 'flex',
                        marginBottom: '5px',
                        width: isLaptop ? '280px' : '100%'
                    }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <input
                                type="date"
                                value={selectedDate || ''}
                                onChange={handleDateChange}
                                className="form-control"
                                style={{ width: '120px', margin: '0.1vw 5px' }}
                            />
                            <button onClick={() => onDateReplicate(chartId)} style={{ width: '20px', height: '20px', fontSize: '10px', marginLeft: '5px', padding: '0px',  }}>+</button>
                        </div>

                        {/* Symbol input + button */}
                        <div style={{ display: 'flex', alignItems: 'center', }} sx={{ marginLeft: isLaptop ? '0px' : '10px' }}>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Symbol here"
                                value={symbol || ''}
                                onChange={handleSymbolChange}
                                style={{ width: '120px', margin: '0.1vw 5px' }}
                            />
                            <button onClick={() => onSymbolReplicate(chartId)} style={{ width: '20px', height: '20px', fontSize: '10px', marginLeft: '5px', padding: '0px', }}>+</button>
                        </div>

                    </Box>

                </>)}

                {(isMobile || isTablet) && (<>
                    <Box sx={{
                        display: {
                            xs: 'column',
                            sm: 'column',
                            md: 'flex',
                            lg: 'flex',
                            xl: 'flex'
                        },
                        marginBottom: '10px',
                        // maxWidth: '500px',
                        width: {
                            xs: '40vw',
                            sm: '25vw',
                            md: '30vw',

                        },
                        justifyItems: 'center'

                    }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <input
                                type="date"
                                value={selectedDate || ''}
                                onChange={handleDateChange}
                                className="form-control"
                                style={{ width: '120px', margin: '0.1vw 5px' }} />
                            <button onClick={() => onDateReplicate(chartId)} style={{ width: '20px', height: '20px', fontSize: '10px', marginLeft: '5px', padding: '0px',  }}>+</button>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Symbol here"
                                value={symbol || ''}
                                onChange={handleSymbolChange}
                                style={{ width: '120px', margin: '0.1vw 5px' }} />
                            <button onClick={() => onSymbolReplicate(chartId)} style={{ width: '20px', height: '20px', fontSize: '10px', marginLeft: '5px', padding: '0px', }}>+</button>
                        </div>
                    </Box>
                </>)}


                {isSearching && (
                    <div
                        style={{
                            position: 'absolute',
                            // top: '25%',
                            // left: '25%',
                            // transform: 'translate(-50%, -50%)',
                            zIndex: 10,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            // backgroundColor: 'rgba(255, 255, 255, 0.8)', // ทำให้ background โปร่งใส
                            // width: '100%',
                            // height: '100%',
                        }}
                    >
                        <CircularProgress />
                    </div>)}

                {/* Submit button */}
                {/* <button
                    className="btn btn-primary d-flex align-items-center justify-content-center"
                    onClick={handleLocalSubmit}
                    style={{ width: '60px', height: '25px', margin: '0.1vw 10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                    Submit
                </button> */}
            </div>
            {lineSeriesData && (<Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
                {/* TradingViewChart */}
                <TradingViewChart
                    lineSeriesData={lineSeriesData}
                    symbol={symbol || ''}
                    date={selectedDate || ''}
                />

                {/* IconButton for Zoom */}
                <IconButton
                    onClick={() =>
                        handleChartClick({
                            lineSeriesData: lineSeriesData,
                            symbol: symbol || '',
                            date: selectedDate || '',
                        })
                    }
                    sx={{
                        position: 'absolute',
                        top: 8, // Distance from top
                        right: 64, // Distance from right
                        zIndex: 10, // Ensure it appears above the chart
                        backgroundColor: 'transparent', // Optional: background color for better visibility
                        '&:hover': {
                            backgroundColor: 'lightgray', // Optional: hover effect
                        },
                    }}
                >
                    <ZoomInIcon /> {/* Replace with any Material-UI icon */}
                </IconButton>
            </Box>)
                }
            <Dialog open={isPopupOpen} onClose={handleClose} fullWidth maxWidth="lg" className="DialogBox">
                <IconButton
                    onClick={handleClose}
                    style={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        zIndex: 1,
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', }}>
                        {popupData && (
                            <>
                                <Typography variant="h3">
                                    Symbol: {popupData.symbol.toUpperCase()}
                                </Typography>
                                <Typography variant="h4">
                                    Date: {popupData.date}
                                </Typography>
                            </>
                        )}
                    </Box>
                    <Box sx={{
                        height: {
                            xs: '300px', // mobile
                            sm: '300px',
                            md: '300px', // tablet
                            lg: '500px', // desktop
                        },
                        width: '100%',
                    }}>
                        {popupData && (
                            <TradingViewChart
                                lineSeriesData={popupData.lineSeriesData}
                                symbol={popupData.symbol}
                                date={popupData.date}
                            />
                        )}
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default function Flow() {
    const csrfToken = useSelector((state) => state.csrfToken);

    const isMobile = useIsMobile();
    console.log("is Mobile?", isMobile)
    const isTablet = useIsTablet();
    console.log("is Tablet?", isTablet)

    const isLongPage = useMediaQuery('(min-height:1025px)');
    const isMobilePage = useMediaQuery('(min-height:741px)');
    const isLaptop = useMediaQuery('(max-height:1340px)');




    const initialContainers = Array.from({ length: 12 }).map((_, i) => ({
        chartId: `chart${i + 1}`,
        selectedDate: '',
        symbol: '',
        lineSeriesData: null,
    }));

    const [containers, setContainers] = useState(initialContainers);

    const fetchData = async (date, symbol) => {
        if (!date || !symbol) {
            console.error('Please provide both symbol and date');
            return null;
        }

        const flowUrl = `${window.config.base_api_url}/flow?symbol=${symbol}&date=${date}`;
        try {
            const response = await fetch(flowUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'xsrf-token': csrfToken,
                },
            });
            if (!response.ok) throw new Error('Network response was not ok');
            const rawData = await response.json();
            if (!rawData || rawData.length === 0) {
                console.error('No data received');
                return null;
            }
            return rawData;
        } catch (error) {
            console.error('Error during fetch operation:', error.message);
            return null;
        }
    };

    const handleSubmit = async (chartId, date, symbol) => {
        const rawData = await fetchData(date, symbol);
        if (!rawData) return;
        const newLineSeriesData = {
            price: convertData(rawData, 'time', 'last'),
            flow: convertData(rawData, 'time', 'flow'),
        };

        setContainers(prev =>
            prev.map(c => c.chartId === chartId ? { ...c, lineSeriesData: newLineSeriesData } : c)
        );
    };

    const handleDateChange = (chartId, newDate) => {
        setContainers(prev =>
            prev.map(c => c.chartId === chartId ? { ...c, selectedDate: newDate } : c)
        );
    };

    const handleSymbolChange = (chartId, newSymbol) => {
        setContainers(prev =>
            prev.map(c => c.chartId === chartId ? { ...c, symbol: newSymbol } : c)
        );
    };

    const handleDateReplicate = (chartId) => {
        const sourceIndex = containers.findIndex(c => c.chartId === chartId);
        if (sourceIndex === -1) return;
        const sourceDate = containers[sourceIndex].selectedDate;
        if (!sourceDate) return;

        setContainers(prev => prev.map((c, idx) => {
            if (idx > sourceIndex) {
                return { ...c, selectedDate: sourceDate };
            }
            return c;
        }));
    };

    const handleSymbolReplicate = (chartId) => {
        const sourceIndex = containers.findIndex(c => c.chartId === chartId);
        if (sourceIndex === -1) return;
        const sourceSymbol = containers[sourceIndex].symbol;
        if (!sourceSymbol) return;

        setContainers(prev => prev.map((c, idx) => {
            if (idx > sourceIndex) {
                return { ...c, symbol: sourceSymbol };
            }
            return c;
        }));
    };

    // return (
    //     <div className="flow-center-container">
    //         <div className="flow-content">

    //             {(!isMobile && !isTablet) && (
    //                 <>
    //                     <Grid container spacing={2} className="chart-row">
    //                         {containers.slice(0, 3).map((cont) => (
    //                             <ChartContainer
    //                                 chartId={cont.chartId}
    //                                 containerData={cont}
    //                                 onSubmit={handleSubmit}
    //                                 onDateChange={handleDateChange}
    //                                 onSymbolChange={handleSymbolChange}
    //                                 onDateReplicate={handleDateReplicate}
    //                                 onSymbolReplicate={handleSymbolReplicate}
    //                             />
    //                         ))}
    //                     </Grid>
    //                     <Grid container spacing={2} className="chart-row">
    //                         {containers.slice(3, 6).map((cont) => (
    //                             <ChartContainer
    //                                 chartId={cont.chartId}
    //                                 containerData={cont}
    //                                 onSubmit={handleSubmit}
    //                                 onDateChange={handleDateChange}
    //                                 onSymbolChange={handleSymbolChange}
    //                                 onDateReplicate={handleDateReplicate}
    //                                 onSymbolReplicate={handleSymbolReplicate}
    //                             />
    //                         ))}
    //                     </Grid>
    //                     <Grid container spacing={2} className="chart-row">
    //                         {containers.slice(6, 9).map((cont) => (
    //                             <ChartContainer
    //                                 chartId={cont.chartId}
    //                                 containerData={cont}
    //                                 onSubmit={handleSubmit}
    //                                 onDateChange={handleDateChange}
    //                                 onSymbolChange={handleSymbolChange}
    //                                 onDateReplicate={handleDateReplicate}
    //                                 onSymbolReplicate={handleSymbolReplicate}
    //                             />
    //                         ))}
    //                     </Grid>
    //                     <Grid container spacing={2} className="chart-row">
    //                         {containers.slice(9, 12).map((cont) => (
    //                             <ChartContainer
    //                                 chartId={cont.chartId}
    //                                 containerData={cont}
    //                                 onSubmit={handleSubmit}
    //                                 onDateChange={handleDateChange}
    //                                 onSymbolChange={handleSymbolChange}
    //                                 onDateReplicate={handleDateReplicate}
    //                                 onSymbolReplicate={handleSymbolReplicate}
    //                             />
    //                         ))}
    //                     </Grid>
    //                 </>
    //             )}
    //             {(isMobile || isTablet) && (
    //                 <>
    //                     <Grid container spacing={2} className="chart-row">
    //                         {containers.slice(0, 2).map((cont) => (
    //                             <ChartContainer
    //                                 chartId={cont.chartId}
    //                                 containerData={cont}
    //                                 onSubmit={handleSubmit}
    //                                 onDateChange={handleDateChange}
    //                                 onSymbolChange={handleSymbolChange}
    //                                 onDateReplicate={handleDateReplicate}
    //                                 onSymbolReplicate={handleSymbolReplicate}
    //                             />
    //                         ))}
    //                     </Grid>
    //                     <Grid container spacing={2} className="chart-row">
    //                         {containers.slice(2, 4).map((cont) => (
    //                             <ChartContainer
    //                                 chartId={cont.chartId}
    //                                 containerData={cont}
    //                                 onSubmit={handleSubmit}
    //                                 onDateChange={handleDateChange}
    //                                 onSymbolChange={handleSymbolChange}
    //                                 onDateReplicate={handleDateReplicate}
    //                                 onSymbolReplicate={handleSymbolReplicate}
    //                             />
    //                         ))}
    //                     </Grid>
    //                     <Grid container spacing={2} className="chart-row">
    //                         {containers.slice(4, 6).map((cont) => (
    //                             <ChartContainer
    //                                 chartId={cont.chartId}
    //                                 containerData={cont}
    //                                 onSubmit={handleSubmit}
    //                                 onDateChange={handleDateChange}
    //                                 onSymbolChange={handleSymbolChange}
    //                                 onDateReplicate={handleDateReplicate}
    //                                 onSymbolReplicate={handleSymbolReplicate}
    //                             />
    //                         ))}
    //                     </Grid>
    //                     {isMobilePage && (
    //                         <Grid container spacing={2} className="chart-row">
    //                             {containers.slice(6, 8).map((cont) => (
    //                                 <ChartContainer
    //                                     chartId={cont.chartId}
    //                                     containerData={cont}
    //                                     onSubmit={handleSubmit}
    //                                     onDateChange={handleDateChange}
    //                                     onSymbolChange={handleSymbolChange}
    //                                     onDateReplicate={handleDateReplicate}
    //                                     onSymbolReplicate={handleSymbolReplicate}
    //                                 />
    //                             ))}
    //                         </Grid>)}

    //                     {isLongPage && (
    //                         <Grid container spacing={2} className="chart-row">
    //                             {containers.slice(8, 10).map((cont) => (
    //                                 <ChartContainer
    //                                     chartId={cont.chartId}
    //                                     containerData={cont}
    //                                     onSubmit={handleSubmit}
    //                                     onDateChange={handleDateChange}
    //                                     onSymbolChange={handleSymbolChange}
    //                                     onDateReplicate={handleDateReplicate}
    //                                     onSymbolReplicate={handleSymbolReplicate}
    //                                 />
    //                             ))}
    //                         </Grid>)}
    //                 </>
    //             )}
    //         </div>
    //     </div>
    // );
    return (
        <>
            <Container sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                width: '100%',
                height: '100vh',
                overflow: 'auto', // Enables scrolling if needed
            }}>
                <Box
                    sx={{
                        maxWidth: 2000,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        marginTop: '5vh',
                        gap: (isMobile || isTablet) ? '20px' : '10px'
                    }}
                >
                    {(!isMobile && !isTablet) && (
                        <>
                            <Grid container spacing={2} className="chart-row"
                                sx={{
                                    height: '15em',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    maxWidth: '100%',
                                }}
                            >
                                {containers.slice(0, 3).map((cont) => (
                                    <ChartContainer
                                        chartId={cont.chartId}
                                        containerData={cont}
                                        onSubmit={handleSubmit}
                                        onDateChange={handleDateChange}
                                        onSymbolChange={handleSymbolChange}
                                        onDateReplicate={handleDateReplicate}
                                        onSymbolReplicate={handleSymbolReplicate}
                                    />
                                ))}
                            </Grid>
                            <Grid container spacing={2} className="chart-row"
                                sx={{
                                    height: '15em',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    maxWidth: '100%',
                                }}
                            >
                                {containers.slice(3, 6).map((cont) => (
                                    <ChartContainer
                                        chartId={cont.chartId}
                                        containerData={cont}
                                        onSubmit={handleSubmit}
                                        onDateChange={handleDateChange}
                                        onSymbolChange={handleSymbolChange}
                                        onDateReplicate={handleDateReplicate}
                                        onSymbolReplicate={handleSymbolReplicate}
                                    />
                                ))}
                            </Grid>
                            <Grid container spacing={2} className="chart-row"
                                sx={{
                                    height: '15em',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    maxWidth: '100%',
                                }}
                            >
                                {containers.slice(6, 9).map((cont) => (
                                    <ChartContainer
                                        chartId={cont.chartId}
                                        containerData={cont}
                                        onSubmit={handleSubmit}
                                        onDateChange={handleDateChange}
                                        onSymbolChange={handleSymbolChange}
                                        onDateReplicate={handleDateReplicate}
                                        onSymbolReplicate={handleSymbolReplicate}
                                    />
                                ))}
                            </Grid>
                            <Grid container spacing={2} className="chart-row"
                                sx={{
                                    height: '15em',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    maxWidth: '100%',
                                }}
                            >
                                {containers.slice(9, 12).map((cont) => (
                                    <ChartContainer
                                        chartId={cont.chartId}
                                        containerData={cont}
                                        onSubmit={handleSubmit}
                                        onDateChange={handleDateChange}
                                        onSymbolChange={handleSymbolChange}
                                        onDateReplicate={handleDateReplicate}
                                        onSymbolReplicate={handleSymbolReplicate}
                                    />
                                ))}
                            </Grid>
                        </>
                    )}
                    {(isMobile || isTablet) && (
                        <>
                            <Grid container spacing={2} className="chart-row"
                                sx={{
                                    height: '15em',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    maxWidth: '100%',
                                }}>
                                {containers.slice(0, 2).map((cont) => (
                                    <ChartContainer
                                        chartId={cont.chartId}
                                        containerData={cont}
                                        onSubmit={handleSubmit}
                                        onDateChange={handleDateChange}
                                        onSymbolChange={handleSymbolChange}
                                        onDateReplicate={handleDateReplicate}
                                        onSymbolReplicate={handleSymbolReplicate}
                                    />
                                ))}
                            </Grid>
                            <Grid container spacing={2} className="chart-row"
                                sx={{
                                    height: '15em',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    maxWidth: '100%',
                                }}>
                                {containers.slice(2, 4).map((cont) => (
                                    <ChartContainer
                                        chartId={cont.chartId}
                                        containerData={cont}
                                        onSubmit={handleSubmit}
                                        onDateChange={handleDateChange}
                                        onSymbolChange={handleSymbolChange}
                                        onDateReplicate={handleDateReplicate}
                                        onSymbolReplicate={handleSymbolReplicate}
                                    />
                                ))}
                            </Grid>
                            <Grid container spacing={2} className="chart-row"
                                sx={{
                                    height: '15em',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    maxWidth: '100%',
                                }}
                            >
                                {containers.slice(4, 6).map((cont) => (
                                    <ChartContainer
                                        chartId={cont.chartId}
                                        containerData={cont}
                                        onSubmit={handleSubmit}
                                        onDateChange={handleDateChange}
                                        onSymbolChange={handleSymbolChange}
                                        onDateReplicate={handleDateReplicate}
                                        onSymbolReplicate={handleSymbolReplicate}
                                    />
                                ))}
                            </Grid>
                            {isMobilePage && (
                                <Grid container spacing={2} className="chart-row"
                                    sx={{
                                        height: '15em',
                                        justifyContent: 'space-around',
                                        width: '100%',
                                        maxWidth: '100%',
                                    }}
                                >
                                    {containers.slice(6, 8).map((cont) => (
                                        <ChartContainer
                                            chartId={cont.chartId}
                                            containerData={cont}
                                            onSubmit={handleSubmit}
                                            onDateChange={handleDateChange}
                                            onSymbolChange={handleSymbolChange}
                                            onDateReplicate={handleDateReplicate}
                                            onSymbolReplicate={handleSymbolReplicate}
                                        />
                                    ))}
                                </Grid>)}

                            {isLongPage && (
                                <Grid container spacing={2} className="chart-row"
                                    sx={{
                                        height: '15em',
                                        justifyContent: 'space-around',
                                        width: '100%',
                                        maxWidth: '100%',
                                    }}
                                >
                                    {containers.slice(8, 10).map((cont) => (
                                        <ChartContainer
                                            chartId={cont.chartId}
                                            containerData={cont}
                                            onSubmit={handleSubmit}
                                            onDateChange={handleDateChange}
                                            onSymbolChange={handleSymbolChange}
                                            onDateReplicate={handleDateReplicate}
                                            onSymbolReplicate={handleSymbolReplicate}
                                        />
                                    ))}
                                </Grid>)}
                        </>
                    )}
                </Box>
            </Container>
        </>);
}
