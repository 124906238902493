import React, { useEffect, useRef, useState } from "react";
import { createChart } from "lightweight-charts";
import { useSelector } from "react-redux"; // <-- to get csrfToken if needed

// Use window.config.base_api_url + "/area"


function AreaSection() {
  const AREA_API = `${window.config.base_api_url}/area`;
  const chartContainerRef = useRef(null);
  const chartRef = useRef(null);
  const [areaData, setAreaData] = useState({});

  // If you have a Redux store that stores the token:
  const csrfToken = useSelector((state) => state.csrfToken);

  useEffect(() => {
    async function fetchAreaData() {
      try {
        // Include xsrf-token in the headers
        const response = await fetch(AREA_API, {
          headers: {
            'Content-Type': 'application/json',
            'xsrf-token': csrfToken,
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setAreaData(data);
      } catch (error) {
        console.error("Error fetching area data:", error);
      }
    }
    fetchAreaData();
  }, [csrfToken]); // re-fetch if token changes

  // Build chart whenever data changes
  useEffect(() => {
    if (!chartContainerRef.current || !Object.keys(areaData).length) return;

    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: 400,
      layout: {
        background: { type: "solid", color: "white" },
        textColor: "#333",
      },
      timeScale: {
        timeVisible: true,
        secondsVisible: false,
      },
    });

    chartRef.current = chart;

    // Extract unique timestamps
    const allTimestamps = new Set();
    Object.values(areaData).forEach((data) => {
      data.forEach((item) => {
        const epochMs = parseDateTime(item.time);
        if (!Number.isNaN(epochMs)) {
          allTimestamps.add(Math.floor(epochMs / 1000)); // seconds
        }
      });
    });
    const sortedTimestamps = Array.from(allTimestamps).sort((a, b) => a - b);

    // Predefined colors
    const lineColors = [
      "#2196f3",
      "#4caf50",
      "#f44336",
      "#9c27b0",
      "#ff9800",
      "#009688",
      "#e91e63",
      "#ffc107",
    ];

    // Forward-fill data for each series
    Object.keys(areaData).forEach((symbol, index) => {
      const rawPoints = areaData[symbol] || [];
      const sortedPoints = [...rawPoints].sort(
        (a, b) => parseDateTime(a.time) - parseDateTime(b.time)
      );

      const finalData = [];
      let lastValue = null;

      sortedTimestamps.forEach((timestamp) => {
        const found = sortedPoints.find(
          (item) => Math.floor(parseDateTime(item.time) / 1000) === timestamp
        );
        if (found) {
          lastValue = found.area;
          finalData.push({
            time: adjustToLocalTime(timestamp),
            value: lastValue,
          });
        } else if (lastValue !== null) {
          finalData.push({
            time: adjustToLocalTime(timestamp),
            value: lastValue,
          });
        }
      });

      const series = chart.addLineSeries({
        lineWidth: 2,
        color: lineColors[index % lineColors.length],
        title: symbol,
      });
      series.setData(finalData);
    });

    chart.timeScale().fitContent();

    return () => {
      chart.remove();
    };
  }, [areaData]);

  function parseDateTime(dateStr) {
    const fixedStr = dateStr.replace(" ", "T");
    return new Date(fixedStr).getTime(); // ms
  }

  function adjustToLocalTime(timestampSec) {
    const date = new Date(timestampSec * 1000);
    const timezoneOffsetSec = date.getTimezoneOffset() * 60;
    return timestampSec - timezoneOffsetSec;
  }

  return (
    <div style={{ marginBottom: "40px" }}>
      <h2>Area (Spread Vol, etc.)</h2>
      <div
        ref={chartContainerRef}
        style={{ width: "100%", minHeight: "400px" }}
      />
    </div>
  );
}

export default AreaSection;
