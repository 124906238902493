import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // ใช้ hook navigate
import ChartSectionWithMultipleLines from './ChartSectionWithMultipleLines';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
import { useIsMobile, useIsPortraitTablet, useIsTablet } from '../../components/hooks';

import { FaSearch } from 'react-icons/fa'; // นำเข้าไอคอนแว่นขยาย
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Box, useMediaQuery, useTheme, IconButton, Typography } from '@mui/material'
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";


const DashboardHis = () => {
  const isMobile = useIsMobile();
  const isPTablet = useIsPortraitTablet();
  const isTablet = useIsTablet();

  const [selectedDate, setSelectedDate] = useState(new Date()); // State ของวันที่ร่วมกัน
  const navigate = useNavigate(); // hook สำหรับ navigate

  const [isCollapsed, setIsCollapsed] = useState(false);

  // const toggleCollapse = () => {
  //   setIsCollapsed(!isCollapsed); // เปลี่ยนสถานะพับ/ขยาย
  // };

  const chartConfigs = [
    { title: 'SET100  Up', market: 'SET100', type: 'in' },
    { title: 'SET100 Down', market: 'SET100', type: 'out' },
    { title: 'NON-SET100 Up', market: 'NON-SET100', type: 'in' },
    { title: 'NON-SET100 Down', market: 'NON-SET100', type: 'out' },
    { title: 'MAI Up', market: 'MAI', type: 'in' },
    { title: 'MAI Down', market: 'MAI', type: 'out' },
    { title: 'WARRANT Up', market: 'WARRANT', type: 'in' },
    { title: 'WARRANT Down', market: 'WARRANT', type: 'out' },
  ];

  const handleExpand = (title, market, type, date) => {
    // กำหนด path ตามประเภทของกราฟ
    let path = '';
    if (market === 'SET100') {
      path = type === 'in' ? '/set-in' : '/set-out';
    } else if (market === 'NON-SET100') {
      path = type === 'in' ? '/non-in' : '/non-out';
    } else if (market === 'MAI') {
      path = type === 'in' ? '/mai-in' : '/mai-out';
    } else if (market === 'WARRANT') {
      path = type === 'in' ? '/warrant-in' : '/warrant-out';
    }

    // ส่ง selectedDate ไปพร้อมกับการเปิดแท็บใหม่
    const formattedDate = date.toISOString();  // ส่ง date ในรูปแบบ ISO string
    const url = `${window.location.origin}${path}?title=${encodeURIComponent(title)}&market=${encodeURIComponent(market)}&type=${encodeURIComponent(type)}&date=${encodeURIComponent(formattedDate)}`;

    // เปิดกราฟในแท็บใหม่
    window.open(url, '_blank');
  };
  return (
    <div className="dashboard" style={{ padding: isMobile || isTablet ? '20px' : '30px', marginTop: '-5px', }}>
      <Typography variant="h3" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', fontFamily: 'Arial, Helvetica, sans-serif', fontWeight: 'bold' }}>Ideatradepoint history</Typography>
      <Box className="date-picker-container" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }} style={(isMobile || isTablet) ? datePickerStyle2 : datePickerStyle}>
        <label htmlFor="date-picker" style={{ fontFamily: 'Arial, Helvetica, sans-serif', }}>Select Date : </label>
        <input
          id="date-picker"
          type="date"
          value={selectedDate.toISOString().split('T')[0]} // แปลง selectedDate เป็นรูปแบบ yyyy-MM-dd
          onChange={(e) => setSelectedDate(new Date(e.target.value))} // อัปเดต selectedDate เมื่อเลือกวันที่ใหม่
        />
      </Box>

      {/* แบ่งแถว 2 คอลัมน์ */}
      {/* แสดงกราฟและปุ่มแว่นขยาย */}
      <div className="chart-grid" style={isMobile || isTablet ? chartGridStyle2 : chartGridStyle}>
        {(!isMobile && !isTablet) && (<>
          {chartConfigs.map((config, index) => (
            <div key={index} style={chartItemStyle}>
              <div style={chartTitleStyle}>
                {/* ปุ่มแว่นขยายข้างๆ ชื่อกราฟ */}
                <FaSearch
                  style={iconStyle}
                  onClick={() => handleExpand(config.title, config.market, config.type, selectedDate)}
                />
              </div>
              <ChartSectionWithMultipleLines
                title={config.title}
                market={config.market}
                type={config.type}
                selectedDate={selectedDate}
              />
            </div>
          ))}
        </>)}

        {(isMobile || isPTablet) && (
          <Swiper

            style={{ maxWidth: '100%' }}
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={20}
            slidesPerView={1}
            pagination={{
              clickable: true,
              dynamicBullets: true,
            }}
            onSwiper={(swiper) => {
              window.mySwiper = swiper;
            }}
          >
            {chartConfigs.reduce((result, _, index) => {
              if (index % 2 === 0) {
                // Group charts into pairs of 2
                result.push(chartConfigs.slice(index, index + 2));
              }
              return result;
            }, []).map((group, groupIndex) => (
              <SwiperSlide key={groupIndex}>
                <div style={{ display: 'flex', flexDirection: 'column', }}>
                  {group.map((config, index) => (
                    <div key={index} style={chartItemStyle}>
                      <div style={chartTitleStyle}>
                        {/* ปุ่มแว่นขยายข้างๆ ชื่อกราฟ */}
                        <FaSearch
                          style={iconStyle}
                          onClick={() => handleExpand(config.title, config.market, config.type, selectedDate)}
                        />
                        {/* <IconButton
                          variant="contained"
                          onClick={toggleCollapse}
                        // sx={{ marginBottom: '10px' }}
                        >
                          {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                        </IconButton> */}
                      </div>
                      <ChartSectionWithMultipleLines
                        title={config.title}
                        market={config.market}
                        type={config.type}
                        selectedDate={selectedDate}
                      />
                    </div>
                  ))}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}

        {(isTablet && !isPTablet) && (
          <Swiper
            style={{ width: '100%', maxWidth: '1200px' }}
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={20}
            slidesPerView={1} // 1 slide ต่อหน้า
            pagination={{
              clickable: true,
              dynamicBullets: true,
            }}
            onSwiper={(swiper) => {
              window.mySwiper = swiper;
            }}
          >
            {/* Slide 1 */}
            <SwiperSlide>
              <div style={chartItemStyle2}>
                <div style={chartItemStyle}>
                  <div style={chartTitleStyle}>
                    <FaSearch
                      style={iconStyle}
                      onClick={() => handleExpand('SET100 Up', 'SET100', 'in', selectedDate)}
                    />
                  </div>
                  <ChartSectionWithMultipleLines title="SET100 Up" market="SET100" type="in" selectedDate={selectedDate} />
                </div>
                <div style={chartItemStyle}>
                  <div style={chartTitleStyle}>
                    <FaSearch
                      style={iconStyle}
                      onClick={() => handleExpand('SET100 Down', 'SET100', 'out', selectedDate)}
                    />
                  </div>
                  <ChartSectionWithMultipleLines title="SET100 Down" market="SET100" type="out" selectedDate={selectedDate} />
                </div>
                <div style={chartItemStyle}>
                  <div style={chartTitleStyle}>
                    <FaSearch
                      style={iconStyle}
                      onClick={() => handleExpand('NON-SET100 Up', 'NON-SET100', 'in', selectedDate)}
                    />
                  </div>
                  <ChartSectionWithMultipleLines title="NON-SET100 Up" market="NON-SET100" type="in" selectedDate={selectedDate} />
                </div>
                <div style={chartItemStyle}>
                  <div style={chartTitleStyle}>
                    <FaSearch
                      style={iconStyle}
                      onClick={() => handleExpand('NON-SET100 Down', 'NON-SET100', 'out', selectedDate)}
                    />
                  </div>
                  <ChartSectionWithMultipleLines title="NON-SET100 Down" market="NON-SET100" type="out" selectedDate={selectedDate} />
                </div>
              </div>

            </SwiperSlide>

            {/* Slide 2 */}
            <SwiperSlide>
              <div
                style={chartItemStyle2}
              >
                <div style={chartItemStyle}>
                  <div style={chartTitleStyle}>
                    <FaSearch
                      style={iconStyle}
                      onClick={() => handleExpand('MAI Up', 'MAI', 'in', selectedDate)}
                    />
                  </div>
                  <ChartSectionWithMultipleLines title="MAI Up" market="MAI" type="in" selectedDate={selectedDate} />
                </div>
                <div style={chartItemStyle}>
                  <div style={chartTitleStyle}>
                    <FaSearch
                      style={iconStyle}
                      onClick={() => handleExpand('MAI Down', 'MAI', 'out', selectedDate)}
                    />
                  </div>
                  <ChartSectionWithMultipleLines title="MAI Down" market="MAI" type="out" selectedDate={selectedDate} />
                </div>
                <div style={chartItemStyle}>
                  <div style={chartTitleStyle}>
                    <FaSearch
                      style={iconStyle}
                      onClick={() => handleExpand('WARRANT Up', 'WARRANT', 'in', selectedDate)}
                    />
                  </div>
                  <ChartSectionWithMultipleLines title="WARRANT Up" market="WARRANT" type="in" selectedDate={selectedDate} />
                </div>
                <div style={chartItemStyle}>
                  <div style={chartTitleStyle}>
                    <FaSearch
                      style={iconStyle}
                      onClick={() => handleExpand('WARRANT Down', 'WARRANT', 'out', selectedDate)}
                    />
                  </div>
                  <ChartSectionWithMultipleLines title="WARRANT Down" market="WARRANT" type="out" selectedDate={selectedDate} />
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        )}


      </div>
    </div>
  );
};

// CSS-in-JS สำหรับการตั้งค่าปุ่มเลือกวันที่ให้ติดบนสุด
const datePickerStyle = {
  position: 'sticky',
  top: '0',
  zIndex: '1000',
  padding: '10px 0',
  marginBottom: '20px',
  textAlign: 'left',
};

const datePickerStyle2 = {
  position: 'sticky',
  top: '0',
  zIndex: '1000',
  padding: '10px 0',
  // marginBottom: '20px',
  textAlign: 'left',
};

const chartGridStyle = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  rowGap: '7px', // ช่องว่างระหว่างแถว
  columnGap: '30px', // ช่องว่างระหว่างคอลัมน์
};

const chartGridStyle2 = {
  display: 'grid',
  gridTemplateColumns: '1fr',
};

const chartItemStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
};

const chartItemStyle2 = {
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: '10px'
};

const chartTitleStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
};

const iconStyle = {
  cursor: 'pointer',
  marginLeft: '310px',
  marginBottom: '-50px',
  fontSize: '20px',
  zIndex: '1000',
};

export default DashboardHis;