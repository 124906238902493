import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { tokens } from "../../theme";
import { useTheme, Box, Container, Grid, Card, TextField, Button, Table, TableHead, TableRow, TableCell, TableBody, Typography, Paper } from '@mui/material';
import Header from "../../components/Header";

import CardContent from '@mui/material/CardContent';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import 'dayjs/locale/en-gb';
import 'dayjs/locale/es-us';
import { __fetching_data__ } from '../../components/api.js';

import dayjs from 'dayjs';

import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

import TableContainer from '@mui/material/TableContainer';
import Slider from '@mui/material/Slider';

import IconButton from '@mui/material/IconButton';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

import Preloader from '../../components/Preloader';
import { useIsMobile, useIsTablet, useIsPortraitTablet } from '../../components/hooks';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './index.css'

const base_api_url =
    process.env.NODE_ENV === "production"
        ? window.location.protocol === "https:"
            ? process.env.REACT_APP_API_HTTPS_URL
            : process.env.REACT_APP_API_HTTP_URL
        : "/application";

function createData(vol_bid, bid, ask, vol_ask) {
    return { vol_bid, bid, ask, vol_ask };
}

const MultiBidaskComponent = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const csrfToken = useSelector((state) => state.csrfToken);
    const now = dayjs();
    const today = now.format('YYYY-MM-DD');
    const [loading, setLoading] = useState(false);

    const [stHour, stMinute] = [10, 0];
    const [enHour, enMinute] = [16, 30];

    const isMobile = useIsMobile();
    const isTablet = useIsTablet();
    const isPTablet = useIsPortraitTablet();


    const stEnTimeMin = dayjs().set('hour', stHour).set('minute', stMinute);
    const stEnTimeMax = dayjs().set('hour', enHour).set('minute', enMinute);

    const today_value = dayjs(today).subtract(0, 'day');
    const today_value1 = dayjs(today).subtract(0, 'day');
    const today_value2 = dayjs(today).subtract(0, 'day');
    const today_value3 = dayjs(today).subtract(0, 'day');
    const today_value4 = dayjs(today).subtract(0, 'day');
    const today_value5 = dayjs(today).subtract(0, 'day');
    const today_value6 = dayjs(today).subtract(0, 'day');
    const today_value7 = dayjs(today).subtract(0, 'day');

    const initialRow = [
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData("", "", "", ""),
        createData("Sum Bid", "", "", "Sum Ask"),
        createData(0, "", "", 0),
    ]
    const initialRow1 = [
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData("", "", "", ""),
        createData("Sum Bid", "", "", "Sum Ask"),
        createData(0, "", "", 0),
    ]
    const initialRow2 = [
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData("", "", "", ""),
        createData("Sum Bid", "", "", "Sum Ask"),
        createData(0, "", "", 0),
    ]
    const initialRow3 = [
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData("", "", "", ""),
        createData("Sum Bid", "", "", "Sum Ask"),
        createData(0, "", "", 0),
    ]
    const initialRow4 = [
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData("", "", "", ""),
        createData("Sum Bid", "", "", "Sum Ask"),
        createData(0, "", "", 0),
    ]
    const initialRow5 = [
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData("", "", "", ""),
        createData("Sum Bid", "", "", "Sum Ask"),
        createData(0, "", "", 0),
    ]
    const initialRow6 = [
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData("", "", "", ""),
        createData("Sum Bid", "", "", "Sum Ask"),
        createData(0, "", "", 0),
    ]
    const initialRow7 = [
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData("", "", "", ""),
        createData("Sum Bid", "", "", "Sum Ask"),
        createData(0, "", "", 0),
    ]

    const [rows, setRows] = useState(initialRow);
    const [rows1, setRows1] = useState(initialRow1);
    const [rows2, setRows2] = useState(initialRow2);
    const [rows3, setRows3] = useState(initialRow3);
    const [rows4, setRows4] = useState(initialRow4);
    const [rows5, setRows5] = useState(initialRow5);
    const [rows6, setRows6] = useState(initialRow6);
    const [rows7, setRows7] = useState(initialRow7);

    const [initialValues, setInitialValues] = useState({
        start_date: today_value,
        end_date: today_value,
        start_time: stEnTimeMin,
        end_time: stEnTimeMax,
        symbol: "",
        speed: 1
    })
    const [initialValues1, setInitialValues1] = useState({
        start_date: today_value,
        end_date: today_value,
        start_time: stEnTimeMin,
        end_time: stEnTimeMax,
        symbol: "",
        speed: 1
    })
    const [initialValues2, setInitialValues2] = useState({
        start_date: today_value,
        end_date: today_value,
        start_time: stEnTimeMin,
        end_time: stEnTimeMax,
        symbol: "",
        speed: 1
    })
    const [initialValues3, setInitialValues3] = useState({
        start_date: today_value,
        end_date: today_value,
        start_time: stEnTimeMin,
        end_time: stEnTimeMax,
        symbol: "",
        speed: 1
    })
    const [initialValues4, setInitialValues4] = useState({
        start_date: today_value,
        end_date: today_value,
        start_time: stEnTimeMin,
        end_time: stEnTimeMax,
        symbol: "",
        speed: 1
    })
    const [initialValues5, setInitialValues5] = useState({
        start_date: today_value,
        end_date: today_value,
        start_time: stEnTimeMin,
        end_time: stEnTimeMax,
        symbol: "",
        speed: 1
    })
    const [initialValues6, setInitialValues6] = useState({
        start_date: today_value,
        end_date: today_value,
        start_time: stEnTimeMin,
        end_time: stEnTimeMax,
        symbol: "",
        speed: 1
    })
    const [initialValues7, setInitialValues7] = useState({
        start_date: today_value,
        end_date: today_value,
        start_time: stEnTimeMin,
        end_time: stEnTimeMax,
        symbol: "",
        speed: 1
    })

    const formatTime = (time) => {
        if (typeof time === 'object') {
            return `${dayjs(time).hour()}:${String(dayjs(time).minute()).padStart(2, '0')}`;
        }
        return time;
    };
    const formatTime1 = (time) => {
        if (typeof time === 'object') {
            return `${dayjs(time).hour()}:${String(dayjs(time).minute()).padStart(2, '0')}`;
        }
        return time;
    };
    const formatTime2 = (time) => {
        if (typeof time === 'object') {
            return `${dayjs(time).hour()}:${String(dayjs(time).minute()).padStart(2, '0')}`;
        }
        return time;
    };
    const formatTime3 = (time) => {
        if (typeof time === 'object') {
            return `${dayjs(time).hour()}:${String(dayjs(time).minute()).padStart(2, '0')}`;
        }
        return time;
    };
    const formatTime4 = (time) => {
        if (typeof time === 'object') {
            return `${dayjs(time).hour()}:${String(dayjs(time).minute()).padStart(2, '0')}`;
        }
        return time;
    };
    const formatTime5 = (time) => {
        if (typeof time === 'object') {
            return `${dayjs(time).hour()}:${String(dayjs(time).minute()).padStart(2, '0')}`;
        }
        return time;
    };
    const formatTime6 = (time) => {
        if (typeof time === 'object') {
            return `${dayjs(time).hour()}:${String(dayjs(time).minute()).padStart(2, '0')}`;
        }
        return time;
    };
    const formatTime7 = (time) => {
        if (typeof time === 'object') {
            return `${dayjs(time).hour()}:${String(dayjs(time).minute()).padStart(2, '0')}`;
        }
        return time;
    };

    const formatDate = (date) => {
        if (typeof date === 'object') {
            return `${dayjs(date).year()}-${String(dayjs(date).month() + 1).padStart(2, '0')}-${String(dayjs(date).date()).padStart(2, '0')}`;
        }
        return date;
    };
    const formatDate1 = (date) => {
        if (typeof date === 'object') {
            return `${dayjs(date).year()}-${String(dayjs(date).month() + 1).padStart(2, '0')}-${String(dayjs(date).date()).padStart(2, '0')}`;
        }
        return date;
    };
    const formatDate2 = (date) => {
        if (typeof date === 'object') {
            return `${dayjs(date).year()}-${String(dayjs(date).month() + 1).padStart(2, '0')}-${String(dayjs(date).date()).padStart(2, '0')}`;
        }
        return date;
    };
    const formatDate3 = (date) => {
        if (typeof date === 'object') {
            return `${dayjs(date).year()}-${String(dayjs(date).month() + 1).padStart(2, '0')}-${String(dayjs(date).date()).padStart(2, '0')}`;
        }
        return date;
    };
    const formatDate4 = (date) => {
        if (typeof date === 'object') {
            return `${dayjs(date).year()}-${String(dayjs(date).month() + 1).padStart(2, '0')}-${String(dayjs(date).date()).padStart(2, '0')}`;
        }
        return date;
    };
    const formatDate5 = (date) => {
        if (typeof date === 'object') {
            return `${dayjs(date).year()}-${String(dayjs(date).month() + 1).padStart(2, '0')}-${String(dayjs(date).date()).padStart(2, '0')}`;
        }
        return date;
    };
    const formatDate6 = (date) => {
        if (typeof date === 'object') {
            return `${dayjs(date).year()}-${String(dayjs(date).month() + 1).padStart(2, '0')}-${String(dayjs(date).date()).padStart(2, '0')}`;
        }
        return date;
    };
    const formatDate7 = (date) => {
        if (typeof date === 'object') {
            return `${dayjs(date).year()}-${String(dayjs(date).month() + 1).padStart(2, '0')}-${String(dayjs(date).date()).padStart(2, '0')}`;
        }
        return date;
    };

    const objectToQueryString = (obj) => {
        return Object.keys(obj)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]))
            .join('&');
    }
    const objectToQueryString1 = (obj) => {
        return Object.keys(obj)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]))
            .join('&');
    }
    const objectToQueryString2 = (obj) => {
        return Object.keys(obj)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]))
            .join('&');
    }
    const objectToQueryString3 = (obj) => {
        return Object.keys(obj)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]))
            .join('&');
    }
    const objectToQueryString4 = (obj) => {
        return Object.keys(obj)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]))
            .join('&');
    }
    const objectToQueryString5 = (obj) => {
        return Object.keys(obj)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]))
            .join('&');
    }
    const objectToQueryString6 = (obj) => {
        return Object.keys(obj)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]))
            .join('&');
    }
    const objectToQueryString7 = (obj) => {
        return Object.keys(obj)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]))
            .join('&');
    }

    const [countRecordBidAsk, setCountRecordBidAsk] = useState(0);
    const [countRecordBidAsk1, setCountRecordBidAsk1] = useState(0);
    const [countRecordBidAsk2, setCountRecordBidAsk2] = useState(0);
    const [countRecordBidAsk3, setCountRecordBidAsk3] = useState(0);
    const [countRecordBidAsk4, setCountRecordBidAsk4] = useState(0);
    const [countRecordBidAsk5, setCountRecordBidAsk5] = useState(0);
    const [countRecordBidAsk6, setCountRecordBidAsk6] = useState(0);
    const [countRecordBidAsk7, setCountRecordBidAsk7] = useState(0);

    const [bidaskLength, setBidAskLength] = useState(1);
    const [bidaskLength1, setBidAskLength1] = useState(1);
    const [bidaskLength2, setBidAskLength2] = useState(1);
    const [bidaskLength3, setBidAskLength3] = useState(1);
    const [bidaskLength4, setBidAskLength4] = useState(1);
    const [bidaskLength5, setBidAskLength5] = useState(1);
    const [bidaskLength6, setBidAskLength6] = useState(1);
    const [bidaskLength7, setBidAskLength7] = useState(1);

    const [resultBidAsk, setResultBidAsk] = useState([]);
    const [resultBidAsk1, setResultBidAsk1] = useState([]);
    const [resultBidAsk2, setResultBidAsk2] = useState([]);
    const [resultBidAsk3, setResultBidAsk3] = useState([]);
    const [resultBidAsk4, setResultBidAsk4] = useState([]);
    const [resultBidAsk5, setResultBidAsk5] = useState([]);
    const [resultBidAsk6, setResultBidAsk6] = useState([]);
    const [resultBidAsk7, setResultBidAsk7] = useState([]);

    const [resultTickMatch, setResultTickMatch] = useState([]);
    const [resultTickMatch1, setResultTickMatch1] = useState([]);
    const [resultTickMatch2, setResultTickMatch2] = useState([]);
    const [resultTickMatch3, setResultTickMatch3] = useState([]);
    const [resultTickMatch4, setResultTickMatch4] = useState([]);
    const [resultTickMatch5, setResultTickMatch5] = useState([]);
    const [resultTickMatch6, setResultTickMatch6] = useState([]);
    const [resultTickMatch7, setResultTickMatch7] = useState([]);

    const [isPlaying, setIsPlaying] = useState(false);
    const [isPlaying1, setIsPlaying1] = useState(false);
    const [isPlaying2, setIsPlaying2] = useState(false);
    const [isPlaying3, setIsPlaying3] = useState(false);
    const [isPlaying4, setIsPlaying4] = useState(false);
    const [isPlaying5, setIsPlaying5] = useState(false);
    const [isPlaying6, setIsPlaying6] = useState(false);
    const [isPlaying7, setIsPlaying7] = useState(false);

    const [Time2Show, setTime2Show] = useState('00:00:00');
    const [Time2Show1, setTime2Show1] = useState('00:00:00');
    const [Time2Show2, setTime2Show2] = useState('00:00:00');
    const [Time2Show3, setTime2Show3] = useState('00:00:00');
    const [Time2Show4, setTime2Show4] = useState('00:00:00');
    const [Time2Show5, setTime2Show5] = useState('00:00:00');
    const [Time2Show6, setTime2Show6] = useState('00:00:00');
    const [Time2Show7, setTime2Show7] = useState('00:00:00');

    const previousBidAsk = useRef({});
    const previousBidAsk1 = useRef({});
    const previousBidAsk2 = useRef({});
    const previousBidAsk3 = useRef({});
    const previousBidAsk4 = useRef({});
    const previousBidAsk5 = useRef({});
    const previousBidAsk6 = useRef({});
    const previousBidAsk7 = useRef({});

    const resetProcess = () => {
        previousBidAsk.current = {};
        document.querySelectorAll('.change-tracking td').forEach(cell => {
            cell.style.backgroundColor = '';
        });
        setRows(initialRow);
        setCountRecordBidAsk(0);
        setIsPlaying(false);
        setTime2Show('00:00:00');
    }
    const resetProcess1 = () => {
        previousBidAsk1.current = {};
        document.querySelectorAll('.change-tracking td').forEach(cell => {
            cell.style.backgroundColor = '';
        });
        setRows1(initialRow1);
        setCountRecordBidAsk1(0);
        setIsPlaying1(false);
        setTime2Show1('00:00:00');
    }
    const resetProcess2 = () => {
        previousBidAsk2.current = {};
        document.querySelectorAll('.change-tracking td').forEach(cell => {
            cell.style.backgroundColor = '';
        });
        setRows2(initialRow2);
        setCountRecordBidAsk2(0);
        setIsPlaying2(false);
        setTime2Show2('00:00:00');
    }
    const resetProcess3 = () => {
        previousBidAsk3.current = {};
        document.querySelectorAll('.change-tracking td').forEach(cell => {
            cell.style.backgroundColor = '';
        });
        setRows3(initialRow3);
        setCountRecordBidAsk3(0);
        setIsPlaying3(false);
        setTime2Show3('00:00:00');
    }
    const resetProcess4 = () => {
        previousBidAsk4.current = {};
        document.querySelectorAll('.change-tracking td').forEach(cell => {
            cell.style.backgroundColor = '';
        });
        setRows4(initialRow4);
        setCountRecordBidAsk4(0);
        setIsPlaying4(false);
        setTime2Show4('00:00:00');
    }
    const resetProcess5 = () => {
        previousBidAsk5.current = {};
        document.querySelectorAll('.change-tracking td').forEach(cell => {
            cell.style.backgroundColor = '';
        });
        setRows5(initialRow5);
        setCountRecordBidAsk5(0);
        setIsPlaying5(false);
        setTime2Show5('00:00:00');
    }
    const resetProcess6 = () => {
        previousBidAsk6.current = {};
        document.querySelectorAll('.change-tracking td').forEach(cell => {
            cell.style.backgroundColor = '';
        });
        setRows6(initialRow6);
        setCountRecordBidAsk6(0);
        setIsPlaying6(false);
        setTime2Show6('00:00:00');
    }
    const resetProcess7 = () => {
        previousBidAsk7.current = {};
        document.querySelectorAll('.change-tracking td').forEach(cell => {
            cell.style.backgroundColor = '';
        });
        setRows7(initialRow7);
        setCountRecordBidAsk7(0);
        setIsPlaying7(false);
        setTime2Show7('00:00:00');
    }

    const handleSubmitForm = async () => {
        setLoading(true);
        resetProcess();
        const values = {
            ...initialValues,
            start_date: formatDate(initialValues.start_date),//dayjs(initialValues.start_date).format('YYYY-MM-DD'),
            end_date: formatDate(initialValues.end_date),//dayjs(initialValues.end_date).format('YYYY-MM-DD'),
            start_time: formatTime(initialValues.start_time),
            end_time: formatTime(initialValues.end_time),
        };
        console.log(values);
        // const requestUrl = `${base_api_url}/bidask_dejavu_report?${objectToQueryString(values)}`;

        const response = await __fetching_data__({ url: `${base_api_url}/bidask_dejavu_report?${objectToQueryString(values)}`, method: "GET", headers: { "xsrf-token": csrfToken } });
        console.log(response)
        if (response.status) {
            setLoading(false);
            setResultBidAsk(response.data.bidask);
            setResultTickMatch(response.data.tickmatch);
            setBidAskLength(response.data.bidask.length);
            setIsPlaying(true);

        }
    }
    const handleSubmitForm1 = async () => {
        setLoading(true);
        resetProcess1();
        const values = {
            ...initialValues1,
            start_date: formatDate1(initialValues1.start_date),
            end_date: formatDate1(initialValues1.end_date),
            start_time: formatTime1(initialValues1.start_time),
            end_time: formatTime1(initialValues1.end_time),
        };
        console.log(values);

        const response = await __fetching_data__({ url: `${base_api_url}/bidask_dejavu_report?${objectToQueryString1(values)}`, method: "GET", headers: { "xsrf-token": csrfToken } });
        console.log(response)
        if (response.status) {
            setLoading(false);
            setResultBidAsk1(response.data.bidask);
            setResultTickMatch1(response.data.tickmatch);
            setBidAskLength1(response.data.bidask.length);
            setIsPlaying1(true);

        }
    }
    const handleSubmitForm2 = async () => {
        setLoading(true);
        resetProcess2();
        const values = {
            ...initialValues2,
            start_date: formatDate2(initialValues2.start_date),
            end_date: formatDate2(initialValues2.end_date),
            start_time: formatTime2(initialValues2.start_time),
            end_time: formatTime2(initialValues2.end_time),
        };
        console.log(values);

        const response = await __fetching_data__({ url: `${base_api_url}/bidask_dejavu_report?${objectToQueryString2(values)}`, method: "GET", headers: { "xsrf-token": csrfToken } });
        console.log(response)
        if (response.status) {
            setLoading(false);
            setResultBidAsk2(response.data.bidask);
            setResultTickMatch2(response.data.tickmatch);
            setBidAskLength2(response.data.bidask.length);
            setIsPlaying2(true);

        }
    }
    const handleSubmitForm3 = async () => {
        setLoading(true);
        resetProcess3();
        const values = {
            ...initialValues3,
            start_date: formatDate3(initialValues3.start_date),
            end_date: formatDate3(initialValues3.end_date),
            start_time: formatTime3(initialValues3.start_time),
            end_time: formatTime3(initialValues3.end_time),
        };
        console.log(values);

        const response = await __fetching_data__({ url: `${base_api_url}/bidask_dejavu_report?${objectToQueryString3(values)}`, method: "GET", headers: { "xsrf-token": csrfToken } });
        console.log(response)
        if (response.status) {
            setLoading(false);
            setResultBidAsk3(response.data.bidask);
            setResultTickMatch3(response.data.tickmatch);
            setBidAskLength3(response.data.bidask.length);
            setIsPlaying3(true);

        }
    }
    const handleSubmitForm4 = async () => {
        setLoading(true);
        resetProcess4();
        const values = {
            ...initialValues4,
            start_date: formatDate4(initialValues4.start_date),
            end_date: formatDate4(initialValues4.end_date),
            start_time: formatTime4(initialValues4.start_time),
            end_time: formatTime4(initialValues4.end_time),
        };
        console.log(values);

        const response = await __fetching_data__({ url: `${base_api_url}/bidask_dejavu_report?${objectToQueryString4(values)}`, method: "GET", headers: { "xsrf-token": csrfToken } });
        console.log(response)
        if (response.status) {
            setLoading(false);
            setResultBidAsk4(response.data.bidask);
            setResultTickMatch4(response.data.tickmatch);
            setBidAskLength4(response.data.bidask.length);
            setIsPlaying4(true);
        }
    }
    const handleSubmitForm5 = async () => {
        setLoading(true);
        resetProcess5();
        const values = {
            ...initialValues5,
            start_date: formatDate5(initialValues5.start_date),
            end_date: formatDate5(initialValues5.end_date),
            start_time: formatTime5(initialValues5.start_time),
            end_time: formatTime5(initialValues5.end_time),
        };
        console.log(values);

        const response = await __fetching_data__({ url: `${base_api_url}/bidask_dejavu_report?${objectToQueryString5(values)}`, method: "GET", headers: { "xsrf-token": csrfToken } });
        console.log(response)
        if (response.status) {
            setLoading(false);
            setResultBidAsk5(response.data.bidask);
            setResultTickMatch5(response.data.tickmatch);
            setBidAskLength5(response.data.bidask.length);
            setIsPlaying5(true);
        }
    }
    const handleSubmitForm6 = async () => {
        setLoading(true);
        resetProcess6();
        const values = {
            ...initialValues6,
            start_date: formatDate6(initialValues6.start_date),
            end_date: formatDate6(initialValues6.end_date),
            start_time: formatTime6(initialValues6.start_time),
            end_time: formatTime6(initialValues6.end_time),
        };
        console.log(values);

        const response = await __fetching_data__({ url: `${base_api_url}/bidask_dejavu_report?${objectToQueryString6(values)}`, method: "GET", headers: { "xsrf-token": csrfToken } });
        console.log(response)
        if (response.status) {
            setLoading(false);
            setResultBidAsk6(response.data.bidask);
            setResultTickMatch6(response.data.tickmatch);
            setBidAskLength6(response.data.bidask.length);
            setIsPlaying6(true);
        }
    }
    const handleSubmitForm7 = async () => {
        setLoading(true);
        resetProcess7();
        const values = {
            ...initialValues7,
            start_date: formatDate7(initialValues7.start_date),
            end_date: formatDate7(initialValues7.end_date),
            start_time: formatTime7(initialValues7.start_time),
            end_time: formatTime7(initialValues7.end_time),
        };
        console.log(values);

        const response = await __fetching_data__({ url: `${base_api_url}/bidask_dejavu_report?${objectToQueryString7(values)}`, method: "GET", headers: { "xsrf-token": csrfToken } });
        console.log(response)
        if (response.status) {
            setLoading(false);
            setResultBidAsk7(response.data.bidask);
            setResultTickMatch7(response.data.tickmatch);
            setBidAskLength7(response.data.bidask.length);
            setIsPlaying7(true);
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setInitialValues((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }
    const handleChange1 = (e) => {
        const { name, value } = e.target
        setInitialValues1((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }
    const handleChange2 = (e) => {
        const { name, value } = e.target
        setInitialValues2((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }
    const handleChange3 = (e) => {
        const { name, value } = e.target
        setInitialValues3((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }
    const handleChange4 = (e) => {
        const { name, value } = e.target;
        setInitialValues4((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handleChange5 = (e) => {
        const { name, value } = e.target;
        setInitialValues5((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handleChange6 = (e) => {
        const { name, value } = e.target;
        setInitialValues6((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handleChange7 = (e) => {
        const { name, value } = e.target;
        setInitialValues7((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleDateChange = (name, value) => {
        setInitialValues((prevData) => {
            let updatedValues = { ...prevData, [name]: value };

            if (name === 'start_date') {
                if (dayjs(value).isSame(dayjs(), 'day')) {
                    updatedValues.end_time = dayjs().isAfter(stEnTimeMax)
                        ? stEnTimeMax.format("HH:mm")
                        : dayjs().format("HH:mm");
                } else {
                    updatedValues.end_time = stEnTimeMax.format("HH:mm");
                }
                updatedValues.end_date = value;
            }

            return updatedValues;
        });
    };
    const handleDateChange1 = (name, value) => {
        setInitialValues1((prevData) => {
            let updatedValues = { ...prevData, [name]: value };

            if (name === 'start_date') {
                if (dayjs(value).isSame(dayjs(), 'day')) {
                    updatedValues.end_time = dayjs().isAfter(stEnTimeMax)
                        ? stEnTimeMax.format("HH:mm")
                        : dayjs().format("HH:mm");
                } else {
                    updatedValues.end_time = stEnTimeMax.format("HH:mm");
                }
                updatedValues.end_date = value;
            }

            return updatedValues;
        });
    };
    const handleDateChange2 = (name, value) => {
        setInitialValues2((prevData) => {
            let updatedValues = { ...prevData, [name]: value };

            if (name === 'start_date') {
                if (dayjs(value).isSame(dayjs(), 'day')) {
                    updatedValues.end_time = dayjs().isAfter(stEnTimeMax)
                        ? stEnTimeMax.format("HH:mm")
                        : dayjs().format("HH:mm");
                } else {
                    updatedValues.end_time = stEnTimeMax.format("HH:mm");
                }
                updatedValues.end_date = value;
            }

            return updatedValues;
        });
    };
    const handleDateChange3 = (name, value) => {
        setInitialValues3((prevData) => {
            let updatedValues = { ...prevData, [name]: value };

            if (name === 'start_date') {
                if (dayjs(value).isSame(dayjs(), 'day')) {
                    updatedValues.end_time = dayjs().isAfter(stEnTimeMax)
                        ? stEnTimeMax.format("HH:mm")
                        : dayjs().format("HH:mm");
                } else {
                    updatedValues.end_time = stEnTimeMax.format("HH:mm");
                }
                updatedValues.end_date = value;
            }

            return updatedValues;
        });
    };
    const handleDateChange4 = (name, value) => {
        setInitialValues4((prevData) => {
            let updatedValues = { ...prevData, [name]: value };

            if (name === 'start_date') {
                if (dayjs(value).isSame(dayjs(), 'day')) {
                    updatedValues.end_time = dayjs().isAfter(stEnTimeMax)
                        ? stEnTimeMax.format("HH:mm")
                        : dayjs().format("HH:mm");
                } else {
                    updatedValues.end_time = stEnTimeMax.format("HH:mm");
                }
                updatedValues.end_date = value;
            }

            return updatedValues;
        });
    };
    const handleDateChange5 = (name, value) => {
        setInitialValues5((prevData) => {
            let updatedValues = { ...prevData, [name]: value };

            if (name === 'start_date') {
                if (dayjs(value).isSame(dayjs(), 'day')) {
                    updatedValues.end_time = dayjs().isAfter(stEnTimeMax)
                        ? stEnTimeMax.format("HH:mm")
                        : dayjs().format("HH:mm");
                } else {
                    updatedValues.end_time = stEnTimeMax.format("HH:mm");
                }
                updatedValues.end_date = value;
            }

            return updatedValues;
        });
    };
    const handleDateChange6 = (name, value) => {
        setInitialValues6((prevData) => {
            let updatedValues = { ...prevData, [name]: value };

            if (name === 'start_date') {
                if (dayjs(value).isSame(dayjs(), 'day')) {
                    updatedValues.end_time = dayjs().isAfter(stEnTimeMax)
                        ? stEnTimeMax.format("HH:mm")
                        : dayjs().format("HH:mm");
                } else {
                    updatedValues.end_time = stEnTimeMax.format("HH:mm");
                }
                updatedValues.end_date = value;
            }

            return updatedValues;
        });
    };
    const handleDateChange7 = (name, value) => {
        setInitialValues7((prevData) => {
            let updatedValues = { ...prevData, [name]: value };

            if (name === 'start_date') {
                if (dayjs(value).isSame(dayjs(), 'day')) {
                    updatedValues.end_time = dayjs().isAfter(stEnTimeMax)
                        ? stEnTimeMax.format("HH:mm")
                        : dayjs().format("HH:mm");
                } else {
                    updatedValues.end_time = stEnTimeMax.format("HH:mm");
                }
                updatedValues.end_date = value;
            }

            return updatedValues;
        });
    };

    const handleTimeChange = (name, value) => {
        setInitialValues((prevData) => ({
            ...prevData,
            [name]: `${String(dayjs(value).hour()).padStart(2, '0')}:${String(dayjs(value).minute()).padStart(2, '0')}`
        }));
    };
    const handleTimeChange1 = (name, value) => {
        setInitialValues1((prevData) => ({
            ...prevData,
            [name]: `${String(dayjs(value).hour()).padStart(2, '0')}:${String(dayjs(value).minute()).padStart(2, '0')}`
        }));
    };
    const handleTimeChange2 = (name, value) => {
        setInitialValues2((prevData) => ({
            ...prevData,
            [name]: `${String(dayjs(value).hour()).padStart(2, '0')}:${String(dayjs(value).minute()).padStart(2, '0')}`
        }));
    };
    const handleTimeChange3 = (name, value) => {
        setInitialValues3((prevData) => ({
            ...prevData,
            [name]: `${String(dayjs(value).hour()).padStart(2, '0')}:${String(dayjs(value).minute()).padStart(2, '0')}`
        }));
    };
    const handleTimeChange4 = (name, value) => {
        setInitialValues4((prevData) => ({
            ...prevData,
            [name]: `${String(dayjs(value).hour()).padStart(2, '0')}:${String(dayjs(value).minute()).padStart(2, '0')}`
        }));
    };
    const handleTimeChange5 = (name, value) => {
        setInitialValues5((prevData) => ({
            ...prevData,
            [name]: `${String(dayjs(value).hour()).padStart(2, '0')}:${String(dayjs(value).minute()).padStart(2, '0')}`
        }));
    };
    const handleTimeChange6 = (name, value) => {
        setInitialValues6((prevData) => ({
            ...prevData,
            [name]: `${String(dayjs(value).hour()).padStart(2, '0')}:${String(dayjs(value).minute()).padStart(2, '0')}`
        }));
    };
    const handleTimeChange7 = (name, value) => {
        setInitialValues7((prevData) => ({
            ...prevData,
            [name]: `${String(dayjs(value).hour()).padStart(2, '0')}:${String(dayjs(value).minute()).padStart(2, '0')}`
        }));
    };

    useEffect(() => {
        if (dayjs(initialValues.start_date).isSame(dayjs(), 'day')) {
            const interval = setInterval(() => {
                const now = dayjs();
                const maxTime = dayjs().set('hour', 16).set('minute', 30);

                setInitialValues((prevData) => ({
                    ...prevData,
                    end_time: now.isAfter(maxTime) ? maxTime.format("HH:mm") : now.format("HH:mm"),
                }));
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [initialValues.start_date]);
    useEffect(() => {
        if (dayjs(initialValues1.start_date).isSame(dayjs(), 'day')) {
            const interval = setInterval(() => {
                const now = dayjs();
                const maxTime = dayjs().set('hour', 16).set('minute', 30);

                setInitialValues1((prevData) => ({
                    ...prevData,
                    end_time: now.isAfter(maxTime) ? maxTime.format("HH:mm") : now.format("HH:mm"),
                }));
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [initialValues1.start_date]);
    useEffect(() => {
        if (dayjs(initialValues2.start_date).isSame(dayjs(), 'day')) {
            const interval = setInterval(() => {
                const now = dayjs();
                const maxTime = dayjs().set('hour', 16).set('minute', 30);

                setInitialValues2((prevData) => ({
                    ...prevData,
                    end_time: now.isAfter(maxTime) ? maxTime.format("HH:mm") : now.format("HH:mm"),
                }));
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [initialValues2.start_date]);
    useEffect(() => {
        if (dayjs(initialValues3.start_date).isSame(dayjs(), 'day')) {
            const interval = setInterval(() => {
                const now = dayjs();
                const maxTime = dayjs().set('hour', 16).set('minute', 30);

                setInitialValues3((prevData) => ({
                    ...prevData,
                    end_time: now.isAfter(maxTime) ? maxTime.format("HH:mm") : now.format("HH:mm"),
                }));
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [initialValues3.start_date]);
    useEffect(() => {
        if (dayjs(initialValues4.start_date).isSame(dayjs(), 'day')) {
            const interval = setInterval(() => {
                const now = dayjs();
                const maxTime = dayjs().set('hour', 16).set('minute', 30);

                setInitialValues4((prevData) => ({
                    ...prevData,
                    end_time: now.isAfter(maxTime) ? maxTime.format("HH:mm") : now.format("HH:mm"),
                }));
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [initialValues4.start_date]);
    useEffect(() => {
        if (dayjs(initialValues5.start_date).isSame(dayjs(), 'day')) {
            const interval = setInterval(() => {
                const now = dayjs();
                const maxTime = dayjs().set('hour', 16).set('minute', 30);

                setInitialValues5((prevData) => ({
                    ...prevData,
                    end_time: now.isAfter(maxTime) ? maxTime.format("HH:mm") : now.format("HH:mm"),
                }));
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [initialValues5.start_date]);
    useEffect(() => {
        if (dayjs(initialValues6.start_date).isSame(dayjs(), 'day')) {
            const interval = setInterval(() => {
                const now = dayjs();
                const maxTime = dayjs().set('hour', 16).set('minute', 30);

                setInitialValues6((prevData) => ({
                    ...prevData,
                    end_time: now.isAfter(maxTime) ? maxTime.format("HH:mm") : now.format("HH:mm"),
                }));
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [initialValues6.start_date]);
    useEffect(() => {
        if (dayjs(initialValues7.start_date).isSame(dayjs(), 'day')) {
            const interval = setInterval(() => {
                const now = dayjs();
                const maxTime = dayjs().set('hour', 16).set('minute', 30);

                setInitialValues7((prevData) => ({
                    ...prevData,
                    end_time: now.isAfter(maxTime) ? maxTime.format("HH:mm") : now.format("HH:mm"),
                }));
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [initialValues7.start_date]);

    const handleClickPlayPause = () => {
        setIsPlaying(!isPlaying);
    };
    const handleClickPlayPause1 = () => {
        setIsPlaying1(!isPlaying1);
    };
    const handleClickPlayPause2 = () => {
        setIsPlaying2(!isPlaying2);
    };
    const handleClickPlayPause3 = () => {
        setIsPlaying3(!isPlaying3);
    };
    const handleClickPlayPause4 = () => {
        setIsPlaying4(!isPlaying4);
    };
    const handleClickPlayPause5 = () => {
        setIsPlaying5(!isPlaying5);
    };
    const handleClickPlayPause6 = () => {
        setIsPlaying6(!isPlaying6);
    };
    const handleClickPlayPause7 = () => {
        setIsPlaying7(!isPlaying7);
    };

    const handleSliderChange = (event, newValue) => {
        setCountRecordBidAsk(newValue);

        if (resultBidAsk[newValue - 1]) {
            const newTime = dayjs(resultBidAsk[newValue - 1].Time).format('HH:mm:ss');
            setTime2Show(newTime);
        }
    };
    const handleSliderChange1 = (event, newValue) => {
        setCountRecordBidAsk1(newValue);

        if (resultBidAsk1[newValue - 1]) {
            const newTime1 = dayjs(resultBidAsk1[newValue - 1].Time).format('HH:mm:ss');
            setTime2Show1(newTime1);
        }
    };
    const handleSliderChange2 = (event, newValue) => {
        setCountRecordBidAsk2(newValue);

        if (resultBidAsk2[newValue - 1]) {
            const newTime2 = dayjs(resultBidAsk2[newValue - 1].Time).format('HH:mm:ss');
            setTime2Show2(newTime2);
        }
    };
    const handleSliderChange3 = (event, newValue) => {
        setCountRecordBidAsk3(newValue);

        if (resultBidAsk3[newValue - 1]) {
            const newTime3 = dayjs(resultBidAsk3[newValue - 1].Time).format('HH:mm:ss');
            setTime2Show3(newTime3);
        }
    };
    const handleSliderChange4 = (event, newValue) => {
        setCountRecordBidAsk4(newValue);

        if (resultBidAsk4[newValue - 1]) {
            const newTime4 = dayjs(resultBidAsk4[newValue - 1].Time).format('HH:mm:ss');
            setTime2Show4(newTime4);
        }
    };
    const handleSliderChange5 = (event, newValue) => {
        setCountRecordBidAsk5(newValue);

        if (resultBidAsk5[newValue - 1]) {
            const newTime5 = dayjs(resultBidAsk5[newValue - 1].Time).format('HH:mm:ss');
            setTime2Show5(newTime5);
        }
    };
    const handleSliderChange6 = (event, newValue) => {
        setCountRecordBidAsk6(newValue);

        if (resultBidAsk6[newValue - 1]) {
            const newTime6 = dayjs(resultBidAsk6[newValue - 1].Time).format('HH:mm:ss');
            setTime2Show6(newTime6);
        }
    };
    const handleSliderChange7 = (event, newValue) => {
        setCountRecordBidAsk7(newValue);

        if (resultBidAsk7[newValue - 1]) {
            const newTime7 = dayjs(resultBidAsk7[newValue - 1].Time).format('HH:mm:ss');
            setTime2Show7(newTime7);
        }
    };

    const NumberWithCommasAndDecimal = (number, decimalPlaces) => {
        const formattedNumber = decimalPlaces === 2
            ? Number(number).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })
            : Number(number).toLocaleString('en-US');

        return <div>{formattedNumber}</div>;
    }
    const NumberWithCommasAndDecimal1 = (number, decimalPlaces) => {
        const formattedNumber = decimalPlaces === 2
            ? Number(number).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })
            : Number(number).toLocaleString('en-US');

        return <div>{formattedNumber}</div>;
    }
    const NumberWithCommasAndDecimal2 = (number, decimalPlaces) => {
        const formattedNumber = decimalPlaces === 2
            ? Number(number).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })
            : Number(number).toLocaleString('en-US');

        return <div>{formattedNumber}</div>;
    }
    const NumberWithCommasAndDecimal3 = (number, decimalPlaces) => {
        const formattedNumber = decimalPlaces === 2
            ? Number(number).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })
            : Number(number).toLocaleString('en-US');

        return <div>{formattedNumber}</div>;
    }
    const NumberWithCommasAndDecimal4 = (number, decimalPlaces) => {
        const formattedNumber = decimalPlaces === 2
            ? Number(number).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })
            : Number(number).toLocaleString('en-US');

        return <div>{formattedNumber}</div>;
    }
    const NumberWithCommasAndDecimal5 = (number, decimalPlaces) => {
        const formattedNumber = decimalPlaces === 2
            ? Number(number).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })
            : Number(number).toLocaleString('en-US');

        return <div>{formattedNumber}</div>;
    }
    const NumberWithCommasAndDecimal6 = (number, decimalPlaces) => {
        const formattedNumber = decimalPlaces === 2
            ? Number(number).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })
            : Number(number).toLocaleString('en-US');

        return <div>{formattedNumber}</div>;
    }
    const NumberWithCommasAndDecimal7 = (number, decimalPlaces) => {
        const formattedNumber = decimalPlaces === 2
            ? Number(number).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })
            : Number(number).toLocaleString('en-US');

        return <div>{formattedNumber}</div>;
    }

    const extractAndConvertChildren = (obj) => {
        return Object.keys(obj).reduce((acc, key) => {
            if (obj[key]) {
                if (typeof obj[key] === 'object' && obj[key].props && obj[key].props.children) {
                    const childrenValue = obj[key].props.children.replace(/,/g, '');
                    acc[key] = parseFloat(childrenValue);
                } else {
                    acc[key] = obj[key];
                }
            }

            return acc;
        }, {});
    };
    const extractAndConvertChildren1 = (obj) => {
        return Object.keys(obj).reduce((acc, key) => {
            if (obj[key]) {
                if (typeof obj[key] === 'object' && obj[key].props && obj[key].props.children) {
                    const childrenValue = obj[key].props.children.replace(/,/g, '');
                    acc[key] = parseFloat(childrenValue);
                } else {
                    acc[key] = obj[key];
                }
            }

            return acc;
        }, {});
    };
    const extractAndConvertChildren2 = (obj) => {
        return Object.keys(obj).reduce((acc, key) => {
            if (obj[key]) {
                if (typeof obj[key] === 'object' && obj[key].props && obj[key].props.children) {
                    const childrenValue = obj[key].props.children.replace(/,/g, '');
                    acc[key] = parseFloat(childrenValue);
                } else {
                    acc[key] = obj[key];
                }
            }

            return acc;
        }, {});
    };
    const extractAndConvertChildren3 = (obj) => {
        return Object.keys(obj).reduce((acc, key) => {
            if (obj[key]) {
                if (typeof obj[key] === 'object' && obj[key].props && obj[key].props.children) {
                    const childrenValue = obj[key].props.children.replace(/,/g, '');
                    acc[key] = parseFloat(childrenValue);
                } else {
                    acc[key] = obj[key];
                }
            }

            return acc;
        }, {});
    };
    const extractAndConvertChildren4 = (obj) => {
        return Object.keys(obj).reduce((acc, key) => {
            if (obj[key]) {
                if (typeof obj[key] === 'object' && obj[key].props && obj[key].props.children) {
                    const childrenValue = obj[key].props.children.replace(/,/g, '');
                    acc[key] = parseFloat(childrenValue);
                } else {
                    acc[key] = obj[key];
                }
            }

            return acc;
        }, {});
    };
    const extractAndConvertChildren5 = (obj) => {
        return Object.keys(obj).reduce((acc, key) => {
            if (obj[key]) {
                if (typeof obj[key] === 'object' && obj[key].props && obj[key].props.children) {
                    const childrenValue = obj[key].props.children.replace(/,/g, '');
                    acc[key] = parseFloat(childrenValue);
                } else {
                    acc[key] = obj[key];
                }
            }

            return acc;
        }, {});
    };
    const extractAndConvertChildren6 = (obj) => {
        return Object.keys(obj).reduce((acc, key) => {
            if (obj[key]) {
                if (typeof obj[key] === 'object' && obj[key].props && obj[key].props.children) {
                    const childrenValue = obj[key].props.children.replace(/,/g, '');
                    acc[key] = parseFloat(childrenValue);
                } else {
                    acc[key] = obj[key];
                }
            }

            return acc;
        }, {});
    };
    const extractAndConvertChildren7 = (obj) => {
        return Object.keys(obj).reduce((acc, key) => {
            if (obj[key]) {
                if (typeof obj[key] === 'object' && obj[key].props && obj[key].props.children) {
                    const childrenValue = obj[key].props.children.replace(/,/g, '');
                    acc[key] = parseFloat(childrenValue);
                } else {
                    acc[key] = obj[key];
                }
            }

            return acc;
        }, {});
    };

    const compareData = (oldData, newData) => {
        const differences = [];

        oldData.forEach((oldRow, index) => {
            const newRow = newData[index];
            if (!newRow) return;
            const diff = {};

            for (const key in oldRow) {
                if (oldRow[key] !== newRow[key]) {
                    const oldVal = parseFloat(oldRow[key]) || 0;
                    const newVal = parseFloat(newRow[key]) || 0;
                    const change = newVal - oldVal;
                    const changeType = change === 0 ? "" : (change > 0 ? 'positive' : 'negative');
                    diff[key] = {
                        old: oldVal,
                        new: newVal,
                        change: change,
                        type: changeType,
                        rowIndex: index
                    };
                }
            }

            if (Object.keys(diff).length > 0) {
                differences.push({ index, diff });
            }
        });

        return differences;
    };
    const compareData1 = (oldData, newData) => {
        const differences = [];

        oldData.forEach((oldRow, index) => {
            const newRow = newData[index];
            if (!newRow) return;
            const diff = {};

            for (const key in oldRow) {
                if (oldRow[key] !== newRow[key]) {
                    const oldVal = parseFloat(oldRow[key]) || 0;
                    const newVal = parseFloat(newRow[key]) || 0;
                    const change = newVal - oldVal;
                    const changeType = change === 0 ? "" : (change > 0 ? 'positive' : 'negative');
                    diff[key] = {
                        old: oldVal,
                        new: newVal,
                        change: change,
                        type: changeType,
                        rowIndex: index
                    };
                }
            }

            if (Object.keys(diff).length > 0) {
                differences.push({ index, diff });
            }
        });

        return differences;
    };
    const compareData2 = (oldData, newData) => {
        const differences = [];

        oldData.forEach((oldRow, index) => {
            const newRow = newData[index];
            if (!newRow) return;
            const diff = {};

            for (const key in oldRow) {
                if (oldRow[key] !== newRow[key]) {
                    const oldVal = parseFloat(oldRow[key]) || 0;
                    const newVal = parseFloat(newRow[key]) || 0;
                    const change = newVal - oldVal;
                    const changeType = change === 0 ? "" : (change > 0 ? 'positive' : 'negative');
                    diff[key] = {
                        old: oldVal,
                        new: newVal,
                        change: change,
                        type: changeType,
                        rowIndex: index
                    };
                }
            }

            if (Object.keys(diff).length > 0) {
                differences.push({ index, diff });
            }
        });

        return differences;
    };
    const compareData3 = (oldData, newData) => {
        const differences = [];

        oldData.forEach((oldRow, index) => {
            const newRow = newData[index];
            if (!newRow) return;
            const diff = {};

            for (const key in oldRow) {
                if (oldRow[key] !== newRow[key]) {
                    const oldVal = parseFloat(oldRow[key]) || 0;
                    const newVal = parseFloat(newRow[key]) || 0;
                    const change = newVal - oldVal;
                    const changeType = change === 0 ? "" : (change > 0 ? 'positive' : 'negative');
                    diff[key] = {
                        old: oldVal,
                        new: newVal,
                        change: change,
                        type: changeType,
                        rowIndex: index
                    };
                }
            }

            if (Object.keys(diff).length > 0) {
                differences.push({ index, diff });
            }
        });

        return differences;
    };
    const compareData4 = (oldData, newData) => {
        const differences = [];

        oldData.forEach((oldRow, index) => {
            const newRow = newData[index];
            if (!newRow) return;
            const diff = {};

            for (const key in oldRow) {
                if (oldRow[key] !== newRow[key]) {
                    const oldVal = parseFloat(oldRow[key]) || 0;
                    const newVal = parseFloat(newRow[key]) || 0;
                    const change = newVal - oldVal;
                    const changeType = change === 0 ? "" : (change > 0 ? 'positive' : 'negative');
                    diff[key] = {
                        old: oldVal,
                        new: newVal,
                        change: change,
                        type: changeType,
                        rowIndex: index
                    };
                }
            }

            if (Object.keys(diff).length > 0) {
                differences.push({ index, diff });
            }
        });

        return differences;
    };
    const compareData5 = (oldData, newData) => {
        const differences = [];

        oldData.forEach((oldRow, index) => {
            const newRow = newData[index];
            if (!newRow) return;
            const diff = {};

            for (const key in oldRow) {
                if (oldRow[key] !== newRow[key]) {
                    const oldVal = parseFloat(oldRow[key]) || 0;
                    const newVal = parseFloat(newRow[key]) || 0;
                    const change = newVal - oldVal;
                    const changeType = change === 0 ? "" : (change > 0 ? 'positive' : 'negative');
                    diff[key] = {
                        old: oldVal,
                        new: newVal,
                        change: change,
                        type: changeType,
                        rowIndex: index
                    };
                }
            }

            if (Object.keys(diff).length > 0) {
                differences.push({ index, diff });
            }
        });

        return differences;
    };
    const compareData6 = (oldData, newData) => {
        const differences = [];

        oldData.forEach((oldRow, index) => {
            const newRow = newData[index];
            if (!newRow) return;
            const diff = {};

            for (const key in oldRow) {
                if (oldRow[key] !== newRow[key]) {
                    const oldVal = parseFloat(oldRow[key]) || 0;
                    const newVal = parseFloat(newRow[key]) || 0;
                    const change = newVal - oldVal;
                    const changeType = change === 0 ? "" : (change > 0 ? 'positive' : 'negative');
                    diff[key] = {
                        old: oldVal,
                        new: newVal,
                        change: change,
                        type: changeType,
                        rowIndex: index
                    };
                }
            }

            if (Object.keys(diff).length > 0) {
                differences.push({ index, diff });
            }
        });

        return differences;
    };
    const compareData7 = (oldData, newData) => {
        const differences = [];

        oldData.forEach((oldRow, index) => {
            const newRow = newData[index];
            if (!newRow) return;
            const diff = {};

            for (const key in oldRow) {
                if (oldRow[key] !== newRow[key]) {
                    const oldVal = parseFloat(oldRow[key]) || 0;
                    const newVal = parseFloat(newRow[key]) || 0;
                    const change = newVal - oldVal;
                    const changeType = change === 0 ? "" : (change > 0 ? 'positive' : 'negative');
                    diff[key] = {
                        old: oldVal,
                        new: newVal,
                        change: change,
                        type: changeType,
                        rowIndex: index
                    };
                }
            }

            if (Object.keys(diff).length > 0) {
                differences.push({ index, diff });
            }
        });

        return differences;
    };

    const isEmpty = (obj) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    }
    const isEmpty1 = (obj) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    }
    const isEmpty2 = (obj) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    }
    const isEmpty3 = (obj) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    }
    const isEmpty4 = (obj) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    }
    const isEmpty5 = (obj) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    }
    const isEmpty6 = (obj) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    }
    const isEmpty7 = (obj) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    }

    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
    const [speedChanged, setSpeedChanged] = useState(false);
    const prevSpeedRef = useRef(initialValues.speed);
    useEffect(() => {
        let interval;
        let speed = initialValues.speed == 0 || initialValues.speed == null ? 1 : initialValues.speed;
        if (isPlaying) {
            interval = setInterval(async () => {
                speed = initialValues.speed == 0 || initialValues.speed == null ? 1 : initialValues.speed;
                if (speedChanged) {
                    setLoading(true);
                    await delay(2000);
                    setLoading(false);
                }
                let time_minute_search = 0;
                setCountRecordBidAsk(prevCount => {
                    if (prevCount >= bidaskLength - 1) {
                        setIsPlaying(false);
                        clearInterval(interval);
                        return prevCount;
                    } else {
                        let bidask_per_sec = resultBidAsk[prevCount];
                        time_minute_search = dayjs(bidask_per_sec['Time']).format('YYYY-MM-DD HH:mm');
                        let time2show = dayjs(bidask_per_sec['Time']).format('HH:mm:ss');
                        setTime2Show(time2show);

                        const dateToCheck = dayjs(formatDate(initialValues.start_date))
                        const comparisonDate = dayjs('2023-05-08');
                        const isBefore = dateToCheck.isBefore(comparisonDate);

                        document.querySelectorAll('.change-tracking td').forEach(cell => {
                            cell.style.backgroundColor = '';
                        });
                        let currentBidAsk = []
                        const updatedRows = [...rows];
                        if (isBefore) {
                            if (bidask_per_sec != undefined) {
                                currentBidAsk[0] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid1), NumberWithCommasAndDecimal(bidask_per_sec.Bid1, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask5, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk5))
                                currentBidAsk[1] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid2), NumberWithCommasAndDecimal(bidask_per_sec.Bid2, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask4, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk4))
                                currentBidAsk[2] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid3), NumberWithCommasAndDecimal(bidask_per_sec.Bid3, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask3, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk3))
                                currentBidAsk[3] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid4), NumberWithCommasAndDecimal(bidask_per_sec.Bid4, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask2, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk2))
                                currentBidAsk[4] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid5), NumberWithCommasAndDecimal(bidask_per_sec.Bid5, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask1, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk1))
                                currentBidAsk[12] = createData(
                                    NumberWithCommasAndDecimal(parseFloat(bidask_per_sec.VolBid1) + parseFloat(bidask_per_sec.VolBid2) + parseFloat(bidask_per_sec.VolBid3) + parseFloat(bidask_per_sec.VolBid4) + parseFloat(bidask_per_sec.VolBid5)),
                                    "", "",
                                    NumberWithCommasAndDecimal(parseFloat(bidask_per_sec.VolAsk1) + parseFloat(bidask_per_sec.VolAsk2) + parseFloat(bidask_per_sec.VolAsk3) + parseFloat(bidask_per_sec.VolAsk4) + parseFloat(bidask_per_sec.VolAsk5)),
                                )
                                const extractedRows = currentBidAsk.map(extractAndConvertChildren);
                                if (!isEmpty(previousBidAsk.current)) {
                                    let compData = compareData(previousBidAsk.current, extractedRows);
                                    if (compData.length > 0) {
                                        compData.map((obj, index) => {
                                            const entries = Object.entries(obj.diff);
                                            entries.map(([key, value]) => {
                                                const cell = document.querySelector(`#cell-${value.rowIndex}-${key}`);
                                                if (cell) {
                                                    cell.style.backgroundColor = value.type === 'positive' ? 'lightgreen' : 'lightcoral';
                                                }
                                            });
                                        })
                                    }
                                }
                                updatedRows[0] = currentBidAsk[0];
                                updatedRows[1] = currentBidAsk[1];
                                updatedRows[2] = currentBidAsk[2];
                                updatedRows[3] = currentBidAsk[3];
                                updatedRows[4] = currentBidAsk[4];
                                updatedRows[12] = currentBidAsk[12];
                                previousBidAsk.current = extractedRows
                            }
                        } else {
                            if (bidask_per_sec != undefined) {
                                currentBidAsk[0] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid1), NumberWithCommasAndDecimal(bidask_per_sec.Bid1, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask10, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk10))
                                currentBidAsk[1] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid2), NumberWithCommasAndDecimal(bidask_per_sec.Bid2, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask9, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk9))
                                currentBidAsk[2] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid3), NumberWithCommasAndDecimal(bidask_per_sec.Bid3, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask8, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk8))
                                currentBidAsk[3] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid4), NumberWithCommasAndDecimal(bidask_per_sec.Bid4, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask7, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk7))
                                currentBidAsk[4] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid5), NumberWithCommasAndDecimal(bidask_per_sec.Bid5, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask6, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk6))
                                currentBidAsk[5] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid6), NumberWithCommasAndDecimal(bidask_per_sec.Bid6, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask5, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk5))
                                currentBidAsk[6] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid7), NumberWithCommasAndDecimal(bidask_per_sec.Bid7, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask4, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk4))
                                currentBidAsk[7] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid8), NumberWithCommasAndDecimal(bidask_per_sec.Bid8, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask3, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk3))
                                currentBidAsk[8] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid9), NumberWithCommasAndDecimal(bidask_per_sec.Bid9, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask2, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk2))
                                currentBidAsk[9] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid10), NumberWithCommasAndDecimal(bidask_per_sec.Bid10, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask1, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk1))
                                currentBidAsk[12] = createData(
                                    NumberWithCommasAndDecimal(
                                        parseFloat(bidask_per_sec.VolBid1) + parseFloat(bidask_per_sec.VolBid2) + parseFloat(bidask_per_sec.VolBid3) + parseFloat(bidask_per_sec.VolBid4) +
                                        parseFloat(bidask_per_sec.VolBid5) + parseFloat(bidask_per_sec.VolBid6) + parseFloat(bidask_per_sec.VolBid7) + parseFloat(bidask_per_sec.VolBid8) +
                                        parseFloat(bidask_per_sec.VolBid9) + parseFloat(bidask_per_sec.VolBid10)
                                    ),
                                    "", "",
                                    NumberWithCommasAndDecimal(
                                        parseFloat(bidask_per_sec.VolAsk1) + parseFloat(bidask_per_sec.VolAsk2) + parseFloat(bidask_per_sec.VolAsk3) + parseFloat(bidask_per_sec.VolAsk4) +
                                        parseFloat(bidask_per_sec.VolAsk5) + parseFloat(bidask_per_sec.VolAsk6) + parseFloat(bidask_per_sec.VolAsk7) + parseFloat(bidask_per_sec.VolAsk8) +
                                        parseFloat(bidask_per_sec.VolAsk9) + parseFloat(bidask_per_sec.VolAsk10)
                                    ),
                                )
                                const extractedRows = currentBidAsk.map(extractAndConvertChildren);
                                if (!isEmpty(previousBidAsk.current)) {
                                    let compData = compareData(previousBidAsk.current, extractedRows);
                                    if (compData.length > 0) {
                                        compData.map((obj, index) => {
                                            const entries = Object.entries(obj.diff);
                                            entries.map(([key, value]) => {
                                                const cell = document.querySelector(`#cell-${value.rowIndex}-${key}`);
                                                if (cell) {
                                                    cell.style.backgroundColor = value.type === 'positive' ? 'lightgreen' : 'lightcoral';
                                                }
                                            });
                                        })
                                    }
                                }
                                updatedRows[0] = currentBidAsk[0];
                                updatedRows[1] = currentBidAsk[1];
                                updatedRows[2] = currentBidAsk[2];
                                updatedRows[3] = currentBidAsk[3];
                                updatedRows[4] = currentBidAsk[4];
                                updatedRows[5] = currentBidAsk[5];
                                updatedRows[6] = currentBidAsk[6];
                                updatedRows[7] = currentBidAsk[7];
                                updatedRows[8] = currentBidAsk[8];
                                updatedRows[9] = currentBidAsk[9];
                                updatedRows[12] = currentBidAsk[12];
                                previousBidAsk.current = extractedRows
                            }
                        }
                        setRows(updatedRows);
                        if (resultTickMatch != undefined) {
                            resultTickMatch.filter((x) => {
                                return x.DtMinOfRec.substring(0, x.DtMinOfRec.length - 3) === time_minute_search;
                            })
                        }
                        return prevCount + 1;
                    }
                });
            }, 1000 / speed);
        }
        if (speed !== prevSpeedRef.current) {
            setSpeedChanged(true);
        } else {
            setSpeedChanged(false);
        }
        prevSpeedRef.current = speed;
        return () => clearInterval(interval);
    }, [isPlaying, countRecordBidAsk, initialValues.speed]);

    const delay1 = (ms) => new Promise(resolve => setTimeout(resolve, ms));
    const [speedChanged1, setSpeedChanged1] = useState(false);
    const prevSpeedRef1 = useRef(initialValues1.speed);
    useEffect(() => {
        let interval;
        let speed = initialValues1.speed == 0 || initialValues1.speed == null ? 1 : initialValues1.speed;
        if (isPlaying1) {
            interval = setInterval(async () => {
                speed = initialValues1.speed == 0 || initialValues1.speed == null ? 1 : initialValues1.speed;
                if (speedChanged1) {
                    setLoading(true);
                    await delay1(2000);
                    setLoading(false);
                }
                let time_minute_search = 0;
                setCountRecordBidAsk1(prevCount => {
                    if (prevCount >= bidaskLength1 - 1) {
                        setIsPlaying1(false);
                        clearInterval(interval);
                        return prevCount;
                    } else {
                        let bidask_per_sec = resultBidAsk1[prevCount];
                        time_minute_search = dayjs(bidask_per_sec['Time']).format('YYYY-MM-DD HH:mm');
                        let time2show1 = dayjs(bidask_per_sec['Time']).format('HH:mm:ss');
                        setTime2Show1(time2show1);

                        const dateToCheck = dayjs(formatDate1(initialValues1.start_date))
                        const comparisonDate = dayjs('2023-05-08');
                        const isBefore = dateToCheck.isBefore(comparisonDate);

                        document.querySelectorAll('.change-tracking td').forEach(cell => {
                            cell.style.backgroundColor = '';
                        });
                        let currentBidAsk = [];
                        const updatedRows = [...rows1];
                        if (isBefore) {
                            if (bidask_per_sec != undefined) {
                                currentBidAsk[0] = createData(NumberWithCommasAndDecimal1(bidask_per_sec.VolBid1), NumberWithCommasAndDecimal1(bidask_per_sec.Bid1, 2), NumberWithCommasAndDecimal1(bidask_per_sec.Ask5, 2), NumberWithCommasAndDecimal1(bidask_per_sec.VolAsk5));
                                currentBidAsk[1] = createData(NumberWithCommasAndDecimal1(bidask_per_sec.VolBid2), NumberWithCommasAndDecimal1(bidask_per_sec.Bid2, 2), NumberWithCommasAndDecimal1(bidask_per_sec.Ask4, 2), NumberWithCommasAndDecimal1(bidask_per_sec.VolAsk4));
                                currentBidAsk[2] = createData(NumberWithCommasAndDecimal1(bidask_per_sec.VolBid3), NumberWithCommasAndDecimal1(bidask_per_sec.Bid3, 2), NumberWithCommasAndDecimal1(bidask_per_sec.Ask3, 2), NumberWithCommasAndDecimal1(bidask_per_sec.VolAsk3));
                                currentBidAsk[3] = createData(NumberWithCommasAndDecimal1(bidask_per_sec.VolBid4), NumberWithCommasAndDecimal1(bidask_per_sec.Bid4, 2), NumberWithCommasAndDecimal1(bidask_per_sec.Ask2, 2), NumberWithCommasAndDecimal1(bidask_per_sec.VolAsk2));
                                currentBidAsk[4] = createData(NumberWithCommasAndDecimal1(bidask_per_sec.VolBid5), NumberWithCommasAndDecimal1(bidask_per_sec.Bid5, 2), NumberWithCommasAndDecimal1(bidask_per_sec.Ask1, 2), NumberWithCommasAndDecimal1(bidask_per_sec.VolAsk1));
                                currentBidAsk[12] = createData(
                                    NumberWithCommasAndDecimal1(parseFloat(bidask_per_sec.VolBid1) + parseFloat(bidask_per_sec.VolBid2) + parseFloat(bidask_per_sec.VolBid3) + parseFloat(bidask_per_sec.VolBid4) + parseFloat(bidask_per_sec.VolBid5)),
                                    "", "",
                                    NumberWithCommasAndDecimal1(parseFloat(bidask_per_sec.VolAsk1) + parseFloat(bidask_per_sec.VolAsk2) + parseFloat(bidask_per_sec.VolAsk3) + parseFloat(bidask_per_sec.VolAsk4) + parseFloat(bidask_per_sec.VolAsk5)),
                                );
                                const extractedRows = currentBidAsk.map(extractAndConvertChildren1);
                                if (!isEmpty1(previousBidAsk1.current)) {
                                    let compData = compareData1(previousBidAsk1.current, extractedRows);
                                    if (compData.length > 0) {
                                        compData.map((obj, index) => {
                                            const entries = Object.entries(obj.diff);
                                            entries.map(([key, value]) => {
                                                const cell = document.querySelector(`#row1-cell-${value.rowIndex}-${key}`);
                                                if (cell) {
                                                    cell.style.backgroundColor = value.type === 'positive' ? 'lightgreen' : 'lightcoral';
                                                }
                                            });
                                        });
                                    }
                                }
                                updatedRows[0] = currentBidAsk[0];
                                updatedRows[1] = currentBidAsk[1];
                                updatedRows[2] = currentBidAsk[2];
                                updatedRows[3] = currentBidAsk[3];
                                updatedRows[4] = currentBidAsk[4];
                                updatedRows[12] = currentBidAsk[12];
                                previousBidAsk1.current = extractedRows;
                            }
                        } else {
                            if (bidask_per_sec != undefined) {
                                currentBidAsk[0] = createData(NumberWithCommasAndDecimal1(bidask_per_sec.VolBid1), NumberWithCommasAndDecimal1(bidask_per_sec.Bid1, 2), NumberWithCommasAndDecimal1(bidask_per_sec.Ask10, 2), NumberWithCommasAndDecimal1(bidask_per_sec.VolAsk10));
                                currentBidAsk[1] = createData(NumberWithCommasAndDecimal1(bidask_per_sec.VolBid2), NumberWithCommasAndDecimal1(bidask_per_sec.Bid2, 2), NumberWithCommasAndDecimal1(bidask_per_sec.Ask9, 2), NumberWithCommasAndDecimal1(bidask_per_sec.VolAsk9));
                                currentBidAsk[2] = createData(NumberWithCommasAndDecimal1(bidask_per_sec.VolBid3), NumberWithCommasAndDecimal1(bidask_per_sec.Bid3, 2), NumberWithCommasAndDecimal1(bidask_per_sec.Ask8, 2), NumberWithCommasAndDecimal1(bidask_per_sec.VolAsk8));
                                currentBidAsk[3] = createData(NumberWithCommasAndDecimal1(bidask_per_sec.VolBid4), NumberWithCommasAndDecimal1(bidask_per_sec.Bid4, 2), NumberWithCommasAndDecimal1(bidask_per_sec.Ask7, 2), NumberWithCommasAndDecimal1(bidask_per_sec.VolAsk7));
                                currentBidAsk[4] = createData(NumberWithCommasAndDecimal1(bidask_per_sec.VolBid5), NumberWithCommasAndDecimal1(bidask_per_sec.Bid5, 2), NumberWithCommasAndDecimal1(bidask_per_sec.Ask6, 2), NumberWithCommasAndDecimal1(bidask_per_sec.VolAsk6));
                                currentBidAsk[5] = createData(NumberWithCommasAndDecimal1(bidask_per_sec.VolBid6), NumberWithCommasAndDecimal1(bidask_per_sec.Bid6, 2), NumberWithCommasAndDecimal1(bidask_per_sec.Ask5, 2), NumberWithCommasAndDecimal1(bidask_per_sec.VolAsk5));
                                currentBidAsk[6] = createData(NumberWithCommasAndDecimal1(bidask_per_sec.VolBid7), NumberWithCommasAndDecimal1(bidask_per_sec.Bid7, 2), NumberWithCommasAndDecimal1(bidask_per_sec.Ask4, 2), NumberWithCommasAndDecimal1(bidask_per_sec.VolAsk4));
                                currentBidAsk[7] = createData(NumberWithCommasAndDecimal1(bidask_per_sec.VolBid8), NumberWithCommasAndDecimal1(bidask_per_sec.Bid8, 2), NumberWithCommasAndDecimal1(bidask_per_sec.Ask3, 2), NumberWithCommasAndDecimal1(bidask_per_sec.VolAsk3));
                                currentBidAsk[8] = createData(NumberWithCommasAndDecimal1(bidask_per_sec.VolBid9), NumberWithCommasAndDecimal1(bidask_per_sec.Bid9, 2), NumberWithCommasAndDecimal1(bidask_per_sec.Ask2, 2), NumberWithCommasAndDecimal1(bidask_per_sec.VolAsk2));
                                currentBidAsk[9] = createData(NumberWithCommasAndDecimal1(bidask_per_sec.VolBid10), NumberWithCommasAndDecimal1(bidask_per_sec.Bid10, 2), NumberWithCommasAndDecimal1(bidask_per_sec.Ask1, 2), NumberWithCommasAndDecimal1(bidask_per_sec.VolAsk1));
                                currentBidAsk[12] = createData(
                                    NumberWithCommasAndDecimal1(
                                        parseFloat(bidask_per_sec.VolBid1) + parseFloat(bidask_per_sec.VolBid2) + parseFloat(bidask_per_sec.VolBid3) + parseFloat(bidask_per_sec.VolBid4) +
                                        parseFloat(bidask_per_sec.VolBid5) + parseFloat(bidask_per_sec.VolBid6) + parseFloat(bidask_per_sec.VolBid7) + parseFloat(bidask_per_sec.VolBid8) +
                                        parseFloat(bidask_per_sec.VolBid9) + parseFloat(bidask_per_sec.VolBid10)
                                    ),
                                    "", "",
                                    NumberWithCommasAndDecimal1(
                                        parseFloat(bidask_per_sec.VolAsk1) + parseFloat(bidask_per_sec.VolAsk2) + parseFloat(bidask_per_sec.VolAsk3) + parseFloat(bidask_per_sec.VolAsk4) +
                                        parseFloat(bidask_per_sec.VolAsk5) + parseFloat(bidask_per_sec.VolAsk6) + parseFloat(bidask_per_sec.VolAsk7) + parseFloat(bidask_per_sec.VolAsk8) +
                                        parseFloat(bidask_per_sec.VolAsk9) + parseFloat(bidask_per_sec.VolAsk10)
                                    ),
                                );
                                const extractedRows = currentBidAsk.map(extractAndConvertChildren1);
                                if (!isEmpty1(previousBidAsk1.current)) {
                                    let compData = compareData1(previousBidAsk1.current, extractedRows);
                                    if (compData.length > 0) {
                                        compData.map((obj, index) => {
                                            const entries = Object.entries(obj.diff);
                                            entries.map(([key, value]) => {
                                                const cell = document.querySelector(`#row1-cell-${value.rowIndex}-${key}`);
                                                if (cell) {
                                                    cell.style.backgroundColor = value.type === 'positive' ? 'lightgreen' : 'lightcoral';
                                                }
                                            });
                                        });
                                    }
                                }
                                updatedRows[0] = currentBidAsk[0];
                                updatedRows[1] = currentBidAsk[1];
                                updatedRows[2] = currentBidAsk[2];
                                updatedRows[3] = currentBidAsk[3];
                                updatedRows[4] = currentBidAsk[4];
                                updatedRows[5] = currentBidAsk[5];
                                updatedRows[6] = currentBidAsk[6];
                                updatedRows[7] = currentBidAsk[7];
                                updatedRows[8] = currentBidAsk[8];
                                updatedRows[9] = currentBidAsk[9];
                                updatedRows[12] = currentBidAsk[12];
                                previousBidAsk1.current = extractedRows;
                            }
                        }
                        setRows1(updatedRows);

                        if (resultTickMatch1 != undefined) {
                            resultTickMatch1.filter((x) => {
                                return x.DtMinOfRec.substring(0, x.DtMinOfRec.length - 3) === time_minute_search;
                            });
                        }
                        return prevCount + 1;
                    }
                });
            }, 1000 / speed);
        }
        if (speed !== prevSpeedRef1.current) {
            setSpeedChanged1(true);
        } else {
            setSpeedChanged1(false);
        }
        prevSpeedRef1.current = speed;
        return () => clearInterval(interval);
    }, [isPlaying1, countRecordBidAsk1, initialValues1.speed]);


    const delay2 = (ms) => new Promise(resolve => setTimeout(resolve, ms));
    const [speedChanged2, setSpeedChanged2] = useState(false);
    const prevSpeedRef2 = useRef(initialValues2.speed);
    useEffect(() => {
        let interval;
        let speed = initialValues2.speed == 0 || initialValues2.speed == null ? 1 : initialValues2.speed;
        if (isPlaying2) {
            interval = setInterval(async () => {
                speed = initialValues2.speed == 0 || initialValues2.speed == null ? 1 : initialValues2.speed;
                if (speedChanged2) {
                    setLoading(true);
                    await delay2(2000);
                    setLoading(false);
                }
                let time_minute_search = 0;
                setCountRecordBidAsk2(prevCount => {
                    if (prevCount >= bidaskLength2 - 1) {
                        setIsPlaying2(false);
                        clearInterval(interval);
                        return prevCount;
                    } else {
                        let bidask_per_sec = resultBidAsk2[prevCount];
                        time_minute_search = dayjs(bidask_per_sec['Time']).format('YYYY-MM-DD HH:mm');
                        let time2show2 = dayjs(bidask_per_sec['Time']).format('HH:mm:ss');
                        setTime2Show2(time2show2);

                        const dateToCheck = dayjs(formatDate2(initialValues.start_date))
                        const comparisonDate = dayjs('2023-05-08');
                        const isBefore = dateToCheck.isBefore(comparisonDate);

                        document.querySelectorAll('.change-tracking td').forEach(cell => {
                            cell.style.backgroundColor = '';
                        });
                        let currentBidAsk = [];
                        const updatedRows = [...rows2];
                        if (isBefore) {
                            if (bidask_per_sec != undefined) {
                                currentBidAsk[0] = createData(NumberWithCommasAndDecimal2(bidask_per_sec.VolBid1), NumberWithCommasAndDecimal2(bidask_per_sec.Bid1, 2), NumberWithCommasAndDecimal2(bidask_per_sec.Ask5, 2), NumberWithCommasAndDecimal2(bidask_per_sec.VolAsk5));
                                currentBidAsk[1] = createData(NumberWithCommasAndDecimal2(bidask_per_sec.VolBid2), NumberWithCommasAndDecimal2(bidask_per_sec.Bid2, 2), NumberWithCommasAndDecimal2(bidask_per_sec.Ask4, 2), NumberWithCommasAndDecimal2(bidask_per_sec.VolAsk4));
                                currentBidAsk[2] = createData(NumberWithCommasAndDecimal2(bidask_per_sec.VolBid3), NumberWithCommasAndDecimal2(bidask_per_sec.Bid3, 2), NumberWithCommasAndDecimal2(bidask_per_sec.Ask3, 2), NumberWithCommasAndDecimal2(bidask_per_sec.VolAsk3));
                                currentBidAsk[3] = createData(NumberWithCommasAndDecimal2(bidask_per_sec.VolBid4), NumberWithCommasAndDecimal2(bidask_per_sec.Bid4, 2), NumberWithCommasAndDecimal2(bidask_per_sec.Ask2, 2), NumberWithCommasAndDecimal2(bidask_per_sec.VolAsk2));
                                currentBidAsk[4] = createData(NumberWithCommasAndDecimal2(bidask_per_sec.VolBid5), NumberWithCommasAndDecimal2(bidask_per_sec.Bid5, 2), NumberWithCommasAndDecimal2(bidask_per_sec.Ask1, 2), NumberWithCommasAndDecimal2(bidask_per_sec.VolAsk1));
                                currentBidAsk[12] = createData(
                                    NumberWithCommasAndDecimal7(parseFloat(bidask_per_sec.VolBid1) + parseFloat(bidask_per_sec.VolBid2) + parseFloat(bidask_per_sec.VolBid3) + parseFloat(bidask_per_sec.VolBid4) + parseFloat(bidask_per_sec.VolBid5)),
                                    "", "",
                                    NumberWithCommasAndDecimal7(parseFloat(bidask_per_sec.VolAsk1) + parseFloat(bidask_per_sec.VolAsk2) + parseFloat(bidask_per_sec.VolAsk3) + parseFloat(bidask_per_sec.VolAsk4) + parseFloat(bidask_per_sec.VolAsk5)),
                                );
                                const extractedRows = currentBidAsk.map(extractAndConvertChildren2);
                                if (!isEmpty2(previousBidAsk2.current)) {
                                    let compData = compareData2(previousBidAsk2.current, extractedRows);
                                    if (compData.length > 0) {
                                        compData.map((obj, index) => {
                                            const entries = Object.entries(obj.diff);
                                            entries.map(([key, value]) => {
                                                const cell = document.querySelector(`#row2-cell-${value.rowIndex}-${key}`);
                                                if (cell) {
                                                    cell.style.backgroundColor = value.type === 'positive' ? 'lightgreen' : 'lightcoral';
                                                }
                                            });
                                        });
                                    }
                                }
                                updatedRows[0] = currentBidAsk[0];
                                updatedRows[1] = currentBidAsk[1];
                                updatedRows[2] = currentBidAsk[2];
                                updatedRows[3] = currentBidAsk[3];
                                updatedRows[4] = currentBidAsk[4];
                                updatedRows[12] = currentBidAsk[12];
                                previousBidAsk2.current = extractedRows;
                            }
                        } else {
                            if (bidask_per_sec != undefined) {
                                currentBidAsk[0] = createData(NumberWithCommasAndDecimal2(bidask_per_sec.VolBid1), NumberWithCommasAndDecimal2(bidask_per_sec.Bid1, 2), NumberWithCommasAndDecimal2(bidask_per_sec.Ask10, 2), NumberWithCommasAndDecimal2(bidask_per_sec.VolAsk10));
                                currentBidAsk[1] = createData(NumberWithCommasAndDecimal2(bidask_per_sec.VolBid2), NumberWithCommasAndDecimal2(bidask_per_sec.Bid2, 2), NumberWithCommasAndDecimal2(bidask_per_sec.Ask9, 2), NumberWithCommasAndDecimal2(bidask_per_sec.VolAsk9));
                                currentBidAsk[2] = createData(NumberWithCommasAndDecimal2(bidask_per_sec.VolBid3), NumberWithCommasAndDecimal2(bidask_per_sec.Bid3, 2), NumberWithCommasAndDecimal2(bidask_per_sec.Ask8, 2), NumberWithCommasAndDecimal2(bidask_per_sec.VolAsk8));
                                currentBidAsk[3] = createData(NumberWithCommasAndDecimal2(bidask_per_sec.VolBid4), NumberWithCommasAndDecimal2(bidask_per_sec.Bid4, 2), NumberWithCommasAndDecimal2(bidask_per_sec.Ask7, 2), NumberWithCommasAndDecimal2(bidask_per_sec.VolAsk7));
                                currentBidAsk[4] = createData(NumberWithCommasAndDecimal2(bidask_per_sec.VolBid5), NumberWithCommasAndDecimal2(bidask_per_sec.Bid5, 2), NumberWithCommasAndDecimal2(bidask_per_sec.Ask6, 2), NumberWithCommasAndDecimal2(bidask_per_sec.VolAsk6));
                                currentBidAsk[5] = createData(NumberWithCommasAndDecimal2(bidask_per_sec.VolBid6), NumberWithCommasAndDecimal2(bidask_per_sec.Bid6, 2), NumberWithCommasAndDecimal2(bidask_per_sec.Ask5, 2), NumberWithCommasAndDecimal2(bidask_per_sec.VolAsk5));
                                currentBidAsk[6] = createData(NumberWithCommasAndDecimal2(bidask_per_sec.VolBid7), NumberWithCommasAndDecimal2(bidask_per_sec.Bid7, 2), NumberWithCommasAndDecimal2(bidask_per_sec.Ask4, 2), NumberWithCommasAndDecimal2(bidask_per_sec.VolAsk4));
                                currentBidAsk[7] = createData(NumberWithCommasAndDecimal2(bidask_per_sec.VolBid8), NumberWithCommasAndDecimal2(bidask_per_sec.Bid8, 2), NumberWithCommasAndDecimal2(bidask_per_sec.Ask3, 2), NumberWithCommasAndDecimal2(bidask_per_sec.VolAsk3));
                                currentBidAsk[8] = createData(NumberWithCommasAndDecimal2(bidask_per_sec.VolBid9), NumberWithCommasAndDecimal2(bidask_per_sec.Bid9, 2), NumberWithCommasAndDecimal2(bidask_per_sec.Ask2, 2), NumberWithCommasAndDecimal2(bidask_per_sec.VolAsk2));
                                currentBidAsk[9] = createData(NumberWithCommasAndDecimal2(bidask_per_sec.VolBid10), NumberWithCommasAndDecimal2(bidask_per_sec.Bid10, 2), NumberWithCommasAndDecimal2(bidask_per_sec.Ask1, 2), NumberWithCommasAndDecimal2(bidask_per_sec.VolAsk1));
                                currentBidAsk[12] = createData(
                                    NumberWithCommasAndDecimal7(
                                        parseFloat(bidask_per_sec.VolBid1) + parseFloat(bidask_per_sec.VolBid2) + parseFloat(bidask_per_sec.VolBid3) + parseFloat(bidask_per_sec.VolBid4) +
                                        parseFloat(bidask_per_sec.VolBid5) + parseFloat(bidask_per_sec.VolBid6) + parseFloat(bidask_per_sec.VolBid7) + parseFloat(bidask_per_sec.VolBid8) +
                                        parseFloat(bidask_per_sec.VolBid9) + parseFloat(bidask_per_sec.VolBid10)
                                    ),
                                    "", "",
                                    NumberWithCommasAndDecimal7(
                                        parseFloat(bidask_per_sec.VolAsk1) + parseFloat(bidask_per_sec.VolAsk2) + parseFloat(bidask_per_sec.VolAsk3) + parseFloat(bidask_per_sec.VolAsk4) +
                                        parseFloat(bidask_per_sec.VolAsk5) + parseFloat(bidask_per_sec.VolAsk6) + parseFloat(bidask_per_sec.VolAsk7) + parseFloat(bidask_per_sec.VolAsk8) +
                                        parseFloat(bidask_per_sec.VolAsk9) + parseFloat(bidask_per_sec.VolAsk10)
                                    ),
                                );
                                const extractedRows = currentBidAsk.map(extractAndConvertChildren2);
                                if (!isEmpty2(previousBidAsk2.current)) {
                                    let compData = compareData2(previousBidAsk2.current, extractedRows);
                                    if (compData.length > 0) {
                                        compData.map((obj, index) => {
                                            const entries = Object.entries(obj.diff);
                                            entries.map(([key, value]) => {
                                                const cell = document.querySelector(`#row2-cell-${value.rowIndex}-${key}`);
                                                if (cell) {
                                                    cell.style.backgroundColor = value.type === 'positive' ? 'lightgreen' : 'lightcoral';
                                                }
                                            });
                                        });
                                    }
                                }
                                updatedRows[0] = currentBidAsk[0];
                                updatedRows[1] = currentBidAsk[1];
                                updatedRows[2] = currentBidAsk[2];
                                updatedRows[3] = currentBidAsk[3];
                                updatedRows[4] = currentBidAsk[4];
                                updatedRows[5] = currentBidAsk[5];
                                updatedRows[6] = currentBidAsk[6];
                                updatedRows[7] = currentBidAsk[7];
                                updatedRows[8] = currentBidAsk[8];
                                updatedRows[9] = currentBidAsk[9];
                                updatedRows[12] = currentBidAsk[12];
                                previousBidAsk2.current = extractedRows;
                            }
                        }
                        setRows2(updatedRows);
                        if (resultTickMatch2 != undefined) {
                            resultTickMatch2.filter((x) => {
                                return x.DtMinOfRec.substring(0, x.DtMinOfRec.length - 3) === time_minute_search;
                            });
                        }
                        return prevCount + 1;
                    }
                });
            }, 1000 / speed);
        }
        if (speed !== prevSpeedRef2.current) {
            setSpeedChanged2(true);
        } else {
            setSpeedChanged2(false);
        }
        prevSpeedRef2.current = speed;
        return () => clearInterval(interval);
    }, [isPlaying2, countRecordBidAsk2, initialValues2.speed]);

    const delay3 = (ms) => new Promise(resolve => setTimeout(resolve, ms));
    const [speedChanged3, setSpeedChanged3] = useState(false);
    const prevSpeedRef3 = useRef(initialValues3.speed);
    useEffect(() => {
        let interval;
        let speed = initialValues3.speed == 0 || initialValues3.speed == null ? 1 : initialValues3.speed;
        if (isPlaying3) {
            interval = setInterval(async () => {
                speed = initialValues3.speed == 0 || initialValues3.speed == null ? 1 : initialValues3.speed;
                if (speedChanged3) {
                    setLoading(true);
                    await delay3(2000);
                    setLoading(false);
                }
                let time_minute_search = 0;
                setCountRecordBidAsk3(prevCount => {
                    if (prevCount >= bidaskLength3 - 1) {
                        setIsPlaying3(false);
                        clearInterval(interval);
                        return prevCount;
                    } else {
                        let bidask_per_sec = resultBidAsk3[prevCount];
                        time_minute_search = dayjs(bidask_per_sec['Time']).format('YYYY-MM-DD HH:mm');
                        let time2show3 = dayjs(bidask_per_sec['Time']).format('HH:mm:ss');
                        setTime2Show3(time2show3);

                        const dateToCheck = dayjs(formatDate3(initialValues.start_date))
                        const comparisonDate = dayjs('2023-05-08');
                        const isBefore = dateToCheck.isBefore(comparisonDate);

                        document.querySelectorAll('.change-tracking td').forEach(cell => {
                            cell.style.backgroundColor = '';
                        });
                        let currentBidAsk = [];
                        const updatedRows = [...rows3];
                        if (isBefore) {
                            if (bidask_per_sec != undefined) {
                                currentBidAsk[0] = createData(NumberWithCommasAndDecimal3(bidask_per_sec.VolBid1), NumberWithCommasAndDecimal3(bidask_per_sec.Bid1, 2), NumberWithCommasAndDecimal3(bidask_per_sec.Ask5, 2), NumberWithCommasAndDecimal3(bidask_per_sec.VolAsk5));
                                currentBidAsk[1] = createData(NumberWithCommasAndDecimal3(bidask_per_sec.VolBid2), NumberWithCommasAndDecimal3(bidask_per_sec.Bid2, 2), NumberWithCommasAndDecimal3(bidask_per_sec.Ask4, 2), NumberWithCommasAndDecimal3(bidask_per_sec.VolAsk4));
                                currentBidAsk[2] = createData(NumberWithCommasAndDecimal3(bidask_per_sec.VolBid3), NumberWithCommasAndDecimal3(bidask_per_sec.Bid3, 2), NumberWithCommasAndDecimal3(bidask_per_sec.Ask3, 2), NumberWithCommasAndDecimal3(bidask_per_sec.VolAsk3));
                                currentBidAsk[3] = createData(NumberWithCommasAndDecimal3(bidask_per_sec.VolBid4), NumberWithCommasAndDecimal3(bidask_per_sec.Bid4, 2), NumberWithCommasAndDecimal3(bidask_per_sec.Ask2, 2), NumberWithCommasAndDecimal3(bidask_per_sec.VolAsk2));
                                currentBidAsk[4] = createData(NumberWithCommasAndDecimal3(bidask_per_sec.VolBid5), NumberWithCommasAndDecimal3(bidask_per_sec.Bid5, 2), NumberWithCommasAndDecimal3(bidask_per_sec.Ask1, 2), NumberWithCommasAndDecimal3(bidask_per_sec.VolAsk1));
                                currentBidAsk[12] = createData(
                                    NumberWithCommasAndDecimal3(parseFloat(bidask_per_sec.VolBid1) + parseFloat(bidask_per_sec.VolBid2) + parseFloat(bidask_per_sec.VolBid3) + parseFloat(bidask_per_sec.VolBid4) + parseFloat(bidask_per_sec.VolBid5)),
                                    "", "",
                                    NumberWithCommasAndDecimal3(parseFloat(bidask_per_sec.VolAsk1) + parseFloat(bidask_per_sec.VolAsk2) + parseFloat(bidask_per_sec.VolAsk3) + parseFloat(bidask_per_sec.VolAsk4) + parseFloat(bidask_per_sec.VolAsk5)),
                                );
                                const extractedRows = currentBidAsk.map(extractAndConvertChildren3);
                                if (!isEmpty3(previousBidAsk3.current)) {
                                    let compData = compareData3(previousBidAsk3.current, extractedRows);
                                    if (compData.length > 0) {
                                        compData.map((obj, index) => {
                                            const entries = Object.entries(obj.diff);
                                            entries.map(([key, value]) => {
                                                const cell = document.querySelector(`#row3-cell-${value.rowIndex}-${key}`);
                                                if (cell) {
                                                    cell.style.backgroundColor = value.type === 'positive' ? 'lightgreen' : 'lightcoral';
                                                }
                                            });
                                        });
                                    }
                                }
                                updatedRows[0] = currentBidAsk[0];
                                updatedRows[1] = currentBidAsk[1];
                                updatedRows[2] = currentBidAsk[2];
                                updatedRows[3] = currentBidAsk[3];
                                updatedRows[4] = currentBidAsk[4];
                                updatedRows[12] = currentBidAsk[12];
                                previousBidAsk3.current = extractedRows;
                            }
                        } else {
                            if (bidask_per_sec != undefined) {
                                currentBidAsk[0] = createData(NumberWithCommasAndDecimal3(bidask_per_sec.VolBid1), NumberWithCommasAndDecimal3(bidask_per_sec.Bid1, 2), NumberWithCommasAndDecimal3(bidask_per_sec.Ask10, 2), NumberWithCommasAndDecimal3(bidask_per_sec.VolAsk10));
                                currentBidAsk[1] = createData(NumberWithCommasAndDecimal3(bidask_per_sec.VolBid2), NumberWithCommasAndDecimal3(bidask_per_sec.Bid2, 2), NumberWithCommasAndDecimal3(bidask_per_sec.Ask9, 2), NumberWithCommasAndDecimal3(bidask_per_sec.VolAsk9));
                                currentBidAsk[2] = createData(NumberWithCommasAndDecimal3(bidask_per_sec.VolBid3), NumberWithCommasAndDecimal3(bidask_per_sec.Bid3, 2), NumberWithCommasAndDecimal3(bidask_per_sec.Ask8, 2), NumberWithCommasAndDecimal3(bidask_per_sec.VolAsk8));
                                currentBidAsk[3] = createData(NumberWithCommasAndDecimal3(bidask_per_sec.VolBid4), NumberWithCommasAndDecimal3(bidask_per_sec.Bid4, 2), NumberWithCommasAndDecimal3(bidask_per_sec.Ask7, 2), NumberWithCommasAndDecimal3(bidask_per_sec.VolAsk7));
                                currentBidAsk[4] = createData(NumberWithCommasAndDecimal3(bidask_per_sec.VolBid5), NumberWithCommasAndDecimal3(bidask_per_sec.Bid5, 2), NumberWithCommasAndDecimal3(bidask_per_sec.Ask6, 2), NumberWithCommasAndDecimal3(bidask_per_sec.VolAsk6));
                                currentBidAsk[5] = createData(NumberWithCommasAndDecimal3(bidask_per_sec.VolBid6), NumberWithCommasAndDecimal3(bidask_per_sec.Bid6, 2), NumberWithCommasAndDecimal3(bidask_per_sec.Ask5, 2), NumberWithCommasAndDecimal3(bidask_per_sec.VolAsk5));
                                currentBidAsk[6] = createData(NumberWithCommasAndDecimal3(bidask_per_sec.VolBid7), NumberWithCommasAndDecimal3(bidask_per_sec.Bid7, 2), NumberWithCommasAndDecimal3(bidask_per_sec.Ask4, 2), NumberWithCommasAndDecimal3(bidask_per_sec.VolAsk4));
                                currentBidAsk[7] = createData(NumberWithCommasAndDecimal3(bidask_per_sec.VolBid8), NumberWithCommasAndDecimal3(bidask_per_sec.Bid8, 2), NumberWithCommasAndDecimal3(bidask_per_sec.Ask3, 2), NumberWithCommasAndDecimal3(bidask_per_sec.VolAsk3));
                                currentBidAsk[8] = createData(NumberWithCommasAndDecimal3(bidask_per_sec.VolBid9), NumberWithCommasAndDecimal3(bidask_per_sec.Bid9, 2), NumberWithCommasAndDecimal3(bidask_per_sec.Ask2, 2), NumberWithCommasAndDecimal3(bidask_per_sec.VolAsk2));
                                currentBidAsk[9] = createData(NumberWithCommasAndDecimal3(bidask_per_sec.VolBid10), NumberWithCommasAndDecimal3(bidask_per_sec.Bid10, 2), NumberWithCommasAndDecimal3(bidask_per_sec.Ask1, 2), NumberWithCommasAndDecimal3(bidask_per_sec.VolAsk1));
                                currentBidAsk[12] = createData(
                                    NumberWithCommasAndDecimal3(
                                        parseFloat(bidask_per_sec.VolBid1) + parseFloat(bidask_per_sec.VolBid2) + parseFloat(bidask_per_sec.VolBid3) + parseFloat(bidask_per_sec.VolBid4) +
                                        parseFloat(bidask_per_sec.VolBid5) + parseFloat(bidask_per_sec.VolBid6) + parseFloat(bidask_per_sec.VolBid7) + parseFloat(bidask_per_sec.VolBid8) +
                                        parseFloat(bidask_per_sec.VolBid9) + parseFloat(bidask_per_sec.VolBid10)
                                    ),
                                    "", "",
                                    NumberWithCommasAndDecimal3(
                                        parseFloat(bidask_per_sec.VolAsk1) + parseFloat(bidask_per_sec.VolAsk2) + parseFloat(bidask_per_sec.VolAsk3) + parseFloat(bidask_per_sec.VolAsk4) +
                                        parseFloat(bidask_per_sec.VolAsk5) + parseFloat(bidask_per_sec.VolAsk6) + parseFloat(bidask_per_sec.VolAsk7) + parseFloat(bidask_per_sec.VolAsk8) +
                                        parseFloat(bidask_per_sec.VolAsk9) + parseFloat(bidask_per_sec.VolAsk10)
                                    ),
                                );
                                const extractedRows = currentBidAsk.map(extractAndConvertChildren3);
                                if (!isEmpty3(previousBidAsk3.current)) {
                                    let compData = compareData3(previousBidAsk3.current, extractedRows);
                                    if (compData.length > 0) {
                                        compData.map((obj, index) => {
                                            const entries = Object.entries(obj.diff);
                                            entries.map(([key, value]) => {
                                                const cell = document.querySelector(`#row3-cell-${value.rowIndex}-${key}`);
                                                if (cell) {
                                                    cell.style.backgroundColor = value.type === 'positive' ? 'lightgreen' : 'lightcoral';
                                                }
                                            });
                                        });
                                    }
                                }
                                updatedRows[0] = currentBidAsk[0];
                                updatedRows[1] = currentBidAsk[1];
                                updatedRows[2] = currentBidAsk[2];
                                updatedRows[3] = currentBidAsk[3];
                                updatedRows[4] = currentBidAsk[4];
                                updatedRows[5] = currentBidAsk[5];
                                updatedRows[6] = currentBidAsk[6];
                                updatedRows[7] = currentBidAsk[7];
                                updatedRows[8] = currentBidAsk[8];
                                updatedRows[9] = currentBidAsk[9];
                                updatedRows[12] = currentBidAsk[12];
                                previousBidAsk3.current = extractedRows;
                            }
                        }
                        setRows3(updatedRows);
                        if (resultTickMatch3 != undefined) {
                            resultTickMatch3.filter((x) => {
                                return x.DtMinOfRec.substring(0, x.DtMinOfRec.length - 3) === time_minute_search;
                            });
                        }
                        return prevCount + 1;
                    }
                });
            }, 1000 / speed);
        }
        if (speed !== prevSpeedRef3.current) {
            setSpeedChanged3(true);
        } else {
            setSpeedChanged3(false);
        }
        prevSpeedRef3.current = speed;
        return () => clearInterval(interval);
    }, [isPlaying3, countRecordBidAsk3, initialValues3.speed]);

    const delay4 = (ms) => new Promise(resolve => setTimeout(resolve, ms));
    const [speedChanged4, setSpeedChanged4] = useState(false);
    const prevSpeedRef4 = useRef(initialValues4.speed);
    useEffect(() => {
        let interval;
        let speed = initialValues4.speed == 0 || initialValues4.speed == null ? 1 : initialValues4.speed;
        if (isPlaying4) {
            interval = setInterval(async () => {
                speed = initialValues4.speed == 0 || initialValues4.speed == null ? 1 : initialValues4.speed;
                if (speedChanged4) {
                    setLoading(true);
                    await delay4(2000);
                    setLoading(false);
                }
                let time_minute_search = 0;
                setCountRecordBidAsk4(prevCount => {
                    if (prevCount >= bidaskLength4 - 1) {
                        setIsPlaying4(false);
                        clearInterval(interval);
                        return prevCount;
                    } else {
                        let bidask_per_sec = resultBidAsk4[prevCount];
                        time_minute_search = dayjs(bidask_per_sec['Time']).format('YYYY-MM-DD HH:mm');
                        let time2show4 = dayjs(bidask_per_sec['Time']).format('HH:mm:ss');
                        setTime2Show4(time2show4);

                        const dateToCheck = dayjs(formatDate4(initialValues.start_date))
                        const comparisonDate = dayjs('2023-05-08');
                        const isBefore = dateToCheck.isBefore(comparisonDate);

                        document.querySelectorAll('.change-tracking td').forEach(cell => {
                            cell.style.backgroundColor = '';
                        });
                        let currentBidAsk = [];
                        const updatedRows = [...rows4];
                        if (isBefore) {
                            if (bidask_per_sec != undefined) {
                                currentBidAsk[0] = createData(NumberWithCommasAndDecimal4(bidask_per_sec.VolBid1), NumberWithCommasAndDecimal4(bidask_per_sec.Bid1, 2), NumberWithCommasAndDecimal4(bidask_per_sec.Ask5, 2), NumberWithCommasAndDecimal4(bidask_per_sec.VolAsk5));
                                currentBidAsk[1] = createData(NumberWithCommasAndDecimal4(bidask_per_sec.VolBid2), NumberWithCommasAndDecimal4(bidask_per_sec.Bid2, 2), NumberWithCommasAndDecimal4(bidask_per_sec.Ask4, 2), NumberWithCommasAndDecimal4(bidask_per_sec.VolAsk4));
                                currentBidAsk[2] = createData(NumberWithCommasAndDecimal4(bidask_per_sec.VolBid3), NumberWithCommasAndDecimal4(bidask_per_sec.Bid3, 2), NumberWithCommasAndDecimal4(bidask_per_sec.Ask3, 2), NumberWithCommasAndDecimal4(bidask_per_sec.VolAsk3));
                                currentBidAsk[3] = createData(NumberWithCommasAndDecimal4(bidask_per_sec.VolBid4), NumberWithCommasAndDecimal4(bidask_per_sec.Bid4, 2), NumberWithCommasAndDecimal4(bidask_per_sec.Ask2, 2), NumberWithCommasAndDecimal4(bidask_per_sec.VolAsk2));
                                currentBidAsk[4] = createData(NumberWithCommasAndDecimal4(bidask_per_sec.VolBid5), NumberWithCommasAndDecimal4(bidask_per_sec.Bid5, 2), NumberWithCommasAndDecimal4(bidask_per_sec.Ask1, 2), NumberWithCommasAndDecimal4(bidask_per_sec.VolAsk1));
                                currentBidAsk[12] = createData(
                                    NumberWithCommasAndDecimal4(parseFloat(bidask_per_sec.VolBid1) + parseFloat(bidask_per_sec.VolBid2) + parseFloat(bidask_per_sec.VolBid3) + parseFloat(bidask_per_sec.VolBid4) + parseFloat(bidask_per_sec.VolBid5)),
                                    "", "",
                                    NumberWithCommasAndDecimal4(parseFloat(bidask_per_sec.VolAsk1) + parseFloat(bidask_per_sec.VolAsk2) + parseFloat(bidask_per_sec.VolAsk3) + parseFloat(bidask_per_sec.VolAsk4) + parseFloat(bidask_per_sec.VolAsk5)),
                                );
                                const extractedRows = currentBidAsk.map(extractAndConvertChildren4);
                                if (!isEmpty4(previousBidAsk4.current)) {
                                    let compData = compareData4(previousBidAsk4.current, extractedRows);
                                    if (compData.length > 0) {
                                        compData.map((obj, index) => {
                                            const entries = Object.entries(obj.diff);
                                            entries.map(([key, value]) => {
                                                const cell = document.querySelector(`#row4-cell-${value.rowIndex}-${key}`);
                                                if (cell) {
                                                    cell.style.backgroundColor = value.type === 'positive' ? 'lightgreen' : 'lightcoral';
                                                }
                                            });
                                        });
                                    }
                                }
                                updatedRows[0] = currentBidAsk[0];
                                updatedRows[1] = currentBidAsk[1];
                                updatedRows[2] = currentBidAsk[2];
                                updatedRows[3] = currentBidAsk[3];
                                updatedRows[4] = currentBidAsk[4];
                                updatedRows[12] = currentBidAsk[12];
                                previousBidAsk4.current = extractedRows;
                            }
                        } else {
                            if (bidask_per_sec != undefined) {
                                currentBidAsk[0] = createData(NumberWithCommasAndDecimal4(bidask_per_sec.VolBid1), NumberWithCommasAndDecimal4(bidask_per_sec.Bid1, 2), NumberWithCommasAndDecimal4(bidask_per_sec.Ask10, 2), NumberWithCommasAndDecimal4(bidask_per_sec.VolAsk10));
                                currentBidAsk[1] = createData(NumberWithCommasAndDecimal4(bidask_per_sec.VolBid2), NumberWithCommasAndDecimal4(bidask_per_sec.Bid2, 2), NumberWithCommasAndDecimal4(bidask_per_sec.Ask9, 2), NumberWithCommasAndDecimal4(bidask_per_sec.VolAsk9));
                                currentBidAsk[2] = createData(NumberWithCommasAndDecimal4(bidask_per_sec.VolBid3), NumberWithCommasAndDecimal4(bidask_per_sec.Bid3, 2), NumberWithCommasAndDecimal4(bidask_per_sec.Ask8, 2), NumberWithCommasAndDecimal4(bidask_per_sec.VolAsk8));
                                currentBidAsk[3] = createData(NumberWithCommasAndDecimal4(bidask_per_sec.VolBid4), NumberWithCommasAndDecimal4(bidask_per_sec.Bid4, 2), NumberWithCommasAndDecimal4(bidask_per_sec.Ask7, 2), NumberWithCommasAndDecimal4(bidask_per_sec.VolAsk7));
                                currentBidAsk[4] = createData(NumberWithCommasAndDecimal4(bidask_per_sec.VolBid5), NumberWithCommasAndDecimal4(bidask_per_sec.Bid5, 2), NumberWithCommasAndDecimal4(bidask_per_sec.Ask6, 2), NumberWithCommasAndDecimal4(bidask_per_sec.VolAsk6));
                                currentBidAsk[5] = createData(NumberWithCommasAndDecimal4(bidask_per_sec.VolBid6), NumberWithCommasAndDecimal4(bidask_per_sec.Bid6, 2), NumberWithCommasAndDecimal4(bidask_per_sec.Ask5, 2), NumberWithCommasAndDecimal4(bidask_per_sec.VolAsk5));
                                currentBidAsk[6] = createData(NumberWithCommasAndDecimal4(bidask_per_sec.VolBid7), NumberWithCommasAndDecimal4(bidask_per_sec.Bid7, 2), NumberWithCommasAndDecimal4(bidask_per_sec.Ask4, 2), NumberWithCommasAndDecimal4(bidask_per_sec.VolAsk4));
                                currentBidAsk[7] = createData(NumberWithCommasAndDecimal4(bidask_per_sec.VolBid8), NumberWithCommasAndDecimal4(bidask_per_sec.Bid8, 2), NumberWithCommasAndDecimal4(bidask_per_sec.Ask3, 2), NumberWithCommasAndDecimal4(bidask_per_sec.VolAsk3));
                                currentBidAsk[8] = createData(NumberWithCommasAndDecimal4(bidask_per_sec.VolBid9), NumberWithCommasAndDecimal4(bidask_per_sec.Bid9, 2), NumberWithCommasAndDecimal4(bidask_per_sec.Ask2, 2), NumberWithCommasAndDecimal4(bidask_per_sec.VolAsk2));
                                currentBidAsk[9] = createData(NumberWithCommasAndDecimal4(bidask_per_sec.VolBid10), NumberWithCommasAndDecimal4(bidask_per_sec.Bid10, 2), NumberWithCommasAndDecimal4(bidask_per_sec.Ask1, 2), NumberWithCommasAndDecimal4(bidask_per_sec.VolAsk1));
                                currentBidAsk[12] = createData(
                                    NumberWithCommasAndDecimal4(
                                        parseFloat(bidask_per_sec.VolBid1) + parseFloat(bidask_per_sec.VolBid2) + parseFloat(bidask_per_sec.VolBid3) + parseFloat(bidask_per_sec.VolBid4) +
                                        parseFloat(bidask_per_sec.VolBid5) + parseFloat(bidask_per_sec.VolBid6) + parseFloat(bidask_per_sec.VolBid7) + parseFloat(bidask_per_sec.VolBid8) +
                                        parseFloat(bidask_per_sec.VolBid9) + parseFloat(bidask_per_sec.VolBid10)
                                    ),
                                    "", "",
                                    NumberWithCommasAndDecimal4(
                                        parseFloat(bidask_per_sec.VolAsk1) + parseFloat(bidask_per_sec.VolAsk2) + parseFloat(bidask_per_sec.VolAsk3) + parseFloat(bidask_per_sec.VolAsk4) +
                                        parseFloat(bidask_per_sec.VolAsk5) + parseFloat(bidask_per_sec.VolAsk6) + parseFloat(bidask_per_sec.VolAsk7) + parseFloat(bidask_per_sec.VolAsk8) +
                                        parseFloat(bidask_per_sec.VolAsk9) + parseFloat(bidask_per_sec.VolAsk10)
                                    ),
                                );
                                const extractedRows = currentBidAsk.map(extractAndConvertChildren4);
                                if (!isEmpty4(previousBidAsk4.current)) {
                                    let compData = compareData4(previousBidAsk4.current, extractedRows);
                                    if (compData.length > 0) {
                                        compData.map((obj, index) => {
                                            const entries = Object.entries(obj.diff);
                                            entries.map(([key, value]) => {
                                                const cell = document.querySelector(`#row4-cell-${value.rowIndex}-${key}`);
                                                if (cell) {
                                                    cell.style.backgroundColor = value.type === 'positive' ? 'lightgreen' : 'lightcoral';
                                                }
                                            });
                                        });
                                    }
                                }
                                updatedRows[0] = currentBidAsk[0];
                                updatedRows[1] = currentBidAsk[1];
                                updatedRows[2] = currentBidAsk[2];
                                updatedRows[3] = currentBidAsk[3];
                                updatedRows[4] = currentBidAsk[4];
                                updatedRows[5] = currentBidAsk[5];
                                updatedRows[6] = currentBidAsk[6];
                                updatedRows[7] = currentBidAsk[7];
                                updatedRows[8] = currentBidAsk[8];
                                updatedRows[9] = currentBidAsk[9];
                                updatedRows[12] = currentBidAsk[12];
                                previousBidAsk4.current = extractedRows;
                            }
                        }
                        setRows4(updatedRows);
                        if (resultTickMatch4 != undefined) {
                            resultTickMatch4.filter((x) => {
                                return x.DtMinOfRec.substring(0, x.DtMinOfRec.length - 3) === time_minute_search;
                            });
                        }
                        return prevCount + 1;
                    }
                });
            }, 1000 / speed);
        }
        if (speed !== prevSpeedRef4.current) {
            setSpeedChanged4(true);
        } else {
            setSpeedChanged4(false);
        }
        prevSpeedRef4.current = speed;
        return () => clearInterval(interval);
    }, [isPlaying4, countRecordBidAsk4, initialValues4.speed]);

    const delay5 = (ms) => new Promise(resolve => setTimeout(resolve, ms));
    const [speedChanged5, setSpeedChanged5] = useState(false);
    const prevSpeedRef5 = useRef(initialValues5.speed);
    useEffect(() => {
        let interval;
        let speed = initialValues5.speed == 0 || initialValues5.speed == null ? 1 : initialValues5.speed;
        if (isPlaying5) {
            interval = setInterval(async () => {
                speed = initialValues5.speed == 0 || initialValues5.speed == null ? 1 : initialValues5.speed;
                if (speedChanged5) {
                    setLoading(true);
                    await delay5(2000);
                    setLoading(false);
                }
                let time_minute_search = 0;
                setCountRecordBidAsk5(prevCount => {
                    if (prevCount >= bidaskLength5 - 1) {
                        setIsPlaying5(false);
                        clearInterval(interval);
                        return prevCount;
                    } else {
                        let bidask_per_sec = resultBidAsk5[prevCount];
                        time_minute_search = dayjs(bidask_per_sec['Time']).format('YYYY-MM-DD HH:mm');
                        let time2show5 = dayjs(bidask_per_sec['Time']).format('HH:mm:ss');
                        setTime2Show5(time2show5);

                        const dateToCheck = dayjs(formatDate5(initialValues.start_date))
                        const comparisonDate = dayjs('2023-05-08');
                        const isBefore = dateToCheck.isBefore(comparisonDate);

                        document.querySelectorAll('.change-tracking td').forEach(cell => {
                            cell.style.backgroundColor = '';
                        });
                        let currentBidAsk = [];
                        const updatedRows = [...rows5];
                        if (isBefore) {
                            if (bidask_per_sec != undefined) {
                                currentBidAsk[0] = createData(NumberWithCommasAndDecimal5(bidask_per_sec.VolBid1), NumberWithCommasAndDecimal5(bidask_per_sec.Bid1, 2), NumberWithCommasAndDecimal5(bidask_per_sec.Ask5, 2), NumberWithCommasAndDecimal5(bidask_per_sec.VolAsk5));
                                currentBidAsk[1] = createData(NumberWithCommasAndDecimal5(bidask_per_sec.VolBid2), NumberWithCommasAndDecimal5(bidask_per_sec.Bid2, 2), NumberWithCommasAndDecimal5(bidask_per_sec.Ask4, 2), NumberWithCommasAndDecimal5(bidask_per_sec.VolAsk4));
                                currentBidAsk[2] = createData(NumberWithCommasAndDecimal5(bidask_per_sec.VolBid3), NumberWithCommasAndDecimal5(bidask_per_sec.Bid3, 2), NumberWithCommasAndDecimal5(bidask_per_sec.Ask3, 2), NumberWithCommasAndDecimal5(bidask_per_sec.VolAsk3));
                                currentBidAsk[3] = createData(NumberWithCommasAndDecimal5(bidask_per_sec.VolBid4), NumberWithCommasAndDecimal5(bidask_per_sec.Bid4, 2), NumberWithCommasAndDecimal5(bidask_per_sec.Ask2, 2), NumberWithCommasAndDecimal5(bidask_per_sec.VolAsk2));
                                currentBidAsk[4] = createData(NumberWithCommasAndDecimal5(bidask_per_sec.VolBid5), NumberWithCommasAndDecimal5(bidask_per_sec.Bid5, 2), NumberWithCommasAndDecimal5(bidask_per_sec.Ask1, 2), NumberWithCommasAndDecimal5(bidask_per_sec.VolAsk1));
                                currentBidAsk[12] = createData(
                                    NumberWithCommasAndDecimal5(parseFloat(bidask_per_sec.VolBid1) + parseFloat(bidask_per_sec.VolBid2) + parseFloat(bidask_per_sec.VolBid3) + parseFloat(bidask_per_sec.VolBid4) + parseFloat(bidask_per_sec.VolBid5)),
                                    "", "",
                                    NumberWithCommasAndDecimal5(parseFloat(bidask_per_sec.VolAsk1) + parseFloat(bidask_per_sec.VolAsk2) + parseFloat(bidask_per_sec.VolAsk3) + parseFloat(bidask_per_sec.VolAsk4) + parseFloat(bidask_per_sec.VolAsk5)),
                                );
                                const extractedRows = currentBidAsk.map(extractAndConvertChildren5);
                                if (!isEmpty5(previousBidAsk5.current)) {
                                    let compData = compareData5(previousBidAsk5.current, extractedRows);
                                    if (compData.length > 0) {
                                        compData.map((obj, index) => {
                                            const entries = Object.entries(obj.diff);
                                            entries.map(([key, value]) => {
                                                const cell = document.querySelector(`#row5-cell-${value.rowIndex}-${key}`);
                                                if (cell) {
                                                    cell.style.backgroundColor = value.type === 'positive' ? 'lightgreen' : 'lightcoral';
                                                }
                                            });
                                        });
                                    }
                                }
                                updatedRows[0] = currentBidAsk[0];
                                updatedRows[1] = currentBidAsk[1];
                                updatedRows[2] = currentBidAsk[2];
                                updatedRows[3] = currentBidAsk[3];
                                updatedRows[4] = currentBidAsk[4];
                                updatedRows[12] = currentBidAsk[12];
                                previousBidAsk5.current = extractedRows;
                            }
                        } else {
                            if (bidask_per_sec != undefined) {
                                currentBidAsk[0] = createData(NumberWithCommasAndDecimal5(bidask_per_sec.VolBid1), NumberWithCommasAndDecimal5(bidask_per_sec.Bid1, 2), NumberWithCommasAndDecimal5(bidask_per_sec.Ask10, 2), NumberWithCommasAndDecimal5(bidask_per_sec.VolAsk10));
                                currentBidAsk[1] = createData(NumberWithCommasAndDecimal5(bidask_per_sec.VolBid2), NumberWithCommasAndDecimal5(bidask_per_sec.Bid2, 2), NumberWithCommasAndDecimal5(bidask_per_sec.Ask9, 2), NumberWithCommasAndDecimal5(bidask_per_sec.VolAsk9));
                                currentBidAsk[2] = createData(NumberWithCommasAndDecimal5(bidask_per_sec.VolBid3), NumberWithCommasAndDecimal5(bidask_per_sec.Bid3, 2), NumberWithCommasAndDecimal5(bidask_per_sec.Ask8, 2), NumberWithCommasAndDecimal5(bidask_per_sec.VolAsk8));
                                currentBidAsk[3] = createData(NumberWithCommasAndDecimal5(bidask_per_sec.VolBid4), NumberWithCommasAndDecimal5(bidask_per_sec.Bid4, 2), NumberWithCommasAndDecimal5(bidask_per_sec.Ask7, 2), NumberWithCommasAndDecimal5(bidask_per_sec.VolAsk7));
                                currentBidAsk[4] = createData(NumberWithCommasAndDecimal5(bidask_per_sec.VolBid5), NumberWithCommasAndDecimal5(bidask_per_sec.Bid5, 2), NumberWithCommasAndDecimal5(bidask_per_sec.Ask6, 2), NumberWithCommasAndDecimal5(bidask_per_sec.VolAsk6));
                                currentBidAsk[5] = createData(NumberWithCommasAndDecimal5(bidask_per_sec.VolBid6), NumberWithCommasAndDecimal5(bidask_per_sec.Bid6, 2), NumberWithCommasAndDecimal5(bidask_per_sec.Ask5, 2), NumberWithCommasAndDecimal5(bidask_per_sec.VolAsk5));
                                currentBidAsk[6] = createData(NumberWithCommasAndDecimal5(bidask_per_sec.VolBid7), NumberWithCommasAndDecimal5(bidask_per_sec.Bid7, 2), NumberWithCommasAndDecimal5(bidask_per_sec.Ask4, 2), NumberWithCommasAndDecimal5(bidask_per_sec.VolAsk4));
                                currentBidAsk[7] = createData(NumberWithCommasAndDecimal5(bidask_per_sec.VolBid8), NumberWithCommasAndDecimal5(bidask_per_sec.Bid8, 2), NumberWithCommasAndDecimal5(bidask_per_sec.Ask3, 2), NumberWithCommasAndDecimal5(bidask_per_sec.VolAsk3));
                                currentBidAsk[8] = createData(NumberWithCommasAndDecimal5(bidask_per_sec.VolBid9), NumberWithCommasAndDecimal5(bidask_per_sec.Bid9, 2), NumberWithCommasAndDecimal5(bidask_per_sec.Ask2, 2), NumberWithCommasAndDecimal5(bidask_per_sec.VolAsk2));
                                currentBidAsk[9] = createData(NumberWithCommasAndDecimal5(bidask_per_sec.VolBid10), NumberWithCommasAndDecimal5(bidask_per_sec.Bid10, 2), NumberWithCommasAndDecimal5(bidask_per_sec.Ask1, 2), NumberWithCommasAndDecimal5(bidask_per_sec.VolAsk1));
                                currentBidAsk[12] = createData(
                                    NumberWithCommasAndDecimal5(
                                        parseFloat(bidask_per_sec.VolBid1) + parseFloat(bidask_per_sec.VolBid2) + parseFloat(bidask_per_sec.VolBid3) + parseFloat(bidask_per_sec.VolBid4) +
                                        parseFloat(bidask_per_sec.VolBid5) + parseFloat(bidask_per_sec.VolBid6) + parseFloat(bidask_per_sec.VolBid7) + parseFloat(bidask_per_sec.VolBid8) +
                                        parseFloat(bidask_per_sec.VolBid9) + parseFloat(bidask_per_sec.VolBid10)
                                    ),
                                    "", "",
                                    NumberWithCommasAndDecimal5(
                                        parseFloat(bidask_per_sec.VolAsk1) + parseFloat(bidask_per_sec.VolAsk2) + parseFloat(bidask_per_sec.VolAsk3) + parseFloat(bidask_per_sec.VolAsk4) +
                                        parseFloat(bidask_per_sec.VolAsk5) + parseFloat(bidask_per_sec.VolAsk6) + parseFloat(bidask_per_sec.VolAsk7) + parseFloat(bidask_per_sec.VolAsk8) +
                                        parseFloat(bidask_per_sec.VolAsk9) + parseFloat(bidask_per_sec.VolAsk10)
                                    ),
                                );
                                const extractedRows = currentBidAsk.map(extractAndConvertChildren4);
                                if (!isEmpty5(previousBidAsk5.current)) {
                                    let compData = compareData5(previousBidAsk5.current, extractedRows);
                                    if (compData.length > 0) {
                                        compData.map((obj, index) => {
                                            const entries = Object.entries(obj.diff);
                                            entries.map(([key, value]) => {
                                                const cell = document.querySelector(`#row5-cell-${value.rowIndex}-${key}`);
                                                if (cell) {
                                                    cell.style.backgroundColor = value.type === 'positive' ? 'lightgreen' : 'lightcoral';
                                                }
                                            });
                                        });
                                    }
                                }
                                updatedRows[0] = currentBidAsk[0];
                                updatedRows[1] = currentBidAsk[1];
                                updatedRows[2] = currentBidAsk[2];
                                updatedRows[3] = currentBidAsk[3];
                                updatedRows[4] = currentBidAsk[4];
                                updatedRows[5] = currentBidAsk[5];
                                updatedRows[6] = currentBidAsk[6];
                                updatedRows[7] = currentBidAsk[7];
                                updatedRows[8] = currentBidAsk[8];
                                updatedRows[9] = currentBidAsk[9];
                                updatedRows[12] = currentBidAsk[12];
                                previousBidAsk5.current = extractedRows;
                            }
                        }
                        setRows5(updatedRows);
                        if (resultTickMatch5 != undefined) {
                            resultTickMatch5.filter((x) => {
                                return x.DtMinOfRec.substring(0, x.DtMinOfRec.length - 3) === time_minute_search;
                            });
                        }
                        return prevCount + 1;
                    }
                });
            }, 1000 / speed);
        }
        if (speed !== prevSpeedRef5.current) {
            setSpeedChanged5(true);
        } else {
            setSpeedChanged5(false);
        }
        prevSpeedRef5.current = speed;
        return () => clearInterval(interval);
    }, [isPlaying5, countRecordBidAsk5, initialValues5.speed]);

    const delay6 = (ms) => new Promise(resolve => setTimeout(resolve, ms));
    const [speedChanged6, setSpeedChanged6] = useState(false);
    const prevSpeedRef6 = useRef(initialValues6.speed);
    useEffect(() => {
        let interval;
        let speed = initialValues6.speed == 0 || initialValues6.speed == null ? 1 : initialValues6.speed;
        if (isPlaying6) {
            interval = setInterval(async () => {
                speed = initialValues6.speed == 0 || initialValues6.speed == null ? 1 : initialValues6.speed;
                if (speedChanged6) {
                    setLoading(true);
                    await delay6(2000);
                    setLoading(false);
                }
                let time_minute_search = 0;
                setCountRecordBidAsk6(prevCount => {
                    if (prevCount >= bidaskLength6 - 1) {
                        setIsPlaying6(false);
                        clearInterval(interval);
                        return prevCount;
                    } else {
                        let bidask_per_sec = resultBidAsk6[prevCount];
                        time_minute_search = dayjs(bidask_per_sec['Time']).format('YYYY-MM-DD HH:mm');
                        let time2show6 = dayjs(bidask_per_sec['Time']).format('HH:mm:ss');
                        setTime2Show6(time2show6);

                        const dateToCheck = dayjs(formatDate6(initialValues.start_date))
                        const comparisonDate = dayjs('2023-05-08');
                        const isBefore = dateToCheck.isBefore(comparisonDate);

                        document.querySelectorAll('.change-tracking td').forEach(cell => {
                            cell.style.backgroundColor = '';
                        });
                        let currentBidAsk = [];
                        const updatedRows = [...rows6];
                        if (isBefore) {
                            if (bidask_per_sec != undefined) {
                                currentBidAsk[0] = createData(NumberWithCommasAndDecimal6(bidask_per_sec.VolBid1), NumberWithCommasAndDecimal6(bidask_per_sec.Bid1, 2), NumberWithCommasAndDecimal6(bidask_per_sec.Ask5, 2), NumberWithCommasAndDecimal6(bidask_per_sec.VolAsk5));
                                currentBidAsk[1] = createData(NumberWithCommasAndDecimal6(bidask_per_sec.VolBid2), NumberWithCommasAndDecimal6(bidask_per_sec.Bid2, 2), NumberWithCommasAndDecimal6(bidask_per_sec.Ask4, 2), NumberWithCommasAndDecimal6(bidask_per_sec.VolAsk4));
                                currentBidAsk[2] = createData(NumberWithCommasAndDecimal6(bidask_per_sec.VolBid3), NumberWithCommasAndDecimal6(bidask_per_sec.Bid3, 2), NumberWithCommasAndDecimal6(bidask_per_sec.Ask3, 2), NumberWithCommasAndDecimal6(bidask_per_sec.VolAsk3));
                                currentBidAsk[3] = createData(NumberWithCommasAndDecimal6(bidask_per_sec.VolBid4), NumberWithCommasAndDecimal6(bidask_per_sec.Bid4, 2), NumberWithCommasAndDecimal6(bidask_per_sec.Ask2, 2), NumberWithCommasAndDecimal6(bidask_per_sec.VolAsk2));
                                currentBidAsk[4] = createData(NumberWithCommasAndDecimal6(bidask_per_sec.VolBid5), NumberWithCommasAndDecimal6(bidask_per_sec.Bid5, 2), NumberWithCommasAndDecimal6(bidask_per_sec.Ask1, 2), NumberWithCommasAndDecimal6(bidask_per_sec.VolAsk1));
                                currentBidAsk[12] = createData(
                                    NumberWithCommasAndDecimal6(parseFloat(bidask_per_sec.VolBid1) + parseFloat(bidask_per_sec.VolBid2) + parseFloat(bidask_per_sec.VolBid3) + parseFloat(bidask_per_sec.VolBid4) + parseFloat(bidask_per_sec.VolBid5)),
                                    "", "",
                                    NumberWithCommasAndDecimal6(parseFloat(bidask_per_sec.VolAsk1) + parseFloat(bidask_per_sec.VolAsk2) + parseFloat(bidask_per_sec.VolAsk3) + parseFloat(bidask_per_sec.VolAsk4) + parseFloat(bidask_per_sec.VolAsk5)),
                                );
                                const extractedRows = currentBidAsk.map(extractAndConvertChildren6);
                                if (!isEmpty6(previousBidAsk6.current)) {
                                    let compData = compareData6(previousBidAsk6.current, extractedRows);
                                    if (compData.length > 0) {
                                        compData.map((obj, index) => {
                                            const entries = Object.entries(obj.diff);
                                            entries.map(([key, value]) => {
                                                const cell = document.querySelector(`#row6-cell-${value.rowIndex}-${key}`);
                                                if (cell) {
                                                    cell.style.backgroundColor = value.type === 'positive' ? 'lightgreen' : 'lightcoral';
                                                }
                                            });
                                        });
                                    }
                                }
                                updatedRows[0] = currentBidAsk[0];
                                updatedRows[1] = currentBidAsk[1];
                                updatedRows[2] = currentBidAsk[2];
                                updatedRows[3] = currentBidAsk[3];
                                updatedRows[4] = currentBidAsk[4];
                                updatedRows[12] = currentBidAsk[12];
                                previousBidAsk6.current = extractedRows;
                            }
                        } else {
                            if (bidask_per_sec != undefined) {
                                currentBidAsk[0] = createData(NumberWithCommasAndDecimal6(bidask_per_sec.VolBid1), NumberWithCommasAndDecimal6(bidask_per_sec.Bid1, 2), NumberWithCommasAndDecimal6(bidask_per_sec.Ask10, 2), NumberWithCommasAndDecimal6(bidask_per_sec.VolAsk10));
                                currentBidAsk[1] = createData(NumberWithCommasAndDecimal6(bidask_per_sec.VolBid2), NumberWithCommasAndDecimal6(bidask_per_sec.Bid2, 2), NumberWithCommasAndDecimal6(bidask_per_sec.Ask9, 2), NumberWithCommasAndDecimal6(bidask_per_sec.VolAsk9));
                                currentBidAsk[2] = createData(NumberWithCommasAndDecimal6(bidask_per_sec.VolBid3), NumberWithCommasAndDecimal6(bidask_per_sec.Bid3, 2), NumberWithCommasAndDecimal6(bidask_per_sec.Ask8, 2), NumberWithCommasAndDecimal6(bidask_per_sec.VolAsk8));
                                currentBidAsk[3] = createData(NumberWithCommasAndDecimal6(bidask_per_sec.VolBid4), NumberWithCommasAndDecimal6(bidask_per_sec.Bid4, 2), NumberWithCommasAndDecimal6(bidask_per_sec.Ask7, 2), NumberWithCommasAndDecimal6(bidask_per_sec.VolAsk7));
                                currentBidAsk[4] = createData(NumberWithCommasAndDecimal6(bidask_per_sec.VolBid5), NumberWithCommasAndDecimal6(bidask_per_sec.Bid5, 2), NumberWithCommasAndDecimal6(bidask_per_sec.Ask6, 2), NumberWithCommasAndDecimal6(bidask_per_sec.VolAsk6));
                                currentBidAsk[5] = createData(NumberWithCommasAndDecimal6(bidask_per_sec.VolBid6), NumberWithCommasAndDecimal6(bidask_per_sec.Bid6, 2), NumberWithCommasAndDecimal6(bidask_per_sec.Ask5, 2), NumberWithCommasAndDecimal6(bidask_per_sec.VolAsk5));
                                currentBidAsk[6] = createData(NumberWithCommasAndDecimal6(bidask_per_sec.VolBid7), NumberWithCommasAndDecimal6(bidask_per_sec.Bid7, 2), NumberWithCommasAndDecimal6(bidask_per_sec.Ask4, 2), NumberWithCommasAndDecimal6(bidask_per_sec.VolAsk4));
                                currentBidAsk[7] = createData(NumberWithCommasAndDecimal6(bidask_per_sec.VolBid8), NumberWithCommasAndDecimal6(bidask_per_sec.Bid8, 2), NumberWithCommasAndDecimal6(bidask_per_sec.Ask3, 2), NumberWithCommasAndDecimal6(bidask_per_sec.VolAsk3));
                                currentBidAsk[8] = createData(NumberWithCommasAndDecimal6(bidask_per_sec.VolBid9), NumberWithCommasAndDecimal6(bidask_per_sec.Bid9, 2), NumberWithCommasAndDecimal6(bidask_per_sec.Ask2, 2), NumberWithCommasAndDecimal6(bidask_per_sec.VolAsk2));
                                currentBidAsk[9] = createData(NumberWithCommasAndDecimal6(bidask_per_sec.VolBid10), NumberWithCommasAndDecimal6(bidask_per_sec.Bid10, 2), NumberWithCommasAndDecimal6(bidask_per_sec.Ask1, 2), NumberWithCommasAndDecimal6(bidask_per_sec.VolAsk1));
                                currentBidAsk[12] = createData(
                                    NumberWithCommasAndDecimal6(
                                        parseFloat(bidask_per_sec.VolBid1) + parseFloat(bidask_per_sec.VolBid2) + parseFloat(bidask_per_sec.VolBid3) + parseFloat(bidask_per_sec.VolBid4) +
                                        parseFloat(bidask_per_sec.VolBid5) + parseFloat(bidask_per_sec.VolBid6) + parseFloat(bidask_per_sec.VolBid7) + parseFloat(bidask_per_sec.VolBid8) +
                                        parseFloat(bidask_per_sec.VolBid9) + parseFloat(bidask_per_sec.VolBid10)
                                    ),
                                    "", "",
                                    NumberWithCommasAndDecimal6(
                                        parseFloat(bidask_per_sec.VolAsk1) + parseFloat(bidask_per_sec.VolAsk2) + parseFloat(bidask_per_sec.VolAsk3) + parseFloat(bidask_per_sec.VolAsk4) +
                                        parseFloat(bidask_per_sec.VolAsk5) + parseFloat(bidask_per_sec.VolAsk6) + parseFloat(bidask_per_sec.VolAsk7) + parseFloat(bidask_per_sec.VolAsk8) +
                                        parseFloat(bidask_per_sec.VolAsk9) + parseFloat(bidask_per_sec.VolAsk10)
                                    ),
                                );
                                const extractedRows = currentBidAsk.map(extractAndConvertChildren4);
                                if (!isEmpty6(previousBidAsk6.current)) {
                                    let compData = compareData6(previousBidAsk6.current, extractedRows);
                                    if (compData.length > 0) {
                                        compData.map((obj, index) => {
                                            const entries = Object.entries(obj.diff);
                                            entries.map(([key, value]) => {
                                                const cell = document.querySelector(`#row6-cell-${value.rowIndex}-${key}`);
                                                if (cell) {
                                                    cell.style.backgroundColor = value.type === 'positive' ? 'lightgreen' : 'lightcoral';
                                                }
                                            });
                                        });
                                    }
                                }
                                updatedRows[0] = currentBidAsk[0];
                                updatedRows[1] = currentBidAsk[1];
                                updatedRows[2] = currentBidAsk[2];
                                updatedRows[3] = currentBidAsk[3];
                                updatedRows[4] = currentBidAsk[4];
                                updatedRows[5] = currentBidAsk[5];
                                updatedRows[6] = currentBidAsk[6];
                                updatedRows[7] = currentBidAsk[7];
                                updatedRows[8] = currentBidAsk[8];
                                updatedRows[9] = currentBidAsk[9];
                                updatedRows[12] = currentBidAsk[12];
                                previousBidAsk6.current = extractedRows;
                            }
                        }
                        setRows6(updatedRows);
                        if (resultTickMatch6 != undefined) {
                            resultTickMatch6.filter((x) => {
                                return x.DtMinOfRec.substring(0, x.DtMinOfRec.length - 3) === time_minute_search;
                            });
                        }
                        return prevCount + 1;
                    }
                });
            }, 1000 / speed);
        }
        if (speed !== prevSpeedRef6.current) {
            setSpeedChanged6(true);
        } else {
            setSpeedChanged6(false);
        }
        prevSpeedRef6.current = speed;
        return () => clearInterval(interval);
    }, [isPlaying6, countRecordBidAsk6, initialValues6.speed]);

    const delay7 = (ms) => new Promise(resolve => setTimeout(resolve, ms));
    const [speedChanged7, setSpeedChanged7] = useState(false);
    const prevSpeedRef7 = useRef(initialValues7.speed);
    useEffect(() => {
        let interval;
        let speed = initialValues7.speed == 0 || initialValues7.speed == null ? 1 : initialValues7.speed;
        if (isPlaying7) {
            interval = setInterval(async () => {
                speed = initialValues7.speed == 0 || initialValues7.speed == null ? 1 : initialValues7.speed;
                if (speedChanged7) {
                    setLoading(true);
                    await delay7(2000);
                    setLoading(false);
                }
                let time_minute_search = 0;
                setCountRecordBidAsk7(prevCount => {
                    if (prevCount >= bidaskLength7 - 1) {
                        setIsPlaying7(false);
                        clearInterval(interval);
                        return prevCount;
                    } else {
                        let bidask_per_sec = resultBidAsk7[prevCount];
                        time_minute_search = dayjs(bidask_per_sec['Time']).format('YYYY-MM-DD HH:mm');
                        let time2show7 = dayjs(bidask_per_sec['Time']).format('HH:mm:ss');
                        setTime2Show7(time2show7);

                        const dateToCheck = dayjs(formatDate7(initialValues.start_date))
                        const comparisonDate = dayjs('2023-05-08');
                        const isBefore = dateToCheck.isBefore(comparisonDate);

                        document.querySelectorAll('.change-tracking td').forEach(cell => {
                            cell.style.backgroundColor = '';
                        });
                        let currentBidAsk = [];
                        const updatedRows = [...rows7];
                        if (isBefore) {
                            if (bidask_per_sec != undefined) {
                                currentBidAsk[0] = createData(NumberWithCommasAndDecimal7(bidask_per_sec.VolBid1), NumberWithCommasAndDecimal7(bidask_per_sec.Bid1, 2), NumberWithCommasAndDecimal7(bidask_per_sec.Ask5, 2), NumberWithCommasAndDecimal7(bidask_per_sec.VolAsk5));
                                currentBidAsk[1] = createData(NumberWithCommasAndDecimal7(bidask_per_sec.VolBid2), NumberWithCommasAndDecimal7(bidask_per_sec.Bid2, 2), NumberWithCommasAndDecimal7(bidask_per_sec.Ask4, 2), NumberWithCommasAndDecimal7(bidask_per_sec.VolAsk4));
                                currentBidAsk[2] = createData(NumberWithCommasAndDecimal7(bidask_per_sec.VolBid3), NumberWithCommasAndDecimal7(bidask_per_sec.Bid3, 2), NumberWithCommasAndDecimal7(bidask_per_sec.Ask3, 2), NumberWithCommasAndDecimal7(bidask_per_sec.VolAsk3));
                                currentBidAsk[3] = createData(NumberWithCommasAndDecimal7(bidask_per_sec.VolBid4), NumberWithCommasAndDecimal7(bidask_per_sec.Bid4, 2), NumberWithCommasAndDecimal7(bidask_per_sec.Ask2, 2), NumberWithCommasAndDecimal7(bidask_per_sec.VolAsk2));
                                currentBidAsk[4] = createData(NumberWithCommasAndDecimal7(bidask_per_sec.VolBid5), NumberWithCommasAndDecimal7(bidask_per_sec.Bid5, 2), NumberWithCommasAndDecimal7(bidask_per_sec.Ask1, 2), NumberWithCommasAndDecimal7(bidask_per_sec.VolAsk1));
                                currentBidAsk[12] = createData(
                                    NumberWithCommasAndDecimal7(parseFloat(bidask_per_sec.VolBid1) + parseFloat(bidask_per_sec.VolBid2) + parseFloat(bidask_per_sec.VolBid3) + parseFloat(bidask_per_sec.VolBid4) + parseFloat(bidask_per_sec.VolBid5)),
                                    "", "",
                                    NumberWithCommasAndDecimal7(parseFloat(bidask_per_sec.VolAsk1) + parseFloat(bidask_per_sec.VolAsk2) + parseFloat(bidask_per_sec.VolAsk3) + parseFloat(bidask_per_sec.VolAsk4) + parseFloat(bidask_per_sec.VolAsk5)),
                                );
                                const extractedRows = currentBidAsk.map(extractAndConvertChildren7);
                                if (!isEmpty7(previousBidAsk7.current)) {
                                    let compData = compareData7(previousBidAsk7.current, extractedRows);
                                    if (compData.length > 0) {
                                        compData.map((obj, index) => {
                                            const entries = Object.entries(obj.diff);
                                            entries.map(([key, value]) => {
                                                const cell = document.querySelector(`#row7-cell-${value.rowIndex}-${key}`);
                                                if (cell) {
                                                    cell.style.backgroundColor = value.type === 'positive' ? 'lightgreen' : 'lightcoral';
                                                }
                                            });
                                        });
                                    }
                                }
                                updatedRows[0] = currentBidAsk[0];
                                updatedRows[1] = currentBidAsk[1];
                                updatedRows[2] = currentBidAsk[2];
                                updatedRows[3] = currentBidAsk[3];
                                updatedRows[4] = currentBidAsk[4];
                                updatedRows[12] = currentBidAsk[12];
                                previousBidAsk7.current = extractedRows;
                            }
                        } else {
                            if (bidask_per_sec != undefined) {
                                currentBidAsk[0] = createData(NumberWithCommasAndDecimal7(bidask_per_sec.VolBid1), NumberWithCommasAndDecimal7(bidask_per_sec.Bid1, 2), NumberWithCommasAndDecimal7(bidask_per_sec.Ask10, 2), NumberWithCommasAndDecimal7(bidask_per_sec.VolAsk10));
                                currentBidAsk[1] = createData(NumberWithCommasAndDecimal7(bidask_per_sec.VolBid2), NumberWithCommasAndDecimal7(bidask_per_sec.Bid2, 2), NumberWithCommasAndDecimal7(bidask_per_sec.Ask9, 2), NumberWithCommasAndDecimal7(bidask_per_sec.VolAsk9));
                                currentBidAsk[2] = createData(NumberWithCommasAndDecimal7(bidask_per_sec.VolBid3), NumberWithCommasAndDecimal7(bidask_per_sec.Bid3, 2), NumberWithCommasAndDecimal7(bidask_per_sec.Ask8, 2), NumberWithCommasAndDecimal7(bidask_per_sec.VolAsk8));
                                currentBidAsk[3] = createData(NumberWithCommasAndDecimal7(bidask_per_sec.VolBid4), NumberWithCommasAndDecimal7(bidask_per_sec.Bid4, 2), NumberWithCommasAndDecimal7(bidask_per_sec.Ask7, 2), NumberWithCommasAndDecimal7(bidask_per_sec.VolAsk7));
                                currentBidAsk[4] = createData(NumberWithCommasAndDecimal7(bidask_per_sec.VolBid5), NumberWithCommasAndDecimal7(bidask_per_sec.Bid5, 2), NumberWithCommasAndDecimal7(bidask_per_sec.Ask6, 2), NumberWithCommasAndDecimal7(bidask_per_sec.VolAsk6));
                                currentBidAsk[5] = createData(NumberWithCommasAndDecimal7(bidask_per_sec.VolBid6), NumberWithCommasAndDecimal7(bidask_per_sec.Bid6, 2), NumberWithCommasAndDecimal7(bidask_per_sec.Ask5, 2), NumberWithCommasAndDecimal7(bidask_per_sec.VolAsk5));
                                currentBidAsk[6] = createData(NumberWithCommasAndDecimal7(bidask_per_sec.VolBid7), NumberWithCommasAndDecimal7(bidask_per_sec.Bid7, 2), NumberWithCommasAndDecimal7(bidask_per_sec.Ask4, 2), NumberWithCommasAndDecimal7(bidask_per_sec.VolAsk4));
                                currentBidAsk[7] = createData(NumberWithCommasAndDecimal7(bidask_per_sec.VolBid8), NumberWithCommasAndDecimal7(bidask_per_sec.Bid8, 2), NumberWithCommasAndDecimal7(bidask_per_sec.Ask3, 2), NumberWithCommasAndDecimal7(bidask_per_sec.VolAsk3));
                                currentBidAsk[8] = createData(NumberWithCommasAndDecimal7(bidask_per_sec.VolBid9), NumberWithCommasAndDecimal7(bidask_per_sec.Bid9, 2), NumberWithCommasAndDecimal7(bidask_per_sec.Ask2, 2), NumberWithCommasAndDecimal7(bidask_per_sec.VolAsk2));
                                currentBidAsk[9] = createData(NumberWithCommasAndDecimal7(bidask_per_sec.VolBid10), NumberWithCommasAndDecimal7(bidask_per_sec.Bid10, 2), NumberWithCommasAndDecimal7(bidask_per_sec.Ask1, 2), NumberWithCommasAndDecimal7(bidask_per_sec.VolAsk1));
                                currentBidAsk[12] = createData(
                                    NumberWithCommasAndDecimal7(
                                        parseFloat(bidask_per_sec.VolBid1) + parseFloat(bidask_per_sec.VolBid2) + parseFloat(bidask_per_sec.VolBid3) + parseFloat(bidask_per_sec.VolBid4) +
                                        parseFloat(bidask_per_sec.VolBid5) + parseFloat(bidask_per_sec.VolBid6) + parseFloat(bidask_per_sec.VolBid7) + parseFloat(bidask_per_sec.VolBid8) +
                                        parseFloat(bidask_per_sec.VolBid9) + parseFloat(bidask_per_sec.VolBid10)
                                    ),
                                    "", "",
                                    NumberWithCommasAndDecimal7(
                                        parseFloat(bidask_per_sec.VolAsk1) + parseFloat(bidask_per_sec.VolAsk2) + parseFloat(bidask_per_sec.VolAsk3) + parseFloat(bidask_per_sec.VolAsk4) +
                                        parseFloat(bidask_per_sec.VolAsk5) + parseFloat(bidask_per_sec.VolAsk6) + parseFloat(bidask_per_sec.VolAsk7) + parseFloat(bidask_per_sec.VolAsk8) +
                                        parseFloat(bidask_per_sec.VolAsk9) + parseFloat(bidask_per_sec.VolAsk10)
                                    ),
                                );
                                const extractedRows = currentBidAsk.map(extractAndConvertChildren4);
                                if (!isEmpty7(previousBidAsk7.current)) {
                                    let compData = compareData7(previousBidAsk7.current, extractedRows);
                                    if (compData.length > 0) {
                                        compData.map((obj, index) => {
                                            const entries = Object.entries(obj.diff);
                                            entries.map(([key, value]) => {
                                                const cell = document.querySelector(`#row7-cell-${value.rowIndex}-${key}`);
                                                if (cell) {
                                                    cell.style.backgroundColor = value.type === 'positive' ? 'lightgreen' : 'lightcoral';
                                                }
                                            });
                                        });
                                    }
                                }
                                updatedRows[0] = currentBidAsk[0];
                                updatedRows[1] = currentBidAsk[1];
                                updatedRows[2] = currentBidAsk[2];
                                updatedRows[3] = currentBidAsk[3];
                                updatedRows[4] = currentBidAsk[4];
                                updatedRows[5] = currentBidAsk[5];
                                updatedRows[6] = currentBidAsk[6];
                                updatedRows[7] = currentBidAsk[7];
                                updatedRows[8] = currentBidAsk[8];
                                updatedRows[9] = currentBidAsk[9];
                                updatedRows[12] = currentBidAsk[12];
                                previousBidAsk7.current = extractedRows;
                            }
                        }
                        setRows7(updatedRows);
                        if (resultTickMatch7 != undefined) {
                            resultTickMatch7.filter((x) => {
                                return x.DtMinOfRec.substring(0, x.DtMinOfRec.length - 3) === time_minute_search;
                            });
                        }
                        return prevCount + 1;
                    }
                });
            }, 1000 / speed);
        }
        if (speed !== prevSpeedRef7.current) {
            setSpeedChanged7(true);
        } else {
            setSpeedChanged7(false);
        }
        prevSpeedRef7.current = speed;
        return () => clearInterval(interval);
    }, [isPlaying7, countRecordBidAsk7, initialValues7.speed]);

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            fontSize: 10,
            backgroundColor: colors.blueAccent[500],
            color: theme.palette.common.white,
            fontWeight: 'bold',

        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 10,
            backgroundColor: colors.blueAccent[500],
            color: theme.palette.common.white,
            fontWeight: 'bold',
        },
    }));

    const ma = {
        marginRight: -40,
        paddingTop: '16px'
    };

    const GridItem = ({ children, xs, md, lg, sx }) => (
        <Grid item xs={xs} md={md} lg={lg} sx={sx}>
            {children}
        </Grid>
    );

    const DatePickerField = ({ id, name, label, value, maxDate, handleChange, disabled }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
            <DatePicker
                id={id}
                name={name}
                label={label}
                slotProps={{ textField: { size: 'small' } }}
                value={value}
                maxDate={maxDate}
                onChange={(value) => handleChange(name, value)}
                disabled={disabled}
                sx={{
                    height: '40px',
                    '& .MuiInputBase-root': {
                        height: '30px',
                    },
                    '& .MuiInputAdornment-root .MuiSvgIcon-root': {
                        fontSize: 10,
                    },
                }}
            />
        </LocalizationProvider>
    );

    const TimePickerField = ({ id, name, label, value, minTime, maxTime, handleChange, disabled }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
            <TimePicker
                id={id}
                name={name}
                label={label}
                slotProps={{ textField: { size: 'small' } }}
                value={value}
                ampm={false}
                minTime={minTime}
                maxTime={maxTime}
                onChange={(value) => handleChange(name, value)}
                disabled={disabled}
                sx={{
                    height: '40px',
                    '& .MuiInputBase-root': {
                        height: '30px',
                    },
                    '& .MuiInputAdornment-root .MuiSvgIcon-root': {
                        fontSize: 10,
                    },
                }}
            />
        </LocalizationProvider>
    );

    const TimeSlider = ({ value, min, max, onChange, isPlaying, onPlayPause, valueLabel }) => (
        <Grid item md={4} display="flex" sx={{ height: '28px' }}>
            <Slider
                defaultValue={1}
                aria-label="Time Slider"
                valueLabelDisplay="auto"
                min={min}
                max={max}
                value={value}
                onChange={onChange}
                valueLabelFormat={(value) => `${valueLabel}`}
                sx={{
                    '& .MuiSlider-thumb': {
                        width: 12,
                        height: 12,
                    },
                }}
            />
            <IconButton onClick={onPlayPause}>
                {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
            </IconButton>
        </Grid>
    );

    const BidAskTable = ({ rows }) => (
        <Card>
            <TableContainer component={Paper} sx={{ marginTop: '-15px', width: '100%' }}>
                <Table aria-label="simple table" className="change-tracking">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">Vol BID</StyledTableCell>
                            <StyledTableCell align="center">BID</StyledTableCell>
                            <StyledTableCell align="center">ASK</StyledTableCell>
                            <StyledTableCell align="center">Vol ASK</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell align="center">{row.vol_bid}</TableCell>
                                <TableCell align="center">{row.bid}</TableCell>
                                <TableCell align="center">{row.ask}</TableCell>
                                <TableCell align="center">{row.vol_ask}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>
    );

    const FormWithTable = ({
        initialValues,
        handleChange,
        handleDateChange,
        handleTimeChange,
        handleSubmitForm,
        today_value,
        rows,
        Time2Show,
        countRecordBidAsk,
        handleSliderChange,
        handleClickPlayPause,
        isPlaying,
        bidaskLength,
        stEnTimeMin,
        stEnTimeMax,
    }) => (
        <Grid container>
            <form>
                {/* Row 1 */}
                <Grid container spacing={1} alignItems="center" justifyContent="center">
                    <GridItem xs={4} md={10} lg={2}>
                        <TextField
                            fullWidth
                            id="symbol"
                            name="symbol"
                            label="Symbol"
                            variant="outlined"
                            required
                            size="small"
                            value={initialValues.symbol}
                            onChange={handleChange}
                            sx={{
                                height: '40px',
                                '& .MuiInputBase-root': {
                                    height: '30px',
                                },
                                marginTop: '8px',
                            }}
                        />
                    </GridItem>
                    <GridItem xs={4} md={3} lg={2}>
                        <DatePickerField
                            id="start_date"
                            name="start_date"
                            label="Start Date"
                            value={initialValues.start_date}
                            maxDate={today_value}
                            handleChange={handleDateChange}
                        />
                    </GridItem>
                    <GridItem xs={4} md={3} lg={2}>
                        <DatePickerField
                            id="end_date"
                            name="end_date"
                            label="End Date"
                            value={initialValues.end_date}
                            maxDate={today_value}
                            handleChange={handleDateChange}
                            disabled
                        />
                    </GridItem>
                </Grid>

                {/* Row 2 */}
                <Grid container spacing={2} justifyContent="center">
                    <GridItem xs={4} md={3} lg={2}>
                        <TimePickerField
                            id="start_time"
                            name="start_time"
                            label="Start Time"
                            value={initialValues.start_time}
                            minTime={stEnTimeMin.subtract(1, 'minute')}
                            maxTime={stEnTimeMax.add(1, 'minute')}
                            handleChange={handleTimeChange}
                        />
                    </GridItem>
                    <GridItem xs={4} md={3} lg={2}>
                        <TimePickerField
                            id="end_time"
                            name="end_time"
                            label="End Time"
                            value={initialValues.end_time}
                            minTime={stEnTimeMin.subtract(1, 'minute')}
                            maxTime={stEnTimeMax.add(1, 'minute')}
                            handleChange={handleTimeChange}
                            disabled
                        />
                    </GridItem>
                    <GridItem xs={4} md={2} lg={1}>
                        <Button
                            variant="contained"
                            fullWidth
                            sx={{
                                backgroundColor: 'blue',
                            }}
                            type="button"
                            onClick={handleSubmitForm}
                        >
                            Search
                        </Button>
                    </GridItem>
                </Grid>
            </form>

            {/* Table */}
            <Grid container justifyContent="center">
                <GridItem md={6}>
                    <BidAskTable rows={rows} />
                </GridItem>
            </Grid>

            {/* Slider */}
            <Grid container justifyContent="center">
                <TimeSlider
                    value={countRecordBidAsk}
                    min={1}
                    max={bidaskLength}
                    onChange={handleSliderChange}
                    isPlaying={isPlaying}
                    onPlayPause={handleClickPlayPause}
                    valueLabel={Time2Show}
                />
            </Grid>
        </Grid>
    );

    {/* <FormWithTable
                            initialValues={initialValues}
                            handleChange={handleChange}
                            handleDateChange={handleDateChange}
                            handleTimeChange={handleTimeChange}
                            handleSubmitForm={handleSubmitForm}
                            today_value={today_value}
                            rows={rows}
                            Time2Show={Time2Show}
                            countRecordBidAsk={countRecordBidAsk}
                            handleSliderChange={handleSliderChange}
                            handleClickPlayPause={handleClickPlayPause}
                            isPlaying={isPlaying}
                            bidaskLength={bidaskLength}
                            stEnTimeMin={stEnTimeMin}
                            stEnTimeMax={stEnTimeMax}
                        /> */}

    return (
        <Box marginTop="10px" sx={{
            maxWidth: { xs: '100vw', sm: '70vw', md: '70vw', lg: '100vw', xl: '100vw' },
            overflowX: {
                xs: 'hidden',
                sm: 'hidden',
                md: 'hidden',
                lg: 'auto',
                xl: 'auto',
            }
        }}>
            <Preloader open={loading} />
            <Box>
                <Container sx={{
                    marginRight: { xs: 0, sm: 0, md: 0, lg: 'auto', xl: 'auto' },
                    maxWidth: '100%',
                    width: '100%'
                }}>
                    {(!isMobile && !isTablet) && (
                        <>
                            <Grid display="flex" sx={{ marginLeft: { lg: -20, xl: -35 } }}>
                                {/* item 1 */}
                                <Grid item lg={6} sx={ma}>
                                    <form>
                                        <Grid container spacing={1} display="flex" alignItems="center" justifyContent="center" xs={12} md={12}>
                                            <Grid xs={4} md={10} lg={1.7}>
                                                <TextField
                                                    fullWidth
                                                    id="symbol"
                                                    name="symbol"
                                                    label="Symbol"
                                                    variant="outlined"
                                                    required
                                                    size='small'
                                                    value={initialValues.symbol}
                                                    onChange={handleChange}
                                                    sx={{
                                                        height: '40px',
                                                        '& .MuiInputBase-root': {
                                                            height: '30px',

                                                        },
                                                        marginTop: '8px',
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4} md={3} lg={2.2} sx={{ flexGrow: 1 }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                    <DatePicker
                                                        id="start_date"
                                                        name="start_date"
                                                        label="Start Date"
                                                        slotProps={{ textField: { size: 'small' } }}
                                                        value={initialValues.start_date}
                                                        maxDate={today_value}
                                                        onChange={(value) => handleDateChange('start_date', value)}
                                                        sx={{
                                                            height: '40px',
                                                            '& .MuiInputBase-root': {
                                                                height: '30px',

                                                            },
                                                            '& .MuiInputAdornment-root': {
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 10,
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={4} md={3} lg={2.2} sx={{ flexGrow: 1 }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                    <DatePicker
                                                        id="end_date"
                                                        name="end_date"
                                                        label="End Date"
                                                        slotProps={{ textField: { size: 'small' } }}
                                                        value={initialValues.end_date}
                                                        maxDate={today_value}
                                                        onChange={(value) => handleDateChange('end_date', value)}
                                                        disabled
                                                        sx={{
                                                            height: '40px',
                                                            '& .MuiInputBase-root': {
                                                                height: '30px',

                                                            },
                                                            '& .MuiInputAdornment-root': {
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 10,
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} display="flex" justifyContent="center" xs={12} md={12}>
                                            <Grid item xs={4} md={3} lg={2}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                    <TimePicker
                                                        id="start_time"
                                                        name="start_time"
                                                        label="Start Time"
                                                        slotProps={{ textField: { size: 'small' } }}
                                                        defaultValue={initialValues.start_time}
                                                        ampm={false}
                                                        minTime={stEnTimeMin.subtract(1, 'minute')}
                                                        maxTime={stEnTimeMax.add(1, 'minute')}
                                                        onChange={(value) => handleTimeChange('start_time', value)}
                                                        sx={{
                                                            height: '40px',
                                                            '& .MuiInputBase-root': {
                                                                height: '30px',

                                                            },
                                                            '& .MuiInputAdornment-root': {
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 10,
                                                                },
                                                            },
                                                        }}

                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={4} md={3} lg={2} sx={{ flexGrow: 1 }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                    <TimePicker
                                                        id="end_time"
                                                        name="end_time"
                                                        label="End Time"
                                                        slotProps={{ textField: { size: 'small' } }}
                                                        value={dayjs(initialValues.end_time, "HH:mm")}
                                                        ampm={false}
                                                        minTime={stEnTimeMin.subtract(1, 'minute')}
                                                        maxTime={stEnTimeMax.add(1, 'minute')}
                                                        onChange={(value) => handleTimeChange('end_time', value)}
                                                        disabled={dayjs(initialValues.start_date).isSame(dayjs(), 'day')}
                                                        sx={{
                                                            height: '40px',
                                                            '& .MuiInputBase-root': {
                                                                height: '30px',

                                                            },
                                                            '& .MuiInputAdornment-root': {
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 10,
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={4} md={2} lg={0.9} sx={{ flexGrow: 1 }}>
                                                <TextField
                                                    fullWidth
                                                    id="speed"
                                                    name="speed"
                                                    label="Speed"
                                                    variant="outlined"
                                                    size='small'
                                                    value={initialValues.speed}
                                                    onChange={handleChange}
                                                    type='number'
                                                    // inputProps={{ step: 2 }}
                                                    sx={{
                                                        height: '40px',
                                                        '& .MuiInputBase-root': {
                                                            height: '30px',

                                                        },
                                                        '& .MuiInputAdornment-root': {
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 10,
                                                            },
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4} md={2} lg={1}>
                                                <Button variant="contained" fullWidth
                                                    sx={{
                                                        backgroundColor: colors.blueAccent[500],
                                                        // height: '25px',
                                                    }}
                                                    type="button" onClick={handleSubmitForm}
                                                >
                                                    Search
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                    <Grid container display="flex" alignItems="center" justifyContent="center" sx={{ marginTop: -1.5 }}>
                                        <Grid item md={8}>
                                            <Box display="flex" alignItems="center" justifyContent="center">
                                                <Typography variant='h5' sx={{ fontWeight: 'bold', }}>{Time2Show}</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container display="flex" alignItems="center" justifyContent="center">
                                        <Grid md={6}>
                                            <Card fullWidth>
                                                <Grid >
                                                    <TableContainer component={Paper} sx={{ marginTop: '-15px', width: '100%', '@media (min-width: 600px)': { width: '100%' } }}>
                                                        <Table aria-label="simple table" className="change-tracking">
                                                            <TableHead>
                                                                <TableRow sx={{ paddingTop: '5px', paddingBottom: '5px' }}>
                                                                    <StyledTableCell align="center">Vol BID</StyledTableCell>
                                                                    <StyledTableCell align="center">BID</StyledTableCell>
                                                                    <StyledTableCell align="center">ASK</StyledTableCell>
                                                                    <StyledTableCell align="center">Vol ASK</StyledTableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {rows.map((row, index) => {
                                                                    if (index < 10) {
                                                                        return (
                                                                            <TableRow key={index}>
                                                                                <TableCell id={`cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px', width: '30%' }}>{row.vol_bid}</TableCell>
                                                                                <TableCell id={`cell-${index}-bid`} align="center" style={{ padding: '0.1px', width: '20%' }}>{row.bid}</TableCell>
                                                                                <TableCell id={`cell-${index}-ask`} align="center" style={{ padding: '0.1px', width: '20%' }}>{row.ask}</TableCell>
                                                                                <TableCell id={`cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px', width: '30%' }}>{row.vol_ask}</TableCell>
                                                                            </TableRow>
                                                                        );
                                                                    } if (index === 11) {
                                                                        return (
                                                                            <TableRow key={index}>
                                                                                <StyledTableCell id={`cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px' }}>{row.vol_bid}</StyledTableCell>
                                                                                <StyledTableCell id={`cell-${index}-bid`} align="center" style={{ padding: '0.1px' }}>{row.bid}</StyledTableCell>
                                                                                <StyledTableCell id={`cell-${index}-ask`} align="center" style={{ padding: '0.1px' }}>{row.ask}</StyledTableCell>
                                                                                <StyledTableCell id={`cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px' }}>{row.vol_ask}</StyledTableCell>
                                                                            </TableRow>
                                                                        )
                                                                    }
                                                                    else {
                                                                        return (
                                                                            <TableRow key={index}>
                                                                                <TableCell id={`cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px' }}>{row.vol_bid}</TableCell>
                                                                                <TableCell align="center" style={{ padding: '0.1px' }}>{row.bid}</TableCell>
                                                                                <TableCell align="center" style={{ padding: '0.1px' }}>{row.ask}</TableCell>
                                                                                <TableCell id={`cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px' }}>{row.vol_ask}</TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    }
                                                                })}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                    <Grid container display="flex" alignItems="center" justifyContent="center">
                                        <Grid item md={4} display="flex" sx={{ height: '28px' }}>
                                            <Slider
                                                defaultValue={1}
                                                aria-label="Time Slider"
                                                valueLabelDisplay="auto"
                                                min={1}
                                                max={bidaskLength}
                                                value={countRecordBidAsk}
                                                onChange={handleSliderChange}
                                                valueLabelFormat={(value) => `${Time2Show}`}
                                                sx={{
                                                    '& .MuiSlider-thumb': {
                                                        width: 12,
                                                        height: 12,
                                                    },
                                                }}
                                            />
                                            <IconButton onClick={handleClickPlayPause}>
                                                {isPlaying ? <PauseIcon /> : <PlayArrowIcon />} {/* Render play or pause icon based on the value of isPlaying */}
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* item 2 */}
                                <Grid item lg={6} sx={ma}>
                                    <form>
                                        <Grid container spacing={1} display="flex" alignItems="center" justifyContent="center" xs={12} md={12}>
                                            <Grid xs={4} md={10} lg={1.7}>
                                                <TextField
                                                    fullWidth1
                                                    id="symbol"
                                                    name="symbol"
                                                    label="Symbol"
                                                    variant="outlined"
                                                    required
                                                    size='small'
                                                    value={initialValues1.symbol}
                                                    onChange={handleChange1}
                                                    sx={{
                                                        height: '40px',
                                                        '& .MuiInputBase-root': {
                                                            height: '30px',
                                                        },
                                                        marginTop: '8px',
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4} md={3} lg={2.2} sx={{ flexGrow: 1 }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                    <DatePicker
                                                        id="start_date"
                                                        name="start_date"
                                                        label="Start Date"
                                                        slotProps={{ textField: { size: 'small' } }}
                                                        value={initialValues1.start_date}
                                                        maxDate={today_value1}
                                                        onChange={(value) => handleDateChange1('start_date', value)}
                                                        sx={{
                                                            height: '40px',
                                                            '& .MuiInputBase-root': {
                                                                height: '30px',
                                                            },
                                                            '& .MuiInputAdornment-root': {
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 10,
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={4} md={3} lg={2.2} sx={{ flexGrow: 1 }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                    <DatePicker
                                                        id="end_date"
                                                        name="end_date"
                                                        label="End Date"
                                                        slotProps={{ textField: { size: 'small' } }}
                                                        value={initialValues1.end_date}
                                                        maxDate={today_value1}
                                                        onChange={(value) => handleDateChange1('end_date', value)}
                                                        disabled
                                                        sx={{
                                                            height: '40px',
                                                            '& .MuiInputBase-root': {
                                                                height: '30px',
                                                            },
                                                            '& .MuiInputAdornment-root': {
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 10,
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} display="flex" justifyContent="center" xs={12} md={12}>
                                            <Grid item xs={4} md={3} lg={2}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                    <TimePicker
                                                        id="start_time"
                                                        name="start_time"
                                                        label="Start Time"
                                                        slotProps={{ textField: { size: 'small' } }}
                                                        defaultValue={initialValues1.start_time}
                                                        ampm={false}
                                                        minTime={stEnTimeMin.subtract(1, 'minute')}
                                                        maxTime={stEnTimeMax.add(1, 'minute')}
                                                        onChange={(value) => handleTimeChange1('start_time', value)}
                                                        sx={{
                                                            height: '40px',
                                                            '& .MuiInputBase-root': {
                                                                height: '30px',

                                                            },
                                                            '& .MuiInputAdornment-root': {
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 10,
                                                                },
                                                            },
                                                        }}

                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={4} md={3} lg={2} sx={{ flexGrow: 1 }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                    <TimePicker
                                                        id="end_time"
                                                        name="end_time"
                                                        label="End Time"
                                                        slotProps={{ textField: { size: 'small' } }}
                                                        value={dayjs(initialValues1.end_time, "HH:mm")}
                                                        ampm={false}
                                                        minTime={stEnTimeMin.subtract(1, 'minute')}
                                                        maxTime={stEnTimeMax.add(1, 'minute')}
                                                        onChange={(value) => handleTimeChange1('end_time', value)}
                                                        disabled={dayjs(initialValues1.start_date).isSame(dayjs(), 'day')}
                                                        sx={{
                                                            height: '40px',
                                                            '& .MuiInputBase-root': {
                                                                height: '30px',

                                                            },
                                                            '& .MuiInputAdornment-root': {
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 10,
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={4} md={2} lg={0.9} sx={{ flexGrow: 1 }}>
                                                <TextField
                                                    fullWidth
                                                    id="speed"
                                                    name="speed"
                                                    label="Speed"
                                                    variant="outlined"
                                                    size='small'
                                                    value={initialValues1.speed}
                                                    onChange={handleChange1}
                                                    type='number'
                                                    // inputProps={{ step: 2 }}
                                                    sx={{
                                                        height: '40px',
                                                        '& .MuiInputBase-root': {
                                                            height: '30px',
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4} md={2} lg={1}>
                                                <Button variant="contained" fullWidth1
                                                    sx={{
                                                        backgroundColor: colors.blueAccent[500],
                                                        height: '30px',
                                                        width: '25px'
                                                    }}
                                                    type="button" onClick={handleSubmitForm1}
                                                >
                                                    Search
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                    <Grid container display="flex" alignItems="center" justifyContent="center" sx={{ marginTop: -1.5 }}>
                                        <Grid item md={8}>
                                            <Box display="flex" alignItems="center" justifyContent="center">
                                                <Typography variant='h5' sx={{ fontWeight: 'bold', }}>{Time2Show1}</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container display="flex" alignItems="center" justifyContent="center">
                                        <Grid item md={6}>
                                            <Card fullWidth1>
                                                <Grid display="flex" alignItems="center" justifyContent="center">
                                                    <TableContainer component={Paper} sx={{ marginTop: '-15px', width: '100%', '@media (min-width: 600px)': { width: '100%' } }}>
                                                        <Table aria-label="simple table" className="change-tracking">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <StyledTableCell align="center">Vol BID</StyledTableCell>
                                                                    <StyledTableCell align="center">BID</StyledTableCell>
                                                                    <StyledTableCell align="center">ASK</StyledTableCell>
                                                                    <StyledTableCell align="center">Vol ASK</StyledTableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {rows1.map((row, index) => {
                                                                    if (index < 10) {
                                                                        return (
                                                                            <TableRow key={index}>
                                                                                <TableCell id={`row1-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px', width: '30%' }}>{row.vol_bid}</TableCell>
                                                                                <TableCell id={`row1-cell-${index}-bid`} align="center" style={{ padding: '0.1px', width: '20%' }}>{row.bid}</TableCell>
                                                                                <TableCell id={`row1-cell-${index}-ask`} align="center" style={{ padding: '0.1px', width: '20%' }}>{row.ask}</TableCell>
                                                                                <TableCell id={`row1-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px', width: '30%' }}>{row.vol_ask}</TableCell>
                                                                            </TableRow>
                                                                        );
                                                                    } if (index === 11) {
                                                                        return (
                                                                            <TableRow key={index}>
                                                                                <StyledTableCell id={`row1-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px' }}>{row.vol_bid}</StyledTableCell>
                                                                                <StyledTableCell id={`row1-cell-${index}-bid`} align="center" style={{ padding: '0.1px' }}>{row.bid}</StyledTableCell>
                                                                                <StyledTableCell id={`row1-cell-${index}-ask`} align="center" style={{ padding: '0.1px' }}>{row.ask}</StyledTableCell>
                                                                                <StyledTableCell id={`row1-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px' }}>{row.vol_ask}</StyledTableCell>
                                                                            </TableRow>
                                                                        )
                                                                    }
                                                                    else {
                                                                        return (
                                                                            <TableRow key={index}>
                                                                                <TableCell id={`row1-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px' }}>{row.vol_bid}</TableCell>
                                                                                <TableCell align="center" style={{ padding: '0.1px' }}>{row.bid}</TableCell>
                                                                                <TableCell align="center" style={{ padding: '0.1px' }}>{row.ask}</TableCell>
                                                                                <TableCell id={`row1-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px' }}>{row.vol_ask}</TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    }
                                                                })}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                    <Grid container display="flex" alignItems="center" justifyContent="center">
                                        <Grid item md={4} display="flex" sx={{ height: '28px' }}>
                                            <Slider
                                                defaultValue={1}
                                                aria-label="Time Slider"
                                                valueLabelDisplay="auto"
                                                min={1}
                                                max={bidaskLength1}
                                                value={countRecordBidAsk1}
                                                onChange={handleSliderChange1}
                                                valueLabelFormat={(value) => `${Time2Show1}`}
                                                sx={{
                                                    '& .MuiSlider-thumb': {
                                                        width: 12,
                                                        height: 12,
                                                    },
                                                }}
                                            />
                                            <IconButton onClick={handleClickPlayPause1}>
                                                {isPlaying1 ? <PauseIcon /> : <PlayArrowIcon />} {/* Render play or pause icon based on the value of isPlaying */}
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* item 3 */}
                                <Grid item lg={6} sx={ma}>
                                    <form>
                                        <Grid container spacing={1} display="flex" alignItems="center" justifyContent="center" xs={12} md={12}>
                                            <Grid xs={4} md={10} lg={1.7}>
                                                <TextField
                                                    fullWidth2
                                                    id="symbol"
                                                    name="symbol"
                                                    label="Symbol"
                                                    variant="outlined"
                                                    required
                                                    size='small'
                                                    value={initialValues2.symbol}
                                                    onChange={handleChange2}
                                                    sx={{
                                                        height: '40px',
                                                        '& .MuiInputBase-root': {
                                                            height: '30px',
                                                        },
                                                        marginTop: '8px',
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4} md={3} lg={2.2} sx={{ flexGrow: 1 }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                    <DatePicker
                                                        id="start_date"
                                                        name="start_date"
                                                        label="Start Date"
                                                        slotProps={{ textField: { size: 'small' } }}
                                                        value={initialValues2.start_date}
                                                        maxDate={today_value2}
                                                        onChange={(value) => handleDateChange2('start_date', value)}
                                                        sx={{
                                                            height: '40px',
                                                            '& .MuiInputBase-root': {
                                                                height: '30px',
                                                            },
                                                            '& .MuiInputAdornment-root': {
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 10,
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={4} md={3} lg={2.2} sx={{ flexGrow: 1 }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                    <DatePicker
                                                        id="end_date"
                                                        name="end_date"
                                                        label="End Date"
                                                        slotProps={{ textField: { size: 'small' } }}
                                                        value={initialValues2.end_date}
                                                        maxDate={today_value2}
                                                        onChange={(value) => handleDateChange2('end_date', value)}
                                                        disabled
                                                        sx={{
                                                            height: '40px',
                                                            '& .MuiInputBase-root': {
                                                                height: '30px',
                                                            },
                                                            '& .MuiInputAdornment-root': {
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 10,
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} display="flex" justifyContent="center" xs={12} md={12}>
                                            <Grid item xs={4} md={3} lg={2}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                    <TimePicker
                                                        id="start_time"
                                                        name="start_time"
                                                        label="Start Time"
                                                        slotProps={{ textField: { size: 'small' } }}
                                                        defaultValue={initialValues2.start_time}
                                                        ampm={false}
                                                        minTime={stEnTimeMin.subtract(1, 'minute')}
                                                        maxTime={stEnTimeMax.add(1, 'minute')}
                                                        onChange={(value) => handleTimeChange2('start_time', value)}
                                                        sx={{
                                                            height: '40px',
                                                            '& .MuiInputBase-root': {
                                                                height: '30px',

                                                            },
                                                            '& .MuiInputAdornment-root': {
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 10,
                                                                },
                                                            },
                                                        }}

                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={4} md={3} lg={2} sx={{ flexGrow: 1 }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                    <TimePicker
                                                        id="end_time"
                                                        name="end_time"
                                                        label="End Time"
                                                        slotProps={{ textField: { size: 'small' } }}
                                                        value={dayjs(initialValues2.end_time, "HH:mm")}
                                                        ampm={false}
                                                        minTime={stEnTimeMin.subtract(1, 'minute')}
                                                        maxTime={stEnTimeMax.add(1, 'minute')}
                                                        onChange={(value) => handleTimeChange2('end_time', value)}
                                                        disabled={dayjs(initialValues2.start_date).isSame(dayjs(), 'day')}
                                                        sx={{
                                                            height: '40px',
                                                            '& .MuiInputBase-root': {
                                                                height: '30px',

                                                            },
                                                            '& .MuiInputAdornment-root': {
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 10,
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={4} md={2} lg={0.9} sx={{ flexGrow: 1 }}>
                                                <TextField
                                                    fullWidth2
                                                    id="speed"
                                                    name="speed"
                                                    label="Speed"
                                                    variant="outlined"
                                                    size='small'
                                                    value={initialValues2.speed}
                                                    onChange={handleChange2}
                                                    type='number'
                                                    // inputProps={{ step: 2 }}
                                                    sx={{
                                                        height: '40px',
                                                        '& .MuiInputBase-root': {
                                                            height: '30px',
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4} md={2} lg={1}>
                                                <Button variant="contained" fullWidth
                                                    sx={{
                                                        backgroundColor: colors.blueAccent[500],
                                                        height: '30px',
                                                    }}
                                                    type="button" onClick={handleSubmitForm2}
                                                >
                                                    Search
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                    <Grid container display="flex" alignItems="center" justifyContent="center" sx={{ marginTop: -1.5 }}>
                                        <Grid item md={8}>
                                            <Box display="flex" alignItems="center" justifyContent="center">
                                                <Typography variant='h5' sx={{ fontWeight: 'bold', }}>{Time2Show2}</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container display="flex" alignItems="center" justifyContent="center">
                                        <Grid item md={6}>
                                            <Card fullWidth2>
                                                <Grid display="flex" alignItems="center" justifyContent="center">
                                                    <TableContainer component={Paper} sx={{ marginTop: '-15px', width: '100%', '@media (min-width: 600px)': { width: '100%' } }}>
                                                        <Table aria-label="simple table" className="change-tracking">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <StyledTableCell align="center">Vol BID</StyledTableCell>
                                                                    <StyledTableCell align="center">BID</StyledTableCell>
                                                                    <StyledTableCell align="center">ASK</StyledTableCell>
                                                                    <StyledTableCell align="center">Vol ASK</StyledTableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {rows2.map((row, index) => {
                                                                    if (index < 10) {
                                                                        return (
                                                                            <TableRow key={index}>
                                                                                <TableCell id={`row2-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px', width: '30%' }}>{row.vol_bid}</TableCell>
                                                                                <TableCell id={`row2-cell-${index}-bid`} align="center" style={{ padding: '0.1px', width: '20%' }}>{row.bid}</TableCell>
                                                                                <TableCell id={`row2-cell-${index}-ask`} align="center" style={{ padding: '0.1px', width: '20%' }}>{row.ask}</TableCell>
                                                                                <TableCell id={`row2-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px', width: '30%' }}>{row.vol_ask}</TableCell>
                                                                            </TableRow>
                                                                        );
                                                                    } if (index === 11) {
                                                                        return (
                                                                            <TableRow key={index}>
                                                                                <StyledTableCell id={`row2-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px' }}>{row.vol_bid}</StyledTableCell>
                                                                                <StyledTableCell id={`row2-cell-${index}-bid`} align="center" style={{ padding: '0.1px' }}>{row.bid}</StyledTableCell>
                                                                                <StyledTableCell id={`row2-cell-${index}-ask`} align="center" style={{ padding: '0.1px' }}>{row.ask}</StyledTableCell>
                                                                                <StyledTableCell id={`row2-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px' }}>{row.vol_ask}</StyledTableCell>
                                                                            </TableRow>
                                                                        )
                                                                    }
                                                                    else {
                                                                        return (
                                                                            <TableRow key={index}>
                                                                                <TableCell id={`row2-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px' }}>{row.vol_bid}</TableCell>
                                                                                <TableCell align="center" style={{ padding: '0.1px' }}>{row.bid}</TableCell>
                                                                                <TableCell align="center" style={{ padding: '0.1px' }}>{row.ask}</TableCell>
                                                                                <TableCell id={`row2-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px' }}>{row.vol_ask}</TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    }
                                                                })}


                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                    <Grid container display="flex" alignItems="center" justifyContent="center">
                                        <Grid item md={4} display="flex" sx={{ height: '28px' }}>
                                            <Slider
                                                defaultValue={1}
                                                aria-label="Time Slider"
                                                valueLabelDisplay="auto"
                                                min={1}
                                                max={bidaskLength2}
                                                value={countRecordBidAsk2}
                                                onChange={handleSliderChange2}
                                                valueLabelFormat={(value) => `${Time2Show2}`}
                                                sx={{
                                                    '& .MuiSlider-thumb': {
                                                        width: 12,
                                                        height: 12,
                                                    },
                                                }}
                                            />
                                            <IconButton onClick={handleClickPlayPause2}>
                                                {isPlaying2 ? <PauseIcon /> : <PlayArrowIcon />} {/* Render play or pause icon based on the value of isPlaying */}
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item lg={6} sx={{
                                    ...ma,
                                    display: {
                                        lg: 'none',
                                        xl: 'block',
                                    },
                                }}>
                                    <form>
                                        <Grid container spacing={1} display="flex" alignItems="center" justifyContent="center" xs={12} md={12}>
                                            <Grid xs={4} md={10} lg={1.7}>
                                                <TextField
                                                    fullWidth3
                                                    id="symbol"
                                                    name="symbol"
                                                    label="Symbol"
                                                    variant="outlined"
                                                    required
                                                    size='small'
                                                    value={initialValues3.symbol}
                                                    onChange={handleChange3}
                                                    sx={{
                                                        height: '40px',
                                                        '& .MuiInputBase-root': {
                                                            height: '30px',
                                                        },
                                                        marginTop: '8px',
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4} md={3} lg={2.2} sx={{ flexGrow: 1 }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                    <DatePicker
                                                        id="start_date"
                                                        name="start_date"
                                                        label="Start Date"
                                                        slotProps={{ textField: { size: 'small' } }}
                                                        value={initialValues3.start_date}
                                                        maxDate={today_value3}
                                                        onChange={(value) => handleDateChange3('start_date', value)}
                                                        sx={{
                                                            height: '40px',
                                                            '& .MuiInputBase-root': {
                                                                height: '30px',
                                                            },
                                                            '& .MuiInputAdornment-root': {
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 10,
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={4} md={3} lg={2.2} sx={{ flexGrow: 1 }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                    <DatePicker
                                                        id="end_date"
                                                        name="end_date"
                                                        label="End Date"
                                                        slotProps={{ textField: { size: 'small' } }}
                                                        value={initialValues3.end_date}
                                                        maxDate={today_value3}
                                                        onChange={(value) => handleDateChange3('end_date', value)}
                                                        disabled
                                                        sx={{
                                                            height: '40px',
                                                            '& .MuiInputBase-root': {
                                                                height: '30px',
                                                            },
                                                            '& .MuiInputAdornment-root': {
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 10,
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} display="flex" justifyContent="center" xs={12} md={12}>
                                            <Grid item xs={4} md={3} lg={2}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                    <TimePicker
                                                        id="start_time"
                                                        name="start_time"
                                                        label="Start Time"
                                                        slotProps={{ textField: { size: 'small' } }}
                                                        defaultValue={initialValues3.start_time}
                                                        ampm={false}
                                                        minTime={stEnTimeMin.subtract(1, 'minute')}
                                                        maxTime={stEnTimeMax.add(1, 'minute')}
                                                        onChange={(value) => handleTimeChange3('start_time', value)}
                                                        sx={{
                                                            height: '40px',
                                                            '& .MuiInputBase-root': {
                                                                height: '30px',

                                                            },
                                                            '& .MuiInputAdornment-root': {
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 10,
                                                                },
                                                            },
                                                        }}

                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={4} md={3} lg={2} sx={{ flexGrow: 1 }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                    <TimePicker
                                                        id="end_time"
                                                        name="end_time"
                                                        label="End Time"
                                                        slotProps={{ textField: { size: 'small' } }}
                                                        value={dayjs(initialValues3.end_time, "HH:mm")}
                                                        ampm={false}
                                                        minTime={stEnTimeMin.subtract(1, 'minute')}
                                                        maxTime={stEnTimeMax.add(1, 'minute')}
                                                        onChange={(value) => handleTimeChange3('end_time', value)}
                                                        disabled={dayjs(initialValues3.start_date).isSame(dayjs(), 'day')}
                                                        sx={{
                                                            height: '40px',
                                                            '& .MuiInputBase-root': {
                                                                height: '30px',

                                                            },
                                                            '& .MuiInputAdornment-root': {
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 10,
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={4} md={2} lg={0.9} sx={{ flexGrow: 1 }}>
                                                <TextField
                                                    fullWidth3
                                                    id="speed"
                                                    name="speed"
                                                    label="Speed"
                                                    variant="outlined"
                                                    size='small'
                                                    value={initialValues3.speed}
                                                    onChange={handleChange}
                                                    type='number'
                                                    // inputProps={{ step: 2 }}
                                                    sx={{
                                                        height: '40px',
                                                        '& .MuiInputBase-root': {
                                                            height: '30px',
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4} md={2} lg={1}>
                                                <Button variant="contained" fullWidth3
                                                    sx={{
                                                        backgroundColor: colors.blueAccent[500],
                                                        height: '30px',
                                                    }}
                                                    type="button" onClick={handleSubmitForm3}
                                                >
                                                    Search
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                    <Grid container display="flex" alignItems="center" justifyContent="center" sx={{ marginTop: -1.5 }}>
                                        <Grid item md={8}>
                                            <Box display="flex" alignItems="center" justifyContent="center">
                                                <Typography variant='h5' sx={{ fontWeight: 'bold', }}>{Time2Show3}</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container display="flex" alignItems="center" justifyContent="center">
                                        <Grid item md={6}>
                                            <Card fullWidth3>
                                                <Grid display="flex" alignItems="center" justifyContent="center">
                                                    <TableContainer component={Paper} sx={{ marginTop: '-15px', width: '100%', '@media (min-width: 600px)': { width: '100%' } }}>
                                                        <Table aria-label="simple table" className="change-tracking">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <StyledTableCell align="center">Vol BID</StyledTableCell>
                                                                    <StyledTableCell align="center">BID</StyledTableCell>
                                                                    <StyledTableCell align="center">ASK</StyledTableCell>
                                                                    <StyledTableCell align="center">Vol ASK</StyledTableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {rows3.map((row, index) => {
                                                                    if (index < 10) {
                                                                        return (
                                                                            <TableRow key={index}>
                                                                                <TableCell id={`row3-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px', width: '30%' }}>{row.vol_bid}</TableCell>
                                                                                <TableCell id={`row3-cell-${index}-bid`} align="center" style={{ padding: '0.1px', width: '20%' }}>{row.bid}</TableCell>
                                                                                <TableCell id={`row3-cell-${index}-ask`} align="center" style={{ padding: '0.1px', width: '20%' }}>{row.ask}</TableCell>
                                                                                <TableCell id={`row3-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px', width: '30%' }}>{row.vol_ask}</TableCell>
                                                                            </TableRow>
                                                                        );
                                                                    } if (index === 11) {
                                                                        return (
                                                                            <TableRow key={index}>
                                                                                <StyledTableCell id={`row3-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px' }}>{row.vol_bid}</StyledTableCell>
                                                                                <StyledTableCell id={`row3-cell-${index}-bid`} align="center" style={{ padding: '0.1px' }}>{row.bid}</StyledTableCell>
                                                                                <StyledTableCell id={`row3-cell-${index}-ask`} align="center" style={{ padding: '0.1px' }}>{row.ask}</StyledTableCell>
                                                                                <StyledTableCell id={`row3-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px' }}>{row.vol_ask}</StyledTableCell>
                                                                            </TableRow>
                                                                        )
                                                                    }
                                                                    else {
                                                                        return (
                                                                            <TableRow key={index}>
                                                                                <TableCell id={`row3-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px' }}>{row.vol_bid}</TableCell>
                                                                                <TableCell align="center" style={{ padding: '0.1px' }}>{row.bid}</TableCell>
                                                                                <TableCell align="center" style={{ padding: '0.1px' }}>{row.ask}</TableCell>
                                                                                <TableCell id={`row3-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px' }}>{row.vol_ask}</TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    }
                                                                })}


                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                    <Grid container display="flex" alignItems="center" justifyContent="center">
                                        <Grid item md={4} display="flex" sx={{ height: '28px' }}>
                                            <Slider
                                                defaultValue={1}
                                                aria-label="Time Slider"
                                                valueLabelDisplay="auto"
                                                min={1}
                                                max={bidaskLength3}
                                                value={countRecordBidAsk3}
                                                onChange={handleSliderChange3}
                                                valueLabelFormat={(value) => `${Time2Show3}`}
                                                sx={{
                                                    '& .MuiSlider-thumb': {
                                                        width: 12,
                                                        height: 12,
                                                    },
                                                }}
                                            />
                                            <IconButton onClick={handleClickPlayPause3}>
                                                {isPlaying3 ? <PauseIcon /> : <PlayArrowIcon />}
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid display="flex" sx={{ marginLeft: { lg: -20, xl: -35 } }}>
                                {/* item 5 */}
                                <Grid item lg={6} sx={ma}>
                                    <form>
                                        <Grid container spacing={1} display="flex" alignItems="center" justifyContent="center" xs={12} md={12}>
                                            <Grid xs={4} md={10} lg={1.7}>
                                                <TextField
                                                    fullWidth4
                                                    id="symbol"
                                                    name="symbol"
                                                    label="Symbol"
                                                    variant="outlined"
                                                    required
                                                    size='small'
                                                    value={initialValues4.symbol}
                                                    onChange={handleChange4}
                                                    sx={{
                                                        height: '40px',
                                                        '& .MuiInputBase-root': {
                                                            height: '30px',
                                                        },
                                                        marginTop: '8px',
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4} md={3} lg={2.2} sx={{ flexGrow: 1 }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                    <DatePicker
                                                        id="start_date"
                                                        name="start_date"
                                                        label="Start Date"
                                                        slotProps={{ textField: { size: 'small' } }}
                                                        value={initialValues4.start_date}
                                                        maxDate={today_value4}
                                                        onChange={(value) => handleDateChange4('start_date', value)}
                                                        sx={{
                                                            height: '40px',
                                                            '& .MuiInputBase-root': {
                                                                height: '30px',
                                                            },
                                                            '& .MuiInputAdornment-root': {
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 10,
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={4} md={3} lg={2.2} sx={{ flexGrow: 1 }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                    <DatePicker
                                                        id="end_date"
                                                        name="end_date"
                                                        label="End Date"
                                                        slotProps={{ textField: { size: 'small' } }}
                                                        value={initialValues4.end_date}
                                                        maxDate={today_value4}
                                                        onChange={(value) => handleDateChange4('end_date', value)}
                                                        disabled
                                                        sx={{
                                                            height: '40px',
                                                            '& .MuiInputBase-root': {
                                                                height: '30px',
                                                            },
                                                            '& .MuiInputAdornment-root': {
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 10,
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} display="flex" justifyContent="center" xs={12} md={12}>
                                            <Grid item xs={4} md={3} lg={2}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                    <TimePicker
                                                        id="start_time"
                                                        name="start_time"
                                                        label="Start Time"
                                                        slotProps={{ textField: { size: 'small' } }}
                                                        defaultValue={initialValues4.start_time}
                                                        ampm={false}
                                                        minTime={stEnTimeMin.subtract(1, 'minute')}
                                                        maxTime={stEnTimeMax.add(1, 'minute')}
                                                        onChange={(value) => handleTimeChange4('start_time', value)}
                                                        sx={{
                                                            height: '40px',
                                                            '& .MuiInputBase-root': {
                                                                height: '30px',

                                                            },
                                                            '& .MuiInputAdornment-root': {
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 10,
                                                                },
                                                            },
                                                        }}

                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={4} md={3} lg={2} sx={{ flexGrow: 1 }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                    <TimePicker
                                                        id="end_time"
                                                        name="end_time"
                                                        label="End Time"
                                                        slotProps={{ textField: { size: 'small' } }}
                                                        value={dayjs(initialValues4.end_time, "HH:mm")}
                                                        ampm={false}
                                                        minTime={stEnTimeMin.subtract(1, 'minute')}
                                                        maxTime={stEnTimeMax.add(1, 'minute')}
                                                        onChange={(value) => handleTimeChange4('end_time', value)}
                                                        disabled={dayjs(initialValues4.start_date).isSame(dayjs(), 'day')}
                                                        sx={{
                                                            height: '40px',
                                                            '& .MuiInputBase-root': {
                                                                height: '30px',

                                                            },
                                                            '& .MuiInputAdornment-root': {
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 10,
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={4} md={2} lg={0.9} sx={{ flexGrow: 1 }}>
                                                <TextField
                                                    fullWidth4
                                                    id="speed"
                                                    name="speed"
                                                    label="Speed"
                                                    variant="outlined"
                                                    size='small'
                                                    value={initialValues4.speed}
                                                    onChange={handleChange4}
                                                    type='number'
                                                    // inputProps={{ step: 2 }}
                                                    sx={{
                                                        height: '40px',
                                                        '& .MuiInputBase-root': {
                                                            height: '30px',
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4} md={2} lg={1}>
                                                <Button variant="contained" fullWidth4
                                                    sx={{
                                                        backgroundColor: colors.blueAccent[500],
                                                        height: '30px',
                                                    }}
                                                    type="button" onClick={handleSubmitForm4}
                                                >
                                                    Search
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                    <Grid container display="flex" alignItems="center" justifyContent="center" sx={{ marginTop: -1.5 }}>
                                        <Grid item md={8}>
                                            <Box display="flex" alignItems="center" justifyContent="center">
                                                <Typography variant='h5' sx={{ fontWeight: 'bold', }}>{Time2Show4}</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container display="flex" alignItems="center" justifyContent="center">
                                        <Grid item md={6}>
                                            <Card fullWidth4>
                                                <Grid display="flex" alignItems="center" justifyContent="center">
                                                    <TableContainer component={Paper} sx={{ marginTop: '-15px', width: '100%', '@media (min-width: 600px)': { width: '100%' } }}>
                                                        <Table aria-label="simple table" className="change-tracking">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <StyledTableCell align="center">Vol BID</StyledTableCell>
                                                                    <StyledTableCell align="center">BID</StyledTableCell>
                                                                    <StyledTableCell align="center">ASK</StyledTableCell>
                                                                    <StyledTableCell align="center">Vol ASK</StyledTableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {rows4.map((row, index) => (
                                                                    <TableRow key={index}>
                                                                        <TableCell id={`row4-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px' }}>{row.vol_bid}</TableCell>
                                                                        <TableCell id={`row4-cell-${index}-bid`} align="center" style={{ padding: '0.1px' }}>{row.bid}</TableCell>
                                                                        <TableCell id={`row4-cell-${index}-ask`} align="center" style={{ padding: '0.1px' }}>{row.ask}</TableCell>
                                                                        <TableCell id={`row4-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px' }}>{row.vol_ask}</TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                    <Grid container display="flex" alignItems="center" justifyContent="center">
                                        <Grid item md={4} display="flex" sx={{ height: '28px' }}>
                                            <Slider
                                                defaultValue={1}
                                                aria-label="Time Slider"
                                                valueLabelDisplay="auto"
                                                min={1}
                                                max={bidaskLength4}
                                                value={countRecordBidAsk4}
                                                onChange={handleSliderChange4}
                                                valueLabelFormat={(value) => `${Time2Show4}`}
                                                sx={{
                                                    '& .MuiSlider-thumb': {
                                                        width: 12,
                                                        height: 12,
                                                    },
                                                }}
                                            />
                                            <IconButton onClick={handleClickPlayPause4} sx={{ marginTop: '7px' }}>
                                                {isPlaying4 ? <PauseIcon /> : <PlayArrowIcon />}
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* item 6 */}
                                <Grid item lg={6} sx={ma}>
                                    <form>
                                        <Grid container spacing={1} display="flex" alignItems="center" justifyContent="center" xs={12} md={12}>
                                            <Grid xs={4} md={10} lg={1.7}>
                                                <TextField
                                                    fullWidth5
                                                    id="symbol"
                                                    name="symbol"
                                                    label="Symbol"
                                                    variant="outlined"
                                                    required
                                                    size='small'
                                                    value={initialValues5.symbol}
                                                    onChange={handleChange5}
                                                    sx={{
                                                        height: '40px',
                                                        '& .MuiInputBase-root': {
                                                            height: '30px',
                                                        },
                                                        marginTop: '8px',
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4} md={3} lg={2.2} sx={{ flexGrow: 1 }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                    <DatePicker
                                                        id="start_date"
                                                        name="start_date"
                                                        label="Start Date"
                                                        slotProps={{ textField: { size: 'small' } }}
                                                        value={initialValues5.start_date}
                                                        maxDate={today_value5}
                                                        onChange={(value) => handleDateChange5('start_date', value)}
                                                        sx={{
                                                            height: '40px',
                                                            '& .MuiInputBase-root': {
                                                                height: '30px',
                                                            },
                                                            '& .MuiInputAdornment-root': {
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 10,
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={4} md={3} lg={2.2} sx={{ flexGrow: 1 }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                    <DatePicker
                                                        id="end_date"
                                                        name="end_date"
                                                        label="End Date"
                                                        slotProps={{ textField: { size: 'small' } }}
                                                        value={initialValues5.end_date}
                                                        maxDate={today_value5}
                                                        onChange={(value) => handleDateChange5('end_date', value)}
                                                        disabled
                                                        sx={{
                                                            height: '40px',
                                                            '& .MuiInputBase-root': {
                                                                height: '30px',
                                                            },
                                                            '& .MuiInputAdornment-root': {
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 10,
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} display="flex" justifyContent="center" xs={12} md={12}>
                                            <Grid item xs={4} md={3} lg={2}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                    <TimePicker
                                                        id="start_time"
                                                        name="start_time"
                                                        label="Start Time"
                                                        slotProps={{ textField: { size: 'small' } }}
                                                        defaultValue={initialValues5.start_time}
                                                        ampm={false}
                                                        minTime={stEnTimeMin.subtract(1, 'minute')}
                                                        maxTime={stEnTimeMax.add(1, 'minute')}
                                                        onChange={(value) => handleTimeChange5('start_time', value)}
                                                        sx={{
                                                            height: '40px',
                                                            '& .MuiInputBase-root': {
                                                                height: '30px',

                                                            },
                                                            '& .MuiInputAdornment-root': {
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 10,
                                                                },
                                                            },
                                                        }}

                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={4} md={3} lg={2} sx={{ flexGrow: 1 }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                    <TimePicker
                                                        id="end_time"
                                                        name="end_time"
                                                        label="End Time"
                                                        slotProps={{ textField: { size: 'small' } }}
                                                        value={dayjs(initialValues5.end_time, "HH:mm")}
                                                        ampm={false}
                                                        minTime={stEnTimeMin.subtract(1, 'minute')}
                                                        maxTime={stEnTimeMax.add(1, 'minute')}
                                                        onChange={(value) => handleTimeChange5('end_time', value)}
                                                        disabled={dayjs(initialValues5.start_date).isSame(dayjs(), 'day')}
                                                        sx={{
                                                            height: '40px',
                                                            '& .MuiInputBase-root': {
                                                                height: '30px',

                                                            },
                                                            '& .MuiInputAdornment-root': {
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 10,
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={4} md={2} lg={0.9} sx={{ flexGrow: 1 }}>
                                                <TextField
                                                    fullWidth5
                                                    id="speed"
                                                    name="speed"
                                                    label="Speed"
                                                    variant="outlined"
                                                    size='small'
                                                    value={initialValues5.speed}
                                                    onChange={handleChange5}
                                                    type='number'
                                                    // inputProps={{ step: 2 }}
                                                    sx={{
                                                        height: '40px',
                                                        '& .MuiInputBase-root': {
                                                            height: '30px',
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4} md={2} lg={1}>
                                                <Button variant="contained" fullWidth5
                                                    sx={{
                                                        backgroundColor: colors.blueAccent[500],
                                                        height: '30px',
                                                    }}
                                                    type="button" onClick={handleSubmitForm5}
                                                >
                                                    Search
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                    <Grid container display="flex" alignItems="center" justifyContent="center" sx={{ marginTop: -1.5 }}>
                                        <Grid item md={8}>
                                            <Box display="flex" alignItems="center" justifyContent="center">
                                                <Typography variant='h5' sx={{ fontWeight: 'bold', }}>{Time2Show5}</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container display="flex" alignItems="center" justifyContent="center">
                                        <Grid item md={6}>
                                            <Card fullWidth5>
                                                <Grid display="flex" alignItems="center" justifyContent="center">
                                                    <TableContainer component={Paper} sx={{ marginTop: '-15px', width: '100%', '@media (min-width: 600px)': { width: '100%' } }}>
                                                        <Table aria-label="simple table" className="change-tracking">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <StyledTableCell align="center">Vol BID</StyledTableCell>
                                                                    <StyledTableCell align="center">BID</StyledTableCell>
                                                                    <StyledTableCell align="center">ASK</StyledTableCell>
                                                                    <StyledTableCell align="center">Vol ASK</StyledTableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {rows5.map((row, index) => (
                                                                    <TableRow key={index}>
                                                                        <TableCell id={`row5-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px' }}>{row.vol_bid}</TableCell>
                                                                        <TableCell id={`row5-cell-${index}-bid`} align="center" style={{ padding: '0.1px' }}>{row.bid}</TableCell>
                                                                        <TableCell id={`row5-cell-${index}-ask`} align="center" style={{ padding: '0.1px' }}>{row.ask}</TableCell>
                                                                        <TableCell id={`row5-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px' }}>{row.vol_ask}</TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                    <Grid container display="flex" alignItems="center" justifyContent="center">
                                        <Grid item md={4} display="flex" sx={{ height: '28px' }}>
                                            <Slider
                                                defaultValue={1}
                                                aria-label="Time Slider"
                                                valueLabelDisplay="auto"
                                                min={1}
                                                max={bidaskLength5}
                                                value={countRecordBidAsk5}
                                                onChange={handleSliderChange5}
                                                valueLabelFormat={(value) => `${Time2Show5}`}
                                                sx={{
                                                    '& .MuiSlider-thumb': {
                                                        width: 12,
                                                        height: 12,
                                                    },
                                                }}
                                            />
                                            <IconButton onClick={handleClickPlayPause5} sx={{ marginTop: '7px' }}>
                                                {isPlaying5 ? <PauseIcon /> : <PlayArrowIcon />}
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* item 7 */}
                                <Grid item lg={6} sx={ma}>
                                    <form>
                                        <Grid container spacing={1} display="flex" alignItems="center" justifyContent="center" xs={12} md={12}>
                                            <Grid xs={4} md={10} lg={1.7}>
                                                <TextField
                                                    fullWidth6
                                                    id="symbol"
                                                    name="symbol"
                                                    label="Symbol"
                                                    variant="outlined"
                                                    required
                                                    size='small'
                                                    value={initialValues6.symbol}
                                                    onChange={handleChange6}
                                                    sx={{
                                                        height: '40px',
                                                        '& .MuiInputBase-root': {
                                                            height: '30px',
                                                        },
                                                        marginTop: '8px',
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4} md={3} lg={2.2} sx={{ flexGrow: 1 }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                    <DatePicker
                                                        id="start_date"
                                                        name="start_date"
                                                        label="Start Date"
                                                        slotProps={{ textField: { size: 'small' } }}
                                                        value={initialValues6.start_date}
                                                        maxDate={today_value6}
                                                        onChange={(value) => handleDateChange6('start_date', value)}
                                                        sx={{
                                                            height: '40px',
                                                            '& .MuiInputBase-root': {
                                                                height: '30px',
                                                            },
                                                            '& .MuiInputAdornment-root': {
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 10,
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={4} md={3} lg={2.2} sx={{ flexGrow: 1 }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                    <DatePicker
                                                        id="end_date"
                                                        name="end_date"
                                                        label="End Date"
                                                        slotProps={{ textField: { size: 'small' } }}
                                                        value={initialValues6.end_date}
                                                        maxDate={today_value6}
                                                        onChange={(value) => handleDateChange6('end_date', value)}
                                                        disabled
                                                        sx={{
                                                            height: '40px',
                                                            '& .MuiInputBase-root': {
                                                                height: '30px',
                                                            },
                                                            '& .MuiInputAdornment-root': {
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 10,
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} display="flex" justifyContent="center" xs={12} md={12}>
                                            <Grid item xs={4} md={3} lg={2}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                    <TimePicker
                                                        id="start_time"
                                                        name="start_time"
                                                        label="Start Time"
                                                        slotProps={{ textField: { size: 'small' } }}
                                                        defaultValue={initialValues6.start_time}
                                                        ampm={false}
                                                        minTime={stEnTimeMin.subtract(1, 'minute')}
                                                        maxTime={stEnTimeMax.add(1, 'minute')}
                                                        onChange={(value) => handleTimeChange6('start_time', value)}
                                                        sx={{
                                                            height: '40px',
                                                            '& .MuiInputBase-root': {
                                                                height: '30px',

                                                            },
                                                            '& .MuiInputAdornment-root': {
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 10,
                                                                },
                                                            },
                                                        }}

                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={4} md={3} lg={2} sx={{ flexGrow: 1 }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                    <TimePicker
                                                        id="end_time"
                                                        name="end_time"
                                                        label="End Time"
                                                        slotProps={{ textField: { size: 'small' } }}
                                                        value={dayjs(initialValues6.end_time, "HH:mm")}
                                                        ampm={false}
                                                        minTime={stEnTimeMin.subtract(1, 'minute')}
                                                        maxTime={stEnTimeMax.add(1, 'minute')}
                                                        onChange={(value) => handleTimeChange6('end_time', value)}
                                                        disabled={dayjs(initialValues6.start_date).isSame(dayjs(), 'day')}
                                                        sx={{
                                                            height: '40px',
                                                            '& .MuiInputBase-root': {
                                                                height: '30px',

                                                            },
                                                            '& .MuiInputAdornment-root': {
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 10,
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={4} md={2} lg={0.9} sx={{ flexGrow: 1 }}>
                                                <TextField
                                                    fullWidth6
                                                    id="speed"
                                                    name="speed"
                                                    label="Speed"
                                                    variant="outlined"
                                                    size='small'
                                                    value={initialValues6.speed}
                                                    onChange={handleChange6}
                                                    type='number'
                                                    // inputProps={{ step: 2 }}
                                                    sx={{
                                                        height: '40px',
                                                        '& .MuiInputBase-root': {
                                                            height: '30px',
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4} md={2} lg={1}>
                                                <Button variant="contained" fullWidth6
                                                    sx={{
                                                        backgroundColor: colors.blueAccent[500],
                                                        height: '30px',
                                                    }}
                                                    type="button" onClick={handleSubmitForm6}
                                                >
                                                    Search
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                    <Grid container display="flex" alignItems="center" justifyContent="center" sx={{ marginTop: -1.5 }}>
                                        <Grid item md={8}>
                                            <Box display="flex" alignItems="center" justifyContent="center">
                                                <Typography variant='h5' sx={{ fontWeight: 'bold', }}>{Time2Show6}</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container display="flex" alignItems="center" justifyContent="center">
                                        <Grid item md={6}>
                                            <Card fullWidth6>
                                                <Grid display="flex" alignItems="center" justifyContent="center">
                                                    <TableContainer component={Paper} sx={{ marginTop: '-15px', width: '100%', '@media (min-width: 600px)': { width: '100%' } }}>
                                                        <Table aria-label="simple table" className="change-tracking">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <StyledTableCell align="center">Vol BID</StyledTableCell>
                                                                    <StyledTableCell align="center">BID</StyledTableCell>
                                                                    <StyledTableCell align="center">ASK</StyledTableCell>
                                                                    <StyledTableCell align="center">Vol ASK</StyledTableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {rows6.map((row, index) => (
                                                                    <TableRow key={index}>
                                                                        <TableCell id={`row6-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px' }}>{row.vol_bid}</TableCell>
                                                                        <TableCell id={`row6-cell-${index}-bid`} align="center" style={{ padding: '0.1px' }}>{row.bid}</TableCell>
                                                                        <TableCell id={`row6-cell-${index}-ask`} align="center" style={{ padding: '0.1px' }}>{row.ask}</TableCell>
                                                                        <TableCell id={`row6-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px' }}>{row.vol_ask}</TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                    <Grid container display="flex" alignItems="center" justifyContent="center">
                                        <Grid item md={4} display="flex" sx={{ height: '28px' }}>
                                            <Slider
                                                defaultValue={1}
                                                aria-label="Time Slider"
                                                valueLabelDisplay="auto"
                                                min={1}
                                                max={bidaskLength6}
                                                value={countRecordBidAsk6}
                                                onChange={handleSliderChange6}
                                                valueLabelFormat={(value) => `${Time2Show6}`}
                                                sx={{
                                                    '& .MuiSlider-thumb': {
                                                        width: 12,
                                                        height: 12,
                                                    },
                                                }}
                                            />
                                            <IconButton onClick={handleClickPlayPause6} sx={{ marginTop: '7px' }}>
                                                {isPlaying6 ? <PauseIcon /> : <PlayArrowIcon />}
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* item 8 */}
                                <Grid item lg={6} sx={{
                                    ...ma,
                                    display: {
                                        lg: 'none',
                                        xl: 'block',
                                    },
                                }}>
                                    <form>
                                        <Grid container spacing={1} display="flex" alignItems="center" justifyContent="center" xs={12} md={12}>
                                            <Grid xs={4} md={10} lg={1.7}>
                                                <TextField
                                                    fullWidth7
                                                    id="symbol"
                                                    name="symbol"
                                                    label="Symbol"
                                                    variant="outlined"
                                                    required
                                                    size='small'
                                                    value={initialValues7.symbol}
                                                    onChange={handleChange7}
                                                    sx={{
                                                        height: '40px',
                                                        '& .MuiInputBase-root': {
                                                            height: '30px',
                                                        },
                                                        marginTop: '8px',
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4} md={3} lg={2.2} sx={{ flexGrow: 1 }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                    <DatePicker
                                                        id="start_date"
                                                        name="start_date"
                                                        label="Start Date"
                                                        slotProps={{ textField: { size: 'small' } }}
                                                        value={initialValues7.start_date}
                                                        maxDate={today_value7}
                                                        onChange={(value) => handleDateChange7('start_date', value)}
                                                        sx={{
                                                            height: '40px',
                                                            '& .MuiInputBase-root': {
                                                                height: '30px',
                                                            },
                                                            '& .MuiInputAdornment-root': {
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 10,
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={4} md={3} lg={2.2} sx={{ flexGrow: 1 }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                    <DatePicker
                                                        id="end_date"
                                                        name="end_date"
                                                        label="End Date"
                                                        slotProps={{ textField: { size: 'small' } }}
                                                        value={initialValues7.end_date}
                                                        maxDate={today_value7}
                                                        onChange={(value) => handleDateChange7('end_date', value)}
                                                        disabled
                                                        sx={{
                                                            height: '40px',
                                                            '& .MuiInputBase-root': {
                                                                height: '30px',
                                                            },
                                                            '& .MuiInputAdornment-root': {
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 10,
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} display="flex" justifyContent="center" xs={12} md={12}>
                                            <Grid item xs={4} md={3} lg={2}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                    <TimePicker
                                                        id="start_time"
                                                        name="start_time"
                                                        label="Start Time"
                                                        slotProps={{ textField: { size: 'small' } }}
                                                        defaultValue={initialValues7.start_time}
                                                        ampm={false}
                                                        minTime={stEnTimeMin.subtract(1, 'minute')}
                                                        maxTime={stEnTimeMax.add(1, 'minute')}
                                                        onChange={(value) => handleTimeChange7('start_time', value)}
                                                        sx={{
                                                            height: '40px',
                                                            '& .MuiInputBase-root': {
                                                                height: '30px',

                                                            },
                                                            '& .MuiInputAdornment-root': {
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 10,
                                                                },
                                                            },
                                                        }}

                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={4} md={3} lg={2} sx={{ flexGrow: 1 }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                    <TimePicker
                                                        id="end_time"
                                                        name="end_time"
                                                        label="End Time"
                                                        slotProps={{ textField: { size: 'small' } }}
                                                        value={dayjs(initialValues7.end_time, "HH:mm")}
                                                        ampm={false}
                                                        minTime={stEnTimeMin.subtract(1, 'minute')}
                                                        maxTime={stEnTimeMax.add(1, 'minute')}
                                                        onChange={(value) => handleTimeChange7('end_time', value)}
                                                        disabled={dayjs(initialValues7.start_date).isSame(dayjs(), 'day')}
                                                        sx={{
                                                            height: '40px',
                                                            '& .MuiInputBase-root': {
                                                                height: '30px',

                                                            },
                                                            '& .MuiInputAdornment-root': {
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 10,
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={4} md={2} lg={0.9} sx={{ flexGrow: 1 }}>
                                                <TextField
                                                    fullWidth7
                                                    id="speed"
                                                    name="speed"
                                                    label="Speed"
                                                    variant="outlined"
                                                    size='small'
                                                    value={initialValues7.speed}
                                                    onChange={handleChange7}
                                                    type='number'
                                                    // inputProps={{ step: 2 }}
                                                    sx={{
                                                        height: '40px',
                                                        '& .MuiInputBase-root': {
                                                            height: '30px',
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4} md={2} lg={1}>
                                                <Button variant="contained" fullWidth7
                                                    sx={{
                                                        backgroundColor: colors.blueAccent[500],
                                                        height: '30px',
                                                    }}
                                                    type="button" onClick={handleSubmitForm7}
                                                >
                                                    Search
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                    <Grid container display="flex" alignItems="center" justifyContent="center" sx={{ marginTop: -1.5 }}>
                                        <Grid item md={8}>
                                            <Box display="flex" alignItems="center" justifyContent="center">
                                                <Typography variant='h5' sx={{ fontWeight: 'bold', }}>{Time2Show7}</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container display="flex" alignItems="center" justifyContent="center">
                                        <Grid item md={6}>
                                            <Card fullWidth7>
                                                <Grid display="flex" alignItems="center" justifyContent="center">
                                                    <TableContainer component={Paper} sx={{ marginTop: '-15px', width: '100%', '@media (min-width: 600px)': { width: '100%' } }}>
                                                        <Table aria-label="simple table" className="change-tracking">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <StyledTableCell align="center">Vol BID</StyledTableCell>
                                                                    <StyledTableCell align="center">BID</StyledTableCell>
                                                                    <StyledTableCell align="center">ASK</StyledTableCell>
                                                                    <StyledTableCell align="center">Vol ASK</StyledTableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {rows7.map((row, index) => (
                                                                    <TableRow key={index}>
                                                                        <TableCell id={`row7-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px' }}>{row.vol_bid}</TableCell>
                                                                        <TableCell id={`row7-cell-${index}-bid`} align="center" style={{ padding: '0.1px' }}>{row.bid}</TableCell>
                                                                        <TableCell id={`row7-cell-${index}-ask`} align="center" style={{ padding: '0.1px' }}>{row.ask}</TableCell>
                                                                        <TableCell id={`row7-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px' }}>{row.vol_ask}</TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                    <Grid container display="flex" alignItems="center" justifyContent="center">
                                        <Grid item md={4} display="flex" sx={{ height: '28px' }}>
                                            <Slider
                                                defaultValue={1}
                                                aria-label="Time Slider"
                                                valueLabelDisplay="auto"
                                                min={1}
                                                max={bidaskLength7}
                                                value={countRecordBidAsk7}
                                                onChange={handleSliderChange7}
                                                valueLabelFormat={(value) => `${Time2Show7}`}
                                                sx={{
                                                    '& .MuiSlider-thumb': {
                                                        width: 12,
                                                        height: 12,
                                                    },
                                                }}
                                            />
                                            <IconButton onClick={handleClickPlayPause7} sx={{ marginTop: '7px' }}>
                                                {isPlaying7 ? <PauseIcon /> : <PlayArrowIcon />}
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    )}
                    {(isTablet && !isPTablet) && (
                        <>
                            <Swiper
                                style={{ width: '100%', maxWidth: '100vw', marginLeft: '0' }}
                                modules={[Navigation, Pagination, Scrollbar, A11y]}
                                spaceBetween={30}
                                slidesPerView={1}
                                pagination={{
                                    clickable: true,
                                    dynamicBullets: true,
                                }}
                            >
                                <SwiperSlide>
                                    {/* หน้าที่ 1 */}
                                    <Grid container spacing={1} style={{ padding: '16px 0 0 16px' }}>
                                        {/* item1 */}
                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                            <form>
                                                <Grid container spacing={1} display="flex" alignItems="center" justifyContent="center" xs={12} sm={12} md={12}>
                                                    <Grid xs={4} md={4} lg={1.7}>
                                                        <TextField
                                                            fullWidth
                                                            id="symbol"
                                                            name="symbol"
                                                            label="Symbol"
                                                            variant="outlined"
                                                            required
                                                            size='small'
                                                            value={initialValues.symbol}
                                                            onChange={handleChange}
                                                            sx={{
                                                                height: '40px',
                                                                '& .MuiInputBase-root': {
                                                                    height: '30px',

                                                                },
                                                                marginTop: '8px',
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4} md={4} lg={2.2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <DatePicker
                                                                id="start_date"
                                                                name="start_date"
                                                                label="Start Date"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={initialValues.start_date}
                                                                maxDate={today_value}
                                                                onChange={(value) => handleDateChange('start_date', value)}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',

                                                                    },
                                                                    '& .MuiInputAdornment-root': {
                                                                        '& .MuiSvgIcon-root': {
                                                                            fontSize: 10,
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={4} md={4} lg={2.2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <DatePicker
                                                                id="end_date"
                                                                name="end_date"
                                                                label="End Date"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={initialValues.end_date}
                                                                maxDate={today_value}
                                                                onChange={(value) => handleDateChange('end_date', value)}
                                                                disabled
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',

                                                                    },
                                                                    '& .MuiInputAdornment-root': {
                                                                        '& .MuiSvgIcon-root': {
                                                                            fontSize: 10,
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2} display="flex" justifyContent="center" xs={12} md={12}>
                                                    <Grid item xs={4} sm={3} md={3} lg={2}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <TimePicker
                                                                id="start_time"
                                                                name="start_time"
                                                                label="Start Time"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                defaultValue={initialValues.start_time}
                                                                ampm={false}
                                                                minTime={stEnTimeMin.subtract(1, 'minute')}
                                                                maxTime={stEnTimeMax.add(1, 'minute')}
                                                                onChange={(value) => handleTimeChange('start_time', value)}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',

                                                                    },
                                                                    '& .MuiInputAdornment-root': {
                                                                        '& .MuiSvgIcon-root': {
                                                                            fontSize: 10,
                                                                        },
                                                                    },
                                                                }}

                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={4} sm={3} md={3} lg={2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <TimePicker
                                                                id="end_time"
                                                                name="end_time"
                                                                label="End Time"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={dayjs(initialValues.end_time, "HH:mm")}
                                                                ampm={false}
                                                                minTime={stEnTimeMin.subtract(1, 'minute')}
                                                                maxTime={stEnTimeMax.add(1, 'minute')}
                                                                onChange={(value) => handleTimeChange('end_time', value)}
                                                                disabled={dayjs(initialValues.start_date).isSame(dayjs(), 'day')}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',

                                                                    },
                                                                    '& .MuiInputAdornment-root': {
                                                                        '& .MuiSvgIcon-root': {
                                                                            fontSize: 10,
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={4} sm={3} md={3} lg={2} sx={{ flexGrow: 1 }}>
                                                        <TextField
                                                            fullWidth
                                                            id="speed"
                                                            name="speed"
                                                            label="Speed"
                                                            variant="outlined"
                                                            size='small'
                                                            value={initialValues.speed}
                                                            onChange={handleChange}
                                                            type='number'
                                                            // inputProps={{ step: 2 }}
                                                            sx={{
                                                                height: '40px',
                                                                '& .MuiInputBase-root': {
                                                                    height: '30px',

                                                                },
                                                                '& .MuiInputAdornment-root': {
                                                                    '& .MuiSvgIcon-root': {
                                                                        fontSize: 10,
                                                                    },
                                                                },
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4} sm={3} md={3} lg={2}>
                                                        <Button variant="contained" fullWidth
                                                            sx={{
                                                                backgroundColor: colors.blueAccent[500],
                                                                // height: '25px',
                                                            }}
                                                            type="button" onClick={handleSubmitForm}
                                                        >
                                                            Search
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                            <Grid container display="flex" alignItems="center" justifyContent="center" sx={{ marginTop: -1.5 }}>
                                                <Grid item md={8}>
                                                    <Box display="flex" alignItems="center" justifyContent="center">
                                                        <Typography variant='h5' sx={{ fontWeight: 'bold', }}>{Time2Show}</Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Grid container display="flex" alignItems="center" justifyContent="center">
                                                <Grid md={8}>
                                                    <Card fullWidth>
                                                        <Grid >
                                                            <TableContainer component={Paper} sx={{ marginTop: '-15px', width: '100%', '@media (min-width: 600px)': { width: '100%' } }}>
                                                                <Table aria-label="simple table" className="change-tracking">
                                                                    <TableHead>
                                                                        <TableRow sx={{ paddingTop: '5px', paddingBottom: '5px' }}>
                                                                            <StyledTableCell align="center">Vol BID</StyledTableCell>
                                                                            <StyledTableCell align="center">BID</StyledTableCell>
                                                                            <StyledTableCell align="center">ASK</StyledTableCell>
                                                                            <StyledTableCell align="center">Vol ASK</StyledTableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {rows.map((row, index) => {
                                                                            if (index < 10) {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell id={`cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px', width: '30%' }}>{row.vol_bid}</TableCell>
                                                                                        <TableCell id={`cell-${index}-bid`} align="center" style={{ padding: '0.1px', width: '20%' }}>{row.bid}</TableCell>
                                                                                        <TableCell id={`cell-${index}-ask`} align="center" style={{ padding: '0.1px', width: '20%' }}>{row.ask}</TableCell>
                                                                                        <TableCell id={`cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px', width: '30%' }}>{row.vol_ask}</TableCell>
                                                                                    </TableRow>
                                                                                );
                                                                            } if (index === 11) {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <StyledTableCell id={`cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px' }}>{row.vol_bid}</StyledTableCell>
                                                                                        <StyledTableCell id={`cell-${index}-bid`} align="center" style={{ padding: '0.1px' }}>{row.bid}</StyledTableCell>
                                                                                        <StyledTableCell id={`cell-${index}-ask`} align="center" style={{ padding: '0.1px' }}>{row.ask}</StyledTableCell>
                                                                                        <StyledTableCell id={`cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px' }}>{row.vol_ask}</StyledTableCell>
                                                                                    </TableRow>
                                                                                )
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell id={`cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px' }}>{row.vol_bid}</TableCell>
                                                                                        <TableCell align="center" style={{ padding: '0.1px' }}>{row.bid}</TableCell>
                                                                                        <TableCell align="center" style={{ padding: '0.1px' }}>{row.ask}</TableCell>
                                                                                        <TableCell id={`cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px' }}>{row.vol_ask}</TableCell>
                                                                                    </TableRow>
                                                                                )
                                                                            }
                                                                        })}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Grid>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                            <Grid container display="flex" alignItems="center" justifyContent="center">
                                                <Grid item sm={8} md={8} display="flex" sx={{ height: '28px' }}>
                                                    <Slider
                                                        defaultValue={1}
                                                        aria-label="Time Slider"
                                                        valueLabelDisplay="auto"
                                                        min={1}
                                                        max={bidaskLength}
                                                        value={countRecordBidAsk}
                                                        onChange={handleSliderChange}
                                                        valueLabelFormat={(value) => `${Time2Show}`}
                                                        sx={{
                                                            '& .MuiSlider-thumb': {
                                                                width: 12,
                                                                height: 12,
                                                            },
                                                        }}
                                                    />
                                                    <IconButton onClick={handleClickPlayPause}>
                                                        {isPlaying ? <PauseIcon /> : <PlayArrowIcon />} {/* Render play or pause icon based on the value of isPlaying */}
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {/* item2 */}
                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                            <form>
                                                <Grid container spacing={1} display="flex" alignItems="center" justifyContent="center" xs={12} md={12}>
                                                    <Grid xs={4} md={4} lg={1.7}>
                                                        <TextField
                                                            fullWidth1
                                                            id="symbol"
                                                            name="symbol"
                                                            label="Symbol"
                                                            variant="outlined"
                                                            required
                                                            size='small'
                                                            value={initialValues1.symbol}
                                                            onChange={handleChange1}
                                                            sx={{
                                                                height: '40px',
                                                                '& .MuiInputBase-root': {
                                                                    height: '30px',
                                                                },
                                                                marginTop: '8px',
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4} md={4} lg={2.2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <DatePicker
                                                                id="start_date"
                                                                name="start_date"
                                                                label="Start Date"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={initialValues1.start_date}
                                                                maxDate={today_value1}
                                                                onChange={(value) => handleDateChange1('start_date', value)}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    },
                                                                    '& .MuiInputAdornment-root': {
                                                                        '& .MuiSvgIcon-root': {
                                                                            fontSize: 10,
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={4} md={4} lg={2.2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <DatePicker
                                                                id="end_date"
                                                                name="end_date"
                                                                label="End Date"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={initialValues1.end_date}
                                                                maxDate={today_value1}
                                                                onChange={(value) => handleDateChange1('end_date', value)}
                                                                disabled
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    },
                                                                    '& .MuiInputAdornment-root': {
                                                                        '& .MuiSvgIcon-root': {
                                                                            fontSize: 10,
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2} display="flex" justifyContent="center" xs={12} md={12}>
                                                    <Grid item xs={4} sm={3} md={3} lg={2}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <TimePicker
                                                                id="start_time"
                                                                name="start_time"
                                                                label="Start Time"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                defaultValue={initialValues1.start_time}
                                                                ampm={false}
                                                                minTime={stEnTimeMin.subtract(1, 'minute')}
                                                                maxTime={stEnTimeMax.add(1, 'minute')}
                                                                onChange={(value) => handleTimeChange1('start_time', value)}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    }
                                                                }}

                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={4} sm={3} md={3} lg={2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <TimePicker
                                                                id="end_time"
                                                                name="end_time"
                                                                label="End Time"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={dayjs(initialValues1.end_time, "HH:mm")}
                                                                ampm={false}
                                                                minTime={stEnTimeMin.subtract(1, 'minute')}
                                                                maxTime={stEnTimeMax.add(1, 'minute')}
                                                                onChange={(value) => handleTimeChange1('end_time', value)}
                                                                disabled={dayjs(initialValues1.start_date).isSame(dayjs(), 'day')}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    }
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={4} sm={3} md={3} lg={2} sx={{ flexGrow: 1 }}>
                                                        <TextField
                                                            fullWidth
                                                            id="speed"
                                                            name="speed"
                                                            label="Speed"
                                                            variant="outlined"
                                                            size='small'
                                                            value={initialValues1.speed}
                                                            onChange={handleChange1}
                                                            type='number'
                                                            // inputProps={{ step: 2 }}
                                                            sx={{
                                                                height: '40px',
                                                                '& .MuiInputBase-root': {
                                                                    height: '30px',
                                                                }
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4} sm={3} md={3} lg={2}>
                                                        <Button variant="contained" fullWidth
                                                            sx={{
                                                                backgroundColor: colors.blueAccent[500],
                                                                // height: '30px',
                                                                // width: '25px'
                                                            }}
                                                            type="button" onClick={handleSubmitForm1}
                                                        >
                                                            Search
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                            <Grid container display="flex" alignItems="center" justifyContent="center" sx={{ marginTop: -1.5 }}>
                                                <Grid item md={8}>
                                                    <Box display="flex" alignItems="center" justifyContent="center">
                                                        <Typography variant='h5' sx={{ fontWeight: 'bold', }}>{Time2Show1}</Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Grid container display="flex" alignItems="center" justifyContent="center">
                                                <Grid item md={8}>
                                                    <Card fullWidth1>
                                                        <Grid display="flex" alignItems="center" justifyContent="center">
                                                            <TableContainer component={Paper} sx={{ marginTop: '-15px', width: '100%', '@media (min-width: 600px)': { width: '100%' } }}>
                                                                <Table aria-label="simple table" className="change-tracking">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <StyledTableCell align="center">Vol BID</StyledTableCell>
                                                                            <StyledTableCell align="center">BID</StyledTableCell>
                                                                            <StyledTableCell align="center">ASK</StyledTableCell>
                                                                            <StyledTableCell align="center">Vol ASK</StyledTableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {rows1.map((row, index) => {
                                                                            if (index < 10) {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell id={`row1-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px', width: '30%' }}>{row.vol_bid}</TableCell>
                                                                                        <TableCell id={`row1-cell-${index}-bid`} align="center" style={{ padding: '0.1px', width: '20%' }}>{row.bid}</TableCell>
                                                                                        <TableCell id={`row1-cell-${index}-ask`} align="center" style={{ padding: '0.1px', width: '20%' }}>{row.ask}</TableCell>
                                                                                        <TableCell id={`row1-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px', width: '30%' }}>{row.vol_ask}</TableCell>
                                                                                    </TableRow>
                                                                                );
                                                                            } if (index === 11) {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <StyledTableCell id={`row1-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px' }}>{row.vol_bid}</StyledTableCell>
                                                                                        <StyledTableCell id={`row1-cell-${index}-bid`} align="center" style={{ padding: '0.1px' }}>{row.bid}</StyledTableCell>
                                                                                        <StyledTableCell id={`row1-cell-${index}-ask`} align="center" style={{ padding: '0.1px' }}>{row.ask}</StyledTableCell>
                                                                                        <StyledTableCell id={`row1-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px' }}>{row.vol_ask}</StyledTableCell>
                                                                                    </TableRow>
                                                                                )
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell id={`row1-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px' }}>{row.vol_bid}</TableCell>
                                                                                        <TableCell align="center" style={{ padding: '0.1px' }}>{row.bid}</TableCell>
                                                                                        <TableCell align="center" style={{ padding: '0.1px' }}>{row.ask}</TableCell>
                                                                                        <TableCell id={`row1-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px' }}>{row.vol_ask}</TableCell>
                                                                                    </TableRow>
                                                                                )
                                                                            }
                                                                        })}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Grid>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                            <Grid container display="flex" alignItems="center" justifyContent="center">
                                                <Grid item sm={8} md={8} display="flex" sx={{ height: '28px' }}>
                                                    <Slider
                                                        defaultValue={1}
                                                        aria-label="Time Slider"
                                                        valueLabelDisplay="auto"
                                                        min={1}
                                                        max={bidaskLength1}
                                                        value={countRecordBidAsk1}
                                                        onChange={handleSliderChange1}
                                                        valueLabelFormat={(value) => `${Time2Show1}`}
                                                        sx={{
                                                            '& .MuiSlider-thumb': {
                                                                width: 12,
                                                                height: 12,
                                                            },
                                                        }}
                                                    />
                                                    <IconButton onClick={handleClickPlayPause1}>
                                                        {isPlaying1 ? <PauseIcon /> : <PlayArrowIcon />} {/* Render play or pause icon based on the value of isPlaying */}
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {/* item3 */}
                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                            <form>
                                                <Grid container spacing={1} display="flex" alignItems="center" justifyContent="center" xs={12} md={12}>
                                                    <Grid xs={4} md={4} lg={1.7}>
                                                        <TextField
                                                            fullWidth2
                                                            id="symbol"
                                                            name="symbol"
                                                            label="Symbol"
                                                            variant="outlined"
                                                            required
                                                            size='small'
                                                            value={initialValues2.symbol}
                                                            onChange={handleChange2}
                                                            sx={{
                                                                height: '40px',
                                                                '& .MuiInputBase-root': {
                                                                    height: '30px',
                                                                },
                                                                marginTop: '8px',
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4} md={4} lg={2.2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <DatePicker
                                                                id="start_date"
                                                                name="start_date"
                                                                label="Start Date"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={initialValues2.start_date}
                                                                maxDate={today_value2}
                                                                onChange={(value) => handleDateChange2('start_date', value)}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    },
                                                                    '& .MuiInputAdornment-root': {
                                                                        '& .MuiSvgIcon-root': {
                                                                            fontSize: 10,
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={4} md={4} lg={2.2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <DatePicker
                                                                id="end_date"
                                                                name="end_date"
                                                                label="End Date"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={initialValues2.end_date}
                                                                maxDate={today_value2}
                                                                onChange={(value) => handleDateChange2('end_date', value)}
                                                                disabled
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    },
                                                                    '& .MuiInputAdornment-root': {
                                                                        '& .MuiSvgIcon-root': {
                                                                            fontSize: 10,
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2} display="flex" justifyContent="center" xs={12} md={12}>
                                                    <Grid item xs={4} sm={3} md={3} lg={2}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <TimePicker
                                                                id="start_time"
                                                                name="start_time"
                                                                label="Start Time"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                defaultValue={initialValues2.start_time}
                                                                ampm={false}
                                                                minTime={stEnTimeMin.subtract(1, 'minute')}
                                                                maxTime={stEnTimeMax.add(1, 'minute')}
                                                                onChange={(value) => handleTimeChange2('start_time', value)}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    }
                                                                }}

                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={4} sm={3} md={3} lg={2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <TimePicker
                                                                id="end_time"
                                                                name="end_time"
                                                                label="End Time"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={dayjs(initialValues2.end_time, "HH:mm")}
                                                                ampm={false}
                                                                minTime={stEnTimeMin.subtract(1, 'minute')}
                                                                maxTime={stEnTimeMax.add(1, 'minute')}
                                                                onChange={(value) => handleTimeChange2('end_time', value)}
                                                                disabled={dayjs(initialValues2.start_date).isSame(dayjs(), 'day')}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    }
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={4} sm={3} md={3} lg={2} sx={{ flexGrow: 1 }}>
                                                        <TextField
                                                            fullWidth2
                                                            id="speed"
                                                            name="speed"
                                                            label="Speed"
                                                            variant="outlined"
                                                            size='small'
                                                            value={initialValues2.speed}
                                                            onChange={handleChange2}
                                                            type='number'
                                                            // inputProps={{ step: 2 }}
                                                            sx={{
                                                                height: '40px',
                                                                '& .MuiInputBase-root': {
                                                                    height: '30px',
                                                                }
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4} sm={3} md={3} lg={2}>
                                                        <Button variant="contained" fullWidth
                                                            sx={{
                                                                backgroundColor: colors.blueAccent[500],
                                                                // height: '30px',
                                                            }}
                                                            type="button" onClick={handleSubmitForm2}
                                                        >
                                                            Search
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                            <Grid container display="flex" alignItems="center" justifyContent="center" sx={{ marginTop: -1.5 }}>
                                                <Grid item md={8}>
                                                    <Box display="flex" alignItems="center" justifyContent="center">
                                                        <Typography variant='h5' sx={{ fontWeight: 'bold', }}>{Time2Show2}</Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Grid container display="flex" alignItems="center" justifyContent="center">
                                                <Grid item md={8}>
                                                    <Card fullWidth2>
                                                        <Grid display="flex" alignItems="center" justifyContent="center">
                                                            <TableContainer component={Paper} sx={{ marginTop: '-15px', width: '100%', '@media (min-width: 600px)': { width: '100%' } }}>
                                                                <Table aria-label="simple table" className="change-tracking">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <StyledTableCell align="center">Vol BID</StyledTableCell>
                                                                            <StyledTableCell align="center">BID</StyledTableCell>
                                                                            <StyledTableCell align="center">ASK</StyledTableCell>
                                                                            <StyledTableCell align="center">Vol ASK</StyledTableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {rows2.map((row, index) => {
                                                                            if (index < 10) {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell id={`row2-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px', width: '30%' }}>{row.vol_bid}</TableCell>
                                                                                        <TableCell id={`row2-cell-${index}-bid`} align="center" style={{ padding: '0.1px', width: '20%' }}>{row.bid}</TableCell>
                                                                                        <TableCell id={`row2-cell-${index}-ask`} align="center" style={{ padding: '0.1px', width: '20%' }}>{row.ask}</TableCell>
                                                                                        <TableCell id={`row2-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px', width: '30%' }}>{row.vol_ask}</TableCell>
                                                                                    </TableRow>
                                                                                );
                                                                            } if (index === 11) {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <StyledTableCell id={`row2-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px' }}>{row.vol_bid}</StyledTableCell>
                                                                                        <StyledTableCell id={`row2-cell-${index}-bid`} align="center" style={{ padding: '0.1px' }}>{row.bid}</StyledTableCell>
                                                                                        <StyledTableCell id={`row2-cell-${index}-ask`} align="center" style={{ padding: '0.1px' }}>{row.ask}</StyledTableCell>
                                                                                        <StyledTableCell id={`row2-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px' }}>{row.vol_ask}</StyledTableCell>
                                                                                    </TableRow>
                                                                                )
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell id={`row2-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px' }}>{row.vol_bid}</TableCell>
                                                                                        <TableCell align="center" style={{ padding: '0.1px' }}>{row.bid}</TableCell>
                                                                                        <TableCell align="center" style={{ padding: '0.1px' }}>{row.ask}</TableCell>
                                                                                        <TableCell id={`row2-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px' }}>{row.vol_ask}</TableCell>
                                                                                    </TableRow>
                                                                                )
                                                                            }
                                                                        })}


                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Grid>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                            <Grid container display="flex" alignItems="center" justifyContent="center">
                                                <Grid item sm={8} md={8} display="flex" sx={{ height: '28px' }}>
                                                    <Slider
                                                        defaultValue={1}
                                                        aria-label="Time Slider"
                                                        valueLabelDisplay="auto"
                                                        min={1}
                                                        max={bidaskLength2}
                                                        value={countRecordBidAsk2}
                                                        onChange={handleSliderChange2}
                                                        valueLabelFormat={(value) => `${Time2Show2}`}
                                                        sx={{
                                                            '& .MuiSlider-thumb': {
                                                                width: 12,
                                                                height: 12,
                                                            },
                                                        }}
                                                    />
                                                    <IconButton onClick={handleClickPlayPause2}>
                                                        {isPlaying2 ? <PauseIcon /> : <PlayArrowIcon />} {/* Render play or pause icon based on the value of isPlaying */}
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {/* item4 */}
                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                            <form>
                                                <Grid container spacing={1} display="flex" alignItems="center" justifyContent="center" xs={12} md={12}>
                                                    <Grid xs={4} md={4} lg={1.7}>
                                                        <TextField
                                                            fullWidth3
                                                            id="symbol"
                                                            name="symbol"
                                                            label="Symbol"
                                                            variant="outlined"
                                                            required
                                                            size='small'
                                                            value={initialValues3.symbol}
                                                            onChange={handleChange3}
                                                            sx={{
                                                                height: '40px',
                                                                '& .MuiInputBase-root': {
                                                                    height: '30px',
                                                                },
                                                                marginTop: '8px',
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4} md={4} lg={2.2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <DatePicker
                                                                id="start_date"
                                                                name="start_date"
                                                                label="Start Date"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={initialValues3.start_date}
                                                                maxDate={today_value3}
                                                                onChange={(value) => handleDateChange3('start_date', value)}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    },
                                                                    '& .MuiInputAdornment-root': {
                                                                        '& .MuiSvgIcon-root': {
                                                                            fontSize: 10,
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={4} md={4} lg={2.2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <DatePicker
                                                                id="end_date"
                                                                name="end_date"
                                                                label="End Date"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={initialValues3.end_date}
                                                                maxDate={today_value3}
                                                                onChange={(value) => handleDateChange3('end_date', value)}
                                                                disabled
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    },
                                                                    '& .MuiInputAdornment-root': {
                                                                        '& .MuiSvgIcon-root': {
                                                                            fontSize: 10,
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2} display="flex" justifyContent="center" xs={12} md={12}>
                                                    <Grid item xs={4} sm={3} md={3} lg={2}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <TimePicker
                                                                id="start_time"
                                                                name="start_time"
                                                                label="Start Time"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                defaultValue={initialValues3.start_time}
                                                                ampm={false}
                                                                minTime={stEnTimeMin.subtract(1, 'minute')}
                                                                maxTime={stEnTimeMax.add(1, 'minute')}
                                                                onChange={(value) => handleTimeChange3('start_time', value)}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    }
                                                                }}

                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={4} sm={3} md={3} lg={2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <TimePicker
                                                                id="end_time"
                                                                name="end_time"
                                                                label="End Time"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={dayjs(initialValues3.end_time, "HH:mm")}
                                                                ampm={false}
                                                                minTime={stEnTimeMin.subtract(1, 'minute')}
                                                                maxTime={stEnTimeMax.add(1, 'minute')}
                                                                onChange={(value) => handleTimeChange3('end_time', value)}
                                                                disabled={dayjs(initialValues3.start_date).isSame(dayjs(), 'day')}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    }
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={4} sm={3} md={3} lg={2} sx={{ flexGrow: 1 }}>
                                                        <TextField
                                                            fullWidth3
                                                            id="speed"
                                                            name="speed"
                                                            label="Speed"
                                                            variant="outlined"
                                                            size='small'
                                                            value={initialValues3.speed}
                                                            onChange={handleChange}
                                                            type='number'
                                                            // inputProps={{ step: 2 }}
                                                            sx={{
                                                                height: '40px',
                                                                '& .MuiInputBase-root': {
                                                                    height: '30px',
                                                                }
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4} sm={3} md={3} lg={2}>
                                                        <Button variant="contained" fullWidth
                                                            sx={{
                                                                backgroundColor: colors.blueAccent[500],
                                                                // height: '30px',
                                                            }}
                                                            type="button" onClick={handleSubmitForm3}
                                                        >
                                                            Search
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                            <Grid container display="flex" alignItems="center" justifyContent="center" sx={{ marginTop: -1.5 }}>
                                                <Grid item md={8}>
                                                    <Box display="flex" alignItems="center" justifyContent="center">
                                                        <Typography variant='h5' sx={{ fontWeight: 'bold', }}>{Time2Show3}</Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Grid container display="flex" alignItems="center" justifyContent="center">
                                                <Grid item md={8}>
                                                    <Card fullWidth3>
                                                        <Grid display="flex" alignItems="center" justifyContent="center">
                                                            <TableContainer component={Paper} sx={{ marginTop: '-15px', width: '100%', '@media (min-width: 600px)': { width: '100%' } }}>
                                                                <Table aria-label="simple table" className="change-tracking">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <StyledTableCell align="center">Vol BID</StyledTableCell>
                                                                            <StyledTableCell align="center">BID</StyledTableCell>
                                                                            <StyledTableCell align="center">ASK</StyledTableCell>
                                                                            <StyledTableCell align="center">Vol ASK</StyledTableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {rows3.map((row, index) => {
                                                                            if (index < 10) {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell id={`row3-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px', width: '30%' }}>{row.vol_bid}</TableCell>
                                                                                        <TableCell id={`row3-cell-${index}-bid`} align="center" style={{ padding: '0.1px', width: '20%' }}>{row.bid}</TableCell>
                                                                                        <TableCell id={`row3-cell-${index}-ask`} align="center" style={{ padding: '0.1px', width: '20%' }}>{row.ask}</TableCell>
                                                                                        <TableCell id={`row3-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px', width: '30%' }}>{row.vol_ask}</TableCell>
                                                                                    </TableRow>
                                                                                );
                                                                            } if (index === 11) {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <StyledTableCell id={`row3-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px' }}>{row.vol_bid}</StyledTableCell>
                                                                                        <StyledTableCell id={`row3-cell-${index}-bid`} align="center" style={{ padding: '0.1px' }}>{row.bid}</StyledTableCell>
                                                                                        <StyledTableCell id={`row3-cell-${index}-ask`} align="center" style={{ padding: '0.1px' }}>{row.ask}</StyledTableCell>
                                                                                        <StyledTableCell id={`row3-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px' }}>{row.vol_ask}</StyledTableCell>
                                                                                    </TableRow>
                                                                                )
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell id={`row3-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px' }}>{row.vol_bid}</TableCell>
                                                                                        <TableCell align="center" style={{ padding: '0.1px' }}>{row.bid}</TableCell>
                                                                                        <TableCell align="center" style={{ padding: '0.1px' }}>{row.ask}</TableCell>
                                                                                        <TableCell id={`row3-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px' }}>{row.vol_ask}</TableCell>
                                                                                    </TableRow>
                                                                                )
                                                                            }
                                                                        })}


                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Grid>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                            <Grid container display="flex" alignItems="center" justifyContent="center">
                                                <Grid item sm={8} md={8} display="flex" sx={{ height: '28px' }}>
                                                    <Slider
                                                        defaultValue={1}
                                                        aria-label="Time Slider"
                                                        valueLabelDisplay="auto"
                                                        min={1}
                                                        max={bidaskLength3}
                                                        value={countRecordBidAsk3}
                                                        onChange={handleSliderChange3}
                                                        valueLabelFormat={(value) => `${Time2Show3}`}
                                                        sx={{
                                                            '& .MuiSlider-thumb': {
                                                                width: 12,
                                                                height: 12,
                                                            },
                                                        }}
                                                    />
                                                    <IconButton onClick={handleClickPlayPause3}>
                                                        {isPlaying3 ? <PauseIcon /> : <PlayArrowIcon />}
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </SwiperSlide>
                                <SwiperSlide>
                                    {/* หน้าที่ 2 */}
                                    <Grid container spacing={1} style={{ padding: '16px 0 0 16px' }}>
                                        {/* item5 */}
                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                            <form>
                                                <Grid container spacing={1} display="flex" alignItems="center" justifyContent="center" xs={12} md={12}>
                                                    <Grid xs={4} md={4} lg={1.7}>
                                                        <TextField
                                                            fullWidth4
                                                            id="symbol"
                                                            name="symbol"
                                                            label="Symbol"
                                                            variant="outlined"
                                                            required
                                                            size='small'
                                                            value={initialValues4.symbol}
                                                            onChange={handleChange4}
                                                            sx={{
                                                                height: '40px',
                                                                '& .MuiInputBase-root': {
                                                                    height: '30px',
                                                                },
                                                                marginTop: '8px',
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4} md={4} lg={2.2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <DatePicker
                                                                id="start_date"
                                                                name="start_date"
                                                                label="Start Date"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={initialValues4.start_date}
                                                                maxDate={today_value4}
                                                                onChange={(value) => handleDateChange4('start_date', value)}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    },
                                                                    '& .MuiInputAdornment-root': {
                                                                        '& .MuiSvgIcon-root': {
                                                                            fontSize: 10,
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={4} md={4} lg={2.2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <DatePicker
                                                                id="end_date"
                                                                name="end_date"
                                                                label="End Date"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={initialValues4.end_date}
                                                                maxDate={today_value4}
                                                                onChange={(value) => handleDateChange4('end_date', value)}
                                                                disabled
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    },
                                                                    '& .MuiInputAdornment-root': {
                                                                        '& .MuiSvgIcon-root': {
                                                                            fontSize: 10,
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2} display="flex" justifyContent="center" xs={12} md={12}>
                                                    <Grid item xs={3} md={3} lg={2}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <TimePicker
                                                                id="start_time"
                                                                name="start_time"
                                                                label="Start Time"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                defaultValue={initialValues4.start_time}
                                                                ampm={false}
                                                                minTime={stEnTimeMin.subtract(1, 'minute')}
                                                                maxTime={stEnTimeMax.add(1, 'minute')}
                                                                onChange={(value) => handleTimeChange4('start_time', value)}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    }
                                                                }}

                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={3} md={3} lg={2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <TimePicker
                                                                id="end_time"
                                                                name="end_time"
                                                                label="End Time"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={dayjs(initialValues4.end_time, "HH:mm")}
                                                                ampm={false}
                                                                minTime={stEnTimeMin.subtract(1, 'minute')}
                                                                maxTime={stEnTimeMax.add(1, 'minute')}
                                                                onChange={(value) => handleTimeChange4('end_time', value)}
                                                                disabled={dayjs(initialValues4.start_date).isSame(dayjs(), 'day')}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    }
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={3} md={3} lg={2} sx={{ flexGrow: 1 }}>
                                                        <TextField
                                                            fullWidth4
                                                            id="speed"
                                                            name="speed"
                                                            label="Speed"
                                                            variant="outlined"
                                                            size='small'
                                                            value={initialValues4.speed}
                                                            onChange={handleChange4}
                                                            type='number'
                                                            // inputProps={{ step: 2 }}
                                                            sx={{
                                                                height: '40px',
                                                                '& .MuiInputBase-root': {
                                                                    height: '30px',
                                                                }
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3} md={3} lg={2}>
                                                        <Button variant="contained" fullWidth4
                                                            sx={{
                                                                backgroundColor: colors.blueAccent[500],
                                                                height: '30px',
                                                            }}
                                                            type="button" onClick={handleSubmitForm4}
                                                        >
                                                            Search
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                            <Grid container display="flex" alignItems="center" justifyContent="center" sx={{ marginTop: -1.5 }}>
                                                <Grid item md={8}>
                                                    <Box display="flex" alignItems="center" justifyContent="center">
                                                        <Typography variant='h5' sx={{ fontWeight: 'bold', }}>{Time2Show4}</Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Grid container display="flex" alignItems="center" justifyContent="center">
                                                <Grid item md={8}>
                                                    <Card fullWidth4>
                                                        <Grid display="flex" alignItems="center" justifyContent="center">
                                                            <TableContainer component={Paper} sx={{ marginTop: '-15px', width: '100%', '@media (min-width: 600px)': { width: '100%' } }}>
                                                                <Table aria-label="simple table" className="change-tracking">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <StyledTableCell align="center">Vol BID</StyledTableCell>
                                                                            <StyledTableCell align="center">BID</StyledTableCell>
                                                                            <StyledTableCell align="center">ASK</StyledTableCell>
                                                                            <StyledTableCell align="center">Vol ASK</StyledTableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {rows4.map((row, index) => {
                                                                            if (index < 10) {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell id={`row4-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px', width: '30%' }}>{row.vol_bid}</TableCell>
                                                                                        <TableCell id={`row4-cell-${index}-bid`} align="center" style={{ padding: '0.1px', width: '20%' }}>{row.bid}</TableCell>
                                                                                        <TableCell id={`row4-cell-${index}-ask`} align="center" style={{ padding: '0.1px', width: '20%' }}>{row.ask}</TableCell>
                                                                                        <TableCell id={`row4-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px', width: '30%' }}>{row.vol_ask}</TableCell>
                                                                                    </TableRow>
                                                                                );
                                                                            } if (index === 11) {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <StyledTableCell id={`row4-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px' }}>{row.vol_bid}</StyledTableCell>
                                                                                        <StyledTableCell id={`row4-cell-${index}-bid`} align="center" style={{ padding: '0.1px' }}>{row.bid}</StyledTableCell>
                                                                                        <StyledTableCell id={`row4-cell-${index}-ask`} align="center" style={{ padding: '0.1px' }}>{row.ask}</StyledTableCell>
                                                                                        <StyledTableCell id={`row4-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px' }}>{row.vol_ask}</StyledTableCell>
                                                                                    </TableRow>
                                                                                )
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell id={`row4-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px' }}>{row.vol_bid}</TableCell>
                                                                                        <TableCell align="center" style={{ padding: '0.1px' }}>{row.bid}</TableCell>
                                                                                        <TableCell align="center" style={{ padding: '0.1px' }}>{row.ask}</TableCell>
                                                                                        <TableCell id={`row4-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px' }}>{row.vol_ask}</TableCell>
                                                                                    </TableRow>
                                                                                )
                                                                            }
                                                                        }
                                                                        )}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Grid>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                            <Grid container display="flex" alignItems="center" justifyContent="center">
                                                <Grid item xs={8} sm={8} md={8} display="flex" sx={{ height: '28px' }}>
                                                    <Slider
                                                        defaultValue={1}
                                                        aria-label="Time Slider"
                                                        valueLabelDisplay="auto"
                                                        min={1}
                                                        max={bidaskLength4}
                                                        value={countRecordBidAsk4}
                                                        onChange={handleSliderChange4}
                                                        valueLabelFormat={(value) => `${Time2Show4}`}
                                                        sx={{
                                                            '& .MuiSlider-thumb': {
                                                                width: 12,
                                                                height: 12,
                                                            },
                                                        }}
                                                    />
                                                    <IconButton onClick={handleClickPlayPause4} sx={{ marginTop: '7px' }}>
                                                        {isPlaying4 ? <PauseIcon /> : <PlayArrowIcon />}
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {/* item6 */}
                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                            <form>
                                                <Grid container spacing={1} display="flex" alignItems="center" justifyContent="center" xs={12} md={12}>
                                                    <Grid xs={4} md={4} lg={1.7}>
                                                        <TextField
                                                            fullWidth5
                                                            id="symbol"
                                                            name="symbol"
                                                            label="Symbol"
                                                            variant="outlined"
                                                            required
                                                            size='small'
                                                            value={initialValues5.symbol}
                                                            onChange={handleChange5}
                                                            sx={{
                                                                height: '40px',
                                                                '& .MuiInputBase-root': {
                                                                    height: '30px',
                                                                },
                                                                marginTop: '8px',
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4} md={4} lg={2.2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <DatePicker
                                                                id="start_date"
                                                                name="start_date"
                                                                label="Start Date"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={initialValues5.start_date}
                                                                maxDate={today_value5}
                                                                onChange={(value) => handleDateChange5('start_date', value)}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    },
                                                                    '& .MuiInputAdornment-root': {
                                                                        '& .MuiSvgIcon-root': {
                                                                            fontSize: 10,
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={4} md={4} lg={2.2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <DatePicker
                                                                id="end_date"
                                                                name="end_date"
                                                                label="End Date"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={initialValues5.end_date}
                                                                maxDate={today_value5}
                                                                onChange={(value) => handleDateChange5('end_date', value)}
                                                                disabled
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    },
                                                                    '& .MuiInputAdornment-root': {
                                                                        '& .MuiSvgIcon-root': {
                                                                            fontSize: 10,
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2} display="flex" justifyContent="center" xs={12} md={12}>
                                                    <Grid item xs={3} md={3} lg={2}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <TimePicker
                                                                id="start_time"
                                                                name="start_time"
                                                                label="Start Time"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                defaultValue={initialValues5.start_time}
                                                                ampm={false}
                                                                minTime={stEnTimeMin.subtract(1, 'minute')}
                                                                maxTime={stEnTimeMax.add(1, 'minute')}
                                                                onChange={(value) => handleTimeChange5('start_time', value)}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    }
                                                                }}

                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={3} md={3} lg={2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <TimePicker
                                                                id="end_time"
                                                                name="end_time"
                                                                label="End Time"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={dayjs(initialValues5.end_time, "HH:mm")}
                                                                ampm={false}
                                                                minTime={stEnTimeMin.subtract(1, 'minute')}
                                                                maxTime={stEnTimeMax.add(1, 'minute')}
                                                                onChange={(value) => handleTimeChange5('end_time', value)}
                                                                disabled={dayjs(initialValues5.start_date).isSame(dayjs(), 'day')}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    }
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={3} md={3} lg={2} sx={{ flexGrow: 1 }}>
                                                        <TextField
                                                            fullWidth5
                                                            id="speed"
                                                            name="speed"
                                                            label="Speed"
                                                            variant="outlined"
                                                            size='small'
                                                            value={initialValues5.speed}
                                                            onChange={handleChange5}
                                                            type='number'
                                                            // inputProps={{ step: 2 }}
                                                            sx={{
                                                                height: '40px',
                                                                '& .MuiInputBase-root': {
                                                                    height: '30px',
                                                                }
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3} md={3} lg={2}>
                                                        <Button variant="contained" fullWidth5
                                                            sx={{
                                                                backgroundColor: colors.blueAccent[500],
                                                                height: '30px',
                                                            }}
                                                            type="button" onClick={handleSubmitForm5}
                                                        >
                                                            Search
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                            <Grid container display="flex" alignItems="center" justifyContent="center" sx={{ marginTop: -1.5 }}>
                                                <Grid item md={8}>
                                                    <Box display="flex" alignItems="center" justifyContent="center">
                                                        <Typography variant='h5' sx={{ fontWeight: 'bold', }}>{Time2Show5}</Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Grid container display="flex" alignItems="center" justifyContent="center">
                                                <Grid item md={8}>
                                                    <Card fullWidth5>
                                                        <Grid display="flex" alignItems="center" justifyContent="center">
                                                            <TableContainer component={Paper} sx={{ marginTop: '-15px', width: '100%', '@media (min-width: 600px)': { width: '100%' } }}>
                                                                <Table aria-label="simple table" className="change-tracking">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <StyledTableCell align="center">Vol BID</StyledTableCell>
                                                                            <StyledTableCell align="center">BID</StyledTableCell>
                                                                            <StyledTableCell align="center">ASK</StyledTableCell>
                                                                            <StyledTableCell align="center">Vol ASK</StyledTableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {rows5.map((row, index) => {
                                                                            if (index < 10) {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell id={`row5-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px', width: '30%' }}>{row.vol_bid}</TableCell>
                                                                                        <TableCell id={`row5-cell-${index}-bid`} align="center" style={{ padding: '0.1px', width: '20%' }}>{row.bid}</TableCell>
                                                                                        <TableCell id={`row5-cell-${index}-ask`} align="center" style={{ padding: '0.1px', width: '20%' }}>{row.ask}</TableCell>
                                                                                        <TableCell id={`row5-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px', width: '30%' }}>{row.vol_ask}</TableCell>
                                                                                    </TableRow>
                                                                                );
                                                                            } if (index === 11) {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <StyledTableCell id={`row5-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px' }}>{row.vol_bid}</StyledTableCell>
                                                                                        <StyledTableCell id={`row5-cell-${index}-bid`} align="center" style={{ padding: '0.1px' }}>{row.bid}</StyledTableCell>
                                                                                        <StyledTableCell id={`row5-cell-${index}-ask`} align="center" style={{ padding: '0.1px' }}>{row.ask}</StyledTableCell>
                                                                                        <StyledTableCell id={`row5-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px' }}>{row.vol_ask}</StyledTableCell>
                                                                                    </TableRow>
                                                                                )
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell id={`row5-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px' }}>{row.vol_bid}</TableCell>
                                                                                        <TableCell align="center" style={{ padding: '0.1px' }}>{row.bid}</TableCell>
                                                                                        <TableCell align="center" style={{ padding: '0.1px' }}>{row.ask}</TableCell>
                                                                                        <TableCell id={`row5-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px' }}>{row.vol_ask}</TableCell>
                                                                                    </TableRow>
                                                                                )
                                                                            }
                                                                        })}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Grid>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                            <Grid container display="flex" alignItems="center" justifyContent="center">
                                                <Grid item xs={8} sm={8} md={8} display="flex" sx={{ height: '1px' }}>
                                                    <Slider
                                                        defaultValue={1}
                                                        aria-label="Time Slider"
                                                        valueLabelDisplay="auto"
                                                        min={1}
                                                        max={bidaskLength5}
                                                        value={countRecordBidAsk5}
                                                        onChange={handleSliderChange5}
                                                        valueLabelFormat={(value) => `${Time2Show5}`}
                                                        sx={{
                                                            '& .MuiSlider-thumb': {
                                                                width: 12,
                                                                height: 12,
                                                            },
                                                        }}
                                                    />
                                                    <IconButton onClick={handleClickPlayPause5} sx={{ marginTop: '7px' }}>
                                                        {isPlaying5 ? <PauseIcon /> : <PlayArrowIcon />}
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {/* item7 */}
                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                            <form>
                                                <Grid container spacing={1} display="flex" alignItems="center" justifyContent="center" xs={12} md={12}>
                                                    <Grid xs={4} md={4} lg={1.7}>
                                                        <TextField
                                                            fullWidth6
                                                            id="symbol"
                                                            name="symbol"
                                                            label="Symbol"
                                                            variant="outlined"
                                                            required
                                                            size='small'
                                                            value={initialValues6.symbol}
                                                            onChange={handleChange6}
                                                            sx={{
                                                                height: '40px',
                                                                '& .MuiInputBase-root': {
                                                                    height: '30px',
                                                                },
                                                                marginTop: '8px',
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4} md={4} lg={2.2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <DatePicker
                                                                id="start_date"
                                                                name="start_date"
                                                                label="Start Date"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={initialValues6.start_date}
                                                                maxDate={today_value6}
                                                                onChange={(value) => handleDateChange6('start_date', value)}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    },
                                                                    '& .MuiInputAdornment-root': {
                                                                        '& .MuiSvgIcon-root': {
                                                                            fontSize: 10,
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={4} md={4} lg={2.2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <DatePicker
                                                                id="end_date"
                                                                name="end_date"
                                                                label="End Date"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={initialValues6.end_date}
                                                                maxDate={today_value6}
                                                                onChange={(value) => handleDateChange6('end_date', value)}
                                                                disabled
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    },
                                                                    '& .MuiInputAdornment-root': {
                                                                        '& .MuiSvgIcon-root': {
                                                                            fontSize: 10,
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2} display="flex" justifyContent="center" xs={12} md={12}>
                                                    <Grid item xs={3} md={3} lg={2}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <TimePicker
                                                                id="start_time"
                                                                name="start_time"
                                                                label="Start Time"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                defaultValue={initialValues6.start_time}
                                                                ampm={false}
                                                                minTime={stEnTimeMin.subtract(1, 'minute')}
                                                                maxTime={stEnTimeMax.add(1, 'minute')}
                                                                onChange={(value) => handleTimeChange6('start_time', value)}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    }
                                                                }}

                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={3} md={3} lg={2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <TimePicker
                                                                id="end_time"
                                                                name="end_time"
                                                                label="End Time"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={dayjs(initialValues6.end_time, "HH:mm")}
                                                                ampm={false}
                                                                minTime={stEnTimeMin.subtract(1, 'minute')}
                                                                maxTime={stEnTimeMax.add(1, 'minute')}
                                                                onChange={(value) => handleTimeChange6('end_time', value)}
                                                                disabled={dayjs(initialValues6.start_date).isSame(dayjs(), 'day')}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    }
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={3} md={3} lg={2} sx={{ flexGrow: 1 }}>
                                                        <TextField
                                                            fullWidth6
                                                            id="speed"
                                                            name="speed"
                                                            label="Speed"
                                                            variant="outlined"
                                                            size='small'
                                                            value={initialValues6.speed}
                                                            onChange={handleChange6}
                                                            type='number'
                                                            // inputProps={{ step: 2 }}
                                                            sx={{
                                                                height: '40px',
                                                                '& .MuiInputBase-root': {
                                                                    height: '30px',
                                                                }
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3} md={3} lg={2}>
                                                        <Button variant="contained" fullWidth6
                                                            sx={{
                                                                backgroundColor: colors.blueAccent[500],
                                                                height: '30px',
                                                            }}
                                                            type="button" onClick={handleSubmitForm6}
                                                        >
                                                            Search
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                            <Grid container display="flex" alignItems="center" justifyContent="center" sx={{ marginTop: -1.5 }}>
                                                <Grid item md={8}>
                                                    <Box display="flex" alignItems="center" justifyContent="center">
                                                        <Typography variant='h5' sx={{ fontWeight: 'bold', }}>{Time2Show6}</Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Grid container display="flex" alignItems="center" justifyContent="center">
                                                <Grid item md={8}>
                                                    <Card fullWidth6>
                                                        <Grid display="flex" alignItems="center" justifyContent="center">
                                                            <TableContainer component={Paper} sx={{ marginTop: '-15px', width: '100%', '@media (min-width: 600px)': { width: '100%' } }}>
                                                                <Table aria-label="simple table" className="change-tracking">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <StyledTableCell align="center">Vol BID</StyledTableCell>
                                                                            <StyledTableCell align="center">BID</StyledTableCell>
                                                                            <StyledTableCell align="center">ASK</StyledTableCell>
                                                                            <StyledTableCell align="center">Vol ASK</StyledTableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {rows6.map((row, index) => {
                                                                            if (index < 10) {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell id={`row6-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px', width: '30%' }}>{row.vol_bid}</TableCell>
                                                                                        <TableCell id={`row6-cell-${index}-bid`} align="center" style={{ padding: '0.1px', width: '20%' }}>{row.bid}</TableCell>
                                                                                        <TableCell id={`row6-cell-${index}-ask`} align="center" style={{ padding: '0.1px', width: '20%' }}>{row.ask}</TableCell>
                                                                                        <TableCell id={`row6-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px', width: '30%' }}>{row.vol_ask}</TableCell>
                                                                                    </TableRow>
                                                                                );
                                                                            } if (index === 11) {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <StyledTableCell id={`row6-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px' }}>{row.vol_bid}</StyledTableCell>
                                                                                        <StyledTableCell id={`row6-cell-${index}-bid`} align="center" style={{ padding: '0.1px' }}>{row.bid}</StyledTableCell>
                                                                                        <StyledTableCell id={`row6-cell-${index}-ask`} align="center" style={{ padding: '0.1px' }}>{row.ask}</StyledTableCell>
                                                                                        <StyledTableCell id={`row6-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px' }}>{row.vol_ask}</StyledTableCell>
                                                                                    </TableRow>
                                                                                )
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell id={`row6-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px' }}>{row.vol_bid}</TableCell>
                                                                                        <TableCell align="center" style={{ padding: '0.1px' }}>{row.bid}</TableCell>
                                                                                        <TableCell align="center" style={{ padding: '0.1px' }}>{row.ask}</TableCell>
                                                                                        <TableCell id={`row6-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px' }}>{row.vol_ask}</TableCell>
                                                                                    </TableRow>
                                                                                )
                                                                            }
                                                                        })}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Grid>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                            <Grid container display="flex" alignItems="center" justifyContent="center">
                                                <Grid item xs={8} sm={8} md={8} display="flex" sx={{ height: '28px' }}>
                                                    <Slider
                                                        defaultValue={1}
                                                        aria-label="Time Slider"
                                                        valueLabelDisplay="auto"
                                                        min={1}
                                                        max={bidaskLength6}
                                                        value={countRecordBidAsk6}
                                                        onChange={handleSliderChange6}
                                                        valueLabelFormat={(value) => `${Time2Show6}`}
                                                        sx={{
                                                            '& .MuiSlider-thumb': {
                                                                width: 12,
                                                                height: 12,
                                                            },
                                                        }}
                                                    />
                                                    <IconButton onClick={handleClickPlayPause6} sx={{ marginTop: '7px' }}>
                                                        {isPlaying6 ? <PauseIcon /> : <PlayArrowIcon />}
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {/* item8 */}
                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                            <form>
                                                <Grid container spacing={1} display="flex" alignItems="center" justifyContent="center" xs={12} md={12}>
                                                    <Grid xs={4} md={4} lg={1.7}>
                                                        <TextField
                                                            fullWidth7
                                                            id="symbol"
                                                            name="symbol"
                                                            label="Symbol"
                                                            variant="outlined"
                                                            required
                                                            size='small'
                                                            value={initialValues7.symbol}
                                                            onChange={handleChange7}
                                                            sx={{
                                                                height: '40px',
                                                                '& .MuiInputBase-root': {
                                                                    height: '30px',
                                                                },
                                                                marginTop: '8px',
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4} md={4} lg={2.2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <DatePicker
                                                                id="start_date"
                                                                name="start_date"
                                                                label="Start Date"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={initialValues7.start_date}
                                                                maxDate={today_value7}
                                                                onChange={(value) => handleDateChange7('start_date', value)}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    },
                                                                    '& .MuiInputAdornment-root': {
                                                                        '& .MuiSvgIcon-root': {
                                                                            fontSize: 10,
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={4} md={4} lg={2.2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <DatePicker
                                                                id="end_date"
                                                                name="end_date"
                                                                label="End Date"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={initialValues7.end_date}
                                                                maxDate={today_value7}
                                                                onChange={(value) => handleDateChange7('end_date', value)}
                                                                disabled
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    },
                                                                    '& .MuiInputAdornment-root': {
                                                                        '& .MuiSvgIcon-root': {
                                                                            fontSize: 10,
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2} display="flex" justifyContent="center" xs={12} md={12}>
                                                    <Grid item xs={3} md={3} lg={2}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <TimePicker
                                                                id="start_time"
                                                                name="start_time"
                                                                label="Start Time"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                defaultValue={initialValues7.start_time}
                                                                ampm={false}
                                                                minTime={stEnTimeMin.subtract(1, 'minute')}
                                                                maxTime={stEnTimeMax.add(1, 'minute')}
                                                                onChange={(value) => handleTimeChange7('start_time', value)}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    }
                                                                }}

                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={3} md={3} lg={2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <TimePicker
                                                                id="end_time"
                                                                name="end_time"
                                                                label="End Time"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={dayjs(initialValues7.end_time, "HH:mm")}
                                                                ampm={false}
                                                                minTime={stEnTimeMin.subtract(1, 'minute')}
                                                                maxTime={stEnTimeMax.add(1, 'minute')}
                                                                onChange={(value) => handleTimeChange7('end_time', value)}
                                                                disabled={dayjs(initialValues7.start_date).isSame(dayjs(), 'day')}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    }
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={3} md={3} lg={2} sx={{ flexGrow: 1 }}>
                                                        <TextField
                                                            fullWidth7
                                                            id="speed"
                                                            name="speed"
                                                            label="Speed"
                                                            variant="outlined"
                                                            size='small'
                                                            value={initialValues7.speed}
                                                            onChange={handleChange7}
                                                            type='number'
                                                            // inputProps={{ step: 2 }}
                                                            sx={{
                                                                height: '40px',
                                                                '& .MuiInputBase-root': {
                                                                    height: '30px',
                                                                }
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3} md={3} lg={2}>
                                                        <Button variant="contained" fullWidth7
                                                            sx={{
                                                                backgroundColor: colors.blueAccent[500],
                                                                height: '30px',
                                                            }}
                                                            type="button" onClick={handleSubmitForm7}
                                                        >
                                                            Search
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                            <Grid container display="flex" alignItems="center" justifyContent="center" sx={{ marginTop: -1.5 }}>
                                                <Grid item md={8}>
                                                    <Box display="flex" alignItems="center" justifyContent="center">
                                                        <Typography variant='h5' sx={{ fontWeight: 'bold', }}>{Time2Show7}</Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Grid container display="flex" alignItems="center" justifyContent="center">
                                                <Grid item md={8}>
                                                    <Card fullWidth7>
                                                        <Grid display="flex" alignItems="center" justifyContent="center">
                                                            <TableContainer component={Paper} sx={{ marginTop: '-15px', width: '100%', '@media (min-width: 600px)': { width: '100%' } }}>
                                                                <Table aria-label="simple table" className="change-tracking">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <StyledTableCell align="center">Vol BID</StyledTableCell>
                                                                            <StyledTableCell align="center">BID</StyledTableCell>
                                                                            <StyledTableCell align="center">ASK</StyledTableCell>
                                                                            <StyledTableCell align="center">Vol ASK</StyledTableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {rows7.map((row, index) => {
                                                                            if (index < 10) {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell id={`row7-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px', width: '30%' }}>{row.vol_bid}</TableCell>
                                                                                        <TableCell id={`row7-cell-${index}-bid`} align="center" style={{ padding: '0.1px', width: '20%' }}>{row.bid}</TableCell>
                                                                                        <TableCell id={`row7-cell-${index}-ask`} align="center" style={{ padding: '0.1px', width: '20%' }}>{row.ask}</TableCell>
                                                                                        <TableCell id={`row7-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px', width: '30%' }}>{row.vol_ask}</TableCell>
                                                                                    </TableRow>
                                                                                );
                                                                            } if (index === 11) {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <StyledTableCell id={`row7-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px' }}>{row.vol_bid}</StyledTableCell>
                                                                                        <StyledTableCell id={`row7-cell-${index}-bid`} align="center" style={{ padding: '0.1px' }}>{row.bid}</StyledTableCell>
                                                                                        <StyledTableCell id={`row7-cell-${index}-ask`} align="center" style={{ padding: '0.1px' }}>{row.ask}</StyledTableCell>
                                                                                        <StyledTableCell id={`row7-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px' }}>{row.vol_ask}</StyledTableCell>
                                                                                    </TableRow>
                                                                                )
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell id={`row7-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px' }}>{row.vol_bid}</TableCell>
                                                                                        <TableCell align="center" style={{ padding: '0.1px' }}>{row.bid}</TableCell>
                                                                                        <TableCell align="center" style={{ padding: '0.1px' }}>{row.ask}</TableCell>
                                                                                        <TableCell id={`row7-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px' }}>{row.vol_ask}</TableCell>
                                                                                    </TableRow>
                                                                                )
                                                                            }
                                                                        })}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Grid>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                            <Grid container display="flex" alignItems="center" justifyContent="center">
                                                <Grid item xs={8} sm={8} md={8} display="flex" sx={{ height: '1px' }}>
                                                    <Slider
                                                        defaultValue={1}
                                                        aria-label="Time Slider"
                                                        valueLabelDisplay="auto"
                                                        min={1}
                                                        max={bidaskLength7}
                                                        value={countRecordBidAsk7}
                                                        onChange={handleSliderChange7}
                                                        valueLabelFormat={(value) => `${Time2Show7}`}
                                                        sx={{
                                                            '& .MuiSlider-thumb': {
                                                                width: 12,
                                                                height: 12,
                                                            },
                                                        }}
                                                    />
                                                    <IconButton onClick={handleClickPlayPause7} sx={{ marginTop: '7px' }}>
                                                        {isPlaying7 ? <PauseIcon /> : <PlayArrowIcon />}
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </SwiperSlide>
                            </Swiper>
                        </>
                    )
                    }
                    {(isMobile || isPTablet) && (
                        <>
                            <Swiper
                                style={{ width: isPTablet ? '90%' : '100%', maxWidth: '100vw' }}
                                modules={[Navigation, Pagination, Scrollbar, A11y]}
                                spaceBetween={30}
                                slidesPerView={1}
                                pagination={{
                                    clickable: true,
                                    dynamicBullets: true,
                                }}
                            >
                                {/* Slide 1 */}
                                <SwiperSlide>
                                    <Grid container spacing={2} style={{ padding: '16px 0 0 16px' }}>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <form>
                                                <Grid container spacing={1} display="flex" alignItems="center" justifyContent="center" xs={12} sm={12} md={12}>
                                                    <Grid xs={4} sm={4} md={10} lg={1.7}>
                                                        <TextField
                                                            fullWidth
                                                            id="symbol"
                                                            name="symbol"
                                                            label="Symbol"
                                                            variant="outlined"
                                                            required
                                                            size='small'
                                                            value={initialValues.symbol}
                                                            onChange={handleChange}
                                                            sx={{
                                                                height: '40px',
                                                                '& .MuiInputBase-root': {
                                                                    height: '30px',

                                                                },
                                                                marginTop: '8px',
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4} sm={4} md={3} lg={2.2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <DatePicker
                                                                id="start_date"
                                                                name="start_date"
                                                                label="Start Date"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={initialValues.start_date}
                                                                maxDate={today_value}
                                                                onChange={(value) => handleDateChange('start_date', value)}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',

                                                                    },
                                                                    '& .MuiInputAdornment-root': {
                                                                        '& .MuiSvgIcon-root': {
                                                                            fontSize: 10,
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={4} sm={4} md={3} lg={2.2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <DatePicker
                                                                id="end_date"
                                                                name="end_date"
                                                                label="End Date"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={initialValues.end_date}
                                                                maxDate={today_value}
                                                                onChange={(value) => handleDateChange('end_date', value)}
                                                                disabled
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',

                                                                    },
                                                                    '& .MuiInputAdornment-root': {
                                                                        '& .MuiSvgIcon-root': {
                                                                            fontSize: 10,
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2} display="flex" justifyContent="center" xs={12} md={12}>
                                                    <Grid item xs={3} md={3} lg={2}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <TimePicker
                                                                id="start_time"
                                                                name="start_time"
                                                                label="Start Time"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                defaultValue={initialValues.start_time}
                                                                ampm={false}
                                                                minTime={stEnTimeMin.subtract(1, 'minute')}
                                                                maxTime={stEnTimeMax.add(1, 'minute')}
                                                                onChange={(value) => handleTimeChange('start_time', value)}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',

                                                                    },
                                                                    '& .MuiInputAdornment-root': {
                                                                        '& .MuiSvgIcon-root': {
                                                                            fontSize: 10,
                                                                        },
                                                                    },
                                                                }}

                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={3} md={3} lg={2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <TimePicker
                                                                id="end_time"
                                                                name="end_time"
                                                                label="End Time"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={dayjs(initialValues.end_time, "HH:mm")}
                                                                ampm={false}
                                                                minTime={stEnTimeMin.subtract(1, 'minute')}
                                                                maxTime={stEnTimeMax.add(1, 'minute')}
                                                                onChange={(value) => handleTimeChange('end_time', value)}
                                                                disabled={dayjs(initialValues.start_date).isSame(dayjs(), 'day')}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',

                                                                    },
                                                                    '& .MuiInputAdornment-root': {
                                                                        '& .MuiSvgIcon-root': {
                                                                            fontSize: 10,
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={3} md={2} lg={0.9} sx={{ flexGrow: 1 }}>
                                                        <TextField
                                                            fullWidth
                                                            id="speed"
                                                            name="speed"
                                                            label="Speed"
                                                            variant="outlined"
                                                            size='small'
                                                            value={initialValues.speed}
                                                            onChange={handleChange}
                                                            type='number'
                                                            // inputProps={{ step: 2 }}
                                                            sx={{
                                                                height: '40px',
                                                                '& .MuiInputBase-root': {
                                                                    height: '30px',

                                                                },
                                                                '& .MuiInputAdornment-root': {
                                                                    '& .MuiSvgIcon-root': {
                                                                        fontSize: 10,
                                                                    },
                                                                },
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3} md={2} lg={1}>
                                                        <Button variant="contained" fullWidth
                                                            sx={{
                                                                backgroundColor: colors.blueAccent[500],
                                                                // height: '25px',
                                                            }}
                                                            type="button" onClick={handleSubmitForm}
                                                        >
                                                            Search
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                            <Grid container display="flex" alignItems="center" justifyContent="center" sx={{ marginTop: -1.5 }}>
                                                <Grid item md={8}>
                                                    <Box display="flex" alignItems="center" justifyContent="center">
                                                        <Typography variant='h5' sx={{ fontWeight: 'bold', }}>{Time2Show}</Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Grid container display="flex" alignItems="center" justifyContent="center">
                                                <Grid xs={8} sm={8} md={6}>
                                                    <Card fullWidth>
                                                        <Grid >
                                                            <TableContainer component={Paper} sx={{ marginTop: '-15px', width: '100%', '@media (min-width: 600px)': { width: '100%' } }}>
                                                                <Table aria-label="simple table" className="change-tracking">
                                                                    <TableHead>
                                                                        <TableRow sx={{ paddingTop: '5px', paddingBottom: '5px' }}>
                                                                            <StyledTableCell align="center">Vol BID</StyledTableCell>
                                                                            <StyledTableCell align="center">BID</StyledTableCell>
                                                                            <StyledTableCell align="center">ASK</StyledTableCell>
                                                                            <StyledTableCell align="center">Vol ASK</StyledTableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {rows.map((row, index) => {
                                                                            if (index < 10) {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell id={`cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px', width: '30%' }}>{row.vol_bid}</TableCell>
                                                                                        <TableCell id={`cell-${index}-bid`} align="center" style={{ padding: '0.1px', width: '20%' }}>{row.bid}</TableCell>
                                                                                        <TableCell id={`cell-${index}-ask`} align="center" style={{ padding: '0.1px', width: '20%' }}>{row.ask}</TableCell>
                                                                                        <TableCell id={`cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px', width: '30%' }}>{row.vol_ask}</TableCell>
                                                                                    </TableRow>
                                                                                );
                                                                            } if (index === 11) {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <StyledTableCell id={`cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px' }}>{row.vol_bid}</StyledTableCell>
                                                                                        <StyledTableCell id={`cell-${index}-bid`} align="center" style={{ padding: '0.1px' }}>{row.bid}</StyledTableCell>
                                                                                        <StyledTableCell id={`cell-${index}-ask`} align="center" style={{ padding: '0.1px' }}>{row.ask}</StyledTableCell>
                                                                                        <StyledTableCell id={`cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px' }}>{row.vol_ask}</StyledTableCell>
                                                                                    </TableRow>
                                                                                )
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell id={`cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px' }}>{row.vol_bid}</TableCell>
                                                                                        <TableCell align="center" style={{ padding: '0.1px' }}>{row.bid}</TableCell>
                                                                                        <TableCell align="center" style={{ padding: '0.1px' }}>{row.ask}</TableCell>
                                                                                        <TableCell id={`cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px' }}>{row.vol_ask}</TableCell>
                                                                                    </TableRow>
                                                                                )
                                                                            }
                                                                        })}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Grid>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                            <Grid container display="flex" alignItems="center" justifyContent="center">
                                                <Grid item xs={8} sm={8} md={4} display="flex" sx={{ height: '28px' }}>
                                                    <Slider
                                                        defaultValue={1}
                                                        aria-label="Time Slider"
                                                        valueLabelDisplay="auto"
                                                        min={1}
                                                        max={bidaskLength}
                                                        value={countRecordBidAsk}
                                                        onChange={handleSliderChange}
                                                        valueLabelFormat={(value) => `${Time2Show}`}
                                                        sx={{
                                                            '& .MuiSlider-thumb': {
                                                                width: 12,
                                                                height: 12,
                                                            },
                                                        }}
                                                    />
                                                    <IconButton onClick={handleClickPlayPause}>
                                                        {isPlaying ? <PauseIcon /> : <PlayArrowIcon />} {/* Render play or pause icon based on the value of isPlaying */}
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <form>
                                                <Grid container spacing={1} display="flex" alignItems="center" justifyContent="center" xs={12} md={12}>
                                                    <Grid xs={4} md={10} lg={1.7}>
                                                        <TextField
                                                            fullWidth1
                                                            id="symbol"
                                                            name="symbol"
                                                            label="Symbol"
                                                            variant="outlined"
                                                            required
                                                            size='small'
                                                            value={initialValues1.symbol}
                                                            onChange={handleChange1}
                                                            sx={{
                                                                height: '40px',
                                                                '& .MuiInputBase-root': {
                                                                    height: '30px',
                                                                },
                                                                marginTop: '8px',
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4} md={3} lg={2.2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <DatePicker
                                                                id="start_date"
                                                                name="start_date"
                                                                label="Start Date"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={initialValues1.start_date}
                                                                maxDate={today_value1}
                                                                onChange={(value) => handleDateChange1('start_date', value)}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    },
                                                                    '& .MuiInputAdornment-root': {
                                                                        '& .MuiSvgIcon-root': {
                                                                            fontSize: 10,
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={4} md={3} lg={2.2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <DatePicker
                                                                id="end_date"
                                                                name="end_date"
                                                                label="End Date"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={initialValues1.end_date}
                                                                maxDate={today_value1}
                                                                onChange={(value) => handleDateChange1('end_date', value)}
                                                                disabled
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    },
                                                                    '& .MuiInputAdornment-root': {
                                                                        '& .MuiSvgIcon-root': {
                                                                            fontSize: 10,
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2} display="flex" justifyContent="center" xs={12} md={12}>
                                                    <Grid item xs={3} md={3} lg={2}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <TimePicker
                                                                id="start_time"
                                                                name="start_time"
                                                                label="Start Time"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                defaultValue={initialValues1.start_time}
                                                                ampm={false}
                                                                minTime={stEnTimeMin.subtract(1, 'minute')}
                                                                maxTime={stEnTimeMax.add(1, 'minute')}
                                                                onChange={(value) => handleTimeChange1('start_time', value)}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    }
                                                                }}

                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={3} md={3} lg={2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <TimePicker
                                                                id="end_time"
                                                                name="end_time"
                                                                label="End Time"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={dayjs(initialValues1.end_time, "HH:mm")}
                                                                ampm={false}
                                                                minTime={stEnTimeMin.subtract(1, 'minute')}
                                                                maxTime={stEnTimeMax.add(1, 'minute')}
                                                                onChange={(value) => handleTimeChange1('end_time', value)}
                                                                disabled={dayjs(initialValues1.start_date).isSame(dayjs(), 'day')}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    }
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={3} md={2} lg={0.9} sx={{ flexGrow: 1 }}>
                                                        <TextField
                                                            fullWidth
                                                            id="speed"
                                                            name="speed"
                                                            label="Speed"
                                                            variant="outlined"
                                                            size='small'
                                                            value={initialValues1.speed}
                                                            onChange={handleChange1}
                                                            type='number'
                                                            // inputProps={{ step: 2 }}
                                                            sx={{
                                                                height: '40px',
                                                                '& .MuiInputBase-root': {
                                                                    height: '30px',
                                                                }
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3} md={2} lg={1}>
                                                        <Button variant="contained" fullWidth
                                                            sx={{
                                                                backgroundColor: colors.blueAccent[500],
                                                                // height: '30px',
                                                                // width: '25px'
                                                            }}
                                                            type="button" onClick={handleSubmitForm1}
                                                        >
                                                            Search
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                            <Grid container display="flex" alignItems="center" justifyContent="center" sx={{ marginTop: -1.5 }}>
                                                <Grid item md={8}>
                                                    <Box display="flex" alignItems="center" justifyContent="center">
                                                        <Typography variant='h5' sx={{ fontWeight: 'bold', }}>{Time2Show1}</Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Grid container display="flex" alignItems="center" justifyContent="center">
                                                <Grid item xs={8} sm={8} md={6}>
                                                    <Card fullWidth1>
                                                        <Grid display="flex" alignItems="center" justifyContent="center">
                                                            <TableContainer component={Paper} sx={{ marginTop: '-15px', width: '100%', '@media (min-width: 600px)': { width: '100%' } }}>
                                                                <Table aria-label="simple table" className="change-tracking">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <StyledTableCell align="center">Vol BID</StyledTableCell>
                                                                            <StyledTableCell align="center">BID</StyledTableCell>
                                                                            <StyledTableCell align="center">ASK</StyledTableCell>
                                                                            <StyledTableCell align="center">Vol ASK</StyledTableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {rows1.map((row, index) => {
                                                                            if (index < 10) {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell id={`row1-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px', width: '30%' }}>{row.vol_bid}</TableCell>
                                                                                        <TableCell id={`row1-cell-${index}-bid`} align="center" style={{ padding: '0.1px', width: '20%' }}>{row.bid}</TableCell>
                                                                                        <TableCell id={`row1-cell-${index}-ask`} align="center" style={{ padding: '0.1px', width: '20%' }}>{row.ask}</TableCell>
                                                                                        <TableCell id={`row1-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px', width: '30%' }}>{row.vol_ask}</TableCell>
                                                                                    </TableRow>
                                                                                );
                                                                            } if (index === 11) {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <StyledTableCell id={`row1-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px' }}>{row.vol_bid}</StyledTableCell>
                                                                                        <StyledTableCell id={`row1-cell-${index}-bid`} align="center" style={{ padding: '0.1px' }}>{row.bid}</StyledTableCell>
                                                                                        <StyledTableCell id={`row1-cell-${index}-ask`} align="center" style={{ padding: '0.1px' }}>{row.ask}</StyledTableCell>
                                                                                        <StyledTableCell id={`row1-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px' }}>{row.vol_ask}</StyledTableCell>
                                                                                    </TableRow>
                                                                                )
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell id={`row1-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px' }}>{row.vol_bid}</TableCell>
                                                                                        <TableCell align="center" style={{ padding: '0.1px' }}>{row.bid}</TableCell>
                                                                                        <TableCell align="center" style={{ padding: '0.1px' }}>{row.ask}</TableCell>
                                                                                        <TableCell id={`row1-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px' }}>{row.vol_ask}</TableCell>
                                                                                    </TableRow>
                                                                                )
                                                                            }
                                                                        })}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Grid>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                            <Grid container display="flex" alignItems="center" justifyContent="center">
                                                <Grid item xs={8} sm={8} md={4} display="flex" sx={{ height: '28px' }}>
                                                    <Slider
                                                        defaultValue={1}
                                                        aria-label="Time Slider"
                                                        valueLabelDisplay="auto"
                                                        min={1}
                                                        max={bidaskLength1}
                                                        value={countRecordBidAsk1}
                                                        onChange={handleSliderChange1}
                                                        valueLabelFormat={(value) => `${Time2Show1}`}
                                                        sx={{
                                                            '& .MuiSlider-thumb': {
                                                                width: 12,
                                                                height: 12,
                                                            },
                                                        }}
                                                    />
                                                    <IconButton onClick={handleClickPlayPause1}>
                                                        {isPlaying1 ? <PauseIcon /> : <PlayArrowIcon />} {/* Render play or pause icon based on the value of isPlaying */}
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Grid container spacing={2} style={{ padding: '16px 0 0 16px' }}>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <form>
                                                <Grid container spacing={1} display="flex" alignItems="center" justifyContent="center" xs={12} md={12}>
                                                    <Grid xs={4} md={10} lg={1.7}>
                                                        <TextField
                                                            fullWidth2
                                                            id="symbol"
                                                            name="symbol"
                                                            label="Symbol"
                                                            variant="outlined"
                                                            required
                                                            size='small'
                                                            value={initialValues2.symbol}
                                                            onChange={handleChange2}
                                                            sx={{
                                                                height: '40px',
                                                                '& .MuiInputBase-root': {
                                                                    height: '30px',
                                                                },
                                                                marginTop: '8px',
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4} md={3} lg={2.2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <DatePicker
                                                                id="start_date"
                                                                name="start_date"
                                                                label="Start Date"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={initialValues2.start_date}
                                                                maxDate={today_value2}
                                                                onChange={(value) => handleDateChange2('start_date', value)}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    },
                                                                    '& .MuiInputAdornment-root': {
                                                                        '& .MuiSvgIcon-root': {
                                                                            fontSize: 10,
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={4} md={3} lg={2.2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <DatePicker
                                                                id="end_date"
                                                                name="end_date"
                                                                label="End Date"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={initialValues2.end_date}
                                                                maxDate={today_value2}
                                                                onChange={(value) => handleDateChange2('end_date', value)}
                                                                disabled
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    },
                                                                    '& .MuiInputAdornment-root': {
                                                                        '& .MuiSvgIcon-root': {
                                                                            fontSize: 10,
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2} display="flex" justifyContent="center" xs={12} md={12}>
                                                    <Grid item xs={3} md={3} lg={2}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <TimePicker
                                                                id="start_time"
                                                                name="start_time"
                                                                label="Start Time"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                defaultValue={initialValues2.start_time}
                                                                ampm={false}
                                                                minTime={stEnTimeMin.subtract(1, 'minute')}
                                                                maxTime={stEnTimeMax.add(1, 'minute')}
                                                                onChange={(value) => handleTimeChange2('start_time', value)}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    }
                                                                }}

                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={3} md={3} lg={2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <TimePicker
                                                                id="end_time"
                                                                name="end_time"
                                                                label="End Time"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={dayjs(initialValues2.end_time, "HH:mm")}
                                                                ampm={false}
                                                                minTime={stEnTimeMin.subtract(1, 'minute')}
                                                                maxTime={stEnTimeMax.add(1, 'minute')}
                                                                onChange={(value) => handleTimeChange2('end_time', value)}
                                                                disabled={dayjs(initialValues2.start_date).isSame(dayjs(), 'day')}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    }
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={3} md={2} lg={0.9} sx={{ flexGrow: 1 }}>
                                                        <TextField
                                                            fullWidth2
                                                            id="speed"
                                                            name="speed"
                                                            label="Speed"
                                                            variant="outlined"
                                                            size='small'
                                                            value={initialValues2.speed}
                                                            onChange={handleChange2}
                                                            type='number'
                                                            // inputProps={{ step: 2 }}
                                                            sx={{
                                                                height: '40px',
                                                                '& .MuiInputBase-root': {
                                                                    height: '30px',
                                                                }
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3} md={2} lg={1}>
                                                        <Button variant="contained" fullWidth
                                                            sx={{
                                                                backgroundColor: colors.blueAccent[500],
                                                                // height: '30px',
                                                            }}
                                                            type="button" onClick={handleSubmitForm2}
                                                        >
                                                            Search
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                            <Grid container display="flex" alignItems="center" justifyContent="center" sx={{ marginTop: -1.5 }}>
                                                <Grid item md={8}>
                                                    <Box display="flex" alignItems="center" justifyContent="center">
                                                        <Typography variant='h5' sx={{ fontWeight: 'bold', }}>{Time2Show2}</Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Grid container display="flex" alignItems="center" justifyContent="center">
                                                <Grid item xs={8} sm={8} md={6}>
                                                    <Card fullWidth2>
                                                        <Grid display="flex" alignItems="center" justifyContent="center">
                                                            <TableContainer component={Paper} sx={{ marginTop: '-15px', width: '100%', '@media (min-width: 600px)': { width: '100%' } }}>
                                                                <Table aria-label="simple table" className="change-tracking">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <StyledTableCell align="center">Vol BID</StyledTableCell>
                                                                            <StyledTableCell align="center">BID</StyledTableCell>
                                                                            <StyledTableCell align="center">ASK</StyledTableCell>
                                                                            <StyledTableCell align="center">Vol ASK</StyledTableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {rows2.map((row, index) => {
                                                                            if (index < 10) {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell id={`row2-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px', width: '30%' }}>{row.vol_bid}</TableCell>
                                                                                        <TableCell id={`row2-cell-${index}-bid`} align="center" style={{ padding: '0.1px', width: '20%' }}>{row.bid}</TableCell>
                                                                                        <TableCell id={`row2-cell-${index}-ask`} align="center" style={{ padding: '0.1px', width: '20%' }}>{row.ask}</TableCell>
                                                                                        <TableCell id={`row2-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px', width: '30%' }}>{row.vol_ask}</TableCell>
                                                                                    </TableRow>
                                                                                );
                                                                            } if (index === 11) {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <StyledTableCell id={`row2-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px' }}>{row.vol_bid}</StyledTableCell>
                                                                                        <StyledTableCell id={`row2-cell-${index}-bid`} align="center" style={{ padding: '0.1px' }}>{row.bid}</StyledTableCell>
                                                                                        <StyledTableCell id={`row2-cell-${index}-ask`} align="center" style={{ padding: '0.1px' }}>{row.ask}</StyledTableCell>
                                                                                        <StyledTableCell id={`row2-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px' }}>{row.vol_ask}</StyledTableCell>
                                                                                    </TableRow>
                                                                                )
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell id={`row2-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px' }}>{row.vol_bid}</TableCell>
                                                                                        <TableCell align="center" style={{ padding: '0.1px' }}>{row.bid}</TableCell>
                                                                                        <TableCell align="center" style={{ padding: '0.1px' }}>{row.ask}</TableCell>
                                                                                        <TableCell id={`row2-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px' }}>{row.vol_ask}</TableCell>
                                                                                    </TableRow>
                                                                                )
                                                                            }
                                                                        })}


                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Grid>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                            <Grid container display="flex" alignItems="center" justifyContent="center">
                                                <Grid item xs={8} sm={8} md={4} display="flex" sx={{ height: '28px' }}>
                                                    <Slider
                                                        defaultValue={1}
                                                        aria-label="Time Slider"
                                                        valueLabelDisplay="auto"
                                                        min={1}
                                                        max={bidaskLength2}
                                                        value={countRecordBidAsk2}
                                                        onChange={handleSliderChange2}
                                                        valueLabelFormat={(value) => `${Time2Show2}`}
                                                        sx={{
                                                            '& .MuiSlider-thumb': {
                                                                width: 12,
                                                                height: 12,
                                                            },
                                                        }}
                                                    />
                                                    <IconButton onClick={handleClickPlayPause2}>
                                                        {isPlaying2 ? <PauseIcon /> : <PlayArrowIcon />} {/* Render play or pause icon based on the value of isPlaying */}
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <form>
                                                <Grid container spacing={1} display="flex" alignItems="center" justifyContent="center" xs={12} md={12}>
                                                    <Grid xs={4} md={10} lg={1.7}>
                                                        <TextField
                                                            fullWidth3
                                                            id="symbol"
                                                            name="symbol"
                                                            label="Symbol"
                                                            variant="outlined"
                                                            required
                                                            size='small'
                                                            value={initialValues3.symbol}
                                                            onChange={handleChange3}
                                                            sx={{
                                                                height: '40px',
                                                                '& .MuiInputBase-root': {
                                                                    height: '30px',
                                                                },
                                                                marginTop: '8px',
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4} md={3} lg={2.2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <DatePicker
                                                                id="start_date"
                                                                name="start_date"
                                                                label="Start Date"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={initialValues3.start_date}
                                                                maxDate={today_value3}
                                                                onChange={(value) => handleDateChange3('start_date', value)}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    },
                                                                    '& .MuiInputAdornment-root': {
                                                                        '& .MuiSvgIcon-root': {
                                                                            fontSize: 10,
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={4} md={3} lg={2.2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <DatePicker
                                                                id="end_date"
                                                                name="end_date"
                                                                label="End Date"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={initialValues3.end_date}
                                                                maxDate={today_value3}
                                                                onChange={(value) => handleDateChange3('end_date', value)}
                                                                disabled
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    },
                                                                    '& .MuiInputAdornment-root': {
                                                                        '& .MuiSvgIcon-root': {
                                                                            fontSize: 10,
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2} display="flex" justifyContent="center" xs={12} md={12}>
                                                    <Grid item xs={3} md={3} lg={2}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <TimePicker
                                                                id="start_time"
                                                                name="start_time"
                                                                label="Start Time"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                defaultValue={initialValues3.start_time}
                                                                ampm={false}
                                                                minTime={stEnTimeMin.subtract(1, 'minute')}
                                                                maxTime={stEnTimeMax.add(1, 'minute')}
                                                                onChange={(value) => handleTimeChange3('start_time', value)}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    }
                                                                }}

                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={3} md={3} lg={2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <TimePicker
                                                                id="end_time"
                                                                name="end_time"
                                                                label="End Time"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={dayjs(initialValues3.end_time, "HH:mm")}
                                                                ampm={false}
                                                                minTime={stEnTimeMin.subtract(1, 'minute')}
                                                                maxTime={stEnTimeMax.add(1, 'minute')}
                                                                onChange={(value) => handleTimeChange3('end_time', value)}
                                                                disabled={dayjs(initialValues3.start_date).isSame(dayjs(), 'day')}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    }
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={3} md={2} lg={0.9} sx={{ flexGrow: 1 }}>
                                                        <TextField
                                                            fullWidth3
                                                            id="speed"
                                                            name="speed"
                                                            label="Speed"
                                                            variant="outlined"
                                                            size='small'
                                                            value={initialValues3.speed}
                                                            onChange={handleChange}
                                                            type='number'
                                                            // inputProps={{ step: 2 }}
                                                            sx={{
                                                                height: '40px',
                                                                '& .MuiInputBase-root': {
                                                                    height: '30px',
                                                                }
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3} md={2} lg={1}>
                                                        <Button variant="contained" fullWidth
                                                            sx={{
                                                                backgroundColor: colors.blueAccent[500],
                                                                // height: '30px',
                                                            }}
                                                            type="button" onClick={handleSubmitForm3}
                                                        >
                                                            Search
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                            <Grid container display="flex" alignItems="center" justifyContent="center" sx={{ marginTop: -1.5 }}>
                                                <Grid item md={8}>
                                                    <Box display="flex" alignItems="center" justifyContent="center">
                                                        <Typography variant='h5' sx={{ fontWeight: 'bold', }}>{Time2Show3}</Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Grid container display="flex" alignItems="center" justifyContent="center">
                                                <Grid item xs={8} sm={8} md={6}>
                                                    <Card fullWidth3>
                                                        <Grid display="flex" alignItems="center" justifyContent="center">
                                                            <TableContainer component={Paper} sx={{ marginTop: '-15px', width: '100%', '@media (min-width: 600px)': { width: '100%' } }}>
                                                                <Table aria-label="simple table" className="change-tracking">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <StyledTableCell align="center">Vol BID</StyledTableCell>
                                                                            <StyledTableCell align="center">BID</StyledTableCell>
                                                                            <StyledTableCell align="center">ASK</StyledTableCell>
                                                                            <StyledTableCell align="center">Vol ASK</StyledTableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {rows3.map((row, index) => {
                                                                            if (index < 10) {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell id={`row3-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px', width: '30%' }}>{row.vol_bid}</TableCell>
                                                                                        <TableCell id={`row3-cell-${index}-bid`} align="center" style={{ padding: '0.1px', width: '20%' }}>{row.bid}</TableCell>
                                                                                        <TableCell id={`row3-cell-${index}-ask`} align="center" style={{ padding: '0.1px', width: '20%' }}>{row.ask}</TableCell>
                                                                                        <TableCell id={`row3-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px', width: '30%' }}>{row.vol_ask}</TableCell>
                                                                                    </TableRow>
                                                                                );
                                                                            } if (index === 11) {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <StyledTableCell id={`row3-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px' }}>{row.vol_bid}</StyledTableCell>
                                                                                        <StyledTableCell id={`row3-cell-${index}-bid`} align="center" style={{ padding: '0.1px' }}>{row.bid}</StyledTableCell>
                                                                                        <StyledTableCell id={`row3-cell-${index}-ask`} align="center" style={{ padding: '0.1px' }}>{row.ask}</StyledTableCell>
                                                                                        <StyledTableCell id={`row3-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px' }}>{row.vol_ask}</StyledTableCell>
                                                                                    </TableRow>
                                                                                )
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell id={`row3-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px' }}>{row.vol_bid}</TableCell>
                                                                                        <TableCell align="center" style={{ padding: '0.1px' }}>{row.bid}</TableCell>
                                                                                        <TableCell align="center" style={{ padding: '0.1px' }}>{row.ask}</TableCell>
                                                                                        <TableCell id={`row3-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px' }}>{row.vol_ask}</TableCell>
                                                                                    </TableRow>
                                                                                )
                                                                            }
                                                                        })}


                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Grid>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                            <Grid container display="flex" alignItems="center" justifyContent="center">
                                                <Grid item xs={8} sm={8} md={4} display="flex" sx={{ height: '28px' }}>
                                                    <Slider
                                                        defaultValue={1}
                                                        aria-label="Time Slider"
                                                        valueLabelDisplay="auto"
                                                        min={1}
                                                        max={bidaskLength3}
                                                        value={countRecordBidAsk3}
                                                        onChange={handleSliderChange3}
                                                        valueLabelFormat={(value) => `${Time2Show3}`}
                                                        sx={{
                                                            '& .MuiSlider-thumb': {
                                                                width: 12,
                                                                height: 12,
                                                            },
                                                        }}
                                                    />
                                                    <IconButton onClick={handleClickPlayPause3}>
                                                        {isPlaying3 ? <PauseIcon /> : <PlayArrowIcon />}
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Grid container spacing={2} style={{ padding: '16px 0 0 16px' }}>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <form>
                                                <Grid container spacing={1} display="flex" alignItems="center" justifyContent="center" xs={12} md={12}>
                                                    <Grid xs={4} md={10} lg={1.7}>
                                                        <TextField
                                                            fullWidth4
                                                            id="symbol"
                                                            name="symbol"
                                                            label="Symbol"
                                                            variant="outlined"
                                                            required
                                                            size='small'
                                                            value={initialValues4.symbol}
                                                            onChange={handleChange4}
                                                            sx={{
                                                                height: '40px',
                                                                '& .MuiInputBase-root': {
                                                                    height: '30px',
                                                                },
                                                                marginTop: '8px',
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4} md={3} lg={2.2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <DatePicker
                                                                id="start_date"
                                                                name="start_date"
                                                                label="Start Date"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={initialValues4.start_date}
                                                                maxDate={today_value4}
                                                                onChange={(value) => handleDateChange4('start_date', value)}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    },
                                                                    '& .MuiInputAdornment-root': {
                                                                        '& .MuiSvgIcon-root': {
                                                                            fontSize: 10,
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={4} md={3} lg={2.2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <DatePicker
                                                                id="end_date"
                                                                name="end_date"
                                                                label="End Date"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={initialValues4.end_date}
                                                                maxDate={today_value4}
                                                                onChange={(value) => handleDateChange4('end_date', value)}
                                                                disabled
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    },
                                                                    '& .MuiInputAdornment-root': {
                                                                        '& .MuiSvgIcon-root': {
                                                                            fontSize: 10,
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2} display="flex" justifyContent="center" xs={12} md={12}>
                                                    <Grid item xs={3} md={3} lg={2}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <TimePicker
                                                                id="start_time"
                                                                name="start_time"
                                                                label="Start Time"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                defaultValue={initialValues4.start_time}
                                                                ampm={false}
                                                                minTime={stEnTimeMin.subtract(1, 'minute')}
                                                                maxTime={stEnTimeMax.add(1, 'minute')}
                                                                onChange={(value) => handleTimeChange4('start_time', value)}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    }
                                                                }}

                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={3} md={3} lg={2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <TimePicker
                                                                id="end_time"
                                                                name="end_time"
                                                                label="End Time"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={dayjs(initialValues4.end_time, "HH:mm")}
                                                                ampm={false}
                                                                minTime={stEnTimeMin.subtract(1, 'minute')}
                                                                maxTime={stEnTimeMax.add(1, 'minute')}
                                                                onChange={(value) => handleTimeChange4('end_time', value)}
                                                                disabled={dayjs(initialValues4.start_date).isSame(dayjs(), 'day')}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    }
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={3} md={2} lg={0.9} sx={{ flexGrow: 1 }}>
                                                        <TextField
                                                            fullWidth4
                                                            id="speed"
                                                            name="speed"
                                                            label="Speed"
                                                            variant="outlined"
                                                            size='small'
                                                            value={initialValues4.speed}
                                                            onChange={handleChange4}
                                                            type='number'
                                                            // inputProps={{ step: 2 }}
                                                            sx={{
                                                                height: '40px',
                                                                '& .MuiInputBase-root': {
                                                                    height: '30px',
                                                                }
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3} md={2} lg={1}>
                                                        <Button variant="contained" fullWidth4
                                                            sx={{
                                                                backgroundColor: colors.blueAccent[500],
                                                                height: '30px',
                                                            }}
                                                            type="button" onClick={handleSubmitForm4}
                                                        >
                                                            Search
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                            <Grid container display="flex" alignItems="center" justifyContent="center" sx={{ marginTop: -1.5 }}>
                                                <Grid item md={8}>
                                                    <Box display="flex" alignItems="center" justifyContent="center">
                                                        <Typography variant='h5' sx={{ fontWeight: 'bold', }}>{Time2Show4}</Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Grid container display="flex" alignItems="center" justifyContent="center">
                                                <Grid item xs={8} sm={8} md={6}>
                                                    <Card fullWidth4>
                                                        <Grid display="flex" alignItems="center" justifyContent="center">
                                                            <TableContainer component={Paper} sx={{ marginTop: '-15px', width: '100%', '@media (min-width: 600px)': { width: '100%' } }}>
                                                                <Table aria-label="simple table" className="change-tracking">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <StyledTableCell align="center">Vol BID</StyledTableCell>
                                                                            <StyledTableCell align="center">BID</StyledTableCell>
                                                                            <StyledTableCell align="center">ASK</StyledTableCell>
                                                                            <StyledTableCell align="center">Vol ASK</StyledTableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {rows4.map((row, index) => {
                                                                            if (index < 10) {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell id={`row4-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px', width: '30%' }}>{row.vol_bid}</TableCell>
                                                                                        <TableCell id={`row4-cell-${index}-bid`} align="center" style={{ padding: '0.1px', width: '20%' }}>{row.bid}</TableCell>
                                                                                        <TableCell id={`row4-cell-${index}-ask`} align="center" style={{ padding: '0.1px', width: '20%' }}>{row.ask}</TableCell>
                                                                                        <TableCell id={`row4-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px', width: '30%' }}>{row.vol_ask}</TableCell>
                                                                                    </TableRow>
                                                                                );
                                                                            } if (index === 11) {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <StyledTableCell id={`row4-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px' }}>{row.vol_bid}</StyledTableCell>
                                                                                        <StyledTableCell id={`row4-cell-${index}-bid`} align="center" style={{ padding: '0.1px' }}>{row.bid}</StyledTableCell>
                                                                                        <StyledTableCell id={`row4-cell-${index}-ask`} align="center" style={{ padding: '0.1px' }}>{row.ask}</StyledTableCell>
                                                                                        <StyledTableCell id={`row4-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px' }}>{row.vol_ask}</StyledTableCell>
                                                                                    </TableRow>
                                                                                )
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell id={`row4-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px' }}>{row.vol_bid}</TableCell>
                                                                                        <TableCell align="center" style={{ padding: '0.1px' }}>{row.bid}</TableCell>
                                                                                        <TableCell align="center" style={{ padding: '0.1px' }}>{row.ask}</TableCell>
                                                                                        <TableCell id={`row4-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px' }}>{row.vol_ask}</TableCell>
                                                                                    </TableRow>
                                                                                )
                                                                            }
                                                                        }
                                                                        )}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Grid>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                            <Grid container display="flex" alignItems="center" justifyContent="center">
                                                <Grid item xs={8} sm={8} md={4} display="flex" sx={{ height: '28px' }}>
                                                    <Slider
                                                        defaultValue={1}
                                                        aria-label="Time Slider"
                                                        valueLabelDisplay="auto"
                                                        min={1}
                                                        max={bidaskLength4}
                                                        value={countRecordBidAsk4}
                                                        onChange={handleSliderChange4}
                                                        valueLabelFormat={(value) => `${Time2Show4}`}
                                                        sx={{
                                                            '& .MuiSlider-thumb': {
                                                                width: 12,
                                                                height: 12,
                                                            },
                                                        }}
                                                    />
                                                    <IconButton onClick={handleClickPlayPause4} sx={{ marginTop: '7px' }}>
                                                        {isPlaying4 ? <PauseIcon /> : <PlayArrowIcon />}
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <form>
                                                <Grid container spacing={1} display="flex" alignItems="center" justifyContent="center" xs={12} md={12}>
                                                    <Grid xs={4} md={10} lg={1.7}>
                                                        <TextField
                                                            fullWidth5
                                                            id="symbol"
                                                            name="symbol"
                                                            label="Symbol"
                                                            variant="outlined"
                                                            required
                                                            size='small'
                                                            value={initialValues5.symbol}
                                                            onChange={handleChange5}
                                                            sx={{
                                                                height: '40px',
                                                                '& .MuiInputBase-root': {
                                                                    height: '30px',
                                                                },
                                                                marginTop: '8px',
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4} md={3} lg={2.2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <DatePicker
                                                                id="start_date"
                                                                name="start_date"
                                                                label="Start Date"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={initialValues5.start_date}
                                                                maxDate={today_value5}
                                                                onChange={(value) => handleDateChange5('start_date', value)}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    },
                                                                    '& .MuiInputAdornment-root': {
                                                                        '& .MuiSvgIcon-root': {
                                                                            fontSize: 10,
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={4} md={3} lg={2.2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <DatePicker
                                                                id="end_date"
                                                                name="end_date"
                                                                label="End Date"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={initialValues5.end_date}
                                                                maxDate={today_value5}
                                                                onChange={(value) => handleDateChange5('end_date', value)}
                                                                disabled
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    },
                                                                    '& .MuiInputAdornment-root': {
                                                                        '& .MuiSvgIcon-root': {
                                                                            fontSize: 10,
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2} display="flex" justifyContent="center" xs={12} md={12}>
                                                    <Grid item xs={3} md={3} lg={2}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <TimePicker
                                                                id="start_time"
                                                                name="start_time"
                                                                label="Start Time"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                defaultValue={initialValues5.start_time}
                                                                ampm={false}
                                                                minTime={stEnTimeMin.subtract(1, 'minute')}
                                                                maxTime={stEnTimeMax.add(1, 'minute')}
                                                                onChange={(value) => handleTimeChange5('start_time', value)}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    }
                                                                }}

                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={3} md={3} lg={2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <TimePicker
                                                                id="end_time"
                                                                name="end_time"
                                                                label="End Time"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={dayjs(initialValues5.end_time, "HH:mm")}
                                                                ampm={false}
                                                                minTime={stEnTimeMin.subtract(1, 'minute')}
                                                                maxTime={stEnTimeMax.add(1, 'minute')}
                                                                onChange={(value) => handleTimeChange5('end_time', value)}
                                                                disabled={dayjs(initialValues5.start_date).isSame(dayjs(), 'day')}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    }
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={3} md={2} lg={0.9} sx={{ flexGrow: 1 }}>
                                                        <TextField
                                                            fullWidth5
                                                            id="speed"
                                                            name="speed"
                                                            label="Speed"
                                                            variant="outlined"
                                                            size='small'
                                                            value={initialValues5.speed}
                                                            onChange={handleChange5}
                                                            type='number'
                                                            // inputProps={{ step: 2 }}
                                                            sx={{
                                                                height: '40px',
                                                                '& .MuiInputBase-root': {
                                                                    height: '30px',
                                                                }
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3} md={2} lg={1}>
                                                        <Button variant="contained" fullWidth5
                                                            sx={{
                                                                backgroundColor: colors.blueAccent[500],
                                                                height: '30px',
                                                            }}
                                                            type="button" onClick={handleSubmitForm5}
                                                        >
                                                            Search
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                            <Grid container display="flex" alignItems="center" justifyContent="center" sx={{ marginTop: -1.5 }}>
                                                <Grid item md={8}>
                                                    <Box display="flex" alignItems="center" justifyContent="center">
                                                        <Typography variant='h5' sx={{ fontWeight: 'bold', }}>{Time2Show5}</Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Grid container display="flex" alignItems="center" justifyContent="center">
                                                <Grid item xs={8} sm={8} md={6}>
                                                    <Card fullWidth5>
                                                        <Grid display="flex" alignItems="center" justifyContent="center">
                                                            <TableContainer component={Paper} sx={{ marginTop: '-15px', width: '100%', '@media (min-width: 600px)': { width: '100%' } }}>
                                                                <Table aria-label="simple table" className="change-tracking">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <StyledTableCell align="center">Vol BID</StyledTableCell>
                                                                            <StyledTableCell align="center">BID</StyledTableCell>
                                                                            <StyledTableCell align="center">ASK</StyledTableCell>
                                                                            <StyledTableCell align="center">Vol ASK</StyledTableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {rows5.map((row, index) => {
                                                                            if (index < 10) {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell id={`row5-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px', width: '30%' }}>{row.vol_bid}</TableCell>
                                                                                        <TableCell id={`row5-cell-${index}-bid`} align="center" style={{ padding: '0.1px', width: '20%' }}>{row.bid}</TableCell>
                                                                                        <TableCell id={`row5-cell-${index}-ask`} align="center" style={{ padding: '0.1px', width: '20%' }}>{row.ask}</TableCell>
                                                                                        <TableCell id={`row5-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px', width: '30%' }}>{row.vol_ask}</TableCell>
                                                                                    </TableRow>
                                                                                );
                                                                            } if (index === 11) {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <StyledTableCell id={`row5-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px' }}>{row.vol_bid}</StyledTableCell>
                                                                                        <StyledTableCell id={`row5-cell-${index}-bid`} align="center" style={{ padding: '0.1px' }}>{row.bid}</StyledTableCell>
                                                                                        <StyledTableCell id={`row5-cell-${index}-ask`} align="center" style={{ padding: '0.1px' }}>{row.ask}</StyledTableCell>
                                                                                        <StyledTableCell id={`row5-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px' }}>{row.vol_ask}</StyledTableCell>
                                                                                    </TableRow>
                                                                                )
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell id={`row5-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px' }}>{row.vol_bid}</TableCell>
                                                                                        <TableCell align="center" style={{ padding: '0.1px' }}>{row.bid}</TableCell>
                                                                                        <TableCell align="center" style={{ padding: '0.1px' }}>{row.ask}</TableCell>
                                                                                        <TableCell id={`row5-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px' }}>{row.vol_ask}</TableCell>
                                                                                    </TableRow>
                                                                                )
                                                                            }
                                                                        })}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Grid>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                            <Grid container display="flex" alignItems="center" justifyContent="center">
                                                <Grid item xs={8} sm={8} md={4} display="flex" sx={{ height: '1px' }}>
                                                    <Slider
                                                        defaultValue={1}
                                                        aria-label="Time Slider"
                                                        valueLabelDisplay="auto"
                                                        min={1}
                                                        max={bidaskLength5}
                                                        value={countRecordBidAsk5}
                                                        onChange={handleSliderChange5}
                                                        valueLabelFormat={(value) => `${Time2Show5}`}
                                                        sx={{
                                                            '& .MuiSlider-thumb': {
                                                                width: 12,
                                                                height: 12,
                                                            },
                                                        }}
                                                    />
                                                    <IconButton onClick={handleClickPlayPause5} sx={{ marginTop: '7px' }}>
                                                        {isPlaying5 ? <PauseIcon /> : <PlayArrowIcon />}
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Grid container spacing={2} style={{ padding: '16px 0 0 16px' }}>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <form>
                                                <Grid container spacing={1} display="flex" alignItems="center" justifyContent="center" xs={12} md={12}>
                                                    <Grid xs={4} md={10} lg={1.7}>
                                                        <TextField
                                                            fullWidth6
                                                            id="symbol"
                                                            name="symbol"
                                                            label="Symbol"
                                                            variant="outlined"
                                                            required
                                                            size='small'
                                                            value={initialValues6.symbol}
                                                            onChange={handleChange6}
                                                            sx={{
                                                                height: '40px',
                                                                '& .MuiInputBase-root': {
                                                                    height: '30px',
                                                                },
                                                                marginTop: '8px',
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4} md={3} lg={2.2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <DatePicker
                                                                id="start_date"
                                                                name="start_date"
                                                                label="Start Date"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={initialValues6.start_date}
                                                                maxDate={today_value6}
                                                                onChange={(value) => handleDateChange6('start_date', value)}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    },
                                                                    '& .MuiInputAdornment-root': {
                                                                        '& .MuiSvgIcon-root': {
                                                                            fontSize: 10,
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={4} md={3} lg={2.2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <DatePicker
                                                                id="end_date"
                                                                name="end_date"
                                                                label="End Date"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={initialValues6.end_date}
                                                                maxDate={today_value6}
                                                                onChange={(value) => handleDateChange6('end_date', value)}
                                                                disabled
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    },
                                                                    '& .MuiInputAdornment-root': {
                                                                        '& .MuiSvgIcon-root': {
                                                                            fontSize: 10,
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2} display="flex" justifyContent="center" xs={12} md={12}>
                                                    <Grid item xs={3} md={3} lg={2}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <TimePicker
                                                                id="start_time"
                                                                name="start_time"
                                                                label="Start Time"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                defaultValue={initialValues6.start_time}
                                                                ampm={false}
                                                                minTime={stEnTimeMin.subtract(1, 'minute')}
                                                                maxTime={stEnTimeMax.add(1, 'minute')}
                                                                onChange={(value) => handleTimeChange6('start_time', value)}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    }
                                                                }}

                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={3} md={3} lg={2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <TimePicker
                                                                id="end_time"
                                                                name="end_time"
                                                                label="End Time"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={dayjs(initialValues6.end_time, "HH:mm")}
                                                                ampm={false}
                                                                minTime={stEnTimeMin.subtract(1, 'minute')}
                                                                maxTime={stEnTimeMax.add(1, 'minute')}
                                                                onChange={(value) => handleTimeChange6('end_time', value)}
                                                                disabled={dayjs(initialValues6.start_date).isSame(dayjs(), 'day')}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    }
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={3} md={2} lg={0.9} sx={{ flexGrow: 1 }}>
                                                        <TextField
                                                            fullWidth6
                                                            id="speed"
                                                            name="speed"
                                                            label="Speed"
                                                            variant="outlined"
                                                            size='small'
                                                            value={initialValues6.speed}
                                                            onChange={handleChange6}
                                                            type='number'
                                                            // inputProps={{ step: 2 }}
                                                            sx={{
                                                                height: '40px',
                                                                '& .MuiInputBase-root': {
                                                                    height: '30px',
                                                                }
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3} md={2} lg={1}>
                                                        <Button variant="contained" fullWidth6
                                                            sx={{
                                                                backgroundColor: colors.blueAccent[500],
                                                                height: '30px',
                                                            }}
                                                            type="button" onClick={handleSubmitForm6}
                                                        >
                                                            Search
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                            <Grid container display="flex" alignItems="center" justifyContent="center" sx={{ marginTop: -1.5 }}>
                                                <Grid item md={8}>
                                                    <Box display="flex" alignItems="center" justifyContent="center">
                                                        <Typography variant='h5' sx={{ fontWeight: 'bold', }}>{Time2Show6}</Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Grid container display="flex" alignItems="center" justifyContent="center">
                                                <Grid item xs={8} sm={8} md={6}>
                                                    <Card fullWidth6>
                                                        <Grid display="flex" alignItems="center" justifyContent="center">
                                                            <TableContainer component={Paper} sx={{ marginTop: '-15px', width: '100%', '@media (min-width: 600px)': { width: '100%' } }}>
                                                                <Table aria-label="simple table" className="change-tracking">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <StyledTableCell align="center">Vol BID</StyledTableCell>
                                                                            <StyledTableCell align="center">BID</StyledTableCell>
                                                                            <StyledTableCell align="center">ASK</StyledTableCell>
                                                                            <StyledTableCell align="center">Vol ASK</StyledTableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {rows6.map((row, index) => {
                                                                            if (index < 10) {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell id={`row6-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px', width: '30%' }}>{row.vol_bid}</TableCell>
                                                                                        <TableCell id={`row6-cell-${index}-bid`} align="center" style={{ padding: '0.1px', width: '20%' }}>{row.bid}</TableCell>
                                                                                        <TableCell id={`row6-cell-${index}-ask`} align="center" style={{ padding: '0.1px', width: '20%' }}>{row.ask}</TableCell>
                                                                                        <TableCell id={`row6-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px', width: '30%' }}>{row.vol_ask}</TableCell>
                                                                                    </TableRow>
                                                                                );
                                                                            } if (index === 11) {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <StyledTableCell id={`row6-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px' }}>{row.vol_bid}</StyledTableCell>
                                                                                        <StyledTableCell id={`row6-cell-${index}-bid`} align="center" style={{ padding: '0.1px' }}>{row.bid}</StyledTableCell>
                                                                                        <StyledTableCell id={`row6-cell-${index}-ask`} align="center" style={{ padding: '0.1px' }}>{row.ask}</StyledTableCell>
                                                                                        <StyledTableCell id={`row6-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px' }}>{row.vol_ask}</StyledTableCell>
                                                                                    </TableRow>
                                                                                )
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell id={`row6-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px' }}>{row.vol_bid}</TableCell>
                                                                                        <TableCell align="center" style={{ padding: '0.1px' }}>{row.bid}</TableCell>
                                                                                        <TableCell align="center" style={{ padding: '0.1px' }}>{row.ask}</TableCell>
                                                                                        <TableCell id={`row6-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px' }}>{row.vol_ask}</TableCell>
                                                                                    </TableRow>
                                                                                )
                                                                            }
                                                                        })}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Grid>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                            <Grid container display="flex" alignItems="center" justifyContent="center">
                                                <Grid item xs={8} sm={8} md={4} display="flex" sx={{ height: '28px' }}>
                                                    <Slider
                                                        defaultValue={1}
                                                        aria-label="Time Slider"
                                                        valueLabelDisplay="auto"
                                                        min={1}
                                                        max={bidaskLength6}
                                                        value={countRecordBidAsk6}
                                                        onChange={handleSliderChange6}
                                                        valueLabelFormat={(value) => `${Time2Show6}`}
                                                        sx={{
                                                            '& .MuiSlider-thumb': {
                                                                width: 12,
                                                                height: 12,
                                                            },
                                                        }}
                                                    />
                                                    <IconButton onClick={handleClickPlayPause6} sx={{ marginTop: '7px' }}>
                                                        {isPlaying6 ? <PauseIcon /> : <PlayArrowIcon />}
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <form>
                                                <Grid container spacing={1} display="flex" alignItems="center" justifyContent="center" xs={12} md={12}>
                                                    <Grid xs={4} md={10} lg={1.7}>
                                                        <TextField
                                                            fullWidth7
                                                            id="symbol"
                                                            name="symbol"
                                                            label="Symbol"
                                                            variant="outlined"
                                                            required
                                                            size='small'
                                                            value={initialValues7.symbol}
                                                            onChange={handleChange7}
                                                            sx={{
                                                                height: '40px',
                                                                '& .MuiInputBase-root': {
                                                                    height: '30px',
                                                                },
                                                                marginTop: '8px',
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4} md={3} lg={2.2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <DatePicker
                                                                id="start_date"
                                                                name="start_date"
                                                                label="Start Date"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={initialValues7.start_date}
                                                                maxDate={today_value7}
                                                                onChange={(value) => handleDateChange7('start_date', value)}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    },
                                                                    '& .MuiInputAdornment-root': {
                                                                        '& .MuiSvgIcon-root': {
                                                                            fontSize: 10,
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={4} md={3} lg={2.2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <DatePicker
                                                                id="end_date"
                                                                name="end_date"
                                                                label="End Date"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={initialValues7.end_date}
                                                                maxDate={today_value7}
                                                                onChange={(value) => handleDateChange7('end_date', value)}
                                                                disabled
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    },
                                                                    '& .MuiInputAdornment-root': {
                                                                        '& .MuiSvgIcon-root': {
                                                                            fontSize: 10,
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2} display="flex" justifyContent="center" xs={12} md={12}>
                                                    <Grid item xs={3} md={3} lg={2}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <TimePicker
                                                                id="start_time"
                                                                name="start_time"
                                                                label="Start Time"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                defaultValue={initialValues7.start_time}
                                                                ampm={false}
                                                                minTime={stEnTimeMin.subtract(1, 'minute')}
                                                                maxTime={stEnTimeMax.add(1, 'minute')}
                                                                onChange={(value) => handleTimeChange7('start_time', value)}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    }
                                                                }}

                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={3} md={3} lg={2} sx={{ flexGrow: 1 }}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <TimePicker
                                                                id="end_time"
                                                                name="end_time"
                                                                label="End Time"
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={dayjs(initialValues7.end_time, "HH:mm")}
                                                                ampm={false}
                                                                minTime={stEnTimeMin.subtract(1, 'minute')}
                                                                maxTime={stEnTimeMax.add(1, 'minute')}
                                                                onChange={(value) => handleTimeChange7('end_time', value)}
                                                                disabled={dayjs(initialValues7.start_date).isSame(dayjs(), 'day')}
                                                                sx={{
                                                                    height: '40px',
                                                                    '& .MuiInputBase-root': {
                                                                        height: '30px',
                                                                    }
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={3} md={2} lg={0.9} sx={{ flexGrow: 1 }}>
                                                        <TextField
                                                            fullWidth7
                                                            id="speed"
                                                            name="speed"
                                                            label="Speed"
                                                            variant="outlined"
                                                            size='small'
                                                            value={initialValues7.speed}
                                                            onChange={handleChange7}
                                                            type='number'
                                                            // inputProps={{ step: 2 }}
                                                            sx={{
                                                                height: '40px',
                                                                '& .MuiInputBase-root': {
                                                                    height: '30px',
                                                                }
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3} md={2} lg={1}>
                                                        <Button variant="contained" fullWidth7
                                                            sx={{
                                                                backgroundColor: colors.blueAccent[500],
                                                                height: '30px',
                                                            }}
                                                            type="button" onClick={handleSubmitForm7}
                                                        >
                                                            Search
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                            <Grid container display="flex" alignItems="center" justifyContent="center" sx={{ marginTop: -1.5 }}>
                                                <Grid item md={8}>
                                                    <Box display="flex" alignItems="center" justifyContent="center">
                                                        <Typography variant='h5' sx={{ fontWeight: 'bold', }}>{Time2Show7}</Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Grid container display="flex" alignItems="center" justifyContent="center">
                                                <Grid item xs={8} sm={8} md={6}>
                                                    <Card fullWidth7>
                                                        <Grid display="flex" alignItems="center" justifyContent="center">
                                                            <TableContainer component={Paper} sx={{ marginTop: '-15px', width: '100%', '@media (min-width: 600px)': { width: '100%' } }}>
                                                                <Table aria-label="simple table" className="change-tracking">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <StyledTableCell align="center">Vol BID</StyledTableCell>
                                                                            <StyledTableCell align="center">BID</StyledTableCell>
                                                                            <StyledTableCell align="center">ASK</StyledTableCell>
                                                                            <StyledTableCell align="center">Vol ASK</StyledTableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {rows7.map((row, index) => {
                                                                            if (index < 10) {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell id={`row7-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px', width: '30%' }}>{row.vol_bid}</TableCell>
                                                                                        <TableCell id={`row7-cell-${index}-bid`} align="center" style={{ padding: '0.1px', width: '20%' }}>{row.bid}</TableCell>
                                                                                        <TableCell id={`row7-cell-${index}-ask`} align="center" style={{ padding: '0.1px', width: '20%' }}>{row.ask}</TableCell>
                                                                                        <TableCell id={`row7-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px', width: '30%' }}>{row.vol_ask}</TableCell>
                                                                                    </TableRow>
                                                                                );
                                                                            } if (index === 11) {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <StyledTableCell id={`row7-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px' }}>{row.vol_bid}</StyledTableCell>
                                                                                        <StyledTableCell id={`row7-cell-${index}-bid`} align="center" style={{ padding: '0.1px' }}>{row.bid}</StyledTableCell>
                                                                                        <StyledTableCell id={`row7-cell-${index}-ask`} align="center" style={{ padding: '0.1px' }}>{row.ask}</StyledTableCell>
                                                                                        <StyledTableCell id={`row7-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px' }}>{row.vol_ask}</StyledTableCell>
                                                                                    </TableRow>
                                                                                )
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell id={`row7-cell-${index}-vol_bid`} align="center" style={{ padding: '0.1px' }}>{row.vol_bid}</TableCell>
                                                                                        <TableCell align="center" style={{ padding: '0.1px' }}>{row.bid}</TableCell>
                                                                                        <TableCell align="center" style={{ padding: '0.1px' }}>{row.ask}</TableCell>
                                                                                        <TableCell id={`row7-cell-${index}-vol_ask`} align="center" style={{ padding: '0.1px' }}>{row.vol_ask}</TableCell>
                                                                                    </TableRow>
                                                                                )
                                                                            }
                                                                        })}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Grid>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                            <Grid container display="flex" alignItems="center" justifyContent="center">
                                                <Grid item xs={8} sm={8} md={4} display="flex" sx={{ height: '1px' }}>
                                                    <Slider
                                                        defaultValue={1}
                                                        aria-label="Time Slider"
                                                        valueLabelDisplay="auto"
                                                        min={1}
                                                        max={bidaskLength7}
                                                        value={countRecordBidAsk7}
                                                        onChange={handleSliderChange7}
                                                        valueLabelFormat={(value) => `${Time2Show7}`}
                                                        sx={{
                                                            '& .MuiSlider-thumb': {
                                                                width: 12,
                                                                height: 12,
                                                            },
                                                        }}
                                                    />
                                                    <IconButton onClick={handleClickPlayPause7} sx={{ marginTop: '7px' }}>
                                                        {isPlaying7 ? <PauseIcon /> : <PlayArrowIcon />}
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </SwiperSlide>
                            </Swiper>
                        </>
                    )}

                </Container>
            </Box>
        </Box >
    );
};

export default MultiBidaskComponent;
