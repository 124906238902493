import React, { useEffect, useRef, useState } from 'react';
import { createChart } from 'lightweight-charts';
import { useSelector } from "react-redux";
import './index.css';
import { useIsMobile, useIsPortraitTablet, useIsTablet } from '../../components/hooks';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Box, useMediaQuery, useTheme, IconButton } from '@mui/material'
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
// import axios from 'axios';

// const API_BASE_URL = "http://172.18.1.81:3101/flow";

const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const earthToneColor = '#D2B48C';
const getOppositeColor = () => '#0d0082';

const getTodayDateString = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const adjustToLocalTime = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const timezoneOffset = date.getTimezoneOffset() * 60;
  return timestamp - timezoneOffset;
};

const ChartSectionWithMultipleLines = ({ title, market, type }) => {
  const chartContainerRef = useRef(null);
  const chartTableContainerRef = useRef(null);
  const chartRef = useRef(null);
  const lineSeriesRef = useRef(new Map());
  const seriesDataRef = useRef(new Map());
  const originalColorsRef = useRef(new Map());

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isPTablet = useIsPortraitTablet();

  const [isCollapsed, setIsCollapsed] = useState(false);

  const [tableData, setTableData] = useState([]);
  const tableDataRef = useRef([]);
  const [previousRanks, setPreviousRanks] = useState({});
  const previousRanksRef = useRef(previousRanks);
  const [lastRankChange, setLastRankChange] = useState({});
  const lastRankChangeRef = useRef(lastRankChange);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const csrfToken = useSelector((state) => state.csrfToken);

  const highlightColorsRef = useRef({});

  // Decide whether to invert + negate values if "Down (-)" or type === "out"
  const shouldInvert = (type === "out");

  const fetchHistoricalData = async () => {
    
    try {
      const fetch_response = await fetch(`${window.config.base_api_url}/flow/today/${market}`, {
        headers: {
          'Content-Type': 'application/json',
          'xsrf-token': csrfToken
        },
      });
      const response = await fetch_response.json();
      console.log(response);

      const data = response.data[type]; // 'in' or 'out'
      const historicalData = {};

      const times = Object.keys(data.rank);
      times.sort();
      const latestTime = times[times.length - 1];
      const latestSymbols = Object.keys(data.rank[latestTime]);

      // Build historical data
      times.forEach((time) => {
        const timestamp = Math.floor(new Date(time).getTime() / 1000);

        Object.keys(data.rank[time]).forEach((symbol) => {
          if (!historicalData[symbol]) {
            historicalData[symbol] = [];
          }
          const rawValue = data.value[time][symbol];
          const finalValue = shouldInvert ? -1 * rawValue : rawValue;

          historicalData[symbol].push({
            time: adjustToLocalTime(timestamp),
            rank: data.rank[time][symbol],
            value: finalValue,
          });
        });
      });

      const newPreviousRanks = {};

      // Track rank changes
      let lastChangeTime = -Infinity;
      const rankChanges = []; // {symbol, time, oldRank, newRank}

      latestSymbols.forEach((symbol) => {
        const symbolData = historicalData[symbol];
        if (!symbolData || symbolData.length === 0) {
          newPreviousRanks[symbol] = null;
          return;
        }

        symbolData.sort((a, b) => a.time - b.time);

        const latestRank = symbolData[symbolData.length - 1].rank;
        newPreviousRanks[symbol] = latestRank;

        // Detect rank changes
        for (let i = 1; i < symbolData.length; i++) {
          if (symbolData[i].rank !== symbolData[i - 1].rank) {
            const changeTime = symbolData[i].time;
            rankChanges.push({
              symbol: symbol,
              time: changeTime,
              oldRank: symbolData[i - 1].rank,
              newRank: symbolData[i].rank
            });
            if (changeTime > lastChangeTime) {
              lastChangeTime = changeTime;
            }
          }
        }

        // Create or update series
        let series = lineSeriesRef.current.get(symbol);
        if (!series) {
          const color = getRandomColor();
          series = chartRef.current.addLineSeries({
            color: color,
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: false,
            title: symbol,
          });
          lineSeriesRef.current.set(symbol, series);
          originalColorsRef.current.set(symbol, color);
        }

        const seriesData = symbolData.map((dataPoint) => ({
          time: dataPoint.time,
          value: dataPoint.value,
        }));
        series.setData(seriesData);
        seriesDataRef.current.set(symbol, seriesData);
      });

      setPreviousRanks(newPreviousRanks);
      previousRanksRef.current = newPreviousRanks;

      // Remove obsolete symbols
      const allSymbols = Array.from(lineSeriesRef.current.keys());
      const symbolsToRemove = allSymbols.filter(sym => !latestSymbols.includes(sym));
      symbolsToRemove.forEach((symbol) => {
        const s = lineSeriesRef.current.get(symbol);
        if (s) {
          chartRef.current.removeSeries(s);
        }
        lineSeriesRef.current.delete(symbol);
        seriesDataRef.current.delete(symbol);
        originalColorsRef.current.delete(symbol);
      });

      if (selectedSymbol && !latestSymbols.includes(selectedSymbol)) {
        setSelectedSymbol(null);
      }

      // Determine highlight colors based on the lastChangeTime
      const lastChanges = rankChanges.filter((rc) => rc.time === lastChangeTime);
      const symbolColors = new Map();
      if (lastChangeTime !== -Infinity && lastChanges.length > 0) {
        lastChanges.forEach((change) => {
          if (change.newRank < change.oldRank) {
            symbolColors.set(change.symbol, '#ccffcc'); // Green
          } else if (change.newRank > change.oldRank) {
            symbolColors.set(change.symbol, '#ffcccc'); // Red
          } else {
            symbolColors.set(change.symbol, 'transparent');
          }
        });
      }

      // Update table data
      const updatedTableData = latestSymbols.map((symbol) => {
        const symbolData = historicalData[symbol];
        const latestData = symbolData[symbolData.length - 1];
        const color = symbolColors.get(symbol) || 'transparent';

        return {
          rank: latestData.rank,
          symbol,
          value: latestData.value,
          color,
        };
      });

      setTableData(updatedTableData);
      tableDataRef.current = updatedTableData;
    } catch (error) {
      console.error(`Error fetching historical data for ${market}:`, error);
    }
  };

  const fetchCurrentData = async () => {
    try {
      if (Object.keys(previousRanksRef.current).length === 0) {
        return;
      }
      const fetch_response = await fetch(`${window.config.base_api_url}/flow/now/${market}`, {
        headers: {
          'Content-Type': 'application/json',
          'xsrf-token': csrfToken
        },
      });
      const response = await fetch_response.json();
      const data = response.data[type]; // "in" or "out"
      const timeKey = Object.keys(data.rank)[0];
      const timestamp = Math.floor(new Date(timeKey).getTime() / 1000);

      const latestSymbols = Object.keys(data.rank[timeKey]);

      // Compare ranks for changes
      const newPreviousRanks = {};
      latestSymbols.forEach((symbol) => {
        const currentRank = data.rank[timeKey][symbol];
        const oldRank = previousRanksRef.current[symbol];

        // If oldRank is undefined => NEW symbol => treat as green
        if (oldRank === undefined) {
          highlightColorsRef.current[symbol] = '#ccffcc'; // new symbol => green
        } else if (currentRank < oldRank) {
          highlightColorsRef.current[symbol] = '#ccffcc'; // green
        } else if (currentRank > oldRank) {
          highlightColorsRef.current[symbol] = '#ffcccc'; // red
        }
        // if currentRank === oldRank => do NOTHING
        // that preserves any previous color

        newPreviousRanks[symbol] = currentRank;
      });

      // Store table
      const updatedTableData = latestSymbols.map((symbol) => {
        const symbolData = seriesDataRef.current.get(symbol) || [];
        const latestData = symbolData[symbolData.length - 1];
        const color = highlightColorsRef.current[symbol] || 'transparent';

        return {
          rank: newPreviousRanks[symbol],
          symbol,
          value: latestData.value,
          color,
        };
      });

      setTableData(updatedTableData);
      tableDataRef.current = updatedTableData;

      // Update previousRanks
      setPreviousRanks(newPreviousRanks);
      previousRanksRef.current = newPreviousRanks;

      // Remove missing symbols
      const allSymbols = Array.from(lineSeriesRef.current.keys());
      const symbolsToRemove = allSymbols.filter(sym => !latestSymbols.includes(sym));
      symbolsToRemove.forEach((symbol) => {
        const series = lineSeriesRef.current.get(symbol);
        if (series) {
          chartRef.current.removeSeries(series);
        }
        lineSeriesRef.current.delete(symbol);
        seriesDataRef.current.delete(symbol);
        originalColorsRef.current.delete(symbol);

        // Also remove from highlightColorsRef if symbol is truly gone
        delete highlightColorsRef.current[symbol];
      });

      if (selectedSymbol && !latestSymbols.includes(selectedSymbol)) {
        setSelectedSymbol(null);
      }

      // Update the chart series with new data points
      latestSymbols.forEach((symbol) => {
        const updatePoint = {
          time: adjustToLocalTime(timestamp),
          value: (shouldInvert ? -1 : 1) * data.value[timeKey][symbol],
        };

        let series = lineSeriesRef.current.get(symbol);
        if (series) {
          const existingData = seriesDataRef.current.get(symbol) || [];
          const lastPoint = existingData[existingData.length - 1];

          if (lastPoint && lastPoint.time === updatePoint.time) {
            existingData[existingData.length - 1] = updatePoint;
          } else {
            existingData.push(updatePoint);
          }
          series.setData(existingData);
          seriesDataRef.current.set(symbol, existingData);
        } else {
          // new symbol => create a line series
          const color = getRandomColor();
          series = chartRef.current.addLineSeries({
            color,
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: false,
            title: symbol,
          });
          series.setData([updatePoint]);
          lineSeriesRef.current.set(symbol, series);
          originalColorsRef.current.set(symbol, color);
          seriesDataRef.current.set(symbol, [updatePoint]);
        }
      });

      // Highlight changes
      const symbolColors = new Map();
      if (Object.keys(highlightColorsRef.current).length > 0) {
        latestSymbols.forEach((symbol) => {
          const color = highlightColorsRef.current[symbol];
          if (color) {
            symbolColors.set(symbol, color);
          }
        });
      }

      // Update table data
      const finalTableData = latestSymbols.map((symbol) => {
        const dataArr = seriesDataRef.current.get(symbol) || [];
        const latestData = dataArr[dataArr.length - 1];
        const color = symbolColors.get(symbol) || 'transparent';

        return {
          rank: newPreviousRanks[symbol],
          symbol,
          value: latestData.value,
          color,
        };
      });

      setTableData(finalTableData);
      tableDataRef.current = finalTableData;

    } catch (error) {
      console.error(`Error fetching current data for ${market}:`, error);
    }
  };

  const handleRowClick = (symbol) => {
    if (selectedSymbol === symbol) {
      setSelectedSymbol(null);
      lineSeriesRef.current.forEach((series, sym) => {
        const originalColor = originalColorsRef.current.get(sym);
        series.applyOptions({
          color: originalColor,
          lineWidth: 2,
        });
      });
    } else {
      setSelectedSymbol(symbol);
      const selectedSeries = lineSeriesRef.current.get(symbol);
      const data = seriesDataRef.current.get(symbol);

      if (selectedSeries && chartRef.current) {
        // Remove and re-add to bring on top
        chartRef.current.removeSeries(selectedSeries);
        const newSeries = chartRef.current.addLineSeries({
          color: selectedSeries.options().color,
          lineWidth: selectedSeries.options().lineWidth,
          lastValueVisible: true,
          priceLineVisible: false,
          title: symbol,
        });
        newSeries.setData(data);
        lineSeriesRef.current.set(symbol, newSeries);

        const originalColor = originalColorsRef.current.get(symbol);
        const oppositeColor = getOppositeColor();
        newSeries.applyOptions({
          color: oppositeColor,
          lineWidth: 4,
        });

        // Fade other series
        lineSeriesRef.current.forEach((s, sym) => {
          if (sym !== symbol) {
            s.applyOptions({
              color: earthToneColor,
              lineWidth: 2,
            });
          }
        });
      }
    }
  };

  useEffect(() => {
    chartRef.current = createChart(chartContainerRef.current, {
      layout: {
        background: { type: 'solid', color: 'white' }
      },
      timeScale: { timeVisible: true, rightOffset: 10 },
      height: 290,
      width: chartContainerRef.current ? chartContainerRef.current.offsetWidth : 600,
      crosshair: {
        horizontalLine: {
          visible: false,
        },
        verticalLine: {
          visible: true,
        },
      },
    });

    chartRef.current.priceScale('right').applyOptions({
      textColor: 'white', // Set price scale text color to white
    });

    // If we want to invert the scale for type === "out"
    if (shouldInvert) {
      chartRef.current.applyOptions({
        invertScale: true,
      });
    }

    chartRef.current.applyOptions({
      localization: {
        priceFormatter: (price) =>
          (price / 1_000_000).toLocaleString('en-US', { maximumFractionDigits: 2 }),
      },
      priceScale: {
        mode: 0,
      },
    });

    const initializeData = async () => {
      await fetchHistoricalData();
      await fetchCurrentData();
    };

    initializeData();

    const intervalID = setInterval(() => {
      fetchCurrentData();
    }, 10000);

    return () => {
      clearInterval(intervalID);
      if (chartRef.current) {
        chartRef.current.remove();
        chartRef.current = null;
      }
    };
  }, [market, type]);

  const toggleSeriesVisibility = (symbol) => {
    const series = lineSeriesRef.current.get(symbol);
    if (series) {
      const currentVisible = series.options().visible ?? true;
      series.applyOptions({ visible: !currentVisible });
    }
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed); // เปลี่ยนสถานะพับ/ขยาย
  };

  // Dynamic Chart Width

  useEffect(() => {
    if (!chartContainerRef.current || !chartRef.current) return;

    const updateChartSize = () => {
      if (chartRef.current) {
        chartRef.current.resize(
          chartContainerRef.current.offsetWidth,
          chartContainerRef.current.offsetHeight
        );
      }
    };

    // ปรับขนาดเมื่อ isCollapsed เปลี่ยน
    updateChartSize();

    // สร้าง ResizeObserver
    const resizeObserver = new ResizeObserver(() => {
      requestAnimationFrame(updateChartSize);
    });

    resizeObserver.observe(chartContainerRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [isCollapsed]);

  const onChartTouch = (event, allowTouchMove) => {
    if (chartTableContainerRef.current && chartTableContainerRef.current.contains(event.target)) {
      window.mySwiper.allowTouchMove = allowTouchMove;
    }
  };

  const renderTable = (startIndex, endIndex, onRowClick, selectedSym) => (
    <table className="rank-table" style={{ fontSize: '11.5px', 
    fontFamily: 'Arial, Helvetica, sans-serif',
    border: '1px solid #ddd',
    borderCollapse: 'collapse',
     }}>
      <thead>
        <tr>
          <th style={{ textAlign: 'center', padding: '3px', backgroundColor: '#fdf5e6' }}>Rank</th>
          <th style={{ textAlign: 'center', padding: '3px', backgroundColor: '#fdf5e6' }}>Symbol</th>
          <th style={{ textAlign: 'center', padding: '3px', backgroundColor: '#fdf5e6' }}>Value</th>
        </tr>
      </thead>
      <tbody>
        {tableData
          .sort((a, b) => a.rank - b.rank)
          .slice(startIndex, endIndex)
          .map((row, index) => (
            <tr
              key={index}
              style={{
                backgroundColor: row.color,
                cursor: 'pointer',
                fontWeight: row.symbol === selectedSym ? 'bold' : 'normal',
                transition: 'background-color 0.3s ease',
                borderBottom: '1px solid #ddd'
              }}
              onClick={() => onRowClick(row.symbol)}
            >
              <td style={{ padding: '3px', textAlign: 'center' }}>{row.rank}</td>
              <td style={{ padding: '3px', textAlign: 'center' }}>{row.symbol}</td>
              <td style={{ padding: '3px', textAlign: 'center' }}>{(row.value / 1_000_000).toLocaleString('en-US', { maximumFractionDigits: 2 })}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );

  return (
    <div className="chart-section" style={{ fontFamily: 'Arial, sans-serif' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <h3
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontFamily: 'Arial, sans-serif',
            flex: '1'
          }}
        >
          {title}
          {/* Open fullscreen in new tab */}
          <button
            onClick={() => {
              const url = `/fullscreenrealflow?market=${market}&type=${type}&title=${encodeURIComponent(title)}`;
              window.open(url, '_blank'); // Open in new tab
            }}
            className="expand-button"
          >
            🔍
          </button>
        </h3>
        {/* Icon พับเก็บ Table สำหรับ Mobile และ Tablet */}
        {
          (isMobile || isTablet) && (
            <IconButton
              variant="contained"
              onClick={toggleCollapse}
            // sx={{ marginBottom: '10px' }}
            >
              {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </IconButton>
          )
        }
        {/* จบ Icon พับเก็บ */}
      </Box>



      <div
        className="chart-table-container"
        ref={chartTableContainerRef}
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: isMobile ? '2vw' : '5px',
          fontFamily: 'Arial, sans-serif',
          justifyContent: 'center'
        }}
      >
        {/* Chart Container */}
        <div
          ref={chartContainerRef}
          className="chart-container"
          style={{ width: isCollapsed ? '100%' : '60%', position: 'relative', fontFamily: 'Arial, sans-serif' }}
          onTouchStart={(event) => onChartTouch(event, false)} //ป้องกันการเลื่อนเปลี่ยนหน้าเมื่อ focus ที่ chart
          onTouchEnd={(event) => onChartTouch(event, true)}
        >
        </div>
        {/* Tables */}
        {(!isMobile && !isTablet) && (
          <>
            {!isCollapsed && (
              <>
                <Box className="table-container" sx={{
                  width: '20%',
                  maxWidth: '150px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                  overflowY: 'auto',
                  maxHeight: '80vh',
                  fontFamily: 'Arial, sans-serif',
                }}>
                  {renderTable(0, 10, handleRowClick, selectedSymbol)}
                </Box>
                {/* <div
              className="table-container"
              style={{
                width: '20%',
                maxWidth: '150px',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                overflowY: 'auto',
                maxHeight: '80vh',
                fontFamily: 'Arial, sans-serif',
              }}
            >

            </div> */}
                {/* <div
              className="table-container"
              style={{
                width: '20%',
                maxWidth: '150px',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                overflowY: 'auto',
                maxHeight: '80vh',
                fontFamily: 'Arial, sans-serif',
              }}
            >
              {renderTable(10, 20, handleRowClick, selectedSymbol)}
            </div> */}

                <Box className="table-container" sx={{
                  width: '20%',
                  maxWidth: '150px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                  overflowY: 'auto',
                  maxHeight: '80vh',
                  fontFamily: 'Arial, sans-serif',
                }}>
                  {renderTable(10, 20, handleRowClick, selectedSymbol)}
                </Box>
              </>
            )}
          </>
        )}

        {(isMobile || isTablet) && (
          <>

            {!isCollapsed && (
              <Box className="table-container" sx={{
                maxWidth: '150px',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                maxHeight: !isTablet ? '30vh' : '250px',
                fontFamily: 'Arial, sans-serif',
                overflowY: 'auto',
                '::-webkit-scrollbar': {
                  width: '5px',
                },
                '::-webkit-scrollbar-thumb': {
                  backgroundColor: '#888',
                  borderRadius: '4px',
                },
                '::-webkit-scrollbar-thumb:hover': {
                  backgroundColor: '#555',
                },
              }}>
                {renderTable(0, 20, handleRowClick, selectedSymbol)}
              </Box>
            )}
          </>
        )}



      </div>
    </div>
  );
};

const Realflow = () => {

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isPTablet = useIsPortraitTablet();

  return (
    <div
      className="dashboard"
      style={{
        display: 'flex',
        justifyContent: 'center',
        padding: (isMobile || isTablet) ? '10px' : '20px',
        fontFamily: 'Arial, sans-serif',
      }}
    >
      <div style={{ maxWidth: '1800px', width: '100%' }}>
        <h1 style={{ textAlign: 'center', marginBottom: '20px', fontFamily: 'Arial, sans-serif' }}>
          Real Flow
        </h1>
        <div
          className="sections"
          style={{
            display: 'grid',
            gridTemplateColumns: (isMobile || isTablet) ? '1fr' : '1fr 1fr',
            gap: '20px',
            fontFamily: 'Arial, sans-serif',
          }}
        >
          {/* Desktop , laptop mode => 1200px */}
          {(!isMobile && !isTablet) && (
            <>
              {/* SET100 and NON-SET100 Money In (+) */}
              <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <ChartSectionWithMultipleLines title="SET100 Up (+)" market="SET100" type="in" />
                <ChartSectionWithMultipleLines title="NON-SET100 Up (+)" market="NON-SET100" type="in" />
              </div>

              {/* SET100 and NON-SET100 Money Out (-) */}
              <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <ChartSectionWithMultipleLines title="SET100 Down (-)" market="SET100" type="out" />
                <ChartSectionWithMultipleLines title="NON-SET100 Down (-)" market="NON-SET100" type="out" />
              </div>

              {/* MAI and WARRANT Money In (+) */}
              <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <ChartSectionWithMultipleLines title="MAI Up (+)" market="MAI" type="in" />
                <ChartSectionWithMultipleLines title="WARRANT Up (+)" market="WARRANT" type="in" />
              </div>

              {/* MAI and WARRANT Money Out (-) */}
              <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <ChartSectionWithMultipleLines title="MAI Down (-)" market="MAI" type="out" />
                <ChartSectionWithMultipleLines title="WARRANT Down (-)" market="WARRANT" type="out" />
              </div>
            </>
          )}

          {/* Lanscape Tablet Mode 
          900px -> 1200px 
          */}

          {(isTablet && !isPTablet) && (
            <Swiper
              style={{ width: '100%', maxWidth: '1200px' }}
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={20}
              slidesPerView={1}
              pagination={{
                clickable: true,
                dynamicBullets: true,
              }}
              onSwiper={(swiper) => {
                window.mySwiper = swiper;
              }}
            >
              {/* Slide 1 */}
              <SwiperSlide>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)', // 2 columns ใน Tablet
                    gap: '10px', // ระยะห่างระหว่าง Grid
                  }}
                >
                  <ChartSectionWithMultipleLines title="SET100 Up (+)" market="SET100" type="in" />
                  <ChartSectionWithMultipleLines title="NON-SET100 Up (+)" market="NON-SET100" type="in" />
                  <ChartSectionWithMultipleLines title="SET100 Down (-)" market="SET100" type="out" />
                  <ChartSectionWithMultipleLines title="NON-SET100 Down (-)" market="NON-SET100" type="out" />
                </div>
              </SwiperSlide>

              {/* Slide 2 */}
              <SwiperSlide>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)', // 2 columns ใน Tablet
                    gap: '10px',
                  }}
                >
                  <ChartSectionWithMultipleLines title="MAI Up (+)" market="MAI" type="in" />
                  <ChartSectionWithMultipleLines title="WARRANT Up (+)" market="WARRANT" type="in" />
                  <ChartSectionWithMultipleLines title="MAI Down (-)" market="MAI" type="out" />
                  <ChartSectionWithMultipleLines title="WARRANT Down (-)" market="WARRANT" type="out" />
                </div>
              </SwiperSlide>
            </Swiper>
          )}


          {/* Mobile Mode , Portrait Tablet Mode <= 900px */}
          {(isMobile || isPTablet) && (
            <>
              {/* หากเข้าสู่ Mobile mode */}
              <Swiper
                style={{ width: '100%', maxWidth: '600px' }}
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={50}
                slidesPerView={1}
                pagination={{
                  clickable: true, dynamicBullets: true,
                }}
                onSwiper={(swiper) => {
                  window.mySwiper = swiper;
                }}
              >
                <SwiperSlide>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <ChartSectionWithMultipleLines title="SET100 Up (+)" market="SET100" type="in" />
                    <ChartSectionWithMultipleLines title="SET100 Down (-)" market="SET100" type="out" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <ChartSectionWithMultipleLines title="NON-SET100 Up (+)" market="NON-SET100" type="in" />
                    <ChartSectionWithMultipleLines title="NON-SET100 Down (-)" market="NON-SET100" type="out" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <ChartSectionWithMultipleLines title="MAI Up (+)" market="MAI" type="in" />
                    <ChartSectionWithMultipleLines title="MAI Down (-)" market="MAI" type="out" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <ChartSectionWithMultipleLines title="WARRANT Up (+)" market="WARRANT" type="in" />
                    <ChartSectionWithMultipleLines title="WARRANT Down (-)" market="WARRANT" type="out" />
                  </div>
                </SwiperSlide>

              </Swiper>

              {/* <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <ChartSectionWithMultipleLines title="SET100 Up (+)" market="SET100" type="in" />
                <ChartSectionWithMultipleLines title="SET100 Down (-)" market="SET100" type="out" />
              </div> */}
              {/* <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
    <ChartSectionWithMultipleLines title="NON-SET100 Up (+)" market="NON-SET100" type="in" />
        <ChartSectionWithMultipleLines title="NON-SET100 Down (-)" market="NON-SET100" type="out" />
    </div> */}
            </>
          )
          }
        </div>
      </div>
    </div>
  );

};

export default Realflow;
