import React, { useEffect, useRef, useState } from 'react';
import { createChart } from 'lightweight-charts';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const earthToneColor = '#D2B48C';
const getOppositeColor = () => '#0d0082';

const adjustToLocalTime = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const timezoneOffset = date.getTimezoneOffset() * 60;
  return timestamp - timezoneOffset;
};

/**
 * Fullscreen page for Historical Flow
 * ------------------------------------
 * Displays a single big chart (no date picker).
 * The main Hisflow page navigates here (e.g. `/fullscreenhisflow?market=SET100&type=in&date=2024-03-12`)
 */
const FullscreenPageHisflow = () => {
  const location = useLocation();
  const csrfToken = useSelector((state) => state.csrfToken);

  // Extract query params: market, type, date, etc.
  // e.g. /fullscreenhisflow?market=SET100&type=in&date=2024-03-12
  const params = new URLSearchParams(location.search);
  const market = params.get('market');       // "SET100" | "MAI" | ...
  const type = params.get('type');           // "in" or "out"
  const dateString = params.get('date');     // e.g. "2024-03-12"
  const title = params.get('title') || 'Hist Fullscreen Chart';

  // Refs
  const chartContainerRef = useRef(null);
  const chartRef = useRef(null);
  const lineSeriesRef = useRef(new Map());
  const seriesDataRef = useRef(new Map());
  const originalColorsRef = useRef(new Map());

  // Local state
  const [tableData, setTableData] = useState([]);
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  

  // Inversion logic for "out" => negative values and inverted scale
  const shouldInvert = (type === 'out');

  /**
   * Fetch Historical Data for the fullscreen chart
   */
  const fetchHistoricalData = async () => {
    try {
      if (!market || !dateString) {
        console.warn('No market or date provided, skipping fetch.');
        return;
      }

      // e.g. /flow/hist/SET100&2024-03-12
      const url = `${window.config.base_api_url}/flow/hist/${market}&${dateString}`;
      const response = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          'xsrf-token': csrfToken,
        },
      });
      const jsonData = await response.json();

      if (!jsonData?.data?.[type]) {
        console.warn(`No data found for market=${market}, date=${dateString}, type=${type}`);
        return;
      }

      const data = jsonData.data[type];
      const historicalData = {};
      const times = Object.keys(data.rank).sort();

      if (times.length === 0) {
        console.warn('No rank times found in data.');
        return;
      }

      const latestTime = times[times.length - 1];
      const latestSymbols = Object.keys(data.rank[latestTime]);

      // Build chart data
      times.forEach((time) => {
        const timestamp = Math.floor(new Date(time).getTime() / 1000);

        Object.keys(data.rank[time]).forEach((symbol) => {
          if (!historicalData[symbol]) {
            historicalData[symbol] = [];
          }
          const rankValue = data.rank[time][symbol];
          const rawValue = data.value[time][symbol];
          const finalValue = shouldInvert ? -1 * rawValue : rawValue;

          historicalData[symbol].push({
            time: adjustToLocalTime(timestamp),
            rank: rankValue,
            value: finalValue,
          });
        });
      });

      // Update or create line series
      latestSymbols.forEach((symbol) => {
        const symData = historicalData[symbol];
        if (!symData || symData.length === 0) return;

        symData.sort((a, b) => a.time - b.time);

        let series = lineSeriesRef.current.get(symbol);
        if (!series) {
          const color = getRandomColor();
          series = chartRef.current.addLineSeries({
            color,
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: false,
            title: symbol,
          });
          lineSeriesRef.current.set(symbol, series);
          originalColorsRef.current.set(symbol, color);
        }

        const seriesData = symData.map((dp) => ({
          time: dp.time,
          value: dp.value,
        }));
        series.setData(seriesData);
        seriesDataRef.current.set(symbol, seriesData);
      });

      // Remove any symbols not in the latest set
      const allSymbols = Array.from(lineSeriesRef.current.keys());
      const toRemove = allSymbols.filter((s) => !latestSymbols.includes(s));
      toRemove.forEach((sym) => {
        const s = lineSeriesRef.current.get(sym);
        if (s) {
          chartRef.current.removeSeries(s);
        }
        lineSeriesRef.current.delete(sym);
        seriesDataRef.current.delete(sym);
        originalColorsRef.current.delete(sym);
      });

      // Prepare table data (simply the final "snapshot")
      const newTableData = latestSymbols.map((symbol) => {
        const arr = historicalData[symbol];
        const lastItem = arr[arr.length - 1];
        return {
          rank: lastItem.rank,
          symbol,
          value: lastItem.value,
          color: 'transparent',
        };
      });
      setTableData(newTableData);
    } catch (err) {
      console.error('Error fetching historical flow data in fullscreen:', err);
    }
  };

  /**
   * Handler to highlight or unhighlight a symbol
   */
  const handleRowClick = (symbol) => {
    if (selectedSymbol === symbol) {
      // Deselect
      setSelectedSymbol(null);
      lineSeriesRef.current.forEach((series, sym) => {
        const origColor = originalColorsRef.current.get(sym);
        series.applyOptions({
          color: origColor,
          lineWidth: 2,
        });
      });
    } else {
      // Highlight
      setSelectedSymbol(symbol);

      const selectedSeries = lineSeriesRef.current.get(symbol);
      const data = seriesDataRef.current.get(symbol);
      if (selectedSeries && chartRef.current) {
        chartRef.current.removeSeries(selectedSeries);

        const newSeries = chartRef.current.addLineSeries({
          color: selectedSeries.options().color,
          lineWidth: selectedSeries.options().lineWidth,
          lastValueVisible: true,
          priceLineVisible: false,
          title: symbol,
        });
        newSeries.setData(data);
        lineSeriesRef.current.set(symbol, newSeries);

        // Switch to "opposite" color
        newSeries.applyOptions({
          color: getOppositeColor(),
          lineWidth: 4,
        });

        // Fade others
        lineSeriesRef.current.forEach((s, otherSymbol) => {
          if (otherSymbol !== symbol) {
            s.applyOptions({
              color: earthToneColor,
              lineWidth: 2,
            });
          }
        });
      }
    }
  };

  // Chart initialization
  useEffect(() => {
    if (!chartContainerRef.current) return;

    // Create chart
    chartRef.current = createChart(chartContainerRef.current, {
      layout: {
        textColor: 'black',
        background: { type: 'solid', color: 'white' },
      },
      timeScale: { timeVisible: true, rightOffset: 10 },
      height: chartContainerRef.current.offsetHeight,
      width: chartContainerRef.current.offsetWidth,
      crosshair: {
        horizontalLine: { visible: false },
        verticalLine: { visible: true },
      },
      localization: {
        priceFormatter: (price) => (price / 1_000_000).toLocaleString('en-US', { maximumFractionDigits: 2 }),
      },
      priceScale: { mode: 0 },
    });

    // Invert if type === "out"
    if (shouldInvert) {
      chartRef.current.applyOptions({ invertScale: true });
    }

    // Fetch the historical data once
    fetchHistoricalData();

    return () => {
      if (chartRef.current) {
        chartRef.current.remove();
        chartRef.current = null;
      }
    };
  }, [market, dateString, type]);

  // Example "navigation" button handlers
  const handleShowAll = () => {
    const timeScale = chartRef.current?.timeScale();
    if (!timeScale) return;

    let earliestTime = Infinity;
    let latestTime = -Infinity;
    seriesDataRef.current.forEach((data) => {
      if (data.length > 0) {
        earliestTime = Math.min(earliestTime, data[0].time);
        latestTime = Math.max(latestTime, data[data.length - 1].time);
      }
    });
    if (earliestTime !== Infinity && latestTime !== -Infinity) {
      timeScale.setVisibleRange({ from: earliestTime, to: latestTime });
    }
  };

  // Render table
  const renderTable = () => {
    const sortedData = [...tableData].sort((a, b) => a.rank - b.rank);
    return (
      <table className="rank-table" style={{ fontSize: '13px', fontFamily: 'Arial, sans-serif' }}>
        <thead>
          <tr>
            <th style={{ textAlign: 'left' }}>Rank</th>
            <th style={{ textAlign: 'left' }}>Symbol</th>
            <th style={{ textAlign: 'left' }}>Value</th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((row, idx) => (
            <tr
              key={idx}
              style={{
                backgroundColor: row.color,
                cursor: 'pointer',
                fontWeight: row.symbol === selectedSymbol ? 'bold' : 'normal',
              }}
              onClick={() => handleRowClick(row.symbol)}
            >
              <td>{row.rank}</td>
              <td>{row.symbol}</td>
              <td>{(row.value / 1_000_000).toLocaleString('en-US', { maximumFractionDigits: 2 })}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px' }}>
      <h3>{title}</h3>
      <div style={{ marginBottom: '10px' }}>
        <button onClick={handleShowAll} className="nav-button">Show All</button>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
        <div
          ref={chartContainerRef}
          style={{ width: '80%', height: '80vh', position: 'relative' }}
        />
        <div
          style={{
            width: '20%',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            overflowY: 'auto',
            maxHeight: '80vh',
          }}
        >
          {renderTable()}
        </div>
      </div>
    </div>
  );
};

export default FullscreenPageHisflow;
