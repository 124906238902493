import { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { tokens } from "../../theme";
import { useTheme, Box, Container, Grid, Card, TextField, Button, Table, TableHead, TableRow, TableCell, TableBody, Typography, Paper, Tooltip } from '@mui/material';
import Header from "../../components/Header";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import CircularProgress from '@mui/material/CircularProgress';

import CardContent from '@mui/material/CardContent';

import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import StatBox from "../../components/StatBox";
import EmailIcon from "@mui/icons-material/Email";

import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';

import 'dayjs/locale/en-gb';
import 'dayjs/locale/es-us';
import { __fetching_data__ } from '../../components/api.js';

import dayjs from 'dayjs';

import { Formik } from 'formik'
import * as yup from "yup";


import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

import TableContainer from '@mui/material/TableContainer';
import Slider from '@mui/material/Slider';

import IconButton from '@mui/material/IconButton';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { useIsMobile, useIsTablet, useIsPortraitTablet } from '../../components/hooks';
import { Link, LinkOff } from "@mui/icons-material";
import SwapVertIcon from '@mui/icons-material/SwapVert';
import MobiledataOffIcon from '@mui/icons-material/MobiledataOff';

import SyncIcon from '@mui/icons-material/Sync';
import SyncDisabledIcon from '@mui/icons-material/SyncDisabled';

import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';




const base_api_url =
    process.env.NODE_ENV === "production"
        ? window.location.protocol === "https:"
            ? process.env.REACT_APP_API_HTTPS_URL
            : process.env.REACT_APP_API_HTTP_URL
        : "/application";

const DejavuTickmatch = () => {

    const isMobile = useIsMobile();
    const isPTablet = useIsPortraitTablet();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const csrfToken = useSelector((state) => state.csrfToken);

    const isSyncing = useRef(false);
    const [isSyncEnabled, setIsSyncEnabled] = useState(false);
    const syncScrollRef = useRef([]);

    const toggleSync = () => {
        setIsSyncEnabled((prev) => {
            isSyncing.current = !prev; // อัปเดตค่า isSyncing
            return !prev;
        });
    };

    const handleScroll = (e, sourceRef) => {
        if (isSyncing.current) {
            syncScrollRef.current.forEach((ref) => {
                if (ref && ref !== sourceRef) {
                    ref.scrollTop = e.target.scrollTop;
                }
            });
        }
    };

    useEffect(() => {
        if (!isSyncing && syncScrollRef.current) {
            // Clear scroll listeners for other refs when syncing is disabled
            syncScrollRef.current.forEach((ref) => {
                if (ref) {
                    ref.removeEventListener("scroll", handleScroll);
                }
            });
        }
    }, [isSyncing]);

    function createData(time, last, vol, type, acc_cum_vol) {
        return { time, last, vol, type, acc_cum_vol };
    }
    const [rows, setRows] = useState(
        [
            // createData('', '', '', ''),
            // createData('', '', '', ''),
        ]
    );

    const [rows2, setRows2] = useState(
        [
            // createData('', '', '', ''),
            // createData('', '', '', ''),
        ]
    );
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            fontSize: 14,
            backgroundColor: colors.blueAccent[500],//"#B6DBFB",//theme.palette.common.black,
            color: theme.palette.common.white,//"#1B7FD6",//theme.palette.common.white,
            fontWeight: 'bold',
            paddingTop: 8, // Set top padding to 0
            paddingBottom: 8, // Set bottom padding to 0
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            backgroundColor: colors.blueAccent[500],
            color: theme.palette.common.white,
            fontWeight: 'bold',
        },
    }));

    const now = dayjs();
    const today = now.format('YYYY-MM-DD');
    const today_value = dayjs(today);
    const [initialValues, setInitialValues] = useState({
        symbol1: "",
        symbol2: "",
        date1: today_value.subtract(1, 'day').format('YYYY-MM-DD'),
        date2: today_value.subtract(1, 'day').format('YYYY-MM-DD'),
    });
    const disableWeekends = (date) => {
        const day = date.day();
        return day === 0 || day === 6;
    };

    const handleChange = (e) => {
        const { name, value } = e.target
        // console.log(name, value)
        setInitialValues((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }

    const handleDateChange = (name, value) => {
        setInitialValues((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }

    const [sumBuy, setSumBuy] = useState([0, 0]);
    const [sumSell, setSumSell] = useState([0, 0]);

    useEffect(() => {



    }, []);

    const formatDate = (date) => {
        if (typeof date === 'object') {
            return `${dayjs(date).year()}-${String(dayjs(date).month() + 1).padStart(2, '0')}-${String(dayjs(date).date()).padStart(2, '0')}`;
        }
        return date;
    };

    const objectToQueryString = (obj) => {
        return Object.keys(obj)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]))
            .join('&');
    }

    const [isFetch, setIsFetch] = useState({
        'form1': false,
        'form2': false,
    });

    const endProcess = () => {
        setIsFetch({
            'form1': false,
            'form2': false,
        });
    }

    const handleSubmitForm1 = async () => {
        setRows([]);
        setIsFetch(prevState => ({
            ...prevState,
            'form1': true,
        }));
        const values = {
            symbol: initialValues.symbol1,
            start_date: formatDate(initialValues.date1),
            end_date: formatDate(initialValues.date1)
        };
        const response = await __fetching_data__({ url: `${base_api_url}/tickmatch_dejavu_report1?${objectToQueryString(values)}`, method: "GET", headers: { "xsrf-token": csrfToken } });
        // console.log(response)
        if (response.status) {
            let newData = [];
            response.data.tickmatch?.map((value, index) => {
                newData.push(createData(value.Time, value.Last, value.Vol.toLocaleString(), value.Type, value.acc_cum_vol.toLocaleString()))
            });
            console.log(newData);
            // setSumBuy(prevState => [
            //     response.data.tickmatch_sum?.[0]?.sum_buy?.toLocaleString() || 0,
            //     ...prevState.slice(1)
            // ]);
            // setSumSell(prevState => [
            //     response.data.tickmatch_sum?.[0]?.sum_sell?.toLocaleString() || 0,
            //     ...prevState.slice(1)
            // ]);
            setSumBuy(prevState => prevState.map((item, index) => index === 0 ? response.data.tickmatch_sum?.[0]?.sum_buy.toLocaleString() || 0 : item));
            setSumSell(prevState => prevState.map((item, index) => index === 0 ? response.data.tickmatch_sum?.[0]?.sum_sell.toLocaleString() || 0 : item));
            setRows(newData);
        }
        endProcess();
    }
    const handleSubmitForm2 = async () => {
        setRows2([]);
        setIsFetch(prevState => ({
            ...prevState,
            'form2': true,
        }));
        const values = {
            symbol: initialValues.symbol2,
            start_date: formatDate(initialValues.date2),
            end_date: formatDate(initialValues.date2)
        };
        const response = await __fetching_data__({ url: `${base_api_url}/tickmatch_dejavu_report1?${objectToQueryString(values)}`, method: "GET", headers: { "xsrf-token": csrfToken } });
        if (response.status) {
            let newData = [];
            response.data.tickmatch.map((value, index) => {
                newData.push(createData(value.Time, value.Last, value.Vol.toLocaleString(), value.Type, value.acc_cum_vol.toLocaleString()))
            });
            setSumBuy(prevState => prevState.map((item, index) => index === 1 ? response.data.tickmatch_sum?.[0]?.sum_buy.toLocaleString() || 0 : item));
            setSumSell(prevState => prevState.map((item, index) => index === 1 ? response.data.tickmatch_sum?.[0]?.sum_sell.toLocaleString() || 0 : item));

            setRows2(newData);
        }
        endProcess();
    }

    const getValidDate = (date) => {
        let validDate = dayjs(date);
        while (disableWeekends(validDate)) {
            validDate = validDate.subtract(1, 'day');
        }
        return validDate;
    };

    const FormSection = ({
        symbolId,
        symbolValue,
        dateId,
        dateValue,
        handleChange,
        handleDateChange,
        handleSubmit,
        today_value,
        disableWeekends,
        colors,
        sumbuy,
        sumsell,
    }) => (
        <Grid container xs={12} sm={12} md={12} lg={12} p={0} m={0}>
            <Grid container spacing={2} sx={{
                marginTop: "0.5rem",
                marginBottom: "0.5rem",
                paddingRight: "0.5rem",
                // backgroundColor: colors.blueAccent[500],
                display: "flex",
                flexDirection: "row", // Ensure horizontal alignment
                justifyContent: "space-between", // Space items across the row
                alignItems: "center", // Align items vertically
                flexWrap: "nowrap", // Prevent items from wrapping to the next line
                gap: "0.5rem", // Optional: Spacing between items
            }}>

                <Grid item xs={4} md={4} sx={{
                }}>
                    <TextField
                        fullWidth
                        id={symbolId}
                        name={symbolId}
                        label="Symbol"
                        variant="outlined"
                        required
                        size="small"
                        value={symbolValue}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={4} md={4} sx={{
                }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                        <DatePicker
                            // fullWidth
                            id={dateId}
                            name={dateId}
                            label="Date"
                            slotProps={{ textField: { size: 'small' } }}
                            defaultValue={dateValue}
                            maxDate={today_value.subtract(1, 'day')}
                            onChange={(value) => handleDateChange(dateId, value)}
                            shouldDisableDate={disableWeekends}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={4} md={4}
                    sx={{
                        fontSize: {
                            xs: '10px',
                            sm: '12px'
                        }
                    }}>
                    <Button
                        variant="contained"
                        fullWidth
                        sx={{ backgroundColor: colors.blueAccent[500] }}
                        onClick={handleSubmit}
                    >
                        Search
                    </Button>
                </Grid>
            </Grid>

            <Grid container spacing={2} sx={{
                // marginTop: "0.5rem",
                marginBottom: "0.5rem",
                paddingRight: "0.5rem",
                // backgroundColor: colors.blueAccent[500],
                display: "flex",
                flexDirection: "row", // Ensure horizontal alignment
                justifyContent: "end", // Space items across the row
                alignItems: "center", // Align items vertically
                flexWrap: "nowrap", // Prevent items from wrapping to the next line
                // gap: "0.5rem", // Optional: Spacing between items
            }}>
                <Grid item xs={4} md={4} sx={{
                }}>
                    <TextField
                        fullWidth
                        label="Sum Buy"
                        variant="outlined"
                        size="small"
                        value={sumbuy}
                        InputProps={{
                            readOnly: true,

                        }}
                        sx={{
                            '& .MuiInputLabel-root': {
                                color: 'green',
                            },
                            '& .MuiInputLabel-root.Mui-focused': {
                                color: 'green',
                            },
                            '& .MuiInputBase-root': {
                                backgroundColor: 'rgba(144, 238, 144, 0.3)',
                            },
                            '& .MuiInputBase-input': {
                                textAlign: 'right',
                                fontSize: 'calc(1rem - 1px)',
                                fontWeight: 'bold',
                                color: 'green',
                                // minWidth: `${sumbuy.toString().length * 12}px`,
                                // overflow: 'hidden', // ป้องกันไม่ให้ข้อความล้น
                                // textOverflow: 'ellipsis', // ตัดข้อความด้วย ... หากยาวเกิน
                                // whiteSpace: 'nowrap', // บังคับให้ข้อความอยู่ในบรรทัดเดียว
                            },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'green',
                                },
                                '&:hover fieldset': {
                                    borderColor: 'green',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: 'green',
                                },
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={4} md={4} sx={{
                }}>
                    <TextField
                        fullWidth
                        label="Sum Sell"
                        variant="outlined"
                        size="small"
                        value={sumsell}
                        InputProps={{
                            readOnly: true,

                        }}
                        sx={{
                            '& .MuiInputLabel-root': {
                                color: 'red',
                            },
                            '& .MuiInputLabel-root.Mui-focused': {
                                color: 'red',
                            },
                            '& .MuiInputBase-root': {
                                backgroundColor: 'rgba(255, 0, 0, 0.1)',
                            },
                            '& .MuiInputBase-input': {
                                textAlign: 'right',
                                fontSize: 'calc(1rem - 1px)',
                                fontWeight: 'bold',
                                color: 'red',
                            },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'red',
                                },
                                '&:hover fieldset': {
                                    borderColor: 'red',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: 'red',
                                },
                            },
                        }}
                    />
                </Grid>
            </Grid>
        </Grid>

    );

    const DataTable = ({ rows, isFetching, syncScrollRef }) => {
        const tableRef = useRef(null);

        // const handleScroll = (e) => {
        //     if (isSyncing && syncScrollRef.current) {
        //         syncScrollRef.current.forEach((ref) => {
        //             if (ref && ref !== tableRef.current) {
        //                 ref.scrollTop = e.target.scrollTop;
        //             }
        //         });
        //     }
        // };


        return (
            <Grid item md={12} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <TableContainer
                    component={Paper}
                    ref={(ref) => {
                        tableRef.current = ref;
                        if (syncScrollRef && !syncScrollRef.current.includes(ref)) {
                            syncScrollRef.current.push(ref);
                        }
                    }}
                    onScroll={handleScroll}
                    sx={{
                        width: '100%',
                        height: '100%',
                        '@media (min-width: 600px)': {
                            width: '100%',
                            height: '100%',
                        },
                        // Table ***************
                        maxHeight: { xs: '32vh', sm: '35vh', md: '50vh', lg: '72vh', xl: '72vh' },
                        overflowY: 'auto',
                        '::-webkit-scrollbar': {
                            width: '8px',
                        },
                        '::-webkit-scrollbar-thumb': {
                            backgroundColor: '#888',
                            borderRadius: '4px',
                        },
                        '::-webkit-scrollbar-thumb:hover': {
                            backgroundColor: '#555',
                        },
                    }}
                >
                    <Table aria-label="simple table" stickyHeader>
                        <TableHead>
                            <TableRow>
                                {['Time', 'Last', 'Vol', 'Type', 'Sum'].map((header) => (
                                    <StyledTableCell
                                        key={header}
                                        align="center"
                                        sx={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}
                                    >
                                        {header}
                                    </StyledTableCell>
                                ))}
                                {/* <StyledTableCell align="center" sx={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>Time</StyledTableCell>
                            <StyledTableCell align="center" sx={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>Last</StyledTableCell>
                            <StyledTableCell align="center" sx={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>Vol</StyledTableCell>
                            <StyledTableCell align="center" sx={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>Type</StyledTableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isFetching ? (
                                <TableRow>
                                    <TableCell colSpan={5} align="center">
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                            ) : (
                                rows.map((row, index) => (
                                    <TableRow key={index} sx={{
                                        td: {
                                            padding: '4px', fontSize: {
                                                xs: 12,
                                                sm: 12,
                                                md: 10,
                                                lg: 12,
                                                xl: 12
                                            }, fontFamily: 'Source Sans Pro, sans-serif'
                                        }
                                    }}>
                                        <TableCell align="center" style={{ padding: '4px' }}>{row.time}</TableCell>
                                        <TableCell align="center" style={{ padding: '4px' }}>{row.last}</TableCell>
                                        <TableCell align="center" style={{ textAlign: 'right', padding: '4px' }}>
                                            {row.vol}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            style={{ padding: '4px', color: row.type === 'BUY' ? 'green' : 'red' }}
                                        >
                                            {row.type}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            style={{
                                                padding: '4px',
                                                color: parseFloat(row.acc_cum_vol.replace(/,/g, '')) > 0 ? 'green' : 'red'
                                            }}
                                        >
                                            {row.acc_cum_vol}
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        );
    }

    const SyncButtonWithTooltip = ({ isSyncEnabled, onClick }) => {
        return (
            <Tooltip title={isSyncEnabled ? 'Click to disable scroll sync' : 'Click to enable scroll sync'}>
                <Button
                    onClick={onClick}
                    variant={isSyncEnabled ? 'contained' : 'outlined'}
                    startIcon={
                        isSyncEnabled ? (
                            <SyncIcon />
                        ) : (
                            <SyncDisabledIcon
                            // sx={{ transform: 'scaleY(-1)' }}
                            />
                        )
                    }
                    sx={{
                        backgroundColor: isSyncEnabled ? 'green' : 'transparent',
                        color: isSyncEnabled ? 'white' : 'gray',
                        borderColor: isSyncEnabled ? 'green' : 'gray',
                        '&:hover': {
                            backgroundColor: isSyncEnabled ? 'green' : 'rgba(0, 0, 0, 0.04)', // Adjust hover state
                        },
                    }}
                >
                    {/* {isSyncEnabled ? 'Disable Sync' : 'Enable Sync'} */} Sync
                </Button>
            </Tooltip>
        );
    };


    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center" p="0">
                <Header title="TickMatch" subtitle="" />
            </Box>

            <Box p="0" display="Box"
                sx={{
                    height: {
                        xs: '90vh', // height for extra-small screens
                        sm: '90vh', // height for small screens
                        md: '82vh', // height for medium screens
                        lg: '90vh', // height for large screens
                        xl: '90vh', // height for extra-large screens
                    },
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '-2.3rem'
                }}>
                <Container maxWidth="xl" sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <Paper
                        sx={{
                            flex: 1,
                            overflowY: 'auto',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            p: 2,
                            maxHeight: {
                                xs: '90vh', // height for extra-small screens
                                sm: '90vh', // height for small screens
                                md: '90vh', // height for medium screens
                                lg: '90vh', // height for large screens
                                xl: '90vh', // height for extra-large screens
                            },
                        }}
                    >
                        <Grid container
                            spacing={{
                                xs: 0,
                                sm: 0,
                                md: 0,
                                lg: 0,
                                xl: 0
                            }}
                            //display="flex" // alignItems="center" justifyContent="center"
                            xs={12} md={12}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',

                                marginBottom: "1rem"
                            }}>
                            {!isMobile && (
                                <Grid className="Container-tm" container
                                    spacing={{
                                        xs: 0,
                                        sm: 0,
                                        md: 5,
                                        lg: 5,
                                        xl: 5
                                    }}
                                    //display="flex" // alignItems="center" justifyContent="center"
                                    xs={12} md={12}
                                    sx={{
                                        marginBottom: "1rem"
                                    }}>
                                    <Grid className="grid-tm1-nM" item xs={12} md={6}
                                    // marginBottom={{
                                    //     xs: '60px',
                                    //     sm: '60px',
                                    //     md: '0px',
                                    //     lg: '0px',
                                    //     xl: '0px',
                                    // }}
                                    >
                                        <SyncButtonWithTooltip isSyncEnabled={isSyncEnabled} onClick={toggleSync} />

                                        {/* Tickmatch Table 1 */}

                                        <Grid container xs={12} sm={12} md={12} lg={12} p={0} m={0}>
                                            <Grid container spacing={2} sx={{
                                                marginTop: "0.5rem",
                                                marginBottom: "0.5rem",
                                                paddingRight: "0.5rem",
                                                // backgroundColor: colors.blueAccent[500],
                                                display: "flex",
                                                flexDirection: "row", // Ensure horizontal alignment
                                                justifyContent: "space-between", // Space items across the row
                                                alignItems: "center", // Align items vertically
                                                flexWrap: "nowrap", // Prevent items from wrapping to the next line
                                                gap: "0.5rem", // Optional: Spacing between items
                                            }}>

                                                <Grid item xs={4} md={4} sx={{
                                                }}>
                                                    <TextField
                                                        fullWidth
                                                        id="symbol1"
                                                        name="symbol1"
                                                        label="Symbol"
                                                        variant="outlined"
                                                        required
                                                        size='small'
                                                        value={initialValues.symbol1}
                                                        onChange={handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} md={4} sx={{
                                                }}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                        <DatePicker
                                                            // fullWidth
                                                            id="date1"
                                                            name="date1"
                                                            label="Date"
                                                            slotProps={{ textField: { size: 'small' } }}
                                                            // defaultValue={initialValues.date1}
                                                            defaultValue={getValidDate(initialValues.date1)}
                                                            maxDate={today_value.subtract(1, 'day')}
                                                            onChange={(value) => handleDateChange('date1', value)}
                                                            shouldDisableDate={disableWeekends}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                                <Grid item xs={4} md={4}
                                                    sx={{
                                                        fontSize: {
                                                            xs: '10px',
                                                            sm: '12px'
                                                        }
                                                    }}>
                                                    <Button
                                                        variant="contained"
                                                        fullWidth
                                                        sx={{ backgroundColor: colors.blueAccent[500] }}
                                                        onClick={handleSubmitForm1}
                                                    >
                                                        Search
                                                    </Button>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={2} sx={{
                                                // marginTop: "0.5rem",
                                                marginBottom: "0.5rem",
                                                paddingRight: "0.5rem",
                                                // backgroundColor: colors.blueAccent[500],
                                                display: "flex",
                                                flexDirection: "row", // Ensure horizontal alignment
                                                justifyContent: "end", // Space items across the row
                                                alignItems: "center", // Align items vertically
                                                flexWrap: "nowrap", // Prevent items from wrapping to the next line
                                                // gap: "0.5rem", // Optional: Spacing between items
                                            }}>
                                                <Grid item xs={4} md={4} sx={{
                                                }}>
                                                    <TextField
                                                        fullWidth
                                                        label="Sum Buy"
                                                        variant="outlined"
                                                        size="small"
                                                        value={sumBuy[0]}
                                                        InputProps={{
                                                            readOnly: true,

                                                        }}
                                                        sx={{
                                                            '& .MuiInputLabel-root': {
                                                                color: 'green',
                                                            },
                                                            '& .MuiInputLabel-root.Mui-focused': {
                                                                color: 'green',
                                                            },
                                                            '& .MuiInputBase-root': {
                                                                backgroundColor: 'rgba(144, 238, 144, 0.3)',
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                textAlign: 'right',
                                                                fontSize: 'calc(1rem - 1px)',
                                                                fontWeight: 'bold',
                                                                color: 'green',
                                                                // minWidth: `${sumbuy.toString().length * 12}px`,
                                                                // overflow: 'hidden', // ป้องกันไม่ให้ข้อความล้น
                                                                // textOverflow: 'ellipsis', // ตัดข้อความด้วย ... หากยาวเกิน
                                                                // whiteSpace: 'nowrap', // บังคับให้ข้อความอยู่ในบรรทัดเดียว
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderColor: 'green',
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: 'green',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: 'green',
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} md={4} sx={{
                                                }}>
                                                    <TextField
                                                        fullWidth
                                                        label="Sum Sell"
                                                        variant="outlined"
                                                        size="small"
                                                        value={sumSell[0]}
                                                        InputProps={{
                                                            readOnly: true,

                                                        }}
                                                        sx={{
                                                            '& .MuiInputLabel-root': {
                                                                color: 'red',
                                                            },
                                                            '& .MuiInputLabel-root.Mui-focused': {
                                                                color: 'red',
                                                            },
                                                            '& .MuiInputBase-root': {
                                                                backgroundColor: 'rgba(255, 0, 0, 0.1)',
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                textAlign: 'right',
                                                                fontSize: 'calc(1rem - 1px)',
                                                                fontWeight: 'bold',
                                                                color: 'red',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderColor: 'red',
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: 'red',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: 'red',
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <DataTable rows={rows} isFetching={isFetch.form1} syncScrollRef={syncScrollRef}
                                            isSyncing={isSyncing} />

                                    </Grid>
                                    {/* Tickmatch Table 2 */}
                                    <Grid className="grid-tm2-nM" item xs={12} md={6}>
                                        {!isPTablet &&
                                            (<>
                                                <SyncButtonWithTooltip isSyncEnabled={isSyncEnabled} onClick={toggleSync} />
                                            </>)}

                                        {/* <FormSection
                                            symbolId="symbol2"
                                            symbolValue={initialValues.symbol2}
                                            dateId="date2"
                                            dateValue={getValidDate(initialValues.date2)}
                                            handleChange={handleChange}
                                            handleDateChange={handleDateChange}
                                            handleSubmit={handleSubmitForm2}
                                            today_value={today_value}
                                            disableWeekends={disableWeekends}
                                            colors={colors}
                                            sumbuy={sumBuy[1]}
                                            sumsell={sumSell[1]} /> */}



                                        <Grid container xs={12} sm={12} md={12} lg={12} p={0} m={0}>
                                            <Grid container spacing={2} sx={{
                                                marginTop: "0.5rem",
                                                marginBottom: "0.5rem",
                                                paddingRight: "0.5rem",
                                                // backgroundColor: colors.blueAccent[500],
                                                display: "flex",
                                                flexDirection: "row", // Ensure horizontal alignment
                                                justifyContent: "space-between", // Space items across the row
                                                alignItems: "center", // Align items vertically
                                                flexWrap: "nowrap", // Prevent items from wrapping to the next line
                                                gap: "0.5rem", // Optional: Spacing between items
                                            }}>

                                                <Grid item xs={4} md={4} sx={{
                                                }}>
                                                    <TextField
                                                        fullWidth
                                                        id="symbol2"
                                                        name="symbol2"
                                                        label="Symbol"
                                                        variant="outlined"
                                                        required
                                                        size='small'
                                                        value={initialValues.symbol2}
                                                        onChange={handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} md={4} sx={{
                                                }}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                        <DatePicker
                                                            // fullWidth
                                                            id="date2"
                                                            name="date2"
                                                            label="Date"
                                                            slotProps={{ textField: { size: 'small' } }}
                                                            // defaultValue={initialValues.date1}
                                                            defaultValue={getValidDate(initialValues.date2)}
                                                            maxDate={today_value.subtract(1, 'day')}
                                                            onChange={(value) => handleDateChange('date2', value)}
                                                            shouldDisableDate={disableWeekends}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                                <Grid item xs={4} md={4}
                                                    sx={{
                                                        fontSize: {
                                                            xs: '10px',
                                                            sm: '12px'
                                                        }
                                                    }}>
                                                    <Button
                                                        variant="contained"
                                                        fullWidth
                                                        sx={{ backgroundColor: colors.blueAccent[500] }}
                                                        onClick={handleSubmitForm2}
                                                    >
                                                        Search
                                                    </Button>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={2} sx={{
                                                // marginTop: "0.5rem",
                                                marginBottom: "0.5rem",
                                                paddingRight: "0.5rem",
                                                // backgroundColor: colors.blueAccent[500],
                                                display: "flex",
                                                flexDirection: "row", // Ensure horizontal alignment
                                                justifyContent: "end", // Space items across the row
                                                alignItems: "center", // Align items vertically
                                                flexWrap: "nowrap", // Prevent items from wrapping to the next line
                                                // gap: "0.5rem", // Optional: Spacing between items
                                            }}>
                                                <Grid item xs={4} md={4} sx={{
                                                }}>
                                                    <TextField
                                                        fullWidth
                                                        label="Sum Buy"
                                                        variant="outlined"
                                                        size="small"
                                                        value={sumBuy[1]}
                                                        InputProps={{
                                                            readOnly: true,

                                                        }}
                                                        sx={{
                                                            '& .MuiInputLabel-root': {
                                                                color: 'green',
                                                            },
                                                            '& .MuiInputLabel-root.Mui-focused': {
                                                                color: 'green',
                                                            },
                                                            '& .MuiInputBase-root': {
                                                                backgroundColor: 'rgba(144, 238, 144, 0.3)',
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                textAlign: 'right',
                                                                fontSize: 'calc(1rem - 1px)',
                                                                fontWeight: 'bold',
                                                                color: 'green',
                                                                // minWidth: `${sumbuy.toString().length * 12}px`,
                                                                // overflow: 'hidden', // ป้องกันไม่ให้ข้อความล้น
                                                                // textOverflow: 'ellipsis', // ตัดข้อความด้วย ... หากยาวเกิน
                                                                // whiteSpace: 'nowrap', // บังคับให้ข้อความอยู่ในบรรทัดเดียว
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderColor: 'green',
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: 'green',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: 'green',
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} md={4} sx={{
                                                }}>
                                                    <TextField
                                                        fullWidth
                                                        label="Sum Sell"
                                                        variant="outlined"
                                                        size="small"
                                                        value={sumSell[1]}
                                                        InputProps={{
                                                            readOnly: true,

                                                        }}
                                                        sx={{
                                                            '& .MuiInputLabel-root': {
                                                                color: 'red',
                                                            },
                                                            '& .MuiInputLabel-root.Mui-focused': {
                                                                color: 'red',
                                                            },
                                                            '& .MuiInputBase-root': {
                                                                backgroundColor: 'rgba(255, 0, 0, 0.1)',
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                textAlign: 'right',
                                                                fontSize: 'calc(1rem - 1px)',
                                                                fontWeight: 'bold',
                                                                color: 'red',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderColor: 'red',
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: 'red',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: 'red',
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <DataTable rows={rows2} isFetching={isFetch.form2} syncScrollRef={syncScrollRef}
                                            isSyncing={isSyncing} />

                                    </Grid>
                                </Grid>)}
                            {isMobile && <>
                                <Grid item xs={12} md={6} sx={{
                                    // height: {
                                    //     xs: '82vh',
                                    //     sm: '82vh',
                                    //     md: '82vh',
                                    //     lg: '90vh',
                                    //     xl: '95vh',
                                    // },
                                }}>
                                    <SyncButtonWithTooltip isSyncEnabled={isSyncEnabled} onClick={toggleSync} />
                                    {/* <FormSection
                                        symbolId="symbol1"
                                        symbolValue={initialValues.symbol1}
                                        dateId="date1"
                                        dateValue={getValidDate(initialValues.date1)}
                                        handleChange={handleChange}
                                        handleDateChange={handleDateChange}
                                        handleSubmit={handleSubmitForm1}
                                        today_value={today_value}
                                        disableWeekends={disableWeekends}
                                        colors={colors}
                                    /> */}
                                    {/* Table 1 */}
                                    <Grid container xs={12} sm={12} md={12} lg={12} p={0} m={0}>
                                        <Grid container spacing={2} sx={{
                                            marginTop: "0.5rem",
                                            marginBottom: "0.5rem",
                                            paddingRight: "0.5rem",
                                            // backgroundColor: colors.blueAccent[500],
                                            display: "flex",
                                            flexDirection: "row", // Ensure horizontal alignment
                                            justifyContent: "space-between", // Space items across the row
                                            alignItems: "center", // Align items vertically
                                            flexWrap: "nowrap", // Prevent items from wrapping to the next line
                                            gap: "0.5rem", // Optional: Spacing between items
                                        }}>

                                            <Grid item xs={4} md={4} sx={{
                                            }}>
                                                <TextField
                                                    fullWidth
                                                    id="symbol1"
                                                    name="symbol1"
                                                    label="Symbol"
                                                    variant="outlined"
                                                    required
                                                    size='small'
                                                    value={initialValues.symbol1}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={4} md={4} sx={{
                                            }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                    <DatePicker
                                                        // fullWidth
                                                        id="date1"
                                                        name="date1"
                                                        label="Date"
                                                        slotProps={{ textField: { size: 'small' } }}
                                                        // defaultValue={initialValues.date1}
                                                        defaultValue={getValidDate(initialValues.date1)}
                                                        maxDate={today_value.subtract(1, 'day')}
                                                        onChange={(value) => handleDateChange('date1', value)}
                                                        shouldDisableDate={disableWeekends}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={4} md={4}
                                                sx={{
                                                    fontSize: {
                                                        xs: '10px',
                                                        sm: '12px'
                                                    }
                                                }}>
                                                <Button
                                                    variant="contained"
                                                    fullWidth
                                                    sx={{ backgroundColor: colors.blueAccent[500] }}
                                                    onClick={handleSubmitForm1}
                                                >
                                                    Search
                                                </Button>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={4} sx={{
                                            // marginTop: "0.5rem",
                                            marginBottom: "0.5rem",
                                            paddingRight: "0.5rem",
                                            // backgroundColor: colors.blueAccent[500],
                                            display: "flex",
                                            flexDirection: "row", // Ensure horizontal alignment
                                            justifyContent: "end", // Space items across the row
                                            alignItems: "center", // Align items vertically
                                            flexWrap: "nowrap", // Prevent items from wrapping to the next line
                                            // gap: "0.5rem", // Optional: Spacing between items
                                        }}>
                                            <Grid item xs={6} md={4} sx={{
                                            }}>
                                                <TextField
                                                    fullWidth
                                                    label="Sum Buy"
                                                    variant="outlined"
                                                    size="small"
                                                    value={sumBuy[0]}
                                                    InputProps={{
                                                        readOnly: true,

                                                    }}
                                                    sx={{
                                                        '& .MuiInputLabel-root': {
                                                            color: 'green',
                                                        },
                                                        '& .MuiInputLabel-root.Mui-focused': {
                                                            color: 'green',
                                                        },
                                                        '& .MuiInputBase-root': {
                                                            backgroundColor: 'rgba(144, 238, 144, 0.3)',
                                                        },
                                                        '& .MuiInputBase-input': {
                                                            textAlign: 'right',
                                                            fontSize: 'calc(1rem - 1px)',
                                                            fontWeight: 'bold',
                                                            color: 'green',
                                                            // minWidth: `${sumbuy.toString().length * 12}px`,
                                                            // overflow: 'hidden', // ป้องกันไม่ให้ข้อความล้น
                                                            // textOverflow: 'ellipsis', // ตัดข้อความด้วย ... หากยาวเกิน
                                                            // whiteSpace: 'nowrap', // บังคับให้ข้อความอยู่ในบรรทัดเดียว
                                                        },
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                borderColor: 'green',
                                                            },
                                                            '&:hover fieldset': {
                                                                borderColor: 'green',
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: 'green',
                                                            },
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6} md={4} sx={{
                                            }}>
                                                <TextField
                                                    fullWidth
                                                    label="Sum Sell"
                                                    variant="outlined"
                                                    size="small"
                                                    value={sumSell[0]}
                                                    InputProps={{
                                                        readOnly: true,

                                                    }}
                                                    sx={{
                                                        '& .MuiInputLabel-root': {
                                                            color: 'red',
                                                        },
                                                        '& .MuiInputLabel-root.Mui-focused': {
                                                            color: 'red',
                                                        },
                                                        '& .MuiInputBase-root': {
                                                            backgroundColor: 'rgba(255, 0, 0, 0.1)',
                                                        },
                                                        '& .MuiInputBase-input': {
                                                            textAlign: 'right',
                                                            fontSize: 'calc(1rem - 1px)',
                                                            fontWeight: 'bold',
                                                            color: 'red',
                                                        },
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                borderColor: 'red',
                                                            },
                                                            '&:hover fieldset': {
                                                                borderColor: 'red',
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: 'red',
                                                            },
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <DataTable rows={rows} isFetching={isFetch.form1} syncScrollRef={syncScrollRef}
                                        isSyncing={isSyncing} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {/* <FormSection
                                        symbolId="symbol2"
                                        symbolValue={initialValues.symbol2}
                                        dateId="date2"
                                        dateValue={getValidDate(initialValues.date2)}
                                        handleChange={handleChange}
                                        handleDateChange={handleDateChange}
                                        handleSubmit={handleSubmitForm2}
                                        today_value={today_value}
                                        disableWeekends={disableWeekends}
                                        colors={colors}
                                    /> */}
                                    {/* Table 2 */}
                                    <Grid container xs={12} sm={12} md={12} lg={12} p={0} m={0}>
                                        <Grid container spacing={2} sx={{
                                            marginTop: "0.5rem",
                                            marginBottom: "0.5rem",
                                            paddingRight: "0.5rem",
                                            // backgroundColor: colors.blueAccent[500],
                                            display: "flex",
                                            flexDirection: "row", // Ensure horizontal alignment
                                            justifyContent: "space-between", // Space items across the row
                                            alignItems: "center", // Align items vertically
                                            flexWrap: "nowrap", // Prevent items from wrapping to the next line
                                            gap: "0.5rem", // Optional: Spacing between items
                                        }}>

                                            <Grid item xs={4} md={4} sx={{
                                            }}>
                                                <TextField
                                                    fullWidth
                                                    id="symbol2"
                                                    name="symbol2"
                                                    label="Symbol"
                                                    variant="outlined"
                                                    required
                                                    size='small'
                                                    value={initialValues.symbol2}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={4} md={4} sx={{
                                            }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                    <DatePicker
                                                        // fullWidth
                                                        id="date2"
                                                        name="date2"
                                                        label="Date"
                                                        slotProps={{ textField: { size: 'small' } }}
                                                        // defaultValue={initialValues.date1}
                                                        defaultValue={getValidDate(initialValues.date2)}
                                                        maxDate={today_value.subtract(1, 'day')}
                                                        onChange={(value) => handleDateChange('date2', value)}
                                                        shouldDisableDate={disableWeekends}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={4} md={4}
                                                sx={{
                                                    fontSize: {
                                                        xs: '10px',
                                                        sm: '12px'
                                                    }
                                                }}>
                                                <Button
                                                    variant="contained"
                                                    fullWidth
                                                    sx={{ backgroundColor: colors.blueAccent[500] }}
                                                    onClick={handleSubmitForm2}
                                                >
                                                    Search
                                                </Button>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={4} sx={{
                                            // marginTop: "0.5rem",
                                            marginBottom: "0.5rem",
                                            paddingRight: "0.5rem",
                                            // backgroundColor: colors.blueAccent[500],
                                            display: "flex",
                                            flexDirection: "row", // Ensure horizontal alignment
                                            justifyContent: "end", // Space items across the row
                                            alignItems: "center", // Align items vertically
                                            flexWrap: "nowrap", // Prevent items from wrapping to the next line
                                            // gap: "0.5rem", // Optional: Spacing between items
                                        }}>
                                            <Grid item xs={6} md={4} sx={{
                                            }}>
                                                <TextField
                                                    fullWidth
                                                    label="Sum Buy"
                                                    variant="outlined"
                                                    size="small"
                                                    value={sumBuy[1]}
                                                    InputProps={{
                                                        readOnly: true,

                                                    }}
                                                    sx={{
                                                        '& .MuiInputLabel-root': {
                                                            color: 'green',
                                                        },
                                                        '& .MuiInputLabel-root.Mui-focused': {
                                                            color: 'green',
                                                        },
                                                        '& .MuiInputBase-root': {
                                                            backgroundColor: 'rgba(144, 238, 144, 0.3)',
                                                        },
                                                        '& .MuiInputBase-input': {
                                                            textAlign: 'right',
                                                            fontSize: 'calc(1rem - 1px)',
                                                            fontWeight: 'bold',
                                                            color: 'green',
                                                            // minWidth: `${sumbuy.toString().length * 12}px`,
                                                            // overflow: 'hidden', // ป้องกันไม่ให้ข้อความล้น
                                                            // textOverflow: 'ellipsis', // ตัดข้อความด้วย ... หากยาวเกิน
                                                            // whiteSpace: 'nowrap', // บังคับให้ข้อความอยู่ในบรรทัดเดียว
                                                        },
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                borderColor: 'green',
                                                            },
                                                            '&:hover fieldset': {
                                                                borderColor: 'green',
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: 'green',
                                                            },
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6} md={4} sx={{
                                            }}>
                                                <TextField
                                                    fullWidth
                                                    label="Sum Sell"
                                                    variant="outlined"
                                                    size="small"
                                                    value={sumSell[1]}
                                                    InputProps={{
                                                        readOnly: true,

                                                    }}
                                                    sx={{
                                                        '& .MuiInputLabel-root': {
                                                            color: 'red',
                                                        },
                                                        '& .MuiInputLabel-root.Mui-focused': {
                                                            color: 'red',
                                                        },
                                                        '& .MuiInputBase-root': {
                                                            backgroundColor: 'rgba(255, 0, 0, 0.1)',
                                                        },
                                                        '& .MuiInputBase-input': {
                                                            textAlign: 'right',
                                                            fontSize: 'calc(1rem - 1px)',
                                                            fontWeight: 'bold',
                                                            color: 'red',
                                                        },
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                borderColor: 'red',
                                                            },
                                                            '&:hover fieldset': {
                                                                borderColor: 'red',
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: 'red',
                                                            },
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <DataTable rows={rows2} isFetching={isFetch.form2} syncScrollRef={syncScrollRef}
                                        isSyncing={isSyncing} />
                                </Grid>
                            </>
                            }

                        </Grid>



                    </Paper>
                </Container>
            </Box>
        </Box >

    );
};

export default DejavuTickmatch;
