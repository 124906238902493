import React, { useEffect, useRef, useState } from 'react';
import { createChart } from 'lightweight-charts';
import { useSelector } from "react-redux";
import './index.css';
import { useIsMobile, useIsPortraitTablet, useIsTablet } from '../../components/hooks';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Box, useMediaQuery, useTheme, IconButton } from '@mui/material'
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

// ---------------------------------------------
// Utilities
// ---------------------------------------------
const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const earthToneColor = '#D2B48C';
const getOppositeColor = () => '#0d0082';

const getTodayDateString = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const adjustToLocalTime = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const timezoneOffset = date.getTimezoneOffset() * 60;
  return timestamp - timezoneOffset;
};

// ---------------------------------------------
// Reusable child: ChartSectionWithMultipleLines
// ---------------------------------------------
const ChartSectionWithMultipleLines = ({ title, market, type }) => {
  const chartContainerRef = useRef(null);
  const chartTableContainerRef = useRef(null);
  const chartRef = useRef(null);
  const lineSeriesRef = useRef(new Map());
  const seriesDataRef = useRef(new Map());
  const originalColorsRef = useRef(new Map());

  const [tableData, setTableData] = useState([]);
  const [previousRanks, setPreviousRanks] = useState({});
  const previousRanksRef = useRef({});
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const csrfToken = useSelector((state) => state.csrfToken);

  const [isCollapsed, setIsCollapsed] = useState(false);

  const shouldInvert = (type === "out");
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isPTablet = useIsPortraitTablet();


  useEffect(() => {
    previousRanksRef.current = previousRanks;
  }, [previousRanks]);

  // ---------------------------------------------
  // sendTelegramMessage is NOT called here directly.
  // We simply push changes into window.__globalRankChanges__ for the parent to handle.
  // ---------------------------------------------

  async function fetchHistoricalData() {
    try {
      const fetch_response = await fetch(`${window.config.base_api_url}/money_in_out/today/${market}`, {
        headers: {
          'Content-Type': 'application/json',
          'xsrf-token': csrfToken
        },
      });
      const response = await fetch_response.json();

      const data = response.data[type]; // 'in' or 'out'
      const times = Object.keys(data.rank).sort();
      const latestTime = times[times.length - 1];
      const latestSymbols = Object.keys(data.rank[latestTime]);

      // Build historical data (symbol -> array of {time,rank,value})
      const historicalData = {};
      const todayDateString = getTodayDateString();
      times.forEach((time) => {
        Object.keys(data.rank[time]).forEach((symbol) => {
          if (!historicalData[symbol]) {
            historicalData[symbol] = [];
          }
          const dateTimeString = `${todayDateString}T${time}`;
          const timestamp = Math.floor(new Date(dateTimeString).getTime() / 1000);
          const rawValue = data.value[time][symbol];
          const finalValue = shouldInvert ? -1 * rawValue : rawValue;

          historicalData[symbol].push({
            time: adjustToLocalTime(timestamp),
            rank: data.rank[time][symbol],
            value: finalValue,
          });
        });
      });

      // Track rank changes + store new previous ranks
      const newPreviousRanks = {};
      let lastChangeTime = -Infinity;
      const rankChanges = [];

      latestSymbols.forEach((symbol) => {
        const symData = historicalData[symbol];
        if (!symData || symData.length === 0) {
          newPreviousRanks[symbol] = null;
          return;
        }
        symData.sort((a, b) => a.time - b.time);
        const latestRank = symData[symData.length - 1].rank;
        newPreviousRanks[symbol] = latestRank;

        // find rank changes
        for (let i = 1; i < symData.length; i++) {
          if (symData[i].rank !== symData[i - 1].rank) {
            const changeTime = symData[i].time;
            rankChanges.push({
              symbol,
              time: changeTime,
              oldRank: symData[i - 1].rank,
              newRank: symData[i].rank
            });
            if (changeTime > lastChangeTime) {
              lastChangeTime = changeTime;
            }
          }
        }
        // create or update line series
        let series = lineSeriesRef.current.get(symbol);
        if (!series) {
          const color = getRandomColor();
          series = chartRef.current.addLineSeries({
            color,
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: false,
            title: symbol,
          });
          lineSeriesRef.current.set(symbol, series);
          originalColorsRef.current.set(symbol, color);
        }
        const seriesData = symData.map(dp => ({
          time: dp.time,
          value: dp.value,
        }));
        series.setData(seriesData);
        seriesDataRef.current.set(symbol, seriesData);
      });

      setPreviousRanks(newPreviousRanks);
      previousRanksRef.current = newPreviousRanks;

      // remove series that no longer exist
      const allSymbols = Array.from(lineSeriesRef.current.keys());
      const toRemove = allSymbols.filter(sym => !latestSymbols.includes(sym));
      toRemove.forEach((sym) => {
        const s = lineSeriesRef.current.get(sym);
        if (s) chartRef.current.removeSeries(s);
        lineSeriesRef.current.delete(sym);
        seriesDataRef.current.delete(sym);
        originalColorsRef.current.delete(sym);
      });

      if (selectedSymbol && !latestSymbols.includes(selectedSymbol)) {
        setSelectedSymbol(null);
      }

      // update table color based on lastChangeTime
      const lastChanges = rankChanges.filter(rc => rc.time === lastChangeTime);
      const symbolColors = new Map();
      if (lastChangeTime !== -Infinity && lastChanges.length > 0) {
        lastChanges.forEach((chg) => {
          if (chg.newRank < chg.oldRank) {
            symbolColors.set(chg.symbol, '#ccffcc');
          } else if (chg.newRank > chg.oldRank) {
            symbolColors.set(chg.symbol, '#ffcccc');
          } else {
            symbolColors.set(chg.symbol, 'transparent');
          }
        });
      }

      // build table data
      const updatedTable = latestSymbols.map((symbol) => {
        const sData = historicalData[symbol];
        const lastItem = sData[sData.length - 1];
        const c = symbolColors.get(symbol) || 'transparent';
        return {
          rank: lastItem.rank,
          symbol,
          value: lastItem.value,
          color: c,
        };
      });
      setTableData(updatedTable);

    } catch (err) {
      console.error('Error fetchHistoricalData for', market, err);
    }
  }

  async function fetchCurrentData() {
    try {
      if (Object.keys(previousRanksRef.current).length === 0) {
        return;
      }
      const fetch_response = await fetch(`${window.config.base_api_url}/money_in_out/now/${market}`, {
        headers: {
          'Content-Type': 'application/json',
          'xsrf-token': csrfToken
        },
      });
      const response = await fetch_response.json();
      const data = response.data[type];
      const timeKey = Object.keys(data.rank)[0];
      const dateTimeString = `${getTodayDateString()}T${timeKey}`;
      const timestamp = Math.floor(new Date(dateTimeString).getTime() / 1000);
      const latestSymbols = Object.keys(data.rank[timeKey]);

      const newTableData = [];
      latestSymbols.forEach((symbol) => {
        const currentRank = data.rank[timeKey][symbol];
        const oldRank = previousRanksRef.current[symbol] ?? currentRank;
        const diff = currentRank - oldRank;
        let color = 'transparent';
        if (diff < 0) { // rank improved => green
          color = '#ccffcc';
        } else if (diff > 0) {
          color = '#ffcccc';
        }
        const rawValue = data.value[timeKey][symbol];
        const finalValue = shouldInvert ? -1 * rawValue : rawValue;

        newTableData.push({
          rank: currentRank,
          symbol,
          time: timeKey,
          value: finalValue,
          color,
        });

        // If rank improved => push to global buffer, skipping Warrant
        if (market !== 'WARRANT' && diff < 0) {
          // e.g. "BEM 18 to 17 value 42.35"
          window.__globalRankChanges__ = window.__globalRankChanges__ || [];
          window.__globalRankChanges__.push({
            chartTitle: title,  // e.g. "SET100 Up (+)"
            symbol,
            oldRank,
            newRank: currentRank,
            value: finalValue
          });
        }
      });

      setTableData(newTableData);

      // update previous ranks
      const newPrev = {};
      newTableData.forEach((row) => {
        newPrev[row.symbol] = row.rank;
      });
      setPreviousRanks(newPrev);
      previousRanksRef.current = newPrev;

      // remove missing symbols
      const allSymbols = Array.from(lineSeriesRef.current.keys());
      const toRemove = allSymbols.filter(sym => !latestSymbols.includes(sym));
      toRemove.forEach((sym) => {
        const s = lineSeriesRef.current.get(sym);
        if (s) chartRef.current.removeSeries(s);
        lineSeriesRef.current.delete(sym);
        seriesDataRef.current.delete(sym);
        originalColorsRef.current.delete(sym);
      });
      if (selectedSymbol && !latestSymbols.includes(selectedSymbol)) {
        setSelectedSymbol(null);
      }

      // update chart series
      latestSymbols.forEach((symbol) => {
        const rawValue = data.value[timeKey][symbol];
        const finalValue = shouldInvert ? -1 * rawValue : rawValue;
        const point = {
          time: adjustToLocalTime(timestamp),
          value: finalValue,
        };
        let series = lineSeriesRef.current.get(symbol);
        if (series) {
          const existingData = seriesDataRef.current.get(symbol) || [];
          if (existingData.length > 0 && existingData[existingData.length - 1].time === point.time) {
            existingData[existingData.length - 1] = point;
          } else {
            existingData.push(point);
          }
          series.setData(existingData);
          seriesDataRef.current.set(symbol, existingData);
        } else {
          const c = getRandomColor();
          series = chartRef.current.addLineSeries({
            color: c,
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: false,
            title: symbol,
          });
          series.setData([point]);
          lineSeriesRef.current.set(symbol, series);
          seriesDataRef.current.set(symbol, [point]);
          originalColorsRef.current.set(symbol, c);
        }
      });
    } catch (err) {
      console.error("fetchCurrentData error for", market, type, err);
    }
  }

  const handleRowClick = (symbol) => {
    if (selectedSymbol === symbol) {
      setSelectedSymbol(null);
      lineSeriesRef.current.forEach((series, sym) => {
        const origColor = originalColorsRef.current.get(sym);
        series.applyOptions({
          color: origColor,
          lineWidth: 2,
        });
      });
    } else {
      setSelectedSymbol(symbol);
      const selectedSeries = lineSeriesRef.current.get(symbol);
      const data = seriesDataRef.current.get(symbol);
      if (selectedSeries && chartRef.current) {
        chartRef.current.removeSeries(selectedSeries);
        const newSeries = chartRef.current.addLineSeries({
          color: selectedSeries.options().color,
          lineWidth: selectedSeries.options().lineWidth,
          lastValueVisible: true,
          priceLineVisible: false,
          title: symbol,
        });
        newSeries.setData(data);
        lineSeriesRef.current.set(symbol, newSeries);

        const oppositeColor = getOppositeColor();
        newSeries.applyOptions({
          color: oppositeColor,
          lineWidth: 4,
        });
        // fade others
        lineSeriesRef.current.forEach((s, sym2) => {
          if (sym2 !== symbol) {
            s.applyOptions({
              color: earthToneColor,
              lineWidth: 2,
            });
          }
        });
      }
    }
  };

  // Initialize chart on mount
  useEffect(() => {
    chartRef.current = createChart(chartContainerRef.current, {
      layout: { background: { type: 'solid', color: 'white' } },
      timeScale: { timeVisible: true, rightOffset: 10 },
      height: 290,
      width: chartContainerRef.current ? chartContainerRef.current.offsetWidth : 600,
      //   width: isCollapsed
      // ? chartContainerRef.current ? chartContainerRef.current.offsetWidth : '100%'
      // : chartContainerRef.current ? chartContainerRef.current.offsetWidth : 600,
      crosshair: {
        horizontalLine: { visible: false },
        verticalLine: { visible: true },
      },
    });

    chartRef.current.priceScale('right').applyOptions({
      textColor: 'white',
    });
    if (shouldInvert) {
      chartRef.current.applyOptions({ invertScale: true });
    }
    chartRef.current.applyOptions({
      localization: {
        priceFormatter: (price) => (price / 1_000_000).toLocaleString('en-US', { maximumFractionDigits: 2 }),
      },
      priceScale: { mode: 0 },
    });

    const init = async () => {
      await fetchHistoricalData();
      await fetchCurrentData();
    };
    init();

    const intervalID = setInterval(() => {
      fetchCurrentData();
    }, 10_000);

    return () => {
      clearInterval(intervalID);
      if (chartRef.current) {
        chartRef.current.remove();
        chartRef.current = null;
      }
    };
  }, [market, type]);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleSeriesVisibility = (symbol) => {
    const series = lineSeriesRef.current.get(symbol);
    if (series) {
      const currentVisible = series.options().visible ?? true;
      series.applyOptions({ visible: !currentVisible });
    }
  };


  function renderTable(startIndex, endIndex) {
    const dataSlice = tableData.sort((a, b) => a.rank - b.rank).slice(startIndex, endIndex);
    return (
      <table className="rank-table" style={{
        fontSize: '11.5px',
        fontFamily: 'Arial, Helvetica, sans-serif',
        border: '1px solid #ddd',
        borderCollapse: 'collapse',
      }}>
        <thead>
          <tr style={{ zIndex: isMobile ? 1 : 0 }}>
            <th style={{ textAlign: 'center', padding: '3px', backgroundColor: '#fdf5e6' }}>Rank</th>
            <th style={{ textAlign: 'center', padding: '3px', backgroundColor: '#fdf5e6' }}>Symbol</th>
            <th style={{ textAlign: 'center', padding: '3px', backgroundColor: '#fdf5e6' }}>Value</th>
          </tr>
        </thead>
        <tbody>
          {dataSlice.map((row, idx) => (
            <tr
              key={idx}
              style={{
                backgroundColor: row.color,
                cursor: 'pointer',
                fontWeight: row.symbol === selectedSymbol ? 'bold' : 'normal',
                borderBottom: '1px solid #ddd'
              }}
              onClick={() => handleRowClick(row.symbol)}
            >
              <td style={{ padding: '3px', textAlign: 'center' }}>{row.rank}</td>
              <td style={{ padding: '3px', textAlign: 'center' }}>{row.symbol}</td>
              <td style={{ padding: '3px', textAlign: 'center' }}>{(row.value / 1_000_000).toLocaleString('en-US', { maximumFractionDigits: 2 })}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }



  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed); // เปลี่ยนสถานะพับ/ขยาย
  };

  // Dynamic Chart Width

  useEffect(() => {
    if (!chartContainerRef.current || !chartRef.current) return;

    const updateChartSize = () => {
      if (chartRef.current) {
        chartRef.current.resize(
          chartContainerRef.current.offsetWidth,
          chartContainerRef.current.offsetHeight
        );
      }
    };

    // ปรับขนาดเมื่อ isCollapsed เปลี่ยน
    updateChartSize();

    // สร้าง ResizeObserver
    const resizeObserver = new ResizeObserver(() => {
      requestAnimationFrame(updateChartSize);
    });

    resizeObserver.observe(chartContainerRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [isCollapsed]);

  //


  const onChartTouch = (event, allowTouchMove) => {
    if (chartTableContainerRef.current && chartTableContainerRef.current.contains(event.target)) {
      window.mySwiper.allowTouchMove = allowTouchMove;
    }
  };


  return (
    <div className="chart-section" style={{ fontFamily: 'Arial,sans-serif' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <h3 style={{
          display: 'flex', alignItems: 'center', justifyContent: 'space-between', flex: '1',
          // paddingRight: isMobile ? '15vw' : '0'
        }}>
          {/** chart Title */}
          {title}
          <button
            onClick={() => {
              const url = `/fullscreenmoneyinout?market=${market}&type=${type}&title=${encodeURIComponent(title)}`;
              window.open(url, '_blank');
            }}
            className="expand-button"
          >
            🔍
          </button>
        </h3>

        {/* Icon พับเก็บ Table สำหรับ Mobile และ Tablet */}
        {(isMobile || isTablet) && (<IconButton
          variant="contained"
          onClick={toggleCollapse}
        // sx={{ marginBottom: '10px' }}
        >
          {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </IconButton>)}
        {/* จบ Icon พับเก็บ */}
      </Box>

      <div className="chart-table-container" ref={chartTableContainerRef} style={{ display: 'flex', flexDirection: 'row', gap: isMobile ? '2vw' : '5px', justifyContent: 'center' }}>
        <div ref={chartContainerRef} style={{ width: isCollapsed ? '100%' : '60%', position: 'relative' }}
          onTouchStart={(event) => onChartTouch(event, false)}
          onTouchEnd={(event) => onChartTouch(event, true)}
        >

        </div>
        {/* โค้ดเก่าอยู่นี่ครับ */}
        {/* <div style={{ width: '20%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
          {renderTable(0, 10)}
        </div>
        <div style={{ width: '20%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
          {renderTable(10, 20)}
        </div> */}

        {/* โค้ดใหม่  */}

        {(!isMobile && !isTablet) && (
          <>
            {/* หากเข้าสู่โหมด Desktop */}
            <div style={{ width: '20%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
              {renderTable(0, 10)}
            </div>
            <div style={{ width: '20%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
              {renderTable(10, 20)}
            </div>
          </>
        )}
        {(isMobile || isTablet) && (
          <>
            {/* หากเข้าสู่โหมด Mobile */}
            {/* <div style={{ display: 'flex', flexDirection: 'column', maxHeight: '250px', overflowY: 'auto' }}>
              {renderTable(0, 20)}
            </div> */}

            {!isCollapsed && (<Box sx={{
              display: 'flex',
              flexDirection: 'column',
              maxHeight: !isTablet ? '30vh' : '230px',
              overflowY: 'auto',
              '::-webkit-scrollbar': {
                width: '5px',
              },
              '::-webkit-scrollbar-thumb': {
                backgroundColor: '#888',
                borderRadius: '4px',
              },
              '::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#555',
              },
            }}>
              {renderTable(0, 20)}
            </Box>)}

          </>
        )
        }

      </div>
    </div>
  );
};


// ---------------------------------------------
// Parent: collects the rank improvements from all ChartSections
// ---------------------------------------------
const MoneyInOut = () => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isPTablet = useIsPortraitTablet();

  // 1) global array
  if (!window.__globalRankChanges__) {
    window.__globalRankChanges__ = [];
  }

  // 2) define sendTelegramMessage
  async function sendTelegramMessage(msg) {
    try {
      const botToken = '7686389843:AAGQTUjCcBTq3xQZh0hS3_B9SUnVv4u8g40';
      const chatId = '-1002304211612';
      const url = `https://api.telegram.org/bot7686389843:AAGQTUjCcBTq3xQZh0hS3_B9SUnVv4u8g40/sendMessage`;
      const resp = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ chat_id: chatId, text: msg }),
      });
      const data = await resp.json();
      console.log('Telegram response:', data);
    } catch (err) {
      console.error('Failed to send Telegram message:', err);
    }
  }

  // 3) Periodically merge & send all improvements
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (window.__globalRankChanges__.length > 0) {
        // build final message
        let lines = [];
        lines.push("Moneyinout Rank Change");
        lines.push("------------------------------------");

        // group by chartTitle
        const byChart = {};
        window.__globalRankChanges__.forEach((chg) => {
          if (!byChart[chg.chartTitle]) {
            byChart[chg.chartTitle] = [];
          }
          byChart[chg.chartTitle].push(chg);
        });

        Object.keys(byChart).forEach((chartTitle) => {
          lines.push(chartTitle);
          byChart[chartTitle].forEach((chg) => {
            lines.push(`${chg.symbol} ${chg.oldRank} to ${chg.newRank} value ${chg.value.toLocaleString('en-US', { maximumFractionDigits: 2 })}`);
          });
          lines.push("------------------------------------");
        });

        const finalMsg = lines.join("\n");
        sendTelegramMessage(finalMsg);

        // clear
        window.__globalRankChanges__ = [];
      }
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (!window.mySwiper) {
      window.mySwiper = null;
    }
  }, []);



  return (
    <div
      className="dashboard"
      style={{
        display: 'flex',
        justifyContent: 'center',
        padding: (isMobile || isTablet) ? '10px' : '20px',
        fontFamily: 'Arial, sans-serif',
      }}
    >
      <div style={{ maxWidth: '1800px', width: '100%' }}>
        <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>
          ideatradepoint realtime
        </h1>
        <div
          className="sections"
          style={{
            display: 'grid',
            gridTemplateColumns: (isMobile || isTablet) ? '1fr' : '1fr 1fr',
            gap: '20px'
          }}
        >
          {/* โค้ดเก่า */}
          {/* SET100 and NON-SET100 Money In (+) */}
          {/* <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <ChartSectionWithMultipleLines title="SET100 Up (+)" market="SET100" type="in" />
            <ChartSectionWithMultipleLines title="NON-SET100 Up (+)" market="NON-SET100" type="in" />
          </div> */}

          {/* SET100 and NON-SET100 Money Out (-) */}
          {/* <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <ChartSectionWithMultipleLines title="SET100 Down (-)" market="SET100" type="out" />
            <ChartSectionWithMultipleLines title="NON-SET100 Down (-)" market="NON-SET100" type="out" />
          </div> */}

          {/* MAI and WARRANT Money In (+) */}
          {/* <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <ChartSectionWithMultipleLines title="MAI Up (+)" market="MAI" type="in" />
            <ChartSectionWithMultipleLines title="WARRANT Up (+)" market="WARRANT" type="in" />
          </div> */}

          {/* MAI and WARRANT Money Out (-) */}
          {/* <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <ChartSectionWithMultipleLines title="MAI Down (-)" market="MAI" type="out" />
            <ChartSectionWithMultipleLines title="WARRANT Down (-)" market="WARRANT" type="out" />
          </div> */}

          {/* โค้ดใหม่ */}

          {(!isMobile && !isTablet) && (
            <>
              {/* หากเข้าสู่ Desktop mode */}
              <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <ChartSectionWithMultipleLines title="SET100 Up (+)" market="SET100" type="in" />
                <ChartSectionWithMultipleLines title="NON-SET100 Up (+)" market="NON-SET100" type="in" />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <ChartSectionWithMultipleLines title="SET100 Down (-)" market="SET100" type="out" />
                <ChartSectionWithMultipleLines title="NON-SET100 Down (-)" market="NON-SET100" type="out" />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <ChartSectionWithMultipleLines title="MAI Up (+)" market="MAI" type="in" />
                <ChartSectionWithMultipleLines title="WARRANT Up (+)" market="WARRANT" type="in" />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <ChartSectionWithMultipleLines title="MAI Down (-)" market="MAI" type="out" />
                <ChartSectionWithMultipleLines title="WARRANT Down (-)" market="WARRANT" type="out" />
              </div>
            </>
          )}
          {(isTablet && !isPTablet) && (
            <Swiper
              style={{ width: '100%', maxWidth: '1200px' }}
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={20}
              slidesPerView={1}
              pagination={{
                clickable: true,
                dynamicBullets: true,
              }}
              onSwiper={(swiper) => {
                window.mySwiper = swiper;
              }}
            >
              {/* Slide 1 */}
              <SwiperSlide>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)', // 2 columns ใน Tablet
                    gap: '10px', // ระยะห่างระหว่าง Grid
                  }}
                >
                  <ChartSectionWithMultipleLines title="SET100 Up (+)" market="SET100" type="in" />
                  <ChartSectionWithMultipleLines title="NON-SET100 Up (+)" market="NON-SET100" type="in" />
                  <ChartSectionWithMultipleLines title="SET100 Down (-)" market="SET100" type="out" />
                  <ChartSectionWithMultipleLines title="NON-SET100 Down (-)" market="NON-SET100" type="out" />
                </div>
              </SwiperSlide>

              {/* Slide 2 */}
              <SwiperSlide>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)', // 2 columns ใน Tablet
                    gap: '10px',
                  }}
                >
                  <ChartSectionWithMultipleLines title="MAI Up (+)" market="MAI" type="in" />
                  <ChartSectionWithMultipleLines title="WARRANT Up (+)" market="WARRANT" type="in" />
                  <ChartSectionWithMultipleLines title="MAI Down (-)" market="MAI" type="out" />
                  <ChartSectionWithMultipleLines title="WARRANT Down (-)" market="WARRANT" type="out" />
                </div>
              </SwiperSlide>
            </Swiper>
          )}
          {(isMobile || isPTablet) && (
            <>
              {/* หากเข้าสู่ Mobile mode */}
              <Swiper
                style={{ width: '100%', maxWidth: '600px' }}
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={50}
                slidesPerView={1}
                pagination={{
                  clickable: true, dynamicBullets: true,
                }}
                onSwiper={(swiper) => {
                  window.mySwiper = swiper;
                }}
              >
                <SwiperSlide>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <ChartSectionWithMultipleLines title="SET100 Up (+)" market="SET100" type="in" />
                    <ChartSectionWithMultipleLines title="SET100 Down (-)" market="SET100" type="out" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <ChartSectionWithMultipleLines title="NON-SET100 Up (+)" market="NON-SET100" type="in" />
                    <ChartSectionWithMultipleLines title="NON-SET100 Down (-)" market="NON-SET100" type="out" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <ChartSectionWithMultipleLines title="MAI Up (+)" market="MAI" type="in" />
                    <ChartSectionWithMultipleLines title="MAI Down (-)" market="MAI" type="out" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <ChartSectionWithMultipleLines title="WARRANT Up (+)" market="WARRANT" type="in" />
                    <ChartSectionWithMultipleLines title="WARRANT Down (-)" market="WARRANT" type="out" />
                  </div>
                </SwiperSlide>

              </Swiper>

              {/* <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <ChartSectionWithMultipleLines title="SET100 Up (+)" market="SET100" type="in" />
                <ChartSectionWithMultipleLines title="SET100 Down (-)" market="SET100" type="out" />
              </div> */}
              {/* <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
    <ChartSectionWithMultipleLines title="NON-SET100 Up (+)" market="NON-SET100" type="in" />
        <ChartSectionWithMultipleLines title="NON-SET100 Down (-)" market="NON-SET100" type="out" />
    </div> */}
            </>
          )
          }
        </div>
      </div>
    </div>
  );
};

export default MoneyInOut;
