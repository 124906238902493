
import React, { useEffect, useRef, useState } from 'react';
import { createChart, LineStyle, CrosshairMode, PriceScaleMode } from 'lightweight-charts';
import { useSelector } from "react-redux";

import { TextField, Container, InputAdornment, CircularProgress, Grid, Button } from "@mui/material";
import { __fetching_data__ } from '../../components/api';

var selectedTimezone = 'Asia/Bangkok';
var uniqueRandomColors = new Array;
const App = ({ market, type }) => {
  const chartContainerRef = useRef(null);
  const chartRef = useRef(null);
  const lineSeriesRef = useRef(new Map());
  const [tableData, setTableData] = useState([]);
  const tableDataRef = useRef([]);
  const [previousRanks, setPreviousRanks] = useState({});
  const previousRanksRef = useRef(previousRanks);
  const [lastRankChange, setLastRankChange] = useState({});
  const lastRankChangeRef = useRef(lastRankChange);
  const seriesDataRef = useRef(new Map());
  const isMounted = useRef(true);
  const originalColorsRef = useRef(new Map());
  const priceLinesRef = useRef(new Map());
  const csrfToken = useSelector((state) => state.csrfToken);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    previousRanksRef.current = previousRanks;
  }, [previousRanks]);

  useEffect(() => {
    lastRankChangeRef.current = lastRankChange;
  }, [lastRankChange]);

  useEffect(() => {
    tableDataRef.current = tableData;
  }, [tableData]);

  const getTodayDateString = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const adjustToLocalTime = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const timezoneOffset = date.getTimezoneOffset() * 60;
    return timestamp - timezoneOffset;
  };

  const fetchHistoricalData = async () => {
    try {
      const fetch_response = await fetch(`${window.config.base_api_url}/money_in_out/today/${market}`, {
        headers: {
          'Content-Type': 'application/json',
          'xsrf-token': csrfToken
        },
      });

      const response = await fetch_response.json();
      console.log(response)

      const data = response.data[type];
      const historicalData = {};
      const todayDateString = getTodayDateString();
      const times = Object.keys(data.rank);
      times.sort();
      const latestTime = times[times.length - 1];
      const latestSymbols = Object.keys(data.rank[latestTime]);

      times.forEach((time) => {
        Object.keys(data.rank[time]).forEach((symbol) => {
          if (!historicalData[symbol]) {
            historicalData[symbol] = [];
          }
          const dateTimeString = `${todayDateString}T${time}`;
          const timestamp = Math.floor(new Date(dateTimeString).getTime() / 1000);

          historicalData[symbol].push({
            time: adjustToLocalTime(timestamp),
            rank: data.rank[time][symbol],
            value: data.value[time][symbol],
          });
        });
      });

      const newPreviousRanks = {};
      const newLastRankChange = {};
      latestSymbols.forEach((symbol) => {
        const symbolData = historicalData[symbol];

        if (!symbolData || symbolData.length === 0) {
          newPreviousRanks[symbol] = null;
          newLastRankChange[symbol] = null;
          return;
        }

        symbolData.sort((a, b) => a.time - b.time);
        let lastRankChangeRank = symbolData[0].rank;
        for (let i = 1; i < symbolData.length; i++) {
          if (symbolData[i].rank !== symbolData[i - 1].rank) {
            lastRankChangeRank = symbolData[i - 1].rank;
          }
        }

        const latestRank = symbolData[symbolData.length - 1].rank;

        newPreviousRanks[symbol] = latestRank;
        newLastRankChange[symbol] = lastRankChangeRank;

        let series = lineSeriesRef.current.get(symbol);
        if (!series) {
          const color = getRandomColor();
          series = chartRef.current.addLineSeries({
            color: color,
            lineWidth: 2,
            lastValueVisible: false,
            priceLineVisible: false
          });
          lineSeriesRef.current.set(symbol, series);
          originalColorsRef.current.set(symbol, color);
        }

        const seriesData = symbolData.map((dataPoint) => ({
          time: dataPoint.time,
          value: dataPoint.value,
        }));
        series.setData(seriesData);
        seriesDataRef.current.set(symbol, seriesData);

        const lastDataPoint = seriesData[seriesData.length - 1];
        if (lastDataPoint) {
          if (priceLinesRef.current.has(symbol)) {
            const oldPriceLine = priceLinesRef.current.get(symbol);
            series.removePriceLine(oldPriceLine);
          }

          const priceLine = series.createPriceLine({
            price: lastDataPoint.value,
            color: series.options().color,
            lineWidth: 1,
            lineStyle: LineStyle.Solid,
            axisLabelVisible: true,
            title: symbol,
            lineVisible: false,
          });
          priceLinesRef.current.set(symbol, priceLine);
        }
      });

      setPreviousRanks(newPreviousRanks);
      setLastRankChange(newLastRankChange);

      const allSymbols = Array.from(lineSeriesRef.current.keys());
      const symbolsToRemove = allSymbols.filter(
        (symbol) => !latestSymbols.includes(symbol)
      );

      symbolsToRemove.forEach((symbol) => {
        const series = lineSeriesRef.current.get(symbol);
        if (series) {
          const priceLine = priceLinesRef.current.get(symbol);
          if (priceLine) {
            series.removePriceLine(priceLine);
            priceLinesRef.current.delete(symbol);
          }
          chartRef.current.removeSeries(series);
        }
        lineSeriesRef.current.delete(symbol);
        seriesDataRef.current.delete(symbol);
        originalColorsRef.current.delete(symbol);
      });


      latestSymbols.forEach((symbol) => {
        if (!lineSeriesRef.current.has(symbol)) {
          const color = getRandomColor();
          lineSeriesRef.current.set(
            symbol,
            chartRef.current.addLineSeries({
              color: color,
              lineWidth: 2,
            })
          );
          originalColorsRef.current.set(symbol, color);
        }
        const seriesData = historicalData[symbol].map((dataPoint) => ({
          time: dataPoint.time,
          value: dataPoint.value,
        }));
        lineSeriesRef.current.get(symbol).setData(seriesData);
        seriesDataRef.current.set(symbol, seriesData);
      });

      const updatedTableData = latestSymbols.map((symbol) => {
        const symbolData = historicalData[symbol];
        const latestData = symbolData[symbolData.length - 1];
        const latestRank = latestData.rank;
        const lastChangeRank = newLastRankChange[symbol];

        let color = 'transparent';
        if (lastChangeRank != null) {
          if (latestRank < lastChangeRank) {
            color = '#ccffcc';
          } else if (latestRank > lastChangeRank) {
            color = '#ffcccc';
          }
        }
        return {
          rank: latestRank,
          symbol,
          value: latestData.value,
          color,
        };
      });

      setTableData(updatedTableData);

    } catch (error) {
      console.error(`Error fetching historical data for ${market}:`, error);
    }
  };

  const fetchCurrentData = async () => {
    try {
      if (Object.keys(previousRanksRef.current).length === 0) {
        console.warn('Previous ranks not initialized, skipping rank comparison.');
        return;
      }

      const fetch_response = await fetch(`${window.config.base_api_url}/money_in_out/now/${market}`, {
        headers: {
          'Content-Type': 'application/json',
          'xsrf-token': csrfToken
        },
      });
      const response = await fetch_response.json();


      const data = response.data[type];
      const timeKey = Object.keys(data.rank)[0];
      const todayDateString = getTodayDateString();
      const dateTimeString = `${todayDateString}T${timeKey}`;
      const timestamp = Math.floor(new Date(dateTimeString).getTime() / 1000);

      const latestSymbols = Object.keys(data.rank[timeKey]);

      const updatedTableData = latestSymbols.map((symbol) => {
        const currentRank = data.rank[timeKey][symbol];
        const lastChangeRank = lastRankChangeRef.current[symbol] || currentRank;

        const previousEntry = tableDataRef.current.find(
          (entry) => entry.symbol === symbol
        );
        const previousColor = previousEntry ? previousEntry.color : 'transparent';

        let color = previousColor;

        if (currentRank === lastChangeRank) {
          color = 'transparent';
          lastRankChangeRef.current = {
            ...lastRankChangeRef.current,
            [symbol]: currentRank,
          };
        } else {
          if (currentRank < lastChangeRank) {
            color = '#ccffcc';
          } else if (currentRank > lastChangeRank) {
            color = '#ffcccc';
          }
        }

        return {
          rank: currentRank,
          symbol,
          time: timeKey,
          value: data.value[timeKey][symbol],
          color,
        };
      });

      setTableData(updatedTableData);

      const newPreviousRanks = {};
      updatedTableData.forEach((row) => {
        newPreviousRanks[row.symbol] = row.rank;
      });
      setPreviousRanks(newPreviousRanks);
      previousRanksRef.current = newPreviousRanks;

      const newLastRankChange = {};
      latestSymbols.forEach((symbol) => {
        if (lastRankChangeRef.current.hasOwnProperty(symbol)) {
          newLastRankChange[symbol] = lastRankChangeRef.current[symbol];
        }
      });
      setLastRankChange(newLastRankChange);
      lastRankChangeRef.current = newLastRankChange;

      const allSymbols = Array.from(lineSeriesRef.current.keys());
      const symbolsToRemove = allSymbols.filter(
        (symbol) => !latestSymbols.includes(symbol)
      );

      symbolsToRemove.forEach((symbol) => {
        const series = lineSeriesRef.current.get(symbol);
        if (series) {
          chartRef.current.removeSeries(series);
        }
        lineSeriesRef.current.delete(symbol);
        seriesDataRef.current.delete(symbol);
        originalColorsRef.current.delete(symbol);
      });

      latestSymbols.forEach((symbol) => {
        const updatePoint = {
          time: adjustToLocalTime(timestamp),
          value: data.value[timeKey][symbol],
        };

        let series = lineSeriesRef.current.get(symbol);
        if (series) {
          series.update(updatePoint);

          const existingData = seriesDataRef.current.get(symbol) || [];

          if (
            existingData.length > 0 &&
            existingData[existingData.length - 1].time === updatePoint.time
          ) {
            existingData[existingData.length - 1] = updatePoint;
          } else {
            existingData.push(updatePoint);
          }

          seriesDataRef.current.set(symbol, existingData);
          if (priceLinesRef.current.has(symbol)) {
            const oldPriceLine = priceLinesRef.current.get(symbol);
            series.removePriceLine(oldPriceLine);
          }

          const newPriceLine = series.createPriceLine({
            price: updatePoint.value,
            color: series.options().color,
            lineWidth: 1,
            lineStyle: LineStyle.Solid,
            axisLabelVisible: true,
            title: symbol,
            lineVisible: false
          });

          priceLinesRef.current.set(symbol, newPriceLine);
        } else {
          const color = getRandomColor();
          series = chartRef.current.addLineSeries({
            color,
            lineWidth: 2,
            lastValueVisible: false,
            priceLineVisible: false
          });
          series.setData([updatePoint]);
          lineSeriesRef.current.set(symbol, series);
          originalColorsRef.current.set(symbol, color);
          seriesDataRef.current.set(symbol, [updatePoint]);

          const priceLine = series.createPriceLine({
            price: updatePoint.value,
            color: series.options().color,
            lineWidth: 1,
            lineStyle: LineStyle.Solid,
            axisLabelVisible: true,
            title: symbol,
            lineVisible: false,
          });
          priceLinesRef.current.set(symbol, priceLine);
        }
      });

    } catch (error) {
      console.error(`Error fetching current data for ${market}:`, error);
    }
  };

  const renderTable = (startIndex, endIndex) => (
    <table className="rank-table" style={{ fontSize: '11.5px', fontFamily: 'Arial, sans-serif' }}>
      <thead>
        <tr>
          <th>No.</th>
          <th>Symbol</th>
        </tr>
      </thead>
      <tbody>
        {tableData
          .sort((a, b) => a.rank - b.rank)
          .slice(startIndex, endIndex)
          .map((row, index) => (
            <tr
              key={index}
              style={{
                backgroundColor: row.color,
                cursor: 'pointer',
                fontWeight: 'normal',
              }}
            >
              <td>{row.rank}</td>
              <td value={inputValue} onClick={handleSendMessage}>{row.symbol}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );

  useEffect(() => {
    chartRef.current = createChart(chartContainerRef.current, {
      layout: { textColor: 'black', background: { type: 'solid', color: 'white' } },
      timeScale: { timeVisible: true },
      height: 250,
      width: chartContainerRef.current ? chartContainerRef.current.offsetWidth : 600,
      crosshair: {
        horizontalLine: {
          visible: false,
        },
        verticalLine: {
          visible: true,
        },
      },
    });

    chartRef.current.applyOptions({
      localization: {
        priceFormatter: (price) => (price / 1_000_000).toLocaleString('en-US', { maximumFractionDigits: 2 }),
      },
      priceScale: {
        mode: 0,
      },
    });

    const initializeData = async () => {
      await fetchHistoricalData();
      await fetchCurrentData();
    };
    initializeData();

    const intervalID = setInterval(() => {
      fetchCurrentData();
    }, 10000);

    return () => {
      clearInterval(intervalID);
      if (chartRef.current) {
        chartRef.current.remove();
        chartRef.current = null;
      }
    };
  }, [market, type]);
  ////////////////////

  const userDetail = useSelector((state) => state.userDetail);
  const base_api_url =
    process.env.NODE_ENV === "production"
      ? window.location.protocol === "https:"
        ? process.env.REACT_APP_API_HTTPS_URL
        : process.env.REACT_APP_API_HTTP_URL
      : "/application";
  const [isMessageSent, setIsMessageSent] = useState(false);
  const [messages, setMessages] = useState([]);
  const [points, setPoints] = useState([])
  const [inputValue, setInputValue] = useState('');
  const resChatBoxRef = useRef(null);

  useEffect(() => {
    scrollToBottomChatbot()
  }, [isMessageSent]);
  const scrollToBottomChatbot = () => {
    resChatBoxRef.current && (resChatBoxRef.current.scrollTop = resChatBoxRef.current.scrollHeight);
  }
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const LoadingIndicator = () => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <CircularProgress sx={{ color: 'red' }} />
    </div>
  );
  const responses = [];
  const handleSendMessage = async () => {
    setIsMessageSent(true);
    if (!inputValue.trim()) return;
    setInputValue('');
    const chatbox = document.getElementById('chatbox');
    var value = {
      user_input: inputValue
    };
    const response = await __fetching_data__({ url: `${base_api_url}/chat_bot_report`, method: "POST", body: JSON.stringify(value) });
    if (response.status) {
      window.data = response.data;
      window.bdata = response.bdata;
      if (response.data.bot_response) {
        responses.push(response.data);
      } else if (response.data.plot_data) {
        const responseContainer = displayPlot(response.data, response.bdata);
        chatbox.appendChild(responseContainer);
      } else if (response.data.index_data) {
        setChartplot(response.data)
      } else if (response.data.marketsummary_data) {
        marketSummaryPlot(response.data);
      } else if (response.data.petroleum_data) {
        petroPlot(response.data);
      } else if (response.data.signal_response) {
        signalResponse(response.data)
      }
    }
    setIsMessageSent(false);
  };
  const displayPlot = (response, bresponse, sense = 0.05) => {
    const chatbox = document.getElementById('chatbox');
    const res_chat_box = document.getElementById('resChatBox');
    const plotData = response.plot_data;
    const bData = bresponse
    const symbol = plotData['symbol'];
    window.plotData = plotData
    console.log('bdata', bData)
    const responseContainer = document.createElement('div');
    const containerWidth = 'auto';
    const containerHeight = 'auto';
    responseContainer.classList.add('response-container');
    responseContainer.style.display = 'grid';
    responseContainer.style.padding = '5px';
    responseContainer.style.gridTemplateColumns = `repeat(3, ${containerWidth})`;
    const chartWidth = chatbox.offsetWidth / 3.1;
    const chartHeight = chatbox.offsetHeight / 4;
    const chartOption = { width: chartWidth, height: chartHeight, crosshair: { mode: 0, }, timeScale: { visible: true }, layout: { background: { type: 'solid', color: '#f5f5f5' } } }

    function chartAnnotation(chart, text) {
      chart.applyOptions({
        watermark: {
          visible: true,
          fontSize: 24,
          horzAlign: 'left',
          vertAlign: 'top',
          color: 'rgb(0,0,0)',
          text: text,
        },
        rightPriceScale: {
          visible: true,
        },
        leftPriceScale: {
          visible: true,
          mode: PriceScaleMode.Normal,
          autoScale: false,
          invertScale: false,
          alignLabels: false,
          minValue: -1,
          maxValue: 1,
        },
      });

    }
    //Chart 1
    function generateChartLast() {
      const chartContainer = document.createElement('div');
      chartContainer.classList.add('chart-container');

      chartContainer.setAttribute('id', `chart-1`)
      const chart = createChart(chartContainer, chartOption);
      chartAnnotation(chart, 'Last')

      const headerText = document.createElement('div');
      headerText.textContent = 'Last';

      const addDivHeader = document.createElement('div')
      addDivHeader.setAttribute('class', 'divHeader');
      addDivHeader.appendChild(headerText)
      chartContainer.appendChild(addDivHeader);

      const sumVal = plotData['price'].reduce((acc, dict) => {
        if ('value' in dict) {
          return acc + dict['value'];
        }
        return acc;
      }, 0);

      let close_series = [];
      if (sumVal === 0 || plotData['price'].length <= 1) {
        chart.applyOptions({
          watermark: {
            visible: true,
            fontSize: 14,
            horzAlign: 'center',
            vertAlign: 'center',
            color: 'grey',
            text: "Last:None",
          },
        });
      } else {
        close_series = chart.addLineSeries({ title: '', color: 'green', lastValueVisible: true, priceLineVisible: false, priceScaleId: 'right', CrosshairMarkerVisible: false })
        chart.applyOptions({
          watermark: {
            visible: true,
            fontSize: 48,
            horzAlign: 'center',
            vertAlign: 'center',
            color: 'rgba(0, 0, 0, 0.1)',
            text: `${inputValue}`,
          },
        });
        chart.applyOptions({
          priceScale: {
            position: 'left',
            mode: 1,
            autoScale: true,
            invertScale: false,
            alignLabels: true,
            borderVisible: true,
            entireTextOnly: true,
          },
        });
        const bar1 = chart.addAreaSeries({
          topColor: 'rgba(255, 99, 71, 1)',
          bottomColor: 'rgba(255, 99, 71, 0.8)',
          lineStyle: 0,
          crosshairMarkerVisible: false,
        });
        let lst1 = []
        let lst2 = []
        let lst3 = []
        let lst4 = []

        const bar2 = chart.addAreaSeries({
          topColor: 'rgba(60, 179, 113, 1)',
          bottomColor: 'rgba(60, 179, 113, 0)',
          lineStyle: 0,
          crosshairMarkerVisible: false,
        });

        const bar3 = chart.addAreaSeries({
          topColor: 'rgba(255, 165, 0, 0.6)',
          bottomColor: 'rgba(255, 165, 0, 0)',
          crosshairMarkerVisible: false,
        });

        const dataForSeries = generateData(plotData['price'], 'time', 'value');
        close_series.setData(dataForSeries);
      }

      return [chart, [close_series], chartContainer]
    }
    //Chart 2
    function generateChartFlow(sense = 0.05) {
      const chartContainer = document.createElement('div');
      chartContainer.classList.add('chart-container');
      chartContainer.classList.add('flow-chart');
      let flow_series = [];
      let markers = []

      const filterContainer = document.createElement('div');
      filterContainer.setAttribute('style', 'position: relative; overflow: visible; z-index:3;')
      const buttonContainer = document.createElement('div');
      buttonContainer.setAttribute('style', 'padding-left: 0%; position: absolute; z-index: 1;"')

      filterContainer.appendChild(buttonContainer);
      chartContainer.appendChild(filterContainer);

      const chart = createChart(chartContainer, chartOption);
      chart.applyOptions({
        crosshair: {
          mode: CrosshairMode.Normal,
          horzLine: {
            labelVisible: false
          },
        }
      })
      chart.priceScale('right').applyOptions({
        visible: false,
        textColor: '#F5F5F5',
      });
      chart.priceScale('left').applyOptions({
        visible: false,
        textColor: '#F5F5F5'

      });
      chartAnnotation(chart, 'Predicted Trend');

      const headerText = document.createElement('div');
      headerText.textContent = 'Predicted Trend';

      const addDivHeader = document.createElement('div')
      addDivHeader.setAttribute('class', 'divHeader');
      addDivHeader.appendChild(headerText)
      chartContainer.appendChild(addDivHeader);

      const sumVal = plotData['flow_accum'].reduce((acc, dict) => {
        if ('value' in dict) {
          return acc + dict['value'];
        }
        return acc;
      }, 0);

      if (sumVal === 0 || plotData['flow_accum'].length <= 1) {
        chart.applyOptions({
          watermark: {
            visible: true,
            fontSize: 14,
            horzAlign: 'center',
            vertAlign: 'center',
            color: 'grey',
            text: "Flow:None",
          },
        });
      } else {


        const series3_1 = chart.addLineSeries({ lineStyle: 0, lineWidth: 3, lastValueVisible: false, priceLineVisible: false, });
        chart.applyOptions({
          watermark: {
            visible: true,
            fontSize: 48,
            horzAlign: 'center',
            vertAlign: 'center',
            color: 'rgba(0, 0, 0, 0.1)',
            text: `${inputValue}`,
          },

        });
        const dataForSeries_1 = generateData(plotData['flow_accum'], 'time', 'value');
        series3_1.setData(dataForSeries_1);

        series3_1.applyOptions({
          priceFormat: {
            type: 'volume',
          },
        });
        function removeSeriesOutside() {
          if (chart && flow_zigzag) {
            chart.removeSeries(flow_zigzag);
          }
        }

        console.log('marker', response)
        flow_series = [series3_1]
        let max_data = plotData['max_peaks']
        const flow_value = plotData['flow_accum'].map(items => items.value)

        console.log('points', points)
        var point_data = response['plot_data']['point_data'].filter(e => e[8] == 'predicted_trend')
        if (point_data) {
          for (var i = 0; i < point_data.length; i++) {
            var point = point_data[i]
            let colorCode = ''
            let charCode = ''
            let barPosition = ''
            if (point[9] == 'A') {
              colorCode = '#008000'
              charCode = 'U'
              barPosition = 'aboveBar'
            } else if (point[9] == 'V') {
              colorCode = '#FF0000'
              charCode = 'D'
              barPosition = 'belowBar'
            }
            markers.push({
              'time': parseInt(point[12]),
              position: barPosition,
              color: colorCode,
              shape: 'circle',
              text: charCode
            })
          }
        }
        function compare(a, b) {
          if (a.time < b.time) {
            return -1;
          }
          if (a.time > b.time) {
            return 1;
          }
          return 0;
        }
        markers.sort(compare)
        series3_1.setMarkers(markers)

        let isControl = false
        window.addEventListener('keydown', (event) => {
          if (event.ctrlKey) {
            isControl = true
            if (noti_group_id == '') {
              noti_group_id = generateGuid()
            }
          }
        });
        window.addEventListener('keyup', (event) => {
          isControl = false
          noti_group_id = ''
        });

        chart.subscribeDblClick((param) => {
          console.log('plot', max_data)
          let max_peaks = max_data['price']['max']
          let min_peaks = max_data['price']['min']
          let b = false
          for (let i = 0; i < markers.length; i++) {
            let obj = markers[i];
            if (param['time'] == obj['time']) {
              b = true;
              markers.splice(i, 1)
              let valueSelect = ''
              for (let [key, value] of param['seriesData']) {
                valueSelect = value['value']
              }
              __fetching_data__({
                url: `${base_api_url}/chat_bot_save_point`, method: "POST", body: JSON.stringify(
                  {
                    "chart_name": "predicted_trend",
                    "value": valueSelect,
                    "method": "del",
                    "timestamp": param['time']
                  }
                )
              }).then((res) => {
                console.log('res', res)
              })
            }
          }
          if (!b) {
            console.log('Hello')
            let bMax = false
            let bMin = false
            let valueSelect = ''
            let valuePosition = ''
            let barPosition = ''
            for (let [key, value] of param['seriesData']) {
              if (max_peaks.includes(value['value'])) {
                valueSelect = value['value']
                valuePosition = 'A'
                bMax = true
                barPosition = 'aboveBar'
              } else if (min_peaks.includes(value['value'])) {
                valueSelect = value['value']
                valuePosition = 'V'
                bMin = true
                barPosition = 'belowBar'
              }
            }
            let colorCode = ''
            let charCode = ''
            if (isControl) {
              if (bMax) {
                colorCode = '#00FF00'
                charCode = 'U'
              } else if (bMin) {
                colorCode = '#7b241c'
                charCode = 'D'
              }
            } else {
              if (bMax) {
                colorCode = '#008000'
                charCode = 'U'
              } else if (bMin) {
                colorCode = '#FF0000'
                charCode = 'D'
              }
            }
            markers = [...markers, {
              'time': param['time'],
              position: barPosition,
              color: colorCode,
              shape: 'circle',
              text: charCode
            }]

            __fetching_data__({
              url: `${base_api_url}/chat_bot_save_point`, method: "POST", body: JSON.stringify(
                {
                  "chart_name": "predicted_trend",
                  "value": valueSelect,
                  "position": valuePosition,
                  "last_value": flow_value[flow_value.length - 1],
                  "method": "add",
                  "symbol": plotData['symbol'],
                  "timestamp": param['time'],
                  'group_id': noti_group_id
                }
              )
            }).then((res) => {
              console.log('res', res)
            })
          }
          console.log('time', markers)
          function compare(a, b) {
            if (a.time < b.time) {
              return -1;
            }
            if (a.time > b.time) {
              return 1;
            }
            return 0;
          }
          markers.sort(compare)
          series3_1.setMarkers(markers)
        });
      }
      return [chart, flow_series, chartContainer];
    }
    window.generateChartFlow = generateChartFlow;
    function generateChartShort() {
      const chartContainer = document.createElement('div');
      chartContainer.classList.add('chart-container');
      const chart = createChart(chartContainer, { ...chartOption, leftPriceScale: { visible: true }, });
      chartAnnotation(chart, '%short');

      console.log("plotdata", plotData)
      const sumVal = plotData['short'].reduce((acc, dict) => {
        return 'value' in dict ? acc + dict['value'] : acc;
      }, 0);

      let short_series = [];
      let short_series1 = [];
      let markers = []

      if (sumVal === 0 || plotData['short'].length <= 1) {
        chart.applyOptions({
          watermark: {
            visible: true,
            fontSize: 14,
            horzAlign: 'center',
            vertAlign: 'center',
            color: 'grey',
            text: "%Short: None",
          },
        });
      } else {
        const dataForSeries = generateData(plotData['short'], 'time', 'value');
        short_series = chart.addLineSeries({ color: 'red', lastValueVisible: true, title: '% daily short', priceLineVisible: false });
        short_series.setData(dataForSeries);

        const dataForSeries1 = generateData(plotData['outstanding_short'], 'time', 'value');
        short_series1 = chart.addLineSeries({ color: 'green', lastValueVisible: true, title: 'outstandingshort', priceLineVisible: false, priceScaleId: 'left', });
        short_series1.setData(dataForSeries1);
        short_series1.applyOptions({
          priceFormat: {
            type: 'volume',
          }
        })

        chart.applyOptions({
          watermark: {
            visible: true,
            fontSize: 48,
            color: 'rgba(0, 0, 0, 0.1)',
            text: `${inputValue}`,
            horzAlign: 'center',
            vertAlign: 'center',
          },
        });

        chart.priceScale('left').applyOptions({
          autoScale: true,
          scaleMargins: { top: 0.1, bottom: 0.1 }
        })
      }
      const series = [short_series1, short_series]
      let titlesVisible = false;
      const toggleButton = document.createElement('button');
      toggleButton.textContent = 'Show/Hide All';
      const headerText = document.createElement('div');
      headerText.textContent = '%short';

      const addDivHeader = document.createElement('div')
      addDivHeader.setAttribute('class', 'divHeader');
      addDivHeader.appendChild(headerText)
      addDivHeader.appendChild(toggleButton)
      chartContainer.appendChild(addDivHeader);
      toggleButton.addEventListener('click', () => {
        titlesVisible = !titlesVisible;
        const buttonText = titlesVisible ? 'Hide/Show All' : 'Show/Hide All';
        toggleButton.textContent = buttonText;

        series.forEach(s => {
          if (s.options().title === 'outstandingshort' || s.options().title === '% daily short') {
            s.applyOptions({
              title: titlesVisible ? s.options().title : '',
            });
          } else if (s.options().title === '') {
            if (s === short_series) {
              s.applyOptions({

                title: titlesVisible ? '% daily short' : '',
              });
            } else if (s === short_series1) {
              s.applyOptions({
                title: titlesVisible ? 'outstandingshort' : '',
              });
            }
          }
        });
      });

      series.forEach(s => {
        if (s.length == 0) {
          return;
        }
        if (s.options().title === 'outstandingshort' || s.options().title === '% daily short') {
          s.applyOptions({
            title: titlesVisible ? s.options().title : '',
          });
        } else if (s.options().title === '') {
          if (s === short_series) {
            s.applyOptions({
              title: titlesVisible ? 'outstandingshort' : '',
            });
          } else if (s === short_series1) {
            s.applyOptions({
              title: titlesVisible ? '% daily short' : '',
            });
          }
        }
      });

      var point_data = response['plot_data']['point_data'].filter(e => e[8] == 'short')
      if (point_data) {
        for (var i = 0; i < point_data.length; i++) {
          var point = point_data[i]
          let colorCode = ''
          let charCode = ''
          let barPosition = ''
          if (point[9] == 'A') {
            colorCode = '#008000'
            charCode = 'U'
            barPosition = 'aboveBar'
          } else if (point[9] == 'V') {
            colorCode = '#FF0000'
            charCode = 'D'
            barPosition = 'belowBar'
          }
          markers.push({
            'time': parseInt(point[12]),
            position: barPosition,
            color: colorCode,
            shape: 'circle',
            text: charCode
          })
        }
      }
      function compare(a, b) {
        if (a.time < b.time) {
          return -1;
        }
        if (a.time > b.time) {
          return 1;
        }
        return 0;
      }
      markers.sort(compare)
      if (short_series1.length > 0) {
        short_series1.setMarkers(markers)
      }

      let max_data = plotData['max_peaks']
      const flow_value = plotData['flow_accum'].map(items => items.value)

      let isControl = false
      window.addEventListener('keydown', (event) => {
        if (event.ctrlKey) {
          isControl = true
          if (noti_group_id == '') {
            noti_group_id = generateGuid()
          }
        }
      });
      window.addEventListener('keyup', (event) => {
        isControl = false
        noti_group_id = ''
      });

      chart.subscribeDblClick((param) => {
        let max_peaks = max_data['outstanding_short']['max']
        let min_peaks = max_data['outstanding_short']['min']
        let b = false
        for (let i = 0; i < markers.length; i++) {
          let obj = markers[i];
          if (param['time'] == obj['time']) {
            b = true;
            markers.splice(i, 1)
            let valueSelect = ''
            for (let [key, value] of param['seriesData']) {
              valueSelect = value['value']
            }
            __fetching_data__({
              url: `${base_api_url}/chat_bot_save_point`, method: "POST", body: JSON.stringify(
                {
                  "chart_name": "short",
                  "value": valueSelect,
                  "method": "del",
                  "timestamp": param['time']
                }
              )
            }).then((res) => {
              console.log('res', res)
            })
          }
        }
        if (!b) {
          let bMax = false
          let bMin = false
          let valueSelect = ''
          let valuePosition = ''
          let barPosition = ''
          for (let [key, value] of param['seriesData']) {
            if (max_peaks.includes(value['value'])) {
              valueSelect = value['value']
              valuePosition = 'A'
              bMax = true
              barPosition = 'aboveBar'
            } else if (min_peaks.includes(value['value'])) {
              valueSelect = value['value']
              valuePosition = 'V'
              bMin = true
              barPosition = 'belowBar'
            }
          }
          let colorCode = ''
          let charCode = ''
          if (isControl) {
            if (bMax) {
              colorCode = '#00FF00'
              charCode = 'U'
            } else if (bMin) {
              colorCode = '#7b241c'
              charCode = 'D'
            }
          } else {
            if (bMax) {
              colorCode = '#008000'
              charCode = 'U'
            } else if (bMin) {
              colorCode = '#FF0000'
              charCode = 'D'
            }
          }
          markers = [...markers, {
            'time': param['time'],
            position: barPosition,
            color: colorCode,
            shape: 'circle',
            text: charCode
          }]
          __fetching_data__({
            url: `${base_api_url}/chat_bot_save_point`, method: "POST", body: JSON.stringify(
              {
                "chart_name": "short",
                "value": valueSelect,
                "position": valuePosition,
                "last_value": flow_value[flow_value.length - 1],
                "method": "add",
                "symbol": plotData['symbol'],
                "timestamp": param['time'],
                'group_id': noti_group_id
              }
            )
          }).then((res) => {
            console.log('res', res)
          })
        }
        console.log('time', markers)
        function compare(a, b) {
          if (a.time < b.time) {
            return -1;
          }
          if (a.time > b.time) {
            return 1;
          }
          return 0;
        }
        markers.sort(compare)
        short_series1.setMarkers(markers)
      });
      return [chart, [short_series, short_series1], chartContainer];
    }
    const [chart1, series1, chartContainer1] = generateChartLast();
    const [chart4, series4, chartContainer4] = generateChartFlow(sense);
    const [chart8, series8, chartContainer8] = generateChartShort();

    let allChartContainers;
    let allGenerators;
    let allSeries;
    let charts;
    if (userDetail.permissions && userDetail.permissions.permission && userDetail.permissions.permission.chatBot9Chanel && userDetail.permissions.permission.chatBot9Chanel.read) {
      allChartContainers = [chartContainer1, chartContainer4, chartContainer8];
      allGenerators = [generateChartLast, generateChartFlow, generateChartShort];
      allSeries = [series1, series4, series8];
      charts = [chart1, chart4, chart8];
    } else {
      return
    }

    function syncChart() {
      charts.forEach((currentChart, currentIndex) => {
        currentChart.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
          charts.forEach((otherChart, otherIndex) => {
            if (currentIndex !== otherIndex) {
              otherChart.timeScale().setVisibleLogicalRange(timeRange);
            }
          });
        });
      });

      charts.forEach((currentChart, currentIndex) => {
        currentChart.subscribeCrosshairMove(param => {
          if (!allSeries[currentIndex] || !allSeries[currentIndex][0]) return;
          const dataPoint = getCrosshairDataPoint(allSeries[currentIndex][0], param);
          if (!dataPoint) return;

          charts.forEach((otherChart, otherIndex) => {
            if (currentIndex !== otherIndex && allSeries[otherIndex] && allSeries[otherIndex][0]) {
              allSeries[otherIndex].forEach(otherSeries => {
                syncCrosshair(otherChart, otherSeries, dataPoint);
              });
            }
          });
        });
      });
    }
    syncChart()
    window.syncChart = syncChart;
    function renderChartInNewWindow(chartContainer, generateChart, symbol) {
      const windowWidth = 800;
      const windowHeight = 600;
      const windowLeft = window.screen.width / 2 - windowWidth / 2;
      const windowTop = window.screen.height / 2 - windowHeight / 2;
      const newWindow = window.open('', 'Chart Window', `width=${windowWidth},height=${windowHeight},left=${windowLeft},top=${windowTop}`);
      newWindow.document.write('<html><head><title>Chart Popup</title></head><body></body></html>');
      const container = newWindow.document.createElement('div');
      container.classList.add('grid', 'grid-cols-[1fr,3fr]', 'grid-rows-1', 'gap-2');
      container.style.width = '100%';
      container.style.height = '100vh';
      const nameContainer = newWindow.document.createElement('div');
      const symbolName = newWindow.document.createElement('h2');
      symbolName.textContent = symbol;
      nameContainer.appendChild(symbolName);
      container.appendChild(nameContainer);
      container.appendChild(chartContainer);

      newWindow.addEventListener('DOMContentLoaded', () => {
        const [newChart, newSeries, newChartContainer] = generateChart();
        container.innerHTML = '';
        container.appendChild(newChartContainer);
        newChart.resize(newChartContainer.clientWidth, newChartContainer.clientHeight);
        const handleResize = () => {
          newChart.resize(newChartContainer.clientWidth, newChartContainer.clientHeight);
        };

        newWindow.addEventListener('resize', handleResize);
        newWindow.addEventListener('beforeunload', () => {
          newWindow.removeEventListener('resize', handleResize);
        });
      });
      newWindow.document.body.appendChild(container);

    }

    allChartContainers.forEach((container, index) => {
      responseContainer.appendChild(container);
    });
    const divElements = responseContainer.querySelectorAll("div.tv-lightweight-charts > table > tr:nth-child(2) > td:nth-child(2) > div");
    divElements.forEach(element => { });
    return responseContainer;
  }
  const signalResponse = (response) => {
    const chatbox = document.getElementById('chatbox');
    const responseContainer = document.createElement('div');
    responseContainer.classList.add('flex', 'flex-row', 'space-x-4');
    const leftContainer = document.createElement('div');
    leftContainer.classList.add('w-1/2');

    const aHeader = document.createElement('p');
    aHeader.classList.add('text-black', 'font-semibold');
    aHeader.textContent = `A Pattern`;

    const downloadAButton = document.createElement('button');
    downloadAButton.classList.add('bg-blue-500', 'text-white', 'px-4', 'py-2', 'rounded', 'hover:bg-blue-600');
    downloadAButton.innerHTML = '👇Export A as CSV';
    downloadAButton.onclick = function () {
      exportCsv(response.csv_data[1], 'a_pattern');
    };
    const aresponse = document.createElement('div');
    aresponse.innerHTML = response.signal_response[1];
    leftContainer.appendChild(aHeader);
    leftContainer.appendChild(downloadAButton);
    leftContainer.appendChild(aresponse);
    const rightContainer = document.createElement('div');
    rightContainer.classList.add('w-1/2');

    const vHeader = document.createElement('p');
    vHeader.classList.add('text-black', 'font-semibold');
    vHeader.textContent = `V Pattern`;

    const downloadVButton = document.createElement('button');
    downloadVButton.classList.add('bg-blue-500', 'text-white', 'px-4', 'py-2', 'rounded', 'hover:bg-blue-600');
    downloadVButton.innerHTML = '👇Export V as CSV';
    downloadVButton.onclick = function () {
      exportCsv(response.csv_data[0], 'v_pattern');
    };

    const vresponse = document.createElement('div');
    vresponse.innerHTML = response.signal_response[0];

    rightContainer.appendChild(vHeader);
    rightContainer.appendChild(downloadVButton);
    rightContainer.appendChild(vresponse);

    responseContainer.appendChild(leftContainer);
    responseContainer.appendChild(rightContainer);
    chatbox.appendChild(responseContainer);

    initializeDataTable(aresponse);
    initializeDataTable(vresponse);
  }
  const marketSummaryPlot = (response) => {
    const chatbox = document.getElementById('chatbox');
    const plotData = response.marketsummary_data;
    const symbol = plotData[4];
    const responseContainer = document.createElement('div');
    responseContainer.setAttribute('class', 'grid grid-cols-3 grid-rows-4 gap-2 p-4');
    const containerWidth = '750px';
    const containerHeight = '250px';
    responseContainer.classList.add('response-container');
    responseContainer.style.gridTemplateColumns = `repeat(3, ${containerWidth})`;
    responseContainer.style.gridTemplateRows = `repeat(4, ${containerHeight})`;
    const chartWidth = 750;
    const chartHeight = 250;
    const chartOption = { width: chartWidth, height: chartHeight, crosshair: { mode: 1, }, timeScale: { visible: true }, layout: { background: { type: 'solid', color: '#f5f5f5' } } }

    function chartAnnotation(chart, text) {
      chart.applyOptions({
        watermark: {
          visible: true,
          fontSize: 24,
          horzAlign: 'left',
          vertAlign: 'top',
          color: 'rgb(1,216,149)',
          text: text,
        },
        rightPriceScale: {
          visible: true,
        },
        leftPriceScale: {
          visible: true,
          mode: LightweightCharts.PriceScaleMode.Normal,
          autoScale: false,
          invertScale: false,
          alignLabels: true,
          minValue: -1,
          maxValue: 1,
        },
      });

    }

    function generateChart1() {
      const chartContainer = document.createElement('div');
      chartContainer.classList.add('chart-container');
      chartContainer.setAttribute('id', `chart-1`)
      const chart = LightweightCharts.createChart(chartContainer, chartOption);
      chartAnnotation(chart, 'Price')
      const series = chart.addLineSeries({ title: 'close', color: '#d93025', lastValueVisible: true, priceLineVisible: false, priceScaleId: 'right', CrosshairMarkerVisible: false })
      const dataForSeries = generateData(plotData[0], 'time', 'value');

      series.setData(dataForSeries);
      series.applyOptions({
        priceFormat: {
          type: 'custom',
          formatter: (price) => { return price_formatter(price) }
        },
      });
      let max_data = plotData['max_peaks']
      let markers = []
      chart.subscribeDblClick((param) => { });
      return [chart, [series], chartContainer]
    }
    function generateChart2() {
      const chartContainer = document.createElement('div');
      chartContainer.classList.add('chart-container');

      chartContainer.setAttribute('id', `chart-2`)
      const chart = LightweightCharts.createChart(chartContainer, chartOption);
      chartAnnotation(chart, 'Open Interest')

      const series = chart.addLineSeries({ title: 'oi', color: '#ffc107', lastValueVisible: true, priceLineVisible: false, priceScaleId: 'right', CrosshairMarkerVisible: false })
      const dataForSeries = generateData(plotData[1], 'time', 'value');

      series.setData(dataForSeries);
      series.applyOptions({
        priceFormat: {
          type: 'volume',
        }
      })

      return [chart, [series], chartContainer]
    }
    function generateChart3() {
      const chartContainer = document.createElement('div');
      chartContainer.classList.add('chart-container');

      chartContainer.setAttribute('id', `chart-3`)
      const chart = LightweightCharts.createChart(chartContainer, chartOption);
      chartAnnotation(chart, 'Volume')

      const series = chart.addLineSeries({ title: 'volume', color: '#28a745', lastValueVisible: true, priceLineVisible: false, priceScaleId: 'right', CrosshairMarkerVisible: false })
      const dataForSeries = generateData(plotData[2], 'time', 'value');

      series.setData(dataForSeries);
      series.applyOptions({
        priceFormat: {
          type: 'volume',
        }
      })

      return [chart, [series], chartContainer]
    }

    function generateChart4() {
      const chartContainer = document.createElement('div');
      chartContainer.classList.add('chart-container');
      const chart = LightweightCharts.createChart(chartContainer, chartOption);
      chartAnnotation(chart, 'Market Summary')

      const market_summay_opt = { priceLineVisible: false, priceScaleId: 'right' };

      const series4_1 = chart.addLineSeries({ color: '#1a73e8', lineStyle: 0, lineWidth: 3, title: 'สถาบัน', ...market_summay_opt });
      const series4_2 = chart.addLineSeries({ color: '#00ff00', lineStyle: 0, lineWidth: 3, title: 'ต่างชาติ', ...market_summay_opt });
      const series4_3 = chart.addLineSeries({ color: '#ff6600', lineStyle: 0, lineWidth: 3, title: 'ในประเทศ', ...market_summay_opt });

      const series = [series4_1, series4_2, series4_3]
      series.forEach((series, index) => {
        const dataForSeries = generateData(plotData[3][index], 'time', 'value');
        series.setData(dataForSeries)
        series.applyOptions({
          priceFormat: {
            type: 'volume',
          }
        })
      });

      return [chart, series, chartContainer]
    }

    const [chart1, series1, chartContainer1] = generateChart1();
    const [chart2, series2, chartContainer2] = generateChart2();
    const [chart3, series3, chartContainer3] = generateChart3();
    const [chart4, series4, chartContainer4] = generateChart4();

    const allSeries = [series1, series2, series3, series4];
    const charts = [chart1, chart2, chart3, chart4];
    charts.forEach((currentChart, currentIndex) => {
      currentChart.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
        charts.forEach((otherChart, otherIndex) => {
          if (currentIndex !== otherIndex) {
            otherChart.timeScale().setVisibleLogicalRange(timeRange);
          }
        });
      });
    });

    charts.forEach((currentChart, currentIndex) => {
      currentChart.subscribeCrosshairMove(param => {
        const dataPoint = getCrosshairDataPoint(allSeries[currentIndex][0], param);

        charts.forEach((otherChart, otherIndex) => {
          if (currentIndex !== otherIndex) {
            allSeries[otherIndex].forEach(otherSeries => {
              syncCrosshair(otherChart, otherSeries, dataPoint);
            });
          }
        });
      });
    });

    function renderChartInNewWindow(chartContainer, generateChart) {
      const windowWidth = 800;
      const windowHeight = 600;
      const windowLeft = window.screen.width / 2 - windowWidth / 2;
      const windowTop = window.screen.height / 2 - windowHeight / 2;
      const newWindow = window.open('', 'Chart Window', `width=${windowWidth},height=${windowHeight},left=${windowLeft},top=${windowTop}`);

      newWindow.document.write('<html><head><title>Chart Popup</title></head><body></body></html>');
      const container = newWindow.document.createElement('div');
      container.classList.add('grid', 'grid-cols-[1fr,3fr]', 'grid-rows-1', 'gap-2');
      newWindow.document.body.appendChild(container);

      const tailwindLink = newWindow.document.createElement('link');
      tailwindLink.href = 'https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css';
      tailwindLink.rel = 'stylesheet';
      newWindow.document.head.appendChild(tailwindLink);

      const nameContainer = newWindow.document.createElement('div');
      const symbolName = newWindow.document.createElement('h2');
      symbolName.textContent = symbol;
      nameContainer.appendChild(symbolName);
      container.appendChild(nameContainer);
      container.appendChild(chartContainer);

      const addButton = newWindow.document.createElement('button');
      addButton.textContent = 'Load Scripts';
      addButton.addEventListener('click', () => {
        const scriptPaths = [
          '/static/config.js',
          'https://code.jquery.com/jquery-3.6.4.min.js',
          'https://cdn.datatables.net/1.11.5/js/jquery.dataTables.min.js',
          'https://unpkg.com/lightweight-charts/dist/lightweight-charts.standalone.production.js',
          '/static/global.js',
          '/static/newdemo.js',
          '/static/demo.js',
          '/static/market_summary.js'
        ];

        scriptPaths.forEach((scriptPath) => {
          const scriptElement = newWindow.document.createElement('script');
          scriptElement.src = scriptPath;
          newWindow.document.body.appendChild(scriptElement);
        });
      });

      newWindow.document.body.appendChild(addButton);

      newWindow.addEventListener('DOMContentLoaded', () => {
        const [newChart, newSeries, newChartContainer] = generateChart();
        container.innerHTML = '';
        container.appendChild(newChartContainer);
      });
    }

    function addRenderButton(generateChart, chartContainer) {
      const [chart, series, container] = generateChart();
      document.querySelectorAll('.newtabButton').forEach(button => {
        button.removeEventListener('click', () => renderChartInNewWindow(container, generateChart));
      });
      const renderButton = document.createElement('button');
      renderButton.setAttribute('class', 'btn');
      renderButton.setAttribute('style', 'z-index:50; position:absolute;');

      renderButton.classList.add("newtabButton");
      renderButton.textContent = '🔎';

      renderButton.addEventListener('click', clickHandler);

      const renderButtonContainer = document.createElement('div');
      renderButtonContainer.setAttribute('style', 'z-index:999; position:relative;')
      renderButtonContainer.appendChild(renderButton);
      chartContainer.insertBefore(renderButtonContainer, chartContainer.firstChild);

      function clickHandler() {
        renderChartInNewWindow(container, generateChart, symbol);
      }
    }

    const allChartContainers = [chartContainer1, chartContainer2, chartContainer3, chartContainer4];
    const allGenerators = [generateChart1, generateChart2, generateChart3, generateChart4];

    allChartContainers.forEach((container, index) => {
      addRenderButton(allGenerators[index], container);
      responseContainer.appendChild(container);
    });

    chatbox.appendChild(responseContainer);
  }
  const petroPlot = (response) => {
    const chatbox = document.getElementById('chatbox');
    const plotData = response.petroleum_data;
    log(plotData)
    const symbol = plotData[4];
    const responseContainer = document.createElement('div');
    responseContainer.setAttribute('class', 'grid grid-cols-3 grid-rows-4 gap-2 p-4');
    const containerWidth = '750px';
    const containerHeight = '250px';
    responseContainer.classList.add('response-container');
    responseContainer.style.gridTemplateColumns = `repeat(3, ${containerWidth})`;
    responseContainer.style.gridTemplateRows = `repeat(4, ${containerHeight})`;
    const chartWidth = 750;
    const chartHeight = 250;
    const chartOption = { width: chartWidth, height: chartHeight, crosshair: { mode: 1, }, timeScale: { visible: true }, layout: { background: { type: 'solid', color: '#f5f5f5' } } }

    function chartAnnotation(chart, text) {
      chart.applyOptions({
        watermark: {
          visible: true,
          fontSize: 24,
          horzAlign: 'left',
          vertAlign: 'top',
          color: 'rgb(1,216,149)',
          text: text,
        },
        rightPriceScale: {
          visible: true,
        },
        leftPriceScale: {
          visible: true,
          mode: LightweightCharts.PriceScaleMode.Normal,
          autoScale: false,
          invertScale: false,
          alignLabels: true,
          minValue: -1,
          maxValue: 1,
        },
      });

    }

    function generateChart1() {
      const chartContainer = document.createElement('div');
      chartContainer.classList.add('chart-container');

      chartContainer.setAttribute('id', `chart-1`)
      const chart = LightweightCharts.createChart(chartContainer, chartOption);
      chartAnnotation(chart, 'Price')

      const series = chart.addLineSeries({ title: 'close', color: '#d93025', lastValueVisible: true, priceLineVisible: false, priceScaleId: 'right', CrosshairMarkerVisible: false })
      const dataForSeries = generateData(plotData[0], 'time', 'value');

      series.setData(dataForSeries);
      series.applyOptions({
        priceFormat: {
          type: 'custom',
          formatter: (price) => { return price_formatter(price) }
        },
      });

      let max_data = plotData['max_peaks']
      let markers = []
      chart.subscribeDblClick((param) => { });
      return [chart, [series], chartContainer]
    }

    const filterContainer = document.createElement('div');
    filterContainer.setAttribute('style', 'position: relative; overflow: visible; z-index:3;')
    const buttonContainer = document.createElement('div');
    buttonContainer.setAttribute('style', 'padding-left: 20%; position: absolute; z-index: 1;"')

    function generateChart2() {
      const chartContainer = document.createElement('div');
      chartContainer.classList.add('chart-container');
      const chart = LightweightCharts.createChart(chartContainer, chartOption);
      chartAnnotation(chart, 'EX-REFIN')

      const ex_refin_opt = { priceLineVisible: false, priceScaleId: 'right' };
      let numberOfColors = plotData[1].length;
      uniqueRandomColors = generateUniqueRandomColors(numberOfColors);
      var series2_arr = new Array;
      plotData[1].forEach((value, index) => {
        series2_arr.push(chart.addLineSeries({ color: uniqueRandomColors[index], lineStyle: 0, lineWidth: 3, title: plotData[4][index]['name'], ...ex_refin_opt }))
      });

      series2_arr.forEach((series, index) => {
        const dataForSeries = generateData(plotData[1][index], 'time', 'value');
        series.setData(dataForSeries)
        series.applyOptions({
          priceFormat: {
            type: 'volume',
          }
        });
      });
      return [chart, series2_arr, chartContainer]
    }
    function generateChart3() {
      const chartContainer = document.createElement('div');
      chartContainer.classList.add('chart-container');
      const chart = LightweightCharts.createChart(chartContainer, chartOption);
      chartAnnotation(chart, 'Marketing Margin')

      const mkt_margin_opt = { priceLineVisible: false, priceScaleId: 'right' };

      var series3_arr = new Array;
      plotData[2].forEach((value, index) => {
        series3_arr.push(chart.addLineSeries({ color: uniqueRandomColors[index], lineStyle: 0, lineWidth: 3, title: plotData[4][index]['name'], ...mkt_margin_opt }))
      });

      series3_arr.forEach((series, index) => {
        const dataForSeries = generateData(plotData[2][index], 'time', 'value');
        series.setData(dataForSeries)
        series.applyOptions({
          priceFormat: {
            type: 'volume',
          }
        });
      });
      return [chart, series3_arr, chartContainer]
    }

    function generateChart4() {
      const chartContainer = document.createElement('div');
      chartContainer.classList.add('chart-container');
      const chart = LightweightCharts.createChart(chartContainer, chartOption);
      chartAnnotation(chart, 'Oil Fund')

      const oil_fund_opt = { priceLineVisible: false, priceScaleId: 'right' };

      var series4_arr = new Array;
      plotData[3].forEach((value, index) => {
        series4_arr.push(chart.addLineSeries({ color: uniqueRandomColors[index], lineStyle: 0, lineWidth: 3, title: plotData[4][index]['name'], ...oil_fund_opt }))
      });

      series4_arr.forEach((series, index) => {
        const dataForSeries = generateData(plotData[3][index], 'time', 'value');
        series.setData(dataForSeries)
        series.applyOptions({
          priceFormat: {
            type: 'volume',
          }
        });
      });
      return [chart, series4_arr, chartContainer]
    }

    const [chart1, series1, chartContainer1] = generateChart1();
    const [chart2, series2, chartContainer2] = generateChart2();
    const [chart3, series3, chartContainer3] = generateChart3();
    const [chart4, series4, chartContainer4] = generateChart4();


    function createColorIndicator(color) {
      const colorIndicator = document.createElement('span');
      colorIndicator.style.display = 'inline-block';
      colorIndicator.style.width = '10px';
      colorIndicator.style.height = '10px';
      colorIndicator.style.backgroundColor = color;
      colorIndicator.style.marginRight = '5px';
      return colorIndicator;
    }
    series2.forEach((series, index) => {
      const show_hide_serie = document.createElement('button');
      show_hide_serie.setAttribute('class', 'btn');
      show_hide_serie.textContent = plotData[4][index]['name'];
      show_hide_serie.setAttribute('style', 'z-index:2;')
      show_hide_serie.appendChild(createColorIndicator(uniqueRandomColors[index]));
      show_hide_serie.addEventListener('click', () => {
        series.applyOptions({
          visible: !series.options().visible,
        });
        series3[index].applyOptions({
          visible: !series3[index].options().visible,
        });
        series4[index].applyOptions({
          visible: !series4[index].options().visible,
        });
      });
      buttonContainer.appendChild(show_hide_serie);
    });
    const show_hide_allserie = document.createElement('button');
    show_hide_allserie.setAttribute('class', 'btn');
    show_hide_allserie.textContent = 'ShowHideAll';
    show_hide_allserie.setAttribute('style', 'z-index:2;')
    show_hide_allserie.appendChild(createColorIndicator('black'));
    show_hide_allserie.addEventListener('click', () => {
      series2.forEach((series, index) => {
        series.applyOptions({
          visible: !series.options().visible,
        });
        series3[index].applyOptions({
          visible: !series3[index].options().visible,
        });
        series4[index].applyOptions({
          visible: !series4[index].options().visible,
        });
      })
    });
    buttonContainer.appendChild(show_hide_allserie);
    filterContainer.appendChild(buttonContainer);
    chartContainer4.appendChild(filterContainer);
    const allSeries = [series1, series2, series3, series4];
    const charts = [chart1, chart2, chart3, chart4];
    charts.forEach((currentChart, currentIndex) => {
      currentChart.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
        charts.forEach((otherChart, otherIndex) => {
          if (currentIndex !== otherIndex) {
            otherChart.timeScale().setVisibleLogicalRange(timeRange);
          }
        });
      });
    });

    charts.forEach((currentChart, currentIndex) => {
      currentChart.subscribeCrosshairMove(param => {
        const dataPoint = getCrosshairDataPoint(allSeries[currentIndex][0], param);

        charts.forEach((otherChart, otherIndex) => {
          if (currentIndex !== otherIndex) {
            allSeries[otherIndex].forEach(otherSeries => {
              syncCrosshair(otherChart, otherSeries, dataPoint);
            });
          }
        });
      });
    });

    function renderChartInNewWindow(chartContainer, generateChart) {
      const windowWidth = 800;
      const windowHeight = 600;
      const windowLeft = window.screen.width / 2 - windowWidth / 2;
      const windowTop = window.screen.height / 2 - windowHeight / 2;
      const newWindow = window.open('', 'Chart Window', `width=${windowWidth},height=${windowHeight},left=${windowLeft},top=${windowTop}`);
      newWindow.document.write('<html><head><title>Chart Popup</title></head><body></body></html>');
      const container = newWindow.document.createElement('div');
      container.classList.add('grid', 'grid-cols-[1fr,3fr]', 'grid-rows-1', 'gap-2');
      newWindow.document.body.appendChild(container);
      const tailwindLink = newWindow.document.createElement('link');
      tailwindLink.href = 'https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css';
      tailwindLink.rel = 'stylesheet';
      newWindow.document.head.appendChild(tailwindLink);
      const nameContainer = newWindow.document.createElement('div');
      const symbolName = newWindow.document.createElement('h2');
      symbolName.textContent = symbol;
      nameContainer.appendChild(symbolName);
      container.appendChild(nameContainer);
      container.appendChild(chartContainer);
      const addButton = newWindow.document.createElement('button');
      addButton.textContent = 'Load Scripts';
      addButton.addEventListener('click', () => {
        const scriptPaths = [
          '/static/config.js',
          'https://code.jquery.com/jquery-3.6.4.min.js',
          'https://cdn.datatables.net/1.11.5/js/jquery.dataTables.min.js',
          'https://unpkg.com/lightweight-charts/dist/lightweight-charts.standalone.production.js',
          '/static/global.js',
          '/static/newdemo.js',
          '/static/demo.js',
          '/static/market_summary.js'
        ];

        scriptPaths.forEach((scriptPath) => {
          const scriptElement = newWindow.document.createElement('script');
          scriptElement.src = scriptPath;
          newWindow.document.body.appendChild(scriptElement);
        });
      });

      newWindow.document.body.appendChild(addButton);
      newWindow.addEventListener('DOMContentLoaded', () => {
        const [newChart, newSeries, newChartContainer] = generateChart();
        container.innerHTML = '';
        container.appendChild(newChartContainer);
      });
    }

    function addRenderButton(generateChart, chartContainer) {
      const [chart, series, container] = generateChart();
      document.querySelectorAll('.newtabButton').forEach(button => {
        button.removeEventListener('click', () => renderChartInNewWindow(container, generateChart));
      });
      const renderButton = document.createElement('button');
      renderButton.setAttribute('class', 'btn');
      renderButton.setAttribute('style', 'z-index:50; position:absolute;');

      renderButton.classList.add("newtabButton");
      renderButton.textContent = '🔎';
      renderButton.addEventListener('click', clickHandler);
      const renderButtonContainer = document.createElement('div');
      renderButtonContainer.setAttribute('style', 'z-index:999; position:relative;')
      renderButtonContainer.appendChild(renderButton);
      chartContainer.insertBefore(renderButtonContainer, chartContainer.firstChild);

      function clickHandler() {
        renderChartInNewWindow(container, generateChart, symbol);
      }
    }

    const allChartContainers = [chartContainer1, chartContainer2, chartContainer3, chartContainer4];
    const allGenerators = [generateChart1, generateChart2, generateChart3, generateChart4];

    allChartContainers.forEach((container, index) => {
      addRenderButton(allGenerators[index], container);
      responseContainer.appendChild(container);
    });

    chatbox.appendChild(responseContainer);
  }
  const setChartplot = (response) => {
    const chatbox = document.getElementById('chatbox');
    const plotData = response.index_data;
    window.plotData = plotData;
    const symbol = plotData['index'];
    const containerWidth = 'auto';
    const containerHeight = 'auto';

    const responseContainer = document.createElement('div');
    if (symbol === 'SET50') {
      responseContainer.setAttribute('class', 'grid grid-cols-3 grid-rows-3 gap-2 p-4');
      responseContainer.classList.add('response-container');
      responseContainer.style.display = 'grid';
      responseContainer.style.gridTemplateColumns = `repeat(2, ${containerWidth})`;
      responseContainer.style.gridTemplateRows = `repeat(3, ${containerHeight})`;
    } else {
      responseContainer.setAttribute('class', 'grid grid-cols-3 grid-rows-2 gap-2 p-4');
      responseContainer.classList.add('response-container');
      responseContainer.style.display = 'grid';
      responseContainer.style.gridTemplateColumns = `repeat(3, ${containerWidth})`;
      responseContainer.style.gridTemplateRows = `repeat(2, ${containerHeight})`;
    }
    const chartWidth = chatbox.offsetWidth / 3;
    const chartHeight = chatbox.offsetHeight / 4;
    const chartOption = { width: chartWidth, height: chartHeight, crosshair: { mode: 1, }, timeScale: { visible: true }, layout: { background: { type: 'solid', color: '#f5f5f5' } } }

    function chartAnnotation(chart, text) {
      chart.applyOptions({
        watermark: {
          visible: true,
          fontSize: 24,
          horzAlign: 'left',
          vertAlign: 'top',
          color: 'rgb(1,216,149)',
          text: text,
        },
        rightPriceScale: {
          visible: true,
        },
        leftPriceScale: {
          visible: true,
          mode: PriceScaleMode.Normal,
          autoScale: false,
          invertScale: false,
          alignLabels: true,
          minValue: -1,
          maxValue: 1,
        },
      });
    }
    function generateIndexLast() {
      const chartIndexContainer = document.createElement('div');
      chartIndexContainer.classList.add('chart-container');

      chartIndexContainer.setAttribute('id', `chart-1`)
      const chart = createChart(chartIndexContainer, chartOption);
      chartAnnotation(chart, 'Last')
      chart.applyOptions({
        rightPriceScale: {
          visible: true,
        },
        leftPriceScale: {
          visible: true,
        },
      });

      const series = chart.addLineSeries({ title: '', color: 'black', lastValueVisible: true, priceLineVisible: false, priceScaleId: 'right', CrosshairMarkerVisible: false })
      const dataForSeries = generateData(plotData['price'][0], 'time', 'value');
      series.setData(dataForSeries);
      let s50_series = [];
      if (plotData['price'].length > 1) {
        s50_series_close = chart.addLineSeries({ title: '', color: 'purple', lastValueVisible: true, priceLineVisible: false, priceScaleId: 'right', CrosshairMarkerVisible: false })
        const dataForSeries = generateData(plotData['price'][1], 'time', 'value');
        s50_series_close.setData(dataForSeries);
        s50_series.push(s50_series_close)
      } else {

      }
      return [chart, [series, ...s50_series], chartIndexContainer]
    }

    function generateIndexChartS50Last() {
      const chartIndexContainer = document.createElement('div');
      chartIndexContainer.classList.add('chart-container');
      chartIndexContainer.setAttribute('id', `chart-3`);

      const filterContainer = document.createElement('div');
      filterContainer.setAttribute('style', 'position: relative; overflow: visible; z-index: 3;');
      chartIndexContainer.appendChild(filterContainer);
      const chart = createChart(chartIndexContainer, chartOption);
      chartAnnotation(chart, 'Trend')
      let series = [];
      let turnoverSeries = [];
      if (plotData['s50_ind']['mksum_s50']) {
        chart.applyOptions({
          rightPriceScale: {
            visible: true,
          },
          leftPriceScale: {
            visible: true,
          },
        });

        const seriesForeign = chart.addLineSeries({
          priceScaleId: 'right',
          color: 'blue'
        });
        const foreignData = generateData(plotData['s50_ind']['mksum_s50'][0], 'time', 'value');
        seriesForeign.setData(foreignData);
        const mksumSeries = [seriesForeign];
        mksumSeries.forEach(serie => {
          serie.applyOptions({
            priceFormat: {
              type: 'volume',
            }
          });
          series.push(serie);
        });
        const turnoverForeign = chart.addLineSeries({
          title: 'eq_foreign',
          priceScaleId: 'right',
          color: 'blue'
        });
        const turnoverForeignData = generateData(plotData['s50_ind']['mksum_s50'][3], 'time', 'value');
        turnoverForeign.setData(turnoverForeignData);
        const turnoverMksumSeries = [turnoverForeign];
        turnoverMksumSeries.forEach(serie => {
          serie.applyOptions({
            priceFormat: {
              type: 'volume',
            },
            visible: false
          });
          turnoverSeries.push(serie);
        });

        const buttonContainer = document.createElement('div');
        buttonContainer.setAttribute('style', 'padding-left: 80%; position: absolute; z-index: 1;');

        const eq = hideSeriesButton(turnoverMksumSeries, 'Eq', '#FF4D02');
        const s50 = hideSeriesButton(mksumSeries, 'S50', '#FF4D02');

        buttonContainer.appendChild(eq);
        buttonContainer.appendChild(s50);

        filterContainer.appendChild(buttonContainer);
      } else {

      }
      return [chart, [...series, ...turnoverSeries], chartIndexContainer];
    }

    function generateIndexChartS50Last() {
      const chartIndexContainer = document.createElement('div');
      chartIndexContainer.classList.add('chart-container');
      chartIndexContainer.setAttribute('id', `chart-3`);

      const filterContainer = document.createElement('div');
      filterContainer.setAttribute('style', 'position: relative; overflow: visible; z-index: 3;');
      chartIndexContainer.appendChild(filterContainer);
      const chart = createChart(chartIndexContainer, chartOption);
      chartAnnotation(chart, 'Trend')
      let series = [];
      let turnoverSeries = [];
      if (plotData['s50_ind']['mksum_s50']) {
        chart.applyOptions({
          rightPriceScale: {
            visible: true,
          },
          leftPriceScale: {
            visible: true,
          },
        });

        const seriesForeign = chart.addLineSeries({
          priceScaleId: 'right',
          color: 'blue'
        });
        const foreignData = generateData(plotData['s50_ind']['mksum_s50'][0], 'time', 'value');
        seriesForeign.setData(foreignData);
        const mksumSeries = [seriesForeign];
        mksumSeries.forEach(serie => {
          serie.applyOptions({
            priceFormat: {
              type: 'volume',
            }
          });
          series.push(serie);
        });

        const turnoverForeign = chart.addLineSeries({
          title: 'eq_foreign',
          priceScaleId: 'right',
          color: 'blue'
        });
        const turnoverForeignData = generateData(plotData['s50_ind']['mksum_s50'][3], 'time', 'value');
        turnoverForeign.setData(turnoverForeignData);
        const turnoverMksumSeries = [turnoverForeign];
        turnoverMksumSeries.forEach(serie => {
          serie.applyOptions({
            priceFormat: {
              type: 'volume',
            },
            visible: false
          });
          turnoverSeries.push(serie);
        });

        const buttonContainer = document.createElement('div');
        buttonContainer.setAttribute('style', 'padding-left: 80%; position: absolute; z-index: 1;');

        const eq = hideSeriesButton(turnoverMksumSeries, 'Eq', '#FF4D02');
        const s50 = hideSeriesButton(mksumSeries, 'S50', '#FF4D02');

        buttonContainer.appendChild(eq);
        buttonContainer.appendChild(s50);

        filterContainer.appendChild(buttonContainer);
      } else {

      }
      return [chart, [...series, ...turnoverSeries], chartIndexContainer];
    }

    function generateIndexoutstanding() {
      const chartIndexContainer = document.createElement('div');
      chartIndexContainer.classList.add('chart-container');
      chartIndexContainer.setAttribute('id', `chart-4`)
      const chart = createChart(chartIndexContainer, chartOption);
      chart.applyOptions({
        crosshair: {
          mode: CrosshairMode.Normal,
          horzLine: {
            labelVisible: false
          },
        },

      })
      chart.priceScale('right').applyOptions({
        visible: false,
        textColor: '#F5F5F5',

      });
      chart.priceScale('left').applyOptions({
        visible: false,
        textColor: '#F5F5F5',


      });

      chartAnnotation(chart, 'Mid Trend')

      const all_series = [];
      const series = chart.addLineSeries({ title: '', color: 'red', lastValueVisible: false, priceLineVisible: false, priceScaleId: 'right', CrosshairMarkerVisible: false })
      const dataForSeries = generateData(plotData['s50_ind']['mid_trend'], 'date', 'close');
      series.setData(dataForSeries);
      series.applyOptions({
        priceFormat: {
          type: 'volume',
        }
      })
      all_series.push(series);
      return [chart, all_series, chartIndexContainer]
    }
    function generateIndexMkb() {
      const chartIndexContainer = document.createElement('div');
      chartIndexContainer.classList.add('chart-container');
      chartIndexContainer.setAttribute('id', `chart-2`);
      const chart = createChart(chartIndexContainer, chartOption);
      chartAnnotation(chart, 'Market Breath');
      const series = chart.addLineSeries({
        title: 'score',
        color: 'blue',
        lastValueVisible: true,
        priceLineVisible: false,
        priceScaleId: 'right',
        CrosshairMarkerVisible: false
      });
      const dataForSeries = generateData(plotData['mkbt'], 'time', 'value');
      series.setData(dataForSeries);
      const mkb_value = plotData['mkbt'].map(item => item.value);
      const max_val = Math.max(...mkb_value);
      const min_val = Math.min(...mkb_value);
      const updatedDataMax = plotData['mkbt'].map(item => ({
        time: item.time,
        value: max_val
      }));
      const updatedDataMin = plotData['mkbt'].map(item => ({
        time: item.time,
        value: min_val
      }));

      const max_series = chart.addLineSeries({
        title: 'highest',
        color: 'blue',
        lineStyle: 1,
        lineWidth: 2,
        lastValueVisible: true,
        priceLineVisible: false,
        priceScaleId: 'right',
        CrosshairMarkerVisible: false
      });

      const min_series = chart.addLineSeries({
        title: 'lowest',
        color: 'blue',
        lineStyle: 1,
        lineWidth: 2,
        lastValueVisible: true,
        priceLineVisible: false,
        priceScaleId: 'right',
        CrosshairMarkerVisible: false
      });

      min_series.setData(generateData(updatedDataMin, 'time', 'value'));
      max_series.setData(generateData(updatedDataMax, 'time', 'value'));

      return [chart, [series], chartIndexContainer];
    }

    function generateIndexFlow() {
      const chartIndexContainer = document.createElement('div');
      chartIndexContainer.classList.add('chart-container');
      chartIndexContainer.setAttribute('id', `chart-3`)
      const chart = createChart(chartIndexContainer, chartOption);
      chart.applyOptions({
        crosshair: {
          mode: CrosshairMode.Normal,
          horzLine: {
            labelVisible: false
          },
        },

      })
      chart.priceScale('right').applyOptions({
        visible: false,
        textColor: '#F5F5F5',

      });
      chart.priceScale('left').applyOptions({
        visible: false,
        textColor: '#F5F5F5',
      });
      chartAnnotation(chart, 'Confirm Up/Down')
      const all_series = [];
      const series = chart.addLineSeries({ title: '', color: 'black', lastValueVisible: false, priceLineVisible: false, priceScaleId: 'right', CrosshairMarkerVisible: false })
      const dataForSeries = generateData(plotData['flow'], 'time', 'value');
      series.setData(dataForSeries);
      series.applyOptions({
        priceFormat: {
          type: 'volume',
        }
      })
      all_series.push(series);
      return [chart, all_series, chartIndexContainer]
    }
    function generateIndexFlowS50() {
      const chartIndexContainer = document.createElement('div');
      chartIndexContainer.classList.add('chart-container');
      chartIndexContainer.setAttribute('id', `chart-3`)
      const chart = createChart(chartIndexContainer,
        {
          width: chartWidth,
          height: chartHeight,
          crosshair: { mode: 1, },
          timeScale: { visible: true },
          layout: { background: { type: 'solid', color: '#f5f5f5' } },
        });
      chart.applyOptions({
        crosshair: {
          mode: CrosshairMode.Normal,
          horzLine: {
            labelVisible: false
          },
        },

      })
      chart.priceScale('right').applyOptions({
        visible: false,
        textColor: '#F5F5F5',

      });
      chart.priceScale('left').applyOptions({
        visible: false,
        textColor: '#F5F5F5',


      });
      chartAnnotation(chart, 'Confirm Up/Down S50')
      const all_series = [];
      const series = chart.addLineSeries({ title: '', color: 'purple', lastValueVisible: false, priceScaleId: 'right', priceLineVisible: false, CrosshairMarkerVisible: false })
      const dataForSeries = generateData(plotData['s50_ind']['flow_s50'], 'time', 'value');
      series.setData(dataForSeries);
      series.applyOptions({
        priceFormat: {
          type: 'volume',
        }
      })
      all_series.push(series);
      return [chart, all_series, chartIndexContainer]
    }

    function generateS50Scoring() {
      const chartIndexContainer = document.createElement('div');
      chartIndexContainer.classList.add('chart-container');
      chartIndexContainer.setAttribute('id', `chart-3`)
      const chart = createChart(chartIndexContainer,
        {
          width: chartWidth,
          height: chartHeight,
          crosshair: { mode: 1, },
          timeScale: { visible: true },
          layout: { background: { type: 'solid', color: '#f5f5f5' } },
        });
      chart.applyOptions({
        crosshair: {
          mode: CrosshairMode.Normal,
          horzLine: {
            labelVisible: false
          },
        },

      })
      chart.applyOptions({
        leftPriceScale: {
          visible: true,
        },
      });
      chartAnnotation(chart, 'S50 Scoring')

      const all_series = [];
      const series = chart.addLineSeries({ title: '', color: 'purple', lastValueVisible: false, priceScaleId: 'right', priceLineVisible: false, CrosshairMarkerVisible: false })
      const dataForSeries = generateData(plotData['s50_scoring'], 'time', 'value');
      series.setData(dataForSeries);
      series.applyOptions({
        priceFormat: {
          type: 'volume',
        }
      })
      all_series.push(series);
      return [chart, all_series, chartIndexContainer]
    }

    //BidAsk
    function generateIndexBidask() {
      const chartIndexContainer = document.createElement('div');
      chartIndexContainer.classList.add('chart-container');
      const filterContainer = document.createElement('div');
      filterContainer.setAttribute('style', 'position: relative; overflow: visible; z-index: 3;');
      chartIndexContainer.appendChild(filterContainer);
      chartIndexContainer.setAttribute('id', `chart-8`);
      const chart = createChart(chartIndexContainer, chartOption);
      chartAnnotation(chart, 'ความน่าสนใจ');
      const bidaskOptions = { priceLineVisible: false, priceScaleId: 'right' };
      const bidAvgSeries = chart.addLineSeries({ color: 'red', lineStyle: 0, lineWidth: 3, title: '', ...bidaskOptions });
      const askAvgSeries = chart.addLineSeries({ color: 'green', lineStyle: 0, lineWidth: 3, title: '', ...bidaskOptions });
      const series5_3 = chart.addLineSeries({ color: '#013220', lineStyle: 1, lineWidth: 2, ...bidaskOptions });
      const series5_4 = chart.addLineSeries({ color: '#8B0000', lineStyle: 1, lineWidth: 2, ...bidaskOptions });
      const series = [bidAvgSeries, askAvgSeries, series5_3, series5_4];
      series.forEach((seri, index) => {
        const dataForSeries = generateData(plotData['ba'][index], 'time', 'value');
        seri.setData(dataForSeries);
        seri.applyOptions({
          priceFormat: {
            type: 'volume',
          }
        });
      });

      let s50Series = [];
      return [chart, [...series, ...s50Series], chartIndexContainer]
    }

    function generateIndexTick() {
      const chartIndexContainer = document.createElement('div');
      chartIndexContainer.classList.add('chart-container');
      chartIndexContainer.setAttribute('id', `chart-5`)

      const filterContainer = document.createElement('div');
      filterContainer.setAttribute('style', 'position: relative; overflow: visible; z-index: 3;');
      chartIndexContainer.appendChild(filterContainer);


      const chart = createChart(chartIndexContainer, chartOption);
      chartAnnotation(chart, 'Tick Match')

      const tickOptions = { axisLabelVisible: true, lastValueVisible: false, priceLineVisible: false, priceScaleId: 'right' };
      const series6_1 = chart.addLineSeries({ color: 'red', lineStyle: 0, lineWidth: 3, title: '', ...tickOptions });
      const series6_2 = chart.addLineSeries({ color: 'green', lineStyle: 0, lineWidth: 3, title: '', ...tickOptions });
      const series6_3 = chart.addLineSeries({ color: '#013220', lineStyle: 1, lineWidth: 2, ...tickOptions });
      const series6_4 = chart.addLineSeries({ color: '#8B0000', lineStyle: 1, lineWidth: 2, ...tickOptions });

      const series = [series6_1, series6_2, series6_3, series6_4];

      series.forEach((series, index) => {
        const dataForSeries = generateData(plotData['tm'][index], 'time', 'value');
        series.setData(dataForSeries);

        series.applyOptions({
          priceFormat: {
            type: 'volume',
          },
        });
      });
      let ticks50Series = [];
      if (plotData['s50_ind']['tm_s50']) {
        series.forEach(seri => {
          seri.applyOptions({
            visible: false
          })
        })

        const s50_tickOptions = { axisLabelVisible: true, lastValueVisible: false, priceLineVisible: false, priceScaleId: 'left' };
        const tickSeries_1 = chart.addLineSeries({ color: 'red', lineStyle: 0, lineWidth: 3, title: '', ...s50_tickOptions });
        const tickSeries_2 = chart.addLineSeries({ color: 'green', lineStyle: 0, lineWidth: 3, title: '', ...s50_tickOptions });
        const tickSeries_3 = chart.addLineSeries({ color: '#013220', lineStyle: 1, lineWidth: 2, ...s50_tickOptions });
        const tickSeries_4 = chart.addLineSeries({ color: '#8B0000', lineStyle: 1, lineWidth: 2, ...s50_tickOptions });
        [tickSeries_1, tickSeries_2, tickSeries_3, tickSeries_4].forEach((seri, index) => {
          const dataForSeries = generateData(plotData['s50_ind']['tm_s50'][index], 'time', 'value');
          seri.setData(dataForSeries);

          seri.applyOptions({
            priceFormat: {
              type: 'volume',
            },
          });
          ticks50Series.push(seri)
        });

        const buttonContainer = document.createElement('div');
        buttonContainer.setAttribute('style', 'padding-left: 80%; position: absolute; z-index: 1;');

        const eq = hideSeriesButton(series, 'Eq', '#FF4D02');
        const s50 = hideSeriesButton(ticks50Series, 'S50', '#FF4D02');

        buttonContainer.appendChild(eq);
        buttonContainer.appendChild(s50);

        filterContainer.appendChild(buttonContainer);
      } else {

      }
      return [chart, [...series, ...ticks50Series], chartIndexContainer]
    }
    function hideSeriesButton(seriesList, text, color) {
      function createColorIndicator(color, isVisible) {
        const colorIndicator = document.createElement('span');
        colorIndicator.style.display = 'inline-block';
        colorIndicator.style.width = '10px';
        colorIndicator.style.height = '10px';
        colorIndicator.style.backgroundColor = isVisible ? color : 'transparent';
        colorIndicator.style.marginRight = '5px';
        colorIndicator.style.marginLeft = '5px';

        return colorIndicator;
      }
      const button = document.createElement('button');
      button.setAttribute('class', 'btn');
      button.textContent = text;
      button.setAttribute('style', 'z-index: 2;');
      button.appendChild(createColorIndicator(color, seriesList[0].options().visible));
      button.addEventListener('click', () => {
        const isVisible = !seriesList[0].options().visible;
        seriesList.forEach(series => {
          series.applyOptions({
            visible: isVisible,
          });
        });
        const colorIndicator = button.querySelector('span');
        colorIndicator.style.backgroundColor = isVisible ? color : 'transparent';
      });

      return button;
    }

    function generateIndexTransaction() {
      const chartIndexContainer = document.createElement('div');
      chartIndexContainer.classList.add('chart-container');
      const filterContainer = document.createElement('div');
      filterContainer.setAttribute('style', 'position: relative; overflow: visible; z-index: 3;');
      chartIndexContainer.appendChild(filterContainer);
      const chart = createChart(chartIndexContainer, chartOption);
      chartAnnotation(chart, 'กระแส')
      chartIndexContainer.setAttribute('id', `chart-6`)
      const transacOptions = { axisLabelVisible: true, lastValueVisible: false, priceLineVisible: false, priceScaleId: 'right' };
      const series7_1 = chart.addLineSeries({ color: 'green', lineStyle: 0, lineWidth: 3, title: '', ...transacOptions });
      const series7_2 = chart.addLineSeries({ color: 'red', lineStyle: 0, lineWidth: 3, title: '', ...transacOptions });
      const series7_3 = chart.addLineSeries({ color: '#8B0000', lineStyle: 1, lineWidth: 2, ...transacOptions });
      const series7_4 = chart.addLineSeries({ color: '#013220', lineStyle: 1, lineWidth: 2, ...transacOptions });
      const series = [series7_1, series7_2, series7_3, series7_4];

      series.forEach((series, index) => {
        const dataForSeries = generateData(plotData['transac'][index], 'time', 'value');
        series.setData(dataForSeries);

        series.applyOptions({
          priceFormat: {
            type: 'volume',
          },
        });
      });
      let s50_series = [];
      if (plotData['s50_ind']['transac_s50']) {
        series.forEach(seri => {
          seri.applyOptions({
            visible: false
          })
        })

        const s50_transacOptions = { axisLabelVisible: true, lastValueVisible: false, priceLineVisible: false, priceScaleId: 'left' };
        const s50_series7_1 = chart.addLineSeries({ color: 'green', lineStyle: 0, lineWidth: 3, title: '', ...s50_transacOptions });
        const s50_series7_2 = chart.addLineSeries({ color: 'red', lineStyle: 0, lineWidth: 3, title: '', ...s50_transacOptions });
        const s50_series7_3 = chart.addLineSeries({ color: '#8B0000', lineStyle: 1, lineWidth: 2, ...s50_transacOptions });
        const s50_series7_4 = chart.addLineSeries({ color: '#013220', lineStyle: 1, lineWidth: 2, ...s50_transacOptions });

        const transac_series = [s50_series7_1, s50_series7_2, s50_series7_3, s50_series7_4];

        transac_series.forEach((seri, index) => {
          const dataForSeries = generateData(plotData['s50_ind']['transac_s50'][index], 'time', 'value');
          seri.setData(dataForSeries);

          seri.applyOptions({
            priceFormat: {
              type: 'volume',
            },
          });
          s50_series.push(seri);
        });
        const buttonContainer = document.createElement('div');
        buttonContainer.setAttribute('style', 'padding-left: 80%; position: absolute; z-index: 1;');

        const eq = hideSeriesButton(series, 'Eq', '#FF4D02')
        const s50 = hideSeriesButton(s50_series, 'S50', '#FF4D02')

        buttonContainer.appendChild(eq);
        buttonContainer.appendChild(s50);

        filterContainer.appendChild(buttonContainer);
      } else {
      }
      return [chart, [...series, ...s50_series], chartIndexContainer]
    }
    function generateIndexPeak() {
      const chartIndexContainer = document.createElement('div');
      chartIndexContainer.classList.add('chart-container');
      chartIndexContainer.setAttribute('id', `chart-7`)

      const filterContainer = document.createElement('div');
      filterContainer.setAttribute('style', 'position: relative; overflow: visible; z-index: 3;');
      chartIndexContainer.appendChild(filterContainer);

      const chart = createChart(chartIndexContainer, chartOption);
      chartAnnotation(chart, 'Peak');

      const series_1 = chart.addLineSeries({ color: 'red', lineStyle: 0, lineWidth: 2, title: 'eq_bid', lastValueVisible: false, priceLineVisible: false, priceScaleId: 'right' });
      const series_2 = chart.addLineSeries({ color: 'green', lineStyle: 0, lineWidth: 2, title: 'eq_ask', lastValueVisible: false, priceLineVisible: false, priceScaleId: 'right' })
      const series_3 = chart.addLineSeries({ color: 'black', lineStyle: 0, lineWidth: 2, title: 'eq_sum', lastValueVisible: false, priceLineVisible: false, priceScaleId: 'right' })

      const series = [series_1, series_2, series_3];
      series.forEach((seri, index) => {
        const dataForSeries = generateData(plotData['swap'][index], 'time', 'value');
        seri.setData(dataForSeries);

      })
      let s50_series = [];
      if (plotData['s50_ind']['swap_s50']) {
        series.forEach(seri => {
          seri.applyOptions({
            visible: false
          })
        })

        const s50_series_1 = chart.addLineSeries({ color: 'red', lineStyle: 0, lineWidth: 2, title: 's50_bid', lastValueVisible: false, priceLineVisible: false, priceScaleId: 'left' });
        const s50_series_2 = chart.addLineSeries({ color: 'green', lineStyle: 0, lineWidth: 2, title: 's50_ask', lastValueVisible: false, priceLineVisible: false, priceScaleId: 'left' });
        const s50_series_3 = chart.addLineSeries({ color: 'black', lineStyle: 0, lineWidth: 2, title: 's50_sum', lastValueVisible: false, priceLineVisible: false, priceScaleId: 'left' });

        const swap_series = [s50_series_1, s50_series_2, s50_series_3];
        swap_series.forEach((seri, index) => {
          const dataForSeries = generateData(plotData['s50_ind']['swap_s50'][index], 'time', 'value');
          seri.setData(dataForSeries);
          s50_series.push(seri);
        });
        const buttonContainer = document.createElement('div');
        buttonContainer.setAttribute('style', 'padding-left: 80%; position: absolute; z-index: 1;');

        const eq = hideSeriesButton(series, 'Eq', '#FF4D02')
        const s50 = hideSeriesButton(s50_series, 'S50', '#FF4D02')

        buttonContainer.appendChild(eq);
        buttonContainer.appendChild(s50);

        filterContainer.appendChild(buttonContainer);
      } else {

      }
      return [chart, [...series, ...s50_series], chartIndexContainer];
    }
    const [chart0, series0, chartIndexContainer0] = generateIndexLast();
    const [chart1, series1, chartIndexContainer1] = generateIndexFlow();
    const [chart4, series4, chartIndexContainer4] = generateIndexoutstanding();
    const [chart5, series5, chartIndexContainer5] = generateIndexTick();
    const [chart6, series6, chartIndexContainer6] = generateIndexTransaction();
    const [chart7, series7, chartIndexContainer7] = generateIndexMkb();
    const [chart8, series8, chartIndexContainer8] = generateIndexoutstanding();

    console.log('result1:', { chart0, series0, chartIndexContainer0 });
    console.log('result2:', { chart1, series1, chartIndexContainer1 });
    console.log('result3:', { chart4, series4, chartIndexContainer4 });
    console.log('result4:', { chart5, series5, chartIndexContainer5 });
    console.log('result5:', { chart6, series6, chartIndexContainer6 });
    console.log('result6:', { chart7, series7, chartIndexContainer7 });
    console.log('result7:', { chart8, series8, chartIndexContainer8 });

    let allSeries, charts, allIndexChartContainers, allIndexGenerators;

    if (symbol === 'SET50') {
      const [chart2, series2, chartIndexContainer2] = generateIndexFlowS50();
      const [chart3, series3, chartIndexContainer3] = generateIndexChartS50Last();
      const [chart7, series7, chartIndexContainer7] = generateIndexBidask();
      const [chart8, series8, charIndexContainer8] = generateS50Scoring();

      allSeries = [series0, series2, series3, series4, series8];
      charts = [chart0, chart2, chart3, chart4, chart8];
      allIndexChartContainers = [chartIndexContainer0, chartIndexContainer2, chartIndexContainer3, chartIndexContainer4, charIndexContainer8];
      allIndexGenerators = [generateIndexLast, generateIndexFlowS50, generateIndexChartS50Last, generateIndexoutstanding, generateS50Scoring];
    } else {
      allSeries = [series0, series1, series4, series5, series6];
      charts = [chart0, chart1, chart4, chart5, chart6];
      allIndexChartContainers = [chartIndexContainer0, chartIndexContainer1, chartIndexContainer5, chartIndexContainer6, chartIndexContainer8];
      allIndexGenerators = [generateIndexLast, generateIndexFlow, generateIndexBidask, generateIndexTick, generateIndexTransaction, generateIndexoutstanding];
    }
    var peak = false;
    if (peak) {
      const [chart_peak, series_peak, chartContainerPeak] = generateIndexPeak();
      allChartContainers.push(chartContainerPeak);
      allSeries.push(series_peak);
      chart_peak.push(series_peak);
    }

    charts.forEach((currentChart, currentIndex) => {
      currentChart.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
        charts.forEach((otherChart, otherIndex) => {
          if (currentIndex !== otherIndex) {
            otherChart.timeScale().setVisibleLogicalRange(timeRange);
          }
        });
      });
    });
    charts.forEach((currentChart, currentIndex) => {
      currentChart.subscribeCrosshairMove(param => {
        const dataPoint = getCrosshairDataPoint(allSeries[currentIndex][0], param);

        charts.forEach((otherChart, otherIndex) => {
          if (currentIndex !== otherIndex) {
            allSeries[otherIndex].forEach(otherSeries => {
              syncCrosshair(otherChart, otherSeries, dataPoint);
            });
          }
        });
      });
    });
    function renderChartInNewWindow(chartIndexContainer, generateIndexChart) {
      const windowWidth = 800;
      const windowHeight = 600;
      const windowLeft = window.screen.width / 2 - windowWidth / 2;
      const windowTop = window.screen.height / 2 - windowHeight / 2;
      const newWindow = window.open('', 'Chart Window', `width=${windowWidth},height=${windowHeight},left=${windowLeft},top=${windowTop}`);
      newWindow.document.write('<html><head><title>Chart Popup</title></head><body></body></html>');
      const container = newWindow.document.createElement('div');
      container.classList.add('grid', 'grid-cols-[1fr,3fr]', 'grid-rows-1', 'gap-2');
      newWindow.document.body.appendChild(container);
      const tailwindLink = newWindow.document.createElement('link');
      tailwindLink.href = 'https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css';
      tailwindLink.rel = 'stylesheet';
      newWindow.document.head.appendChild(tailwindLink);
      const nameContainer = newWindow.document.createElement('div');
      const symbolName = newWindow.document.createElement('h2');
      symbolName.textContent = symbol;
      nameContainer.appendChild(symbolName);
      container.appendChild(nameContainer);
      container.appendChild(chartIndexContainer);
      newWindow.addEventListener('DOMContentLoaded', () => {
        const [newChart, newSeries, newChartContainer] = generateIndexChart();
        container.innerHTML = '';
        container.appendChild(newChartContainer);
      });
    }
    function addRenderButton(generateIndexChart, chartIndexContainer) {
      const [chart, series, container] = generateIndexChart();
      document.querySelectorAll('.newtabButton').forEach(button => {
        button.removeEventListener('click', () => renderChartInNewWindow(container, generateIndexChart));
      });
      const renderButton = document.createElement('button');
      renderButton.setAttribute('class', 'btn');
      renderButton.setAttribute('style', 'z-index:50; position:absolute;');
      renderButton.classList.add("newtabButton");
      renderButton.textContent = '🔎';
      renderButton.addEventListener('click', clickHandler);
      const renderButtonContainer = document.createElement('div');
      renderButtonContainer.setAttribute('style', 'z-index:999; position:relative;')
      renderButtonContainer.appendChild(renderButton);
      chartIndexContainer.insertBefore(renderButtonContainer, chartIndexContainer.firstChild);
      function clickHandler() {
        renderChartInNewWindow(container, generateIndexChart, symbol);
      }
    }
    allIndexChartContainers.forEach((container, index) => {
      responseContainer.appendChild(container);
    });

    chatbox.appendChild(responseContainer);
  }
  function initializeDataTable(container) {
    const dataTable = container.querySelector('table');
    if (dataTable) {
      if (!$(dataTable).DataTable()) {
        $(dataTable).DataTable({
          "index": false
        });
      }
    }
  }
  let noti_group_id = ''
  function generateGuid() {
    return Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
  }
  function generateData(data, date_columm = 'date', field = 'value') {
    const timeZoneOffset = 7 * 60 * 60 * 1000;
    const cdata = data.map(d => {
      let time = timeToTz(d[date_columm], selectedTimezone)
      let time2 = (new Date(d[date_columm]).getTime() + timeZoneOffset) / 1000

      return { time: time2, value: parseFloat(d[field]) || 0 }
    });
    return cdata;
  }
  function timeToTz(originalTime, timeZone) {
    const zonedDate = new Date(new Date(originalTime).toLocaleString('en-US', { timeZone }));
    const options = {
      timeZone: timeZone,
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false,
    };
    const formatter = new Intl.DateTimeFormat('en-US', options);
    return zonedDate.getTime() / 1000;
  }
  function getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  function generateUniqueRandomColors(length) {
    const uniqueColors = new Set();

    while (uniqueColors.size < length) {
      const color = getRandomColor();
      uniqueColors.add(color);
    }

    return Array.from(uniqueColors);
  }
  function syncCrosshair(chart, series, dataPoint) {
    if (dataPoint) {
      chart.setCrosshairPosition(dataPoint.value, dataPoint.time, series);
      return;
    }
    chart.clearCrosshairPosition();
  }
  function getCrosshairDataPoint(series, param) {
    if (!param.time) {
      return null;
    }
    const dataPoint = param.seriesData.get(series);
    return dataPoint || null;
  }
  ///////////////////
  const [selectedMarket, setSelectedMarket] = React.useState("SET100");

  const handleDropdownChange = (event) => {
    setSelectedMarket(event.target.value);
  };

  return (
    <div className="dashboard" style={{ display: 'flex', justifyContent: 'center', padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <div style={{ width: '100%' }}>
        <h1 style={{ textAlign: 'center', marginBottom: '20px', fontFamily: 'Arial, sans-serif' }}>Index</h1>
        <div>
          <label htmlFor="market-select" style={{ fontSize: '16px', marginRight: '10px' }}>Select Market: </label>
          <select id="market-select" value={selectedMarket} onChange={handleDropdownChange}>
            <option value="SET100">SET100</option>
            <option value="NON-SET100">NON-SET100</option>
            <option value="MAI">MAI</option>
            <option value="WARRANT">WARRANT</option>
          </select>
          <App market={selectedMarket} type={type} />
        </div>
        <div className="chart-section" style={{ fontFamily: 'Arial, sans-serif' }}>
          <div className="chart-table-container" style={{ display: 'flex', flexDirection: 'row', gap: '20px', fontFamily: 'Arial, sans-serif', }}>
            <div className="chart-container" style={{ width: '60%', position: 'relative', fontFamily: 'Arial, sans-serif' }}
              ref={chartContainerRef}>
            </div>
            <div className="table-container" style={{ width: '20%', display: 'flex', flexDirection: 'column', gap: '10px', fontFamily: 'Arial, sans-serif', }}>
              {renderTable(0, 10)}
            </div>
            <div className="table-container" style={{ width: '20%', display: 'flex', flexDirection: 'column', gap: '10px', fontFamily: 'Arial, sans-serif', }}>
              {renderTable(10, 20)}
            </div>
          </div>
        </div>
        <br />
        <style>
          {`
    .divHeader {
  position: relative;
  top: -90%;
  left: 16%;
  width: 60%;
  display: flex;
  justify-content: space-between;
  z-index: 90000000 !important;
  }

  `}
        </style>
        {/* <Grid>
          <Container style={{ height: 'calc(47vh - var(--top-bar-height))', display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                overflowY: 'auto',
              }}>
              <div id="chatbox" style={{ width: '100%', height: '250%' }}>
                {isMessageSent && (
                  <LoadingIndicator />
                )}
              </div>
            </div>

            <TextField
              fullWidth
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={(event) => { if (event.key === 'Enter') handleSendMessage(); }}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <Button
                      variant="contained"
                      fullWidth
                      sx={{
                        width: "auto",
                        height: "auto"
                      }}
                      type="button"
                      onClick={handleSendMessage}
                    >
                      Search
                    </Button>
                  </InputAdornment>
                )
              }}
            />
          </Container>
        </Grid> */}
      </div>
    </div>
  );
};

export default App;