import React, { useEffect, useRef, useState } from 'react';
import { createChart } from 'lightweight-charts';
import { useSelector } from "react-redux";
import './Hisflow.css'; // Reuse the same CSS from your Realflow or adapt as needed
import { useIsMobile, useIsPortraitTablet, useIsTablet } from '../../components/hooks';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Box, useMediaQuery, useTheme, IconButton } from '@mui/material'
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const earthToneColor = '#D2B48C';
const getOppositeColor = () => '#0d0082';

// Utility to format the date as YYYY-MM-DD
const formatDateString = (date) => {
  // Ensure it's a valid date object
  if (!(date instanceof Date) || isNaN(date)) {
    return '';
  }
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

// Convert server UTC timestamps to local time
const adjustToLocalTime = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const timezoneOffset = date.getTimezoneOffset() * 60;
  return timestamp - timezoneOffset;
};

/**
 * ChartSectionWithMultipleLines
 * --------------------------------
 * Renders a single chart section with two tables (ranks 0-10, 10-20).
 * Fetches historical flow data for a selected date from the endpoint:
 *   http://172.18.1.81:3101/flow/hist/${market}&${formattedDate}
 */
const ChartSectionWithMultipleLines = ({ title, market, type, selectedDate }) => {
  const chartContainerRef = useRef(null);
  const chartRef = useRef(null);
  const lineSeriesRef = useRef(new Map());
  const seriesDataRef = useRef(new Map());
  const originalColorsRef = useRef(new Map());

  const [isCollapsed, setIsCollapsed] = useState(false);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isPTablet = useIsPortraitTablet();

  const [tableData, setTableData] = useState([]);
  const tableDataRef = useRef([]);
  const [previousRanks, setPreviousRanks] = useState({});
  const previousRanksRef = useRef(previousRanks);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedSymbol, setSelectedSymbol] = useState(null);

  const csrfToken = useSelector((state) => state.csrfToken);

  // For "out" type, we invert the scale and negate the values
  const shouldInvert = (type === "out");

  // Keep references in sync
  useEffect(() => {
    previousRanksRef.current = previousRanks;
  }, [previousRanks]);

  useEffect(() => {
    tableDataRef.current = tableData;
  }, [tableData]);

  /**
   * Fetch Historical Data for the selected date
   */
  const fetchHistoricalData = async () => {
    try {
      if (!selectedDate) {
        console.warn('No date selected, skipping fetch.');
        return;
      }

      const formattedDate = formatDateString(selectedDate);
      if (!formattedDate) {
        console.warn('Invalid date, skipping fetch.');
        return;
      }

      // Example endpoint: http://172.18.1.81:3101/flow/hist/SET100&2024-03-12
      const url = `${window.config.base_api_url}/flow/hist/${market}&${formattedDate}`;
      const fetch_response = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          'xsrf-token': csrfToken
        },
      });
      const response = await fetch_response.json();

      // The data structure should contain "in" and "out", same as your example
      const data = response.data[type]; // 'in' or 'out'
      if (!data) {
        console.warn(`No data found for type=${type}`);
        return;
      }

      // Build up historical data
      const historicalData = {};
      const times = Object.keys(data.rank).sort();

      const latestTime = times[times.length - 1];
      const latestSymbols = Object.keys(data.rank[latestTime]);

      times.forEach((time) => {
        // Convert to timestamp
        const timestamp = Math.floor(new Date(time).getTime() / 1000);

        Object.keys(data.rank[time]).forEach((symbol) => {
          if (!historicalData[symbol]) {
            historicalData[symbol] = [];
          }
          const rankValue = data.rank[time][symbol];
          const rawValue = data.value[time][symbol];
          const finalValue = shouldInvert ? -1 * rawValue : rawValue;

          historicalData[symbol].push({
            time: adjustToLocalTime(timestamp),
            rank: rankValue,
            value: finalValue,
          });
        });
      });

      // Create line series or update existing
      const newPreviousRanks = {};
      latestSymbols.forEach((symbol) => {
        const symData = historicalData[symbol];
        if (!symData || symData.length === 0) {
          newPreviousRanks[symbol] = null;
          return;
        }

        symData.sort((a, b) => a.time - b.time);
        const latestRank = symData[symData.length - 1].rank;
        newPreviousRanks[symbol] = latestRank;

        let series = lineSeriesRef.current.get(symbol);
        if (!series) {
          const color = getRandomColor();
          series = chartRef.current.addLineSeries({
            color,
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: false,
            title: symbol,
          });
          lineSeriesRef.current.set(symbol, series);
          originalColorsRef.current.set(symbol, color);
        }
        const seriesData = symData.map(dp => ({
          time: dp.time,
          value: dp.value,
        }));
        series.setData(seriesData);
        seriesDataRef.current.set(symbol, seriesData);
      });

      // Remove symbols no longer present
      const allSymbols = Array.from(lineSeriesRef.current.keys());
      const toRemove = allSymbols.filter(sym => !latestSymbols.includes(sym));
      toRemove.forEach((sym) => {
        const s = lineSeriesRef.current.get(sym);
        if (s) {
          chartRef.current.removeSeries(s);
        }
        lineSeriesRef.current.delete(sym);
        seriesDataRef.current.delete(sym);
        originalColorsRef.current.delete(sym);
      });

      setPreviousRanks(newPreviousRanks);

      // Prepare table data
      const updatedTableData = latestSymbols.map((symbol) => {
        const sData = historicalData[symbol];
        const latestPoint = sData[sData.length - 1];
        return {
          rank: latestPoint.rank,
          symbol,
          value: latestPoint.value,
          color: 'transparent', // We'll handle color logic if needed
        };
      });

      setTableData(updatedTableData);
    } catch (error) {
      console.error(`Error fetching historical data for ${market}, date=${selectedDate}:`, error);
    }
  };

  // Since this is purely historical data for a given date, we might not need
  // a "current" polling approach. But if you want to poll for partial data, you could:
  // const fetchCurrentData = () => {...} // if needed.

  // Handler for row click to highlight a symbol
  const handleRowClick = (symbol) => {
    if (selectedSymbol === symbol) {
      // Deselect
      setSelectedSymbol(null);
      lineSeriesRef.current.forEach((series, sym) => {
        const origColor = originalColorsRef.current.get(sym);
        series.applyOptions({
          color: origColor,
          lineWidth: 2,
        });
      });
    } else {
      setSelectedSymbol(symbol);

      const selectedSeries = lineSeriesRef.current.get(symbol);
      const data = seriesDataRef.current.get(symbol);
      if (selectedSeries && chartRef.current) {
        chartRef.current.removeSeries(selectedSeries);
        const newSeries = chartRef.current.addLineSeries({
          color: selectedSeries.options().color,
          lineWidth: selectedSeries.options().lineWidth,
          lastValueVisible: true,
          priceLineVisible: false,
          title: symbol,
        });
        newSeries.setData(data);
        lineSeriesRef.current.set(symbol, newSeries);

        const oppositeColor = getOppositeColor();
        newSeries.applyOptions({
          color: oppositeColor,
          lineWidth: 4,
        });

        // Fade others
        lineSeriesRef.current.forEach((s, otherSymbol) => {
          if (otherSymbol !== symbol) {
            s.applyOptions({
              color: earthToneColor,
              lineWidth: 2,
            });
          }
        });
      }
    }
  };

  useEffect(() => {
    // Initialize the chart
    chartRef.current = createChart(chartContainerRef.current, {
      layout: { background: { type: 'solid', color: 'white' } },
      timeScale: { timeVisible: true, rightOffset: 10 },
      height: 290,
      width: chartContainerRef.current ? chartContainerRef.current.offsetWidth : 600,
      crosshair: {
        horizontalLine: { visible: false },
        verticalLine: { visible: true },
      },
    });

    // Invert scale if needed
    if (shouldInvert) {
      chartRef.current.applyOptions({
        invertScale: true,
      });
    }

    chartRef.current.applyOptions({
      localization: {
        priceFormatter: (price) =>
          (price / 1_000_000).toLocaleString('en-US', { maximumFractionDigits: 2 }),
      },
      priceScale: {
        mode: 0,
      },
    });

    // Fetch the historical data for the selected date
    fetchHistoricalData();

    return () => {
      if (chartRef.current) {
        chartRef.current.remove();
      }
    };
    // Re-run fetch whenever 'selectedDate', 'market', or 'type' changes
  }, [selectedDate, market, type]);

  const toggleSeriesVisibility = (symbol) => {
    const series = lineSeriesRef.current.get(symbol);
    if (series) {
      const currentVisible = series.options().visible ?? true;
      series.applyOptions({ visible: !currentVisible });
    }
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed); // เปลี่ยนสถานะพับ/ขยาย
  };

  // Dynamic Chart Width

  useEffect(() => {
    if (!chartContainerRef.current || !chartRef.current) return;

    const updateChartSize = () => {
      if (chartRef.current) {
        chartRef.current.resize(
          chartContainerRef.current.offsetWidth,
          chartContainerRef.current.offsetHeight
        );
      }
    };

    // ปรับขนาดเมื่อ isCollapsed เปลี่ยน
    updateChartSize();

    // สร้าง ResizeObserver
    const resizeObserver = new ResizeObserver(() => {
      requestAnimationFrame(updateChartSize);
    });

    resizeObserver.observe(chartContainerRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [isCollapsed]);

  const renderTable = (startIndex, endIndex) => (
    <table className="rank-table" style={{
      fontSize: '11.5px',
      fontFamily: 'Arial, Helvetica, sans-serif',
      border: '1px solid #ddd',
      borderCollapse: 'collapse',
    }}>
      <thead>
        <tr>
          <th style={{ textAlign: 'center', padding: '3px', backgroundColor: '#fdf5e6' }}>Rank</th>
          <th style={{ textAlign: 'center', padding: '3px', backgroundColor: '#fdf5e6' }}>Symbol</th>
          <th style={{ textAlign: 'center', padding: '3px', backgroundColor: '#fdf5e6' }}>Value</th>
        </tr>
      </thead>
      <tbody>
        {tableData
          .sort((a, b) => a.rank - b.rank)
          .slice(startIndex, endIndex)
          .map((row, index) => (
            <tr
              key={index}
              style={{
                backgroundColor: row.color,
                cursor: 'pointer',
                fontWeight: row.symbol === selectedSymbol ? 'bold' : 'normal',
                transition: 'background-color 0.3s ease',
                borderBottom: '1px solid #ddd'
              }}
              onClick={() => handleRowClick(row.symbol)}
            >
              <td style={{ padding: '3px', textAlign: 'center' }}>{row.rank}</td>
              <td style={{ padding: '3px', textAlign: 'center' }}>{row.symbol}</td>
              <td style={{ padding: '3px', textAlign: 'center' }}>{(row.value / 1_000_000).toLocaleString('en-US', { maximumFractionDigits: 2 })}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );

  return (
    <div className="chart-section" style={{ fontFamily: 'Arial, sans-serif' }}>
      <h3 style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {title}
        {/* If you want a fullscreen for historical data, you can do similarly: */}
        <button
          onClick={() => {
            const dateStr = formatDateString(selectedDate); // Convert selectedDate to YYYY-MM-DD
            const url = `/fullscreenhisrealflow?market=${market}&type=${type}&date=${dateStr}&title=${encodeURIComponent(title)}`;
            window.open(url, '_blank');
          }}
          className="expand-button"
        >
          🔍
        </button>
      </h3>
      <div className="chart-table-container" style={{ display: 'flex', flexDirection: 'row', gap: isMobile ? '2vw' : '5px' }}>
        <div
          ref={chartContainerRef}
          className="chart-container"
          style={{ width: '60%', position: 'relative' }}
        >
          {/* <button
            onClick={() => setDropdownOpen(!dropdownOpen)}
            style={{
              position: 'absolute',
              top: 5,
              right: 5,
              zIndex: 10,
              fontFamily: 'Arial, sans-serif',
            }}
          >
            Show/Hide Lines
          </button> */}
          {dropdownOpen && (
            <div
              style={{
                position: 'absolute',
                top: '40px',
                right: 5,
                background: 'white',
                border: '1px solid #ccc',
                padding: '10px',
                zIndex: 20,
                maxHeight: '200px',
                overflowY: 'scroll',
                fontFamily: 'Arial, sans-serif',
              }}
            >
              {Array.from(lineSeriesRef.current.keys()).map((symbol) => (
                <div key={symbol}>
                  <input
                    id={`toggle-${symbol}`}
                    type="checkbox"
                    checked={lineSeriesRef.current.get(symbol)?.options().visible ?? true}
                    onChange={() => toggleSeriesVisibility(symbol)}
                  />
                  <label htmlFor={`toggle-${symbol}`}>{symbol}</label>
                </div>
              ))}
            </div>
          )}
        </div>
        <div
          className="table-container"
          style={{
            width: '20%',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            overflowY: 'auto',
            maxHeight: '80vh',
          }}
        >
          {renderTable(0, 10)}
        </div>
        <div
          className="table-container"
          style={{
            width: '20%',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            overflowY: 'auto',
            maxHeight: '80vh',
          }}
        >
          {renderTable(10, 20)}
        </div>
      </div>
    </div>
  );
};

/**
 * Main Hisflow Component
 * --------------------------------
 * - Renders a date picker at the top.
 * - Renders multiple <ChartSectionWithMultipleLines> for each market and type ("in" or "out").
 */
const Hisflow = () => {
  function getDefaultDate() {
    const date = new Date();

    // Step 1: Subtract one day (i.e., "yesterday")
    date.setDate(date.getDate() - 1);

    // Step 2: If "yesterday" is Saturday (6) or Sunday (0), set it to Friday
    if (date.getDay() === 6) {
      // Saturday → go back 1 more day
      date.setDate(date.getDate() - 1);
    } else if (date.getDay() === 0) {
      // Sunday → go back 2 more days
      date.setDate(date.getDate() - 2);
    }

    return date;
  }

  // Usage in a React component:
  const [selectedDate, setSelectedDate] = useState(() => getDefaultDate());

  // Inline styling for date picker to place under the main title
  const datePickerStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    margin: '10px 0',
    fontFamily: 'Arial, Helvetica, sans-serif',
  };

  return (
    <div
      className="dashboard"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        fontFamily: 'Arial, sans-serif',
      }}
    >
      <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>RealFlow history</h1>

      {/* Date Picker */}
      <div className="date-picker-container" style={datePickerStyle}>
        <label htmlFor="date-picker" style={{ fontWeight: 'bold' }}>Select Date:</label>
        <input
          id="date-picker"
          type="date"
          value={selectedDate.toISOString().split('T')[0]}
          onChange={(e) => {
            // Build new date from e.target.value
            const newDate = new Date(e.target.value);
            setSelectedDate(newDate);
          }}
          style={{
            padding: '4px',
            fontFamily: 'Arial, Helvetica, sans-serif',
          }}
        />
      </div>

      <div
        className="sections"
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '20px',
          width: '100%',
        }}
      >
        {/* SET100 and NON-SET100 for "in" */}
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <ChartSectionWithMultipleLines
            title="SET100 Hist In (+)"
            market="SET100"
            type="in"
            selectedDate={selectedDate}
          />
          <ChartSectionWithMultipleLines
            title="NON-SET100 Hist In (+)"
            market="NON-SET100"
            type="in"
            selectedDate={selectedDate}
          />
        </div>

        {/* SET100 and NON-SET100 for "out" */}
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <ChartSectionWithMultipleLines
            title="SET100 Hist Out (-)"
            market="SET100"
            type="out"
            selectedDate={selectedDate}
          />
          <ChartSectionWithMultipleLines
            title="NON-SET100 Hist Out (-)"
            market="NON-SET100"
            type="out"
            selectedDate={selectedDate}
          />
        </div>

        {/* MAI and WARRANT for "in" */}
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <ChartSectionWithMultipleLines
            title="MAI Hist In (+)"
            market="MAI"
            type="in"
            selectedDate={selectedDate}
          />
          <ChartSectionWithMultipleLines
            title="WARRANT Hist In (+)"
            market="WARRANT"
            type="in"
            selectedDate={selectedDate}
          />
        </div>

        {/* MAI and WARRANT for "out" */}
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <ChartSectionWithMultipleLines
            title="MAI Hist Out (-)"
            market="MAI"
            type="out"
            selectedDate={selectedDate}
          />
          <ChartSectionWithMultipleLines
            title="WARRANT Hist Out (-)"
            market="WARRANT"
            type="out"
            selectedDate={selectedDate}
          />
        </div>
      </div>
    </div>
  );
};

export default Hisflow;
