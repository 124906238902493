import React from 'react';

const Table = ({ upperExceedData, lowerExceedData }) => {
  const adjustToLocalTime = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const timezoneOffset = date.getTimezoneOffset() * 60;
    return timestamp - timezoneOffset;
  };

  const renderTableRows = (data) => {
    console.log("Data before rendering:", data); // ตรวจสอบข้อมูลที่เข้ามาในฟังก์ชัน

    return data.map((row, i) => {
      // แปลงเวลาให้เป็น local time
      const adjustedTimestamp = adjustToLocalTime(Math.floor(new Date(row.time).getTime() / 1000));
      const dateObj = new Date(adjustedTimestamp * 1000);

      if (isNaN(dateObj.getTime())) {
        console.error('Invalid time value:', row.time); // แสดงข้อผิดพลาดถ้าข้อมูลเวลาไม่ถูกต้อง
        return null;
      }

      // ฟังก์ชันการแปลงตัวเลข
      const formatNumber = (number) => {
        if (isNaN(number)) {
          console.error("Invalid number:", number);
          return ''; // คืนค่าว่างในกรณีที่ไม่ใช่ตัวเลข
        }
        return number.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      };
      
      // แยกวันที่และเวลา
      const date = dateObj.toISOString().slice(0, 10); // ดึง YYYY-MM-DD
      const time = dateObj.toISOString().slice(11, 19); // ดึง HH:MM:SS

      return (
        <tr key={i} style={{ borderBottom: '1px solid #eee' }}>
          <td style={{ padding: '3px', width: '100px', textAlign: 'center' }}>{date}</td>
          <td style={{ padding: '3px', width: '100px', textAlign: 'center' }}>{time}</td>
          <td style={{ padding: '3px', width: '100px', textAlign: 'center' }}>{row.last ? formatNumber(row.last) : ''}</td>
          <td style={{ padding: '3px', textAlign: 'center' }}>{row.bid_vol !== null && row.bid_vol !== undefined ? formatNumber(row.bid_vol) : '0'}</td>
          <td style={{ padding: '3px', textAlign: 'center' }}>{row.ask_vol ? formatNumber(row.ask_vol) : ''}</td>
          <td style={{ padding: '3px', textAlign: 'center' }}>{row.spread_vol ? formatNumber(row.spread_vol) : ''}</td>
          <td style={{ padding: '3px', textAlign: 'center' }}>{row.flow ? formatNumber(row.flow) : ''}</td>
        </tr>
      );
    });
  };

  // ตรวจสอบข้อมูลก่อนการเรนเดอร์
  console.log("Upper Exceed Data:", upperExceedData);
  console.log("Lower Exceed Data:", lowerExceedData);
  return (
    <div style={{ display: 'flex', gap: '20px', marginLeft: '90px' }}>
      <div>
        <h3 style={{ textAlign: 'center' }}>Spread Volume &gt; Upper Bound</h3>
        <table style={{ border: '1px solid #ccc', borderCollapse: 'collapse', fontSize: '14px' }}>
          <thead>
          <tr style={{ borderBottom: '2px solid #ccc' }}>
    <th style={{ textAlign: 'center', padding: '3px', border: '1px solid #ccc', width: '40px' }}>Date</th>
    <th style={{ textAlign: 'center', padding: '3px', border: '1px solid #ccc', width: '40px' }}>Time</th>
    <th style={{ textAlign: 'center', padding: '3px', border: '1px solid #ccc', width: '40px' }}>Last</th>
    <th style={{ textAlign: 'center', padding: '3px', border: '1px solid #ccc', width: '80px' }}>Bid Vol</th>
    <th style={{ textAlign: 'center', padding: '3px', border: '1px solid #ccc', width: '80px' }}>Ask Vol</th>
    <th style={{ textAlign: 'center', padding: '3px', border: '1px solid #ccc', width: '80px' }}>Spread Vol</th>
    <th style={{ textAlign: 'center', padding: '3px', border: '1px solid #ccc', width: '100px' }}>Flow</th>
  </tr>
          </thead>
          <tbody>{renderTableRows(upperExceedData)}</tbody>
        </table>
      </div>

      <div>
        <h3 style={{ textAlign: 'center' }}>Spread Volume &lt; Lower Bound</h3>
        <table style={{ border: '1px solid #ccc', borderCollapse: 'collapse', fontSize: '14px' }}>
          <thead>
          <tr style={{ borderBottom: '2px solid #ccc' }}>
    <th style={{ textAlign: 'center', padding: '3px', border: '1px solid #ccc', width: '40px' }}>Date</th>
    <th style={{ textAlign: 'center', padding: '3px', border: '1px solid #ccc', width: '40px' }}>Time</th>
    <th style={{ textAlign: 'center', padding: '3px', border: '1px solid #ccc', width: '40px' }}>Last</th>
    <th style={{ textAlign: 'center', padding: '3px', border: '1px solid #ccc', width: '80px' }}>Bid Vol</th>
    <th style={{ textAlign: 'center', padding: '3px', border: '1px solid #ccc', width: '80px' }}>Ask Vol</th>
    <th style={{ textAlign: 'center', padding: '3px', border: '1px solid #ccc', width: '80px' }}>Spread Vol</th>
    <th style={{ textAlign: 'center', padding: '3px', border: '1px solid #ccc', width: '100px' }}>Flow</th>
  </tr>
          </thead>
          <tbody>{renderTableRows(lowerExceedData)}</tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
