import React from "react";
import AreaSection from "./AreaSection";
import HotStockSection from "./HotStockSection";

function Hotstock() {
  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <h1>Real time Hot Stock</h1>

      <hr style={{ margin: "20px 0" }} />

      {/* Area Section */}
      <AreaSection />

      <hr style={{ margin: "20px 0" }} />

      {/* Hot Stock Section */}
      <HotStockSection />
    </div>
  );
}

export default Hotstock;
