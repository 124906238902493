import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, Paper, CircularProgress , Button ,Alert,Snackbar} from '@mui/material';
import { __fetching_data__ } from '../../components/api.js';

import convertThaiDateToJSDate from './component/fn';


const base_api_url =
    process.env.NODE_ENV === "production"
        ? window.location.protocol === "https:"
            ? process.env.REACT_APP_API_HTTPS_URL
            : process.env.REACT_APP_API_HTTP_URL
        : "/application";

const LinkNewsComponent = () => { 
    const [news, setNews] = useState([]);
    const [linkname, setLinkName] = useState('');
    const [selectedHeadlines, setSelectedHeadlines] = useState([]); // เก็บ headline ที่ถูกเลือก
    const [isAtBottom, setIsAtBottom] = useState(true); // ตรวจสอบว่าปุ่มอยู่ด้านล่างสุดหรือไม่
    const [isLoading, setIsLoading] = useState(true); // สถานะการโหลด
    const [isDeleting, setIsDeleting] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('success');
    const [showAlert, setShowAlert] = useState(false);

    const csrfToken = useSelector((state) => state.csrfToken);

    // ตรวจสอบตำแหน่งของ Box
    useEffect(() => {
        const handleScroll = () => {
            const windowHeight = window.innerHeight;
            const bodyHeight = document.body.scrollHeight;
            const scrollPosition = window.scrollY;
    
            const isAtBottom = windowHeight + scrollPosition >= bodyHeight - 1; // ตรวจสอบว่าถึงด้านล่างสุดแล้วหรือยัง
    
            setIsAtBottom(isAtBottom);
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Fetch news from backend
    useEffect(() => {
        const fetchNews = async () => {
            try {
                setIsLoading(true);
                
                // ดึง path จาก URL
                const pathname = window.location.pathname;
                // ตัด `/linknews/` ออกและแปลง URL Encoded เป็นข้อความ
                const linkName = decodeURIComponent(pathname.replace('/linknews/', ''));
                
                // ตรวจสอบว่า linkName มีค่า
                if (!linkName) {
                    throw new Error('Invalid link name');
                }
                
                setLinkName(linkName);
    
                // encode linkName ใน URL
                const encodedLinkName = encodeURIComponent(linkName);
                
                const response = await __fetching_data__({
                    url: `${base_api_url}/check_news_type/${encodedLinkName}`,
                    method: "GET",
                    headers: { 
                        "xsrf-token": csrfToken,
                    },
                });

                if (!response || !response.data) {
                    throw new Error('Invalid response data');
                }

                setNews(
                    response.data.data.map((item) => ({
                        date: item.Date,
                        time: item.Time,
                        symbol: item.Symbol,
                        source: item.Source,
                        headline: item.Headline
                    })).sort((a, b) => {
                        const datetimeA = convertThaiDateToJSDate(a.date, a.time);
                        const datetimeB = convertThaiDateToJSDate(b.date, b.time);
                        return datetimeB - datetimeA; // เรียงจากล่าสุดไปเก่าสุด
                    })
                );
    
            } catch (error) {
                console.error('Error fetching news:', error);
                showAlertMessage('ไม่สามารถโหลดข้อมูลข่าวได้', 'error');
            } finally {
                setIsLoading(false);
            }
        };
    
        fetchNews();

    }, [__fetching_data__, csrfToken]);

    const handleCheckboxChange = (headline) => {
        setSelectedHeadlines((prevSelected) => {
            if (prevSelected.includes(headline)) {
                return prevSelected.filter((h) => h !== headline);
            } else {
                return [...prevSelected, headline];
            }
        });
    };

    const showAlertMessage = (message, severity) => {
        setAlertMessage(message);
        setAlertSeverity(severity);
        setShowAlert(true);
    };
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    };

    const handleConfirmDelete = async () => {
        if (isDeleting) return; // Prevent multiple clicks while processing

        setIsDeleting(true);
        try {
            for (const headline of selectedHeadlines) {
                const payload = {
                    word: linkname,
                    except_sub_words: [headline] // ส่ง array ของ headlines ที่ถูกเลือก
                };

                const response = await __fetching_data__({ 
                    url: `${base_api_url}/add_except_sub_words/`, 
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'xsrf-token': csrfToken,
                    },
                    body: JSON.stringify(payload),
                });

                // ตรวจสอบว่า response เป็น JSON หรือไม่
                let result;
                if (response && typeof response.json === 'function') {
                    result = await response.json(); // กรณี response เป็น Response object
                } else {
                    result = response; // กรณี response เป็น JSON object อยู่แล้ว
                }
        
                if (response.ok || result.status) {
                    showAlertMessage(result.data.message || `ลบข่าวสำเร็จ: ${headline}`, 'success');
                } else {
                    showAlertMessage(result.data.message || `ไม่สามารถลบข่าวได้: ${headline}`, 'error');
                }            
                // รอสักครู่เพื่อแสดงผลทีละข้อความ (optional)
                await new Promise((resolve) => setTimeout(resolve, 3000));
            }

        // รีเซ็ตการเลือกข่าวหลังประมวลผล
        setSelectedHeadlines([]);
        // โหลดข้อมูลใหม่หลังจากลบเสร็จ (ถ้าต้องการ)
        setTimeout(() => {
            window.location.reload();
        }, 3000);

        } catch (error) {
            console.error('Error updating news status:', error);
            showAlertMessage('เกิดข้อผิดพลาดในการลบข่าว', 'error');
        }
    };


    return (
        <>
            <Box
                sx={{
                    maxWidth: 600,
                    marginTop: '16px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    padding: 2,
                    backgroundColor: 'white',
                    borderRadius: 3,
                    boxShadow: 2,
                    fontFamily: 'Arial, sans-serif',
                }}
            >
                <Typography variant="h4" gutterBottom>
                    {linkname}
                </Typography>

                {isLoading ? (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '200px',
                        }}
                    >
                        <CircularProgress />
                    </Box>
                ) : (
                    <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>วันที่/เวลา</TableCell>
                                    <TableCell>หลักทรัพย์</TableCell>
                                    <TableCell>แหล่งข่าว</TableCell>
                                    <TableCell>หัวข้อข่าว</TableCell>
                                    <TableCell>เลือก</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {news.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell style={{ whiteSpace: 'nowrap' }}>
                                            {item.date || 'ข่าววันนี้'} <br /> {item.time || '-'}
                                        </TableCell>                                        
                                        <TableCell>{item.symbol || '-'}</TableCell>
                                        <TableCell>{item.source || '-'}</TableCell>
                                        <TableCell>{item.headline}</TableCell>
                                        <TableCell>
                                            <Checkbox
                                                checked={selectedHeadlines.includes(item.headline)}
                                                onChange={() => handleCheckboxChange(item.headline)}
                                                disabled={isDeleting}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}

                <Box
                    sx={{
                        position: 'sticky',
                        bottom: 16,
                        marginTop: '16px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        backgroundColor: 'white',
                        padding: 2,
                        borderRadius: 3,
                        boxShadow: isAtBottom ? 'none' : 3,
                        zIndex: 1000,
                        border: '1px solid #ddd',
                    }}
                >
                    <Button
                        variant="contained"
                        color="error"
                        onClick={handleConfirmDelete}
                        disabled={selectedHeadlines.length === 0 || isDeleting}
                        sx={{
                            borderRadius: 2,
                            boxShadow: 'none',
                        }}
                    >
                        {isDeleting ? <CircularProgress size={24} color="inherit" /> : 'Delete'}
                    </Button>
                </Box>
            </Box>

            <Snackbar 
                open={showAlert} 
                autoHideDuration={6000} 
                onClose={handleCloseAlert}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert 
                    onClose={handleCloseAlert} 
                    severity={alertSeverity} 
                    sx={{ width: '100%' }}
                >
                    {alertMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default LinkNewsComponent;
