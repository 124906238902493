import React, { useEffect, useRef, useState } from 'react';
import { createChart, LineStyle } from 'lightweight-charts';
// import axios from 'axios';
import { FaSearch, FaTimes } from 'react-icons/fa'; // นำเข้าไอคอนแว่นขยายและปิด
import { useDispatch, useSelector } from 'react-redux';
import Loading from './Loading';
import { useIsMobile, useIsPortraitTablet, useIsTablet } from '../../components/hooks';
import { IconButton, Box, } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const API_BASE_URL = "http://172.18.1.81:3100/money_in_out/hist/";

const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

// ฟังก์ชันปรับเวลาให้ตรงกับเขตเวลาท้องถิ่น
const adjustToLocalTime = (timestamp) => {
  const date = new Date(timestamp * 1000); // สร้าง Date จาก timestamp
  const timezoneOffset = date.getTimezoneOffset() * 60; // หาค่าชดเชย timezone ในหน่วยวินาที
  return timestamp - timezoneOffset; // ปรับ timestamp ให้ตรงกับ Local Time
};

const ChartSectionWithMultipleLines = ({ title, market, type, selectedDate }) => {
  const chartContainerRef = useRef(null);
  const chartTableContainerRef = useRef(null);
  const chartRef = useRef(null);
  const lineSeriesRef = useRef(new Map());
  const originalColorsRef = useRef(new Map());
  const highlightedSeriesRef = useRef(null); // เก็บซีรีส์ที่ถูกไฮไลต์
  const [tableData, setTableData] = useState([]);
  const [previousData, setPreviousData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null); // เก็บ symbol ของแถวที่ถูกเลือก
  const [isFullscreen, setIsFullscreen] = useState(false); // สถานะโหมดขยาย
  const priceLinesRef = useRef(new Map());  // ใช้ useRef เพื่อเก็บเส้นราคา
  const priceToSymbolMap = useRef(new Map());
  const [highlightedSymbol, setHighlightedSymbol] = useState(null);
  const csrfToken = useSelector((state) => state.csrfToken)
  const [highlightedRow, setHighlightedRow] = useState(null);

  const [isCollapsed, setIsCollapsed] = useState(false);

  const isMobile = useIsMobile();
  const isPTablet = useIsPortraitTablet();
  const isTablet = useIsTablet();


  // ฟังก์ชันรีเซ็ตไฮไลต์
  const resetHighlight = () => {
    lineSeriesRef.current.forEach((seriesData) => {
      seriesData.series.applyOptions({
        color: getRandomColor(), // ใช้สีสุ่ม
        lineWidth: 2, // รีเซ็ตความหนาเส้น
      });
    });

    highlightedSeriesRef.current = null;
    setHighlightedSymbol(null); // รีเซ็ตสถานะสัญลักษณ์ที่ไฮไลต์
  };

  // ฟังก์ชันไฮไลต์เส้นกราฟ
  const highlightLineSeries = (symbol) => {
    const seriesData = lineSeriesRef.current.get(symbol);
    if (!seriesData) {
      console.warn(`Series not found for symbol: ${symbol}`);
      return;
    }

    // ไฮไลต์เส้นที่เลือก
    const series = seriesData.series;
    if (series) {
      // ตั้งค่าความโปร่งใสให้เส้นอื่น ๆ
      lineSeriesRef.current.forEach((data) => {
        if (data.series !== series) {
          data.series.applyOptions({
            color: 'rgba(181, 101, 29, 0.5)', // สีพื้นฐาน
            lineWidth: 2, // ความหนาพื้นฐาน
          });
        }
      });

      series.applyOptions({
        lineWidth: 4,
        color: 'blue',
      });

      highlightedSeriesRef.current = series;
      console.log(`Highlighted series for symbol: ${symbol}`);
    } else {
      console.warn(`Series object not valid for symbol: ${symbol}`);
    }
  };

  // ฟังก์ชันจัดการคลิกที่แถวในตาราง
  const onClickRow = (symbol) => {
    console.log("Row clicked:", symbol);

    // ถ้าสัญลักษณ์ที่คลิกเป็นตัวเดียวกับที่ถูกไฮไลต์
    if (highlightedSymbol === symbol) {
      console.log("Symbol clicked again. Resetting highlight.");
      resetHighlight(); // ยกเลิกการไฮไลต์
      setHighlightedRow(null); // รีเซ็ตแถวที่ถูกเลือก
      return;
    }

    // อัปเดตสัญลักษณ์และแถวที่ถูกเลือก
    setHighlightedSymbol(symbol);
    setHighlightedRow(symbol);
    highlightLineSeries(symbol); // ไฮไลต์เส้นกราฟ
  };

  // ฟังก์ชันจัดการคลิกที่พื้นที่ว่างในกราฟ
  const handleChartClick = (e) => {
    if (!e.target.closest('.rank-table')) {
      if (highlightedSeriesRef.current) {
        console.log("Skip reset on chart click. Highlighted series exists.");
        return; // ถ้ามีเส้นไฮไลต์อยู่ ไม่รีเซ็ต
      }
      resetHighlight();
    }
  };


  // ฟังก์ชันขยายกราฟ
  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const handleExpandClick = () => {
    onExpand(title, market, type, selectedDate);
  };

  // ตั้งค่า Event Listener สำหรับการคลิก
  useEffect(() => {
    document.addEventListener('click', handleChartClick);

    return () => {
      document.removeEventListener('click', handleChartClick);
    };
  }, []);

  // ฟังก์ชันกำหนดสีของแถวในตาราง
  const getRowColor = (symbol, value) => {
    if (symbol === highlightedRow) return '#be8e60'; // สีฟ้าสำหรับแถวที่ถูกเลือก
    const previousValue = previousData[symbol];
    if (previousValue === undefined) return 'white';
    if (value > previousValue) return '#e8f5e9'; // สีเขียวสำหรับค่าที่เพิ่มขึ้น
    if (value < previousValue) return '#ffebee'; // สีแดงสำหรับค่าที่ลดลง
    return 'white';
  };

  const fetchHistoricalData = async (date) => {
    const formattedDate = date.toISOString().split('T')[0];
    try {
      setIsLoading(true);

      const fetch_response = await fetch(`${window.config.base_api_url}/money_in_out/hist/${market}?date=${formattedDate}`, {
        headers: {
          'Content-Type': 'application/json',
          'xsrf-token': csrfToken,
        },
      });

      const data = await fetch_response.json();
      console.log(data);

      if (data && data.data && data.data[type]) {
        const dataSource = data.data[type]; // ใช้ type ที่กำหนด
        const historicalData = {};

        Object.keys(dataSource.rank).forEach((time) => {
          if (dataSource.rank[time] && dataSource.value[time]) {
            Object.keys(dataSource.rank[time]).forEach((symbol) => {
              if (!historicalData[symbol]) {
                historicalData[symbol] = [];
              }

              const timestamp = Math.floor(new Date(`${formattedDate}T${time}`).getTime() / 1000);

              // เข้าถึง value โดยตรง
              const value = dataSource.value[time][symbol];

              if (value === undefined || isNaN(value)) {
                console.warn(`Invalid value for ${symbol} at time ${time}:`, value);
                return; // ข้ามหากไม่มีข้อมูล
              }

              // ถ้า type เป็น 'out' ให้ใส่เครื่องหมาย - หน้าค่า value
              const adjustedValue = type === 'out' ? -value / 1000000 : value / 1000000;

              historicalData[symbol].push({
                time: adjustToLocalTime(timestamp),
                rank: dataSource.rank[time][symbol],
                value: adjustedValue,
              });

              priceToSymbolMap.current.set(value, symbol);
            });
          }
        });



        Object.keys(historicalData).forEach((symbol) => {
          if (!lineSeriesRef.current.has(symbol)) {
            // const color = getRandomColor();
            const series = chartRef.current.addLineSeries({
              // color,
              color: getRandomColor(),
              lineWidth: 2,
              title: symbol,
              priceLineVisible: false, // ปิดการแสดงเส้นประ
            });
            lineSeriesRef.current.set(symbol, { series, symbol });
            // originalColorsRef.current.set(symbol, color);
          }
          lineSeriesRef.current.get(symbol).series.setData(
            historicalData[symbol].map((dataPoint) => ({
              time: dataPoint.time,
              value: dataPoint.value,
            }))
          );
        });

        const updatedTableData = Object.keys(historicalData).map((symbol) => {
          const latestData = historicalData[symbol][historicalData[symbol].length - 1];
          // ถ้า type เป็น 'out' ให้กลับลำดับ rank (เช่น 20 -> 1)
          const adjustedRank = type === 'out' ? 21 - latestData.rank : latestData.rank;

          // แสดงค่า value พร้อมเครื่องหมาย - ถ้าเป็น type 'out'
          const displayValue = type === 'out' ? -latestData.value : latestData.value;

          return {
            rank: adjustedRank,
            symbol,
            value: displayValue.toFixed(2), // แสดง value ที่แปลงแล้วในตาราง
          };
        });

        setTableData(updatedTableData);
        updatePriceFormatter();
        setIsLoading(false);
      } else {
        console.warn("Data is not available or incomplete.");
        setIsLoading(false);
      }
    } catch (error) {
      console.error(`Error fetching data:`, error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // สร้างกราฟเมื่อคอมโพเนนต์ถูก mount
    chartRef.current = createChart(chartContainerRef.current, {
      layout: { textColor: 'black', background: { type: 'solid', color: 'white' } },
      timeScale: { timeVisible: true, rightOffset: 10 },
      height: 290,
      width: chartContainerRef.current ? chartContainerRef.current.offsetWidth : 600,
    });

    chartRef.current.applyOptions({
      localization: {
        priceFormatter: (price) => price.toLocaleString('en-US', { maximumFractionDigits: 2 }),
      },
      priceScale: {
        mode: 0,
        borderColor: '#ddd',
        scaleMargins: {
          top: 0.2,
          bottom: 0.2,
        },
      },
    });
    // เพิ่มการตั้งค่า textColor ของ price scale
    chartRef.current.priceScale('right').applyOptions({
      textColor: 'white', // ตั้งสีข้อความของ price scale
    });

    fetchHistoricalData(selectedDate);

    // ทำความสะอาดเมื่อคอมโพเนนต์ unmount
    return () => {
      if (chartRef.current) {
        chartRef.current.remove();
      }
    };
  }, [market, type, selectedDate]);

  const renderTables = () => {
    // const firstTableData = tableData.sort((a, b) => b.value - a.value).slice(0, 10);
    // const secondTableData = tableData.sort((a, b) => b.value - a.value).slice(10, 20);

    const sortedData = tableData.sort((a, b) => b.value - a.value);

    const renderTable = (data, startIndex) => (
      <table
        className="rank-table"
        style={{
          marginLeft: (isMobile || isPTablet) ? '0' : (isTablet && !isPTablet) ? '0' : '10px',
          marginTop: (isMobile || isPTablet) ? '0' : (isTablet && !isPTablet) ? '0' : '30px',
          maxHeight: '230px',
          overflowY: 'auto',
          fontFamily: 'Arial, Helvetica, sans-serif',
          fontSize: '11px', // ลดขนาดฟอนต์
          borderCollapse: 'collapse', // ทำให้เส้นชิดกัน
          width: (isMobile || isPTablet) ? '40%' : (isTablet && !isPTablet) ? '40%' : '80%', // ปรับขนาดตาราง
          border: '1px solid #ddd', // เส้นขอบตาราง


        }}>
        <thead>
          <tr>
            <th style={{ padding: '5px', backgroundColor: '#fdf5e6', color: 'black' }}>Rank</th>
            <th style={{ padding: '5px', backgroundColor: '#fdf5e6', color: 'black' }}>Symbol</th>
            <th style={{ padding: '5px', backgroundColor: '#fdf5e6', color: 'black' }}>Value</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr
              key={index}
              style={{
                backgroundColor: getRowColor(row.symbol, row.value),
                cursor: 'pointer',
                transition: 'background-color 0.3s ease',
                borderBottom: '1px solid #ddd', // เส้นขอบตาราง
              }}
              onClick={() => onClickRow(row.symbol)}
            >
              <td style={{ padding: '5px', textAlign: 'center' }}>{startIndex + index + 1}</td>
              <td style={{ padding: '5px', textAlign: 'center' }}>{row.symbol}</td>
              <td style={{ padding: '5px', textAlign: 'center' }}>
                {type === 'out' ? `-${Math.abs(row.value).toLocaleString()}` : row.value.toLocaleString()}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );

    if (isMobile || isPTablet) {
      // สำหรับ mobile: แสดง 20 แถวในตารางเดียว
      return (
        <div style={{
          display: 'flex', justifyContent: 'center',
        }}>
          {renderTable(sortedData.slice(0, 20), 0)}
        </div>
      );
    } else if (isTablet & !isPTablet) {
      // สำหรับ mobile: แสดง 20 แถวในตารางเดียว
      return (
        <div style={{
          display: 'flex', justifyContent: 'center',
        }}>
          {renderTable(sortedData.slice(0, 20), 0)}
        </div>
      );
    } else {
      // สำหรับหน้าจอใหญ่: แบ่งตารางเป็น 2 ส่วน
      const firstTableData = sortedData.slice(0, 10);
      const secondTableData = sortedData.slice(10, 20);

      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            columnGap: '-80px',
            marginTop: '-70px',
          }}
        >
          {renderTable(firstTableData, 0)}
          {renderTable(secondTableData, 10)}
        </div>
      );
    }
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed); // เปลี่ยนสถานะพับ/ขยาย
  };

  // Dynamic Chart Width

  useEffect(() => {
    if (!chartContainerRef.current || !chartRef.current) return;

    const updateChartSize = () => {
      if (chartRef.current) {
        chartRef.current.resize(
          chartContainerRef.current.offsetWidth,
          chartContainerRef.current.offsetHeight
        );
      }
    };

    // ปรับขนาดเมื่อ isCollapsed เปลี่ยน
    updateChartSize();

    // สร้าง ResizeObserver
    const resizeObserver = new ResizeObserver(() => {
      requestAnimationFrame(updateChartSize);
    });

    resizeObserver.observe(chartContainerRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [isCollapsed]);

  const onChartTouch = (event, allowTouchMove) => {
    if (chartTableContainerRef.current && chartTableContainerRef.current.contains(event.target)) {
      window.mySwiper.allowTouchMove = allowTouchMove;
    }
  };


  return (
    <div
      className={`chart-section ${isFullscreen ? 'fullscreen' : ''}`}
      style={
        isFullscreen
          ? {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            zIndex: 1000,
            backgroundColor: 'white', // เปลี่ยนสีพื้นหลัง
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)', // เพิ่มเงา
          }
          : {
            width: '100%',
          }
      }
    >
      {/* ส่วนหัวข้อกราฟพร้อมปุ่ม Expand */}
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px', marginTop: '10px', fontFamily: 'Arial, Helvetica, sans-serif' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h3 style={{
            margin: 0,
            marginLeft: isFullscreen ? '750px' : '10px',
            textAlign: isFullscreen ? 'center' : 'left',

          }}>{title}</h3>
          {isLoading ? (
            <div style={{ marginTop: '20px' }}>
              <Loading />
            </div>
          ) : tableData.length === 0 ? (
            <span style={{ fontStyle: 'italic', color: '#888', marginLeft: '10px' }}>No Data Available</span>
          ) : null}
        </div>
        {/* <button
          onClick={handleExpandClick}
          style={{
            padding: '5px 15px',
            marginRight: '550px',
            backgroundColor: 'none',
            color: 'black',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
        >
          {isFullscreen ? <FaTimes size={20} /> : <FaSearch size={20} />}
        </button> */}
        {(isMobile || isTablet) && (<IconButton
          variant="contained"
          onClick={toggleCollapse}
        // sx={{ marginBottom: '10px' }}
        >
          {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </IconButton>)}
      </div>

      {/* ส่วนของกราฟและตาราง */}
      {(!isMobile && !isTablet) && (
        <div style={{ display: 'flex', flexDirection: isFullscreen ? 'column' : 'row' }}>
          <div
            ref={chartContainerRef}
            style={{
              flex: isFullscreen ? 0 : isMobile ? 1 : 1,
              marginLeft: isFullscreen ? '550px' : isMobile ? '0' : '-10px', // ระยะห่างจากกราฟหลักเมื่ออยู่ในโหมด fullscreen
              maxWidth: isMobile ? '100vw' : 'none',
              width: isFullscreen ? '1700px' : isMobile ? '100vw' : '660px', // กราฟขยายเต็มจอในโหมด Fullscreen
              height: isFullscreen ? '60vh' : '290px', // ความสูงของกราฟในโหมด Fullscreen
              marginTop: isFullscreen ? '50px' : '0', // เว้นระยะในโหมด Fullscreen
              transition: 'all 0.3s ease',
            }}
          ></div>


          <div style={{
            flex: isFullscreen ? 0 : isMobile ? 1 : 0.8,
            padding: isFullscreen ? '10px' : '0px',
            width: isFullscreen ? '500px' : '0px',
            height: isFullscreen ? '500px' : '0px',
            marginTop: isFullscreen ? '100px' : '0',
            marginLeft: isFullscreen ? '600px' : '0',

          }}>{renderTables()}</div>
        </div>
      )}

      {(isMobile || isTablet || isPTablet) && (
        <div className="Chart-and-Table" ref={chartTableContainerRef} style={{
          display: 'flex',
          flexDirection: isFullscreen ? 'column' : 'row',
        }}>
          <div
            ref={chartContainerRef}
            style={{
              // flex: isFullscreen ? 0 : isMobile || isTablet ? 1 : 1,
              marginLeft: isFullscreen ? '550px' : isMobile || isTablet ? '0' : '-10px', // ระยะห่างจากกราฟหลักเมื่ออยู่ในโหมด fullscreen
              // maxWidth: isMobile || isTablet ? '100vw' : 'none',
              width: isFullscreen ? '1700px' : (isMobile || isTablet) ? isCollapsed ? '100%' : '60%' : '660px', // กราฟขยายเต็มจอในโหมด Fullscreen
              height: isFullscreen ? '60vh' : '290px', // ความสูงของกราฟในโหมด Fullscreen
              marginTop: isFullscreen ? '50px' : '0', // เว้นระยะในโหมด Fullscreen
              transition: 'all 0.3s ease',
            }}
            onTouchStart={(event) => onChartTouch(event, false)}
            onTouchEnd={(event) => onChartTouch(event, true)}
          >
          </div>


          {!isCollapsed && (
            <Box className="table-of-chart" sx={{
              // flex: isFullscreen ? 0 : isMobile || isTablet ? 1 : 0.8,
              padding: isFullscreen ? '10px' : '0px',
              maxWidth: isFullscreen ? '500px' : isMobile || isTablet ? '40%' : '40%px',
              // height: isFullscreen ? '500px' : isMobile || isTablet ? '300px' : '0',
              marginTop: isFullscreen ? '100px' : '0',
              marginLeft: isFullscreen ? '600px' : '0',
              maxHeight: '250px',
              overflowY: 'auto',
              '::-webkit-scrollbar': {
                width: '5px',
              },
              '::-webkit-scrollbar-thumb': {
                backgroundColor: '#888',
                borderRadius: '4px',
              },
              '::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#555',
              },

            }}>{renderTables()}</Box>
          )}
        </div>
      )}

    </div>
  );

};

export default ChartSectionWithMultipleLines;
