import React, { useState, useEffect, useRef, createRef } from "react";
import { Sidebar as ProSidebar, Menu, MenuItem, useProSidebar, SubMenu } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme, useMediaQuery, Popper, Grow, Paper, Avatar, Button } from "@mui/material";
import { Link, NavLink, Navigate, useLocation } from "react-router-dom";
// import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";

import ChatIcon from '@mui/icons-material/Chat';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import BarChartIcon from '@mui/icons-material/BarChart';
import InsightsIcon from '@mui/icons-material/Insights';
import { useDispatch, useSelector } from "react-redux";
import { setLeftMenu } from "../../state";

import ViewSidebarIcon from '@mui/icons-material/ViewSidebar';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import OilBarrelIcon from '@mui/icons-material/OilBarrel';
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart';
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MenuList from "@mui/material/MenuList";
import LogoutIcon from "@mui/icons-material/Logout";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import LeftArrowSvg from '../../images/sidebar/sidebar-left.svg';

import RightArrowSvg from '../../images/sidebar/sidebar-right.svg';

import GroupsIcon from '@mui/icons-material/Groups';

import MenuIcon from '@mui/icons-material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import Cookies from "js-cookie";
import { styled } from '@mui/system';

import TimelineIcon from '@mui/icons-material/Timeline';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

const Item = ({ title, to, icon, selected, setSelected, isMobile, user, permissionKey }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { collapseSidebar, collapsed } = useProSidebar();
  const textStyle = {
    fontSize: '1.18rem', // Set the desired font size
    // padding: '10px', // Set the desired padding
    // color: setSelected ? colors.primary[400] : 'red',
    fontFamily: '"Sora", sans-serif',
    fontWeight: '300',
    fontStyle: 'normal',
    //color: '#3083FF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
  const handleItemClick = (event) => {
    setSelected(title);
    isMobile ? collapseSidebar(true) : collapseSidebar(false);
  };

  // if (!userDetail.permissions ||
  //   !userDetail.permissions.permission ||
  //   !userDetail.permissions.permission[permission_key] ||
  //   !userDetail.permissions.permission[permission_key].read) {
  //   return <Navigate to="/401" replace />;
  // }
  // if (user.permissions && user.permissions.permission && user.permissions.permission[permission_key] && user.permissions.permission[permission_key].read) {
  //   return <Navigate to="/401" replace />;
  // }
  //||  (userDetail.permissions && userDetail.permissions.permission && userDetail.permissions.permission.chatBot9Chanel && userDetail.permissions.permission.chatBot9Chanel.read)

  if (
    !user ||
    !user.permissions ||
    !user.permissions.permission ||
    !user.permissions.permission[permissionKey] ||
    !user.permissions.permission[permissionKey].read
  ) {
    // return <Navigate to="/401" replace />;
  }


  return (


    <NavLink to={to}
      style={{

        position: 'relative',
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',
        //justifyContent: 'center',
        left: '-1vw'
      }}>
      <Box
        active={selected === title}
        sx={{
          //position: 'relative',
          color: selected === title ? colors.greenAccent[100] : colors.grey[100],
          //padding: '8px 10px',
          //height: '30px',

          //adding: '1vh 1rem 1vh 3.5vw',
          //marginBottom: '0.5rem',
          //marginTop: '0.5rem',

          left: '1.5vw',
          color: 'black',
          width: {
            [theme.breakpoints.up('lg', '1400')]: {
              width: '18vw',
              padding: '1vh 1rem 1vh 3.2vw',
              position: 'relative',
              left: '1vw'
            },//Higher screen

            [theme.breakpoints.between('lg', '1370')]: {
              width: '22vw',
              padding: '1vh 1rem 1vh 2vw',
              position: 'relative',
              left: '1.7vw'
            },//IPadProHorizontal
            [theme.breakpoints.between('md', '1270')]: {
              width: '18vw',
              padding: '1vh 1rem 1vh 2vw',
              position: 'relative',
              left: '-0.2vw'
            },//macbook2020
            [theme.breakpoints.between('lg', '1370')]: {
              width: '20vw',
              padding: '1vh 1rem 1vh 3.2vw',
              position: 'relative',
              left: '1vw'
            },//IPadProHorizontal
            [theme.breakpoints.between('md', '1190')]: {
              width: '20vw',
              padding: '1vh 1rem 1vh 3.4vw',
              position: 'relative',
              left: '1vw'
            },//IPadAirHorizontal
            [theme.breakpoints.between('md', '1030')]: {
              width: '27vw',
              padding: '0.7vh 1rem 0.7vh 2.2vw',
              position: 'relative',
              left: '2vw',
            },//IPadPro
            [theme.breakpoints.between('sm', '830')]: {
              width: '28vw',
              padding: '1vh 1rem 1vh 3vw',
              position: 'relative',
              left: '2vw'
            },//IPadAir
            [theme.breakpoints.between('sm', '770')]: {
              width: '40.5vw',
              padding: '1.3vh 3.5rem 1.3vh 3.2vw',
              position: 'relative',
              left: '2vw'
            },//IPadMini
          },
          padding: '1.5vh 3rem 1.5vh 2vw',
          display: 'flex',
          alignItems: 'center',
          //justifyContent: 'center',
          flexDirection: 'row',
          color: 'black',

          ":hover": {
            backgroundImage: 'linear-gradient(90deg, #3083FF, #1D4E99);',
            //border: '1px solid black',
            borderRadius: '15px',
            color: 'white !important'
          },
          ":after": {
            backgroundImage: 'linear-gradient(90deg, #3083FF, #1D4E99);',
          }

        }}
        onClick={handleItemClick}
        icon={icon}
      >
        <Typography style={textStyle}>{icon}&nbsp;&nbsp;&nbsp;&nbsp;{title}</Typography>
      </Box>
    </NavLink >




  );
};

const ItemNew1 = ({ title, to, icon, selected, setSelected, isMobile, user, permissionKey }) => {

  // console.log(permissionKey, user.permissions.permission, user.permissions.permission[permissionKey])
  // console.log((
  //   !user ||
  //   !user.permissions ||
  //   !user.permissions.permission ||
  //   !user.permissions.permission[permissionKey] ||
  //   !user.permissions.permission[permissionKey].read
  // ))
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { collapseSidebar, collapsed } = useProSidebar();
  // const [isSubscriber, setIsSubscriber] = useState(false);
  // const location = useLocation();
  // console.log((location.pathname), (selected), (to));
  // console.log("Check Active Menu", selected == to)

  const textStyle = {
    fontSize: '1rem', // Set the desired font size
    // padding: '10px', // Set the desired padding
    // color: setSelected ? colors.primary[400] : 'red',
    fontFamily: 'Sora, sans-serif',
    fontWeight: '300',
    fontStyle: 'normal',
    //color: '#3083FF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

  };
  const handleItemClick = (event) => {
    setSelected(to);
    isMobile ? collapseSidebar(true) : collapseSidebar(false);
  };

  // console.log('Breakpoints:', theme.breakpoints.values);
  let isSubscriber = false;
  if (
    !user ||
    !user.permissions ||
    !user.permissions.permission ||
    !user.permissions.permission[permissionKey] ||
    !user.permissions.permission[permissionKey].read
  ) {
    // return <Navigate to="/401" replace />;
    // setIsSubscriber(true);
    isSubscriber = true;
    // to = 'upgrade';
  }

  const Ribbon = styled('div')(({ theme }) => ({
    right: '1vw',
    width: '50px', // Default size
    height: '50px',
    backgroundColor: 'red',
    color: 'white',
    position: 'absolute',
    // top: '0.5rem',
    top: '0rem',
    // right: '-10px',
    // transform: 'rotate(45deg)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '0.6rem',
    fontWeight: '300',
    zIndex: 1,
    [theme.breakpoints.up('sm')]: {
      width: '60px',
      height: '20px',
      fontSize: '0.45rem',
    },
    [theme.breakpoints.up('md')]: {
      width: '60px',
      height: '20px',
      fontSize: '0.55rem',
    },
    [theme.breakpoints.up('lg')]: {
      width: '70px',
      height: '20px',
      fontSize: '0.7rem',
    },
  }));

  const NavLinkWithRibbon = styled(NavLink)(({ theme }) => ({
    position: 'relative',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    // left: '1vw',
    '&.active': {
      borderRadius: '15px',
      color: 'white',
    },
    marginTop: '3px'
  }));

  const StyledBox = styled(Box)(({ theme, selected, title, colors }) => ({
    backgroundColor: isSubscriber ? '#E5E5E5' : 'white',
    backgroundImage: selected == to ? 'linear-gradient(90deg, #3083FF, #1D4E99)' : 'white',
    borderRadius: '15px',
    // position: 'relative',
    color: selected == to ? 'white !important' : "black",
    // left: '1.5vw',
    // color: 'black',
    // width: {
    //   [theme.breakpoints.between('sm', '770')]: {
    //     width: '40.5vw',
    //     padding: '1.3vh 3.5rem 1.3vh 3.2vw',
    //     position: 'relative',
    //     left: '2vw',
    //   },
    //   [theme.breakpoints.between('sm', '830')]: {
    //     width: '28vw',
    //     padding: '1vh 1rem 1vh 3vw',
    //     position: 'relative',
    //     left: '2vw',
    //   },
    //   [theme.breakpoints.between('md', '1030')]: {
    //     width: '27vw',
    //     padding: '0.7vh 1rem 0.7vh 2.2vw',
    //     position: 'relative',
    //     left: '2vw',
    //   },
    //   [theme.breakpoints.between('md', '1190')]: {
    //     width: '20vw',
    //     padding: '1vh 1rem 1vh 3.4vw',
    //     position: 'relative',
    //     left: '1vw',
    //   },
    //   [theme.breakpoints.between('md', '1270')]: {
    //     width: '18vw',
    //     padding: '1vh 1rem 1vh 2vw',
    //     position: 'relative',
    //     left: '-0.2vw',
    //   },
    //   [theme.breakpoints.between('lg', '1370')]: {
    //     width: '22vw',
    //     padding: '1vh 1rem 1vh 2vw',
    //     position: 'relative',
    //     left: '1.7vw',
    //   },
    //   [theme.breakpoints.between('lg', '1370')]: {
    //     width: '20vw',
    //     padding: '1vh 1rem 1vh 3.2vw',
    //     position: 'relative',
    //     left: '1vw',
    //   },
    //   [theme.breakpoints.up('lg', '1400')]: {
    //     width: '18vw',
    //     padding: '1vh 1rem 1vh 3.2vw',
    //     position: 'relative',
    //     left: '1vw',
    //   },
    // },


    position: 'relative',
    width: '95%',
    padding: '1.5vh 4rem 1.5vh 3vw',
    [theme.breakpoints.between('sm', '770')]: {
      padding: '1.3vh 3.5rem 1.3vh 3.2vw',
    },
    [theme.breakpoints.between('sm', '830')]: {
      padding: '1vh 1rem 1vh 3vw',
    },
    [theme.breakpoints.between('md', '1030')]: {
      padding: '0.7vh 1rem 0.7vh 2.2vw',
    },
    [theme.breakpoints.between('md', '1190')]: {
      padding: '1vh 1rem 1vh 3.4vw',
    },
    [theme.breakpoints.between('md', '1270')]: {
      padding: '1vh 1rem 1vh 2vw',
    },
    [theme.breakpoints.between('lg', '1370')]: {
      padding: '1vh 1rem 1vh 2vw',
    },
    [theme.breakpoints.up('lg', '1400')]: {
      padding: '1vh 1rem 1vh 3.2vw',
    },


    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    ':hover': {
      backgroundImage: 'linear-gradient(90deg, #3083FF, #1D4E99)',
      borderRadius: '15px',
      color: 'white !important',
    },
    ':after': {
      backgroundImage: 'linear-gradient(90deg, #3083FF, #1D4E99)',
    },
  }));
  {/* <Typography>{icon}&nbsp;&nbsp;&nbsp;&nbsp;{title}</Typography> */ }
  return (
    <NavLinkWithRibbon to={isSubscriber ? 'upgrade' : to}>
      <StyledBox selected={selected} title={title} colors={colors} onClick={handleItemClick} icon={icon}>
        <Typography style={textStyle}>{icon}&nbsp;&nbsp;&nbsp;&nbsp;{title}</Typography>
        {isSubscriber && <Ribbon>Subscription</Ribbon>}
      </StyledBox>
    </NavLinkWithRibbon >
  );
};

const ListItemLink = (props) => {
  const { icon, primary, to, itemStyle } = props;

  return (
    <li>
      <ListItem button component={Link} to={to}>
        {icon ? icon : null}
        <ListItemText primary={primary} style={{ ...itemStyle, marginLeft: '8px' }} />
      </ListItem>
    </li>
  );
}

const SidebarAdmin = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const colors = tokens(theme.palette.mode);
  const location = useLocation();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState(location.pathname);

  const { collapseSidebar, collapsed } = useProSidebar();
  // const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const leftMenuSelect = useSelector((state) => state.leftMenu);
  const userDetail = useSelector((state) => state.userDetail);

  //new width model
  const sidebarWidths = {
    active: {

      [theme.breakpoints.up('lg', '1400')]: {
        width: '20vw',
        position: 'relative',
        overflowX: 'hidden',
        transition: 'width 0.25s'
      },//Higher screen

      [theme.breakpoints.between('lg', '1370')]: {
        width: '22vw',
        position: 'relative',
        overflowX: 'hidden',
        transition: 'width 0.25s'
      },//IPadProHorizontal

      [theme.breakpoints.between('md', '1190')]: {
        width: '22vw',
        position: 'relative',
        overflowX: 'hidden',
        transition: 'width 0.25s'
      },//IPadAirHorizontal
      [theme.breakpoints.between('md', '1030')]: {
        // width: '32vw',
        width: '24vw',
        position: 'relative',
        overflowX: 'hidden',
        transition: 'width 0.25s'
      },//IPadPro
      [theme.breakpoints.between('sm', '830')]: {
        width: '33vw',
        position: 'relative',
        overflowX: 'hidden',
        transition: 'width 0.25s'
      },//IPadAir
      [theme.breakpoints.between('sm', '770')]: {
        width: '31vw',
        position: 'relative',
        overflowX: 'hidden',
        transition: 'width 0.25s'
      },//IPadMini

    },
    inactive: {
      width: '0vw',
      transition: 'width 0.25s'

    }
  };

  const [SideBarActive, setSideBarActive] = useState(sidebarWidths.active);

  const [IsSideBarAppear, setIsSideBarAppear] = useState(true);

  useEffect(() => {
    const savedSidebarState = localStorage.getItem('sidebarState');
    if (savedSidebarState) {
      const parsedState = JSON.parse(savedSidebarState);
      setIsSideBarAppear(parsedState.IsSideBarAppear);
      setSideBarActive(parsedState.sideBarActive);
    }
  }, []);
  const hideSideBar = () => {
    setIsSideBarAppear(false)
    setSideBarActive(!sidebarWidths.inactive);
    localStorage.setItem('sidebarState', JSON.stringify({
      IsSideBarAppear: false,
      sideBarActive: false,
    }));
  };

  const showSideBar = () => {
    setIsSideBarAppear(true)
    setSideBarActive(!sidebarWidths.active);
    localStorage.setItem('sidebarState', JSON.stringify({
      IsSideBarAppear: true,
      sideBarActive: true,
    }));
  };



  //const [sidebarWidth, setSidebarWidth] = useState(sidebarWidths.active);

  useEffect(() => {
    // console.log(`lef menu ${leftMenuSelect}`)
    isMobile ? collapseSidebar(true) : collapseSidebar(false);
    if (isMobile) {
      // setIsSidebarHovered(!isSidebarHovered);
    }
  }, [isMobile]);

  const csrfToken = useSelector((state) => state.csrfToken);
  const isAuth = (Boolean(useSelector((state) => state.token))) && (Boolean(Cookies.get("access_token")));
  const userId = useSelector((state) => state.user);

  useEffect(() => {
    // console.log(isAuth, csrfToken, userId)
    // console.log(userDetail)
  }, [userDetail]);


  const [isSidebarHovered, setIsSidebarHovered] = useState(false);
  const [isClickCollapsed, setIsClickCollapsed] = useState(false);
  const handleSidebar = () => {
    if (isSidebarHovered && !collapsed) {
      collapseSidebar(false);
      setIsSidebarHovered(false);
    } else {
      collapseSidebar();
      setIsSidebarHovered(!isSidebarHovered);
    }
    setIsClickCollapsed(false);
  }
  const handleMouseEnter = () => {
    setIsClickCollapsed(true);
    if (isClickCollapsed) {
      if (isSidebarHovered && collapsed) {
        collapseSidebar(false);

      }
    }
  };
  const handleMouseLeave = () => {
    if (isSidebarHovered && !collapsed) {
      collapseSidebar(true);
      // if (sidebarRef.current) {
      //   // console.log('Sidebar width:', sidebarRef.current.clientWidth);
      //   // setDivPosition({ left: sidebarRef.current.clientWidth + 1 });
      //   console.log('Sidebar width:', sidebarRef.current.clientWidth);
      // }
      // setDivPosition({ left: 10 });
    }
  };


  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const [showDiv, setShowDiv] = useState(false);

  const toggleDiv = () => {
    setShowDiv(!showDiv);
  };

  const [divPosition, setDivPosition] = useState({ left: 0, top: 0 });
  const iconButtonRef = useRef();
  const sideDivRef = useRef();
  const sidebarRef = useRef(null);

  useEffect(() => {
    sidebarRef.current = sidebarRef.current;
  }, [sidebarRef]);

  useEffect(() => {
    if (showDiv && iconButtonRef.current) {
      const rect = iconButtonRef.current.getBoundingClientRect();
      setDivPosition({ left: rect.left + rect.width, top: rect.top + 5 });
    }
  }, [showDiv]);

  useEffect(() => {
    // const handleClickOutside = (event) => {
    //   if (showDiv) {
    //     setShowDiv(false);
    //   }
    // };

    // document.addEventListener('mousedown', handleClickOutside);
    // return () => {
    //   document.removeEventListener('mousedown', handleClickOutside);
    // };
  }, []);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        if (entry.target === sidebarRef.current) {
          const width = entry.contentRect.width;
          setDivPosition(prevData => ({
            ...prevData,
            left: width
          }));
          if (isSidebarHovered && !collapsed) {
            if (showDiv && iconButtonRef.current) {
              const rect = iconButtonRef.current.getBoundingClientRect();
              setDivPosition({ left: rect.left + rect.width, top: rect.top + 5 });
            }
          }
        }
      }
    });

    if (sidebarRef.current) {
      resizeObserver.observe(sidebarRef.current);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, [collapsed]);


  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={{
          position: 'absolute',
          top: '1rem',        // or something small
          left: '1rem',       // if the sidebar is on the left
          zIndex: 9999,
        }}
      >
      <Typography onClick={IsSideBarAppear ? hideSideBar : showSideBar}>
        <img
          src={IsSideBarAppear ? LeftArrowSvg : RightArrowSvg}
          alt="Toggle Sidebar"
          style={{ width: '24px', height: '24px', cursor: 'pointer' }}
        />
      </Typography>
      </Box>

      <Box
        sx={{
          "& .pro-sidebar-inner": {
            background: `${colors.primary[400]} !important`,
          },
          "& .pro-icon-wrapper": {
            backgroundColor: "transparent !important",
          },
          "& .pro-inner-item": {
            padding: "5px 35px 5px 20px !important",
          },
          "& .pro-inner-item:hover": {
            color: "#868dfb !important",
          },
          "& .pro-menu-item.active": {
            color: "#6870fa !important",
          },

          marginLeft: isSidebarHovered ? '3rem' : 0,
          transition: 'margin-right 0.5s ease',


        }}
      >
        <Box
          ref={sidebarRef}
          backgroundColor="white"
          collapsed={isCollapsed}

          sx={{
            boxShadow: '2px 12px 10.8px #ECECEC',
            width: IsSideBarAppear ? sidebarWidths.active : sidebarWidths.inactive,
            height: '100vh',
            overflowY: 'auto',
            zIndex: '100',
            // transition: 'width 0.5s ease', //
            position: isSidebarHovered ? 'relative' : 'relative',
            top: 0,
            left: 0,


          }}
          collapsedWidth='3rem'
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >




          <Menu iconShape="square">
            {/* LOGO AND MENU ICON */}
            <MenuItem
              style={{
                margin: "0 0 10px 0",
                color: colors.grey[100],
                cursor: "default",
                padding: "0",
              }}
            >
              {collapsed ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexGrow={1}
                  flexBasis={0}
                >
                  <img
                    src={userDetail.img_src ? userDetail.img_src : "data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220,0,20,20%22%20width=%2239%22%20height=%2239%22%3E%3Crect%20height=%2220%22%20width=%2220%22%20fill=%22hsl%28153,25%25,50%25%29%22/%3E%3Ctext%20fill=%22white%22%20x=%2210%22%20y=%2214.8%22%20font-size=%2214%22%20font-family=%22-apple-system,BlinkMacSystemFont,Trebuchet%20MS,Roboto,Ubuntu,sans-serif%22%20text-anchor=%22middle%22%3EI%3C/text%3E%3C/svg%3E"}
                    alt="Your Image"
                    style={{ borderRadius: "50%" }}
                  />
                </Box>
              ) : (
                <Box display="flex" justifyContent="space-between" alignItems="center"
                >
                  <Box
                    // bgcolor="red"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexGrow={1}
                    flexBasis={0}
                  ></Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexGrow={1}
                    flexBasis={0}
                    padding={0}
                  >
                    <React.Fragment>
                      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                        <Tooltip title="Account">
                          <IconButton
                            ref={iconButtonRef}
                            onClick={toggleDiv}
                            // size="small"
                            sx={{ ml: 2 }}
                          >
                            {/* <Avatar sx={{ width: 64, height: 64 }}> */}
                            <img
                              src={userDetail.img_src ? userDetail.img_src : "data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220,0,20,20%22%20width=%2239%22%20height=%2239%22%3E%3Crect%20height=%2220%22%20width=%2220%22%20fill=%22hsl%28153,25%25,50%25%29%22/%3E%3Ctext%20fill=%22white%22%20x=%2210%22%20y=%2214.8%22%20font-size=%2214%22%20font-family=%22-apple-system,BlinkMacSystemFont,Trebuchet%20MS,Roboto,Ubuntu,sans-serif%22%20text-anchor=%22middle%22%3EI%3C/text%3E%3C/svg%3E"}
                              alt="Your Image"
                              style={{ borderRadius: "50%" }}
                            // onClick={handleMenuClick} 
                            />
                            {/* </Avatar> */}
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </React.Fragment>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="end"
                    alignItems="center"
                    flexGrow={1}
                    flexBasis={0}

                  >
                  </Box>
                </Box>
              )}
            </MenuItem>

            {!collapsed && (
              <Box mb="25px">
                <Box textAlign="center">
                  <Typography
                    variant="h2"
                    color={colors.grey[100]}
                    fontWeight="bold"
                    sx={{ m: "10px 0 0 0" }}
                  >
                    {/* Ed Roh */}
                  </Typography>
                  <Typography variant="h5" color={colors.greenAccent[500]}>
                    {/* VP Fancy Admin */}
                  </Typography>
                </Box>
              </Box>
            )}

            <Box sx={{
              width: '20vw',
            }}>
              <Box variant="h2" color='black' overflow='visible'
                sx={{
                  width: {
                    [theme.breakpoints.up('lg', '1400')]: {
                      width: '21vw',
                      left: '-0.1vw',
                      marginBottom: '2.1rem',
                      top: '-5vh'
                    },//Higher screen

                    [theme.breakpoints.between('lg', '1370')]: {
                      width: '25vw',
                      left: '-0.8vw',
                      marginBottom: '2rem'
                    },//IPadProHorizontal

                    [theme.breakpoints.between('md', '1190')]: {
                      width: '24vw',
                      left: '0.1vw',
                      marginBottom: '2rem'
                    },//IPadAirHorizontal

                    [theme.breakpoints.between('md', '1030')]: {
                      // width: '34vw',
                      width: '24vw',
                      left: '-0.6vw',
                      marginBottom: '2rem'
                    },//IPadPro
                    [theme.breakpoints.between('sm', '830')]: {
                      width: '37vw',
                      left: '-0.8vw',
                      marginBottom: '2rem'
                    },//IPadAir
                    [theme.breakpoints.between('sm', '770')]: {
                      width: '35vw',
                      left: '-0.7vw',
                      marginBottom: '2.5rem'
                    },//IPadMini
                  },
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  top: '0vh',
                  left: '1vw'
                  //top: '20vh'
                }}>
                <img src="https://i.postimg.cc/GmshdzQ6/logo-Black-01.png" alt="" width='70%' height='auto' />
              </Box>
            </Box>

            <Box paddingLeft={collapsed ? undefined : "5%"}
              sx={{
                position: 'relative',
                top: '-6.2vh'
              }}
            >
              {!collapsed && <Typography
                variant="h5"
                color={colors.grey[300]}
                sx={{
                  m: "15px 0 5px 20px",
                  marginBottom: '1.5rem',
                  fontSize: {
                    [theme.breakpoints.up('lg', '1400')]: {
                      fontSize: '1.1rem',
                    },//Higher screen
                    [theme.breakpoints.between('lg', '1370')]: {
                      fontSize: '1.1rem',
                    },//IPadProHorizontal
                    [theme.breakpoints.between('md', '1190')]: {
                      fontSize: '1.1rem',

                    },//IPadAirHorizontal
                  },
                  fontFamily: '"Sora", sans-serif',
                  fontWeight: '400',
                  fontStyle: 'normal',
                  //color: '#3083FF',
                }}
              >
                Data
              </Typography>}

              <ItemNew1
                title='หมอดูหุ้น'
                to="/chatbot"
                icon={<ChatIcon />}
                selected={selected}
                setSelected={setSelected}
                isMobile={isMobile}
                user={userDetail}
                permissionKey={'chatBot9Chanel'}
              />
              <ItemNew1
                title='Petroleum'
                to="/petroleum"
                icon={<OilBarrelIcon />}
                selected={selected}
                setSelected={setSelected}
                isMobile={isMobile}
                user={userDetail}
                permissionKey={'petroleumChart'}
              />

              <ItemNew1
                title="Rubber Thai"
                to="/rubber"
                icon={<SsidChartIcon />}
                selected={selected}
                setSelected={setSelected}
                isMobile={isMobile}
                user={userDetail}
                permissionKey={'rubberThaiChart'}
              />


              <ItemNew1
                title="Realtime Chart"
                to="/realtime"
                icon={<BarChartIcon />}
                selected={selected}
                setSelected={setSelected}
                isMobile={isMobile}
                user={userDetail}
                permissionKey={'realTimeChart'}
              // permissionKey={'rubberThaiChart'}
              />

              <ItemNew1
                title="Gold"
                to="/gold"
                icon={<InsightsIcon />}
                selected={selected}
                setSelected={setSelected}
                isMobile={isMobile}
                user={userDetail}
                permissionKey={'goldChart'}
              />

              < ItemNew1
                title="BidAsk"
                to="/dejavu/bidask"
                icon={<TimelineIcon />}
                selected={selected}
                setSelected={setSelected}
                isMobile={isMobile}
                user={userDetail}
                permissionKey={'bidaskDejavu'}
              />

              < ItemNew1
                title="TickMatch"
                to="/dejavu/tickmatch"
                icon={<FormatListBulletedIcon />}
                selected={selected}
                setSelected={setSelected}
                isMobile={isMobile}
                user={userDetail}
                permissionKey={'tickmatchDejavu'}
              />

              < ItemNew1
                title="DR"
                to="/dr"
                icon={<FormatListBulletedIcon />}
                selected={selected}
                setSelected={setSelected}
                isMobile={isMobile}
                user={userDetail}
                permissionKey={'tickmatchDejavu'}
              />

              {userDetail.user_type == 'employee' && (
                !collapsed && <Typography
                  variant="h5"
                  color={colors.grey[300]}
                  sx={{
                    m: "15px 0 5px 20px",
                    marginBottom: '1.5rem',
                    fontSize: {
                      [theme.breakpoints.up('lg', '1400')]: {
                        fontSize: '1.1rem',
                      },//Higher screen
                      [theme.breakpoints.between('lg', '1370')]: {
                        fontSize: '1.1rem',
                      },//IPadProHorizontal
                      [theme.breakpoints.between('md', '1190')]: {
                        fontSize: '1.1rem',

                      },//IPadAirHorizontal
                    },
                    fontFamily: '"Sora", sans-serif',
                    fontWeight: '400',
                    fontStyle: 'normal',
                    //color: '#3083FF',
                  }}
                >
                  Administrator
                </Typography>
              )}
              {/* {userDetail.user_type === 'employee' && (
              
              )}
              {userDetail.user_type === 'employee' && (
              
              )} */}
              {userDetail.user_type === 'employee' && (
                < ItemNew1
                  title="Manage User"
                  to="/admin/users"
                  icon={<PeopleOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  isMobile={isMobile}
                  user={userDetail}
                  permissionKey={'manageUser'}
                />
              )}
              {userDetail.user_type === 'employee' && (
                < ItemNew1
                  title="Manage Role"
                  to="/admin/roles"
                  icon={<GroupsIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  isMobile={isMobile}
                  user={userDetail}
                  permissionKey={'manageUser'}
                />
              )}
              {/* {userDetail.user_type === 'employee' && userDetail.permissions && userDetail.permissions.permission && userDetail.permissions.permission.manageUser && userDetail.permissions.permission.manageUser.read && (
                <Item
                  title="Manage User"
                  to="/admin/users"
                  icon={<PeopleOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  isMobile={isMobile}
                />
              )}
              {userDetail.user_type === 'employee' && userDetail.permissions && userDetail.permissions.permission && userDetail.permissions.permission.manageRole && userDetail.permissions.permission.manageRole.read && (
                <Item
                  title="Manage Role"
                  to="/admin/roles"
                  icon={<GroupsIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  isMobile={isMobile}
                />
              )} */}







            </Box>
          </Menu>



        </Box>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 99999
          }}
        >
          {
            showDiv && (
              <Box
                className="sideDiv"
                sx={{
                  position: 'absolute',
                  // backgroundColor: '#ffffff',
                  // border: '1px solid #000000',
                  // padding: '10px',
                  width: '200px',
                  height: '150px',
                  top: divPosition.top,
                  left: divPosition.left,
                  fontSize: '10px'
                }}
              >

                <Card sx={{ minWidth: 275 }}>
                  <CardContent
                    style={{
                      // backgroundColor: 'red',
                      padding: 0
                    }}>

                    <MenuList
                      autoFocusItem={open}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      // onKeyDown={handleListKeyDown}
                      style={{
                        padding: 0
                      }}
                    >

                      <li>
                        <ListItem >
                          <ListItemText
                            primary={<Typography style={{ marginLeft: '8px' }} variant="h3" gutterBottom>IDEATRADE</Typography>}
                          />
                        </ListItem>
                      </li>
                      <Divider variant="middle" component="li" />
                      <ListItemLink
                        to="/profile"
                        primary={
                          <Typography>Account</Typography>
                        }
                        icon={<PersonOutlinedIcon />}
                      />
                      <ListItemLink
                        to="/logout"
                        primary={
                          <Typography>Logout</Typography>
                        }
                        icon={<LogoutIcon style={{ color: 'red' }} />}
                        itemStyle={{ color: 'red' }}
                      />
                    </MenuList>
                  </CardContent>

                </Card>
              </Box>
            )

          }



        </div>





      </Box >
    </Box >




  );
};

export default SidebarAdmin;