import React, { useState } from "react";
import "./FormCol.css";

const Form = ({ symbol, setSymbol, startDate, setStartDate, endDate, setEndDate, nStd, setNStd, window, setWindow, fetchData }) => {
  const [loading, setLoading] = useState(false); // สถานะการโหลด

  const handleSubmit = async () => {
    setLoading(true); // เริ่มโหลดเมื่อกด Submit
    await fetchData(); // เรียกใช้ fetchData เพื่อดึงข้อมูล
    setLoading(false); // หยุดโหลดเมื่อข้อมูลเสร็จ
  };

  

  return (
    <div className="form-container">
      {/* Symbol */}
      <div className="inputGroup full-width">
        <input
          type="text"
          id="symbol"
          value={symbol}
          onChange={(e) => setSymbol(e.target.value)}
          required
        />
        <label htmlFor="symbol">Symbol</label>
      </div>

      {/* Start Date และ End Date */}
      <div className="form-row">
        <div className="inputGroup">
          <input
            type="date"
            id="startDate"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            required
          />
          <label htmlFor="startDate">Start Date</label>
        </div>
        <div className="inputGroup">
          <input
            type="date"
            id="endDate"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            required
          />
          <label htmlFor="endDate">End Date</label>
        </div>
      </div>

      {/* n_std และ Window */}
      <div className="form-row">
        <div className="inputGroup">
          <input
            type="number"
            id="nStd"
            value={nStd}
            onChange={(e) => setNStd(e.target.value)}
            required
          />
          <label htmlFor="nStd">n_std</label>
        </div>
        <div className="inputGroup">
          <input
            type="number"
            id="window"
            value={window}
            onChange={(e) => setWindow(e.target.value)}
            required
          />
          <label htmlFor="window">Window</label>
        </div>
      </div>

      {/* ปุ่ม Submit */}
      <button className="btn-hover color-3" onClick={handleSubmit}>
        SUBMIT
      </button>
    </div>
  );
};

export default Form;
