import React, { useState, useEffect, useRef } from 'react';
import { createChart } from 'lightweight-charts';
import { useDispatch, useSelector } from "react-redux";
// import Papa from 'papaparse';
// import './DW.css';
// import config from '../config/config.json';

var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds

const parseCSV = (text) => {
  const lines = text.trim().split('\n');
  const headers = lines[0].split(',').map(header => header.trim());
  const result = [];

  for (let i = 1; i < lines.length; i++) {
    if (lines[i].trim() === '') continue; // Skip empty lines
    const obj = {};
    const currentLine = lines[i].split(',').map(value => value.trim());
    for (let j = 0; j < headers.length; j++) {
      obj[headers[j]] = currentLine[j];
    }
    result.push(obj);
  }
  return result;
};

const DW = () => {
  const [symbols, setSymbols] = useState([]); // State to store symbols
  const [symbol, setSymbol] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState(new Date(Date.now() - tzoffset).toISOString().slice(0, 16));
  const [chartDataPrice, setChartDataPrice] = useState([]);
  const [chartDataC, setChartDataC] = useState([]);
  const [chartDataP, setChartDataP] = useState([]);
  const [chartDataDif, setChartDataDif] = useState([]);
  const [peaksPrice, setPeaksPrice] = useState([]);
  const [valleysPrice, setValleysPrice] = useState([]);
  const [peaksC, setPeaksC] = useState([]);
  const [valleysC, setValleysC] = useState([]);
  const [peaksP, setPeaksP] = useState([]);
  const [valleysP, setValleysP] = useState([]);
  const [peaksDif, setPeaksDif] = useState([]);
  const [valleysDif, setValleysDif] = useState([]);
  const [foldersPrice, setFoldersPrice] = useState([]);
  const [foldersC, setFoldersC] = useState([]);
  const [foldersP, setFoldersP] = useState([]);
  const [symbolConfirmed, setSymbolConfirmed] = useState(false);
  const [timeFrame, setTimeFrame] = useState('Intraday');
  const [loading, setLoading] = useState(false);

  const priceChartRef = useRef();
  const cChartRef = useRef();
  const pChartRef = useRef();
  const difChartRef = useRef(); // New ref for difChart
  const csrfToken = useSelector((state) => state.csrfToken);

  useEffect(() => { // Load symbols from Symbol.csv (initial fetch)
    const loadSymbols = async () => {
      try {
        const fetch_response = await fetch(`${window.config.base_api_url}/api/data/Symbol`, {
          headers: {
            'Content-Type': 'text/csv',
            'xsrf-token': csrfToken,
          },
        });
        const data = await fetch_response.text();
        const parsedData = parseCSV(data);
        const symbolList = parsedData.map(row => row.Symbol);
        setSymbols(symbolList);
      } catch (err) {
        console.error("Error loading symbols:", err);
      }
    };

    loadSymbols();
  }, []);

  const fetchData = async () => {
    if (symbol && startDate && endDate) {
      setLoading(true); // Start loading

      try {
        const dataPrice = await fetch(`${window.config.base_api_url}/api/data/${symbol}/Price`, {
          headers: {
            'Content-Type': 'text/csv',
            'xsrf-token': csrfToken,
          },
        }).then(res => res.text());

        const dataC = await fetch(`${window.config.base_api_url}/api/data/${symbol}/C`, {
          headers: {
            'Content-Type': 'text/csv',
            'xsrf-token': csrfToken,
          },
        }).then(res => res.text()).catch(() => '');

        const dataP = await fetch(`${window.config.base_api_url}/api/data/${symbol}/P`, {
          headers: {
            'Content-Type': 'text/csv',
            'xsrf-token': csrfToken,
          },
        }).then(res => res.text()).catch(() => '');

        const dataDif = await fetch(`${window.config.base_api_url}/api/data/${symbol}/dif`, {
          headers: {
            'Content-Type': 'text/csv',
            'xsrf-token': csrfToken,
          },
        }).then(res => res.text()).catch(() => '');

        // Determine which peak/valley columns to use based on the selected time frame
        let peakColumn = '';
        let valleyColumn = '';

        switch (timeFrame) {
          case 'Intraday':
            peakColumn = 'peak_Intraday';
            valleyColumn = 'valley_Intraday';
            break;
          case '30 Min':
            peakColumn = 'peak_30';
            valleyColumn = 'valley_30';
            break;
          case '60 Min':
            peakColumn = 'peak_60';
            valleyColumn = 'valley_60';
            break;
          case 'Day':
            peakColumn = 'peak_Day';
            valleyColumn = 'valley_Day';
            break;
          default:
            peakColumn = 'peak_Intraday';
            valleyColumn = 'valley_Intraday';
        }

        const folderSetPrice = new Set();
        const folderSetC = new Set();
        const folderSetP = new Set();

        const filterAndMapData = (data, folderSet, isPriceData = false) => {
          const folderArray = [];
          const filteredData = [];
          const peakData = [];
          const valleyData = [];
          const lastDataPointOfDay = {}; // Object to store the last data point and folder for each day

          const parsedData = parseCSV(data);

          parsedData.forEach(d => {
            const date = new Date(d.Timestamp);
            const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000); // Convert UTC to local time
            const timestamp = localDate.getTime() / 1000; // Convert to seconds

            if (date >= new Date(startDate) && date <= new Date(endDate)) {
              let shouldAddData = false;

              if (timeFrame === 'Intraday') {
                shouldAddData = true;
              } else if (timeFrame === '30 Min' && date.getMinutes() % 30 === 0) {
                shouldAddData = true;
              } else if (timeFrame === '60 Min' && date.getMinutes() === 0) {
                shouldAddData = true;
              } else if (timeFrame === 'Day') {
                const dayKey = localDate.toISOString().split('T')[0]; // YYYY-MM-DD format
                // Store the latest data point for each day along with peak and valley
                lastDataPointOfDay[dayKey] = {
                  time: timestamp,
                  value: parseFloat(d.Value),
                  folders: d.Folder,
                  peak: parseFloat(d[peakColumn]), // Capture peak for the day
                  valley: parseFloat(d[valleyColumn]) // Capture valley for the day
                };
              }

              if (shouldAddData) {
                // Normalize folder string
                let folders = d.Folder.split(',')
                  .map(folder => folder.trim())
                  .filter(folder => folder && folder.toLowerCase() !== 'nan'); // Filter out empty and 'NaN' folders

                folders = [...new Set(folders)]; // Remove duplicates
                folders.sort(); // Sort folders alphabetically

                // Add normalized folders to the folderArray
                folders.forEach(folder => folderArray.push(folder));

                filteredData.push({
                  time: timestamp,
                  value: parseFloat(d.Value),
                });

                // Process peaks and valleys based on the selected time frame
                if (isPriceData) {
                  if (d[peakColumn] && !isNaN(d[peakColumn])) {
                    peakData.push({ time: timestamp, value: parseFloat(d[peakColumn]) });
                  }
                  if (d[valleyColumn] && !isNaN(d[valleyColumn])) {
                    valleyData.push({ time: timestamp, value: parseFloat(d[valleyColumn]) });
                  }
                }
              }
            }
          });

          // If the timeFrame is 'Day', add the last data point of each day including peaks and valleys
          if (timeFrame === 'Day') {
            Object.keys(lastDataPointOfDay).forEach(day => {
              const dataPoint = lastDataPointOfDay[day];
              filteredData.push({
                time: dataPoint.time,
                value: dataPoint.value,
              });

              // Process and add folder information
              let folders = dataPoint.folders.split(',')
                .map(folder => folder.trim())
                .filter(folder => folder && folder.toLowerCase() !== 'nan'); // Filter out empty and 'NaN' folders

              folders = [...new Set(folders)]; // Remove duplicates
              folders.sort(); // Sort folders alphabetically

              // Add folders to folderArray
              folders.forEach(folder => folderArray.push(folder));

              // Add peak and valley data for the day
              if (!isNaN(dataPoint.peak)) {
                peakData.push({ time: dataPoint.time, value: dataPoint.peak });
              }
              if (!isNaN(dataPoint.valley)) {
                valleyData.push({ time: dataPoint.time, value: dataPoint.valley });
              }
            });
          }

          // Sort and store the folder data
          folderArray.sort((a, b) => a.localeCompare(b)); // Sort the array
          folderArray.forEach(folder => folderSet.add(folder)); // Add sorted folders to the set

          // Return the filtered data and peaks/valleys
          return {
            filteredData: filteredData, // Ensure sorted by time
            peakData,
            valleyData,
          };
        };

        // Process data for all charts
        const priceData = filterAndMapData(dataPrice, folderSetPrice, true);
        const cData = filterAndMapData(dataC, folderSetC, true);
        const pData = filterAndMapData(dataP, folderSetP, true);
        const difData = filterAndMapData(dataDif, new Set(), true);

        // Set chart data and peaks/valleys for each chart
        setChartDataPrice(priceData.filteredData);
        setChartDataC(cData.filteredData);
        setChartDataP(pData.filteredData);
        setChartDataDif(difData.filteredData);

        setPeaksPrice(priceData.peakData);
        setValleysPrice(priceData.valleyData);

        setPeaksC(cData.peakData);
        setValleysC(cData.valleyData);

        setPeaksP(pData.peakData);
        setValleysP(pData.valleyData);

        setPeaksDif(difData.peakData);
        setValleysDif(difData.valleyData);

        // Set folder data for display
        setFoldersPrice(Array.from(new Set(folderSetPrice)).join(', '));
        setFoldersC(Array.from(new Set(folderSetC)).join(', '));
        setFoldersP(Array.from(new Set(folderSetP)).join(', '));

        setLoading(false); // End loading
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (symbolConfirmed) { // Only fetch data if symbol is confirmed
      fetchData(); // Fetch new data when symbol, startDate, endDate, or timeFrame changes
    }
  }, [symbolConfirmed, startDate, endDate, timeFrame]); // Added symbolConfirmed here

  useEffect(() => { // Create charts and synchronize them

    const resizeChart = (chart, chartRef) => {
      if (chart && chartRef.current) {
        const width = chartRef.current.clientWidth;
        chart.resize(width, 300); // Fixed height at 300px
      }
    };

    const priceChart = createChart(priceChartRef.current, {
      // height: 400, 
      height: 300,
      crosshair: { mode: 1 },
      timeScale: { timeVisible: true },
      rightPriceScale: { visible: false }, // Hide price scale
    });

    // Apply watermark with dynamic symbol
    priceChart.applyOptions({
      watermark: {
        visible: true,
        fontSize: 24,
        horzAlign: 'center',
        vertAlign: 'center',
        color: 'rgba(171, 71, 188, 0.5)', // Adjust color and opacity as needed
        text: `${symbol}`,
      },
    });

    const priceSeries = priceChart.addLineSeries({ color: 'green' });
    priceSeries.setData(chartDataPrice);

    let cSeries, pSeries, difSeries;

    // Create cChart (C data)
    const cChart = createChart(cChartRef.current, {
      // height: 400, 
      height: 300,
      crosshair: { mode: 1 },
      timeScale: { timeVisible: true },
      rightPriceScale: { visible: false }, // Hide price scale
    });

    cChart.applyOptions({
      watermark: {
        visible: true,
        fontSize: 24,
        horzAlign: 'center',
        vertAlign: 'center',
        color: 'rgba(171, 71, 188, 0.5)',
        text: `CALL`,
      },
    });

    cSeries = cChart.addLineSeries({ color: 'blue' });
    cSeries.setData(chartDataC);

    // Create pChart (P data)
    const pChart = createChart(pChartRef.current, {
      // height: 400, 
      height: 300,
      crosshair: { mode: 1 },
      timeScale: { timeVisible: true },
      rightPriceScale: { visible: false },
    });

    pChart.applyOptions({
      watermark: {
        visible: true,
        fontSize: 24,
        horzAlign: 'center',
        vertAlign: 'center',
        color: 'rgba(171, 71, 188, 0.5)',
        text: `PUT`,
      },
    });

    pSeries = pChart.addLineSeries({ color: 'purple' });
    pSeries.setData(chartDataP);

    // Create difChart (Dif data)
    const difChart = createChart(difChartRef.current, {
      height: 300,
      // height: 400, 
      crosshair: { mode: 1 },
      timeScale: { timeVisible: true },
      rightPriceScale: { visible: false },
    });

    difChart.applyOptions({
      watermark: {
        visible: true,
        fontSize: 24,
        horzAlign: 'center',
        vertAlign: 'center',
        color: 'rgba(171, 71, 188, 0.5)',
        text: `NET`,
      },
    });

    difSeries = difChart.addLineSeries({ color: 'red' });
    difSeries.setData(chartDataDif);

    const handleResize = () => {
      resizeChart(priceChart, priceChartRef);
      resizeChart(cChart, cChartRef);
      resizeChart(pChart, pChartRef);
      resizeChart(difChart, difChartRef);
    };

    window.addEventListener('resize', handleResize);

    // Initial resize
    handleResize();

    const addMarkersToChart = (chartSeries, peaks, valleys) => {
      const markers = [];

      peaks.forEach(peak => {
        markers.push({
          time: peak.time, // Ensure time is in ascending order
          position: 'aboveBar',
          color: '#83D143',
          shape: 'arrowUp',
          text: `U`,
        });
      });

      valleys.forEach(valley => {
        markers.push({
          time: valley.time, // Ensure time is in ascending order
          position: 'belowBar', // Triangle below the data point
          color: '#F5564F', // Color for valleys
          shape: 'arrowDown',
          text: `D`,
        });
      });

      // Sort markers by time (ascending)
      markers.sort((a, b) => a.time - b.time);

      // Set markers on the series
      chartSeries.setMarkers(markers);
    };

    // Example: Add markers to a specific chart series
    addMarkersToChart(priceSeries, peaksPrice, valleysPrice);
    addMarkersToChart(cSeries, peaksC, valleysC);
    addMarkersToChart(pSeries, peaksP, valleysP);
    addMarkersToChart(difSeries, peaksDif, valleysDif);

    // Synchronize visible range across charts
    const syncVisibleRange = (sourceChart, targetChart) => {
      if (sourceChart && targetChart) {
        sourceChart.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
          targetChart.timeScale().setVisibleLogicalRange(timeRange);
        });
      }
    };

    // Synchronize crosshair movement across charts
    const syncCrosshair = (sourceChart, sourceSeries, targetChart, targetSeries) => {
      if (sourceChart && targetChart) {
        sourceChart.subscribeCrosshairMove(param => {
          const dataPoint = param.seriesData.get(sourceSeries);
          if (dataPoint) {
            targetChart.setCrosshairPosition(dataPoint.value, dataPoint.time, targetSeries);
          } else {
            targetChart.clearCrosshairPosition();
          }
        });
      }
    };

    // Synchronize charts
    if (cChart) {
      syncVisibleRange(priceChart, cChart);
      syncVisibleRange(cChart, priceChart);
      syncCrosshair(priceChart, priceSeries, cChart, cSeries);
      syncCrosshair(cChart, cSeries, priceChart, priceSeries);
    }

    if (pChart) {
      syncVisibleRange(priceChart, pChart);
      syncVisibleRange(pChart, priceChart);
      syncCrosshair(priceChart, priceSeries, pChart, pSeries);
      syncCrosshair(pChart, pSeries, priceChart, priceSeries);
    }

    if (difChart) { // Sync difChart with other charts
      syncVisibleRange(priceChart, difChart);
      syncVisibleRange(difChart, priceChart);
      syncCrosshair(priceChart, priceSeries, difChart, difSeries);
      syncCrosshair(difChart, difSeries, priceChart, priceSeries);

      if (cChart) {
        syncVisibleRange(cChart, difChart);
        syncVisibleRange(difChart, cChart);
        syncCrosshair(cChart, cSeries, difChart, difSeries);
        syncCrosshair(difChart, difSeries, cChart, cSeries);
      }

      if (pChart) {
        syncVisibleRange(pChart, difChart);
        syncVisibleRange(difChart, pChart);
        syncCrosshair(pChart, pSeries, difChart, difSeries);
        syncCrosshair(difChart, difSeries, pChart, pSeries);
      }
    }

    return () => {
      window.removeEventListener('resize', handleResize);

      priceChart.remove();
      cChart && cChart.remove();
      pChart && pChart.remove();
      difChart && difChart.remove(); // Remove difChart on cleanup
    };
  }, [chartDataPrice, chartDataC, chartDataP, chartDataDif, peaksPrice, valleysPrice, peaksC, valleysC, peaksP, valleysP, peaksDif, valleysDif, symbol]); // Added all peak/valley data to dependency array

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontFamily: 'Arial, sans-serif' }}>
      <h1 style={{ marginBottom: '20px' }}>DW View Charts</h1>
      <div style={{ marginBottom: '20px' }}>
        <div>
          <label>Symbol: </label>
          <input
            list="symbols"
            value={symbol}
            onChange={(e) => {
              setSymbol(e.target.value);
              setSymbolConfirmed(false); // Reset confirmation when symbol changes
            }}
            placeholder="Search or select a symbol"
          />
          <datalist id="symbols">
            {symbols.map((sym) => (
              <option key={sym} value={sym} />
            ))}
          </datalist>
        </div>
        <div>
          <label>Select Start Date and Time: </label>
          <input
            type="datetime-local"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)} />
        </div>
        <div>
          <label>Select End Date and Time: </label>
          <input
            type="datetime-local"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)} />
          <button onClick={() => setSymbolConfirmed(true)}>Enter</button>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <label style={{ marginRight: '10px' }}>Time frame:</label>
          <button
            onClick={() => setTimeFrame('Intraday')}
            style={{
              marginRight: '5px',
              cursor: 'pointer',
              backgroundColor: timeFrame === 'Intraday' ? '#414141' : '#f0f0f0',
              color: timeFrame === 'Intraday' ? 'white' : 'black',
              fontWeight: timeFrame === 'Intraday' ? 'bold' : 'normal',
              border: '1px solid ' + (timeFrame === 'Intraday' ? '#414141' : '#ccc'),
              borderRadius: '4px',
            }}
          >
            Intraday
          </button>
          <button
            onClick={() => setTimeFrame('30 Min')}
            style={{
              marginRight: '5px',
              cursor: 'pointer',
              backgroundColor: timeFrame === '30 Min' ? '#414141' : '#f0f0f0',
              color: timeFrame === '30 Min' ? 'white' : 'black',
              fontWeight: timeFrame === '30 Min' ? 'bold' : 'normal',
              border: '1px solid ' + (timeFrame === '30 Min' ? '#414141' : '#ccc'),
              borderRadius: '4px',
            }}
          >
            30 Min
          </button>
          <button
            onClick={() => setTimeFrame('60 Min')}
            style={{
              marginRight: '5px',
              cursor: 'pointer',
              backgroundColor: timeFrame === '60 Min' ? '#414141' : '#f0f0f0',
              color: timeFrame === '60 Min' ? 'white' : 'black',
              fontWeight: timeFrame === '60 Min' ? 'bold' : 'normal',
              border: '1px solid ' + (timeFrame === '60 Min' ? '#414141' : '#ccc'),
              borderRadius: '4px',
            }}
          >
            60 Min
          </button>
          <button
            onClick={() => setTimeFrame('Day')}
            style={{
              marginRight: '5px',
              cursor: 'pointer',
              backgroundColor: timeFrame === 'Day' ? '#414141' : '#f0f0f0',
              color: timeFrame === 'Day' ? 'white' : 'black',
              fontWeight: timeFrame === 'Day' ? 'bold' : 'normal',
              border: '1px solid ' + (timeFrame === 'Day' ? '#414141' : '#ccc'),
              borderRadius: '4px',
            }}
          >
            Day
          </button>
        </div>
      </div>
      {loading ? (
        <div style={{
          fontSize: '24px',
          fontWeight: 'bold',
          color: '#000000',
          textAlign: 'center',
          marginTop: '20px',
        }}>Loading...</div>
      ) : (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          width: '90%',
          // maxWidth: '1800px',
        }}>
          <div style={{ flex: 1, margin: '0 10px' }}>
            <div ref={priceChartRef}></div>
            <div style={{
              whiteSpace: 'pre-wrap',
              textAlign: 'left',
              marginTop: '10px',
              fontSize: '14px',
              color: '#414141',
              lineHeight: '1.5',
            }}>
              {(typeof foldersPrice === 'string' ? foldersPrice.split(',') : []).map((folder, index) => (
                <span key={index} style={{
                  display: 'inline-block',
                  minWidth: '130px',
                }}>{folder.trim()}</span>
              ))}
            </div>
            <div ref={cChartRef}></div>
            <div style={{
              whiteSpace: 'pre-wrap',
              textAlign: 'left',
              marginTop: '10px',
              fontSize: '14px',
              color: '#414141',
              lineHeight: '1.5',
            }}>
              {(typeof foldersC === 'string' ? foldersC.split(',') : []).map((folder, index) => (
                <span key={index} style={{
                  display: 'inline-block',
                  minWidth: '130px',
                }}>{folder.trim()}</span>
              ))}
            </div>
          </div>
          <div style={{ flex: 1, margin: '0 10px' }}>
            <div ref={difChartRef}></div>
            <div style={{
              whiteSpace: 'pre-wrap',
              textAlign: 'left',
              marginTop: '10px',
              fontSize: '14px',
              color: '#414141',
              lineHeight: '1.5',
            }}>
              {/* No folders to display */}
            </div>
            <div ref={pChartRef}></div>
            <div style={{
              whiteSpace: 'pre-wrap',
              textAlign: 'left',
              marginTop: '10px',
              fontSize: '14px',
              color: '#414141',
              lineHeight: '1.5',
            }}>
              {(typeof foldersP === 'string' ? foldersP.split(',') : []).map((folder, index) => (
                <span key={index} style={{
                  display: 'inline-block',
                  minWidth: '130px',
                }}>{folder.trim()}</span>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DW;
